import React, { useEffect, useMemo, useRef, useState } from 'react'
// Common
import Ckeditor4 from 'components/common/ckeditor4';
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
import InputBox from 'components/common/inputbox/InputBox';
// Moduels
import { EmailTemplateItem } from 'store/modules/types';
// Styles
import { EmailTemplateWriteStyles as Styled } from './styles/EmailTemplateWrite.styles'
// Components
import HtmlView from '../section/HtmlView';
// Hooks
import useEmailTemp from 'hooks/useEmailTemplate';

interface Itemplate {
  templateId: string,
  templateName: string,
  template: string,
}

interface IProps {
  itemNumber: number,
  open: boolean,
  onClose: () => void,
  item: EmailTemplateItem,
  showFeedback: (msg: string) => void,
}

const EmailTemplateWrite: React.FC<IProps> = (props) => {

  const { itemNumber, open, onClose, item, showFeedback } = props;

  const [ writeTemplate, setWriteTemplate ] = useState<Itemplate>({ 
    templateId: item.templateId,
    templateName: item.templateName,
    template: item.template,
  });
  const [ writeMode, setWriteMode ] = useState<string>('');
  const { requestInsertEmailTemplate, requestUpdateEmailTemplate } = useEmailTemp();
  const [ validTemplateName, setValidTemplateName ] = useState<boolean>(false);
  const templateIdRef = useRef<HTMLInputElement>(null);
  const templateNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    item.templateId.length === 0 ? setWriteMode('post') : setWriteMode('put');
  }, []);

  const changeTemplateCode = (data:any) => {
    setWriteTemplate({
      ...writeTemplate,
      template: data,
    });
  }

  const changeTemplateName = (text: string) => {
    if (validTemplateName) {
      if (writeTemplate.templateName.length > 0) {
        setValidTemplateName(false);
        setWriteTemplate({
          ...writeTemplate,
          templateName: text,
        });
      } else {
        setWriteTemplate({
          ...writeTemplate,
          templateName: text,
        });
      }
    } else {
      setWriteTemplate({
        ...writeTemplate,
        templateName: text,
      });
    }
  }

  const onWriteTemplate = () => {
    if (writeMode === 'post') {
      if (writeTemplate.templateName.length === 0) {
        setValidTemplateName(true);
        if (templateNameRef.current !== null) {
          templateNameRef.current.focus();
        }
        return;
      } else {
        setValidTemplateName(false);
      }

      const payload: any = {
        body: {
          template: writeTemplate.template,
          templateName: writeTemplate.templateName,
        },
        callback: (succeeded: boolean, res: any) => {
          if (succeeded && res.response.code === 'OK') {
            showFeedback('E-mail 템플릿 등록 완료되었습니다.');
            onClose();
          } else {
            showFeedback('E-mail 템플릿 등록 실패하였습니다.');
            onClose();
          }
        }
      }
      requestInsertEmailTemplate(payload);
    } else {
      if (writeTemplate.templateName.length === 0) {
        setValidTemplateName(true);
        return;
      }

      const payload: any = {
        body: {
          template: writeTemplate.template,
          emailTemplateId: writeTemplate.templateId,
          templateName: writeTemplate.templateName,
        },
        callback: (succeeded: boolean, res: any) => {
          if (succeeded && res.response.code === 'OK') {
            showFeedback('E-mail 템플릿 수정 완료되었습니다.');
            onClose();
          } else {
            showFeedback('E-mail 템플릿 수정 실패하였습니다.');
          }
        }
      }
      requestUpdateEmailTemplate(payload);
    }
  }

  const editor = useMemo(() => 
    writeMode === 'post' ?
      <Ckeditor4 
        url="EMAIL_TEMPLATE"
        setContents={changeTemplateCode}
        height={500}
      />
    : 
      <Ckeditor4 
        url="EMAIL_TEMPLATE"
        contents={writeTemplate.template}
        setContents={changeTemplateCode}
        height={500}
      />
  , [writeTemplate]);

  return (
    <BasePopup 
      id={itemNumber}
      open={open}
      onClose={onClose}
      size={'large'}
      type={PopupTypes.DIALOG}
      buttons={[{}, {onClick: onWriteTemplate}]}
    >
      <Styled.Wrap>
        <Styled.PopupHeader>{writeMode === 'post' ? '템플릿 작성' : '템플릿 수정'}</Styled.PopupHeader>
        <Styled.Title>제목</Styled.Title>
        <InputBox 
          value={writeTemplate.templateName}
          setText={changeTemplateName}
          error={validTemplateName}
          errorMessage='* 템플릿 이름을 입력해주세요.'
          inputRef={templateNameRef}
        />
        <Styled.Title>내용</Styled.Title>
        <Styled.Editor>
          {editor}
        </Styled.Editor>
        <Styled.Title>미리보기</Styled.Title>
        <HtmlView 
          htmlCode={writeTemplate.template}
        />
      </Styled.Wrap>
    </BasePopup>
  )
}

export default EmailTemplateWrite
