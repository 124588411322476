import React, { useContext } from 'react'
import moment from 'moment';
import 'moment/locale/ko';
import { IMembershipItemsType, IMembershipType, IUser, IUserCooperationType, IUserMembership, IUserReviewrayMembership, TMemberDetails, TRoleType } from 'store/modules/types';

export interface IUserInfo {
  regDt: string;
  updateDt: string;
  uid: number;
  customerType: string;
  userId: string;
  userName: string;
  phone: string;
  email: string;
  quitYn: boolean;
  quitDate: string;
  temporaryPwYn: boolean;
  mailAgreementYn: boolean;
  smsAgreementYn: boolean;
  customer: ICustomerInfo;
  authorities: IAuthorityListItem[];
  agreements: IAgreementListItem[];
}

export interface ICustomerInfo {
  regDt: string;
  updateDt: string;
  customerId: string;
  customerName: string;
  customerType: string;
  customerDetails: ICustomerDetailsInfo;
}

export interface ICustomerDetailsInfo {
  paidShoplinker: boolean;
  emailYnShoplinker: boolean;
  smsYnShoplinker: boolean;
  monitoringSampleView: boolean;
}

export interface IAuthorityListItem {
  authority: string;
}

export interface IAgreementListItem {
  agreementYn: boolean;
  regDt: string;
  uid: number;
  updateDt: string;
  userTos: IUserTos;
}

export interface IUserTos {
  regDt: string;
  updateDt: string;
  uid: number;
  title: string;
  content: string;
  url: string;
  priority: number;
  required: boolean;
  useYn: boolean;
  applyDate: string;
}

export const initCustomerMembership: { 
  irankMembership : IUserMembership, 
  reviewMembership : IUserReviewrayMembership
} = {
  irankMembership : {
    cycle: 0,
    discountPrice: 0,
    discountRate: 0,
    id: 0,
    isEnd: false,
    isUnSubscribe: false,
    membershipItems: [],
    membership: 'FREE',
    membershipEndDate: null,
    membershipStartDate: null,
    nextPayment: null,
    nowPayment: null,
    option: 'OPTION_1',
    price: 0,
    reviewProductCount: null,
    scheduleDt: null,
    serviceType: 'IRANK',
    membershipCount: {
      keywordCount : {
        total: 0,
        month: 0,
      },
      productCount : {
        total: 0,
        month: 0,
        product: 0,
      },
      recommendCount: {
        total: 0,
        month: 0,
        product: 0,
      },
      monitoringCount: {
        product: 0,
        keyword: 0,
        group: 0,
      }
    }
  },
  reviewMembership : {
    contractProductCount: 0,
    contractReviewCount: 0,
    counts: [],
    id: 0,
    isEnd: true,
    items: [],
    membership: 'FREE',
    membershipEndDate: null,
    membershipStartDate: null,
    option: 'OPTION_1',
    productChangeCount: 0,
    productChangeLimit: 0,
    serviceType: 'REVIEWRAY'
  }
}

export const initMemberDetails: TMemberDetails = {
  cooperationType: 'COMMON',
  customerType: '',
  userId: '',
  userName: '',
  phone: '',
  email: '',
  mailAgreementYn: false,
  smsAgreementYn: false,
  customer: {
    customerId: '',
    customerName: '',
    customerType: '',
  },
  roles: [],
  socialOnly: false,
  uid: '0',
  agreements: [
    {
      agreementYn: false,
      regDt: '',
      uid: 0,
      updateDt: '',
      userTos: {
        required: false,
        applyDate:'',
        content:'',
        regDt: '',
        priority: 0,
        useYn: false,
        uid: 0,
        updateDt: '',
        title: '',
        url: '',
      }
    }
  ]
}

export interface IUserTosInfo {
  regDt: string,
  updateDt: string,
  uid: number,
  title: string,
  priority: number,
  required: boolean,
  useYn: boolean,
  applyDate: string
}

export const initUserTosInfo : IUserTosInfo = {
  regDt: '',
  updateDt: '',
  uid: 0,
  title: '',
  priority: 0,
  required: false,
  useYn: false,
  applyDate: ''
}

export interface IUserMarketList {
  marketId: string,
  marketName: string,
  marketLogo: string,
  customerMarketData: any[]
}

export const initUserMarketList : IUserMarketList[] = [
  {
    marketId: '',
    marketName: '',
    marketLogo: '',
    customerMarketData: []
  }
]

export interface ISaleInfo {
  [key: string] : number;
  accumulateAmount: number;
  accumulateClaim: number;
  accumulateQuantity: number;
  monthlyAmount: number;
  monthlyClaim: number;
  monthlyQuantity: number;
  yesterdayAmount: number;
  yesterdayClaim: number;
  yesterdayQuantity: number;
}

export const initSaleInfo : ISaleInfo = {  
  accumulateAmount: 0,
  accumulateClaim: 0,
  accumulateQuantity: 0,
  monthlyAmount: 0,
  monthlyClaim: 0,
  monthlyQuantity: 0,
  yesterdayAmount: 0,
  yesterdayClaim: 0,
  yesterdayQuantity: 0,
}

export const initSaleDates: string[] = [
  moment().startOf('month').format('YYYY-MM-DD'),
  moment().endOf('month').format('YYYY-MM-DD'),
]


export interface IUserJoinRequestParms {
  uid?: number;
  userId?: string;
  password?: string;
  checkPassword?: string;
  phone: string;
  email: string;
  customerName: string;
  nickName: string;

  error: boolean;
  errorMessage: any;

  userName: string,

  showPassowrd: boolean;

  privacyCheck: boolean;
  termsCheck: boolean;
  marketingCheck: boolean;
  informationCheck: boolean;
  authButton: boolean;
  authStatesFlag: boolean;
  agreements: any[];
  customerType?: string;
  mailAgreementYn: boolean;
  
  paymentNo: number;
  priceNo: number;
  roles: string[];
  smsAgreementYn: boolean;
}

export const initIuserJoinRequestParmas : IUserJoinRequestParms = {
  userId: '',
  password: '',
  checkPassword: '',
  phone: '',
  email: '',
  customerName: '',
  nickName: '',
  
  error: false,
  errorMessage: {
    userId: '',
    password: '',
    checkPassword: '',
    phone: '',
    email: '',
    customerName: '',
  },
  userName: '',
  showPassowrd: false,

  privacyCheck: false,
  termsCheck: false,
  marketingCheck: false,
  informationCheck: false,
  authButton: false,
  authStatesFlag: false,
  agreements: [],
  customerType: '',
  mailAgreementYn: false,
  
  paymentNo: 0,
  priceNo: 0,
  roles: [
    'OPERATOR'
  ],
  smsAgreementYn: false,
}

export interface IPasswordParams {
  oldPasswrod: string;
  password: string;
  checkPassword: string;
  showPassowrd: boolean;
  error: boolean;
  errorMessage: any;
}

export const initIPasswordParams : IPasswordParams = {
  oldPasswrod: '',
  password: '',
  checkPassword: '',
  showPassowrd: false,
  error: false,
  errorMessage: {
    oldPasswrod: '',
    password: '',
    checkPassword: '',
  },
}

export interface IPopupMode {
  open: boolean;
  mode: string;
}

export const initIPopupMode: IPopupMode = {
  open: false,
  mode: 'add',
}

type TSearchType = 'ID' | 'CUSTOMER_ID' | 'EMAIL' | 'PHONE';

export interface ISearchUserParams {
  keyword: string;
  page: number;
  size: number;
  registerEndDate?: string;
  registerStartDate?: string;
  //roleType: string[];
  searchType: TSearchType;
  quit: boolean;
  sms?: string;
  email?: string;
  roles: TRoleType[];
  irankMemberships: IMembershipType['irankMemberships'][]
  reviewMemberships: IMembershipType['reviewMemberships'][]
  cooperationTypes: IUserCooperationType[]
  userSort?:
  | 'REG_DT_ASC' 
  | 'REG_DT_DESC' 
  | 'LOGIN_REG_DT_ASC' 
  | 'LOGIN_REG_DT_DESC' 
  | 'LOGIN_COUNT_ASC' 
  | 'LOGIN_COUNT_DESC'
  | ''
}

export const ROLE_TYPES: TRoleType[] = ['USER', 'OPERATOR', 'DEVELOPER', 'TESTER', 'ADMIN']

export const initISearchUserParams: ISearchUserParams = {
  searchType :'ID',
  keyword: '',
  page: 1,
  size: 10,
  quit: false,
  roles: ROLE_TYPES,
  irankMemberships: ['FREE', 'BASIC', 'STANDARD', 'PREMIUM', 'ENTERPRISE', ],
  reviewMemberships: ['FREE', 'ENTERPRISE', 'DEMO', 'PREMIUM'],
  cooperationTypes: ['COMMON', 'SHOPLINKER']
}

export interface IUserRoleListItem {
  authority: string;
  code: string;
  text: string;
}

export interface ISaleDetailInfos {
  marketId: string;
  amount: number;
  quantity: number;
}

export interface IMySmartStore {
  id: number;
  customerId: string;
  storeUrl: string;
  storeId: number;
  storeName: string;
  storeImage: string;
  description: string;
  grade: string;
  companyName: string;
  ceo: string;
  email: string;
  customerServiceNumber: string;
  businessNumber: string;
  mailOrderSalesNumber: string;
  regDt: string;
  updateDt: string;
  mySmartstoreProducts: {
    id: number;
    customerId: string;
    analyzeType: string;
    storeId: string;
    productId: string;
    productName: string;
    productUrl: string;
    productThumbnailUrl: string;
    productRegDt: string;
    productPrice: number;
    productSalePrice: number;
    productSaleRate: number;
    deliveryFee: number;
    category: string;
    categoryId: string;
    categoryName: string;
    starPoint: number;
    reviewCount: number;
    tags: string[];
    saleCount6Months: number;
    saleCount3Days: number;
    keywordFit: number;
    keywordFitDate: string;
    sellingStatus: string;
    regDt: string;
    updateDt: string;
    relatedPopularKeywords: string[];
    otherPopularKeywords: string[];
  }[];
}

export const initMySmartStore: IMySmartStore = {
  id: 0,
  customerId: '',
  storeUrl: '',
  storeId: 0,
  storeName: '',
  storeImage: '',
  description: '',
  grade: '',
  companyName: '',
  ceo: '',
  email: '',
  customerServiceNumber: '',
  businessNumber: '',
  mailOrderSalesNumber: '',
  regDt: '',
  updateDt: '',
  mySmartstoreProducts: []
}

export interface IUserAnalyzeStatusData {
  totalCount: number;
  monthlyCount: number;
}

export const initUserAnalyzeStatusData: IUserAnalyzeStatusData = {
  totalCount: 0,
  monthlyCount: 0
}

export interface IUserMonitoringAnalyzeStatusData {
  totalCount: number;
  currentCount: number;
}

export const initUserMonitoringAnalyzeStatusData: IUserMonitoringAnalyzeStatusData = {
  totalCount: 0,
  currentCount: 0
}

export interface IUserAnalyzeStatus {
  analyzeProduct: IUserAnalyzeStatusData;
  analyzeKeyword: IUserAnalyzeStatusData;
  analyzeComprehensive: IUserAnalyzeStatusData;
  compareKeyword: IUserMonitoringAnalyzeStatusData;
  compareProduct: IUserMonitoringAnalyzeStatusData;
  monitoringProduct: IUserMonitoringAnalyzeStatusData;
  monitoringKeyword: IUserMonitoringAnalyzeStatusData;
}

export const initUserAnalyzeStatus: IUserAnalyzeStatus = {
  analyzeProduct: initUserAnalyzeStatusData,
  analyzeKeyword: initUserAnalyzeStatusData,
  analyzeComprehensive: initUserAnalyzeStatusData,
  compareKeyword: initUserMonitoringAnalyzeStatusData,
  compareProduct: initUserMonitoringAnalyzeStatusData,
  monitoringProduct: initUserMonitoringAnalyzeStatusData,
  monitoringKeyword: initUserMonitoringAnalyzeStatusData
}

export interface IAnalyzeStatus {
  totalCount: number;
  monthlyCount: number;
  dailyCount?: number;
  type: string;
  name?: string;
  currentCount?: number;
}

export const initAnalyzeStatus: IAnalyzeStatus = {
  dailyCount: 0,
  totalCount: 0,
  monthlyCount: 0,
  type: ''
}

export const initMonitoringAnalyzeStatus: IAnalyzeStatus = {
  totalCount: 0,
  monthlyCount: 0,
  currentCount: 0,
  type: '',
  name: '',
}


export interface IAnalyzeContent {
  /**
   * product & keyword 
   */
  no: string;

  /**
   * keyword
   */
  regDt: string;

  /**
   * compareMonitoring
   */
  deleteDt: string;
  lastRankDt: string;
  status: string;

  /**
   * traceMonitoring
   */
  rankingOfPage: null | number;
  pageNo: null | number;
  rankingOfAll: null | number;
  rankingOfAllNoAd: null | number;
  rankingOfPageNoAd: null | number;

  /**
   * product & comprehensive
   */
  marketName: string;
  
  /**
   * product & comprehensive & compareMonitoring & traceMonitoring
   */
  productUrl: string;

  /**
   * product & comprehensive & compareMonitoring & traceMonitoring
   */
  productName: string;

  /**
   * keyword & compareMonitoring & traceMonitoring
   */
  keyword: string;

}

export interface IAnalyzeContents {
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: IAnalyzeContent[]
}

export interface IAnalyzeData {
  contents: IAnalyzeContents;
  status: IAnalyzeStatus[];
}

// Date to String( YYYY-MM-DD )
export const getDateFormatter = (date: Date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth());
  const monthStr = month >= 10 ? month : '0' + month;
  let day = date.getDate();
  const dayStr = day >= 10 ? day : '0' + day;
  return year + '-' + monthStr + '-' + dayStr;
};

// String( YYYY-MM-DD ) to Date
export const getStringDateConvert = (dateStr: string) => {
  let result = null;
  if (dateStr.length > 0 ) {
    const yyyyMMdd = String(dateStr);
    const sYear = yyyyMMdd.substring(0,4);
    const sMonth = yyyyMMdd.substring(5,7);
    const sDate = yyyyMMdd.substring(8,10);
    result = new Date(Number(sYear), Number(sMonth)-1, Number(sDate));
  }
  return result;
}

type UserInfoType = {
  selectedUser: IUser | null;
  setSelectedUser: (user:IUser | null) => void
  //selectedUserStore: IMySmartStore | null;
 //setSelectedUserStore: (store: any) => void;
  searchParams: ISearchUserParams
  setSearchParams: (params: any) => void
}

export const UserContext = React.createContext<UserInfoType>({ 
  selectedUser: null, 
  setSelectedUser: () => {}, 
  //selectedUserStore: null, 
  //setSelectedUserStore: () => {}, 
  searchParams: initISearchUserParams, 
  setSearchParams: () => {} 
})
export const useUserContext = () => useContext(UserContext);

type ConstObjType = {
  [index: string]: string
}

export const ROLE_NAMES: ConstObjType = {
  'ROLE_ADMIN': '관리자',
  'ROLE_OPERATOR': '운영자',
  'ROLE_MANAGER': '매니저',
  'ROLE_USER': '사용자',
  'ROLE_DEVELOPER': '개발자',
  'ROLE_TESTER': '테스터'
}

export const constants = {
  ROLE_NAMES
};