import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { switchMap, map, catchError } from 'rxjs/operators';
import { createAction, handleActions } from 'redux-actions';
import epicService, {API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';
import { useState } from "react";

import { NEXT_MENUS as MENUS, IMenuParams } from 'components/common/constants';

// modules
import { BasicState, ICommonCode } from './types';

// actions
import * as actionTypes from './types/actions';

// ACTION PART
const action = {
  epicSucceeded: createAction(actionTypes.EPIC_SUCCESS),
  epicFailed: createAction(actionTypes.EPIC_FAIL),
  epicCanceled: createAction(actionTypes.EPIC_CANCEL),
  // login(id: string, pw: string)
  login: createAction(actionTypes.LOGIN),
  // loginResult
  loginSucceeded: createAction(actionTypes.LOGIN_SUCCEEDED),
  // loginFailed
  loginFailed: createAction(actionTypes.LOGIN_FAILED),
  // logout()
  logout: createAction(actionTypes.LOGOUT),
  // token refresh
  tokenRefresh: createAction(actionTypes.TOKEN_REFRESH),
  succeededTokenRefresh: createAction(actionTypes.SUCCEEDED_TOKEN_REFRESH),
  // set menu(menu: string)
  setMenu: createAction(actionTypes.SET_MENU),
  // upateUserInfo
  upateUserInfo: createAction(actionTypes.UPDATE_USER_INFO),

  // get common code
  getAllCommonCode: createAction(actionTypes.GET_ALL_COMMON_CODE),
  succeededAllCommonCode: createAction(actionTypes.SUCCEEDED_GET_ALL_COMMON_CODE),
  failedAllCommonCode: createAction(actionTypes.FAILED_GET_ALL_COMMON_CODE),
 
}

// REDUCER PART (used redux-actions)
const initialState = new BasicState(); // for immutablility
const reducer = handleActions<BasicState>(
  {
    [actionTypes.EPIC_SUCCESS]: (state, action): BasicState => {
      // do nothing

      return state
    },
    [actionTypes.EPIC_FAIL]: (state, action): BasicState => {
      // do nothing

      return state
    },
    [actionTypes.EPIC_CANCEL]: (state, action): BasicState => {
      // do noting

      return state
    },
    [actionTypes.LOGIN]: (state, action): BasicState => {
      console.log("action#LOGIN", action.payload);

      return state
        .updateIn(['loginResult', 'error'], () => null)
        .updateIn(['loginResult', 'loading'], () => true);
    },
    [actionTypes.LOGIN_SUCCEEDED]: (state, action): BasicState => {
      const account = action.payload;
      if (account) {
        localStorage.setItem('account', JSON.stringify(account));
        //localStorage.setItem('access-token', account.user.accessToken);
        //localStorage.setItem('refresh-token', account.user.refreshToken);
      }

      return state
        .set('user', action.payload.user)
        .set('menu', MENUS[0] as IMenuParams)
        .updateIn(['loginResult', 'error'], () => null)
        .updateIn(['loginResult', 'loading'], () => false);
    },
    [actionTypes.SUCCEEDED_TOKEN_REFRESH]: (state, action): BasicState => {
      console.log('action.payload:::', action.payload)
      const payload = action.payload as unknown as { data: { accessToken: string, refreshToken: string } };;
      return state
        .updateIn(['user', 'access-token'], () => payload.data.accessToken)
    },
    [actionTypes.LOGIN_FAILED]: (state, action): BasicState => {
      return state
        .updateIn(['loginResult', 'error'], () => action.payload)
        .updateIn(['loginResult', 'loading'], () => false);
    },
    [actionTypes.LOGOUT]: (state): BasicState => {
      localStorage.removeItem('account');
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');

      return state
        .set('user', null)
        .set('menu', null as any)
        .updateIn(['loginResult', 'error'], () => null)
        .updateIn(['loginResult', 'loading'], () => false);
    },
    [actionTypes.SET_MENU]: (state, action): BasicState => {
      return state
        .set('menu', action.payload as any);
    },
    [actionTypes.UPDATE_USER_INFO]: (state): BasicState => {
      const account = localStorage.getItem('account');
      let accountObj = null;
      if (account) {
        accountObj = JSON.parse(account);
      }

      if (!accountObj) return state;
      console.log('state ::', state);
      return state
        .set('menu', MENUS[0] as IMenuParams)
        .set('user', accountObj.user)
    },
        
    [actionTypes.GET_ALL_COMMON_CODE]: (state, action): BasicState => {
      return state
    },
    [actionTypes.SUCCEEDED_GET_ALL_COMMON_CODE]: (state, action): BasicState => {
      const data = action.payload.commonCodeList;      
      return state
        .set('commonCodeList', data)         
    },
  },
  initialState
);

// EPIC PART
// const requestLoginEpic = (action$: any) => 
//   action$.pipe(
//     ofType(actionTypes.LOGIN),
//     switchMap((req: any) => {
//       return ajax.post(
//         API_URL.LOGIN,
//         req.payload,
//         { 'Content-Type': 'application/json' }
//       ).pipe(
//         map(res => {
//           console.log("requestLoginEpic", res);

//           const user = res.response.data;
//           // if (user.userId === 'admin') {
//             if (user.authorities[0].authority === 'ROLE_ADMIN' || 
//                 user.authorities[0].authority === 'ROLE_OPERATOR' ) {
//               return action.loginSucceeded({
//                 user: user
//               });
//             }
//           // }

//           return action.loginFailed({
//             msg: 'Access Denied, no authority'
//           });
//         }),
//         catchError(e => {
//           console.log("requestLoginEpic", "error", e);
//           return of({
//             type: actionTypes.LOGIN_FAILED,
//             payload: e.xhr.response
//           })
//         })
//       )
//     })
//   );

  // const requestTokenRefresh = (actions$: any) => {
  //   const reqParams: IEpicReqParams = {
  //     actions: {
  //       request: actionTypes.TOKEN_REFRESH,
  //       success: (res: AjaxResponse) => action.succeededTokenRefresh({
  //         data: res.response.data
  //       }),
  //     },
  //     url: API_URL.TOKEN_REFRESH,
  //     method: AjaxMethods.POST,
  //     async: false,
  //   };
  //   return epicService.request(actions$, reqParams);
  // }


const requestCommonCodeEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_ALL_COMMON_CODE,
      success: (res: AjaxResponse) => action.succeededAllCommonCode({ commonCodeList: res.response.data }),
      fail: actionTypes.FAILED_GET_ALL_COMMON_CODE
    },
    url: API_URL.CODE,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}
  


const epic = combineEpics(
  //requestLoginEpic,
  //requestTokenRefresh,
  requestCommonCodeEpic,
);

export { action, epic };
export default reducer;