import React from 'react';

import { Image } from 'semantic-ui-react'
import Popover from '@material-ui/core/Popover';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

interface IProps {
  imgSrc: string;
}

const ImagePreview : React.FC<IProps> = (props) => {
  const {children, imgSrc} = props

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);    
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div >
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: (window.screen.height/5), left: (window.screen.width/2.5)}}
        style={{maxWidth:'70%'}}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        transitionDuration='auto'
        disableRestoreFocus
      >
        <Image src={imgSrc} />        
      </Popover>
    </div>
  );
}

export default ImagePreview;