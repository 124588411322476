import React, { useEffect, useState } from 'react';
// Hooks
import useFaq from 'hooks/useFaq';
// Components
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
import { Form, TextArea } from 'semantic-ui-react';
// Styled
import { FaqDetailStyles as Styled } from '../styles/FaqDetail.styles';


interface IProps {
  faqId: number;
  faqCode: any[];
  open: boolean;
  onClose(): void;
}
const FaqDetial: React.FC<IProps> = (props) => {

  const { faqId, faqCode, open, onClose } = props;
  const { requestGetFaq } = useFaq();
  const [ detailData, setDetailData ] = useState<any>();

  useEffect(() => {
    if (faqId) {
      const payload: any = {
        pathVariables: { faqId },
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            const result = res.response.data;
            setDetailData(result);
          }
        }
      }
      requestGetFaq(payload);
    }
    else {
      console.log(" 시스템 오류입니다. ");
    }
  }, []);

  const categoryValueToText = (value : string) => {
    if(faqCode){
      const matchedItem = faqCode.filter(item => item.value === value);
      return matchedItem.length > 0 ? matchedItem[0].text : 'none';
    }
    return 'none';
  };
  const faqTypeName = detailData != undefined ? categoryValueToText(detailData.faqType) : "";

  const detailView = detailData != undefined ?
    <>
      <Form>
        <Form.Group widths='equal'>
          <Form.TextArea 
            label="카테고리"
            value={faqTypeName}
            style={{ 
              height: '50px', 
              backgroundColor: 'rgb(240, 240, 240)', 
              overflowY: 'scroll' 
            }}
            readOnly
          />
          <Form.Checkbox 
            label='게시글 노출 여부' 
            checked={detailData.viewYn}
            style={{ float: 'right'}}
            readOnly
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.TextArea
            label='제목'
            value={detailData.faqTitle}
            style={{ 
              height: '50px',
              backgroundColor: 'rgb(240, 240, 240)',
              overflowY: 'scroll' 
            }}
            readOnly
          />
        </Form.Group>
        내용
        <Styled.HtmlContents
          dangerouslySetInnerHTML={{ __html: detailData.faqContents }}
        />
      </Form>
    </>
  : null;

  return (
    <>
      <BasePopup
        open={open}
        id={faqId}
        size='large'
        type={PopupTypes.CONFIRM}
        title='FAQ 상세'
        buttons={[
          {onClick: onClose}
        ]}
        onClose={onClose}
      >
        {detailView}
      </BasePopup>
    </>
  )
}
export default FaqDetial;