import React, { useEffect, useState } from 'react'
// components
import Card from './Card'
import List from './List';
// lib
import moment from 'moment';
// styles
import { UserInfoStyles as Styled } from './styles/UserInfo.styles'
import useDashBoard from 'hooks/useDashBoard';
import { IDashboardUserStatus, initDashboardUserStatus, IPopupSearchData, TDashboardPopupType, IJoinUserList, IDashboardPopupListProps, IJoinUser, IVisitorUserList, IVisitUser, IUserStoreList, IStoreUser } from './interface';
import UserInfoCardDatas from './UserInfoCardDatas';
import JoinUserChart from './chart/JoinUserChart';
import VisitorChart from './chart/VisitorChart';
import { IPage } from 'store/modules/types';
import { PARTNERSHIP } from 'util/constants';

interface IProps {
  searchDate: string;
  onChangeStringDate: (date: string) => void;
  popupData: IPopupSearchData;
  popupOpen: () => void;
  setPopupType: (popupType: TDashboardPopupType) => void;
  setPopupDatas: (totalContentsCount: number, totalPageNumber: number, listData: IDashboardPopupListProps) => void;
}

const UserInfo: React.FC<IProps> = (props) => {
  const { searchDate, onChangeStringDate, popupData, popupOpen, setPopupType, setPopupDatas } = props;
  const { 
    requestGetDashboardUser,
    requestGetDashboardUserMore,
    requestGetDashboardUserLoginLogMore,
  } = useDashBoard();

  const [ userStatus, setUserStatus ] = useState<IDashboardUserStatus>(initDashboardUserStatus);

  useEffect(() => {
      if (popupData.popupType === 'user-join') {
        onUserJoinList();
      }
      
      if (popupData.popupType === 'user-visitors') {
        onUserVisitorList();
      }
      
      // if (popupData.popupType === 'user-store') {
      //   onUserStoreList();
      // }
  }, [popupData.popupType, popupData.startDate, popupData.endDate, popupData.pageNumber])

  useEffect(() => {
    const payload: any = {
      queryParameters: {
        searchDt: searchDate,
      },
      callback: (succeeded: boolean, res:any) => {
        if (succeeded) {
          setUserStatus(res.response.data)
        }
      }
    }
    requestGetDashboardUser(payload)
  }, [searchDate])

  const onUserJoinList = () => {
    const payload: any = {
      queryParameters: {
        startDt: popupData.startDate,
        endDt: popupData.endDate,
        page: popupData.pageNumber,
        size: 10,
      },
      callback: (succeeded: boolean, res:any) => {
        if (succeeded) {
          const resData = res.response.data;
          const page = resData.page as IPage
          const list = resData.list
          setPopupDatas(
            page.totalPages,
            page.totalElements,
            {
              headers: ['No.', 'UserId', 'UserName', '고객번호', '셀러정보', '전화번호', '가입경로', '가입일자', '제휴'],
              contents: getPopupJoinUserListData(list),
              flex: [0.5, 1.5, 1, 1.5, 1.5, 1.5, 0.5, 1.7, 1.7],
              defaultMsg: '해당 기간 가입자 없음',
              alignLeftItemCount: 5,
              fillHeadLine: true,
            }
          );
        }
      }
    }

    requestGetDashboardUserMore(payload)
  }

  const onUserVisitorList = () => {
    requestGetDashboardUserLoginLogMore({
      queryParameters: {
        startDt: popupData.startDate,
        endDt: popupData.endDate,
        page: popupData.pageNumber,
        size: 10,
      },
      callback: (succeeded: boolean, res:any) => {
        if (succeeded) {
          const resData = res.response.data;
          const page = resData.page as IPage;
          const list = resData.list;
          setPopupDatas(
            page.totalPages,
            page.totalElements,
            {
              headers: ['No.', 'UserId', 'UserName', '고객번호', '셀러정보', '접속환경', '방문일자', '방문횟수', '제휴'],
              contents: getPopupVisitorUserListData(list),
              flex: [0.5, 1, 1, 1, 1, 0.5, 1.5, 0.5, 1],
              defaultMsg: '해당 기간 방문자 없음',
              alignLeftItemCount: 5,
              fillHeadLine: true,
            }
          );
        }
      },

      async: true,
    })
  };

  const formattedPhone = (phone: string) => {
    return phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
  }

  const formattedUserId = (userId: string) => {
    return userId.replace(/\@.*/, '...');
  }

  const getPopupJoinUserListData = (list: IJoinUser[]) => {
    return list.map((data, index) => {
      return [
        ((popupData.pageNumber - 1) * popupData.searchPageSize) + (index + 1),
        formattedUserId(data.userId),
        data.userName,
        data.customerId,
        data.customerName,
        formattedPhone(data.phone),
        //data.email,
        data.clientType,
        new Date(data.regDt).toLocaleString(),
        PARTNERSHIP[data.cooperationType]
      ]
    })
  }

  const joinUserListData = userStatus.user.userDetails.list.map((data) => {
    return [
      formattedUserId(data.userId),
      data.userName,
      data.customerId,
      data.customerName,      
      formattedPhone(data.phone),
      data.clientType,
      //data.email,
      new Date(data.regDt).toLocaleString(),
      PARTNERSHIP[data.cooperationType]
    ]
  })

  const getPopupVisitorUserListData = (list: IVisitUser[]) => {
    return list.map((data, index) => {
      return [
        ((popupData.pageNumber - 1) * popupData.searchPageSize) + (index + 1),
        formattedUserId(data.userId),
        data.userName,
        data.customerId,
        data.customerName,
        data.clientType,
        new Date(data.regDt).toLocaleString(),
        data.loginCount.toLocaleString(),
        PARTNERSHIP[data.cooperationType],
      ]
    })
  }

  const visitorListData = userStatus.userLogin.userLoginDetails.list.map((data) => {
    return [
      formattedUserId(data.userId),
      data.userName,
      data.customerId,
      data.customerName,
      data.clientType,
      new Date(data.regDt).toLocaleString(),
      data.loginCount.toLocaleString(),
      PARTNERSHIP[data.cooperationType]
    ]
  })

  const getElementAtEvent = (element: any) => {
    if (!element.length) return;

    const { _index } = element[0];

    const date = searchDate.split('-');
    onChangeStringDate(`${date[0]}-${date[1]}-${_index + 1 < 10 ? `0${_index + 1}` : _index + 1}`);
  };

  const onJoinMore = () => {
    setPopupType('user-join');
    popupOpen();
  }

  const onVisitorMore = () => {
    setPopupType('user-visitors');
    popupOpen();
  }

  const onStoreMore = () => {
    //setPopupType('user-store');
    popupOpen();
  }

  return (
    <Styled.UserInfo>
      <Styled.Section>
        <UserInfoCardDatas userStatus={userStatus} />
      </Styled.Section>
      <Styled.TitleWrap>
        <Styled.Title>{`오늘 ( ${searchDate} ) 가입자 리스트 (${userStatus.dayUserCount.total.toLocaleString()})`}</Styled.Title>
        <Styled.MoreButton onClick={onJoinMore}>{'더보기 >'}</Styled.MoreButton>
      </Styled.TitleWrap>
      <Styled.Section>
        <List 
          headers={['UserId', 'UserName', '고객번호', '셀러정보', '전화번호', '가입경로', '가입일자', '제휴']}
          contents={joinUserListData}
          flex={[1.5, 1.5, 1.5, 1.5, 1.5, 1, 1.7, 2.5, 1.7]}
          defaultMsg='오늘 가입자 없음'
          alignLeftItemCount={4}
          fillHeadLine
        />
      </Styled.Section>
      <Styled.Title>월간 가입자 추이</Styled.Title>
      <Styled.Section>
        <JoinUserChart
          searchDate={searchDate}
          userStatus={userStatus}
          getElementAtEvent={getElementAtEvent}
        />
      </Styled.Section>
      <Styled.TitleWrap>
        <Styled.Title>{`오늘 ( ${searchDate} ) 방문자 리스트 (${userStatus.dayLoginCount.total.toLocaleString()})`}</Styled.Title>
        <Styled.MoreButton onClick={onVisitorMore}>{'더보기 >'}</Styled.MoreButton>
      </Styled.TitleWrap>
      <Styled.Section>
        <List 
          headers={['UserId', 'UserName', '고객번호', '셀러정보', '접속환경', '방문일자', '방문횟수', '제휴']}
          contents={visitorListData}
          flex={[1, 1, 1, 1, 0.5, 1, 1, 1]}
          defaultMsg='오늘 방문자 없음'
          alignLeftItemCount={4}
          fillHeadLine
        />
      </Styled.Section>
      <Styled.Title>월간 방문자 추이</Styled.Title>
      <Styled.Section>
        <VisitorChart
          searchDate={searchDate}
          userStatus={userStatus}
          getElementAtEvent={getElementAtEvent}
        />
      </Styled.Section>
     {/* <Styled.TitleWrap>
        <Styled.Title>{`오늘 ( ${searchDate} ) 스토어 등록 리스트 (${userStatus.storeStatus.adminListDto.dailyCount.toLocaleString()})`}</Styled.Title>
        <Styled.MoreButton onClick={onStoreMore}>{'더보기 >'}</Styled.MoreButton>
      </Styled.TitleWrap>
      <Styled.Section>
        <List 
          headers={['UserId', '고객번호', '셀러정보', '스토어 구분', '스토어명', '스토어 URL', '등록일자']}
          contents={storeListData}
          flex={[1, 1, 1, 1, 2.5, 2.5, 1]}
          defaultMsg='오늘 등록된 스토어'
          alignLeftItemCount={6}
          fillHeadLine
        />
      </Styled.Section>
       <Styled.Title>월간 스토어 등록 추이</Styled.Title>
      <Styled.Section>
        <UserStoreChart
          searchDate={searchDate}
          userStatus={userStatus}
          getElementAtEvent={getElementAtEvent}
        />
      </Styled.Section> */}
    </Styled.UserInfo>
  )
}

export default UserInfo