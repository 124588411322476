import { useDispatch, useSelector } from 'react-redux'
// Modules
import { action } from 'store/modules/email';
import { ActionPayload, StoreState } from 'store/modules/types';

const useEmail = () => {

  const dispatch = useDispatch();

  const email = useSelector((state: StoreState) => state.email);

  // 이메일 별 전송
  const sendEmailBulkByEmails = (payload: ActionPayload) => {
    dispatch(action.sendEmailBulkByEmails(payload));
  }

  // ROLE별 전송 (유저 전체전송)
  const sendEmailBulkByRole = (payload: ActionPayload) => {
    dispatch(action.sendEmailBulkByRole(payload));
  }

  const requestGetUserSendLogs = () => {
    dispatch(action.requestGetUserSendLogs());
  }

  const requestGetUserSendDetailLogs = (payload: ActionPayload) => {
    dispatch(action.requestGetUserSendDetailLogs(payload));
  }

  return {
    email,
    sendEmailBulkByEmails,
    sendEmailBulkByRole,
    requestGetUserSendLogs,
    requestGetUserSendDetailLogs,
  }
}

export default useEmail
