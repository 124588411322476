import React, { useState } from 'react'
// Common
import TableList, { ITableListButton, ITableListProps } from 'components/common/table/TableList';
// Components
import EmailTemplateListItem from './EmailTemplateListItem';
// Interface
import { EmailTemplateItem } from 'store/modules/types';
// Components
import EmailTemplateDetail from './popup/EmailTemplateDetail';
import EmailTemplateWrite from './popup/EmailTemplateWrite';
import EmailTemplateDelete from './popup/EmailTemplateDelete';
// Lib
import { Slide, Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

export type popuptype = 'detail' | 'write' | 'delete';

export interface PopupMode {
  itemNumber: number,
  item: EmailTemplateItem,
  mode: popuptype
}

interface IProps {
  list: EmailTemplateItem[];
  isLoading: boolean;
}

const EmailTemplateList: React.FC<IProps> = (props) => {

  const { list, isLoading } = props;

  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ resultMsg, setResultMsg] = useState('');
  const [ openPopup, setOpenPopup ] = useState<boolean>(false);
  const [ templatePopupMode, setTemplatePopupMode ] = useState<PopupMode>({
    itemNumber: 0,
    item: {
      regDt: '',
      updateDt: '',
      templateId: '',
      templateName: '',
      template: '',
    },
    mode: 'detail'
  });

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  const onAddButtonClicked = () => {
    setTemplatePopupMode({
      ...templatePopupMode,
      itemNumber: 0,
      item: {
        regDt: '',
        updateDt: '',
        templateId: '',
        templateName: '',
        template: '',
      },
      mode: 'write',
    });
    setOpenPopup(true);
  }

  const title = <div style={{ font:'inherit', textAlign:'left' }}>제목</div>;
  const headers = [title, '수정일', '등록일', ''];
  const headerWidth = [4, 1, 1, 1];
  const buttons: ITableListButton[] = [
    {
      name: '템플릿 등록하기',
      icon: 'add',
      onButtonClicked: onAddButtonClicked,
    },
  ]

  const onOpenTemplateDetail = (itemNumber: number, item: EmailTemplateItem, mode: popuptype) => {
    setTemplatePopupMode({
      ...templatePopupMode,
      itemNumber: itemNumber,
      item: item,
      mode: mode,
    });
    setOpenPopup(true);
  }

  const onClosePopup = () => {
    setOpenPopup(false);
  }

  const detailPopup = openPopup && templatePopupMode.mode === 'detail' &&
    <EmailTemplateDetail 
      itemNumber={templatePopupMode.itemNumber}
      open={openPopup}
      onClose={onClosePopup}
      item={templatePopupMode.item}
    />

  const writePopup = openPopup && templatePopupMode.mode === 'write' &&
    <EmailTemplateWrite 
      itemNumber={templatePopupMode.itemNumber}
      open={openPopup}
      onClose={onClosePopup}
      item={templatePopupMode.item}
      showFeedback={(msg: string) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />

  const deletePopup = openPopup && templatePopupMode.mode === 'delete' &&
    <EmailTemplateDelete 
    templateNumber={templatePopupMode.itemNumber}
      open={openPopup}
      onClose={onClosePopup}
      templateId={templatePopupMode.item.templateId}
      showFeedback={(msg: string) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />

  const itemList = !isLoading &&
    list.map((item: EmailTemplateItem, index: number) => (
      <EmailTemplateListItem 
        key={index}
        item={item} 
        itemNumber={index + 1} 
        onTemplatePopupOpen={onOpenTemplateDetail}
        setOpenTemplatePopup={setOpenPopup}
        setTemplatePopupMode={setTemplatePopupMode}
      />
    ))

  const tableListProps: ITableListProps = {
    title: 'E-mail 템플릿',
    headers,
    headerWidth,
    color: 'violet',
    count: list.length || 0,
    buttons,
    // refresh: () => {}
  }

  return (
    <>
      <TableList {...tableListProps}>
        {itemList ? itemList : '검색 결과가 없습니다.'}
      </TableList>
      
      {detailPopup}
      {writePopup}
      {deletePopup}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}
const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default EmailTemplateList
