import React, { useState ,SyntheticEvent } from 'react';

// Components
import UserPasswordChange from 'components/users/popup/UserPasswordChange';

// Styles
import { AppHeaderMenuStyles as Styled } from './styles/AppHeaderMenu.styles';

// Hooks
import useBasics from 'hooks/useBasics';
import { Avatar, Menu, MenuItem, IconButton, Typography, Breadcrumbs } from '@material-ui/core';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

// Material
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { API_URL } from 'store/modules/types/epics';
import { constants } from 'components/users/interface';

interface IMenuProps {
  authenticated: boolean;
}

interface IMenuItemProps {
  authenticated: boolean;
  menu: string;
  userInfo: any;
  onMenuClick(e: SyntheticEvent, item: any): void
}

interface ISubMenuItemProps {
  name: string;
  items: string[];
}

const AppHeaderMenu: React.FC<IMenuProps> = (props: IMenuProps) => {
  const { authenticated } = props;
  const { basics, changeMenu, onLogout } = useBasics();
  const { user, menu } = basics;
  const { authorities } = user;
  const userAuth = authorities[0] != undefined ? authorities[0].authority : '';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const [ popupOpen, setPopupOpen ] = useState(false);
  const [ snackbarOpen, setSnackbarOpen ] = useState(false);
  const [ resultMsg, setResultMsg ] = useState('');

  const onPopupClose = () => {
    setPopupOpen(false);
  };

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  const userInfo = {
    nickName: (user && user.userName) || '',
    loginId: (user && user.userId) || '',
    authority: (user && user.authorities[0].authority) || '',
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    fetch(`${API_URL.LOGOUT}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include',
    }).then(res => res.json()
    ).then((res:any) => {
      onLogout();      
    }).catch(e => {})
  };

  const handelPasswordChange = () => {
    setAnchorEl(null);
    setPopupOpen(true);
  }

  const passwordChangeItem = userAuth !== 'ROLE_ADMIN' ?
    <MenuItem onClick={handelPasswordChange}>비밀번호 변경</MenuItem>
  : null;

  let role = '';
  if (constants.ROLE_NAMES[userAuth]) {
    role=constants.ROLE_NAMES[userAuth]
  }

  return (
    <>
      <Styled.RootDiv>
        <Styled.NavDiv>
          <Breadcrumbs separator="-" aria-label="breadcrumb">
            <Typography style={{ fontWeight: 800 }}>ADMIN</Typography>
            <Typography style={{ fontWeight: 600 }}>{menu?.name}</Typography>
          </Breadcrumbs>
        </Styled.NavDiv>
        <Styled.OptionDiv>
          <span>{`${userInfo.nickName} ${role} 님`}</span>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <ArrowDropDownOutlinedIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
          >
            {passwordChangeItem} 
            <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
          </Menu>
        </Styled.OptionDiv>
      </Styled.RootDiv>

      <UserPasswordChange
        user={user}
        open={popupOpen}
        onClose={onPopupClose}
        showFeedback= {(msg:string) => {
          setResultMsg(msg);
          setSnackbarOpen(true);
        }}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}
const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}
export default AppHeaderMenu;