import React, { useEffect } from 'react';

// Components
import QnaList from './QnaList';

import useBasics from 'hooks/useBasics';
import useQna from 'hooks/useQna';

// CSS
import { initRequestParams, IRequestQnaListParams } from './interfaces';
import { convertCommonCode } from 'util/common';

interface Icategories {
  value: string;
  text: string;
}

const Faq: React.FC = () => {

  const { basics } = useBasics();
  const { user } = basics;
  const { qna, requestQnaList } = useQna();
  const { qnaData } = qna;
  const { commonCodeList } = basics;
  
  useEffect(() => {
    onRequest();
  }, []);

  const onRequest = (params?: any) => {   
    let tempParams: IRequestQnaListParams = params ? params : initRequestParams;

    tempParams = {
      ...tempParams,
      //userId: user.userId
    }

    // console.log('params ::', params);
    requestQnaList({
      queryParameters: tempParams
    });
  }

  // console.log('commonCodeList ; ', commonCodeList.faq_type);
  
  return (
    // <FaqList faqCode={faqCode} faqList={faqList} refresh={requestFaqList} />
    <section className="qna">
      <QnaList 
        category={convertCommonCode(commonCodeList.faq_type)} 
        page={qnaData.page} 
        list={qnaData.list} 
        onRequest={onRequest} 
      />
    </section>
  )
}

export default Faq;