import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { GuideState } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';


// ACTION PART
const action = {
    // getGuideList
    getGuideList: createAction(actionTypes.GET_GUIDE_LIST),
    fulfilledGuideList: createAction(actionTypes.FULFILLED_GUIDE_LIST),
    failedGuideList: createAction(actionTypes.FAILED_GUIDE_LIST),

    // insertGuide
    insertGuide: createAction(actionTypes.INSERT_GUIDE),
    succeededInsertGuide: createAction(actionTypes.SUCCEEDED_INSERT_GUIDE),
    failedInsertGuide: createAction(actionTypes.FAILED_INSERT_GUIDE),

    // modifyGuide
    modifyGuide: createAction(actionTypes.MODIFY_GUIDE),
    succeededModifyGuide: createAction(actionTypes.SUCCEEDED_MODIFY_GUIDE),
    failedModifyGuide: createAction(actionTypes.FAILED_MODIFY_GUIDE),

    // dleteGuide
    deleteGuide: createAction(actionTypes.DELETE_GUIDE),
    succeededDeleteGuide: createAction(actionTypes.SUCCEEDED_DELETE_GUIDE),
    failedDeleteGuide: createAction(actionTypes.FAILED_DELETE_GUIDE),

  }
  
  const initialState: GuideState = {
    guideList: [],
  };
  
  const reducer = handleActions<GuideState>(
    {
      [actionTypes.GET_GUIDE_LIST]: (state, action): GuideState => {
        return state
      },
      [actionTypes.FULFILLED_GUIDE_LIST]: (state, action): GuideState => {
        return {
          ...state,
          guideList: action.payload.guideList
        }
      },
      [actionTypes.FAILED_GUIDE_LIST]: (state, action): GuideState => {
        return {
          ...state,
        }
      },
      [actionTypes.INSERT_GUIDE]: (state, action): GuideState => {
        return state
      },
      [actionTypes.SUCCEEDED_INSERT_GUIDE]: (state, action): GuideState => {
        return {
          ...state,
        }
      },
      [actionTypes.FAILED_INSERT_GUIDE]: (state, action): GuideState => {
        return {
          ...state,
        }
      },
      [actionTypes.MODIFY_GUIDE]: (state, action): GuideState => {
        return state
      },
      [actionTypes.SUCCEEDED_MODIFY_GUIDE]: (state, action): GuideState => {
        return {
          ... state,
        }
      },
      [actionTypes.FAILED_MODIFY_GUIDE]: (state, action): GuideState => {
        return {
          ... state,
        }
      },
      [actionTypes.DELETE_GUIDE]: (state, action): GuideState => {
        return state
      },
      [actionTypes.SUCCEEDED_DELETE_GUIDE]: (state, action): GuideState => {
        return {
          ... state,
        }
      },
      [actionTypes.FAILED_DELETE_GUIDE]: (state, action): GuideState => {
        return {
          ... state,
        }
      }
    },
    initialState
  );
  
  // EPIC PART
  const requestGuideListEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_GUIDE_LIST,
        success: (res: AjaxResponse) => action.fulfilledGuideList({ guideList: res.response.data.list }),
        fail: actionTypes.FAILED_GUIDE_LIST
      },
      url: API_URL.USER_GUIDE,
      method: AjaxMethods.GET
    };
  
    return epicService.request(actions$, reqParams);
  }

  const insertGuideEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.INSERT_GUIDE,
        success: (res: AjaxResponse) => action.getGuideList({ guideList: res.response.list }),
        fail: actionTypes.FAILED_INSERT_GUIDE
      },
      url: API_URL.USER_GUIDE,
      method: AjaxMethods.POST
    };
    return epicService.request(actions$, reqParams);
  }

  const modifyGuideEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.MODIFY_GUIDE,
        success: (res: AjaxResponse) => action.getGuideList({ guideList: res.response.list }),
        fail: actionTypes.FAILED_MODIFY_GUIDE
      },
      url: API_URL.USER_GUIDE,
      method: AjaxMethods.PUT
    };
    return epicService.request(actions$, reqParams);
  }

  const deleteGuideEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.DELETE_GUIDE,
        success: (res: AjaxResponse) => action.getGuideList({ guideList: res.response.list }),
        fail: actionTypes.FAILED_DELETE_GUIDE
      },
      url: API_URL.USER_GUIDE,
      method: AjaxMethods.DELETE
    };
    return epicService.request(actions$, reqParams);
  }

  
  const epic = combineEpics(
    requestGuideListEpic,
    insertGuideEpic,
    modifyGuideEpic,
    deleteGuideEpic,
  );
  
  export { action, epic };
  export default reducer;