import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
`

const TableContainer = styled.div`
  flex-direction: column;
  justify-content: space-around;
`

const TableListDiv = styled.div`
  min-width: 600px;
  margin: 0 10px;
  position: relative;
`

const UserInfoMarketDiv = styled.div`
  flex: 1;
  margin-right: 10px;
`

const UserSalesSummaryDiv = styled.div`
  flex: 0.6;
  margin: 0 10px;
`

export const UserListStyled = {
  Container,
  TableContainer,
  TableListDiv,
  UserInfoMarketDiv,
  UserSalesSummaryDiv,
}