import React, { useEffect, useState } from 'react'
// lib
import { makeStyles } from '@material-ui/core';
import Flatpickr from "react-flatpickr";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
// styles
import { DatePickStyles as Styled } from './styles/DatePick.styles'
import 'flatpickr/dist/themes/material_blue.css';
import { ISearchParams, useSearchParamsContext } from './interfaces';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 220,
  },
  monthButton: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  }
}))


interface IProps {
}

const DatePick: React.FC<IProps> = (props) => {

  const { searchParams, setSearchParams } = useSearchParamsContext();
  const { startDate, endDate } = searchParams;
  
  const classes = useStyles();

  const onChangeDate = (dates: Date[]) => {
    const date: string[] = dates.map((date: any) => moment(date).format('YYYY-MM-DD'));
    const [startDate, endDate] = [...date];
   
    setSearchParams({
      ...searchParams,
      startDate,
      endDate,
    });
  }

  const onClose = (dates: Date[]) => {
    const [ startDate, endDate ] = (dates.length === 1 ? dates.concat(dates) : dates).map(date => moment(date).format('YYYY-MM-DD'));

    setSearchParams({
      ...searchParams,
      startDate,
      endDate,
    });
  };  

  const dateRange: string = `${startDate} ~ ${endDate}`;

  return (
    <Styled.Wrap>
      <Flatpickr
        value={dateRange}
        onChange={onChangeDate}
        onClose={onClose}
        options={{
          mode: "range",
          locale: 'ko',
          enableTime: false,
          conjunction: '~',
          dateFormat: 'Y-m-d',
          closeOnSelect: false,
        }}
        render={
          ({defaultValue, value, ...props}, ref) => {
            return (
              <TextField
                id="date"
                label="Date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={defaultValue}
                inputRef={ref}
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="end" style={{ zIndex: -1, position: 'relative'}} >
                      <TodayIcon />
                    </InputAdornment>
                }}
              />
            )
          }
        }
      />
    </Styled.Wrap>
  )
}

export default DatePick
