import React, { SyntheticEvent, useState } from 'react';

import { Label, LabelProps } from 'semantic-ui-react';

// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
// import UserModify from './popup/UserModify';
import QnaDelete from './popup/QnaDelete';
import { IQnaListItem } from './interfaces';


// Hooks
interface IProps {
  category: any[];
  user: any;
  qna: IQnaListItem;
  requestParams: any;
  onShowDetail(detailNo: number, status: number, delYn: boolean): void;
  setIsDeleted: Function;
  onRequest: Function;
  showFeedback: { (msg: string): void };
}

const QnaListItem: React.SFC<IProps> = (props) => {
  const { category, user, requestParams, qna, onShowDetail, setIsDeleted, onRequest, showFeedback } = props;
  // QnA 데이터 속성과 매핑
  const { qnaId, qnaCategoryType, qnaTitle, userId, regDt, status, fileYn, delYn } = qna;


  const [open, setOpen] = useState(false);
  const [popupName, setPopupName] = useState(null);

  const onClose = () => {
    console.log("onClose");
    setOpen(false);
  }

  const onDeleteButtonClicked = (e: SyntheticEvent, item: any) => {
    console.log('onButtonClicked', e, item);

    const id = (item && item.data) || undefined;
    if (id) {
      /*if (item.name === 'edit') {
        setPopupProps({
          id,
          title: 'edit user',
          content: ''
        } as IPopupProps);
      }*/

      setPopupName(item.name);
      setOpen(true);
    }
  };
  
  // console.log('category : ', category);
  const categoryValueToText = (value : string) => {
    if(category){
      const matchedItem = category.filter(item => item.value === value)
      return matchedItem.length > 0 ? matchedItem[0].text : 'none';
    }
    return 'none';
  }


  // const role = (authorities && authorities[0] && authorities[0].authority) || '';
  const socialIdSeperatorIndex: number = userId ? userId.indexOf('@@') : -1;
  const parsedUserId: string = userId && socialIdSeperatorIndex !== -1 ? userId.substring(0, socialIdSeperatorIndex) : userId ? userId  : '';
  const regDate = new Date(regDt).toLocaleString();
  const tableListItemsProps: ITableListItemProps = {
    id: qnaId,
    items: [
      statusLabel(status, delYn), 
      categoryValueToText(qnaCategoryType), 
      (
        <div className="qna-title cursor-pointer" onClick={()=>onShowDetail(qnaId, status, delYn)}>
          {qnaTitle}
          <span className={fileYn ? "file-clip" : ""} />
        </div>
      ),
      parsedUserId, 
      regDate
    ],
    iconButtons: getButtons(onDeleteButtonClicked, delYn)
  }

  return (
    <>
      <TableListItem {...tableListItemsProps} />
      {/* <UserModify
        user={user}
        open={open && popupName === 'edit'}
        onClose={onClose}
        showFeedback={showFeedback}
      /> */}
      <QnaDelete
        id={qnaId}
        open={open && popupName === 'delete'}
        requestParams={requestParams}
        onClose={onClose}
        onRequest={onRequest}
        showFeedback={showFeedback}
      />
    </>
  );
}

const statusLabel = (status: number, delYn?: boolean) => {
  // const roleData = role.split('_');
  let roleData = '';

  let color: LabelProps['color'] = 'red';
  switch (status) {
    case 1:
      color = 'teal';
      roleData = '미확인';
      break;
    case 2:
      color = 'blue';
      roleData = '처리중';
      break;
    case 3:
      color = 'red';
      roleData = '답변완료';
      break;
  }
  if(delYn){
    color = 'grey';
    roleData = '삭제됨';
  }

  return (
    <>
      <Label color={color} ribbon style={{ fontSize: '0.75rem', width: '100px' }}>{roleData}</Label>
    </>
  )
}

const getButtons = (onClicked: any, isDeleted?: boolean) => {
  const iconButtons: ITableListItemIconButton[] = [];
  const deleteButton: ITableListItemIconButton = {
    color: 'red',
    iconName: 'delete',
    tooltip: 'Delete QnA' ,
    onItemClicked: onClicked
  }  
  if(!isDeleted)
    iconButtons.push(deleteButton);

  return iconButtons;
}

export default QnaListItem;