import React from 'react';

import { Modal, Image } from 'semantic-ui-react';
import { Button, Typography } from '@material-ui/core';
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
// Config
import { SERVER_URL } from 'store/modules/types/epics';

interface IProps {
    guide:any;
    open: boolean;
    onClose(): void;
}

const GuideImageView: React.SFC<IProps> = (props) => {

    const { guide, open, onClose } = props;
    const { no, imageName, imagePath, type, sort, viewYn } = guide;

    let image =  guide !== null ? SERVER_URL + imagePath + imageName : null;

    return (
        <BasePopup
            open={open}
            id={no}
            size='large'
            type={PopupTypes.BASIC}
            buttons={[{}]}
            onClose={onClose}
            >
            <Modal.Content>
                <Image src={image} fluid />
            </Modal.Content>
        </BasePopup>
    );
}


export default GuideImageView;