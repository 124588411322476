import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { EmailState } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse, ajax } from 'rxjs/ajax';

// ACTION PART
const action = {
  sendEmailBulkByEmails: createAction(actionTypes.SEND_EMAIL_BULK_BY_EMAILS),
  succeededSendEmailBulkByEmails: createAction(actionTypes.SUCCEEDED_SEND_EMAIL_BULK_BY_EMAILS),
  failedSendEmailBulkByEmails: createAction(actionTypes.FAILED_SEND_EMAIL_BULK_BY_EMAILS),

  sendEmailBulkByRole: createAction(actionTypes.SEND_EMAIL_BULK_BY_ROLE),
  succeededSendEmailBulkByRole: createAction(actionTypes.SUCCEEDED_SEND_EMAIL_BULK_BY_ROLE),
  failedSendEmailBulkByRole: createAction(actionTypes.FAILED_SEND_EMAIL_BULK_BY_ROLE),

  requestGetUserSendLogs: createAction(actionTypes.GET_USER_SEND_LOGS),
  succeededGetUserSendLogs: createAction(actionTypes.SUCCEEDED_GET_USER_SEND_LOGS),
  failedGetUserSendLogs: createAction(actionTypes.FAILED_GET_USER_SEND_LOGS),

  requestGetUserSendDetailLogs: createAction(actionTypes.GET_USER_SEND_DETAIL_LOGS),
  succeededGetUserSendDetailLogs: createAction(actionTypes.SUCCEEDED_GET_USER_SEND_DETAIL_LOGS),
  failedGetUserSendDetailLogs: createAction(actionTypes.FAILED_GET_USER_SEND_DETAIL_LOGS),
}
//
const initialState = new EmailState();

const reducer = handleActions<EmailState>(
  {
    [actionTypes.SUCCEEDED_GET_USER_SEND_LOGS]: (state, action): EmailState => {
      const payload: any = action.payload
      return state
        .set('logList', payload.list);
    },
    [actionTypes.SUCCEEDED_GET_USER_SEND_DETAIL_LOGS]: (state, action): EmailState => {
      const payload: any = action.payload
      return state
        .set('sendList', payload.list);
    },
  },
  initialState
);

// EPIC PART
const sendEmailBulkByEmailsEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.SEND_EMAIL_BULK_BY_EMAILS,
      success: (res: AjaxResponse) => action.succeededSendEmailBulkByEmails(),
      fail: actionTypes.FAILED_SEND_EMAIL_BULK_BY_EMAILS
    },
    url: API_URL.EMAIL_SEND,
    method: AjaxMethods.POST
  };
  
  return epicService.request(actions$, reqParams);
}

const sendEmailBulkByRoleEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.SEND_EMAIL_BULK_BY_ROLE,
      success: (res: AjaxResponse) => action.succeededSendEmailBulkByRole(),
      fail: actionTypes.FAILED_SEND_EMAIL_BULK_BY_ROLE
    },
    url: API_URL.EMAIL_SEND,
    method: AjaxMethods.POST
  };
  
  return epicService.request(actions$, reqParams);
}

const requestGetUserSendLogsEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_SEND_LOGS,
      success: (res: AjaxResponse) => action.succeededGetUserSendLogs({ list: res.response.data.list }),
      fail: actionTypes.FAILED_GET_USER_SEND_LOGS
    },
    url: API_URL.EMAIL_LOGS,
    method: AjaxMethods.GET
  };
  
  return epicService.request(actions$, reqParams);
}

const requestGetUserSendDetailLogsEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_SEND_DETAIL_LOGS,
      success: (res: AjaxResponse) => action.succeededGetUserSendDetailLogs({ list: res.response.list }),
      fail: actionTypes.FAILED_GET_USER_SEND_DETAIL_LOGS
    },
    url: API_URL.EMAIL_LOGS,
    method: AjaxMethods.GET
  };
  
  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  sendEmailBulkByEmailsEpic,
  sendEmailBulkByRoleEpic,
  requestGetUserSendLogsEpic,
  requestGetUserSendDetailLogsEpic,
);

export { action, epic };
export default reducer;
