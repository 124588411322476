import moment from 'moment';
import React, { useCallback, useState } from 'react'
import { BannerItem, IBannerData } from './interface';
import { BannerListItemStyles as Styled } from './styles/BannerListItem.styles'
import useBanner from 'hooks/useBanner';
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';

interface IProps {
  banner: BannerItem;
  flexList: number[];
  onOpenPopup: (mode: 'detail' | 'write') => void;
  onSetSelectBannerData: (banner: BannerItem) => void;
  onToggleActive: (banner: BannerItem) => void;
  requestBanner:Function
}

const BannerListItem: React.FC<IProps> = (props) => {
  const { 
    banner, 
    flexList, 
    onOpenPopup, 
    onSetSelectBannerData, 
    onToggleActive,
    requestBanner
  } = props;

  const { requestDeleteBanner } = useBanner();
  const [ confirmOpen, setConfirmOpen ] = useState<boolean>(false)

  const config = require('../../config/index')
  const serverUrl = config.WEB_URL.includes('localhost') ? config.DEV_URL : config.WEB_URL;
  const onSelect = () => {
    onSetSelectBannerData(banner);
    onOpenPopup('detail');
  }

  const startDate = moment(banner.startDt);
  const endDate = moment(banner.endDt);
  const imgPath = `${serverUrl}${banner.imagePath}${banner.imageName}`;

  const closePopup = useCallback(() => {setConfirmOpen(false)}, []) 

  const onDelete = () => {
    const payload: any = {
      pathVariables: {
        path1: banner.bannerId,
      },
      callback: (succeeded: boolean, res: any) => {
        if(succeeded && res.response.code === 'OK'){
          requestBanner(1);
        }
      },
      async: true,
    }

    requestDeleteBanner(payload);
  }

  const deleteConfirmPopup = (
    <BasePopup
      open={confirmOpen}
      id={0}
      size='mini'
      type={PopupTypes.DIALOG}
      buttons={[
        {
          name: '취소',
          onClick: closePopup,
        }, 
        {
          name: '삭제',
          onClick: onDelete,
        }
      ]}
      onClose={() => {setConfirmOpen(false)}}
      >
        선택한 배너를 삭제 하시겠습니까?
    </BasePopup>
  )

  return (
    <Styled.Li>
      {/* <Styled.Content flex={flexList[0]}>
        <Styled.CheckBox checked={deleteBannerIds.includes(banner.bannerId)} onChange={onCheck} />
      </Styled.Content> */}
      <Styled.Content flex={flexList[0]}>{banner.serviceType}</Styled.Content>
      <Styled.Content flex={flexList[1]}>{banner.deviceType}</Styled.Content>
      <Styled.Content 
        flex={flexList[2]} 
        align='left' 
        onClick={onSelect} 
        cursor='pointer'
        underLine
      >
        {banner.name}
      </Styled.Content>
      <Styled.Content flex={flexList[3]}>
        <Styled.ToggleButton active={banner.activeYn} onClick={() => onToggleActive(banner)}>
          <Styled.Switch active={banner.activeYn} />
        </Styled.ToggleButton>
      </Styled.Content>
      <Styled.Content flex={flexList[4]}>
        <Styled.Content flex={1}>
          {banner.startDt ? startDate.format('YYYY-MM-DD') : '-'}
          <br />
          {banner.startDt ? startDate.format('HH:mm') : '-'}
        </Styled.Content>
        <Styled.Content flex={1}>
          {banner.endDt ? endDate.format('YYYY-MM-DD') : '-'}
          <br />
          {banner.endDt ? endDate.format('HH:mm') : '-'}
        </Styled.Content>
      </Styled.Content>
      <Styled.Content flex={flexList[5]}>
        <Styled.Img src={imgPath} />
      </Styled.Content>
      <Styled.Content flex={flexList[6]}>
        <i 
          className="red delete link icon" 
          style={{
            padding: '0px 15px', 
            margin: '0px'
          }}
          onClick={() => { setConfirmOpen(true) }}
        />
      </Styled.Content>
      {deleteConfirmPopup}
    </Styled.Li>
  )
}

export default BannerListItem