import React, { useState, useEffect } from "react";
import useTrendNews from "hooks/useTrendNews";
import { TrendNewsStyles as Styled } from "../styles/CommerceNewsStyle/TrendNews.style";
import DateOfWeek from "../DayOfWeek";

import {
  Title,
  RecollectButtonContainer,
} from "../styles/CommonStyle/Common.style";
import { CustomizedButton } from "../styles/CommonStyle/Common.style";

interface IProps {
  searchDate: string;
}
const CommerceNews: React.FC<IProps> = (props) => {
  const { searchDate } = props;

  const { trendNews, requestTrendNews, requestRecollectTrendNews } =
    useTrendNews();
  const { newsData } = trendNews;

  useEffect(() => {
    console.log("in useffect");
    requestTrendNews({ queryParameters: { searchDt: searchDate } });
  }, [searchDate]);

  const formatDate = (date: string): string => {
    return date.toString().slice(0, 10).replace(/-/g, "");
  };

  const onRecollect = (date: string) => {
    const payload: any = {
      queryParameters: { searchDt: date },
      callback: (succeded: boolean, res: any) => {
        if (succeded) {
          alert(date + "일 재수집 요청 성공");
        } else {
          alert(date + "일 재수집 실패");
        }
      },
    };
    requestRecollectTrendNews(payload);
  };

  const today = new Date().toISOString().slice(0, 10);
  return (
    <>
      <Title>커머스 뉴스</Title>

      {newsData.list.map(
        (news, _) =>
          newsData && (
            <>
              <DateOfWeek isFullDate={true} dateString={news.date}></DateOfWeek>
              <Styled.Wrapper>
                <Styled.recollectAndLinkWrapper>
                  <Styled.LinkButton
                    as="a"
                    href={`http://asp1.iquick4u.com/iquick/S1KIES1453/listView.html?page=1&category=2&group=&keyword=&keyfield=ALL&sdate=${formatDate(
                      news.date
                    )}&edate=${formatDate(news.date)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Styled.LinkText>뉴스 클리핑 바로가기</Styled.LinkText>{" "}
                  </Styled.LinkButton>

                  <CustomizedButton
                    margin="0"
                    onClick={() => onRecollect(news.date)}
                  >
                    재수집
                  </CustomizedButton>
                </Styled.recollectAndLinkWrapper>
                <Styled.Card>
                  <Styled.ContentBox>
                    <Styled.Key>{"커머스 뉴스 수집 수"} </Styled.Key>
                    <Styled.Value>{news.count} </Styled.Value>
                  </Styled.ContentBox>
                </Styled.Card>
              </Styled.Wrapper>
            </>
          )
      )}
    </>
  );
};

export default CommerceNews;
