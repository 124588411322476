import React from "react"
import styled from "styled-components"

const Svg = styled.svg`
  
  &:hover {
    cursor: pointer;
  }
  
  & + & {
    margin-top: 5px;
  }
`

export type SORT_TYPE = 'DESC' | 'ASC'

interface IProps {
  type : SORT_TYPE
  selected: SORT_TYPE | ''
  handler: (type: SORT_TYPE) => void
}

const Sort: React.FC<IProps> = (props) => {
  const { type, selected, handler } = props
  const onClick = () => {
    handler(type)
  }
  return <Svg 
    width="8" 
    height="5" 
    viewBox="0 0 8 5" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
      {
        type === 'ASC' 
          ? <path d="M7.25 4.5625L0.724609 4.5625C0.267578 4.5625 0.0390625 4.0293 0.369141 3.69922L3.61914 0.449219C3.82227 0.246094 4.15234 0.246094 4.35547 0.449219L7.60547 3.69922C7.93555 4.0293 7.70703 4.5625 7.25 4.5625Z" fill={selected === type? "#00069B" : "#CCCCD7"}></path>
          : <path d="M0.724609 0.5L7.25 0.5C7.70703 0.5 7.93555 1.05859 7.60547 1.38867L4.35547 4.63867C4.15234 4.8418 3.82227 4.8418 3.61914 4.63867L0.369141 1.38867C0.0390625 1.05859 0.267578 0.5 0.724609 0.5Z" fill={selected === type? "#00069B" : "#CCCCD7"}></path>
      }
    </Svg>
}

export default Sort