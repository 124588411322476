import React from 'react'
// Styles
import { InputBoxStyles as Styled } from './styles/InputBox.styles'

interface IProps {
  type?: 'text' | 'password';
  value?: string;
  placeholder?: string;
  readOnly?: boolean;
  setText: (text: string) => void;
  error?: boolean;
  errorMessage?: string;
  inputRef?: React.RefObject<HTMLInputElement>
}

const InputBox: React.FC<IProps> = (props) => {

  const { type, value, placeholder, readOnly, setText, error, errorMessage, inputRef } = props;

  const errorText = error && errorMessage &&
    <Styled.ErrorMessage>
      {errorMessage}
    </Styled.ErrorMessage>

  return (
    <Styled.InputBox>
      <Styled.Input 
        type={type || 'text'} 
        value={value} 
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setText(e.currentTarget.value)}
        error={error}
        ref={inputRef}
      />
      {errorText}
    </Styled.InputBox>
  )
}

export default InputBox
