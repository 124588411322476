import React, { useEffect, useState } from 'react';
// Hooks
import useBasics from 'hooks/useBasics';
import useNotice from 'hooks/useNotice';
// Components
import NoticeList from './NoticeList';
// Interface
import { IRequestNoticeParams, initIRequestNoticeParams } from './interface';

const Notice: React.FC = () => {
  const { basics } = useBasics();
  const { commonCodeList } = basics;
  const { notice, requestGetNoticeList } = useNotice();
  const { noticeList } = notice;
  const [ requestParams, setRequestParams ] = useState<IRequestNoticeParams>(initIRequestNoticeParams);

  useEffect(() => {
    onRequestNoticeList();
  }, []);

  const onRequestNoticeList = (params?: any) => {
    const reqParmas = params === undefined ? initIRequestNoticeParams : params;
    setRequestParams(reqParmas);
    if (reqParmas && reqParmas.page > 0) {
      requestGetNoticeList({
        queryParameters: reqParmas
      });
    }
  };
  return (
    <>
      <NoticeList 
        params={requestParams}
        setParams={setRequestParams}
        itemList={noticeList}
        noticeTypes={commonCodeList.notice_type}
        refresh={onRequestNoticeList}
      />
    </>
  )
}
export default Notice;