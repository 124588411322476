import React, { SyntheticEvent, useState } from 'react';

import { Label, LabelProps, Image, Table } from 'semantic-ui-react';
import { Link } from '@material-ui/core';
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'

import GuideImageView from './popup/guideImageView';
import GuideDelete from './popup/guideDelete';
import GuideModify from './popup/guideModify';
// Config
import { SERVER_URL } from 'store/modules/types/epics';
import useBasics from 'hooks/useBasics';
import ListItemLabel from './ListItemLabel';

interface IProps {
  typeList: any[];
  guide: any;
  fileType: string;
  showFeedback: { (msg: string): void };
}

const refColor: LabelProps['color'][] = ['orange', 'pink', 'purple', 'blue', 'green', 'violet', 'grey'];

const GuideListItem: React.SFC<IProps> = (props) => {

  const { typeList, guide, fileType, showFeedback } = props;
  const { no, detailNo, imageName, imagePath, priority, guideType, viewYn } = guide;

  // const { getTextByCode } = useBasics();
  const [open, setOpen] = useState(false);
  const [popupName, setPopupName] = useState('');

  const onClose = () => {
    setOpen(false);
  }

  const onButtonClicked = (e: SyntheticEvent, item: any) => {
    const id = (item && item.name) || undefined;
    if (id) {
      setPopupName(item.name);
      setOpen(true);
    }
  };

  const onView = () => {
    setPopupName('view');
    setOpen(true);
  }

  const imageIcon = (path: string, name: string) => {
    let img = SERVER_URL + path + name;
    return (
      <>
        <Link href='#' onClick={() => onView()} >
          <Image src={img} size='tiny' />
        </Link>
      </>
    )
  }
    
  const typeIndex: number = typeList && typeList.length > 0 ? typeList.findIndex(item => item.value === guideType) : 0;
  const labelColor: LabelProps['color'] = refColor[typeIndex];
  const labelName: string = typeList.find((type) => type.value === guideType)?.text;
  // const labelName: string = getTextByCode('use_guide', guideType);

  const tableListItemsProps: ITableListItemProps = {
    id: no,
    items: [
      <ListItemLabel color={labelColor}>{labelName}</ListItemLabel>, 
      imageIcon(imagePath, imageName), 
      priority, 
      useYn(viewYn)
    ],
    iconButtons: getButtons(onButtonClicked)
  }

  return (
    <>
      <TableListItem {...tableListItemsProps} />
      <GuideImageView
        guide={guide}
        open={open && popupName === 'view'}
        onClose={onClose}
      />
      <GuideDelete
        guide={guide}
        open={open && popupName === 'delete'}
        onClose={onClose}
        showFeedback={showFeedback}
      />
      <GuideModify
        guideItem={guide}
        typeList={typeList}
        fileType={fileType}
        open={open && popupName === 'edit'}
        onClose={onClose}
        showFeeback={showFeedback}
      />
    </>
  )
}

const useYn = (viewYn: boolean) => {
  let data = viewYn === true ? '게시' : '미게시';
  return (
    <>
      <span style={{ verticalAlign: 'middle' }}>{data}</span>
    </>
  )
}

const getButtons = (onClicked: any) => {
  const iconButtons: ITableListItemIconButton[] = [];
  const editButton: ITableListItemIconButton = {
    color: 'green',
    iconName: 'edit',
    tooltip: 'edit guide',
    onItemClicked: onClicked
  };
  const deleteButton: ITableListItemIconButton = {
    color: 'red',
    iconName: 'delete',
    tooltip: 'delete guide',
    onItemClicked: onClicked
  }
  iconButtons.push(editButton);
  iconButtons.push(deleteButton);
  return iconButtons;
}

export default GuideListItem;