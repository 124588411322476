export interface IRequestQnaListParams {  
  page : number,
  size : number,
  qnaCategoryType: string,
  qnaStatus : number[],
  isDelete: boolean ,
  title?: string,
}

export interface IInsertQnaParams {
  qnaId?: number
  qnaContents: string,
  file: IQnaFileParams[];
}

export interface IAnswerQnaParams {
  qnaId: number
  qnaContents: string,
  file: IQnaFileParams[];
  deleteFileNo?: number[],
}

export interface IQnaFileParams {
  imageName: string;
  imagePath: string;
  originName: string;
}

export interface IQnaFiles {
  no: number;
  originFileName: string;
  imageName: string;
  imagePath: string;
  regDt: string;
  updateDt: string;
  useYn: boolean;
}

export interface IQnaListItem {
  qnaId: number;
  userId: string;
  qnaCategoryType: string;
  qnaTitle: string;
  requestEmail: string;
  status: number;
  regDt: string;
  fileYn: boolean;
  delYn: boolean;
}

export interface IQnaDetails {
  qnaNo: number;
  qnaType: string;
  regDt: string;
  regUserId: string;
  qnaContents: string;
  qnaFiles: IQnaFiles[];
  sendEmailYn: false;
}

export interface IQnaDetailParams {
  qnaCategoryType: string;
  qnaDetails: IQnaDetails[];
  qnaId: number;
  qnaTitle: string;
  regDt: string;
  requestEmail: string;
  status: number;
  userId: string;
  phone: string;
}




// 변수 초기화
export const initRequestParams: IRequestQnaListParams = {
  page : 1,
  size : 10,
  qnaCategoryType: '',
  qnaStatus : [0, 1, 2, 3],
  isDelete: false,
}

export const initInsertQnaParams: IInsertQnaParams = {
  qnaContents:'',
  file:[]
}

export const initQnaDetailParams: IQnaDetailParams = {    
  qnaCategoryType: '',
  qnaDetails: [{
    qnaNo: 0,
    qnaType: '',
    regDt: '',
    regUserId: '',
    qnaContents: '',
    qnaFiles: [],
    sendEmailYn: false
  },],
  qnaId: 0,
  qnaTitle: '',
  regDt: '',
  requestEmail: '',
  status: 0,
  userId: '',
  phone: '',
}

export const initAnswerQnaParams: IAnswerQnaParams = {
  qnaId: -1,
  qnaContents: '',
  file: [],
  deleteFileNo:[]
}

export const initQnaDetail: IQnaDetails = {   
  qnaNo: -1,
  qnaType: '',
  regDt: '',
  regUserId: '',
  qnaContents: '',
  qnaFiles: [],
  sendEmailYn: false      
}