import React, { Fragment } from 'react';

import clsx from 'clsx';

import AppHeaderMenu from './AppHeaderMenu';
import LNBMenu from 'components/menu/LNBMenu';

// Styles
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

// Hooks
import useBasics from 'hooks/useBasics';
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircle from '@material-ui/icons/AccountCircle';


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#fff'
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: '#454545'
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      minWidth: '73px',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      backgroundColor: '#454545'
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

interface IHeaderProps {
  authenticated: boolean;
  //onChangeMenu(menu: string): void;
}

const AppHeader: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { authenticated } = props;

  const { changeMenu } = useBasics();

  const handleDrawerOpen = () => {
    setMenuOpen(true);
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: menuOpen,
        })}
      >
        <Toolbar style={{ margin: 0, padding: 0 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '0 0 73px', height: '64px', backgroundColor: menuOpen ? '#fff' : '#454545', borderRight: menuOpen ? '0.5px solid rgba(0, 0, 0, .2)' : '' }}>
            {
              menuOpen ? (
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              ) : (
                <img src="icon/32-x-32.png" onClick={handleDrawerOpen} style={{ cursor: 'pointer', filter: 'brightness(0) invert(1)', marginLeft: '-4px' }} />
              )
            }
          </div>
          <AppHeaderMenu authenticated />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: menuOpen,
          [classes.drawerClose]: !menuOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: menuOpen,
            [classes.drawerClose]: !menuOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          { menuOpen ? (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <img src="icon/32-x-32.png" style={{ filter: 'brightness(0) invert(1)', marginLeft: '12px' }} />
                <img src="images/logo-header.png" style={{ width: '40%', objectFit: 'contain', margin: '0 0 0 5px', filter: 'brightness(0) invert(1)' }} />
                {/*<span style={{ margin: '0 0 0 10px', color: 'rgba(255, 255, 255, 0.6)', fontWeight: 500, fontSize: '0.8rem' }}>ADMIN</span>*/}
              </div>
            </div>
          ): null}
        </div>
        <Divider />

        <LNBMenu open={menuOpen} />
      </Drawer>
    </>
  )
}

export default AppHeader;