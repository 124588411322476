import useReviewConfirm from 'hooks/useReviewConfirm';
import React, { useEffect, useState } from 'react'
import { DailyCollectStyles as Styled } from './styles/DailyCollect.style'
import List from './List';
import { Checkbox } from 'semantic-ui-react';
import { IUnCollectedProduct, IUnCollectedReviewProduct } from 'store/modules/types';

interface IProps {
  date: string;
}

const DailyCollect: React.FC<IProps> = (props) => {
  const { date } = props;
  const { reviewConfirm, requestGetReviewConfirmDailyCollect } = useReviewConfirm();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedProductIdList, setSelectedProductIdList] = useState<string[]>([])
  const [selectedReviewProductIdList, setSelectedReviewProductIdList] = useState<string[]>([])

  const productIds = reviewConfirm.dailyCollect.uncollectedProducts.map((product) => product.productId)
  const reviewProductIds = reviewConfirm.dailyCollect.uncollectedReviews.map((product) => product.productId)

  useEffect(() => {
    setIsLoading(true)
    requestGetReviewConfirmDailyCollect({
      queryParameters: {
        date,
      },
      callback: (succeeded: boolean, res: any) => {
        setIsLoading(false);
      }
    })
  }, [date])

  const handleRecollect = () => {

  }

  const handleAllProductSelect = () => {
    if (selectedProductIdList.length === productIds.length) {
      setSelectedProductIdList([])
    } else {
      setSelectedProductIdList(productIds)
    }
  }

  const handleAllReviewProductSelect = () => {
    if (selectedReviewProductIdList.length === reviewProductIds.length) {
      setSelectedReviewProductIdList([])
    } else {
      setSelectedReviewProductIdList(reviewProductIds)
    }
  }

  const handleProduct = (product: IUnCollectedProduct) => {
    if (!!selectedProductIdList.find((selectedProductId) => selectedProductId === product.productId)) {
      setSelectedProductIdList(selectedProductIdList.filter((selectedProductId) => selectedProductId !== product.productId))
    } else {
      setSelectedProductIdList([
        ...selectedProductIdList,
        product.productId,
      ])
    }
  }

  const handleReviewProduct = (product: IUnCollectedReviewProduct) => {
    if (!!selectedReviewProductIdList.find((selectedProductId) => selectedProductId === product.productId)) {
      setSelectedReviewProductIdList(selectedReviewProductIdList.filter((selectedProductId) => selectedProductId !== product.productId))
    } else {
      setSelectedReviewProductIdList([
        ...selectedReviewProductIdList,
        product.productId,
      ])
    }
  }

  return (
    <Styled.Wrap>
      <Styled.RecollectButton onClick={handleRecollect}>재수집</Styled.RecollectButton>
      <Styled.DataWrapList>
        <Styled.DataWrap>
          <Styled.DataWrapTitle>상품 정보</Styled.DataWrapTitle>
          <Styled.CardList>
            <Styled.Card>
              <Styled.CardTitle>전체 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.dailyCollect.allProductCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardTitle>수집 완료 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.dailyCollect.collectedProductCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardTitle>수집 실패</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.dailyCollect.failedProductCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
          </Styled.CardList>
          <Styled.ListTitle>
            {`미 수집 리스트 (${reviewConfirm.dailyCollect.uncollectedProducts.length.toLocaleString()})`}
          </Styled.ListTitle>
          <List 
            id={'daily-collect-product-list'}
            listWidthList='minmax(0, 10%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 45%)'
            titleList={[
              <Checkbox 
                name={'all'}
                value={'all'}
                label={''}
                checked={selectedProductIdList.length !== 0 && selectedProductIdList.length === productIds.length}
                onClick={handleAllProductSelect}
              />,
              'product_id',
              'market_id',
              '마켓',
              '상품명',
            ]}
            list={reviewConfirm.dailyCollect.uncollectedProducts.map((product) => (
              <React.Fragment key={`uncollectedProducts-${product.productId}`}>
                <Styled.ListItemText>
                  <Checkbox 
                    name={`${product.productId}`}
                    value={`${product.productId}`}
                    label={''}
                    checked={
                      !!selectedProductIdList.find((selecteProductId) => selecteProductId === product.productId)
                    }
                    onClick={() => handleProduct(product)}
                  />
                </Styled.ListItemText>
                <Styled.ListItemText>{product.productId}</Styled.ListItemText>
                <Styled.ListItemText>{product.marketId}</Styled.ListItemText>
                <Styled.ListItemText>{product.marketName}</Styled.ListItemText>
                <Styled.ListItemText 
                  isClick 
                  onClick={() => window.open(product.productUrl)}
                >
                  {product.productName}
                </Styled.ListItemText>
              </React.Fragment>
            ))}
            isHoverEvent={false}
            isLoading={isLoading}
          />
        </Styled.DataWrap>
        <Styled.DataWrap>
          <Styled.DataWrapTitle>리뷰 정보</Styled.DataWrapTitle>
          <Styled.CardList>
            <Styled.Card>
              <Styled.CardTitle>리뷰 수 변화 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.dailyCollect.increaseReviewCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardTitle>리뷰 수 미달 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.dailyCollect.uncollectedReviewCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
          </Styled.CardList>
          <Styled.ListTitle>
            {`미 수집 리스트 (${reviewConfirm.dailyCollect.uncollectedReviews.length.toLocaleString()})`}
          </Styled.ListTitle>
          <List 
            id={'daily-collect-review-product-list'}
            listWidthList='minmax(0, 10%) minmax(0, 15%) minmax(0, 15%) minmax(0, 18%) minmax(0, 14%) minmax(0, 14%) minmax(0, 14%)'
            titleList={[
              <Checkbox 
                name={'all'}
                value={'all'}
                label={''}
                checked={selectedReviewProductIdList.length !== 0 && selectedReviewProductIdList.length === reviewProductIds.length}
                onClick={handleAllReviewProductSelect}
              />,
              'product_id',
              'market_id',
              '마켓',
              '리뷰 증가수',
              '리뷰 수집수',
              '미 수집수',
            ]}
            list={reviewConfirm.dailyCollect.uncollectedReviews.map((product) => (
              <React.Fragment key={`uncollectedProducts-${product.productId}`}>
                <Styled.ListItemText>
                  <Checkbox 
                    name={`${product.productId}`}
                    value={`${product.productId}`}
                    label={''}
                    checked={
                      !!selectedProductIdList.find((selecteProductId) => selecteProductId === product.productId)
                    }
                    onClick={() => handleReviewProduct(product)}
                  />
                </Styled.ListItemText>
                <Styled.ListItemText>{product.productId}</Styled.ListItemText>
                <Styled.ListItemText>{product.marketId}</Styled.ListItemText>
                <Styled.ListItemText>{product.marketName}</Styled.ListItemText>
                <Styled.ListItemText>{product.increaseReviewCount.toLocaleString()}</Styled.ListItemText>
                <Styled.ListItemText>{product.collectedReviewCount.toLocaleString()}</Styled.ListItemText>
                <Styled.ListItemText>{product.unCollectedReviewCount.toLocaleString()}</Styled.ListItemText>
              </React.Fragment>
            ))}
            isHoverEvent={false}
            isLoading={isLoading}
          />
        </Styled.DataWrap>
      </Styled.DataWrapList>
    </Styled.Wrap>
  )
}

export default DailyCollect