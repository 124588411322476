import styled from 'styled-components'

interface IStyleProps {
  error?: boolean;
}

const InputBox = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: ${(props: IStyleProps) => props.error ? '1px solid red' : '1px solid rgba(34,36,38,.15)'};
  border-radius: 2px;
    
  &:focus {
    outline: ${(props: IStyleProps) => props.error ? '1px solid red' : '1px solid #85b7d9'};
    border: none;
    border-radius: 2px;
  }
`

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
`

export const InputBoxStyles = {
  InputBox,
  Input,
  ErrorMessage,
}