import React, { useEffect } from "react";
import { CardStyles as Styled } from "./styles/CommonStyle/Card.style";

interface IProps {
  text: string;
  value: number;
}
const Card: React.FC<IProps> = (props) => {
  const { text, value } = props;
  return (
    <Styled.Card>
      <Styled.ContentBox>
        <Styled.Key> {text} </Styled.Key>
        <Styled.Value>{value} </Styled.Value>
      </Styled.ContentBox>
    </Styled.Card>
  );
};

export default Card;
