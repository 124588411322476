import React, { useEffect, useState } from 'react';

import { Card, Grid, Icon, List, Divider } from 'semantic-ui-react';
import { TextField, InputLabel, Paper, Tab, Tabs, Box, Container, Chip, FormGroup, FormControlLabel, Switch, Button, Select, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { useUserContext, initCustomerMembership, initMemberDetails, ROLE_TYPES, ISearchUserParams } from './interface';

import { UserInfoSummaryStyles as Styled } from './styles/UserInfoSummary.styles';
import useUsers from 'hooks/useUsers';
import { useSearchStyles } from 'components/common/nextTable/TableList';
import { TRoleType } from 'store/modules/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      fontFamily: '',
      fontSize: '5px'
    },
    label: {
      color: 'black',
      fontFamily: 'Roboto NotoSansKr sans-serif',
      fontWeight: 'bold',
      fontSize: '12px',
    },
    row: {
      marginTop: '-20px'
    },
    tab: {
      background: 'white'
    },
    chip: {
      marginTop: '10px',
      margin: theme.spacing(0.5),
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface IProps {
  onResetPassword: () => void;
  onUpdateUser: () => void;
  onDeleteUser: () => void;
  refresh: () => void
  userAuth: string;
}

const ADMIN_ROLE = ['ROLE_ADMIN', 'ROLE_OPERATOR', 'ROLE_DEVELOPER']

const UserInfoSummary: React.FC<IProps> = (props) => {

  const { onResetPassword, onUpdateUser, onDeleteUser, refresh, userAuth } = props;

  const searchClasses = useSearchStyles();
  const { selectedUser, setSelectedUser } = useUserContext();
  const { requestPatchUserRoles, requestGetUserByUid } = useUsers()
  const [ selectedRoleTypes, setRoleTypes ] = useState<TRoleType[]>(selectedUser && selectedUser.memberDetails.roles ? selectedUser.memberDetails.roles.map(role => role.role) : [])

  const userInfo = selectedUser ? selectedUser : {
    customerMembership : initCustomerMembership,
    memberDetails: initMemberDetails,
    cooperationType: 'COMMON'
  };
  //const userStore = selectedUserStore ? selectedUserStore : initMySmartStore;

  const { 
    customerMembership : { 
      irankMembership,
      reviewMembership 
    },
    memberDetails : {
      agreements,
      customer,
      customerType,
      email,
      mailAgreementYn,
      phone,
      roles,
      smsAgreementYn,
      socialOnly,
      uid,
      userId,
      userName,
    }
  } = userInfo;
  // const { userTos } = agreements;
  const marketingAgreementYn = agreements.find((agreement) => agreement.userTos.title === '마케팅 수신 동의')?.agreementYn;  
  const { customerId, customerName } = customer;
  const [ tabMenu, setTabMenu ] = useState(0);
  //const [ selectedRoles, setSelectedRoles ] = useState<TRoleType[]>();
  const [ showRolesButton, setShowRolesButton ] = useState<boolean>(false);
  

  useEffect(() => {
    if (selectedUser && selectedUser.memberDetails.roles) {
      setRoleTypes(selectedUser.memberDetails.roles.map(role => role.role))
    } else {
      setRoleTypes([])
    }

    setShowRolesButton(false)

  }, [selectedUser])

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabMenu(newValue);
  };

  const dateOptions: Intl.DateTimeFormatOptions = { 
    month: "2-digit", 
    day: "2-digit", 
    year: "numeric", 
  };

  const dtOptions: Intl.DateTimeFormatOptions = { 
    ...dateOptions,
    hour: "2-digit", 
    minute: "2-digit", 
    second: "2-digit",
  };

  const userPhone = phone ? phoneNumberFormat(phone) : "";
  const selectedUserId = userId ? userId + "(" + customerType + ")" : "";
  //const regDate = regDt ? new Intl.DateTimeFormat("ja-JP", dtOptions).format(new Date(regDt)) : '';
  //const updateDate = regDt ? new Intl.DateTimeFormat("ja-JP", dtOptions).format(new Date(updateDt)) : '';
  //const userQuitDate = quitDate ? new Intl.DateTimeFormat("ja-JP", dateOptions).format(new Date(quitDate)) : '';
  const userRole = roles && roles.length > 0 ? roles.map((data, index) => 
    <li key={index} style={{ display: 'inline' }}>
      <Chip
        label={data.role}
        className={classes.chip}
        color="primary"
        size="small"
        variant="outlined"
      />
    </li>
  ) : [];

  // const quitTag = quitYn ? 
  //   <List.Item style={{ marginBottom: '5px' }} >
  //     <Icon name="clock outline" />    
  //     <List.Content>
  //       <List.Header>탈퇴일</List.Header>
  //       <List.Description>
  //         {userQuitDate}
  //       </List.Description>
  //     </List.Content>
  //   </List.Item>
  // : null;

  const tosListTag = agreements && agreements.length > 0 ? agreements.sort((a: any, b: any) => {
    return a.userTos.priority - b.userTos.priority;
  }).map((data:any, index:number) => {
    // let flag = BigInt(data.uid) === BigInt(userTos.uid) ? true : false;
    //console.log('agreement', data)

    const { userTos, agreementYn } = data;
    const { title } = userTos;

    return (
      <FormControlLabel
        key={title}
        control={
          <Switch
            checked={data.agreementYn}
            color="primary"
          />
        }
        label={title}
      />
    )
  }) : null;

  const buttonList = [
    {
      role: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
      name: '비밀번호 수정',
      onClick: onResetPassword,
    },
    {
      role: ['ROLE_ADMIN', 'ROLE_OPERATOR', 'ROLE_DEVELOPER'],
      name: '사용자정보 수정',
      onClick: onUpdateUser,
    },
    {
      role: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
      name: '사용자 삭제',
      onClick: onDeleteUser,
    }
  ];


  const buttons = selectedUser ? buttonList.filter(button => button.role.includes(userAuth)).map((button) => {
    return (
      <Button 
        variant="outlined" 
        color="secondary" 
        onClick={button.onClick}
        style={{ 
          height: '27px', 
          marginRight: '5px'
        }}>
        {button.name}
      </Button>
    )
  }) : null;

  const onClickToggleButton = (option: any, types:any[]) => {
    const isExistedValue: boolean = types.includes(option);
      if (isExistedValue) {
        setRoleTypes(types.filter(t => t !== option))
      } else {
        setRoleTypes([option])
      }
    
    
  }

  const ToggleButton = ({types, option}:{types?:any[], option: TRoleType} ) => {
    const type = types? types : [];
    const isActivated: boolean = type.includes(option as any);

    return (
      <Styled.ToggleButton 
        key={option}
        name={option}         
        onClick={(e:any) => {
          onClickToggleButton(option, type)
        }} 
        active={isActivated}
      >
        {option}
      </Styled.ToggleButton>
    )
  }


  const editHandler = () => {
    if (ADMIN_ROLE.includes(userAuth)) {
      setShowRolesButton(!showRolesButton)
    }
  }

  const modifyRolesHandler = () => {
    if (selectedUser) {
      const payload: any = {
        pathVariables: {
          path1: selectedUser.memberDetails.uid,
        },
        queryParameters: {
          roleTypes: selectedRoleTypes
        },
        callback: (succeeded: boolean, res: any) => {
          if(succeeded && res.response.code === 'OK') {
            alert('변경 완료 하였습니다.')
            const payload: any = {
              pathVariables: { uid: selectedUser?.memberDetails.uid },
              queryParameters: {
                cooperationType: selectedUser?.cooperationType,
              },
              callback: (succeeded: boolean, res:any) => {
                if (succeeded) {
                  setSelectedUser(
                    {
                      ...res.response.data,
                      ...{cooperationType: selectedUser?.cooperationType}
                    }
                  );
                }
              }
            }
            requestGetUserByUid(payload)
            refresh()
          } else {
            alert('오류가 발생하였습니다.')
          }
        },
      }
      requestPatchUserRoles(payload)
    }
    
  }

  return (
    <>
      <Card fluid style={{ display: 'flex', flex: 1 }}>
        <Card.Content style={{ overflowY: 'auto', overflowX: 'hidden', display: 'flex' }}>
          <Styled.Content>
            <Styled.Title>사용자 정보</Styled.Title>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <List style={{ flex: 1, margin: 0 }} >
                <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="user" />    
                  <List.Content>
                    <List.Header>고객번호</List.Header>
                    <List.Description>
                      {customerId}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="user" />    
                  <List.Content>
                    <List.Header>사용자ID(유형)</List.Header>
                    <List.Description>
                      {selectedUserId}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="user" />    
                  <List.Content>
                    <List.Header>사용자명</List.Header>
                    <List.Description>
                      {userName}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
              <List style={{ flex: 1, margin: 0 }}>
                <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="building" />    
                  <List.Content>
                    <List.Header>사업자명</List.Header>
                    <List.Description>
                      {customerName}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="phone" />    
                  <List.Content>
                    <List.Header>핸드폰번호</List.Header>
                    <List.Description>
                      {userPhone}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="envelope outline" />    
                  <List.Content>
                    <List.Header>이메일</List.Header>
                    <List.Description>
                      {email}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
              <List style={{ flex: 1, margin: 0 }}>
                {/* <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="clock outline" />    
                  <List.Content>
                    <List.Header>가입일</List.Header>
                    <List.Description>
                      {regDate}
                    </List.Description>
                  </List.Content>
                </List.Item> */}
                {/* <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="clock outline" />    
                  <List.Content>
                    <List.Header>수정일</List.Header>
                    <List.Description>
                      {updateDate}
                    </List.Description>
                  </List.Content>
                </List.Item> */}
                <List.Item style={{ marginBottom: '5px' }} >
                  <Icon name="user" />    
                  <List.Content>
                    <List.Header>
                      <span>권한</span>
                      {ADMIN_ROLE.includes(userAuth) && selectedUser && selectedUser.memberDetails.roles &&
                        <Styled.EditButtonWrap>
                          {!showRolesButton && <Styled.EditButton onClick={editHandler}>Edit</Styled.EditButton>}
                          {showRolesButton && <>
                            <Styled.EditButton onClick={modifyRolesHandler}>수정하기</Styled.EditButton>
                            <Styled.CancelButton onClick={editHandler}>취소</Styled.CancelButton>
                          </>}
                          {showRolesButton && <>
                              <Styled.ToggleButtonWrap>
                                {ROLE_TYPES.map(role => <ToggleButton types={selectedRoleTypes} option={role} />)}
                              </Styled.ToggleButtonWrap>
                            </>
                          }
                        </Styled.EditButtonWrap>
                      }
                    </List.Header>
                    <List.Description>
                      {userRole.length === 0 
                        ? <li style={{ display: 'inline' }}>
                            <Chip
                              label="ROLE_USER"
                              className={classes.chip}
                              color="primary"
                              size="small"
                              variant="outlined"
                            />
                          </li>
                        : userRole
                      }
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
              <List style={{ flex: 1, margin: 0 }} >
                <List.Item style={{ marginBottom: '5px' }} >
                  <List.Content>
                    <List.Description>
                      <FormGroup row>
                        <Styled.ControlLabel>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!marketingAgreementYn}
                                color="primary"
                              />
                            }
                            label="마케팅 수신동의"
                          />
                        </Styled.ControlLabel>
                        <Styled.ControlLabel>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={mailAgreementYn}
                                color="primary"
                              />
                            }
                            label="이메일 수신동의"
                          />
                        </Styled.ControlLabel>
                        <Styled.ControlLabel>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={smsAgreementYn}
                                color="primary"
                              />
                            }
                            label="SMS 수신동의"
                          />
                        </Styled.ControlLabel>
                      </FormGroup>
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </div>
          </Styled.Content>
        </Card.Content>
      </Card>
    </>
  )
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>
            {children}
          </Box>
        </Container>
      )}
    </div>
  );
}

// 핸드폰번호 하이픈
const phoneReplace = /-/g;
export const phoneNumberFormat = (value: number | string) => {
  const phoneNumber = value.toString().replace(phoneReplace, '');
  let format;
  if (phoneNumber.length === 10) {
    format = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3,6,)}-${phoneNumber.slice(6)}`;
  } 
  else if (phoneNumber.length === 11) {
    format = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3,7,)}-${phoneNumber.slice(7)}`;
  } 
  else {
    format = phoneNumber;
  }
  return format;
};

export default UserInfoSummary;