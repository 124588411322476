import { Card, TextField, Container, CardActions, Typography } from '@material-ui/core';

import styled from 'styled-components';

const RootDiv = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background-color: #f8f8f8;
`

const LogoDiv = styled(Container)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
`;

const LogoTitleText = styled(Typography)`
  &&& {
    color: rgba(0, 0, 0, 0.3);
    font-weight: 800;
    font-size: 0.98rem;
    margin-left: 10px;
  }
`;

const LoginDiv = styled(Card)`
  &&& {
    display: flex;
    min-width: 580px;
    min-height: 350px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 15px;
  }
`;

const LoginTitleText = styled(Typography)`
  &&& {
    color: rgba(0, 0, 0, 0.3);
    font-weight: 800;
    font-size: 1rem;
    padding-bottom: 20px;
  }
`;

const InputTextDiv = styled(TextField)`
  &&& {
    margin-bottom: 18px;
  }
`;

const LoginButtonDiv = styled(CardActions)`
  &&& {
    width: 50%;
    margin-top: 15px;
  }
`

export const NextLoginStyles = {
  RootDiv,
  LogoDiv,
  LogoTitleText,
  LoginDiv,
  LoginTitleText,
  InputTextDiv,
  LoginButtonDiv,
}