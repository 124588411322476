import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import { CrawlLogState } from './types';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
    requestGetCrawlLog: createAction(actionTypes.GET_DATA_CONFIRM_CRAWL_LOG),
    succededGetCrawlLog: createAction(actionTypes.SUCCEEDED_DATA_CONFIRM_CRAWL_LOG),
    failedGetCrawlLog: createAction(actionTypes.FAILED_DATA_CONFIRM_CRAWL_LOG),

    requestRecollectCrawlLog: createAction(actionTypes.RECOLLECT_DATA_CONFIRM_CRAWL_LOG),
    succededRecollectCrawlLog: createAction(actionTypes.SUCCEEDED_RECOLLECT_DATA_CONFIRM_CRAWL_LOG),
    failedRecollectCrawlLog: createAction(actionTypes.FAILED_RECOLLECT_DATA_CONFIRM_CRAWL_LOG),
}
const initialState: CrawlLogState = {
  crawlLog: {
    size: 0,
    list: []
  }
}

const reducer = handleActions<CrawlLogState>(
  {
    [actionTypes.SUCCEEDED_DATA_CONFIRM_CRAWL_LOG]: (state, action): CrawlLogState => {
      return {
        ...state,
        crawlLog: action.payload.crawlLog
      }
    },
  },
  initialState
);

const requestCrawlLogEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DATA_CONFIRM_CRAWL_LOG,
      success: (res: AjaxResponse) => action.succededGetCrawlLog({ crawlLog: res.response.data }),
      fail: actionTypes.FAILED_DATA_CONFIRM_CRAWL_LOG
    },
    url: API_URL.CRAWL_LOG,
    method: AjaxMethods.GET
  }

  return epicService.request(actions$, reqParams);
}

const requestRecollectCrawlLogEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.RECOLLECT_DATA_CONFIRM_CRAWL_LOG,
      success: (res: AjaxResponse) => action.succededRecollectCrawlLog(),
      fail: actionTypes.FAILED_RECOLLECT_DATA_CONFIRM_CRAWL_LOG
    },
    url: API_URL.CRAWL_LOG,
    method: AjaxMethods.POST
  }

  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  requestCrawlLogEpic,
  requestRecollectCrawlLogEpic,
);

export { action, epic };
export default reducer;