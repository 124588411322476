import React, { useState } from 'react'
import { IndexStyles as Styled } from "./styles/Index.style";
import moment from 'moment';
import { ReviewConfirmMenuType } from './interface';
import Calendar from 'components/common/calendar/Calendar';
import FirstCollect from './FirstCollect';
import DailyCollect from './DailyCollect';
import StatusCheck from './StatusCheck';

const ValidationReview: React.FC = () => {
  const [currentMenu, setCurrentMenu] = useState<ReviewConfirmMenuType>('first-collect');
  const [searchDate, setSearchDate] = useState<string>(
    moment().format('YYYY-MM-DD')
  );

  const onChangeEventDate = (e: any) => {
    const date = moment(e[0]).format('YYYY-MM-DD');
    setSearchDate(date);
  };

  const handleMenu = (menu: ReviewConfirmMenuType) => {
    setSearchDate(moment().format('YYYY-MM-DD'))
    setCurrentMenu(menu)
  }

  return (
    <Styled.Container>
      {currentMenu === 'daily-collect' && (
        <Calendar
          type='main'
          searchDate={searchDate}
          label='day'
          onChangeEventDate={onChangeEventDate}
        />
      )}
      <Styled.ButtonContainer>
        <Styled.Button
          as={
            currentMenu === 'first-collect'
              ? Styled.SelectedButton
              : Styled.Button
          }
          onClick={() => handleMenu('first-collect')}
        >
          최초 수집
        </Styled.Button>
        <Styled.Button
          as={
            currentMenu === 'daily-collect'
              ? Styled.SelectedButton
              : Styled.Button
          }
          onClick={() => handleMenu('daily-collect')}
        >
          데일리 수집
        </Styled.Button>
        <Styled.Button
          as={
            currentMenu === 'status-check'
              ? Styled.SelectedButton
              : Styled.Button
          }
          onClick={() => handleMenu('status-check')}
        >
          Status 체크
        </Styled.Button>
      </Styled.ButtonContainer>
      <Styled.Article>
        {currentMenu === 'first-collect' && <FirstCollect />}
        {currentMenu === 'daily-collect' && <DailyCollect date={searchDate} />}
        {currentMenu === 'status-check' && <StatusCheck />}
      </Styled.Article>
    </Styled.Container>
  )
}

export default ValidationReview