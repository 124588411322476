import { combineEpics } from 'redux-observable';
import { createAction } from 'redux-actions';
import { TrendNewsState } from './types';
// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';
import { handleActions } from 'redux-actions';

const action = {
    requestTrendNews: createAction(actionTypes.GET_DATA_CONFIRM_TREND_NEWS),
    requestTrendNewsSuccess: createAction(actionTypes.SUCCEEDED_GET_DATA_CONFIRM_TREND_NEWS),
    requestTrendNewsFailed: createAction(actionTypes.FAILED_GET_DATA_CONFIRM_TREND_NEWS),
    requestRecollectTrendNews: createAction(actionTypes.RECOLLECT_TREND_NEWS),
    requestRecollectTrendNewsSuccess: createAction(actionTypes.SUCCEEDED_RECOLLECT_TREND_NEWS),
    requestRecollectTrendNewsFailure: createAction(actionTypes.FAILED_RECOLLECT_TREND_NEWS),
}

const initialState: TrendNewsState = {
  newsData: {
      size: 0,
      list: []
    }  
  };

const reducer = handleActions<TrendNewsState>(
    {
      [actionTypes.SUCCEEDED_GET_DATA_CONFIRM_TREND_NEWS]: (state, action): TrendNewsState => {
        return {
          ...state,
          newsData: action.payload.newsData
        }
      },
    },
    initialState
    );

const requestTrendNews = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_DATA_CONFIRM_TREND_NEWS,
        success: (res: AjaxResponse) => action.requestTrendNewsSuccess({newsData: res.response.data}),
        fail: actionTypes.FAILED_GET_DATA_CONFIRM_TREND_NEWS
      },
      url: API_URL.TREND_NEWS,
      method: AjaxMethods.GET
    };
    return epicService.request(actions$, reqParams);
}

const requestRecollectTrendNewsEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.RECOLLECT_TREND_NEWS,
      success: (res: AjaxResponse) => action.requestRecollectTrendNewsSuccess(),
      fail: actionTypes.FAILED_RECOLLECT_TREND_NEWS
    },
    url: API_URL.TREND_NEWS,
    method: AjaxMethods.POST
  }
  return epicService.request(actions$, reqParams);
  ;
}


const epic = combineEpics(requestTrendNews,requestRecollectTrendNewsEpic);
export {action, epic}
export default reducer;