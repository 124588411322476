import React, { useState } from 'react';
// Components
import TableList, { ITableListProps, ITableListButton, IFindTextBox, ISelectBox, IPagination, ICheckBox } from 'components/common/table/TableList';
import NoticeListItem from './NoticeListItem';
import NoticeWrite from './popup/NoticeWrite';
import NoticeDelete from './popup/NoticeDelete';
import NoticeDetail from './popup/NoticeDetail';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
// Interface
import { IPopupParams, initIPopupParams } from './interface';
import { IPage } from 'store/modules/types';
import useBasics from 'hooks/useBasics';
import { convertCommonCode } from 'util/common';

interface IProps {
  params: any;
  setParams: Function;
  itemList: {
    list: any[];
    page: IPage;
  };
  noticeTypes: any[];
  refresh: Function;
}

const NoticeList: React.FC<IProps> = (props) => {

  const { params, setParams, itemList, noticeTypes, refresh } = props;
  const [ popupMode, setPopupMode ] = useState<IPopupParams>(initIPopupParams);
  // Snackbar Message params
  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ resultMsg, setResultMsg] = useState('');
  const { commonCodeList : { service_type } } = useBasics().basics

  const [ serviceType, setServiceType] = useState([
    { value: 'SELLERSPOT,IRANK,REVIEWRAY', text: '전체' },
    ...convertCommonCode(service_type)
  ]);
  
  // Function
  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  const onClose = () => {
    setPopupMode({
      ...popupMode,
      open: false,
      mode: '',
      noticeNo: 0
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({
      ...params,
      [event.target.name]: event.target.value
    });
  };

  const onSearch = (event: any) => {
    if(event.key === 'Enter' || event === 'Clicked'){      
      refresh({
        ...params,
        page: 1,
      });
    }
  };

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page:any) => {    
    refresh({
      ...params,
      [page.name]: page.activePage
    })
  };

  const onAddButtonClicked = () => {
    setPopupMode({
      ...popupMode,
      open: true,
      mode: 'write'
    });
  };

  // Table drawing Start
  // Finder
  const finder: IFindTextBox = {
    name: 'title',
    value: params.title,
    onChange: onChange,
    onSearch: onSearch
  };

  const onChangeServiceType = (data: any) => {    
    const value = data.value
    const serviceTypes = params.serviceTypes as string;
    const serviceTypesArr = serviceTypes.split(',');
    const newServiceTypes = serviceTypesArr.includes(value) 
      ?  [...serviceTypesArr.filter(status => status != value)]
      : [...serviceTypesArr, value]

    //const value = getData.value === 'COMMON' ? 'SELLERSPOT,IRANK,REVIEWRAY' : getData.value;    
    refresh({
      ...params,
      page: 1,
      serviceTypes: newServiceTypes.join(',')
    });
  };

  // const select : ISelectBox = {
  //   name: 'serviceTypes',
  //   options: serviceType,
  //   value: params.serviceTypes,
  //   onChange: onChangeServiceType,
  // };
//SELLERSPOT,IRANK,REVIEWRAY
  const checkbox: ICheckBox = {
    name : 'serviceTypes',
    options: [{
      value: 'SELLERSPOT',
      text: '셀러공간',
    }, {
      value: 'IRANK',
      text: '아이랭크',
    }, {
      value: 'REVIEWRAY',
      text: '리뷰레이',
    }],
    onChange: onChangeServiceType,
    defaultValues: params.serviceTypes.split(','),
  }


  // Header
  const title = <div style={{font:'inherit', textAlign:'left'}}>제목</div>;
  const headers = [title, '중요글여부', '게시유무', '항상 위에 표시', '회원전용', '등록일', ''];
  const headerWidth = [5,1,1,1,1,1,1];
  // Pagination
  const pagination: IPagination = {
    name: 'page',
    activePage: params.page,
    totalPage: itemList.page.totalPages,
    onChange: onChangePage
  };
  // Button
  const buttons: ITableListButton[] = [
    {
      name: '공지사항 등록하기',
      icon: 'add',
      onButtonClicked: onAddButtonClicked
    }
  ];
  // Table
  const tableListProps: ITableListProps = {
    title: '',
    size: 'small',
    color: 'violet',
    checkbox,
    headers,
    headerWidth,
    finder,
    pagination,
    buttons,
    count: itemList.page.totalElements,
    refresh: () => refresh()
  };
  // Table drawing End

  // List item 
  const noticeItems = itemList.list.map((item:any, index:number) => 
    <NoticeListItem
      item={item}
      key={index}
      setPopupMode={setPopupMode}
    />
  );

  // Popup view
  const writePopup = popupMode.open && ( popupMode.mode === "write" || popupMode.mode === "edit" ) ? 
    <NoticeWrite
      noticeNo={popupMode.noticeNo}
      noticeTypes={noticeTypes}
      open={popupMode.open}
      onClose={onClose}
      showFeedback= {(msg:string) => {        
        refresh({
          ...params,
          page: 1,
          serviceTypes: 'SELLERSPOT,IRANK,REVIEWRAY'
        });
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  : null;

  const deletePopup = popupMode.open && popupMode.mode === "delete" ?
    <NoticeDelete
      noticeNo={popupMode.noticeNo}
      open={popupMode.open}
      onClose={onClose}
      showFeedback= {(msg:string) => {        
        refresh({
          ...params,
          page: 1,
          serviceTypes: 'SELLERSPOT,IRANK,REVIEWRAY'
        });
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  : null;
 
  const detailPopup = popupMode.open && popupMode.mode === "detail" ?
    <NoticeDetail
      noticeNo={popupMode.noticeNo}
      noticeTypes={noticeTypes}
      open={popupMode.open}
      onClose={onClose}
    />
  : null;

  return (
    <>
      <TableList {...tableListProps}>
        {noticeItems}
      </TableList>

      {/* Popup */}
      {writePopup}      
      {deletePopup}
      {detailPopup}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}
const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}
export default NoticeList;