import styled from 'styled-components';

interface IStyleCardProps{
  selected?: boolean;
  height?: string;
  width?: string;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto; 
  white-space: nowrap; 
  gap: 10px;
  padding: 0px;
`;

const MarketContainer = styled.div`
width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;  // 날짜와 카드의 수직 중앙 정렬
  margin-bottom: 20px;  // 전체 컨테이너의 하단 여백
`;

const Section = styled.div`
  display: flex;
  flex-direction: column; // 세로 방향으로 배치
  border-right: 1px solid #ccc;
  padding: 5px;
`;

const ContentBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; 
  white-space: nowrap; 
  padding: 0px 20px 0px 20px;
`;

const Card = styled.div<IStyleCardProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 1px;
  box-shadow: ${props => 
    props.selected 
      ? '0 0 0 3px rgba(0, 123, 255, 0.5), 0 4px 8px rgba(0, 0, 0, 0.2)' 
      : '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'};

  
  gap: 15px;
  cursor: pointer;
  transition: box-shadow 0.17s ease-in-out;
  // padding: 0px 35px 0px 35px;

  &:hover {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5), 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const TextContainer = styled.div `
  display: flex;
`;

const Title = styled.p`
  font-size: 0.8rem;
  margin: 0 0 0 0;
  padding: 20px 20px 20px 0px;
`;

const Text = styled.p`

  font-size: 1.1em;
  margin: 0 10px 0 0;
  color: #333;
  display: flex;
`;

const Value = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
`;


export const TodayBestStyles = {
  Container, 
  MarketContainer,
  Section,
  CardContainer,
  ContentBox,
  Card,
  TextContainer,
  Text,
  Value,
  Title
}