import React, { useEffect, useState } from 'react';

// Hooks
import useUsers from 'hooks/useUsers';

// Components
import UserList from './UserList';

// Interface
import { 
  UserContext,
  ISearchUserParams, 
  initISearchUserParams 
} from './interface';
import { IUser } from 'store/modules/types';

const Users: React.FC = () => {
  const { users, requestUserList } = useUsers();
  const { userList } = users;

  const [ selectedUser, setSelectedUser ] = useState<IUser | null>(null);
  const [ searchParams, setSearchParams ] = useState<ISearchUserParams>(initISearchUserParams);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  useEffect(() => {
    onUserList();
  }, []);
  
  const onUserList = (params?: ISearchUserParams, pageNumber?: number, reset: boolean = false) => {
    setIsLoading(true);
    const reqParams: ISearchUserParams = !params ? searchParams : params;
    let pgNum = pageNumber && pageNumber >= 0 ? pageNumber : 1;
    reqParams.page = pgNum;

    const resultParams = {
      ...reqParams,
      page: pgNum,
    }
    
    setSearchParams(reqParams);
    if (reset) {
      setSelectedUser(null);
    }
    const payload: any = {
      queryParameters: {
        ...resultParams,
        roles:resultParams.roles,
        irankMemberships:resultParams.irankMemberships,
        reviewMemberships:resultParams.reviewMemberships,
        size: 10,
      },
      callback: () => {
        setIsLoading(false);
      }
    }

    requestUserList(payload,);
  };

  const userListSize = userList.list.length;
  if (userListSize > initISearchUserParams.size) {
    return null;
  }

  return (
    <UserContext.Provider 
      value={{ 
        selectedUser, 
        setSelectedUser, 
        searchParams, 
        setSearchParams 
      }}
    >
      <UserList 
        data={userList}
        setParams={setSearchParams}
        refresh={onUserList}
        isLoading={isLoading}
      />
    </UserContext.Provider>
  )
}

export default Users;