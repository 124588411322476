import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup'
import React, { useEffect, useState } from 'react'
import { IStatusCheckFilter } from './interface'
import { ProductStatusType, TableNameType } from 'store/modules/types'
import { StatusFilterPopupStyles as Styled } from './styles/StatusFilterPopup.style'
import { Checkbox } from 'semantic-ui-react'
import useMarket from 'hooks/useMarket'

interface IProps {
  open: boolean
  selectedData: IStatusCheckFilter
  handleClose: () => void
  handleFilter: (filter: IStatusCheckFilter) => void
}

const StatusFilterPopup: React.FC<IProps> = (props) => {
  const { open, selectedData, handleClose, handleFilter } = props
  const { market, requestMarketList } = useMarket()

  const [selectData, setSelectData] = useState<IStatusCheckFilter>(selectedData)

  useEffect(() => {
    if (market.marketData.list.length === 0) {
      requestMarketList({})
    }
  }, [])

  const handleTableName = (name: TableNameType) => {
    if (selectData.tableName.includes(name)) {
      setSelectData({
        ...selectData,
        tableName: [...selectData.tableName].filter((selectedName) => selectedName !== name)
      })
    } else {
      setSelectData({
        ...selectData,
        tableName: [
          ...selectData.tableName,
          name,
        ]
      })
    }
  }

  const handleStatus = (status: ProductStatusType) => {
    if (selectData.status.includes(status)) {
      setSelectData({
        ...selectData,
        status: [...selectData.status].filter((selectedStatus) => selectedStatus !== status)
      })
    } else {
      setSelectData({
        ...selectData,
        status: [
          ...selectData.status,
          status,
        ]
      })
    }
  }

  const handleMarket = (market: string) => {
    if (selectData.market.includes(market)) {
      setSelectData({
        ...selectData,
        market: [...selectData.market].filter((selectedMarket) => selectedMarket !== market)
      })
    } else {
      setSelectData({
        ...selectData,
        market: [
          ...selectData.market,
          market,
        ]
      })
    }
  }

  return (
    <BasePopup
      open={open}
      id={0}
      size='small'
      title={'필터'}
      type={PopupTypes.DIALOG}
      buttons={[
        {}, 
        { onClick: () => {
            handleFilter(selectData) 
            handleClose()
          }
        }
      ]}
      onClose={handleClose}
    >
      <Styled.Wrap>
        <Styled.SelectedList>
          {selectData.tableName.map((tableName) => (
            <Styled.SelectedItem 
              key={`selected-tablename-${tableName}`}
              onClick={() => handleTableName(tableName)}
            >
              <Styled.SelectedTitle>구분</Styled.SelectedTitle>
              <Styled.SelectedValue>
                {tableName === 'COMPARE' ? '경쟁상품 등록' : '상품 등록'}
              </Styled.SelectedValue>
            </Styled.SelectedItem>
          ))}
          {selectData.status.map((status) => (
            <Styled.SelectedItem 
              key={`selected-status-${status}`}
              onClick={() => handleStatus(status)}
            >
              <Styled.SelectedTitle>Status</Styled.SelectedTitle>
              <Styled.SelectedValue>
                {status}
              </Styled.SelectedValue>
            </Styled.SelectedItem>
          ))}
          {selectData.market.map((market) => (
            <Styled.SelectedItem 
              key={`selected-market-${market}`}
              onClick={() => handleMarket(market)}
            >
              <Styled.SelectedTitle>마켓</Styled.SelectedTitle>
              <Styled.SelectedValue>
                {market}
              </Styled.SelectedValue>
            </Styled.SelectedItem>
          ))}
        </Styled.SelectedList>
        <Styled.FilterWrap>
          <Styled.FilterTitle>구분</Styled.FilterTitle>
          <Styled.FilterItemList>
            <Checkbox 
              name={'상품 등록'}
              value={'상품 등록'}
              label={'상품 등록'}
              checked={selectData.tableName.includes('PRODUCT')}
              onClick={() => handleTableName('PRODUCT')}
            />
            <Checkbox 
              name={'경쟁상품 등록'}
              value={'경쟁상품 등록'}
              label={'경쟁상품 등록'}
              checked={selectData.tableName.includes('COMPARE')}
              onClick={() => handleTableName('COMPARE')}
            />
          </Styled.FilterItemList>
        </Styled.FilterWrap>
        <Styled.FilterWrap>
          <Styled.FilterTitle>Status</Styled.FilterTitle>
          <Styled.FilterItemList>
            <Checkbox 
              name={'BEFORE'}
              value={'BEFORE'}
              label={'BEFORE'}
              checked={selectData.status.includes('BEFORE')}
              onClick={() => handleStatus('BEFORE')}
            />
            <Checkbox 
              name={'COLLECTING'}
              value={'COLLECTING'}
              label={'COLLECTING'}
              checked={selectData.status.includes('COLLECTING')}
              onClick={() => handleStatus('COLLECTING')}
            />
            <Checkbox 
              name={'COLLECTED'}
              value={'COLLECTED'}
              label={'COLLECTED'}
              checked={selectData.status.includes('COLLECTED')}
              onClick={() => handleStatus('COLLECTED')}
            />
            <Checkbox 
              name={'ANALYZED'}
              value={'ANALYZED'}
              label={'ANALYZED'}
              checked={selectData.status.includes('ANALYZED')}
              onClick={() => handleStatus('ANALYZED')}
            />
          </Styled.FilterItemList>
        </Styled.FilterWrap>
        <Styled.FilterWrap>
          <Styled.FilterTitle>마켓</Styled.FilterTitle>
          <Styled.FilterItemList>
            {market.marketData.list.map((market) => (
              <Checkbox 
                key={`filter-market-${market.marketId}`}
                name={market.name}
                value={market.name}
                label={market.name}
                checked={selectData.market.includes(market.name)}
                onClick={() => handleMarket(market.name)}
              />
            ))}
          </Styled.FilterItemList>
        </Styled.FilterWrap>
      </Styled.Wrap>
    </BasePopup>
  )
}

export default StatusFilterPopup