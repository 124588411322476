import React, { useEffect, useState } from 'react';
// Hooks
import useEvent from 'hooks/useEvent';
import useBasics from 'hooks/useBasics';
// Components
import EventList from './EventList';
// Interface
import { IRequestEventParams, initIRequestEventParams } from './interface';
import { convertCommonCode } from 'util/common';


const Events: React.FC = () => {
  const { basics } = useBasics();
  const { commonCodeList } = basics;
  const { events, requestEventList } = useEvent();
  const { eventList } = events;
  const [ requestParams, setRequestParams ] = useState<IRequestEventParams>(initIRequestEventParams);

  useEffect(() => {
    onRequestEventList();
  }, []);
  
  const onRequestEventList = (params?: any) => {
    const reqParams = params === undefined ? initIRequestEventParams : params;
    setRequestParams(reqParams);
    requestEventList({
      queryParameters: reqParams
    });
  };

  return (
    <> 
      <EventList
        requestParams={requestParams}
        setRequestParams={setRequestParams}
        typeList={convertCommonCode(commonCodeList.event_type)}
        eventList={eventList}
        refresh={onRequestEventList}
      />
    </>
  )
}

export default Events; 