import React, { useState, useEffect } from 'react'
// lib
import Flatpickr from 'react-flatpickr';
import flatpickr from "flatpickr";
import TextField from '@material-ui/core/TextField';
import TodayIcon from '@material-ui/icons/Today';
import InputAdornment from '@material-ui/core/InputAdornment';
// style
import { CalendarStyles as Styled } from './styles/Calendar.styles';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
interface IProps {
  type: 'main' | 'static';
  searchDate: string;
  label?: string;
  onChangeEventDate: (e: any) => void;
}

const Calendar: React.FC<IProps> = ({ type, searchDate, label, onChangeEventDate }) => {
  const [ scrollY, setScrollY ] = useState<number>(window.scrollY);
  const [ viewNavCalendar, setViewNavCalendar ] = useState<boolean>(false);

  useEffect(() => {
    if (type === 'main') {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 120) {
          setViewNavCalendar(true);
          setTimeout(() => {
            setScrollY(window.scrollY);
          }, 200)
        }
        else setViewNavCalendar(false);
      })
  
      return () => {
        window.removeEventListener('scroll', () => {
          if (window.scrollY > 120) {
            setViewNavCalendar(true);
            setTimeout(() => {
              setScrollY(window.scrollY);
            }, 200)
          }
          else setViewNavCalendar(false);
        })
      }
    }
  }, [])

  const calendar = (
    <Flatpickr
      localName="ko"
      value={searchDate}
      onChange={onChangeEventDate}
      options={{
        mode: "single",
        locale: "ko",
        enableTime: false,
        dateFormat: 'Y-m-d',
      }}
      render={
        ({defaultValue, value, ...props}, ref) => {
          return (
            <TextField
              id="date"
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={defaultValue}
              inputRef={ref}
              InputProps={{
                endAdornment: 
                  <InputAdornment position="end" style={{ zIndex: -1, position: 'relative' }} >
                    <TodayIcon />
                  </InputAdornment>
              }}
            />
          )
        }
      }
    />
  )

  return (
    <>
      {type === 'main' ? (
        <Styled.MainCalendar isSideView={viewNavCalendar} scrollY={scrollY}>{calendar}</Styled.MainCalendar>
      ) : (
        <Styled.CalendarWrap>{calendar}</Styled.CalendarWrap>
      )}
    </>
  )
}

export default Calendar