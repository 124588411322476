import moment from 'moment';
import React from 'react'
import { Line } from 'react-chartjs-2';
import { IDashboardUserStatus } from '../interface';

interface IProps {
  searchDate: string;
  userStatus: IDashboardUserStatus;
  getElementAtEvent: (element: any) => void;
}

const JoinUserChart: React.FC<IProps> = (props) => {
  const { searchDate, userStatus, getElementAtEvent } = props;

  const getJoinUserChartData = () => {
    const dailyData = Array.from({ length: moment(searchDate).endOf('month').get('date') }, (v) => 0)

    userStatus.user.mobileChart.forEach((data) => {
      const count = dailyData[moment(data.date).get('date') - 1];
      dailyData[moment(data.date).get('date') - 1] = count && count > 0? data.count + count : data.count
    });

    userStatus.user.webChart.forEach((data) => {
      const count = dailyData[moment(data.date).get('date') - 1];
      dailyData[moment(data.date).get('date') - 1] = count && count > 0? data.count + count : data.count
    });

    return dailyData
  }

  const dailyData = getJoinUserChartData();
  const dayArray = dailyData && dailyData.length > 0 ? dailyData.map((a, index) => (index + 1) + '일') : []
  const maxValue = dailyData && dailyData.length > 0 ? dailyData.reduce((a, b) => Math.max(a, b)): 0

  const chartData = {
    labels: dayArray,
    datasets: [
      {
        label: "일별 가입자 수",
        data: dailyData,
        fill: false,
        borderColor: "#80b6f4"
      }
    ]
  };

  const legend = {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#323130",
      fontSize: 14
    }
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: maxValue + 3,
            stepSize: 1,
            maxTicksLimit: 10,
          }
        }
      ]
    },
    onHover: (event: any, chartElement: any) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }
  };

  return (
    <Line 
      data={chartData} 
      legend={legend} 
      options={options} 
      height={50}
      getElementAtEvent={getElementAtEvent}
    />
  )
}

export default JoinUserChart