import TableList, { ICheckBox, IPagination, ITableListProps } from "components/common/table/TableList";
import React, { useState } from "react"
import { IParams, IPopupParams, initIPopupParams } from "./interface";
import { IPage } from "store/modules/types";
import useBasics from "hooks/useBasics";
import DemoItem from "./DemoItem";
import DemoDetail from "./popup/DemoDetail";

interface IProps {
  params: IParams;
  setParams: Function;
  itemList: {
    list: any[];
    page: IPage;
  };
  refresh: Function;
}


const DemoList: React.FC<IProps> = (props) => {
  const { params, setParams, itemList, refresh } = props;
  const { commonCodeList : { review_demo_status } } = useBasics().basics

  const [ snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [ popupMode, setPopupMode ] = useState<IPopupParams>(initIPopupParams);
  const [ resultMsg, setResultMsg] = useState<string>('');
  

  const title = <div style={{font:'inherit', textAlign:'left'}}>상호/법인명</div>;
  const headers = [title, '업무용이메일', '연락처', '이름', '마케팅 수신동의', '데모신청일', '데모이용기간', '상테'];
  const headerWidth = [2,1,1,1,1,1,1,1];

   // Function
   const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  const onClose = () => {
    setPopupMode({
      ...popupMode,
      open: false,
      mode: '',
      id: 0
    });
  };

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page:any) => {    
    refresh({
      ...params,
      [page.name]: page.activePage
    })
  };

  const onChangeCheckbox = (data: any) => {    
    const value = data.value
    const statuses = params.statuses;
    const statusesArr = statuses.split(',')
    const newStatues = statusesArr.includes(value) 
      ?  [...statusesArr.filter(status => status != value)]
      : [...statusesArr, value]
    
    refresh({
      ...params,
      statuses: newStatues.join(',')
    })
  }

  const pagination: IPagination = {
    name: 'page',
    activePage: params.page,
    totalPage: itemList.page.totalPages,
    onChange: onChangePage
  };

  const checkbox: ICheckBox = {
    name : 'statuses',
    options: [{
      value: 'WAIT',
      text: '대기',
    }, {
      value: 'COMPLETE',
      text: '발급완료',
    }, {
      value: 'CANCEL',
      text: '취소',
    }],
    onChange: onChangeCheckbox,
    defaultValues: params.statuses.split(','),
  }

  const tableListProps: ITableListProps = {
    title: '',
    size: 'small',
    color: 'violet',
    headers,
    headerWidth,
    pagination,
    checkbox,
    count: itemList.page.totalElements,
    refresh: () => refresh()
  };

  const demoItems = itemList.list.map((item:any, index:number) => 
    <DemoItem
      item={item}
      key={index}
      setPopupMode={setPopupMode}
    />
  );

  const detailPopup = popupMode.open && popupMode.mode === "detail" ?
    <DemoDetail
      id={popupMode.id}
      open={popupMode.open}      
      onClose={onClose}
      refresh={refresh}
    />
  : null;


  return <>
    <TableList {...tableListProps}>
        {demoItems}
    </TableList>

    {detailPopup}
  </>
}

export default DemoList