import { createStore, Store, applyMiddleware } from "redux";

import { composeWithDevTools } from 'redux-devtools-extension';

import { createEpicMiddleware } from 'redux-observable';

import logger from 'redux-logger';

// modules
import modules from "./modules";
import { StoreState } from "./modules/types";

const epicMiddleware = createEpicMiddleware();

export const epicRun = () => {
  epicMiddleware.run(modules.rootEpic);
}

export default function configureStore():Store<StoreState> {
  const store = createStore(
    modules.rootReducer,
    composeWithDevTools(
      applyMiddleware(epicMiddleware, logger)
    )
  );
  
  return store;
}