import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/market';
import { ActionPayload } from 'store/modules/types';

const useMarket = () => {

  const dispatch = useDispatch();

  const market = useSelector((state: StoreState) => state.market);

  const requestMarketList = (data: any) => {
    dispatch(action.getMarketList(data));
  }

  const requestGetMarket = (payload: ActionPayload) => {
    dispatch(action.getMarket(payload));
  }

  const requestCreateMarket = (payload: ActionPayload) => {
    dispatch(action.createMarket(payload));
  }

  const requestDeleteMarket = (payload: ActionPayload) => {
    dispatch(action.deleteMarket(payload));
  }
  
  const requestModifyMarket = (payload: ActionPayload) => {
    dispatch(action.modifyMarket(payload));
  }

  return {
    market,
    requestMarketList,
    requestGetMarket,
    requestCreateMarket,
    requestDeleteMarket,
    requestModifyMarket,
  }
}
 
export default useMarket;