import React, { useState, useEffect, SyntheticEvent } from 'react';

import useUsers from 'hooks/useUsers';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
import {  Grid, Segment, Header, Form, Button, Checkbox, Input, Label, CheckboxProps } from 'semantic-ui-react';

interface IProps {
    open: boolean;
    onClose(): void;
    showFeedback: { (msg: string): void };
}

const PaymentEditor: React.FC<IProps> = (props) => {

    const { open, onClose, showFeedback } = props;

    const { users, requestCodeList, insertPayment }  = useUsers();
    const { codeList } = users;

    const [ onButton, setOnButton ] = useState(false);

    /* Api Call */     
    useEffect(() => {requestCodeList()} ,[]);

    /* Save Api Params */
    const [ extraService, setExtraService ] = useState('');
    const [ functionProvided, setFunctionProvided ] = useState('');
    const [ paymentType, setPaymentType ] = useState('');
    const [ unlimitedYn, setUnlimitedYn ] = useState(Boolean);
    const [ useYn, setUseYn ] = useState(true);
    const [ viewYn, setViewYn ] = useState(true);
    const [ analysisMarket, setAnalysisMarket ] = useState(0);
    const [ analysisTerm, setAnalysisTerm ] = useState(0);
    const [ productInsertCount, setProductInsertCount ] = useState(0);
    const [ price, setPrice ] = useState(0);

    let extraServiceArray:Array<string> = new Array<string>();
    let extraServiceStr:string;
    let functionProvidedArray:Array<string> = new Array<string>();
    let functionProvidedStr:string;


    /* Params Change Event! */
    const extraServiceChange = (e : React.FormEvent<HTMLInputElement>, data: any) => {
        e.preventDefault();
        data.checked === true ? extraServiceArray.push(data.value) : extraServiceArray.splice(extraServiceArray.indexOf(data.value),1);
        extraServiceStr = extraServiceArray.join(",");     
    }
    const functionProvidedChange = (e : React.FormEvent<HTMLInputElement>, data: any) => {
        e.preventDefault();
        data.checked === true ? functionProvidedArray.push(data.value) : functionProvidedArray.splice(functionProvidedArray.indexOf(data.value), 1);
        functionProvidedStr = functionProvidedArray.join(",");
    }
    const unlimitedYnChange = (e : React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.checked === true ? setUnlimitedYn(true) : setUnlimitedYn(false);
    }
    const paymentTypeChange = (e : React.ChangeEvent<HTMLInputElement>)  => {
        setPaymentType(e.target.value);
    }
    const useYnChange = (e : React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.checked === true ? setUseYn(true) : setUseYn(false);
    }
    const viewYnChange = (e : React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.checked === false ? setViewYn(true) : setViewYn(false);
    }
    const priceChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setPrice(e.target.valueAsNumber);
    }
    const analysisMarketChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setAnalysisMarket(e.target.valueAsNumber);
    }
    const analysisTermChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setAnalysisTerm(e.target.valueAsNumber);
    }
    const productInsertCountChange = (e : React.ChangeEvent<HTMLInputElement>)  => {
        setProductInsertCount(e.target.valueAsNumber);
    }

    const onInsertPayment = (e: SyntheticEvent, item: any) => {
        insertPayment({
            "analysisMarket": analysisMarket,
            "analysisTerm": analysisTerm,
            "extraService": extraServiceStr,
            "functionProvided": functionProvidedStr,
            "paymentType": paymentType,
            "price": price,
            "productInsertCount": productInsertCount,
            "unlimitedYn": unlimitedYn,
            "useYn": useYn,
            "viewYn": viewYn
        });
        showFeedback("등록이 완료되었습니다.");
        onClose();
    }
    /* Service Code */
    const functionProvidedCodeArray : [{}] = codeList.function_provided;
    const functionProvidedCode = functionProvidedCodeArray ? functionProvidedCodeArray.map((data:any) => (
        <Form.Checkbox 
            type='checkbox'
            label={data.text}
            value={data.value} 
            onChange={functionProvidedChange}
        >
            {data.text}
        </Form.Checkbox>  
    )) : null;

    /* Extra Service Code */
    const extraServiceCodeArray : [{}] = codeList.extra_service;
    const extraServiceCode = extraServiceCodeArray ? extraServiceCodeArray.map((data:any) => (
        <Form.Checkbox 
            type='checkbox'
            label={data.text}
            value={data.value}
            onChange={extraServiceChange}
        >
            {data.text}
        </Form.Checkbox>
    )) : null;

    return (
        <BasePopup
            open={open}
            id={0}
            size='tiny'
            type={PopupTypes.DIALOG}
            title='요금제 등록'
            buttons={[
                {}, { onClick: onInsertPayment }
            ]}
            onClose={onClose}
            >
            <Form size='tini' >
                <Form.Group>
                    <Form.Checkbox
                        label='사용 유무'
                        defaultChecked={true}
                        onChange={useYnChange}
                    />
                    <Form.Checkbox
                        label='게시글 노출 여부'
                        defaultChecked={true}
                        onChange={viewYnChange}
                    />
                    <Form.Checkbox
                        label='서비스 무제한'
                        onChange={unlimitedYnChange} 
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input
                        label='요금제명'
                        size='small'
                        onChange={paymentTypeChange}
                    />
                    <Form.Input
                        label='요금제 가격(원)'
                        type="number"
                        size='small'
                        onChange={priceChange}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input
                        label='분석 마켓 수(개)'
                        type="number"
                        size='mini'
                        style={{ width: '100%' }}
                        onChange={analysisMarketChange}
                    />
                    <Form.Input
                        label='데이터 분석 기간(개월)'
                        type="number"
                        size='mini'
                        style={{ width: '100%' }}
                        onChange={analysisTermChange}
                    />
                    <Form.Input
                        label='상품 등록 수(개)'
                        type="number"
                        size='mini'
                        style={{ width: '100%' }}
                        onChange={productInsertCountChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field width={10}>* 서비스 항목</Form.Field>
                    <Form.Field width={10}>* 부가 서비스 항목</Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={10}>{functionProvidedCode}</Form.Field>
                    <Form.Field width={10}>{extraServiceCode}</Form.Field>
                </Form.Group>
            </Form>
        </BasePopup>
    );
}

export default PaymentEditor;