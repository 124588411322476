import React from 'react'
import Card from './Card';
import { IDashboardUserStatus } from './interface';

interface IProps {
  userStatus: IDashboardUserStatus;
}

const UserInfoCardDatas: React.FC<IProps> = (props) => {
  const { userStatus } = props;

  const datas = [
    {
      title: { text: '전체 가입자 수', value: userStatus.allUserCount },
    },
    {
      title: { text: '일 가입자 수', value: userStatus.dayUserCount.total.toLocaleString() },
      contents: [
        { text: 'PC', value: userStatus.dayUserCount.pc.toLocaleString() },
        { text: 'APP', value: userStatus.dayUserCount.mobile.toLocaleString() },
      ]
    },
    {
      title: { text: '월 가입자 수', value: userStatus.monthUserCount.total.toLocaleString() },
      contents: [
        { 
          text: 'PC', 
          value: userStatus.monthUserCount.pc.toLocaleString()
        },
        { 
          text: 'APP', 
          value: userStatus.monthUserCount.mobile.toLocaleString()
        },
      ]
    },
    {
      title: { 
        text: '일 방문자 수', 
        value: userStatus.dayLoginCount.total.toLocaleString(),
      },
      contents: [
        { 
          text: 'PC', 
          value: userStatus.dayLoginCount.pc.toLocaleString(),
        },
        { 
          text: 'APP',  
          value: userStatus.dayLoginCount.mobile.toLocaleString(),
        },
      ]
    },
    {
      title: { 
        text: '월 방문자 수', 
        value: userStatus.monthLoginCount.total.toLocaleString(),
      },
      contents: [
        { 
          text: 'PC', 
          value: userStatus.monthLoginCount.pc.toLocaleString()
        },
        { 
          text: 'APP', 
          value: userStatus.monthLoginCount.mobile.toLocaleString()
        },
      ]
    },
    // {
    //   title: { 
    //     text: '전체 스토어 수', 
    //     value: userStatus.storeStatus.adminListDto.totalCount.toLocaleString(),
    //   },
    //   contents: [
    //     { 
    //       text: '월', 
    //       value: userStatus.storeStatus.adminListDto.monthlyCount.toLocaleString(),
    //     },
    //     { 
    //       text: '일', 
    //       value: userStatus.storeStatus.adminListDto.dailyCount.toLocaleString(),
    //     },
    //   ]
    // },
  ]

  return (
    <>
      {datas.map((data, index) => (
        <Card 
          key={`${data.title.text}-card${index}`} 
          title={data.title} 
          contents={data.contents} 
        />
      ))}
    </>
  )
}

export default UserInfoCardDatas