import React, { useState, useEffect, SyntheticEvent } from 'react';

import { Select, Form, Message, Checkbox } from 'semantic-ui-react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

// Hooks
import useSalonon from 'hooks/useSalonon';

// Components
import TableList, { ITableListProps, ITableListButton, IFindTextBox, IPagination } from 'components/common/table/TableList';
import PostListItem from './PostListItem';

import '../interfaces';
import { initGetData } from '../interfaces';

const initRequestParams = {
  page: 1,
  size : 0,
  mainTitle : '',
}


export const TagPopupTypes = {
  MANAGE: 'manage',
  SELECT: 'select',
} as const
type TagPopupType = typeof TagPopupTypes[keyof typeof TagPopupTypes]

interface IProps {
  category: any[];
  postId: number;
  user: any;
  open: boolean;
  onCreateQna: Function;
  onClose(): void;
  type: TagPopupType;
  insertedPost?: any;
  setInsertedPost?: Function;
}

const PostList: React.SFC<IProps> = (props) => {

  const { category, postId, user, open, onClose, type, insertedPost, setInsertedPost } = props;

  const { requestDsonSelectList } = useSalonon();

  const [ requestParams, setRequestParams ] = useState(initRequestParams);

  const [ selectedPost, setSelectedPost ] = useState(insertedPost);
  const [ list, setList ] = useState<any[]>([]);
  const [ data, setData ] = useState(initGetData);

  
  useEffect(() => {
    onRequest();
  }, []);

  const onRequest = (params? : any) => {
    let reqParams = null;

    if(params){
      reqParams = params;
    }else{     
      reqParams = {
        page: 0,
        size: 0,
      }
    }
    requestDsonSelectList({
      queryParameters: reqParams,
      callback: (succeeded:boolean, res:any) => {
        if(succeeded){
          console.log('res', res);
          setData({
            list: res.response.data.list,
            page: res.response.data.page,
          });
          setList(res.response.data.list);
        }
      }
    });
  }

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, getData: any) => {
    // setPageNo(getData.activePage);
    setRequestParams({
      ...requestParams, 
      [getData.name]: getData.activePage
    })
    onRequest({
      ...requestParams, 
      [getData.name]: getData.activePage
    });
  }

  const onConfirm = () => {
    if(setInsertedPost)
      setInsertedPost(selectedPost)
    onCloseClicked();
  }

  const onCloseClicked = () => {    
    setRequestParams(initRequestParams);
    onClose();
  }
  
  const onChange = (event : React.ChangeEvent<HTMLInputElement>, getData: any) => {
    // console.log('data :: ', getData);
    setRequestParams({
      ...requestParams,
      [event.target.name] : event.target.value
    });
  }

  const onSearch = (event : any) => {  
    if(event.key === 'Enter' || event === 'Clicked'){
      setRequestParams({      
        ...requestParams,
        page: 1,
      });

      onRequest({
        ...requestParams,
        page: 1,
      });
    }
  }
  
  
  const headers = [    
    '선택',
    '제목', 
  ];

  const headerWidth = [0.2, 1];
  
  const finder : IFindTextBox = {
    name: 'mainTitle',
    value: requestParams.mainTitle,
    onChange: onChange,
    onSearch: onSearch    
  }  
  
  const pagination : IPagination = {
    name: 'page',
    activePage: requestParams.page,
    totalPage: data.page.totalPages,
    onChange: onChangePage, 
  }
  
  const tableListProps: ITableListProps = {
    title: ' ',
    size: 'small',
    color: 'violet',
    finder,
    headers,
    headerWidth,
    // buttons,
    count: data.page.totalElements,
    pagination,
    refresh: ()=> onRequest()
  }

  const postItems = list
  .filter(item => item.postsId !== postId)
  .map((item:any) => (
    <PostListItem
      key={item.postsId}  
      category={category}
      requestParams={'1'}
      data={item}
      onRequest={onRequest}
      // setIsDeleted= {setIsDeleted}
      type={type}
      selectedPost={selectedPost}
      setSelectedPost={setSelectedPost}
    />
  ));

  const buttons = type === TagPopupTypes.MANAGE ? [
    { onClick: onCloseClicked }
  ] : [
    { onClick: onCloseClicked },
    { onClick: onConfirm }
  ]  

  return (
    <BasePopup
      open={open} 
      id={user.uid}
      size='tiny'
      type={type === 'manage' ? PopupTypes.BASIC : PopupTypes.DIALOG}
      title='연관 포스트 선택'
      buttons={buttons}
      onClose={onCloseClicked}
    >
      <TableList {...tableListProps}>        
        {postItems}
      </TableList>
    </BasePopup>
  );
}


export default PostList;