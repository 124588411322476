import React from 'react';
import { UserMembershipStyles as Styled } from './styles/UserMembership.styles';
import { useUserContext } from './interface';
import useBasics from 'hooks/useBasics';
import { findCode } from 'util/common';
import { ICounts, MEMBERSHIP_OPTION_NAME } from 'store/modules/types';
import MembershipUseInfo from './MembershipUseInfo';
import IrankMembershipInfoCard from './IrankMembershiInfoCard';
import ReviewrayMembershipInfoCard from './ReviewrayMembershiInfoCard';

const REVIERAY_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="57" height="62" viewBox="0 0 57 62" fill="none">
<path d="M27.1863 34.6079V60.7193C27.1863 61.7055 26.1419 62.3194 25.3025 61.8231L18.8055 58.0024C18.4146 57.7738 18.1776 57.3493 18.1776 56.8986V36.4235C18.1776 35.9598 17.9341 35.5353 17.5368 35.3067L10.9565 31.5448C10.1043 31.0549 10.1043 29.8075 10.9565 29.3176L17.5432 25.5165C17.9341 25.2879 18.1776 24.8634 18.1776 24.4062V17.2677C18.1776 16.2815 17.1268 15.6676 16.2938 16.164L9.63021 20.1283C9.24577 20.3569 9.0087 20.7749 9.0087 21.2321V50.0344C9.0087 51.0206 7.9643 51.6345 7.12494 51.1381L0.627918 47.3174C0.237071 47.0888 0 46.6643 0 46.2137V15.9484C0 15.4912 0.237071 15.0733 0.62151 14.8447L25.3025 0.175756C26.1355 -0.32061 27.1863 0.293316 27.1863 1.27952V27.4302L26.2956 28.9911C26.2252 29.1152 26.0842 29.2066 25.924 29.2262L22.9574 29.5985C22.5474 29.6507 22.3872 30.0818 22.6819 30.33L24.8284 32.1391C24.9437 32.2371 25.0014 32.3807 24.9693 32.5179L24.4632 35.0716C24.3927 35.4243 24.822 35.6855 25.1808 35.5222L27.1863 34.6144V34.6079ZM55.3849 14.8447L30.7103 0.175756C29.8773 -0.32061 28.8265 0.293316 28.8265 1.27952V27.2146L29.8389 28.9911C29.9094 29.1152 30.0503 29.2066 30.2105 29.2262L33.1771 29.5985C33.5872 29.6507 33.7474 30.0818 33.4526 30.33L31.3062 32.1391C31.1908 32.2371 31.1332 32.3807 31.1652 32.5179L31.6714 35.0716C31.7419 35.4243 31.3126 35.6855 30.9538 35.5222L28.8265 34.5556V60.7258C28.8265 61.712 29.8709 62.326 30.7103 61.8296L37.2073 58.0089C37.5982 57.7803 37.8352 57.3558 37.8352 56.9051V36.43C37.8352 35.9663 38.0787 35.5418 38.476 35.3132L45.0563 31.5513C45.9085 31.0615 45.9085 29.814 45.0563 29.3242L38.4696 25.5231C38.0787 25.2945 37.8352 24.8699 37.8352 24.4128V17.2742C37.8352 16.288 38.886 15.6741 39.719 16.1705L46.3826 20.1349C46.767 20.3635 47.0041 20.7815 47.0041 21.2386V50.0409C47.0041 51.0271 48.0485 51.641 48.8879 51.1447L55.3849 47.324C55.7757 47.0954 56.0128 46.6709 56.0128 46.2202V15.9484C56.0128 15.4912 55.7757 15.0733 55.3913 14.8447H55.3849Z" fill="url(#paint0_linear_1_24664)"/>
<defs>
<linearGradient id="paint0_linear_1_24664" x1="28.0064" y1="10.2141" x2="28.0064" y2="67.2962" gradientUnits="userSpaceOnUse">
<stop stop-color="#6300FF"/>
<stop offset="0.73" stop-color="#96EADA"/>
</linearGradient>
</defs>
</svg>

const IRANK_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="45" height="73" viewBox="0 0 45 73" fill="none">
<path d="M45 51.537V60.1479L23.0079 73V26.7324L45 13.8803V22.4038L31.4746 30.3104V59.4436L45 51.537ZM0 51.537V60.1479L21.9921 73V26.7324L0 13.8803V22.4038L13.5254 30.3104V59.4436L0 51.537ZM44.4921 12.8521L22.5 25.7042L0.507901 12.8521L22.5 0L44.4921 12.8521ZM28.9402 17.155L27.7111 13.3765L32.917 10.7032L25.7201 10.1532L22.5 6.71908L19.2799 10.1532L12.083 10.7032L17.2889 13.3765L16.0598 17.155L22.4949 15.3711L28.93 17.155H28.9402Z" fill="url(#paint0_radial_1_24601)"/>
<defs>
<radialGradient id="paint0_radial_1_24601" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.5 36.3304) scale(40.6879 38.7123)">
<stop stop-color="#0037FF"/>
<stop offset="0.59" stop-color="#446CFD"/>
<stop offset="1" stop-color="#CDD5F9"/>
</radialGradient>
</defs>
</svg>

const IRANK_TEXT = <svg xmlns="http://www.w3.org/2000/svg" width="73" height="20" viewBox="0 0 73 20" fill="none">
<path d="M68.5375 10.0607C66.9975 10.3807 65.2175 10.4607 63.0775 10.4607H56.8575V8.08074H63.1175C65.1375 8.08074 67.0575 8.04074 68.5975 7.78074V3.66074H56.8575V1.26074H71.3375V7.86074C71.3375 10.1007 71.1175 12.6607 70.7975 14.3407H68.0775C68.2975 13.1607 68.4575 11.5807 68.5375 10.0607ZM55.9375 16.1207H72.2575V18.5007H55.9375V16.1207Z" fill="#353535"/>
<path d="M43.8184 9.18043C45.0584 9.18043 45.8384 8.96043 46.4384 8.52043V1.04043H49.0184V5.10043H50.4384V0.94043H53.0584V12.0604H50.4384V7.48043H49.0184V11.7404H46.4384V10.5804C45.8384 11.1204 44.9184 11.3804 43.0784 11.3804H37.8184V5.14043H42.2384V3.36043H37.8184V1.16043H44.8984V7.34043H40.4584V9.18043H43.8184ZM38.8184 16.0404C38.8184 14.8404 39.4184 14.0004 40.4384 13.4204C41.7584 12.6604 43.8184 12.3804 46.1584 12.3804C48.4784 12.3804 50.5384 12.6604 51.8784 13.4204C52.8984 14.0004 53.4984 14.8404 53.4984 16.0404C53.4984 17.2404 52.8984 18.1004 51.8784 18.6804C50.5384 19.4204 48.4784 19.7004 46.1584 19.7004C43.8184 19.7004 41.7584 19.4204 40.4384 18.6804C39.4184 18.1004 38.8184 17.2404 38.8184 16.0404ZM41.5584 16.0404C41.5584 16.3604 41.7384 16.6204 42.1184 16.8204C42.8384 17.2004 44.2584 17.3804 46.1584 17.3804C48.0584 17.3804 49.4784 17.2004 50.1984 16.8204C50.5584 16.6204 50.7584 16.3604 50.7584 16.0404C50.7584 15.7204 50.5584 15.4604 50.1984 15.2804C49.4784 14.9004 48.0584 14.7204 46.1584 14.7204C44.2584 14.7204 42.8384 14.9004 42.1184 15.2804C41.7384 15.4604 41.5584 15.7204 41.5584 16.0404Z" fill="#353535"/>
<path d="M28.8984 9.64043C28.8984 12.8604 28.6384 15.3204 27.5184 16.7804C26.7584 17.7804 25.5384 18.3204 24.0984 18.3204C22.6784 18.3204 21.4584 17.7804 20.6784 16.7804C19.5584 15.3204 19.3184 12.8604 19.3184 9.64043C19.3184 6.44043 19.5584 3.98043 20.6784 2.52043C21.4584 1.52043 22.6784 0.960429 24.0984 0.960429C25.5384 0.960429 26.7584 1.52043 27.5184 2.52043C28.6384 3.98043 28.8984 6.44043 28.8984 9.64043ZM22.0184 9.64043C22.0184 12.3604 22.1584 14.3804 22.7584 15.2804C23.0584 15.7204 23.5384 15.9604 24.0984 15.9604C24.6584 15.9604 25.1584 15.7204 25.4584 15.2804C26.0584 14.3804 26.1784 12.3604 26.1784 9.64043C26.1784 6.94043 26.0584 4.92043 25.4584 4.02043C25.1584 3.58043 24.6584 3.34043 24.0984 3.34043C23.5384 3.34043 23.0584 3.58043 22.7584 4.02043C22.1584 4.92043 22.0184 6.94043 22.0184 9.64043ZM34.2984 19.3004H31.5584V0.94043H34.2984V19.3004Z" fill="#353535"/>
<path d="M10.2992 9.64043C10.2992 12.8604 10.0392 15.3204 8.91922 16.7804C8.15922 17.7804 6.93922 18.3204 5.49922 18.3204C4.05922 18.3204 2.83922 17.7804 2.07922 16.7804C0.959219 15.3204 0.699219 12.8604 0.699219 9.64043C0.699219 6.44043 0.959219 3.98043 2.07922 2.52043C2.83922 1.52043 4.05922 0.960429 5.49922 0.960429C6.93922 0.960429 8.15922 1.52043 8.91922 2.52043C10.0392 3.98043 10.2992 6.44043 10.2992 9.64043ZM3.41922 9.64043C3.41922 12.3604 3.53922 14.3804 4.13922 15.2804C4.43922 15.7204 4.93922 15.9604 5.49922 15.9604C6.05922 15.9604 6.53922 15.7204 6.83922 15.2804C7.43922 14.3804 7.57922 12.3604 7.57922 9.64043C7.57922 6.94043 7.43922 4.92043 6.83922 4.02043C6.53922 3.58043 6.05922 3.34043 5.49922 3.34043C4.93922 3.34043 4.43922 3.58043 4.13922 4.02043C3.53922 4.92043 3.41922 6.94043 3.41922 9.64043ZM15.3392 8.22043H18.0992V10.5804H15.3392V19.3004H12.5992V0.94043H15.3392V8.22043Z" fill="#353535"/>
</svg>

const REVIEWRAY_TEXT = <svg xmlns="http://www.w3.org/2000/svg" width="69" height="18" viewBox="0 0 69 18" fill="none">
<path d="M57.8747 2.74016C57.6141 2.74016 57.3687 2.79383 57.1387 2.90116C56.9087 2.99316 56.7017 3.1235 56.5177 3.29216C56.3337 3.4455 56.1957 3.63716 56.1037 3.86716C56.0117 4.09716 55.9657 4.3425 55.9657 4.60316V13.0672C55.9657 13.3278 56.0117 13.5732 56.1037 13.8032C56.1957 14.0178 56.3337 14.2095 56.5177 14.3782C56.7017 14.5468 56.9087 14.6848 57.1387 14.7922C57.3687 14.8842 57.6141 14.9302 57.8747 14.9302H58.7027C58.9634 14.9302 59.2011 14.8842 59.4157 14.7922C59.6304 14.6848 59.8221 14.5468 59.9907 14.3782C60.1594 14.1942 60.2897 13.9948 60.3817 13.7802C60.4737 13.5502 60.5197 13.3125 60.5197 13.0672V4.60316C60.5197 4.35783 60.4737 4.12783 60.3817 3.91316C60.2897 3.68316 60.1594 3.48383 59.9907 3.31516C59.8374 3.1465 59.6457 3.0085 59.4157 2.90116C59.2011 2.79383 58.9711 2.74016 58.7257 2.74016H57.8747ZM58.8407 0.118164C59.4387 0.118164 59.9984 0.24083 60.5197 0.486163C61.0411 0.731497 61.4934 1.06116 61.8768 1.47516C62.2754 1.87383 62.5821 2.34916 62.7967 2.90116C63.0267 3.43783 63.1418 4.00516 63.1418 4.60316V13.0672C63.1418 13.6652 63.0267 14.2325 62.7967 14.7692C62.5667 15.3058 62.2524 15.7812 61.8537 16.1952C61.4704 16.6092 61.0104 16.9312 60.4737 17.1612C59.9371 17.4065 59.3697 17.5292 58.7717 17.5292H57.8747C57.2461 17.5292 56.6557 17.4142 56.1037 17.1842C55.5517 16.9542 55.0688 16.6398 54.6548 16.2412C54.2561 15.8272 53.9341 15.3518 53.6887 14.8152C53.4587 14.2785 53.3438 13.6958 53.3438 13.0672V4.60316C53.3438 3.9745 53.4587 3.39183 53.6887 2.85516C53.9341 2.3185 54.2561 1.85083 54.6548 1.45216C55.0688 1.03816 55.5517 0.716164 56.1037 0.486163C56.6557 0.24083 57.2461 0.118164 57.8747 0.118164H58.8407ZM68.0637 17.5292H65.2348V0.118164H68.0637V17.5292Z" fill="#353535"/>
<path d="M42.2449 17.4372H35.5059V7.52416H39.6919V2.71716H35.5059V0.210164H42.3139V10.1922H38.1279V15.0222H42.2449V17.4372ZM44.3149 8.67416H42.8889V6.23616H44.3149V0.118164H46.8679V17.5292H44.3149V8.67416ZM50.7549 17.5292H48.1789V0.118164H50.7549V17.5292Z" fill="#353535"/>
<path d="M18.195 9.68637V0.0263672H21.047V2.83237H29.672V0.0263672H32.524V9.68637H18.195ZM33.72 11.2964V13.7344H29.902V17.5294H27.326V13.7344H23.646V17.5294H21.07V13.7344H17.252V11.2964H33.72ZM21.047 7.20237H29.672V5.33937H21.047V7.20237Z" fill="#353535"/>
<path d="M15.1005 17.5292H12.5705V0.118164H15.1005V17.5292ZM9.48845 0.210164V10.1462H3.14045V15.0222H9.69545V17.4372H0.564453V7.50116H6.91245V2.71716H0.564453V0.210164H9.48845Z" fill="#353535"/>
</svg>

interface IProps {
  onOpen: () => void
}

const UserMembership: React.FC<IProps> = (props) => {
  const { onOpen } = props;
  const { basics } = useBasics();
  const { commonCodeList } = basics;
  const { selectedUser, setSelectedUser } = useUserContext();
  

  if (!selectedUser) return <></>

  
  const irankMembership = selectedUser.customerMembership.irankMembership
  const irankSubscription = irankMembership.membership === 'FREE' || !irankMembership.isEnd

  const irankmembershipText = irankSubscription
    ? `${
        findCode(
          commonCodeList.membership_type,
          irankMembership.membership
            ? irankMembership.membership
            : '',
        ).value
      } ${
        irankMembership.membership !== 'FREE'
          ? MEMBERSHIP_OPTION_NAME[irankMembership.option]
          : ''
      } 멤버십`
    : '구독만료'

  // 리뷰레이 멤버십
  const reviewMembership = selectedUser.customerMembership.reviewMembership
  const reviewraySubscription = reviewMembership.membership === 'FREE' || !reviewMembership.isEnd

  const productCount = reviewMembership.counts.find(
    (item) => item.item === 'CONTRACT_PRODUCT_COUNT',
  )
  const reviewCount = reviewMembership.counts.find(
    (item) => item.item === 'CONTRACT_REVIEW_COUNT',
  )
  const reviewrayMembershipText = reviewraySubscription
    ? reviewMembership.membership === 'FREE'
      ? `${
          findCode(
            commonCodeList.membership_type,
            reviewMembership.membership
              ? reviewMembership.membership
              : '',
          ).value
        } 멤버십`
      : `상품 ${productCount ? productCount.currentCount.toLocaleString() : 0}/${
          productCount ? productCount.limitCount.toLocaleString() : 0
        } 리뷰 ${reviewCount ? reviewCount.currentCount.toLocaleString() : 0}/${
          reviewCount ? reviewCount.limitCount.toLocaleString() : 0
        } 이용중`
    : '계약만료'

    

  return <Styled.UserMembershipsWrap>
    <Styled.UserMembershipWrap>
      <Styled.UserMembershiInfo>
        <Styled.MembershipTitleWrap>
          {IRANK_ICON}
          <Styled.MembershipTitle>
            {IRANK_TEXT}
            <Styled.Text size='12px' weight={400} color='#55585C'>클릭 한번으로 정확하고 빠른 진단</Styled.Text>
          </Styled.MembershipTitle>
        </Styled.MembershipTitleWrap>
        <Styled.MembershipInfo>{irankmembershipText}</Styled.MembershipInfo>
        <Styled.UseDate>{
          irankMembership.membershipStartDate && irankMembership.membershipEndDate 
            ? `이용기간 : ${irankMembership.membershipStartDate} ~ ${irankMembership.membershipEndDate}`
            : ''
          }
        </Styled.UseDate>
      </Styled.UserMembershiInfo>
      <MembershipUseInfo title={'아이랭크 이용현황'}>
        <>
          {/* <IrankMembershipInfoCard title={'키워드 분석'} items={[{
            text: '이번달 조회수',
            value: getItemValue(
              irankMembership.membershipItems.find((item) => item.item === 'KEYWORD_ANALYSIS_COUNT')?.itemValue
            ),
            unit: '회',
          }]} /> */}
          <IrankMembershipInfoCard title={'키워드 분석'} items={[
            {text : '누적 조회 수', value: `${irankMembership.membershipCount.keywordCount.total}`, unit:'회'},
            {text : '이번달 조회 수', value: `${irankMembership.membershipCount.keywordCount.month}`, unit:'회'}
          ]} />
          <IrankMembershipInfoCard title={'상품 진단'} items={[
            {text : '누적 조회 수', value: `${irankMembership.membershipCount.productCount.total}`, unit:'회'},
            {text : '이번달 조회 수', value: `${irankMembership.membershipCount.productCount.month}`, unit:'회'},
            {text : '등록 상품 수', value: `${irankMembership.membershipCount.productCount.product}`, unit:'개'},
          ]} />
          <IrankMembershipInfoCard title={'카테고리 분석'} items={[
            {text : '누적 조회 수', value: `${irankMembership.membershipCount.recommendCount.total}`, unit:'회'},
            {text : '이번달 조회 수', value: `${irankMembership.membershipCount.recommendCount.month}`, unit:'회'},
            {text : '등록 상품 수', value: `${irankMembership.membershipCount.recommendCount.product}`, unit:'개'},
          ]} />
          <IrankMembershipInfoCard title={'모니터링'} items={[
            {text : '등록 상품 수', value: `${irankMembership.membershipCount.monitoringCount.product}`, unit:'개'},
            {text : '둥록 키워드 수', value: `${irankMembership.membershipCount.monitoringCount.keyword}`, unit:'개'},
            {text : '등록 그룹 수', value: `${irankMembership.membershipCount.monitoringCount.group}`, unit:'개'},
          ]} />
        </>
      </MembershipUseInfo>
    </Styled.UserMembershipWrap>
    <Styled.UserMembershipWrap>
      <Styled.UserMembershiInfo>
        <Styled.MembershipTitleWrap>
          {REVIERAY_ICON}
          <Styled.MembershipTitle>
            {REVIEWRAY_TEXT}
            <Styled.Text size='12px' weight={400} color='#55585C'>리뷰에서 해답을 찾다!</Styled.Text>
          </Styled.MembershipTitle>
        </Styled.MembershipTitleWrap>
        <Styled.MembershipInfo>{reviewrayMembershipText}</Styled.MembershipInfo>
        <Styled.UseDate>{
          reviewMembership.membershipStartDate && reviewMembership.membershipEndDate 
            ? `이용기간 : ${reviewMembership.membershipStartDate} ~ ${reviewMembership.membershipEndDate}`
            : ''
          }
        </Styled.UseDate>
      </Styled.UserMembershiInfo>
      <MembershipUseInfo title={'리뷰레이 이용현황'}>
        <>
          <ReviewrayMembershipInfoCard items={[{
            text:'상품 등록수',
            value: `(${getReviewrayItem('CONTRACT_PRODUCT_COUNT', reviewMembership.counts).currentCount}/${getReviewrayItem('CONTRACT_PRODUCT_COUNT', reviewMembership.counts).limitCount})`
          }, {
            text:'상품 변경수',
            value: `(${reviewMembership.productChangeCount}/${reviewMembership.productChangeLimit})`
          }, {
            text:'리뷰 등록수',
            value: `(${getReviewrayItem('CONTRACT_REVIEW_COUNT', reviewMembership.counts).currentCount}/${getReviewrayItem('CONTRACT_REVIEW_COUNT', reviewMembership.counts).limitCount})`
          },]} />
          <ReviewrayMembershipInfoCard items={[{
            text:'상품 리뷰 분석',
            value: `(${getReviewrayItem('REVIEW_PRODUCT_ANALYSIS', reviewMembership.counts).currentCount}/${getReviewrayItem('REVIEW_PRODUCT_ANALYSIS', reviewMembership.counts).limitCount})`
          }]} />
          <ReviewrayMembershipInfoCard items={[{
            text:'경쟁 상품 등록 수',
            value: `(${getReviewrayItem('REVIEW_COMPARISON', reviewMembership.counts).currentCount}/${getReviewrayItem('REVIEW_COMPARISON', reviewMembership.counts).limitCount})`
          }]} />
        </>
      </MembershipUseInfo>
      <Styled.ShowReviewrayProducts onClick={onOpen}>{`등록 상품 보기 >`}</Styled.ShowReviewrayProducts>
    </Styled.UserMembershipWrap>
  </Styled.UserMembershipsWrap> 
}

const getReviewrayItem = (key: string, items?: ICounts[]) => {
  const item = items?.find(item => item.item === key)

  if (item) {
    return item
  }

  return {
    currentCount: 0,
    item: key,
    limitCount: 0
  }
}

const getItemValue = (itemValue?:string) => {
  if (itemValue) {
    return itemValue
  }

  return '0'
}

export default UserMembership