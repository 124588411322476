import React, { useState, useEffect } from "react";
import { TrendKeywordStyles as Styled } from "../styles/TrendKeywordStyle/TrendKeyword.style";
import TrendKeywordInfo from "./TrendKeywordInfo";
import {
  CustomizedButton as LinkButton,
  Title as MarketName,
} from "../styles/CommonStyle/Common.style";
import useTrendKeyword from "hooks/useTrendKeyword";
interface IProps {
  searchDate: string;
}
const TrendKeyword: React.FC<IProps> = (props) => {
  const { searchDate } = props;
  const [currentMallId, setCurrentMallId] = useState<string>("mall0148");
  const [currentMarket, setCurrentMarket] = useState<string>("스마트스토어");
  const [currentLink, setCurrentLink] = useState<string>(
    "https://search.shopping.naver.com/best/category/keyword?categoryCategoryId=ALL&chartRank=1"
  );
  const [selectedButtonIndex, setSelectedButtonIndex] = useState<number>(0);
  const [isMissing, setIsMissing] = useState<boolean>(false);
  const marketInfos = [
    {
      mallId: "mall0148",
      marketName: "스마트스토어",
      link: "https://search.shopping.naver.com/best/category/keyword?categoryCategoryId=ALL&chartRank=1",
    },
    {
      mallId: "mall0025",
      marketName: "11번가",
      link: "https://www.11st.co.kr/main",
    },
    {
      mallId: "mall0010",
      marketName: "G마켓",
      link: "https://m.gmarket.co.kr/#tab_keyword",
    },
    {
      mallId: "mall0003",
      marketName: "옥션",
      link: "https://m.auction.co.kr/",
    },
    {
      mallId: "mall0287",
      marketName: "위메프",
      link: "https://search.wemakeprice.com/main",
    },
    {
      mallId: "mall0012",
      marketName: "인터파크",
      link: "https://www.interpark.com/",
    },
    {
      mallId: "mall0182",
      marketName: "티몬",
      link: "https://home.tmon.co.kr/",
    },
    { mallId: "mall0024", marketName: "SSG", link: "https://www.ssg.com/" },
    {
      mallId: "mall0187",
      marketName: "롯데백화점",
      link: "https://www.lotteon.com/p/display/main/ellotte?mall_no=2&ch_no=100195&ch_dtl_no=1000617",
    },
    {
      mallId: "mall0033",
      marketName: "AK몰",
      link: "https://m.akmall.com/search/SearchMain.do",
    },
  ];

  return (
    <>
      <Styled.Section>
        <Styled.ButtonContainer>
          {marketInfos.map((market, index) => (
            <Styled.Button
              key={index}
              selected={index === selectedButtonIndex}
              missingKeyword={isMissing}
              onClick={() => {
                setCurrentMallId(market.mallId);
                setCurrentMarket(market.marketName);
                setCurrentLink(market.link);
                setSelectedButtonIndex(index);
              }}
            >
              {market.marketName}
            </Styled.Button>
          ))}
        </Styled.ButtonContainer>
      </Styled.Section>
      <MarketName>{currentMarket}</MarketName>
      <TrendKeywordInfo
        searchDate={searchDate}
        mallId={currentMallId}
        link={currentLink}
      ></TrendKeywordInfo>
    </>
  );
};
export default TrendKeyword;
