import React, { useState } from 'react';
// Components
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import TableList, { ITableListProps, ITableListButton, IFindTextBox, ISelectBox, IPagination } from 'components/common/table/TableList';
import EventListItem from './EventListItem';
import EventWrite from './popup/EventWrite';
import EventDelete from './popup/EventDelete';
import EventDetail from './popup/EventDetail';
// Interface
import { IPopupParams, initIPopupParams } from './interface';
import { IPage } from 'store/modules/types';

interface IProps {
  requestParams: any;
  setRequestParams: Function;
  typeList: any[];
  eventList: {
    list: any[],
    page: IPage
  };
  refresh: Function;
}
const EventList: React.FC<IProps> = (props) => {
  const { requestParams, setRequestParams, typeList, eventList, refresh } = props;
  // Snackbar Message params
  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ resultMsg, setResultMsg] = useState('');
  // Popup mode
  const [ popupMode, setPopupMode ] = useState<IPopupParams>(initIPopupParams);

  // Snackbar Close
  const onCloseSnackbar = () => {
    setResultMsg('');
    setSnackbarOpen(false);
  };

  const onClose = () => {
    setPopupMode({
      ...popupMode,
      open: false,
      mode: '',
      eventNo: 0
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestParams({
      ...requestParams,
      [event.target.name]: event.target.value
    });
  };

  const onSearch = (event: any) => {
    if(event.key === 'Enter' || event === 'Clicked'){
      refresh({
        ...requestParams,
        page: 1,
      });
    }
  };

  // const onCategorySearch = (event: React.SyntheticEvent<HTMLElement, Event>, getData: any) => {    
  //   const value = getData.value === 'ALL' ? '' : getData.value;
  //   refresh({
  //     ...requestParams,
  //     pageNumber: 1,
  //     searchType: value
  //   });
  // };

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page:any) => {
    refresh({
      ...requestParams,
      [page.name]: page.activePage
    })
  };

  const onAddButtonClicked = () => {
    setPopupMode({
      ...popupMode,
      open: true,
      mode: 'write'
    });
  };

  // Table drawing Start
  // const select : ISelectBox = {
  //   name: 'searchType',
  //   value: requestParams.searchType === '' ? 'ALL' : requestParams.searchType,
  //   // defaultValue: 'ALL',
  //   options: [{value:'ALL', text:'전체'}].concat(typeList),
  //   onChange: onCategorySearch
  // }
  // Finder
  const finder: IFindTextBox = {
    name: 'title',
    value: requestParams.title,
    onChange: onChange,
    onSearch: onSearch
  };
  // Header
  const title = <div style={{font:'inherit', textAlign:'left'}}>제목</div>;
  const headers = ['', '썸네일', title, 'NEW아이콘', '당첨유무', '종료유무', '게시유무', '팝업노출', '회원전용', '시작일', '종료일', ''];
  const headerWidth = [1, 2, 4, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  // Pagination
  const pagination: IPagination = {
    name: 'page',
    activePage: requestParams.page,
    totalPage: eventList.page.totalPages,
    onChange: onChangePage
  };
  // Button
  const buttons: ITableListButton[] = [
    {
      name: '이벤트 등록하기',
      icon: 'add',
      onButtonClicked: onAddButtonClicked
    }
  ];
  // Table
  const tableListProps: ITableListProps = {
    title: '',
    //select: select,
    size: 'small',
    color: 'violet',
    headers,
    headerWidth,
    finder,
    pagination,
    buttons,
    count: eventList.page.totalElements,
    refresh: () => refresh()
  };
  // Table drawing End

  const itemList = eventList.page.totalPages > 0 ? eventList.list.map((item:any, index:number) => {
    return (
      <EventListItem
        typeList={typeList}
        event={item}
        setPopupMode={setPopupMode}
        key={index}
      />
    )
  }) : null;

  // Popup
  const writePopup = popupMode.open && ( popupMode.mode === 'write' || popupMode.mode === 'edit' ) ? 
    <EventWrite
      eventNo={popupMode.eventNo}
      typeList={typeList}
      open={popupMode.open}
      onClose={onClose}
      showFeedback= {(msg:string) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  : null;

  const deletePopup = popupMode.open && popupMode.mode === 'delete' ? 
    <EventDelete
      eventNo={popupMode.eventNo}
      open={popupMode.open}
      onClose={onClose}
      showFeedback= {(msg:string) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  : null;

  const detailPopup = popupMode.open && popupMode.mode === 'detail' ?
    <EventDetail
      eventNo={popupMode.eventNo}
      typeList={typeList}
      open={popupMode.open}
      onClose={onClose}
    />
  : null;
  
  return (
    <>

      <TableList {...tableListProps}>        
        { itemList }
      </TableList>

      {writePopup}
      {deletePopup}
      {detailPopup}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}

const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default EventList;