import styled, { css } from 'styled-components';

interface IStyleProps {
  flex?: number;
}

interface IColorWrapStyles {
  isGradation: boolean
  backgroundColor: string
}

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Item = styled.li`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
`

const Title = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`

const Div = styled.div`
  display: flex;
  align-items: center;
`

const Input = styled.input.attrs({ readonly: true, disabled: true })<IStyleProps>`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  flex: ${({ flex }) => flex && flex};

  &:focus {
    outline: none;
  }
`

const ColorPicker = styled.input`
  
`

const Text = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  cursor: default;
  margin-right: 15px;
  display: flex;
  align-items: center;
`

const Radio = styled.input.attrs({ type: 'radio' })`
  width: 15px;
  height: 15px;
  cursor: default;
  margin-right: 5px;
`

const Image = styled.img`
  width: 100%;
  height: 200px;
`

const Link = styled.a`
  font-size: 14px;
  padding: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
`

const ColorWrap = styled.div<IColorWrapStyles>`
  margin-left: 10px;
  width: 100px;
  height: 20px;

  ${({ isGradation, backgroundColor }) => isGradation ? css`
    background-image: ${backgroundColor};
  ` : css`
    background-color: ${backgroundColor};
  `}
`

export const BannerDetailStyles = {
  List,
  Item,
  Title,
  Div,
  Input,
  Text,
  Label,
  Radio,
  Image,
  Link,
  ColorPicker,
  ColorWrap,
}
