import React from "react"
import { TReviewProductType } from "store/modules/types"
import styled from "styled-components"

const Wrap = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const ProductWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`

const Image = styled.img`
  width: 80px;
  height: 80px;
`

const ProductInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ProductTitle = styled.span`
  color: #353535;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  
  width: 50%;
  display: -webkit-box;
  overflow:hidden; 
  word-wrap: break-word;
  text-overflow:ellipsis; 
  white-space:normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const Category = styled.span`
  color: #7A8483;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Price = styled.span`
  color: #353535;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  small {
    font-weight: 500;
  }
`

const EtcWrap = styled.div`
  display: flex;
  justify-content: space-between;

  width: 350px;
`

const EtcInfoWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 5px;
`

const Text = styled.span<{color: string, size: string, weight: number}>`
  font-size: ${({size}) => size};
  font-weight: ${({weight}) => weight};
  color: ${({color}) => color};
`

interface IProps {
  product: TReviewProductType
}

const ReviewrayProductInfo: React.FC<IProps> = (props) => {
  const { product } = props

  return <Wrap>
    <ProductWrap>
      <Image 
        src={product.productThumbnailUrl}
        alt=''
      />
      <ProductInfoWrap>
        <ProductTitle>{product.productName}</ProductTitle>
        <Category>{product.category}</Category>
        <Price>{`${product.productSalePrice.toLocaleString()}원`}</Price>
      </ProductInfoWrap>
    </ProductWrap>
    <EtcWrap>
        <EtcInfoWrap>
          <Text size="12px" color="#7A8483" weight={400}>전체 리뷰</Text>
          <Text size="12px" color="#353535" weight={700}>{product.reviewCount.toLocaleString()}</Text>
        </EtcInfoWrap>
        <EtcInfoWrap>
          <Text size="12px" color="#7A8483" weight={400}>평점</Text>
          <Text size="12px" color="#ED0D93" weight={700}>{product.starPoint}</Text>
        </EtcInfoWrap>
        <EtcInfoWrap>
          <Text size="12px" color="#7A8483" weight={400}>평점</Text>
          <Text size="12px" color="#313131" weight={700}>{Math.round(product.cast * 100) / 100}</Text>
        </EtcInfoWrap>
      </EtcWrap>
  </Wrap>
}

export default ReviewrayProductInfo