import styled, { css } from 'styled-components'
import { 
  Form,
  Button,
} from 'semantic-ui-react';


const SearchDiv = styled.div`
  margin: 0 10px;
`

const SearchButtonDiv = styled.div`
`

const InputLabelDiv = styled.div`
  width: 100%;
`

const InputLabelSpan = styled.span`
  display: flex;
  align-items: center;
  margin-left: 9px;
  font-size: 10px;
  color: #A6A6A6;
`

const RowDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const ColumnDiv = styled.div`
  display: flex; 
  flex-direction: column;
  flex: 1;

  & + & {
    margin-left: 10px;
  }
`

const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
  .field {
    flex: 1;
    font-size: 1em;
  }
  .field:nth-of-type(n+2) {
    margin-left: 5px;
    font-size: 1em;
  } 
`

const FormSelect = styled(Form.Select)`
  #header-select-box {
    width: 100%;
    height: 33px;
    font-size: 12px;
    .menu {
      max-height: 9.5em;
      .item {
        font-size: 1em;
        height: 2.7em;
        padding: .78571429em 2.1em .78571429em 1em !important;
        .text {
          font-size: 1em;
        }
      }
    }
  }
`

const SearchInputDiv = styled.div`
  width: 100%;
  margin-left: 5px;
`

const PickrClose = styled.div`
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
`

const UserRoleTypesDiv = styled.div`
  &&& {
    margin-top: 3px;
  }
`;

const UserRoleTypesLabel = styled.span`
  &&& {
    display: block;
    margin-left: 9px;
    font-size: 10px;
    color: #A6A6A6;
  }
`

const ToggleButtonDiv = styled.div`
  &&& {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-start;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.45em;
  }
`;

const ToggleButton = styled(Button as any)`
  &&& {
    padding: 5px 10px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    background: #fff;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
    margin: 3px 2px;
    &:hover {
      background: rgba(95, 117, 209, 0.25);
    ${(props: {active: boolean}) => 
      props.active && 
      css`
        background: rgba(95, 117, 209, 0.5);
        color: white;
        font-weight: 500;
      `      
    }
      
    }
    ${(props: {active: boolean}) => 
      props.active && 
      css`
        background: rgba(95, 117, 209, 0.5);
        color: white;
        font-weight: 500;
      `
      
    }
  }
`

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;

  & + & {
    margin-left: 10px;
  }
`

const Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
`

const FormCheckBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 0 5px;
`


export const UserSearchStyles = {
  SearchDiv,
  SearchButtonDiv,
  InputLabelDiv,
  InputLabelSpan,
  RowDiv,
  ColumnDiv,
  FlexRowDiv,
  SearchInputDiv,
  PickrClose,
  FormSelect,
  UserRoleTypesDiv,
  UserRoleTypesLabel,
  ToggleButtonDiv,
  ToggleButton,
  FormDiv,
  Text,
  FormCheckBox,
  CheckBox,
  Label,
}