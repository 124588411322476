import React, { SyntheticEvent } from 'react';

import clsx from 'clsx';

import { NEXT_MENUS as MENUS, IMenuParams } from 'components/common/constants';

// material-ui
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, Tooltip, Zoom } from '@material-ui/core';

// Styles
import { LNBMenuStyle as Styled } from './styles/LNBMenuStyle.styles';

// Hooks
import useBasics from 'hooks/useBasics';

const useStyles = makeStyles({
  listItemRoot: {
    '&$focusVisible': {
      backgroundColor: '#3c5adb',
    },
    '&$selected, &$selected:hover': {
      backgroundColor: '#3c5adb',
    },
  },
  listItemTextPrimary: {
    color: '#fff',
    fontSize: '0.85rem',
    fontWeight: 800
  }
});

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

interface IProps {
  open: boolean
}

const LNBMenu: React.FC<IProps>  = (props: IProps) => {
  const classes = useStyles();
  const { basics, changeMenu } = useBasics();
  const { menu } = basics;
  const { open } = props;


  const handleMenuClick = (e: SyntheticEvent, item: any) => {
    changeMenu(item);
  }

  const menuItems = MENUS.map((item: IMenuParams) => {
    
    const name = item.name;
    const selected = menu && menu.key === item.key;
    const icon = <i className="material-icons lnb-md-light">{item.icon}</i>;

    return (
      <CustomTooltip key={name} arrow title={name} TransitionComponent={Zoom} disableHoverListener={open}>
        <ListItem
          classes={{
            root: classes.listItemRoot
          }}
          button
          selected={selected}
          onClick={(e) => handleMenuClick(e, item)}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <Styled.MenuItemText
            classes={{
              primary: classes.listItemTextPrimary
            }}
            primary={name}
          />
        </ListItem>
      </CustomTooltip>
    )
  });

  return (
    <List>
      {menuItems}
    </List>
  );
}



export default LNBMenu;