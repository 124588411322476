import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import { BestProductState } from './types';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
    requestTodayBest: createAction(actionTypes.GET_DATA_CONFIRM_TODAY_BEST),
    requestTodayBestSuccess: createAction(actionTypes.SUCCEEDED_GET_DATA_CONFIRM_TODAY_BEST),
    requestTodayBestFailed: createAction(actionTypes.FAILED_GET_DATA_CONFIRM_TODAY_BEST),
    requestRecollectTodayBest: createAction(actionTypes.RECOLLECT_TODAY_BEST),
    requestRecollectTodayBestSuccess: createAction(actionTypes.SUCCEEDED_RECOLLECT_TODAY_BEST),
    requestRecollectTodayBesFailure: createAction(actionTypes.FAILED_RECOLLECT_TODAY_BEST),
}

const initialState: BestProductState = {
    bestData: {
        size: 0,
        list: []
      }  
    };

const reducer = handleActions<BestProductState>(
  {
    [actionTypes.SUCCEEDED_GET_DATA_CONFIRM_TODAY_BEST]: (state, action): BestProductState => {
      return {
        ...state,
        bestData: action.payload.bestData
      }
    },
    //failed data 일 경우 
    // [actionTypes.FAILED_GET_DATA_CONFIRM_TODAY_BEST]: (state, action): BestProductState => {
    //   return {
    //     bestData: action.payload.bestData 
    //   }
    // },
  },
  initialState
  );

const requestBestProductEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_DATA_CONFIRM_TODAY_BEST,
        success: (res: AjaxResponse) => action.requestTodayBestSuccess({ bestData: res.response.data }),
        fail: actionTypes.FAILED_GET_DATA_CONFIRM_TODAY_BEST
      },
      url: API_URL.BEST_PRODUCT,
      method: AjaxMethods.GET
    }
    return epicService.request(actions$, reqParams);
    ;
}

const requestRecollectBestProductEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.RECOLLECT_TODAY_BEST,
      success: (res: AjaxResponse) => action.requestRecollectTodayBestSuccess({ bestData: res.response.data }),
      fail: actionTypes.FAILED_RECOLLECT_TODAY_BEST
    },
    url: API_URL.BEST_PRODUCT,
    method: AjaxMethods.POST
  }
  return epicService.request(actions$, reqParams);
  ;
}

const epic = combineEpics(
  requestBestProductEpic,
  requestRecollectBestProductEpic
);
export{action, epic};
export default reducer;