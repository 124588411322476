import React, { useEffect, useState } from "react";
import { MonitoringKeywords } from "store/modules/types";
import { MonitoringInfoStyles as Styled } from "../styles/MonitoringStyle/MonitoringInfo.style";
import Card from "../Card";
import {
  Table,
  Title,
  RecollectButtonContainer,
} from "../styles/CommonStyle/Common.style";
import { CustomizedButton } from "../styles/CommonStyle/Common.style";
import useMonitoringKeyword from "hooks/useMonitoringKeyword";

interface IProps {
  searchDate: string;
  total: number;
  failedCount: number;
  monitoringKeywords: MonitoringKeywords[];
}

const MonitoringKeyword: React.FC<IProps> = (props) => {
  const { searchDate, total, failedCount, monitoringKeywords } = props;
  const { requestRecollectKeywordMonitoring } = useMonitoringKeyword();
  const today = new Date().toISOString().slice(0, 10);
  const [checkedProducts, setCheckedProducts] =
    useState<MonitoringKeywords[]>(monitoringKeywords);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );

  const getInitialCheckedItems = (num: number) => {
    const initialCheckedItems: { [key: number]: boolean } = {};
    for (let i = 0; i < num; i++) {
      initialCheckedItems[i] = true;
    }
    return initialCheckedItems;
  };

  useEffect(() => {
    setCheckedProducts(monitoringKeywords);
    setCheckedItems(getInitialCheckedItems(monitoringKeywords.length));
  }, [monitoringKeywords]);

  const handleCheckboxChange = (idx: number) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [idx]: !prevCheckedItems[idx],
      };

      const updatedCheckedProducts = monitoringKeywords.filter(
        (_, index) => newCheckedItems[index]
      );

      setCheckedProducts(updatedCheckedProducts);
      return newCheckedItems;
    });
  };

  const onRecollect = (monitoringKeywords: MonitoringKeywords[]) => {
    if (!monitoringKeywords.length) {
      alert("미수집 리스트가 존재 하지 않습니다");
      return;
    }
    const productIds: number[] = Array.from(
      new Set(monitoringKeywords.map((obj) => obj.monitoringProductId))
    );

    const productItemIds: number[] = Array.from(
      new Set(monitoringKeywords.map((obj) => obj.id))
    );

    const payload: any = {
      body: {
        crawlerTarget: "TARGETADMINRANKING",
        productIds: productIds,
        productItemIds: productItemIds,
      },
      callback: (succeeded: boolean, res: any) => {
        if (succeeded) {
          alert("재수집 요청 성공");
        } else {
          if (res.status === 409) {
            alert("재수집 중");
          } else {
            alert("재수집 실패");
          }
        }
      },
    };
    requestRecollectKeywordMonitoring(payload);
  };

  return (
    <Styled.Container>
      <RecollectButtonContainer>
        <Title>키워드 랭킹 정보</Title>
        {searchDate === today && (
          <CustomizedButton onClick={() => onRecollect(checkedProducts)}>
            재수집
          </CustomizedButton>
        )}
      </RecollectButtonContainer>
      <Styled.CardContainer>
        <Card text="전체" value={total}></Card>
        <Card text="수집완료" value={total - failedCount}></Card>
        <Card text="실패" value={failedCount}></Card>
      </Styled.CardContainer>
      <Styled.Text>미수집 리스트 ({monitoringKeywords.length})</Styled.Text>
      {monitoringKeywords.length > 0 && (
        <Table isChecklist={true}>
          <thead>
            <tr>
              <th>product_id</th>
              <th>monitoring_product_id</th>
              <th>item_type</th>
              <th>item_value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {monitoringKeywords.map((item, idx) => (
              <tr key={idx}>
                <td>{item.productId}</td>
                <td>{item.monitoringProductId}</td>
                <td>{item.itemType}</td>
                <td>{item.itemValue}</td>
                <td>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(idx)}
                    checked={!!checkedItems[idx]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Styled.Container>
  );
};

export default MonitoringKeyword;
