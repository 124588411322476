import React, { useState, SyntheticEvent } from 'react';

import { Form, Grid } from 'semantic-ui-react';
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

import useGuide from 'hooks/useGuide';

import ImageUpload from 'components/common/fileuploader/ImageUploader';
import Code from '../code.json';

interface IFiles{
	fileName:string;
	filePath:string,
	fileUrl:string,
}

interface IProps{
	open: boolean;
	typeList: any[];
	onClose(): void;
	fileType: string;
	showFeedback: { (msg: string): void };
}

const GuideWrite: React.SFC<IProps> = (props) => {

	const { open, typeList, onClose, fileType, showFeedback }  = props;

	const { guide, onInsertGuide, onModifyGuide, requestGuideList } = useGuide();

	const [ file, setFile ] = useState<IFiles>();
	
	const [ guideType, setGuideType ] = useState('');
	const [ priority, setPriority ] = useState(0);
	const [ viewYn, setViewYn ] = useState(true);

	const [ codeList ] = Code;

	const handleChange = (e: any, { name, value }: any) => ({ [name]: value });

	const guideTypeCickEvent = (value: any) => {
		setGuideType(value);
	}

	const sortChange = (e : React.ChangeEvent<HTMLInputElement>)  => {
		setPriority(parseInt(e.target.value));
	}

	const viewYnChange = (e : React.FormEvent<HTMLInputElement>) => {
			e.currentTarget.checked == false ? setViewYn(true) : setViewYn(false);
	}

	const onWriteGuide = (e: SyntheticEvent, item: any) => {
		const fileName = file === undefined ? '' : file.fileName;
		const filePath = file === undefined ? '' : file.filePath;

		const guideTypeItem = guide.guideList !=undefined ? guide.guideList.find((item:any) =>{
			if (item.guideType === guideType) {
				return item;
			}
		}) : undefined;
	
		if (guideTypeItem != undefined) {
			onModifyGuide({
				"detail": [{
				"imageName": fileName,
				"imagePath": filePath,	
				"priority": priority,
				}],
				"guideType": guideType,
				"no": guideTypeItem.guideNo,
				"viewYn": viewYn,
			});
		}
		else {
			onInsertGuide({
				"detail": [{
					"imageName": fileName,
					"imagePath": filePath,	
					"priority": priority,
				}],
				"guideType": guideType,
				"viewYn": viewYn,
			});
		}
		showFeedback("등록이 완료되었습니다.");
		onClose();
	}

	const viewType = typeList.map((item:any) => {
		return (
			<Form.Radio
				label={item.text}
				value={item.value}
				checked={guideType === item.value}
				onChange={handleChange}
				onClick={() => {guideTypeCickEvent(item.value) }}
			/>
		)
		
	})

	return (
		<BasePopup
			open={open}
			id={0}
			size='large'
			title='이용 가이드 등록'
			type={PopupTypes.DIALOG}
			buttons={[{},{ onClick: onWriteGuide }]}
			onClose={onClose}
		>
			<Form>
				<Form.Group>
					<Form.Checkbox 
						label='게시글 노출 여부' 
						defaultChecked={true} 
						onChange={viewYnChange} 
					/>
				</Form.Group>
				<Form.Group inline>
					<label>이용 가이드 타입 : </label>
					{viewType}
				</Form.Group>
				<Form.Group inline>
					<Form.Input
						// control={Input}
						label='순서 : '
						type='number'
						onChange={sortChange}
					/>
				</Form.Group>
			</Form>
			<ImageUpload fileType={fileType} setFile={setFile} />
		</BasePopup>
	);
}
export default GuideWrite;