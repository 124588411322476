import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/salonOn';

const useSalonOn = () => {

  const dispatch = useDispatch();

  const salonOn = useSelector((state: StoreState) => state.salonOn);
  
  // const requestQnaCode = (groupName: any) => {
  //   dispatch(action.getQnaCode({
  //     pathVariables : {'groupName' : groupName},
  //   }));
  // }
    
  const requestDsonList = (data: any) => {    
    dispatch(action.requestDsonList(data));
  }    

  const requestDsonSelectList = (data: any) => {    
    dispatch(action.requestDsonSelectList(data));
  }    
  
  const requestCreateDson = (data: any) => {    
    dispatch(action.createDson(data));
  }    
  
  const requestModifyDson = (data: any) => {    
    dispatch(action.requestModifyDson(data));
  }    
  
  const requestDeleteDson = (data: any) => {    
    dispatch(action.requestDeleteDson(data));
  }    
    
  const requestDsonDetail = (data: any) => {    
    dispatch(action.requestDsonDetail(data));
  }    
  


  
  const requestTagList = (data: any) => {    
    dispatch(action.requestTagList(data));
  }    

  const createTag = (data: any) => {    
    dispatch(action.createTag(data));
  }    

  const modifyTag = (data: any) => {    
    dispatch(action.modifyTag(data));
  }    

  const deleteTag = (data: any) => {    
    dispatch(action.deleteTag(data));
  }    
  
  return {
    salonOn,
    requestDsonList,
    requestDsonSelectList,
    requestCreateDson,
    requestModifyDson,
    requestDeleteDson,
    requestDsonDetail,
    
    requestTagList,
    createTag,
    modifyTag,
    deleteTag,
  }
}

export default useSalonOn;