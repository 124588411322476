import styled from 'styled-components';
import { Form, Image } from 'semantic-ui-react';
import { Container } from '@material-ui/core';

const HtmlContents = styled.div`
  height: 600px;
  border: 1px solid rgba(34,36,38,.15);
  background-color: rgb(240, 240, 240);  
  padding: .78571429em 1em; 
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  overflow: scroll;
`;

const FormDiv = styled(Form)`
  &&& {
    .form-input {
      margin-top: 1em;
    }
    .form-checkbox {
      margin-top: 1em;
    }
    .checkbox.read-only {
      cursor: default;
      input, label {
        cursor: default;
      }
      label:before {
        background: lightgray;
        border: 0;
      }
    }
  }
`

const ThumbnailImage = styled(Image)`
  &&& {
    width: 150px;
    height: 150px;
  }
`

const PopupDiv = styled(Container)`
  &&& {
    /* text-align: center; */
  }
`

const PopupImageDiv = styled(Container)`
  &&& {
    position: relative;
    padding: 0;
    border: 1px solid #d1d1d1;
    img {
      display: inline;
      width: 600px;
      height: 600px;
    }
  }
`

const LinkAreaItem = styled.div`
  &&& {
    border: 0;
    border-top: 1px solid #efefef;
    padding-top: 5px;
    margin-bottom: 20px;
  }
`

const LinkAreaDiv = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    padding: 0;
    .form-input-axis {
      margin: 0;
      .ui.input {
        input {
          width: inherit;
        }
      }
    }
    .form-input-axis:nth-child(n+2) {
      margin-left: 10px
    }
  }
`;

const ColorBoxSpan = styled.span`
  &&& {
    display: inline-block;
    width: 40px;
    height: 13px;
    margin-left: 10px;
    opacity: 0.3;
  }
`

const LinkLabelDiv = styled.div`
  &&& {
    margin-top: 10px;
  }
`

export const EventDetailStyles = {
  HtmlContents,
  FormDiv,
  ThumbnailImage,
  PopupDiv,
  PopupImageDiv,
  LinkAreaDiv,
  LinkAreaItem,
  ColorBoxSpan,
  LinkLabelDiv,
}