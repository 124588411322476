import React from 'react';
// Lib
import CKEditor from 'ckeditor4-react';
// Config
import { SERVER_URL } from 'store/modules/types/epics';

interface IProps {
  url: any;
  contents?: any;
  setContents: Function;
  detailNo?: any;
  height?: number;
}
const CkEditor4: React.FC<IProps> = (props) => {

  const { url, contents, setContents, detailNo, height } = props;
  const uploadUrl = SERVER_URL+'/apis/v3/file/'+url;
  const customerHeigth = height != undefined ? height : 300;

  const handleChange = (e:any) => {
    setTimeout(() => {
      const getContents = e.editor.getData();
      if (getContents.length > 0) {
        setContents(getContents);
      }
    }, 1)
  }

  const handleSelectionChange = (e:any) => {
    const getContents = e.editor.getData();
    if (getContents.length === 0) {
      if (contents != undefined && contents.length > 0) {
        setContents(' ');
      }
    }
    else {
      setContents(getContents);
    }
  }

  return (
    <>
      <CKEditor 
        data={contents}
        config={{          
          uploadUrl: uploadUrl,
          imageUploadUrl: uploadUrl,
          allowedContent: true,
          height: customerHeigth,                            
          extraPlugins: "justify,uploadimage,preview,undo,colorbutton,font",
          toolbarGroups: [
            { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
            { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
            { name: 'styles', groups: [ 'format', 'font' ] },
            { name: 'forms', groups: [ 'forms' ] },
            { name: 'basicstyles', groups: [ 'basicstyles', 'colors', 'cleanup' ] },
            // { name: 'colors', groups: [ 'colors' ] },
            { name: 'paragraph', groups: [ 'align', 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },
            { name: 'links', groups: [ 'links' ] },
            { name: 'insert', groups: [ 'insert' ] },
            { name: 'others', groups: [ 'others' ] },
            { name: 'document', groups: [ 'mode', 'document', 'doctools'] },
            // { name: 'tools', groups: [ 'maximize' ] },
          ],
          removeButtons: 'Styles,Cut,Copy,Paste,PasteText,PasteFromWord,Replace,Scayt,NewPage,ExportPdf,Save,Templates,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Language,Anchor,Flash,Smiley,SpecialChar,PageBreak,Iframe,ShowBlocks',
          versionCheck: false
        }}
        onChange={handleChange}
        onKey={handleChange}
        onSelectionChange={handleSelectionChange}
      />
    </>
  )
}
export default CkEditor4;