import React, { useEffect } from 'react';

// Components
import TermList from './TermList';

import useUsers from 'hooks/useUsers';

const UseTerms: React.SFC = () => {

  const { users, requestTosList } = useUsers();
  const { tosList } = users;

  useEffect(() => {
    onRequestTosList();
  }, []);

  const onRequestTosList = () => {
    const payload: any = {}
    requestTosList(payload);
  }

  return (
    <TermList list={tosList} refresh={onRequestTosList} />
  );
}

export default UseTerms;