
export type TDemoStatusType = 'WAIT' | 'COMPLETE' | 'CANCEL'

export interface IParams {
  statuses: string
  page: number
  size: number
}

export const initIRequestDemoListParams : IParams = {
  page: 1,
  size: 10,
  statuses: 'WAIT,COMPLETE,CANCEL'
}

export const REVIEWRAY_DEMOS_STATUS: {[key in string] : string} = {  
  WAIT: '대기중',
  COMPLETE: '발급완료',
  CANCEL: '취소'
}

export interface IPopupParams {
  id: number;
  open: boolean;
  mode: string;
}

export const initIPopupParams : IPopupParams = {
  id: 0,
  open: false,
  mode: ''
}

export interface IDetailParams {
  comment: string
  company: string
  department: string
  email: string
  id: number
  marketingAgreementYn: boolean
  membershipEndDate: string | null
  membershipStartDate: string | null
  name: string
  phone: string
  position: string
  privacyPolicyAgreementYn: string | null
  regDt: string
  status: string
  storeUrl: string
}

export type TDemoItemType = 'REVIEW_REPORT' | 'REVIEW_INSIGHT' | 'REVIEW_SEARCH' | 'REVIEW_PRODUCT_ANALYSIS' | 'REVIEW_COMPARISON' | 'REVIEW_COMPARISON_CHANGE'

export interface IDemoItemParam {
  itemType: TDemoItemType,
  limitCount: number,
  itemValue: string
}

export interface IDemoUsersParam {
  userId: string,
  customerId: string,
  startDate: string,
  endDate: string,
  contractProductCount: number,
  contractReviewCount: number,
  productChangeCount: number,
  items: IDemoItemParam[]
}

export const ininDemoUserParam : IDemoUsersParam  = {
  userId: '',
  customerId: '',
  startDate: '',
  endDate: '',
  contractProductCount: 1,
  contractReviewCount: 100,
  productChangeCount: 0,
  items: [
    {
      itemType: "REVIEW_REPORT",
      limitCount: 0,
      itemValue: "O"
    },
    {
      itemType: "REVIEW_INSIGHT",
      limitCount: 0,
      itemValue: "O"
    },
    {
      itemType: "REVIEW_SEARCH",
      limitCount: 0,
      itemValue: "O"
    },
    {
      itemType: "REVIEW_PRODUCT_ANALYSIS",
      limitCount: 10,
      itemValue: "10"
    },
    {
      itemType: "REVIEW_COMPARISON",
      limitCount: 3,
      itemValue: "3"
    },
    {
      itemType: "REVIEW_COMPARISON_CHANGE",
      limitCount: 1,
      itemValue: "1"
    }
  ]
}