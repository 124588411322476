import React, { useEffect, useState, Fragment } from 'react';

import { Container, Header, Segment, Dimmer, Loader, Image, Input, Button, Table, Select, Grid } from 'semantic-ui-react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@material-ui/core/';
// import Pagination from '@material-ui/lab/Pagination';



// Components
import PaymentList from './paymentList';

import useBasics from 'hooks/useBasics';
import useUsers from 'hooks/useUsers';

const Payment: React.SFC = () => {

  const { basics } = useBasics();
  const { users, requestPaymentList }  = useUsers();
  const { menu } = basics;
  const { paymentList } = users;

  useEffect(() => requestPaymentList() 
    
  ,[]);

  return (
    <PaymentList list={paymentList} refresh={requestPaymentList} />
  );
}

export default Payment;