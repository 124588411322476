import styled from 'styled-components';

interface IStyleProps {
  isLoading: boolean;
}

const UserSales = styled.article`
  margin: 30px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Wrap = styled.section`
  width: 90%;
  border: 1px solid rgba(34,36,38,.1);
  border-radius: 4px;
  box-shadow: 
    0px 3px 3px -2px rgb(0 0 0 / 20%), 
    0px 3px 4px 0px rgb(0 0 0 / 14%), 
    0px 1px 8px 0px rgb(0 0 0 / 12%);
`

const SubTitle = styled.span`
  display: block;  
  flex: 0.8;
  font-size: 15px;
  font-weight: bold;
  padding: 0 0 0 25px;
`

const MarketSelect = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`

const DateSelect = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`

const DatePick = styled.div`
  flex: 10;
  border-left: 1px solid rgba(34,36,38,.1);
`

const ExcelDownButton = styled.button`
  margin-top: 40px;
  padding: 10px 0px;
  background-color: black;
  color: white;
  font-size: 16px;
  font-weight: 500;
  width: 13%;
  border-radius: 3px;
  opacity: ${(props: IStyleProps) => props.isLoading ? 0.5 : 1};
  cursor: ${(props: IStyleProps) => props.isLoading ? 'default' : 'pointer'};
`

export const UserSalesStyles = {
  UserSales,
  Wrap,
  SubTitle,
  MarketSelect,
  DateSelect,
  DatePick,
  ExcelDownButton,
}
