import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

// modules
import { StoreState } from './types';

// reducers
import basics, { epic as basicEpic } from './basics';
import qna, { epic as qnaEpic } from './qna';
import event, { epic as eventEpic } from './event';
import users, { epic as userEpic } from './users'; 
import sales, { epic as salesEpic } from './sales'; 
import { epic as monitoringEpic } from './monitorings';
import guide, { epic as guideEpic } from './guide';
import faq, { epic as faqEpic } from './faq';
import salonOn, { epic as salonOnEpic } from './salonOn'; 
import market, { epic as marketEpic } from './market';
import notice, { epic as noticeEpic } from './notice';
import codeLists, { epic as codelistEpic  } from './codeListAll';
import emailTemplate, { epic as emailTempEpic } from './emailTemplate';
import email, { epic as emailEpic } from './email';
import { epic as bannerEpic } from './banner';
import userSales, { epic as userSalesEpic } from './userSales';
import userLogs, { epic as userLogsEpic } from './userLogs';
import demo, { epic as demoEpic } from './demo';
import { epic as dashboardEpic } from './dashboard';
import bestProduct, { epic as bestProductEpic } from './todayBest';
import bestMarketData, { epic as bestProductPerMarketEpic } from './todayBestPerMarket';
import trendNews, { epic as trendNewsEpic } from './trendNews';
import productMonitoring, {epic as productMonitoringEpic} from './productMonitoring';
import keywordMonitoring, {epic as keywordMonitoringEpic} from './keywordMonitoring';
import trendKeyword, {epic as trendKeywordEpic} from './trendKeyword';
import hotKeyword500, {epic as hotKeyworEpic} from './hotKeyword500';
import naverAttribute, {epic as naverAttributeEpic} from './naverAttribute';
import crawlLog, {epic as crawlLogEpic} from './crawlLog';
import reviewConfirm, {epic as reviewConfirmEpic} from './reviewConfirm';
import { FlipRounded } from '@material-ui/icons';

const rootEpic = combineEpics(
  demoEpic,
  basicEpic, 
  userEpic, 
  monitoringEpic, 
  qnaEpic, 
  guideEpic, 
  eventEpic, 
  salonOnEpic, 
  faqEpic, 
  codelistEpic, 
  marketEpic, 
  salesEpic, 
  noticeEpic, 
  emailEpic,
  emailTempEpic, 
  bannerEpic,
  userSalesEpic,
  userLogsEpic,
  dashboardEpic,
  bestProductEpic,
  bestProductPerMarketEpic,
  trendNewsEpic,
  trendKeywordEpic,
  productMonitoringEpic,
  keywordMonitoringEpic,
  hotKeyworEpic,
  naverAttributeEpic,
  crawlLogEpic,
  reviewConfirmEpic,
)

const rootReducer = combineReducers<StoreState>({
  basics,
  users,
  qna,
  guide,
  event,
  faq,
  salonOn,
  codeLists,
  market,
  sales,
  notice,
  email,
  emailTemplate,
  userSales,
  userLogs,
  demo,
  bestProduct,
  bestMarketData,
  trendNews,
  trendKeyword,
  productMonitoring,
  keywordMonitoring,
  hotKeyword500,
  naverAttribute,
  crawlLog,
  reviewConfirm,
});

const modules = {
  rootEpic, rootReducer
}

export default modules;