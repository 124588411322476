import styled from 'styled-components'

interface IStyleProps {
  align?: string;
  margin?: string;
  width?: string;
  fontWeight?: number;
}

const Wrap = styled.section`
  width: 100%;
  border: 1px solid rgba(34,36,38,.1);
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding: 10px 30px;
`

const ContentList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ContentListItem = styled.li<IStyleProps>`
  display: flex;
  justify-content: ${({ align }) => align};
  padding: 15px 0;
`

const Content = styled.div<IStyleProps>`
  display: flex;
  width: 40%;
`

const Text = styled.span<IStyleProps>`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 12px;
  font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 700};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width ? width : '90px'};
`

const Input = styled.input`
  width: 100%;
  font-size: 12px;
  padding: 10px;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 5px;

  &:focus {
    outline: 1px solid black;
  }

  &:hover {
    outline: 1px solid black;
  }
`

const Label = styled.label`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
`

const CheckBox = styled.input.attrs({ type: 'checkBox' })`
  width: 15px;
  height: 15px;
`

export const SearchBoxStyles = {
  Wrap,
  ContentList,
  ContentListItem,
  Content,
  Text,
  Input,
  Label,
  CheckBox,
}