import { is } from 'immutable';
import styled from 'styled-components';
interface IStyleButtonProps {
  height?:string;
  width?:string;
  margin?: string;
  buttonContainerJustifyContent?: string;
}
interface IStyleTableProps {
  tableLayout?: string;
  isChecklist?: boolean;
  isEmpty?: boolean;
}

interface IStyleTextProps {
  isNull: boolean;
}

export const RecollectButtonContainer = styled.div`
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  margin-bottom: 10px;
  gap: 10px;
  padding: 0px;
`;

export const CustomizedButton = styled.a <IStyleButtonProps>`
background-color: #e6e6e6;
color: black;
border: 1px solid rgba(0, 0, 0, 0.12);
border-radius: 8px;
cursor: pointer;
font-size: 12px;
width: ${({ width }) => width || '80px'};
height: ${({ height }) => height || '35px'};

display: flex;
align-items: center;
justify-content: center;
text-align: center;
white-space: nowrap; 
&:hover {
  background-color: #BAD3E2;
}
margin: ${({ margin }) => margin || '0px 20px 10px 0px'};
&:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
`;
export const TableWrapper = styled.div`
  overflow-x: auto; /* 가로 스크롤 활성화 */
  width: 100%;
`;

export const Table = styled.table <IStyleTableProps> `
  width: 100%;
  border-collapse: collapse;
  table-layout: ${({ tableLayout }) => tableLayout || 'auto'};


  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: start;
    white-space: nowrap;
    min-width: 100px;

  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-align: center;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

  th:last-child, td:last-child {
    ${({ isChecklist }) => isChecklist && `
    width: 30px; /* 체크박스 열의 너비를 작게 설정 */
    padding: 0; /* 패딩 제거 */
    min-width: 0;
    text-align: center;
    `}
  }
`;
export const StyledTd = styled.td<{ isEmpty?: boolean }>`
  ${({ isEmpty }) => isEmpty && `
    background-color: #ffcccc; /* 연한 빨간색 */
  `}
`;

export const Title = styled.h2`
font-size: 1.0rem;
font-weight: 600;
margin-bottom: 10px;
`

export const Text = styled.p<{isNull?: boolean}>`
  ${({ isNull }) => isNull && `
  text-decoration-line: line-through;  
  color: red;
  `}
`;
