import React from 'react';

import { Icon } from 'semantic-ui-react';

// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/nextTable/TableListItem';

interface IProps {
  faqCode: any;
  faq: any;
  onSelect: Function;
}

const CHECK_IMPORTANT_ICON = <i className="material-icons" style={{ color: '#de5252' }}>priority_high</i>;
const CHECK_VIEW_ICON = <i className="material-icons" style={{ color: 'rgba(0, 0, 0, .6)' }}>check</i>;
const CHECK_HIDE_ICON = <i className="material-icons" style={{ color: 'rgba(0, 0, 0, .6)' }}>close</i>;

const FaqListItem: React.FC<IProps> = (props) => {

  const { faqCode, faq, onSelect } = props;
  const { uid, faqType, faqTitle, regDt, userId, viewYn } = faq;

  const onEditButtonClicked = () => {
    console.log('onEditButtonClicked');
  };

  const onDeleteButtonClicked = () => {
    console.log('onDeleteButtonClicked');
  };

  const iconButtons: ITableListItemIconButton[] = [
    {
      color: 'green',
      iconName: 'edit',
      onItemClicked: onEditButtonClicked
    },
    {
      color: 'red',
      iconName: 'delete',
      onItemClicked: onDeleteButtonClicked
    }
  ];

  const options = { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };
  const regDate = new Date(regDt);
  const formattedRegDate = new Intl.DateTimeFormat("ja-JP", options).format(regDate);
  //const regDate = new Date(regDt).toLocaleString('ko-KR', '');
  const tableListItemsProps: ITableListItemProps = {
    id: uid,
    items: [
      { value: faqCode },
      { value: faqTitle, textAlign: 'left', clickable: true },
      { value: viewYn ? CHECK_VIEW_ICON : null },
      { value: userId },
      { value: formattedRegDate }
    ],
    iconButtons,
    onRowSelectedEvent: onSelect
  }

  return (
    <TableListItem {...tableListItemsProps} />
  );
}

export default FaqListItem;