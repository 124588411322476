import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 10px;
  padding: 0px;
`;

const Section = styled.div`
  flex: 1;
  border-right: 1px solid #ccc;
  padding: 5px;
`;

export const MonitoringStyles = {
  Container, 
  Section,
}