import React, { useContext } from 'react'
import moment from 'moment';

export interface ISearchParams {
  endDate: string;
  keyword: string;
  pageNumber: number;
  pageSize: number;
  roleType: string;
  startDate: string;
}

export const initSearchParams: ISearchParams = {
  endDate: moment().format('YYYY-MM-DD'),
  keyword: '',
  pageNumber: 1,
  pageSize: 0,
  roleType: 'ALL',
  startDate: moment().format('YYYY-MM-DD'),
}

type SearchParamsType = {
  searchParams: ISearchParams;
  setSearchParams: (params: ISearchParams) => void;
  onSearch: (params?: ISearchParams) => void;
}

export const SearchParamsContext = React.createContext<SearchParamsType>({
  searchParams: initSearchParams,
  setSearchParams: () => {},
  onSearch: () => {},
})
export const useSearchParamsContext = () => useContext(SearchParamsContext);

export interface IUserLoginLog {
  userId: string;
  userRole: string;
  clientType: string;
  agent: string;
  timestamp: string;
  clientIp: string;
  customerName: string;
}