import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse, ajax } from 'rxjs/ajax';

// ACTION PART
const action = {
  requestBannerList: createAction(actionTypes.GET_BANNER_LIST),
  succeededBannerList: createAction(actionTypes.SUCCEEDED_GET_BANNER_LIST),
  failedBannerFaqCreate: createAction(actionTypes.FAILED_GET_BANNER_LIST),

  requestCreateBanner: createAction(actionTypes.CREATE_BANNER),
  succeededCreateBanner: createAction(actionTypes.SUCCEEDED_CREATE_BANNER),
  failedCreateBanner: createAction(actionTypes.FAILED_CREATE_BANNER),

  requestUpdateBanner: createAction(actionTypes.UPDATE_BANNER),
  succeededUpdateBanner: createAction(actionTypes.SUCCEEDED_UPDATE_BANNER),
  failedUpdateBanner: createAction(actionTypes.FAILED_UPDATE_BANNER),

  requestDeleteBanner: createAction(actionTypes.DELETE_BANNER),
  succeededDeleteBanner: createAction(actionTypes.SUCCEEDED_DELETE_BANNER),
  failedDeleteBanner: createAction(actionTypes.FAILED_DELETE_BANNER),

  requestBannerActiveToggle: createAction(actionTypes.BANNER_ACTIVE_TOGGLE),
  succeededBannerActiveToggle: createAction(actionTypes.SUCCEEDED_BANNER_ACTIVE_TOGGLE),
  failedBannerActiveToggle: createAction(actionTypes.FAILED_BANNER_ACTIVE_TOGGLE),
}

// EPIC PART
const requestBannerListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_BANNER_LIST,
      success: (res: AjaxResponse) => action.succeededBannerList({ list: res.response.data.list }),
      fail: actionTypes.FAILED_GET_BANNER_LIST,
    },
    url: API_URL.BANNER,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestCreateBannerEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.CREATE_BANNER,
      success: (res: AjaxResponse) => action.succeededCreateBanner(),
      fail: actionTypes.FAILED_CREATE_BANNER,
    },
    url: API_URL.BANNER,
    method: AjaxMethods.POST
  };

  return epicService.request(actions$, reqParams);
}

const requestUpdateBannerEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.UPDATE_BANNER,
      success: (res: AjaxResponse) => action.succeededUpdateBanner(),
      fail: actionTypes.FAILED_UPDATE_BANNER,
    },
    url: API_URL.BANNER,
    method: AjaxMethods.PATCH
  };

  return epicService.request(actions$, reqParams);
}

const requestDeleteBannerEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.DELETE_BANNER,
      success: (res: AjaxResponse) => action.succeededDeleteBanner(),
      fail: actionTypes.FAILED_DELETE_BANNER,
    },
    url: API_URL.BANNER,
    method: AjaxMethods.DELETE
  };

  return epicService.request(actions$, reqParams);
}

const requestBannerActiveToggleEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.BANNER_ACTIVE_TOGGLE,
      success: (res: AjaxResponse) => action.succeededUpdateBanner(),
      fail: actionTypes.FAILED_UPDATE_BANNER,
    },
    url: API_URL.BANNER,
    method: AjaxMethods.PATCH
  };

  return epicService.request(actions$, reqParams);
}


const epic = combineEpics(
  requestBannerListEpic,
  requestCreateBannerEpic,
  requestUpdateBannerEpic,
  requestDeleteBannerEpic,
  requestBannerActiveToggleEpic,
);

export { action, epic };