import React, { useState } from 'react';

// Components
import TableList, { ITableHeader, ITableListProps, ITableListButton, IPagination, ISearchTextBox } from 'components/common/nextTable/TableList';
import FaqListItem from './FaqListItem';
import FaqDetail from './FaqDetail';

export interface IFaqReqParams {
  faqType: string,
  pageNumber: number;
  pageSize: number;
  searchKey: string;
}

export const initFaqReqParams: IFaqReqParams = {
  faqType: "",
  pageNumber: 1,
  pageSize: 0,
  searchKey: ''
}

interface IProps {
  faqCodes: any[];
  data: any;
  onRequest: Function;
}

const FaqList: React.FC<IProps> = (props) => {

  const { faqCodes, data, onRequest } = props;
  const [reqParams, setReqParams] = useState<IFaqReqParams>({...initFaqReqParams});
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const onAddButtonClicked = () => {
    console.log('onAddUserButtonClicked');
  };

  const onSearch = (text : string, type: string) => {
    const params: IFaqReqParams = {
      ...reqParams,
      faqType: type === 'all' ? '' : type,
      searchKey: text,
      pageNumber: 1
    }

    setReqParams(params);

    onRequest(params);
  }

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page: number) => {
    console.log("onChangePage", page, reqParams);

    const params: IFaqReqParams = {
      ...reqParams,
      pageNumber: page
    }

    setReqParams(params);
    
    console.log("onChangePage", params);

    onRequest(params);
  }

  const searchable : ISearchTextBox = {
    name: 'searchKey',
    onSearch: onSearch
  }

  const pagination : IPagination = {
    name: 'pageNumber',
    activePage: reqParams.pageNumber,
    totalPage: data.totalPages,
    totalCount: data.totalElements,
    onChange: onChangePage, 
  }

  const buttons: ITableListButton[] = [
    {
      name: 'Add Terms',
      icon: 'add',
      onButtonClicked: onAddButtonClicked
    }
  ];

  const headers: ITableHeader[] = [
    { title: '타입', width: '10%' },
    { title: '제목', width: '60%' },
    { title: '노출', width: '5%' },
    { title: '작성자', width: '10%' },
    { title: '등록일자', width: '15%' }
  ];

  const tableListProps: ITableListProps = {
    buttons,
    headers,
    searchable,
    searchCategory: faqCodes,
    pagination,
    count: data && data.content ? data.content.length : 0,
    refresh: () => {}
  }

  const faqItems = data.content && data.content.map((faq: any, index: number) => {

    return (
      <FaqListItem
        key={index}
        faq={faq}
        faqCode={getFaqType(faqCodes, faq)}
        onSelect={() => setSelectedIndex(index)}
      />
    )
  });

  return (
    <>
      {
        selectedIndex < 0 ? (
          <TableList {...tableListProps}>
            {faqItems}
          </TableList>
        ) : (
          <FaqDetail faqType={getFaqType(faqCodes, data.content[selectedIndex])} data={data.content[selectedIndex]} onToList={() => setSelectedIndex(-1)} />
        )
      }
    </>
  )
}

const getFaqType = (faqCodes: any[], faq: any) => {
  const matchFaqCode = faqCodes.filter((code: any) => code.value === faq.faqType );
  const faqCode = matchFaqCode && matchFaqCode.length > 0 ? matchFaqCode[0].text : '';

  return faqCode;
}

export default FaqList;