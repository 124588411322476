import React from "react";
import { IndexStyles as Styled } from "./styles/Dataconfirm.style";

interface DayOfWeekProps {
  dateString: string; // 'yyyy-mm-dd' 형식의 문자열
  isFullDate: boolean;
}

const DayOfWeek: React.FC<DayOfWeekProps> = (props) => {
  const { dateString, isFullDate: fullDate } = props;
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day); // 월은 0부터 시작
  const dayNames = ["일", "월", "화", "수", "목", "금", "토"];
  const dayOfWeekIndex = date.getDay(); // 0: 일요일, 1: 월요일, ..., 6: 토요일
  const shortDayOfWeek = dayNames[dayOfWeekIndex];

  const options = { weekday: "long" } as const;
  const dayOfWeek = date.toLocaleDateString("ko-KR", options);

  return (
    <>
      {fullDate ? (
        <Styled.Text>
          {dateString} {dayOfWeek}
        </Styled.Text>
      ) : (
        <p>{shortDayOfWeek}</p>
      )}
    </>
  );
};

export default DayOfWeek;
