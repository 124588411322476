import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import { KeywordMonitoringState, MonitoringProductState } from './types';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
    requestProductMonitoring: createAction(actionTypes.GET_DATA_CONFIRM_PRODUCT_MONITORING),
    requestProductMonitoringSuccess: createAction(actionTypes.SUCCEEDED_GET_DATA_CONFIRM_PRODUCT_MONITORING),
    requestProductMonitoringFailed: createAction(actionTypes.FAILED_GET_DATA_CONFIRM_PRODUCT_MONITORING),
    requestRecollectProductMonitoring: createAction(actionTypes.RECOLLECT_PRDUCT_MONITORING),
    requestRecollectProductMonitoringSuccess: createAction(actionTypes.SUCCEEDED_RECOLLECT_PRDUCT_MONITORING),
    requestRecollectProductMonitoringFailed: createAction(actionTypes.FAILED_RECOLLECT_PRDUCT_MONITORING)

}

const initialState: MonitoringProductState = {
    productMonitoringData: {
        total: 0 ,
        failedCount: 0,
        monitoringProducts: [],
      }  
    };

const reducer = handleActions<MonitoringProductState>(
  {
    [actionTypes.SUCCEEDED_GET_DATA_CONFIRM_PRODUCT_MONITORING]: (state, action): MonitoringProductState => {
      return {
        ...state,
        productMonitoringData: action.payload.productMonitoringData
      }
    },
  },
  initialState
  );

const requestProductMonitoringEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_DATA_CONFIRM_PRODUCT_MONITORING,
        success: (res: AjaxResponse) => action.requestProductMonitoringSuccess({ productMonitoringData: res.response.data }),
        fail: actionTypes.FAILED_GET_DATA_CONFIRM_PRODUCT_MONITORING
      },
      url: API_URL.MONITORING_PRODUCT,
      method: AjaxMethods.GET
    }
    return epicService.request(actions$, reqParams);
    ;
}

const requestRecollectMonitoringEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.RECOLLECT_PRDUCT_MONITORING,
      success: (res: AjaxResponse) => action.requestRecollectProductMonitoringSuccess(),
      fail: actionTypes.FAILED_RECOLLECT_PRDUCT_MONITORING
    },
    url: API_URL.MONITORING_RECOLLECT,
    method: AjaxMethods.POST
  }
  return epicService.request(actions$, reqParams);
  ;
}

const epic = combineEpics(
    requestProductMonitoringEpic,
    requestRecollectMonitoringEpic
);
export{action, epic};
export default reducer;