import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import {BestProductPerMarketState, TodayBestState } from './types';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
    requestTodayBestPerMarket: createAction(actionTypes.GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET),
    requestTodayBestPerMarketSuccess: createAction(actionTypes.SUCCEEDED_GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET),
    requestTodayBestPerMarketFailed: createAction(actionTypes.FAILED_GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET),
}
const initialState: BestProductPerMarketState = {
  marketData: {
    marketId: "",
    todayBestDtoList: [],
  }
}

const reducer = handleActions<BestProductPerMarketState>(
  {
    [actionTypes.SUCCEEDED_GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET]: (state, action): BestProductPerMarketState => {
      return {
        ...state,
        marketData: action.payload.marketData
      }
    },
    //failed data 일 경우 
    // [actionTypes.FAILED_GET_DATA_CONFIRM_TODAY_BEST]: (state, action): BestProductState => {
    //   return {
    //     bestData: action.payload.bestData 
    //   }
    // },
  },
  initialState
  );

const requestBestProductPerMarketEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET,
      success: (res: AjaxResponse) => action.requestTodayBestPerMarketSuccess({ marketData: res.response.data }),
      fail: actionTypes.FAILED_GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET
    },
    url: API_URL.BEST_PRODUCT_PER_MARKET,
    method: AjaxMethods.GET
  }
  return epicService.request(actions$, reqParams);
  ;
}

const epic = combineEpics(
    requestBestProductPerMarketEpic,
);
export{action, epic};
export default reducer;