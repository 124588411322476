import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/guide';
import { ActionPayload } from 'store/modules/types';

const useGuide = () => {

  const dispatch = useDispatch();

  const guide = useSelector((state: StoreState) => state.guide);

  const requestGuideList = () => {
    dispatch(action.getGuideList());
  }

  const onInsertGuide = (data: any) => {
    dispatch(action.insertGuide({
      body: data
    }));
  }

  const onModifyGuide = (data: any) => {
    dispatch(action.modifyGuide({
      body: data
    }))
  }

  const onDeleteGuide = (data: any) => {
    dispatch(action.deleteGuide(data))
  }
  
  return {
    guide,
    requestGuideList,
    onInsertGuide,
    onModifyGuide,
    onDeleteGuide,
  }
}
 
export default useGuide;