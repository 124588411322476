import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/demo';
import { ActionPayload } from 'store/modules/types';

const useDemo = () => {

  const dispatch = useDispatch();

  const demo = useSelector((state: StoreState) => state.demo);

  // Get List
  const requestGetReviewDemos = (data: any) => {
    dispatch(action.requestGetReviewDemos(data));
  }

  const requestGetReviewDemoById = (data: any) => {
    dispatch(action.requestGetReviewDemoById(data));
  }

  const requestGetReviewDemoUsers = (data: any) => {
    dispatch(action.requestGetReviewDemoUsers(data));
  } 

  const requestPatchReviewDemoComplete = (data: any) => {
    dispatch(action.requestPatchReviewDemoComplete(data))
  }

  const requestPatchReviewDemoCancel = (data: any) => {
    dispatch(action.requestPatchReviewDemoCancel(data))
  }

  return {
    demo,
    requestGetReviewDemos,
    requestGetReviewDemoById,
    requestGetReviewDemoUsers,
    requestPatchReviewDemoComplete,
    requestPatchReviewDemoCancel,
  }
}

export default useDemo;