import styled from 'styled-components'

const Wrap = styled.div`
  @media(min-width: 500px) {
    margin: 0 5px;
  }
  
  @media(min-width: 1000px) {
    margin: 0 10px;
  }

  @media(min-width: 1700px) {
    margin: 0 15px;
  }
`

export const DatePickStyles = {
  Wrap,
}