import React, { useEffect, Fragment, useState, FormEventHandler } from 'react';

import {  Header, Form, Button, Checkbox, Input, Label, CheckboxProps } from 'semantic-ui-react';
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

import useUsers from 'hooks/useUsers';


interface IProps {
    paymentNo: number;
    open: boolean;
    onClose(): void;
    showFeedback: Function;
}

const PaymentWrite: React.FC<IProps> = (props) => {

    const { paymentNo, open, onClose, showFeedback } = props;

    const { users, requestPayment, requestCodeList, requestPaymentList, modifyPayment } = useUsers();
    const { codeList } = users;
    const [ paymentDetail, setPaymentDetail] = useState(Object);

    /* Api Call */
    useEffect(() => { 
        requestPayment({
            pathVariables: { 'payment_no' : BigInt(paymentNo)},
            callback: onPaymentDetail
        });
        requestCodeList();
    } ,[]);
    /* Api requestPayment Call back  */
    const onPaymentDetail = (success:boolean, data:any) => {
        setPaymentDetail(data.response.data);
    }

    const functionProvidedInit = new String(paymentDetail.functionProvided);
    let functionProvidedList = functionProvidedInit.split(',');
    const functionProvidedArray = functionProvidedInit.split(',');

    const extraServiceInit = new String(paymentDetail.extraService);
    let extraServiceList:Array<string> = extraServiceInit.split(',');
    const extraServiceArray:Array<string> = extraServiceInit.split(',');
    /* Params Change Event! */
    const extraServiceChange = (e : React.FormEvent<HTMLInputElement>, data: any) => {
        e.preventDefault();
        data.checked === true ? extraServiceArray.push(data.value) : extraServiceArray.splice(extraServiceArray.indexOf(data.value),1);
        setPaymentDetail({...paymentDetail, extraService: extraServiceArray.join(",")});
    }
    const functionProvidedChange = (e : React.FormEvent<HTMLInputElement>, data: any) => {
        e.preventDefault();
        data.checked == true ? functionProvidedArray.push(data.value) : functionProvidedArray.splice(functionProvidedArray.indexOf(data.value), 1);
        setPaymentDetail({...paymentDetail, functionProvided: functionProvidedArray.join(",")});
    }
    const paymentTypeChange = (e : React.ChangeEvent<HTMLInputElement>)  => {
        setPaymentDetail({...paymentDetail, paymentType: e.target.value});
    }

    // Form Event Search !!
    const unlimitedYnChange = (e : React.FormEvent<HTMLInputElement>) => {
        setPaymentDetail({...paymentDetail, unlimitedYn: !paymentDetail.unlimitedYn});
    }
    const useYnChange = (e : React.FormEvent<HTMLInputElement>) => {
        setPaymentDetail({...paymentDetail, useYn: !paymentDetail.useYn});
    }

    const viewYnChange = (e : React.FormEvent<HTMLInputElement>) => {
        setPaymentDetail({...paymentDetail, viewYn: !paymentDetail.viewYn});
    }

    const priceChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setPaymentDetail({...paymentDetail, price :e.target.valueAsNumber});
    }
    const analysisMarketChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setPaymentDetail({...paymentDetail, analysisMarket :e.target.valueAsNumber});
    }
    const analysisTermChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setPaymentDetail({...paymentDetail, analysisTerm :e.target.valueAsNumber});
    }
    const productInsertCountChange = (e : React.ChangeEvent<HTMLInputElement>)  => {
        setPaymentDetail({...paymentDetail, productInsertCount :e.target.valueAsNumber});
    }
    
    const providedCheck = (data:string) => {
        let flag = false;
        for (let index = 0; index < functionProvidedList.length; index++) {
            if(functionProvidedList[index] === data){
                functionProvidedList.splice(functionProvidedList.indexOf(data), 1);
                flag = true;
                break;
            }
        }
        return flag;
    }
 
    function extraServiceCheck(data:string){
        let flag = false;
        for (let index = 0; index < extraServiceList.length; index++) {
            if(extraServiceList[index] === data){
                extraServiceList.splice(extraServiceList.indexOf(data), 1);
                flag = true;
                break;
            }
        }
        return flag;
    }

    const paymentModfiy = () => {
        modifyPayment({
            "paymentNo": paymentNo,
            "analysisMarket": paymentDetail.analysisMarket,
            "analysisTerm": paymentDetail.analysisTerm,
            "extraService": paymentDetail.extraService,
            "functionProvided": paymentDetail.functionProvided,
            "paymentType": paymentDetail.paymentType,
            "price": paymentDetail.price,
            "productInsertCount": paymentDetail.productInsertCount,
            "unlimitedYn": paymentDetail.unlimitedYn,
            "useYn": paymentDetail.useYn,
            "viewYn": paymentDetail.viewYn
        });
        showFeedback("수정이 완료 되었습니다.");
        onClose();
    }

    /* Service Code */
    const functionProvidedCodeArray : [{}] = codeList.function_provided;
    const functionProvidedCode = functionProvidedCodeArray ? 
        functionProvidedCodeArray.map((data:any) => 
        <Form.Checkbox type='checkbox' label={data.text} value={data.value} checked={providedCheck(data.value)} onChange={functionProvidedChange}/>
    ) : null;

    // // Extra Service Code
    const extraServiceCodeArray : [{}] = codeList.extra_service;
    const extraServiceCode = extraServiceCodeArray ? 
        extraServiceCodeArray.map((data:any) => 
        <Form.Checkbox  label={data.text} value={data.value} checked={extraServiceCheck(data.value)} onChange={extraServiceChange} />
    ) : null;

    const pageTitle = '요금제 수정';
    const useYnTag = <Form.Checkbox label='사용 유무' checked={paymentDetail.useYn} style={{paddingTop:'30px'}} onChange={useYnChange}/>;
    const viewYnTag = <Form.Checkbox  label='게시글 노출 여부' checked={paymentDetail.viewYn} style={{paddingTop:'30px'}} onChange={viewYnChange} /> ;
    const unlimitedYnTag = <Form.Checkbox label='서비스 무제한' checked={paymentDetail.unlimitedYn} style={{paddingTop:'30px'}} onChange={unlimitedYnChange} />;
    const paymentTypeTag = <Form.Input fluid label='요금제명' value={paymentDetail.paymentType} onChange={paymentTypeChange} /> ;
    const priceTag = <Form.Input fluid label='요금제 가격(원)' type="number" value={paymentDetail.price} onChange={priceChange} />;
    const analysisMarketTag = <Form.Input fluid label='분석 마켓 수(개)' type="number" value={paymentDetail.analysisMarket} onChange={analysisMarketChange} /> ;
    const analysisTermTag = <Form.Input  fluid label='데이터 분석 기간(개월)' type="number" value={paymentDetail.analysisTerm} onChange={analysisTermChange} />
    const productInsertCountTag = <Form.Input fluid label='상품 등록 수(개)' type="number" value={paymentDetail.productInsertCount} onChange={productInsertCountChange}/>;
    
    return (
        <BasePopup
            open={open}
            id={0}
            size='tiny'
            type={PopupTypes.DIALOG}
            title='요금제 등록'
            buttons={[
                {}, { onClick: paymentModfiy}
            ]}
            onClose={onClose}
        >
            <Form size='tini' >
                <Form.Group>
                    {useYnTag} 
                    {viewYnTag} 
                    {unlimitedYnTag}
                </Form.Group>
                <Form.Group widths='equal'>
                    {paymentTypeTag} 
                    {priceTag}
                </Form.Group>
                <Form.Group widths='equal'>
                    {analysisMarketTag}
                    {analysisTermTag}
                    {productInsertCountTag}
                </Form.Group>
                <Form.Group>
                    <Form.Field width={10}>* 서비스 항목</Form.Field>
                    <Form.Field width={10}>* 부가 서비스 항목</Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={10}>{functionProvidedCode}</Form.Field>
                    <Form.Field width={10}>{extraServiceCode}</Form.Field>
                </Form.Group>
            </Form>
        </BasePopup>
    )
}
export default PaymentWrite;