import styled from 'styled-components';

import { Menu, Table } from "semantic-ui-react";

const FooterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  border-radius: 3px;
`

const TableBody = styled(Table.Body)`
  position: ${(props) => props.setPosition};
`;

const ChekcboxWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  & > div > label {
    font-size: 13px !important;
  }
`

const HeaderTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
`

const SortWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TableListStyles = {
  FooterDiv,
  Loader,
  TableBody,
  ChekcboxWrap,
  HeaderTitleWrap,
  SortWrap
}