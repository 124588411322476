import styled from 'styled-components';

const Date = styled.p`
font-size: 1.2em;
font-weight: 600;
padding-top: 10px;
`;

const LinkButton = styled.a`
background-color: #e6e6e6;

color: black;
border: 1px solid rgba(0, 0, 0, 0.12);
border-radius: 8px;
cursor: pointer;
font-size: 12px;
width: 180px; 
height: 30px; 
display: flex;
align-items: center;
justify-content: center;
margin-bottom: "30px";
&:hover {
  background-color: #BAD3E2;
}

&:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
`;
export const TrendKeywordInfoStyles = {
    Date,
    LinkButton
}