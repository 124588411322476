import React, { useEffect, useState } from "react";
import useTrendKeyword from "hooks/useTrendKeyword";
import { TrendKeywordInfoStyles as Styled } from "../styles/TrendKeywordStyle/TrendKeywordInfo.style";
import {
  RecollectButtonContainer,
  Table,
  TableWrapper,
  StyledTd,
  CustomizedButton,
} from "../styles/CommonStyle/Common.style";
import DayOfWeek from "../DayOfWeek";

interface IProps {
  searchDate: string;
  mallId: string;
  link: string;
}

interface HashMap<K, V> {
  [key: string]: V;
}

const TrendKeywordInfo: React.FC<IProps> = (props) => {
  const { searchDate, mallId, link } = props;
  const { trendKeyword, requestTrendKeyword, requestRecollectTrendKeyword } =
    useTrendKeyword();
  const today = new Date().toISOString().slice(0, 10);
  const [keywordMissing, setKeywordMissing] = useState<boolean>(false);
  const { keywordData } = trendKeyword;
  const hourList = keywordData.list.map((item) => item.hour);
  const keywordsList = keywordData.list.map((item) => item.todayKeywords);

  const reCollectFormat: HashMap<string, string> = {
    mall0148: "naver",
    mall0025: "elevenst",
    mall0010: "gmarket",
    mall0003: "auction",
    mall0287: "wemakeprice",
    mall0012: "interpark",
    mall0182: "tmon",
    mall0024: "ssg",
    mall0187: "lottemall",
    mall0033: "akmall",
  };

  const maxKeywords = Math.max(
    ...keywordsList.map((keywords) => keywords.length)
  );

  const latestHour =
    keywordData?.list && keywordData.list[0] ? keywordData.list[0].hour : 0;

  const rankLength =
    keywordData?.list && keywordData.list[0]?.todayKeywords
      ? keywordData.list[0].todayKeywords.length
      : 0;

  const formatTime = (time: number): string => {
    return time.toString().padStart(2, "0");
  };

  useEffect(() => {
    setKeywordMissing(false);
    console.log("before API called keywordMissing is", keywordMissing);
    console.log("changed date or mallId");
    requestTrendKeyword({
      queryParameters: { searchDt: searchDate, marketId: mallId },
    });
  }, [searchDate, mallId]);

  const onRecollect = () => {
    const payload: any = {
      queryParameters: { marketName: reCollectFormat[mallId] },
      callback: (succeded: boolean, res: any) => {
        if (succeded) {
          alert(mallId + " 재수집 요청 성공");
        } else {
          alert(mallId + " 재수집 실패");
        }
      },
    };
    requestRecollectTrendKeyword(payload);
  };

  return (
    <>
      <RecollectButtonContainer>
        <DayOfWeek isFullDate={true} dateString={searchDate}>
          {" "}
        </DayOfWeek>
        {today === searchDate && (
          <CustomizedButton
            height="30px"
            width="60px"
            margin="0px 0px 0px"
            onClick={onRecollect}
          >
            재수집
          </CustomizedButton>
        )}
        <Styled.LinkButton
          as="a"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          인기키워드 연결 링크
        </Styled.LinkButton>
      </RecollectButtonContainer>

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th> Rank/Hour </th>
              {Array.from({ length: latestHour + 1 }, (_, index) => (
                <th key={index}>{formatTime(latestHour - index)}시</th>
              ))}{" "}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: maxKeywords }, (_, rowIndex) => (
              <tr key={rowIndex}>
                <th>{rowIndex + 1}</th>
                {keywordsList.map((keywords, index) => {
                  const isEmpty = !keywords[rowIndex];
                  return (
                    <StyledTd key={index} isEmpty={isEmpty}>
                      {keywords[rowIndex] ? keywords[rowIndex].keyword : ""}
                    </StyledTd>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </>
  );
};
export default TrendKeywordInfo;
