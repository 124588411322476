import React, { useEffect, useState } from 'react'
// Hooks
import useEmailTemplate from 'hooks/useEmailTemplate';
// Components
import EmailTemplateList from './EmailTemplateList';

const EmailTemplate: React.FC = () => {
	const [ isLoading, setIsLoading ] = useState<boolean>(true);
	const { emailTemplate, requestGetEmailTemplateList } = useEmailTemplate();
	const { list } = emailTemplate;

	useEffect(() => {
		setIsLoading(true);
		requestGetEmailTemplateList({
			callback: (succeeded: boolean, res: any) => {
				if (succeeded) {
					setIsLoading(false);
				}
			}
		} as any);
	}, [])

return <EmailTemplateList list={list} isLoading={isLoading} />
}

export default EmailTemplate
