import { IPage, initPage } from "store/modules/types";
import { Tracing } from "trace_events";

export interface IDashboardQnaCount {
  allNonCheck: number;
  allInProgress: number;
  countByTypes: {
    enumMapperValue: {
      key: string, 
      value: string
    };
    nonCheckCount: number;
    inProgressCount: number;
  }[];
}

export const initDashboardQnaCount: IDashboardQnaCount = {
  allNonCheck: 0,
  allInProgress: 0,
  countByTypes: [],
}

export interface IJoinUser {
  uid: number;
  userId: string;
  userName: string;
  phone: string;
  email: string;
  customerId: string;
  customerName: string;
  clientType: string;
  regDt: string;
  storeName: string;
  cooperationType: string;
}

export interface IVisitUser {
  userId: string;
  userName: string;
  customerId: string;
  customerName: string;
  visitDate: string;
  loginCount: number;
  clientType: string;
  regDt: string;
  cooperationType: string;
}

export interface IStoreUser {
  userId: string;
  customerId: string;
  customerName: string;
  storeId: number;
  storeName: string;
  storeType: string;
  storeUrl: string;
  regDt: string;
}

export interface IUserCount {
  mobile: number
  pc: number
  total: number
}

export interface IDeviceChart {
  clientType: string,
  date: string,
  count: number
}

export interface IDashboardUserStatus {
  allUserCount: number,
  dayLoginCount: IUserCount
  dayUserCount: IUserCount
  monthLoginCount: IUserCount
  monthUserCount: IUserCount
  user: {
    mobileChart: IDeviceChart[]
    userDetails: {
      size: number,
      list: {
        userId: string,
        userName: string,
        customerId: string,
        customerName: string,
        phone: string,
        email: string,
        clientType: string,
        regDt: string,
        cooperationType: string,
      }[],
      page: IPage,
    }
    webChart: IDeviceChart[]
  },
  userLogin: {
    mobileChart: IDeviceChart[]
    userLoginDetails : {
      size: number,
      list: {
        userId: string,
        userName: string,
        customerId: string,
        customerName: string,
        loginCount: number,
        clientType: string,
        regDt: string,
        cooperationType: string,
      }[],
      page: IPage,
    },
    webChart : IDeviceChart[]
  }
}

// export interface IDashboardUserStatus {
//   joinCountAll: number;
//   joinStatus: {
//     adminListDto: {
//       contents: IJoinUser[];
//       dailyCount: number;
//       monthlyCount: number;
//     };
//     daily: {
//       date: string;
//       clientType: string;
//       count: number;
//     }[];
//   }
//   visitorStatus: {
//     adminListDto: {
//       contents: IVisitUser[];
//       dailyCount: number;
//       monthlyCount: number;
//     };
//     daily: {
//       date: string;
//       details: {
//         date: string;
//         clientType: string;
//         count: number;
//       }[];
//       totalCount: number;
//     }[];
//   }
//   storeStatus: {
//     adminListDto: {
//       contents: IStoreUser[];
//       totalCount: number;
//       dailyCount: number;
//       monthlyCount: number;
//     }
//     daily: {
//       date: string; 
//       count: number;
//     }[];
//   }
// }

export const initDashboardUserStatus: IDashboardUserStatus = {
  
  allUserCount: 0,
  dayLoginCount: {
    mobile: 0,
    pc: 0,
    total: 0,
  },
  dayUserCount: {
    mobile: 0,
    pc: 0,
    total: 0,
  },
  monthLoginCount: {
    mobile: 0,
    pc: 0,
    total: 0,
  },
  monthUserCount: {
    mobile: 0,
    pc: 0,
    total: 0,
  },
  user: {
    mobileChart: [],
    userDetails: {
      size: 0,
      list: [],
      page: initPage,
    },
    webChart: []
  },
  userLogin: {
    mobileChart: [],
    userLoginDetails : {
      size: 0,
      list: [],
      page: initPage,
    },
    webChart: []
  }
}

export interface IDashboardPopupListProps {
  title?: string;
  headers: string[];
  contents: any[][];
  defaultMsg: string;
  flex: number[];
  gap?: string;
  alignLeftItemCount?: number;
  boldHeader?: boolean;
  headerFontSize?: string;
  itemFontSize?: string;
  itemDivide?: boolean;
  fillHeadLine?: boolean;
}

export const initDashboardPopupListProps: IDashboardPopupListProps = {
  title: undefined,
  headers: [],
  contents: [],
  defaultMsg: '',
  flex: [],
  gap: undefined,
  alignLeftItemCount: undefined,
  boldHeader: undefined,
  headerFontSize: undefined,
  itemFontSize: undefined,
  itemDivide: undefined,
  fillHeadLine: undefined,
}

export type TDashboardPopupType = 
  'user-join' 
  | 'user-visitors' 
  | ''

  // | 'user-store'
  // | 'analyze'
  // | 'monitoring';

export interface IPopupSearchData {
  popupType: TDashboardPopupType;
  searchPageSize: number;
  startDate: string;
  endDate: string;
  totalPageNumber: number;
  pageNumber: number;
  totalContentsCount: number;
}

export interface IJoinUserList {
  contents: IJoinUser[];
  totalCount: number;
}

export interface IVisitorUserList {
  contents: IVisitUser[];
  totalCount: number;
}

export interface IUserStoreList {
  contents: IStoreUser[];
  totalCount: number;
}

export interface IAnalyzeListContent {
  name: string;
  type: string;
  userId: string;
  customerId: string;
  regDt: string;
  customerName: string;
}

export interface IDashboardAnalyzeStatus {
  analyzeAllList: {
    contents: IAnalyzeListContent[],
    totalCount: number;
  };
  product: {
    adminListDto: {
      totalCount: number;
      monthlyCount: number;
      dailyCount: number;
    };
    daily: {
      date: string;
      count: number;
    }[];
  };
  keyword: {
    adminListDto: {
      totalCount: number;
      monthlyCount: number;
      dailyCount: number;
    };
    daily: {
      date: string;
      count: number;
    }[];
  };
  comprehensive: {
    adminListDto: {
      totalCount: number;
      monthlyCount: number;
      dailyCount: number;
    };
    daily: {
      date: string;
      count: number;
    }[];
  };
}

export const initDashboardAnalyzeStatus: IDashboardAnalyzeStatus = {
  analyzeAllList: {
    contents: [],
    totalCount: 0
  },
  product: {
    adminListDto: {
      totalCount: 0,
      monthlyCount: 0,
      dailyCount: 0
    },
    daily: []
  },
  keyword: {
    adminListDto: {
      totalCount: 0,
      monthlyCount: 0,
      dailyCount: 0
    },
    daily: [],
  },
  comprehensive: {
    adminListDto: {
      totalCount: 0,
      monthlyCount: 0,
      dailyCount: 0
    },
    daily: []
  }
}

export interface IAnalyzeList {
  contents: IAnalyzeListContent[];
  totalCount: number;
}

export interface IMonitoringListContent {
  name: string;
  type: string;
  customerId: string;
  regDt: string;
  customerName: string;
  userId: string;
}

export interface IDashboardMonitoringStatus {
  monitoringAllList: {
    contents: IMonitoringListContent[],
    totalCount: number;
  };
  compareKeyword: {
    adminListDto: {
      totalCount: number;
      monthlyCount: number;
      dailyCount: number;
    };
    daily: {
      date: string;
      count: number;
    }[];
  };
  compareProduct: {
    adminListDto: {
      totalCount: number;
      monthlyCount: number;
      dailyCount: number;
    };
  };
  monitoringKeyword: {
    adminListDto: {
      totalCount: number;
      monthlyCount: number;
      dailyCount: number;
    };
  };
  monitoringProduct: {
    adminListDto: {
      totalCount: number;
      monthlyCount: number;
      dailyCount: number;
    };
    daily: {
      date: string;
      count: number;
    }[];
  }
}

export const initDashboardMonitoringStatus: IDashboardMonitoringStatus = {
  monitoringAllList: {
    contents: [],
    totalCount: 0
  },
  compareKeyword: {
    adminListDto: {
      totalCount: 0,
      monthlyCount: 0,
      dailyCount: 0
    },
    daily: []
  },
  compareProduct: {
    adminListDto: {
      totalCount: 0,
      monthlyCount: 0,
      dailyCount: 0
    }
  },
  monitoringKeyword: {
    adminListDto: {
      totalCount: 0,
      monthlyCount: 0,
      dailyCount: 0
    }
  },
  monitoringProduct: {
    adminListDto: {
      totalCount: 0,
      monthlyCount: 0,
      dailyCount: 0
    },
    daily: []
  }
}

export interface IMonitoringList {
  contents: IMonitoringListContent[];
  totalCount: number;
}