import styled from 'styled-components'

interface IStyleProps {
  color?: string;
  fontSize?: string;
  margin?: string;
}

const TableWrap = styled.div`
  & > div > table > thead,
  & > div > table > tbody {
    font-size: 12px;
  }
`

const Text = styled.span<IStyleProps>`
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: 700;
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  padding: 0 60px;

  & > div {
    margin-right: 20px;
  }
`

export const DetailSendLogsStyles = {
  TableWrap,
  Text,
  Filters,
}