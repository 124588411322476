import MarketDetail from 'components/market/popup/MarketDetail';
import { useDispatch, useSelector } from 'react-redux';
// Modules
import { ActionPayload, StoreState, UserSales } from 'store/modules/types';
import { action } from 'store/modules/userSales';

const useUserSales = () => {

  const dispatch = useDispatch();

  const userSales = useSelector((state: StoreState) => state.userSales);

  // Get List
  const requestGetUserSalesList = (data: any) => {
    dispatch(action.requestGetUserSalesList(data));
  }

  return {
    userSales,
    requestGetUserSalesList,
  }
}

export default useUserSales
