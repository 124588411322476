import { useDispatch, useSelector } from 'react-redux';

// modules
import { ICommonCode, StoreState } from 'store/modules/types';
import { action } from 'store/modules/basics';

const useBasics = () => {

  const dispatch = useDispatch();

  const basics = useSelector((state: StoreState) => state.basics);
  const { user, commonCodeList } = basics;

  const onLogin = (user: any): void => {
    dispatch(action.login(user));
  }

  const onLogout = (): void => {
    dispatch(action.logout());
  }

  const onLoginFailed = (data: any): void => {
    dispatch(action.loginFailed(data));
  }

  const onLoginSucceeded = (data:any): void => {
    dispatch(action.loginSucceeded(data));
  }

  const changeMenu = (selectMenu: string = 'dashboard'): void => {
    /*if (selectMenu === menu) {
      return;
    }*/
    dispatch(action.setMenu(selectMenu));
  }

  const isAuthenticated = () => {
    //console.log("isAuthenticated#user = ", user);

    let checkUser = user;
    let neededUpdatingUser = false;
    if (!checkUser) {
      checkUser = localStorage.getItem('account');
      neededUpdatingUser = true;
    }
    //const accessToken = localStorage.getItem('access-token');
    //console.log("isAuthenticated#user = ", user);
    //console.log("isAuthenticated#accessToken = ", accessToken);
    if (checkUser) {
      if (neededUpdatingUser) {
        dispatch(action.upateUserInfo());
      }
      return true;
    }
    localStorage.removeItem('account');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');

    return false;
  }
  
  const requestAllCommonCode = (payload: any) => {
    dispatch(action.getAllCommonCode(payload));
  }

  const getTextByCode = (key: string, value: string) => {
    const tempCommonCodeList: any = commonCodeList;
    const codeIndex: number = tempCommonCodeList[key].findIndex((item: {value: string, text: string}) => item.value === value)

    if (codeIndex === -1) {
      return '';
    }

    return tempCommonCodeList[key][codeIndex].text;
  }

  const getMarketName = (marketId: string) => {
    const marketInfo: ICommonCode[] = commonCodeList.admin_user_market_list.filter(
      market => market.value.toLowerCase() === marketId
    );

    return marketInfo.length > 0 ? marketInfo[0].value : '-';
  }
    

  return {
    basics,
    isAuthenticated,
    onLogin,
    onLoginFailed,
    onLoginSucceeded,
    onLogout,
    changeMenu,
    requestAllCommonCode,
    getTextByCode,
    getMarketName,
  }
}

export default useBasics;