import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { UserState, initPage } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse, ajax } from 'rxjs/ajax';

// ACTION PART
const action = {
  // getUserList
  getUserList: createAction(actionTypes.GET_USER_LIST),
  // fulfilledUserList
  fulfilledUserList: createAction(actionTypes.FULFILLED_USER_LIST),
  // failedUserList
  failedUserList: createAction(actionTypes.FAILED_USER_LIST),
  // deleteUser(id, callback)
  updateUser: createAction(actionTypes.UPDATE_USER),
  succeededUpdateUser: createAction(actionTypes.SUCCEEDED_UPDATE_USER),
  // deleteUser(id, callback)
  deleteUser: createAction(actionTypes.DELETE_USER),
  // succeededDeleteUser
  succeededDeleteUser: createAction(actionTypes.SUCCEEDED_DELETE_USER),
  // failedDeleteUser
  failedDeleteUser: createAction(actionTypes.FAILED_DELETE_USER),
  getUser: createAction(actionTypes.GET_USER),
  fulfilledUser: createAction(actionTypes.FULFILLED_USER),
  failedUser: createAction(actionTypes.FAILED_USER),
  // dashboard getUserList
  getDashboardUserList: createAction(actionTypes.GET_DASHBOARD_USER_LIST),
  fulfilledDashboardUserList: createAction(actionTypes.FULFILLED_DASHBOARD_USER_LIST),
  failedDashboardUserList: createAction(actionTypes.FAILED_DASHBOARD_USER_LIST),

  // User Password changed
  userPasswordUpdate: createAction(actionTypes.USER_PASSWORD_UPDATE),
  userPasswordUpdateSucceeded: createAction(actionTypes.USER_PASSWORD_UPDATE_SUCCEEDED),
  userPasswordUpdateFailed: createAction(actionTypes.USER_PASSWORD_UPDATE_FAILED),

  userPasswordReset: createAction(actionTypes.USER_PASSWORD_RESET),
  userPasswordResetSucceeded: createAction(actionTypes.USER_PASSWORD_RESET_SUCCEEDED),
  userPasswordResetFailed: createAction(actionTypes.USER_PASSWORD_RESET_FAILED),

  getUserMarketList: createAction(actionTypes.GET_USER_MARKET_LIST),
  fulfilledUserMarketList: createAction(actionTypes.FULFILLED_USER_MARKET_LIST),
  failedUserMarketList: createAction(actionTypes.FAILED_USER_MARKET_LIST),

  userIdCheck: createAction(actionTypes.USER_ID_CHECK),
  userIdCheckSucceeded: createAction(actionTypes.USER_ID_CHECK_SUCCEEDED),
  userIdCheckFailed: createAction(actionTypes.USER_ID_CHECK_FAILED),

  userEmailCheck: createAction(actionTypes.USER_EMAIL_CHECK),
  userEmailCheckSucceeded: createAction(actionTypes.USER_EMAIL_CHECK_SUCCEEDED),
  userEmailCheckFailed: createAction(actionTypes.USER_EMAIL_CHECK_FAILED),

  userInsert: createAction(actionTypes.USER_INSERT),
  userInsertSucceeded: createAction(actionTypes.USER_INSERT_SUCCEEDED),
  userInsertFailed: createAction(actionTypes.USER_INSERT_FAILED),

  // getTosList
  getTosList: createAction(actionTypes.GET_TOS_LIST),
  // fulfilledTosList
  fulfilledTosList: createAction(actionTypes.FULFILLED_TOS_LIST),
  // failedTosList
  failedTosList: createAction(actionTypes.FAILED_TOS_LIST),
  // --Payment Start--
  // paymentList
  getPaymentList: createAction(actionTypes.GET_PAYMENT_LIST),
  fulfilledPaymentList: createAction(actionTypes.FULFILLED_PAYMENT_LIST),
  failedPaymentList: createAction(actionTypes.FAILED_PAYMENT_LIST),

  getPayment: createAction(actionTypes.GET_PAYMENT),
  fulfilledPayment: createAction(actionTypes.FULFILLED_PAYMENT),
  failedPayment: createAction(actionTypes.FAILED_PAYMENT),

  deletePayment: createAction(actionTypes.DELETE_PAYMENT),
  succeededDeletePayment: createAction(actionTypes.SUCCEEDED_DELETE_PAYMENT),
  failedDeletePayment: createAction(actionTypes.FAILED_DELETE_PAYMENT),

  insertPayment: createAction(actionTypes.INSERT_PAYMENT),
  succeededInsertPayment: createAction(actionTypes.SUCCEEDED_INSERT_PAYMENT),
  failedInsertPayment: createAction(actionTypes.FAILED_INSERT_PAYMENT),

  modifyPayment: createAction(actionTypes.MODIFY_PAYMENT),
  succeededModifyPayment: createAction(actionTypes.SUCCEEDED_MODIFY_PAYMENT),
  failedModifyPayment: createAction(actionTypes.FAILED_MODIFY_PAYMENT),
  // --Payment End--

  // Common Code Start
  codeListAll: createAction(actionTypes.GET_CODE_LIST_ALL),
  fulfilledCodeListAll: createAction(actionTypes.FULFILLED_CODE_LIST_ALL),
  failedCodeListAl: createAction(actionTypes.FAILED_CODE_LIST_ALL),
  // Common Code End

  // Secret login
  secretLogin: createAction(actionTypes.SECRET_LOGIN),
  secretLoginSucceeded: createAction(actionTypes.SECRET_LOGIN_SUCCEEDED),
  secretLoginFailed: createAction(actionTypes.SECRET_LOGIN_FAILED),

  // /api/v1/admin/userSales 판매정보 - 사용자 선택시
  getUserSalesInfoSummary: createAction(actionTypes.GET_USER_SALES_INFO_SUMMARY),
  fulfilledUserSalesInfoSummary: createAction(actionTypes.FULFILLED_USER_SALES_INFO_SUMMARY),
  failedUserSalesInfoSummary: createAction(actionTypes.FAILED_USER_SALES_INFO_SUMMARY),

  // /api/v1/admin/userSalesDetail 판매정보 - 사용자 선택 후 날짜 선택시
  getUserSalesInfoDetail: createAction(actionTypes.GET_USER_SALES_INFO_DETAIL),
  fulfilledUserSalesInfoDetail: createAction(actionTypes.FULFILLED_USER_SALES_INFO_DETAIL),
  failedUserSalesInfoDetail: createAction(actionTypes.FAILED_USER_SALES_INFO_DETAIL),

  getUserAnalyzeStatus: createAction(actionTypes.GET_USER_ANALYZE_STATUS),
  succeededGetUserAnalyzeStatus: createAction(actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS),
  failedGetUserAnalyzeStatus: createAction(actionTypes.FAILED_GET_USER_ANALYZE_STATUS),


  getUserAnalyzeStatusProduct: createAction(actionTypes.GET_USER_ANALYZE_STATUS_PRODUCTS),
  succeededGetUserAnalyzeStatusProduct: createAction(actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_PRODUCTS),
  failedGetUserAnalyzeStatusProduct: createAction(actionTypes.FAILED_GET_USER_ANALYZE_STATUS_PRODUCTS),

  getUserAnalyzeStatusKeyword: createAction(actionTypes.GET_USER_ANALYZE_STATUS_KEYWORD),
  succeededGetUserAnalyzeStatusKeyword: createAction(actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_KEYWORD),
  failedGetUserAnalyzeStatusKeyword: createAction(actionTypes.FAILED_GET_USER_ANALYZE_STATUS_KEYWORD),

  getUserAnalyzeStatusComprehensive: createAction(actionTypes.GET_USER_ANALYZE_STATUS_COMPREHENSIVE),
  succeededGetUserAnalyzeStatusComprehensive: createAction(actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_COMPREHENSIVE),
  failedGetUserAnalyzeStatusComprehensive: createAction(actionTypes.FAILED_GET_USER_ANALYZE_STATUS_COMPREHENSIVE),

  getUserAnalyzeStatusCompareMonitoring: createAction(actionTypes.GET_USER_ANALYZE_STATUS_COMPARE_MONITORING),
  succeededGetUserAnalyzeStatusCompareMonitoring: createAction(actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_COMPARE_MONITORING),
  failedGetUserAnalyzeStatusCompareMonitoring: createAction(actionTypes.FAILED_GET_USER_ANALYZE_STATUS_COMPARE_MONITORING),

  getUserAnalyzeStatusTraceMonitoring: createAction(actionTypes.GET_USER_ANALYZE_STATUS_TRACE_MONITORING),
  succeededGetUserAnalyzeStatusTraceMonitoring: createAction(actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_TRACE_MONITORING),
  failedGetUserAnalyzeStatusTraceMonitoring: createAction(actionTypes.FAILED_GET_USER_ANALYZE_STATUS_TRACE_MONITORING),

  requestGetUserByUid: createAction(actionTypes.GET_USER_BY_UID),
  succeededGetUserByUid: createAction(actionTypes.SUCCEEDED_GET_USER_BY_UID),
  failedGetUserByUid: createAction(actionTypes.FAILED_GET_USER_BY_UID),

  requestPatchUserRoles: createAction(actionTypes.PATCH_USER_ROLES),
  succeededPatchUserRoles: createAction(actionTypes.SUCCEEDED_PATCH_USER_ROLES),
  failedPatchUserRoles: createAction(actionTypes.FAILED_PATCH_USER_ROLES),

  requestGetReviewrayProducts: createAction(actionTypes.GET_REVIEWRAY_PRODUCTS),
  succeededGetReviewrayProducts: createAction(actionTypes.SUCCEEDED_GET_REVIEWRAY_PRODUCTS),
  failedGetReviewrayProducts: createAction(actionTypes.FAILED_GET_REVIEWRAY_PRODUCTS),
}

const initialState: UserState = {
  userList: {
    list: [],
    page: initPage
  },
  dashboardUserData: {
    list: {
      content: [],
      size: 0,
      totalPages: 0,
      totalElements: 0,
    },
    joinDaily: [],
  },
  tosList: [],
  paymentList: [],
  codeList: [],
};

const reducer = handleActions<UserState>(
  {
    [actionTypes.GET_USER_LIST]: (state, action): UserState => {
      return state
    },
    [actionTypes.FULFILLED_USER_LIST]: (state, action): UserState => {
      return {
        ...state,
        userList: action.payload.userList
      }
    },
    [actionTypes.FAILED_USER_LIST]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.UPDATE_USER]: (state, action): UserState => {
      return state
    },
    [actionTypes.SUCCEEDED_UPDATE_USER]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_UPDATE_USER]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.DELETE_USER]: (state, action): UserState => {
      return state
    },
    [actionTypes.SUCCEEDED_DELETE_USER]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_DELETE_USER]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.GET_USER]: (state, action): UserState => {
      return state
    },
    [actionTypes.FULFILLED_USER]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_USER]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.GET_DASHBOARD_USER_LIST]: (state, action): UserState => {
      return state
    },
    [actionTypes.FULFILLED_DASHBOARD_USER_LIST]: (state, action): UserState => {
      // 전체 가입자수를 호출 할때는 callback 함수를 사용하므로 데이터를 dashboardUserList store에 저장 하지않음.
      const { dashboardUserData } = action.payload
      if (dashboardUserData 
        && dashboardUserData.joinDaily.length === 0 
        && dashboardUserData.list.content.length === 1) {
      return state
    }
      return {
        ...state,
        dashboardUserData,
      }
    },
    [actionTypes.FAILED_DASHBOARD_USER_LIST]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.GET_USER_MARKET_LIST]: (state, action): UserState => {
      return state
    },
    [actionTypes.FULFILLED_USER_MARKET_LIST]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_USER_MARKET_LIST]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.GET_TOS_LIST]: (state, action): UserState => {
      return state
    },
    [actionTypes.FULFILLED_TOS_LIST]: (state, action): UserState => {

      return {
        ...state,
        tosList: action.payload.tosList
      }
    },
    [actionTypes.FAILED_TOS_LIST]: (state, action): UserState => {
      return {
        ...state,
      }
    },    
    [actionTypes.GET_PAYMENT_LIST]: (state, action): UserState => {
      return state
    },
    [actionTypes.FULFILLED_PAYMENT_LIST]: (state, action): UserState => {
      return {
        ...state,
        paymentList: action.payload.paymentList
      }
    },
    [actionTypes.FAILED_PAYMENT_LIST]: (state, action): UserState => {
      return {
        ...state
      }
    },
    [actionTypes.GET_PAYMENT]: (state, action): UserState => {
      return state
    },
    [actionTypes.FULFILLED_PAYMENT]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_PAYMENT]: (state, action): UserState => {
      return{
        ...state,
      }
    },
    [actionTypes.DELETE_PAYMENT]: (state, action): UserState => {
      return state
    },
    [actionTypes.SUCCEEDED_DELETE_PAYMENT]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_DELETE_PAYMENT]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.INSERT_PAYMENT]: (state, action): UserState => {
      return state
    },
    [actionTypes.SUCCEEDED_INSERT_PAYMENT]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_INSERT_PAYMENT]: (state, action): UserState => {
      return state
    },
    [actionTypes.MODIFY_PAYMENT]: (state, action): UserState => {
      return state
    },
    [actionTypes.SUCCEEDED_MODIFY_PAYMENT]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_MODIFY_PAYMENT]: (state, action): UserState => {
      return {
        ...state,
      }
    },
    [actionTypes.GET_CODE_LIST_ALL]: (state, action): UserState => {
      return state
    },
    [actionTypes.FULFILLED_CODE_LIST_ALL]: (state, action): UserState => {
      return {
        ...state,
        codeList: action.payload.codeList
      }
    },

    [actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS]: (state, action): UserState => {
      return state
    },

    [actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_PRODUCTS]: (state, action): UserState => {
      return state
    },

    [actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_KEYWORD]: (state, action): UserState => {
      return state
    },

    [actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_COMPREHENSIVE]: (state, action): UserState => {
      return state
    },

    [actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_COMPARE_MONITORING]: (state, action): UserState => {
      return state
    },

    [actionTypes.SUCCEEDED_GET_USER_ANALYZE_STATUS_TRACE_MONITORING]: (state, action): UserState => {
      return state
    },

    [actionTypes.GET_USER_BY_UID]: (state, action): UserState => {
      return state
    },

    [actionTypes.SUCCEEDED_GET_USER_BY_UID]: (state, action): UserState => {
      return state
    },

    [actionTypes.PATCH_USER_ROLES]: (state, action): UserState => {
      return state
    },

    [actionTypes.SUCCEEDED_PATCH_USER_ROLES]: (state, action): UserState => {
      return state
    },

    [actionTypes.GET_REVIEWRAY_PRODUCTS]: (state, action): UserState => {
      return state
    },

    [actionTypes.SUCCEEDED_GET_REVIEWRAY_PRODUCTS]: (state, action): UserState => {
      return state
    },

  },
  initialState
);

// EPIC PART
const requestUserListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_LIST,
      success: (res: AjaxResponse) => action.fulfilledUserList({ userList: res.response.data }),
      fail: actionTypes.FAILED_USER_LIST
    },
    url: API_URL.ADMIN_USER_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardUserListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_USER_LIST,
      success: (res: AjaxResponse) => action.fulfilledDashboardUserList({ dashboardUserData: res.response.data }),
      fail: actionTypes.FAILED_DASHBOARD_USER_LIST
    },
    url: API_URL.DASHBOARD,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const updateUserEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.UPDATE_USER,
      success: (res: AjaxResponse) => action.succeededUpdateUser({ }),
      fail: actionTypes.FAILED_UPDATE_USER
    },
    url: API_URL.USER,
    method: AjaxMethods.PUT
  };

  return epicService.request(actions$, reqParams);
}

const deleteUserEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.DELETE_USER,
      success: (res: AjaxResponse) => action.succeededDeleteUser({ }),
      fail: actionTypes.FAILED_DELETE_USER
    },
    url: API_URL.USER,
    method: AjaxMethods.DELETE
  };

  return epicService.request(actions$, reqParams);
}

const requestUserEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER,
      success: (res: AjaxResponse) => action.fulfilledUser({ user: res.response.data }),
      fail: actionTypes.FAILED_USER
    },
    url: API_URL.USER,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestUserPasswordUpdate = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.USER_PASSWORD_UPDATE,
      success: (res: AjaxResponse) => action.userPasswordUpdateSucceeded({ }),
      fail: actionTypes.USER_PASSWORD_UPDATE_FAILED
    },
    url: API_URL.USER_PW_CHANGE,
    method: AjaxMethods.PUT
  };
  return epicService.request(actions$, reqParams);
}

const requestUserPasswordReset = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.USER_PASSWORD_RESET,
      success: (res: AjaxResponse) => action.userPasswordResetSucceeded({ }),
      fail: actionTypes.USER_PASSWORD_RESET_FAILED
    },
    url: API_URL.USER_PW_CHANGE,
    method: AjaxMethods.PATCH
  };
  return epicService.request(actions$, reqParams);
}


const requestUserMarketListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_MARKET_LIST,
      success: (res: AjaxResponse) => action.fulfilledUserMarketList({ userMarket: res.response.list }),
      fail: actionTypes.FAILED_USER_MARKET_LIST
    },
    url: API_URL.USER_MARKET,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}


const requestUserIdCheckEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.USER_ID_CHECK,
      success: (res: AjaxResponse) => action.userIdCheckSucceeded({ }),
      fail: actionTypes.USER_ID_CHECK_FAILED
    },
    url: API_URL.ID_CHECK,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestUserEmailCheckEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.USER_EMAIL_CHECK,
      success: (res: AjaxResponse) => action.userEmailCheckSucceeded({ }),
      fail: actionTypes.USER_EMAIL_CHECK_FAILED
    },
    url: API_URL.EMAIL_CHECK,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestUserInsertEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.USER_INSERT,
      success: (res: AjaxResponse) => action.userInsertSucceeded({ }),
      fail: actionTypes.USER_INSERT_FAILED
    },
    url: API_URL.JOIN,
    method: AjaxMethods.POST
  };

  return epicService.request(actions$, reqParams);
}

const requestTosListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_TOS_LIST,
      success: (res: AjaxResponse) => action.fulfilledTosList({ tosList: res.response.data.list }),
      fail: actionTypes.FAILED_TOS_LIST
    },
    url: API_URL.TOS,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestPaymentListEpic = (action$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_PAYMENT_LIST,
      success: (res: AjaxResponse) => action.fulfilledPaymentList({ paymentList: res.response.data }),
      fail: actionTypes.FAILED_PAYMENT_LIST
    },
    url: API_URL.PAYMENT,
    method: AjaxMethods.GET
  }
  return epicService.request(action$, reqParams);
}

const requestPaymentEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_PAYMENT,
      success: (res:AjaxResponse) => action.fulfilledPayment({ payment: res.response.data }),
      fail: actionTypes.FAILED_PAYMENT
    },
    url: API_URL.PAYMENT_DETAIL,
    method: AjaxMethods.GET 
  }
  return epicService.request(actions$, reqParams);
}

const deletePaymentEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.DELETE_PAYMENT,
      success: (res: AjaxResponse) => action.getPaymentList(),
      fail: actionTypes.FAILED_DELETE_PAYMENT
    },
    url: API_URL.PAYMENT_DELETE,
    method: AjaxMethods.DELETE
  }
  return epicService.request(actions$, reqParams);
}

const insertPaymentEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.INSERT_PAYMENT,
      success: (res: AjaxResponse) => action.getPaymentList(),
      fail: actionTypes.FAILED_INSERT_PAYMENT
    },
    url: API_URL.PAYMENT_INSERT,
    method: AjaxMethods.POST
  }
  return epicService.request(actions$, reqParams);
}

const modifyPaymentEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.MODIFY_PAYMENT,
      success: (res: AjaxResponse) => action.getPaymentList(),
      fail: actionTypes.FAILED_MODIFY_PAYMENT
    },
    url: API_URL.PAYMENT_MODIFY,
    method: AjaxMethods.PUT
  }
  return epicService.request(actions$, reqParams);
}

const requestCodeListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_CODE_LIST_ALL,
      success: (res: AjaxResponse) => action.fulfilledCodeListAll({ codeList: res.response }),
      fail: actionTypes.FAILED_CODE_LIST_ALL
    },
    url: API_URL.CODE,
    method: AjaxMethods.GET
  }
  return epicService.request(actions$, reqParams);
}

const secretLoginEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.SECRET_LOGIN,
      success: (res: AjaxResponse) => action.secretLoginSucceeded({ }),
      fail: actionTypes.SECRET_LOGIN_FAILED
    },
    url: API_URL.LOGIN_ADMIN,
    method: AjaxMethods.POST
  };
  return epicService.request(actions$, reqParams);
}

// /api/v1/admin/userSales 판매정보 - 사용자 선택시
const requestUserSalesInfoSummary = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_SALES_INFO_SUMMARY,
      success: (res: AjaxResponse) => action.failedUserSalesInfoSummary({ }),
      fail: actionTypes.FAILED_USER_SALES_INFO_SUMMARY
    },
    url: API_URL.ADMIN_USER_SALES_INFO_SUMMARY,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

// /api/v1/admin/userSalesDetail 판매정보 - 사용자 선택 후 날짜 선택시
const requestUserSalesInfoDetail = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_SALES_INFO_DETAIL,
      success: (res: AjaxResponse) => action.failedUserSalesInfoDetail({ }),
      fail: actionTypes.FAILED_USER_SALES_INFO_DETAIL
    },
    url: API_URL.ADMIN_USER_SALES_INFO_DETAIL,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestUserAnalyzeStatusEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_ANALYZE_STATUS,
      success: (res: AjaxResponse) => action.succeededGetUserAnalyzeStatus({ }),
      fail: actionTypes.FAILED_GET_USER_ANALYZE_STATUS
    },
    url: API_URL.ADMIN_USERS_V2,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestUserAnalyzeStatusProductEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_ANALYZE_STATUS_PRODUCTS,
      success: (res: AjaxResponse) => action.succeededGetUserAnalyzeStatusProduct({ }),
      fail: actionTypes.FAILED_GET_USER_ANALYZE_STATUS_PRODUCTS
    },
    url: API_URL.ADMIN_USERS_V2,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestUserAnalyzeStatusKeywordEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_ANALYZE_STATUS_KEYWORD,
      success: (res: AjaxResponse) => action.succeededGetUserAnalyzeStatusKeyword({ }),
      fail: actionTypes.FAILED_GET_USER_ANALYZE_STATUS
    },
    url: API_URL.ADMIN_USERS_V2,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestUserAnalyzeStatusComprehensiveEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_ANALYZE_STATUS_COMPREHENSIVE,
      success: (res: AjaxResponse) => action.succeededGetUserAnalyzeStatusComprehensive({ }),
      fail: actionTypes.FAILED_GET_USER_ANALYZE_STATUS_COMPREHENSIVE
    },
    url: API_URL.ADMIN_USERS_V2,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestUserAnalyzeStatusCompareMonitoringEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_ANALYZE_STATUS_COMPARE_MONITORING,
      success: (res: AjaxResponse) => action.succeededGetUserAnalyzeStatusCompareMonitoring({ }),
      fail: actionTypes.FAILED_GET_USER_ANALYZE_STATUS_COMPARE_MONITORING
    },
    url: API_URL.ADMIN_USERS_V2,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestUserAnalyzeStatusTraceMonitoringEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_ANALYZE_STATUS_TRACE_MONITORING,
      success: (res: AjaxResponse) => action.succeededGetUserAnalyzeStatusTraceMonitoring({ }),
      fail: actionTypes.FAILED_GET_USER_ANALYZE_STATUS_TRACE_MONITORING
    },
    url: API_URL.ADMIN_USERS_V2,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestGetUserByUid = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_BY_UID,
      success: (res: AjaxResponse) => action.succeededGetUserByUid(),
      fail: actionTypes.FAILED_GET_USER_BY_UID
    },
    url: API_URL.USERS,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const requestPatchUserRoles = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.PATCH_USER_ROLES,
      success: (res: AjaxResponse) => action.succeededPatchUserRoles(),
      fail: actionTypes.FAILED_PATCH_USER_ROLES
    },
    url: `${API_URL.USERS}/roles`,
    method: AjaxMethods.PATCH
  };
  return epicService.request(actions$, reqParams);
}

const requestGetReviewrayProducts = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_REVIEWRAY_PRODUCTS,
      success: (res: AjaxResponse) => action.succeededGetReviewrayProducts(),
      fail: actionTypes.FAILED_GET_REVIEWRAY_PRODUCTS
    },
    url: `${API_URL.REVIVEWRAY_PRODUCTS}`,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  requestUserListEpic,
  requestDashboardUserListEpic,
  updateUserEpic,
  deleteUserEpic,
  requestUserEpic,
  requestUserPasswordUpdate,
  requestUserPasswordReset,
  requestUserMarketListEpic,
  requestUserIdCheckEpic,
  requestUserEmailCheckEpic,
  requestUserInsertEpic,
  requestTosListEpic,
  requestPaymentListEpic,
  requestPaymentEpic,
  deletePaymentEpic,
  insertPaymentEpic,
  modifyPaymentEpic,
  requestCodeListEpic,
  secretLoginEpic,
  requestUserSalesInfoSummary,
  requestUserSalesInfoDetail,
  requestUserAnalyzeStatusEpic,
  requestUserAnalyzeStatusProductEpic,
  requestUserAnalyzeStatusKeywordEpic,
  requestUserAnalyzeStatusComprehensiveEpic,
  requestUserAnalyzeStatusCompareMonitoringEpic,
  requestUserAnalyzeStatusTraceMonitoringEpic,
  
  requestGetUserByUid,
  requestPatchUserRoles,
  requestGetReviewrayProducts,
);

export { action, epic };
export default reducer;