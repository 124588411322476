import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/qna';
import { ActionPayload } from 'store/modules/types';

const useQna = () => {

  const dispatch = useDispatch();

  const qna = useSelector((state: StoreState) => state.qna);
  
  // const requestQnaCode = (groupName: any) => {
  //   dispatch(action.getQnaCode({
  //     pathVariables : {'groupName' : groupName},
  //   }));
  // }
    
  const requestQnaList = (data: any) => {    
    dispatch(action.getQnaList(data));
  }    
  
  const onGetQna = (payload: ActionPayload) => {    
    dispatch(action.getQna(payload));
  }

  const onCreateQna = (data: any) => {
    dispatch(action.createQna(data));
  }
  
  const onModifyQna = (data: any) => {
    dispatch(action.modifyQna({body : data}));
  }
  
  const onDeleteQna = (data: any) => {
    dispatch(action.deleteQna(data));
  }
  
  const onStatsUpdate = (data: any) => {
    // dispatch(action.statusUpdate({
    //   pathVariables : {'qnaId' : qnaId }}));
    dispatch(action.statusUpdate(data));
  }
  
  return {
    qna,
    requestQnaList,
    onGetQna,
    onCreateQna,
    onModifyQna,
    onDeleteQna,
    onStatsUpdate,
  }
}

export default useQna;