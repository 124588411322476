import React from 'react'
// Common
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
// Hooks
import useEmailTemplate from 'hooks/useEmailTemplate';

interface IProps {
  templateNumber: number,
  templateId: string,
  open: boolean,
  onClose(): void;
  showFeedback: Function;
}

const EmailTemplateDelete: React.FC<IProps> = (props) => {

  const { templateNumber, templateId, open, onClose, showFeedback } = props;
  const { requestDeleteEmailTemplate } = useEmailTemplate();

  const onConfirm = () => {
    const payload: any = {
      pathVariables: { path1: templateId },
      callback: (succeeded: boolean, res: any) => {
        if(succeeded && res.response.code === 'OK'){
          showFeedback('템플릿 삭제 완료되었습니다.');
        }else{
          showFeedback('템플릿 삭제 실패하였습니다.');
        }
      }
    }
    requestDeleteEmailTemplate(payload);
    onClose();
  };

  return (
    <BasePopup
      id={templateNumber}
      open={open}
      size="small"
      type={PopupTypes.DIALOG}
      title='E-mail 템플릿 삭제'
      buttons={[
        {}, { onClick: onConfirm }
      ]}
      onClose={onClose}
    >
      <span>삭제하시겠습니까?</span>
    </BasePopup>
  )
}

export default EmailTemplateDelete

