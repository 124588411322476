import React, { useEffect } from "react";
import useMonitoringProduct from "hooks/useMonitoringProduct";
import useMonitoringKeyword from "hooks/useMonitoringKeyword";
import MonitoringProduct from "./MonitoringProduct";
import MonitoringKeyword from "./MonitoringKeyword";
import { MonitoringStyles as Styled } from "../styles/MonitoringStyle/Monitoring.style";

interface IProps {
  searchDate: string;
}
const Monitoring: React.FC<IProps> = (props) => {
  const { searchDate } = props;
  const { productMonitoring, requestProductMonitoring } =
    useMonitoringProduct();
  const { keywordMonitoring, requestKeywordMonitoring } =
    useMonitoringKeyword();
  const { productMonitoringData } = productMonitoring;
  const { keywordMonitoringData } = keywordMonitoring;

  useEffect(() => {
    requestProductMonitoring({ queryParameters: { searchDt: searchDate } });
  }, [searchDate]);

  useEffect(() => {
    requestKeywordMonitoring({ queryParameters: { searchDt: searchDate } });
  }, [searchDate]);

  const today = new Date().toISOString().slice(0, 10);

  return (
    <Styled.Container>
      <Styled.Section>
        <MonitoringProduct
          searchDate={searchDate}
          total={productMonitoringData.total}
          failedCount={productMonitoringData.failedCount}
          monitoringProducts={productMonitoringData.monitoringProducts}
        />
      </Styled.Section>
      <Styled.Section>
        <MonitoringKeyword
          searchDate={searchDate}
          total={keywordMonitoringData.total}
          failedCount={keywordMonitoringData.failedCount}
          monitoringKeywords={keywordMonitoringData.monitoringKeywords}
        />
      </Styled.Section>
    </Styled.Container>
  );
};

export default Monitoring;
