import React, { useState, useEffect } from 'react';
// Components
import { Form, Image } from 'semantic-ui-react';
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
// Hooks
import useEvent from 'hooks/useEvent';
// Config
import { SERVER_URL } from 'store/modules/types/epics';
// Styled
import { EventDetailStyles as Styled } from '../styles/EventDetail.styles';
import { IEventDetailData, initEventDetailData, IPopupLinkRanges, linkAreaColor, locationButtonTextList } from '../interface';


interface IProps {
  eventNo: number;
  typeList: any[];
  open: boolean;
  onClose(): void;
}

const EventDetail: React.FC<IProps> = (props) => {
  const { eventNo, typeList, open, onClose } = props;
  const { requestEventDetail } = useEvent();
  const [ detailData, setDetailData ] = useState<IEventDetailData>(initEventDetailData);
  const [ popupLinkRanges, setPopupLinkRanges ] = useState<IPopupLinkRanges[]>([]);

  useEffect(() => {
    const payload: any = {
      pathVariables: { eventNo },
      callback: (succeeded: boolean, res:any) => {
        if (succeeded) {
          const result = res.response.data;
          console.log(result)
          setDetailData(result);
          if (result.popups[0]) {
            setPopupLinkRanges(result.popups[0].popupLinkRanges);
          }
        }
      }
    }
    requestEventDetail(payload);
  },[]);

  const categoryValueToText = (value : string) => {
    if(typeList){
      const matchedItem = typeList.filter(item => item.value === value);
      return matchedItem.length > 0 ? matchedItem[0].text : 'none';
    }
    return 'none';
  };

  const eventTypeName = detailData != undefined ? categoryValueToText(detailData.type) : "";
  const eventStartAndEndDate = detailData != undefined ? detailData.startDate + "~" + detailData.endDate : "";
  const popupStartAndEndDate = detailData.popups.length > 0 ? detailData.popups[0].startDt + "~" + detailData.popups[0].endDt : "";
  const eventImage = detailData != undefined ? SERVER_URL + detailData.eventImagePath + detailData.eventImageName : "";
  const popupImage = detailData.popups.length > 0 ? SERVER_URL + detailData.popups[0].popupImagePath + detailData.popups[0].popupImageName : "";

  // let linkArea: JSX.Element | undefined = undefined;
  // if (detailData.objPop.length > 0) {
  //   if (detailData.objPop[0].widthValue !== 0 && detailData.objPop[0].heightValue !== 0) {
  //     linkArea = (
  //       <span 
  //         style={{
  //           position: 'absolute',
  //           display: 'inline-block',
  //           background: 'rgba(0, 0, 0, .5)',
  //           zIndex: 10,
  //           width: `${detailData.objPop[0].widthValue}px`,
  //           height: `${detailData.objPop[0].heightValue}px`,
  //           top: `${detailData.objPop[0].topValue}px`,
  //           left: `${detailData.objPop[0].leftValue}px`,
  //         }} 
  //       />
  //     )
  //   }
  // }
    
  // 링크 영역의 폭, 높이 값이 있고, 팝업 이미지 소스가 있으면 가시화된 링크 영역 생성
  const linkArea: JSX.Element[] = popupImage.length > 0 && popupLinkRanges.length > 0 ? popupLinkRanges.map((item, index) => (
    <span 
      style={{
        position: 'absolute',
        display: 'inline-block',
        zIndex: 10,
        width: `${item.widthValue}px`,
        height: `${item.heightValue}px`,
        top: `${item.topValue}px`,
        left: `${item.leftValue}px`,
        background: linkAreaColor[index],
        opacity: '0.4',
      }} 
    />
  )) : [];

  const linkAreaArray: JSX.Element[] = popupLinkRanges.map((item, index) => {
    const widthValue: number = item.widthValue;
    const heightValue: number = item.heightValue;
    const topValue: number = item.topValue;
    const leftValue: number = item.leftValue;
    const url: string = item.url;

    return (
      <>
        <Styled.LinkLabelDiv className="link-sub-label">
          <span>링크영역 #{index+1}</span>
          <Styled.ColorBoxSpan style={{background: linkAreaColor[index]}} />
        </Styled.LinkLabelDiv>
        <Styled.LinkAreaItem>
          <Styled.LinkAreaDiv>   
            <Form.Group style={{marginTop: '10px'}}>
              <Form.TextArea 
                label="폭"
                value={widthValue}
                style={{ 
                  height: '50px', 
                  backgroundColor: 'rgb(240, 240, 240)',              
                }}
                readOnly
              />
              <Form.TextArea 
                label="높이"
                value={heightValue}
                style={{ 
                  height: '50px', 
                  backgroundColor: 'rgb(240, 240, 240)', 
                }}
                readOnly
              />
              <Form.TextArea 
                label="X좌표"
                value={leftValue}
                style={{ 
                  height: '50px', 
                  backgroundColor: 'rgb(240, 240, 240)', 
                }}
                readOnly
              />
              <Form.TextArea 
                label="Y좌표"
                value={topValue}
                style={{ 
                  height: '50px', 
                  backgroundColor: 'rgb(240, 240, 240)', 
                }}
                readOnly
              />
            </Form.Group>
          </Styled.LinkAreaDiv>
          <Form.TextArea 
            label="외부 링크"
            value={url}
            style={{ 
              height: '50px',
              backgroundColor: 'rgb(240, 240, 240)', 
            }}
            readOnly
          /> 
        </Styled.LinkAreaItem>
      </>
    );
  })

  const locationCheckboxList: JSX.Element[] = detailData.popups.length > 0 ? locationButtonTextList.map((item, index) => {
    const isChecked: boolean = detailData.popups[0].location.indexOf(item.value) !== -1;

    return (
      <Form.Checkbox 
        key={index}
        label={item.text}
        checked={isChecked}            
        readOnly
      />      
    )
  }) : [];

  const popupDiv: JSX.Element = detailData.popupYn ? (
    <Styled.PopupDiv>    
      <Form.Group widths='equal'>
        <Form.Checkbox 
          label='링크 클릭 시 회원가입 페이지로 이동' 
          checked={detailData.popups[0].loginYn}            
          readOnly
        />      
      </Form.Group>
      팝업 노출 위치 
      <Form.Group style={{padding: '0 20px'}}>
        {locationCheckboxList}
      </Form.Group>
      <Form.TextArea 
        label="팝업 시작/종료일"
        value={popupStartAndEndDate}
        style={{ 
          height: '50px', 
          backgroundColor: 'rgb(240, 240, 240)', 
          overflowY: 'scroll' 
        }}
        readOnly
      />

      팝업 이미지
      <Styled.PopupImageDiv>
        {linkArea}
        <Image src={popupImage} />
      </Styled.PopupImageDiv>
      {linkAreaArray}
    </Styled.PopupDiv>
  ) : <></>;
  
  const view = detailData != undefined ? 
    <>
      <Styled.FormDiv>
        <Form.Group widths='equal'>
          <Form.Checkbox 
            label='NEW아이콘' 
            checked={detailData.topView}            
            readOnly
          />
          <Form.Checkbox 
            label='당첨유무' 
            checked={detailData.winnerYn}
            readOnly
          />
          <Form.Checkbox 
            label='종료유무' 
            checked={detailData.completeYn}
            readOnly
          />
          <Form.Checkbox 
            label='게시유무' 
            checked={detailData.viewYn}
            readOnly
          />
          <Form.Checkbox 
            label='회원전용' 
            checked={detailData.loginYn}
            readOnly
          />
        </Form.Group>

        <Form.Group widths='equal' style={{ padding: '0 8px 0 8px' }} >
          <Styled.ThumbnailImage src={eventImage} floated='left' />
          <div style={{ width: '100%' }}>
            <Form.TextArea 
              label="카테고리"
              value={eventTypeName}
              style={{ 
                height: '50px', 
                backgroundColor: 'rgb(240, 240, 240)', 
                overflowY: 'scroll' 
              }}
              readOnly
            />
            <Form.TextArea 
              label="이벤트 시작/종료일"
              value={eventStartAndEndDate}
              style={{ 
                height: '50px', 
                backgroundColor: 'rgb(240, 240, 240)', 
                overflowY: 'scroll' 
              }}
              readOnly
            />
          </div>
        </Form.Group>

        <Form.TextArea 
          label="제목"
          value={detailData.title}
          style={{ 
            height: '50px', 
            backgroundColor: 'rgb(240, 240, 240)', 
            overflowY: 'scroll' 
          }}
          readOnly
        />
        <Styled.HtmlContents 
          dangerouslySetInnerHTML={{ __html: detailData.contents }}
        />
        <Form.Checkbox 
          label='팝업 노출' 
          className="form-checkbox"
          checked={detailData.popupYn}
          readOnly
        />
        {popupDiv}
      </Styled.FormDiv>
    </>
  : null;

  return (
    <>
      <BasePopup
        open={open}
        id={0}
        size='small'
        title="이벤트 상세"
        type={PopupTypes.DIALOG}
        buttons={[{name: 'OK', onClick: onClose}]}
        onClose={onClose}
      >
        {view}
      </BasePopup>
    </>
  );
} 
export default EventDetail;