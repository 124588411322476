import moment from 'moment';
import React from 'react'
import DatePick from './DatePick';
import { IRequestBannerParams } from './interface'
import { SearchBoxStyles as Styled } from './styles/SearchBox.styles'
import Toggle from './Toggle';

interface IProps {
  requestParams: IRequestBannerParams;
  onChangeParams: (
    key: keyof IRequestBannerParams, 
    value: IRequestBannerParams[keyof IRequestBannerParams]
  ) => void;
  isOnlyDefaultBanner: boolean;
  onChangeOnlyDefault: () => void;
  onSearch: () => void;
}

const SearchBox: React.FC<IProps> = (props) => {
  const { 
    requestParams, 
    onChangeParams, 
    isOnlyDefaultBanner, 
    onChangeOnlyDefault,
    onSearch,
  } = props;

  const serviceTypeValue = [
    {
      value: '',
      label: '전체',
    },
    {
      value: 'SELLERSPOT',
      label: '셀러공간',
    },
    {
      value: 'IRANK',
      label: '아이랭크',
    },
    {
      value: 'REVIEWRAY',
      label: '리뷰레이',
    },
  ]

  const usingValue = [
    {
      value: '',
      label: '전체',
    },
    {
      value: 'USE',
      label: '사용',
    },
    {
      value: 'NOT_USE',
      label: '미사용',
    },
  ]
    
  const onChangeBannerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeParams('name', e.currentTarget.value);
  }

  const onChangeDate = (key: 'endDt' | 'startDt', value: string) => {
    onChangeParams(key, value);
  }

  const onKeySearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  }

  return (
    <Styled.Wrap>
      <Styled.ContentList>
        <Styled.ContentListItem>
          <Styled.Text>게시 기간</Styled.Text>
          <Styled.Content>
            <DatePick 
              type='startDt' 
              dataFormat='basic'
              date={requestParams.startDt} 
              onChangeDate={onChangeDate} 
            />
            <Styled.Text margin='0 10px' width='10px' fontWeight={500}>~</Styled.Text>
            <DatePick 
              type='endDt' 
              dataFormat='basic'
              date={requestParams.endDt} 
              onChangeDate={onChangeDate} 
            />
          </Styled.Content>
        </Styled.ContentListItem>
        <Styled.ContentListItem>
          <Styled.Text>분류</Styled.Text>
          <Styled.Content>
            <Toggle 
              requestParams={requestParams} 
              type='serviceType' 
              datas={serviceTypeValue} 
              onChangeParams={onChangeParams} 
            />
          </Styled.Content>
          <Styled.Text>사용 상태</Styled.Text>
          <Styled.Content>
            <Toggle 
              requestParams={requestParams} 
              type='activeYn' 
              datas={usingValue} 
              onChangeParams={onChangeParams}
            />
          </Styled.Content>
          <Styled.Content>
            <Styled.Label>
              <Styled.Text>기본 배너 여부</Styled.Text>
              <Styled.CheckBox checked={isOnlyDefaultBanner} onChange={onChangeOnlyDefault} />
            </Styled.Label>
          </Styled.Content>
        </Styled.ContentListItem>
        <Styled.ContentListItem>
          <Styled.Text>배너명</Styled.Text>
          <Styled.Content>
            <Styled.Input 
              placeholder='배너명을 입력해주세요'
              value={requestParams.name}
              onChange={onChangeBannerName}
              onKeyPress={onKeySearch}
            />
          </Styled.Content>
        </Styled.ContentListItem>
      </Styled.ContentList>
    </Styled.Wrap>
  )
}

export default SearchBox