import React, { useEffect, useState } from 'react';

// Components
import { 
  Card, 
  Form,
  Icon,
} from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import Flatpickr from 'react-flatpickr';

// Styled
import { UserSearchStyles as Styled } from './styles/UserSearch.styles';

// Interface
import {
  useUserContext,
  getDateFormatter,
  initISearchUserParams,
} from './interface'
import useBasics from 'hooks/useBasics';
import { ICommonCode, ICommonCodeConvert } from 'store/modules/types';
import RadioGroup from 'components/common/radioGroup';
import { convertCommonCode } from 'util/common';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { PARTNERSHIP } from 'util/constants';

interface IProps {
  refresh: any;
}

const UserSearch: React.FC<IProps> = (props) => {
  const { refresh } = props;

  const { commonCodeList } = useBasics().basics;

  const options = convertCommonCode(commonCodeList.admin_user_search_type);
  const roleType = convertCommonCode(commonCodeList.admin_user_role_type);
  const membershipType = convertCommonCode(commonCodeList.membership_type);
  const irankMembershipType = membershipType.filter(membership => initISearchUserParams.irankMemberships.includes(membership.value))
  const reviewrayMembershipType = membershipType.filter(membership => initISearchUserParams.reviewMemberships.includes(membership.value))
  const membershipItemType = commonCodeList.membership_item_type;

  const { setSelectedUser, searchParams } = useUserContext();
  const [ params, setParams ] = useState(searchParams);
  const [ regDates, setRegDates ] = useState<Date[]>([]);
  const userRoleOptions: ICommonCodeConvert[] = [
    { text: '전체', value: 'ALL' },
    ...roleType,
  ];
  const irankMembershipTypes: ICommonCodeConvert[] = [
    { text: '전체', value: 'IRANK-ALL' },
    ...irankMembershipType,
  ];
  const reviewrayMembershipTypes: ICommonCodeConvert[] = [
    { text: '전체', value: 'REVIEWRAY-ALL' },
    ...reviewrayMembershipType,
  ];

  const cooperationTypes: ICommonCodeConvert[] = [
    { text: '일반', value: 'COMMON' },
    { text: '샵링커', value: 'SHOPLINKER' },    
  ];

  const cooperationTypesAll: ICommonCodeConvert[] = [
    { text: '전체', value: 'COOPERATION-ALL' },
    { text: `${PARTNERSHIP['COMMON']}`, value: 'COMMON' },
    { text: `${PARTNERSHIP['SHOPLINKER']}`, value: 'SHOPLINKER' },    
  ];

  useDidMountEffect(() => {
    onSearch()
  }, [])

  const onChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
    setParams({
      ...params,
      [data.name]: data.value
    });
  };

  const onChangeParams = (key: string, value: string) => {
    setParams({
      ...params,
      [key]: value,
    })
  }

  const onSearch = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSelectedUser(null);
    refresh(params, 1);
  };

  const onRefresh = () => {
    setSelectedUser(null);
    setParams(initISearchUserParams)
    refresh(initISearchUserParams);
  }

  const onSearchKey = (event: any) => {  
    if(event.key === 'Enter' ){
      onSearch();
    }
  };

  const onClickToggleButton = (event: any, types:any[], commonCode: {value: any; text: any;}[], key:string) => {
    const {name = ''} = event.target
    let tempTypes: string[] = [];
    const type = types? types : []

    if (name.indexOf('ALL') > -1) {
      const commonCodeValues: string[] = commonCode.map(item => item.value);
      const isAllActivated: boolean = commonCodeValues.filter(value => !type.includes(value as any)).length === 0;
      tempTypes = isAllActivated ? [commonCodeValues[0]] : [...commonCodeValues];
    } else {
      
      const isExistedValue: boolean = type.includes(name);
      if (isExistedValue) {
        tempTypes = type.length > 1 ? type.filter(type => type !== name) : [...type];
      }
      else {
        tempTypes = [...type, name];
      }
    }
    setParams({
      ...params,
      [key]: tempTypes as any[]
    })
  }

  const onpickrReset = () => {
    setParams({
      ...params,
      registerStartDate: '',
      registerEndDate: '',
    });
  }

  const onChangeStartDate = (date: Date[]) => {
    setParams({
      ...params,
      registerStartDate: getDateFormatter(date[0]),
    })
  }

  const onChangeEndDate = (date: Date[]) => {
    setParams({
      ...params,
      registerEndDate: getDateFormatter(date[0]),
    })
  }

  const onChangeQuitYn = () => {
    setParams({
      ...params,
      quit: !params.quit,
    })
  }

  // const handelDatePickerChange = (dates: Date[]) => {
  //   const date: string[] = dates.map((date: any) => getDateFormatter(date));
  //   const [regStartDate, regEndDate] = dates.length > 1 ? [...date] : [...date, ...date];
   
  //   setParams({
  //     ...params,
  //     regStartDate,
  //     regEndDate,
  //   });

  //   setRegDates(dates);
  // };

  const ToggleButton = ({types, commonCode, option, keyName}:{types:any[], commonCode: {value: any; text: any;}[], option: ICommonCodeConvert, keyName:string}) => {
    const type = types? types : [];
    const codeValues: string[] = commonCode.map(item => item.value);
    const isAllActivated: boolean = codeValues.filter(value => !type.includes(value as any)).length === 0;
    const isActivated: boolean = option.value.indexOf('ALL') > -1  ? isAllActivated : type.includes(option.value as any);

    return (
      <Styled.ToggleButton 
        key={option.value}
        name={option.value}         
        onClick={(e:any) => {
          onClickToggleButton(e, type, commonCode, keyName)
        }} 
        active={isActivated}
      >
        {option.text}
      </Styled.ToggleButton>
    )
  }
  
  const userRoleButtons = userRoleOptions.map(option => {
    return <ToggleButton 
      types={params.roles}
      commonCode={roleType}
      option={option}
      keyName='roles'
    />
  })

  const irankMembershipButtons = irankMembershipTypes.map(option => {
    return <ToggleButton 
      types={params.irankMemberships}
      commonCode={irankMembershipType}
      option={option}
      keyName='irankMemberships'
    />
  })

  const reviewrayMembershipButtons = reviewrayMembershipTypes.map(option => {
    return <ToggleButton 
      types={params.reviewMemberships}
      commonCode={reviewrayMembershipType}
      option={option}
      keyName='reviewMemberships'
    />
  })


  const cooperationTypeButtons = cooperationTypesAll.map(option => {
    return <ToggleButton 
      types={params.cooperationTypes}
      commonCode={cooperationTypes}
      option={option}
      keyName='cooperationTypes'
    />
  })
  
  return(
    <>
      <Styled.SearchDiv>
        <Card fluid>
          <Card.Content style={{ height: 'auto', overflowY: 'auto' }}>
            <Styled.RowDiv style={{ display: 'flex' }} >
              <Styled.FormSelect
                width={2}
                id="header-select-box"
                label={<Styled.InputLabelSpan>검색유형</Styled.InputLabelSpan>}
                name="searchType"
                value={params.searchType} 
                options={options} 
                onChange={onChange}
              />
              <Styled.SearchInputDiv>
                <Form.Input
                  fluid
                  name='keyword'
                  label={<Styled.InputLabelSpan>검색어</Styled.InputLabelSpan>}
                  placeholder='검색어를 입력해주세요.'
                  value={params.keyword}
                  onChange={onChange}
                  onKeyPress={onSearchKey} 
                />
              </Styled.SearchInputDiv>
            </Styled.RowDiv>
            <Styled.FlexRowDiv>
              <Styled.FormDiv>
                <Styled.InputLabelSpan>이메일 수신</Styled.InputLabelSpan>
                <RadioGroup 
                  objKey='email'
                  datas={[
                    {
                      value: '',
                      label: '전체',
                    },
                    {
                      value: 'true',
                      label: '허용',
                    },
                    {
                      value: 'false',
                      label: '거부',
                    },
                  ]}
                  onClick={onChangeParams}
                  checkedValue={params.email? params.email : ''}
                />
              </Styled.FormDiv>
              <Styled.FormDiv>
                <Styled.InputLabelSpan>SMS 수신</Styled.InputLabelSpan>
                <RadioGroup 
                  objKey='sms'
                  datas={[
                    {
                      value: '',
                      label: '전체',
                    },
                    {
                      value: 'true',
                      label: '허용',
                    },
                    {
                      value: 'false',
                      label: '거부',
                    },
                  ]}
                  onClick={onChangeParams}
                  checkedValue={params.sms? params.sms : ''}
                />
              </Styled.FormDiv>
            </Styled.FlexRowDiv>

            <Styled.UserRoleTypesDiv>
              <Styled.UserRoleTypesLabel>
                그룹
              </Styled.UserRoleTypesLabel>
              <Styled.ToggleButtonDiv>
                {userRoleButtons}
              </Styled.ToggleButtonDiv>
            </Styled.UserRoleTypesDiv>

            <Styled.UserRoleTypesDiv>
              <Styled.UserRoleTypesLabel>
                아이랭크 멤버십
              </Styled.UserRoleTypesLabel>
              <Styled.ToggleButtonDiv>
                {irankMembershipButtons}
              </Styled.ToggleButtonDiv>
            </Styled.UserRoleTypesDiv>

            <Styled.UserRoleTypesDiv>
              <Styled.UserRoleTypesLabel>
                리뷰레이 멤버십
              </Styled.UserRoleTypesLabel>
              <Styled.ToggleButtonDiv>
                {reviewrayMembershipButtons}
              </Styled.ToggleButtonDiv>
            </Styled.UserRoleTypesDiv>

            <Styled.UserRoleTypesDiv>
              <Styled.UserRoleTypesLabel>
                제휴여부
              </Styled.UserRoleTypesLabel>
              <Styled.ToggleButtonDiv>
                {cooperationTypeButtons}
              </Styled.ToggleButtonDiv>
            </Styled.UserRoleTypesDiv>

            <Styled.FlexRowDiv >
              <Styled.ColumnDiv>
                <Styled.InputLabelDiv>
                  <Styled.InputLabelSpan>가입일자</Styled.InputLabelSpan>
                </Styled.InputLabelDiv>
                <Styled.RowDiv>
                  <Flatpickr
                    className="custom-pickr"
                    value={params.registerStartDate}
                    onChange={onChangeStartDate}
                    options={{
                      mode: "single",
                      locale: "ko",
                      enableTime: false,
                      conjunction: "~",
                      dateFormat: 'Y-m-d',
                      closeOnSelect: false
                    }}
                  />
                  <Styled.Text>&nbsp;~&nbsp;</Styled.Text>
                  <Flatpickr
                    className="custom-pickr"
                    value={params.registerEndDate}
                    onChange={onChangeEndDate}
                    options={{
                      mode: "single",
                      locale: "ko",
                      enableTime: false,
                      conjunction: "~",
                      dateFormat: 'Y-m-d',
                      closeOnSelect: false
                    }}
                  />
                  <Styled.PickrClose onClick={onpickrReset}>
                    <Icon 
                      link  
                      name="delete" 
                      size="large"
                      color="grey"
                    />
                  </Styled.PickrClose> 
                </Styled.RowDiv>
              </Styled.ColumnDiv>    
              <Styled.ColumnDiv>    
                <Styled.InputLabelSpan>탈퇴회원</Styled.InputLabelSpan>
                <Styled.FormCheckBox>
                  <Styled.CheckBox
                    id={`quitYn-checkbox`}
                    checked={!!params.quit}
                    onClick={onChangeQuitYn}
                  />
                  <Styled.Label htmlFor={`quitYn-checkbox`}>탈퇴회원 포함</Styled.Label>
                </Styled.FormCheckBox>
              </Styled.ColumnDiv>
            </Styled.FlexRowDiv>

            {/* <Styled.FlexRowDiv >
                  
            </Styled.FlexRowDiv> */}

            <Styled.FlexRowDiv style={{ justifyContent: 'center', marginTop: '15px' }}>
              <Styled.SearchButtonDiv>
                <Button 
                  variant="outlined" 
                  color="primary"
                  onClick={(e) => onSearch(e)}
                  style={{ 
                    height: '27px', 
                    marginRight: '5px' 
                  }}>
                  검색
                  </Button>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={onRefresh}
                  style={{ 
                    height: '27px' 
                  }}>
                  초기화
                </Button>
              </Styled.SearchButtonDiv>
            </Styled.FlexRowDiv>

          </Card.Content>
        </Card>
      </Styled.SearchDiv>
    </>
  )
}

export default UserSearch;