import styled from 'styled-components'

interface IStyleProps {
  isSucceded?: boolean
}

const Wrap = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const HeaderWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 5%) minmax(0, 20%) minmax(0, 20%) minmax(0, 45%) minmax(0, 10%);
  border: 1px solid #0000001f;
  border-bottom: none;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #f0f8ff;
`

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Item = styled.li`
  width: 100%;
  border: 1px solid #0000001f;

  &:hover {
    background-color: #f9f9f9;
  }

  & + & {
    border-top: none;
  }
`

const BasicDataWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 5%) minmax(0, 20%) minmax(0, 20%) minmax(0, 45%) minmax(0, 10%);
  cursor: pointer;
`

const DetailDataWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 30px;
`

const DetailData = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
  border-top: 1px solid #0000001f;
  white-space: pre-wrap;
`

const DetailDataTitle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  margin-left: 10px;
  font-weight: 700;
  font-size: 15px;
`

const DetailDataValue = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`

const Data = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  white-space: pre-wrap;
  font-weight: 500;
`

const StatusColor = styled.div<IStyleProps>`
  width: 10px;
  height: 10px;
  border-radius: 150px;
  background-color: ${({ isSucceded }) => isSucceded ? '#89ff5e' : '#ff0000'};
`

const RecollectButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #8e8e8e;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #636363;
  }
`

const SelectTimeWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const SelectTimeTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-weight: 700;
  font-size: 15px;
`

const SelectTimeList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

const SelectTimeItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #0000001f;
`

export const RealTimeStyles = {
  Wrap,
  HeaderWrap,
  Header,
  List,
  Item,
  BasicDataWrap,
  DetailDataWrap,
  DetailData,
  DetailDataTitle,
  DetailDataValue,
  Data,
  StatusColor,
  RecollectButton,
  SelectTimeWrap,
  SelectTimeTitle,
  SelectTimeList,
  SelectTimeItem,
  NoData,
}
