import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 99%;
  margin: 20px 50px 30px 20px 
`;
const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  `;

const CheckListContainer = styled.div`
  display: flex;
`

const Text = styled.p`
  display: flex;
  font-size: 1.2em;
  margin: 0 10px 0 0;
  font-weight: 450;
  color: #333;
`;


const Wrapper = styled.div `
  display: flex;
`;

const LinkButton = styled.a`
background-color: #e6e6e6;
color: black;
border: 1px solid rgba(0, 0, 0, 0.12);
padding: 10px 30px;
border-radius: 8px;
cursor: pointer;
font-size: 15px;
width: 200px; 
height: 35px; 
display: flex;
align-items: center;
justify-content: center;
white-space: nowrap; /* 텍스트를 한 줄로 표시 */
&:hover {
  background-color: #BAD3E2;
}

&:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
`;

const LinkText = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
`;

export const HotKeywordStyles = {
    CardContainer,
    TextContainer,
    CheckListContainer,
    Text,
    LinkButton,
    LinkText,
    Wrapper
}