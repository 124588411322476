import React from 'react';

// Styles
import { NextLoginStyles as Styled } from './styles/NextLogin.styles';

import { Cookies } from 'react-cookie'

// material-ui
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/icons/PersonOutline';
import LockIcon from '@material-ui/icons/LockOutlined';

// semantic-ui
import { Form, Message } from 'semantic-ui-react';

// Hooks
import useBasics from 'hooks/useBasics';
import { API_URL } from 'store/modules/types/epics';



// Interfaces
interface ILoginProps {
}

const NextLogin: React.FC<ILoginProps> = (props: ILoginProps) => {
  const cookies = new Cookies()

  const { basics, onLogin, onLoginFailed, onLoginSucceeded } = useBasics();
  const { loginResult } = basics;

  const [id, setId] = React.useState("");
  const [pw, setPw] = React.useState("");
  const [error, setError] = React.useState(["", ""]);

  const onClickLogin = () => {
    console.log("onClickLogin");

    let error = false;
    let errorId = "";
    let errorPw = "";
    if (!id) {
      error = true;
      errorId = "Please enter your id";
    }
    if (!pw) {
      error = true;
      errorPw = "Please enter your password";
    }
    setError([errorId, errorPw]);

    if (error) return;

    fetch(`${API_URL.LOGIN}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: id,
        userPW: pw,
        clientType: 'WEB',
      }),
      credentials: 'include',
    }).then(res => res.json()
    ).then((res:any) => {
      onLogin({})
      // cookies.remove('isAuth', {
      //   domain: '.sellerspot.co.kr'
      // })
      const user = res.data;
      if (res.code === 'OK') {
        return onLoginSucceeded({
          user: user
        })
      }
      
      return onLoginFailed({
        msg: 'Access Denied, no authority'
      });
    }).catch(e => onLoginFailed({
      msg: 'occured error'
    }))

    // onLogin({
    //   userId: id,
    //   userPW: pw,
    //   clientType: 'WEB',
    // });
  };

  const loginErrored = (loginResult && loginResult.error) ? true : false;
  const loginErrorMessage = loginResult?.error?.msg;

  return (
    <Styled.RootDiv>
      <Styled.LogoDiv>
        <img src="icon/32-x-32.png" style={{ paddingRight: '3px' }} />  
        <img src="images/logo-header.svg" />  
        <Styled.LogoTitleText>
          ADMIN
        </Styled.LogoTitleText>
      </Styled.LogoDiv>

      <Styled.LoginDiv>
        <CardContent>
          <Styled.LoginTitleText>
            LOGIN
          </Styled.LoginTitleText>
        </CardContent>

        <form noValidate autoComplete="off" style={{ width: '60%' }}>
          <Container>
            <Styled.InputTextDiv
              required 
              fullWidth
              id="username"
              label="username"
              value={id}
              onChange={(e) => setId(e.target.value)}
              error={(!id && error[0]) ? true : false}
              helperText={(!id && error[0]) || null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }} 
            />
            <Styled.InputTextDiv
              required 
              fullWidth
              id="password"
              label="password"
              type="password"
              value={pw}
              onChange={(e) => setPw(e.target.value)}
              error={(!pw && error[1]) ? true : false}
              helperText={(!pw && error[1]) || null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e) => { if (e.key === 'Enter') { onClickLogin() } }}
            />
          </Container>

          {loginErrorMessage ? (
          <Form.Field style={{ margin: '0 30px' }}>
            <Message
              size='mini'
              error
              content={loginErrorMessage}
            />
          </Form.Field>
        ) : null}
        </form>

        <Styled.LoginButtonDiv>
          <Button variant="outlined" color="primary" onClick={onClickLogin} fullWidth>
            send
          </Button>
        </Styled.LoginButtonDiv>
        
      </Styled.LoginDiv>
    </Styled.RootDiv>
  )
}

export default NextLogin;