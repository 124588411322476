import React from 'react'
import { IRequestBannerParams } from './interface'
import { ToggleStyles as Styled } from './styles/Toggle.styles'

interface IProps {
  requestParams: IRequestBannerParams;
  type: 'serviceType' | 'activeYn';
  datas: {label: string, value: string | undefined}[];
  onChangeParams: (key: keyof IRequestBannerParams, value: IRequestBannerParams[keyof IRequestBannerParams]) => void;
}

const Toggle: React.FC<IProps> = (props) => {
  const { requestParams, type, datas, onChangeParams } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeParams(type, e.currentTarget.value);
  }

  return (
    <>
      {datas.map((data, index) => (
        <Styled.Label key={data.label}>
          <Styled.RadioButton 
            name={type} 
            onChange={onChange} 
            value={data.value} 
            checked={requestParams[type] === data.value}
          />
          {data.label}
        </Styled.Label>
      ))}
    </>
  )
}

export default Toggle