import React from 'react';

// Components
import AppHeader from 'components/header/AppHeader';

interface IHeaderProps {
  authenticated: boolean;
}

const Header: React.SFC<IHeaderProps> = (props) => {

  const { authenticated } = props;

  return (
    <header>
      {/* 왜 컴포넌트를 직접 불러오지 않고 컴포넌트에서 컴포넌트를 불러왔나?
      확장성을 위해서?
      컴포넌트를 추가하기 위해서? */}
      <AppHeader authenticated={authenticated} />
    </header>
  );
}

export default Header;