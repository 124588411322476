import React from 'react';

// Components
import TableItemDetail, { ITableItemProps } from 'components/common/nextTable/TableItemDetail';

// Styles


interface IProps {
  data: any;
  onToList: Function;
}

const NoticeDetail: React.FC<IProps> = (props) => {

  const { data, onToList } = props;

  const detailaData: ITableItemProps = {
    title: data.title,
    content: data.contents,
    regDt: data.regDt,
    onToList
  }

  return (
    <TableItemDetail data={detailaData} />
  )
}

export default NoticeDetail;