import React, { SyntheticEvent } from 'react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
import { Modal, Button } from 'semantic-ui-react';

// Hooks
import useUsers from 'hooks/useUsers';

interface IProps {
    paymentNo: number;
    paymentType: string;
    open: boolean;
    // openDelete: boolean;
    // onCloseDelete(): any;
    onClose(): void;
    showFeedback: Function;
  } 

const PaymentDelete: React.SFC<IProps> = (props) => {

    const { deletePayment } = useUsers();

    // const { paymentNo, openDelete, paymentType, onCloseDelete, showFeedback} = props;
    const { paymentNo, paymentType, open, onClose, showFeedback} = props;

    const onDeleted = (data:string) => {
        console.log("onDeleted => callback!!!!");
        showFeedback(data+"요금제 삭제 완료되었습니다.");
    }

    const onDeletePayment = (e: SyntheticEvent, item: any) => {
        e.isPropagationStopped();
        
        const paymentNo = (item && item.data) || undefined;
        if(paymentNo){
            console.log("API CALL PAYMENT DELETE !! ");
            deletePayment({
                pathVariables: { payment_no: BigInt(paymentNo)},
                callback: onDeleted(paymentType)
            });
        }
        // onCloseDelete();
        onClose();
    }

    return(
        <BasePopup
            open={open}
            id={paymentNo}
            size='tiny'
            type={PopupTypes.DIALOG}
            title='요금제 삭제'
            buttons={[
                {}, { onClick: onDeletePayment }
            ]}
            // onClose={onCloseDelete}
            onClose={onClose}
        >
            <span>{paymentType} 요금제를 삭제하시겠습니까?</span>
        </BasePopup>
    )
};
export default PaymentDelete;