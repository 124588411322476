import React, { useEffect, useState } from 'react'
// styles
import { UserSalesStyles as Styled } from './styles/UserSales.styles'
// components
import MarketList from './MarketList'
// interface
import { IUserSalesParams, initUserSalesParams } from './interface';
import { ICommonCode } from 'store/modules/types';
// hooks
import useBasics from 'hooks/useBasics';
import useUserSales from 'hooks/useUserSales';
// lib
import DatePick from './DatePick';

const UserSales: React.FC = () => {
  const { basics } = useBasics();
  const { admin_user_market_list: marketData } = basics.commonCodeList;
  const { userSales, requestGetUserSalesList } = useUserSales();
  const { userSalesData } = userSales;
  const [ requestParams, setRequestParams ] = useState<IUserSalesParams>(initUserSalesParams);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ buttonClick, setButtonClick ] = useState<boolean>(false);

  useEffect(() => {
    if (buttonClick && userSalesData.length > 0) {
      import('./excelDown')
        .then((module) => {
          const { default: excelDown } = module;
          
          excelDown(userSalesData, marketData);
        });

      setIsLoading(false);
      setButtonClick(false);
    }
  }, [userSalesData]);

  const onExcelDown = () => {
    setIsLoading(true);
    setButtonClick(true);

    // 선택한 마켓 리스트 순서 정렬.
    const marketListSort: string[] = [];

    marketData.forEach((data: ICommonCode) => {
      requestParams.marketList.forEach((market: string) => {
        if (data.value === market) {
          marketListSort.push(data.value);
        }
      })
    })

    setRequestParams({
      ...requestParams,
      marketList: marketListSort,
    })

    requestGetUserSalesList({
      queryParameters: {
        ...requestParams,
        marketList: marketListSort,
      },
    });
  }

  return (
    <Styled.UserSales>
      <Styled.Wrap>
        <Styled.MarketSelect>
          <Styled.SubTitle>마켓</Styled.SubTitle>
          <MarketList 
            requestParams={requestParams}
            setRequestParams={setRequestParams}
          />
        </Styled.MarketSelect>
        <Styled.DateSelect>
          <Styled.SubTitle>매출 조회</Styled.SubTitle>
          <Styled.DatePick>
            <DatePick 
              requestParams={requestParams}
              setRequestParams={setRequestParams}
            />
          </Styled.DatePick>
        </Styled.DateSelect>
      </Styled.Wrap>
      <Styled.ExcelDownButton 
        onClick={() => !isLoading && onExcelDown()} 
        disabled={isLoading} 
        isLoading={isLoading}
      >
        {!isLoading ? '엑셀 다운로드' : 'DownLoading...'}
      </Styled.ExcelDownButton>
    </Styled.UserSales>
  )
}

export default UserSales
