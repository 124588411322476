import React, { SyntheticEvent, useState } from 'react';

// UI
import { Label, LabelProps, Image, Checkbox } from 'semantic-ui-react';
import { Link } from '@material-ui/core';

// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
import { stringify } from 'querystring';
// import UserModify from './popup/UserModify';

export const TagPopupTypes = {
  MANAGE: 'manage',
  SELECT: 'select',
} as const
type TagPopupType = typeof TagPopupTypes[keyof typeof TagPopupTypes]

interface IModifyTagParams {
  hashtagId: number;
  hashtagName: string;
  useYn: boolean;
}

// Hooks
interface IProps {
  category: any[];
  data: any;
  requestParams: any;
  setIsDeleted?: Function;
  onRequest: Function;
  type: TagPopupType;
  insertedPost?: any;
  setInsertedPost?: Function;
  selectedPost?: any;
  setSelectedPost?: Function;
}

const PostListItem: React.SFC<IProps> = (props) => {
  const { data, type, selectedPost, setSelectedPost } = props;
  // QnA 데이터 속성과 매핑
  const { postsId, categoryCode, mainTitle } = data;

  const [isEditMode, setIsEditMode] = useState(false);

  const onButtonClicked = (e: SyntheticEvent, item: any) => {
    // console.log('onButtonClicked', e, item);

    const id = (item && item.data) || undefined;
    if (id) {
      if (item.name === 'edit') { // 수정을 위해 입력 텍스트로 변환
        setIsEditMode(true);
      }
    }
  };


  const onCheckSelected = (postId:number) => {
    const filtered = selectedPost ? selectedPost.filter((item:any) => item.postsId === postId) : null
    // console.log('filtered ', filtered)
    
    return filtered.length > 0 ? true : false;
  }

  const onChangeCheck = (event: React.FormEvent<HTMLInputElement>, data: any) => {
    if(data.checked && setSelectedPost){
      setSelectedPost(selectedPost.concat({
        postsId: postsId,
        mainTitle: mainTitle
      }))
    } else if(!data.checked && setSelectedPost) {
      const filtered = selectedPost.filter((item:any) => item.postsId !== postsId)
      setSelectedPost(filtered);
    }
  }

  const tableListItemsProps: ITableListItemProps = {
    id: postsId,
    items: [  
      (<>
        <div style={{textAlign:'center'}}><Checkbox checked={onCheckSelected(postsId)} onChange={onChangeCheck} /></div>
      </>),
      <div style={{textAlign:'center'}} >{mainTitle}</div>,
    ],
    // iconButtons: getButtons(hashtagId, isEditMode, onButtonClicked)
  }

  return (
    <>
      <TableListItem {...tableListItemsProps} />
    </>
  );
}

const getButtons = (hashtagId:number, isEditMode: boolean, onClicked: any) => {
  const iconButtons: ITableListItemIconButton[] = [];
  if (hashtagId !== -1 && !isEditMode){
    const editButton: ITableListItemIconButton = {
      color: 'green',
      iconName: 'edit',
      tooltip: 'Edit Tag',
      onItemClicked: onClicked
    };
    const deleteButton: ITableListItemIconButton = {
      color: 'red',
      iconName: 'delete',
      tooltip: 'Delete Tag' ,
      onItemClicked: onClicked
    }  
    iconButtons.push(editButton);  
    iconButtons.push(deleteButton);
  } else if (hashtagId !== -1 && isEditMode) {
    const confirmButton: ITableListItemIconButton = {
      color: 'green',
      iconName: 'check',
      tooltip: 'Confirm',
      onItemClicked: onClicked
    };
    const deleteButton: ITableListItemIconButton = {
      color: 'red',
      iconName: 'delete',
      tooltip: 'Delete Tag' ,
      onItemClicked: onClicked
    }  
    iconButtons.push(confirmButton);  
    iconButtons.push(deleteButton);
  } else {    
    const confirmButton: ITableListItemIconButton = {
      color: 'green',
      iconName: 'check',
      tooltip: 'Confirm',
      onItemClicked: onClicked
    };
    iconButtons.push(confirmButton);  
  }

  return iconButtons;
}


const dateToLocale = (regDt: any) => {    
  const regDate = new Date(regDt)
  const year = regDate.getFullYear();
  const month = regDate.getMonth() < 10 ? '0' + (regDate.getMonth()+1) : (regDate.getMonth()+1);
  const date = regDate.getDate() < 10 ? '0' + regDate.getDate() : regDate.getDate();

  // const regDate = regDt != undefined ? regDt.split('T')[0] : "0000-00-00";
  return year+'-'+month+'-'+date;
}

  
// console.log('category : ', category);
const categoryValueToText = (value : string, category: [{value:string, text:string}]) => {
  const matchedItem = category ? category.filter(item => item.value === value) : []
  
  return matchedItem.length > 0 ? matchedItem[0].text : 'none';
}


export default PostListItem;