import React, { useEffect, useState } from 'react';
// hooks
import useMarket from 'hooks/useMarket';
import useBasics from 'hooks/useBasics';
// Components
import MarketList from './MarketList';
// interface
import { IRequestMarketParams, initRequestMarketListParams } from './interfaces';
import { convertCommonCode } from 'util/common';

const Market: React.FC = () => {
  const { basics } = useBasics();
  const { commonCodeList } = basics;
  const { market, requestMarketList } = useMarket();
  const { marketData } = market;
  const [ requestParams, setRequestParams ] = useState<IRequestMarketParams>(initRequestMarketListParams);

  useEffect(() => onRequest(), []);

  const onRequest = (params? : any) => {
    const reqParmas = params === undefined ? initRequestMarketListParams : params;
    setRequestParams(reqParmas);
    // requestMarketList({
    //   queryParameters: reqParmas
    // });
  };

  return(
    <>
      {/* <MarketList
        categoryList={convertCommonCode(commonCodeList.market_type)}
        marketTypeList={convertCommonCode(commonCodeList.market_summary_type)}
        list={marketData}
        requestParams={requestParams}
        setRequestParams={setRequestParams}
        refresh={onRequest}
      /> */}
    </>
  )
}
export default Market;