import React, { useState } from 'react'
import EmailSendingHistoryList from './EmailSendingHistoryList'
import { Slide, Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

const Email: React.FC = () => {
  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ resultMsg, setResultMsg] = useState('');

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  const showFeedback = (msg: string) => {
    setResultMsg(msg);
    setSnackbarOpen(true);
  }

  return (
    <>
      <EmailSendingHistoryList showFeedback={showFeedback} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}
const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default Email