import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { EmailTemplateState } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse, ajax } from 'rxjs/ajax';

// ACTION PART
const action = {
  requestGetEmailTemplateList: createAction(actionTypes.GET_EMAIL_TEMPLATE),
  succeededGetEmailTemplateList: createAction(actionTypes.SUCCEEDED_GET_EMAIL_TEMPLATE),
  failedGetEmailTemplateList: createAction(actionTypes.FAILED_GET_EMAIL_TEMPLATE),

  requestCreateEmailTemplateList: createAction(actionTypes.CREATE_EMAIL_TEMPLATE),
  succeededCreateEmailTemplateList: createAction(actionTypes.SUCCEEDED_CREATE_EMAIL_TEMPLATE),
  failedCreateEmailTemplateList: createAction(actionTypes.FAILED_CREATE_EMAIL_TEMPLATE),

  requestUpdateEmailTemplateList: createAction(actionTypes.UPDATE_EMAIL_TEMPLATE),
  succeededUpdateEmailTemplateList: createAction(actionTypes.SUCCEEDED_UPDATE_EMAIL_TEMPLATE),
  failedUpdateEmailTemplateList: createAction(actionTypes.FAILED_UPDATE_EMAIL_TEMPLATE),

  requestDeleteEmailTemplateList: createAction(actionTypes.DELETE_EMAIL_TEMPLATE),
  succeededDeleteEmailTemplateList: createAction(actionTypes.SUCCEEDED_DELETE_EMAIL_TEMPLATE),
  failedDeleteEmailTemplateList: createAction(actionTypes.FAILED_DELETE_EMAIL_TEMPLATE),
}
//
const initialState = new EmailTemplateState();

const reducer = handleActions<EmailTemplateState>(
  {
    [actionTypes.GET_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.SUCCEEDED_GET_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      const payload: any = action.payload
      return state
        .set('list', payload.emailTemplate);
    },
    [actionTypes.FAILED_GET_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.CREATE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.SUCCEEDED_CREATE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.FAILED_CREATE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.UPDATE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.SUCCEEDED_UPDATE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.FAILED_UPDATE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.DELETE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.SUCCEEDED_DELETE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
    [actionTypes.FAILED_DELETE_EMAIL_TEMPLATE]: (state, action): EmailTemplateState => {
      return state;
    },
  },
  initialState
);

// EPIC PART
const requestEmailTemplateEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_EMAIL_TEMPLATE,
      success: (res: AjaxResponse) => action.succeededGetEmailTemplateList({ emailTemplate: res.response.data.list }),
      fail: actionTypes.FAILED_GET_EMAIL_TEMPLATE
    },
    url: API_URL.EMAIL_TEMPLATE,
    method: AjaxMethods.GET
  };
  
  return epicService.request(actions$, reqParams);
}

const requestEmailTemplateWriteEpic = (action$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.CREATE_EMAIL_TEMPLATE,
      success: (res: AjaxResponse) => action.requestGetEmailTemplateList(),
      fail: actionTypes.FAILED_CREATE_EMAIL_TEMPLATE
    },
    url: API_URL.EMAIL_TEMPLATE,
    method: AjaxMethods.POST
  }

  return epicService.request(action$, reqParams);
}

const requestEmailTemplateUpdateEpic = (action$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.UPDATE_EMAIL_TEMPLATE,
      success: (res: AjaxResponse) => action.requestGetEmailTemplateList(),
      fail: actionTypes.FAILED_UPDATE_EMAIL_TEMPLATE
    },
    url: API_URL.EMAIL_TEMPLATE,
    method: AjaxMethods.PATCH
  }

  return epicService.request(action$, reqParams);
}

const requestEmailTemplateDeleteEpic = (action$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.DELETE_EMAIL_TEMPLATE,
      success: (res: AjaxResponse) => action.requestGetEmailTemplateList(),
      fail: actionTypes.FAILED_DELETE_EMAIL_TEMPLATE
    },
    url: API_URL.EMAIL_TEMPLATE,
    method: AjaxMethods.DELETE
  }

  return epicService.request(action$, reqParams);
}

const epic = combineEpics(
  requestEmailTemplateEpic,
  requestEmailTemplateWriteEpic,
  requestEmailTemplateUpdateEpic,
  requestEmailTemplateDeleteEpic,
);

export { action, epic };
export default reducer;