import styled, { css } from 'styled-components'

interface IStyleProps {
  flexDirection?: string;
  flex?: number;
}

interface IColorWrapStyles {
  isGradation: boolean
  backgroundColor: string
}

const Form = styled.form`
  width: 100%;
  padding: 10px;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Item = styled.li<IStyleProps>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  margin-bottom: 20px;
`

const Div = styled.div`
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
`

const Title = styled.h3<IStyleProps>`
  font-size: 14px;
  font-weight: 600;
  flex: ${({ flex }) => flex && flex};
  margin-bottom: 5px;
`

const Input = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 5px;
  font-size: 14px;

  &:focus {
    outline: 1px solid black;
  }

  &:hover {
    border: 1px solid black;
  }
`

const Select = styled.select<IStyleProps>`
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  flex: ${({ flex }) => flex && flex};
`

const Option = styled.option`
  display: flex;
  align-items: center;

  &:hover {
    background-color: black;
    color: white;
  }
`

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  align-items: center;
`

const Radio = styled.input.attrs({ type: 'radio' })`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-right: 5px;
`

const Text = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 15px;
  height: 15px;
  margin: 0 5px 0 10px;
`

const RequireMark = styled.span`
  color: red;
`

const ColorPicker = styled.input``

const ColorWrap = styled.div<IColorWrapStyles>`
  margin-left: 10px;
  width: 100px;
  height: 20px;
  border: 1px solid #343434;

  ${({ isGradation, backgroundColor }) => isGradation ? css`
    background-image: ${backgroundColor};
  ` : css`
    background-color: ${backgroundColor};
  `}
`

export const BannerWriteStyles = {
  Form,
  List,
  Item,
  Div,
  Title,
  Input,
  Select,
  Option,
  Label,
  Radio,
  Text,
  CheckBox,
  RequireMark,
  ColorPicker,
  ColorWrap,
}
