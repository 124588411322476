import React, { SyntheticEvent } from 'react';

import { Icon } from 'semantic-ui-react'

// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem';

interface IProps {
  term: any;
}

const UserListItem: React.FC<IProps> = (props) => {
  const { term } = props;
  const { uid, title, priority, required, useYn, applyDate, regDt } = term;

  const onButtonClicked = (e: SyntheticEvent, item: any) => {
    const id = (item && item.data) || undefined;
    const name = item.name;
    if(id){
      
    }
  };

  const requiredState = required ? <Icon color='green' name='checkmark' size='large' /> : '';
  const useState = useYn ? <Icon color='green' name='checkmark' size='large' /> : ''
  const applyDate2 = new Date(applyDate).toLocaleDateString();
  const regDate = new Date(regDt).toLocaleString();
  const tableListItemsProps: ITableListItemProps = {
    id: uid,
    items: [title, priority, requiredState, useState, applyDate2, regDate],
    iconButtons: getButtons(onButtonClicked)
  };

  return (
    <TableListItem {...tableListItemsProps} />
  );
}
const getButtons = (onClicked: any) => {
  const iconButtons: ITableListItemIconButton[] = [];
  const editButton: ITableListItemIconButton = {
    color: 'green',
    iconName: 'edit',
    tooltip: 'edit Term',
    onItemClicked: onClicked
  };
  const deleteButton: ITableListItemIconButton = {
    color: 'red',
    iconName: 'delete',
    tooltip: 'delete Term',
    onItemClicked: onClicked
  }

  iconButtons.push(editButton);
  iconButtons.push(deleteButton);
  return iconButtons;
}
export default UserListItem;