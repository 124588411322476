import React from 'react';

import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';

interface IProps {
  id: number;
  open: boolean;
  onDelete: Function;
  onClose: Function;
}

const SalonOnDelete: React.SFC<IProps> = (props) => {
  
  const { id, open, onClose, onDelete } = props;

  const onDeleteItem = () => {
    onDelete();
    onClose();
  }

  return (
    <BasePopup
      open={open}
      id={id}
      size='tiny'
      type={PopupTypes.DIALOG}      
      title='Delete Item'
      buttons={[
        {}, { onClick: onDeleteItem }
      ]}
      onClose={()=>onClose()}
    >
      <span>Are you sure want to delete?</span>
    </BasePopup>
  );
}

export default SalonOnDelete;