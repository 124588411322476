import React from 'react';
// Components
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
// Hooks
import useNotice from 'hooks/useNotice';

interface IProps {
  noticeNo: number;
  open: boolean;
  onClose(): void;
  showFeedback: Function;
}
const NoticeDelete: React.FC<IProps> = (props) => {

  const { noticeNo, open, onClose, showFeedback } = props;
  const { requestDeleteNotice } = useNotice();
  
  // Function
  const onDeletsNotice = () => {
    if (noticeNo) {
      const payload: any = {
        pathVariables: { noticeNo: noticeNo },
        callback: (succeeded: boolean, res: any) => {
          if(succeeded){
            showFeedback('공지사항 삭제 완료되었습니다.');
          }else{
            showFeedback('공지사항 실패 하였습니다.');
          }
        }
      }
      requestDeleteNotice(payload);
    }
    else {
      console.log("삭제 에러");
    }
    onClose();
  };

  return (
    <>
      <BasePopup 
        id={noticeNo}
        open={open}
        onClose={onClose}
        size="small"
        title="공지사항 삭제"
        type={PopupTypes.DIALOG}
        buttons={[{}, { onClick: onDeletsNotice }]}
      >
        <span>삭제하시겠습니까?</span>
      </BasePopup>
    </>
  )
}
export default NoticeDelete;