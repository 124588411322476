import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
import TableList, { ITableListProps } from 'components/common/table/TableList';
import TableListItem from 'components/common/table/TableListItem';
import useEmail from 'hooks/useEmail';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DetailSendLogsStyles as Styled } from './styles/DetailSendLogs.styles';
import { Form } from 'semantic-ui-react';
import { IDetailSendLog } from 'store/modules/types';

interface IProps {
  open: boolean;
  closePopup: () => void;
  sendId?: number;
}

const DetailSendLogs: React.FC<IProps> = (props) => {
  const { open, closePopup, sendId } = props;
  const { email, requestGetUserSendDetailLogs } = useEmail();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ filter, setFilter ] = useState<{ isSuccess: boolean; isInvalid: boolean; }>({
    isSuccess: false,
    isInvalid: false,
  });

  const [ sendEmail, setSendEmail ] = useState<any[]>([])

  useEffect(() => {
    if (sendId) {
      setIsLoading(true);
      requestGetUserSendDetailLogs({
        pathVariables: {
          path1: sendId,
        },
        callback: (succeeded: boolean, res: any) => {
          if (succeeded) {
            setSendEmail(res.response.data.list)
          }
          setIsLoading(false);
        }
      } as any)
    }
  }, [sendId])

  const onClose = () => {
    closePopup();
    setFilter({
      isSuccess: false,
      isInvalid: false,
    });
  }

  const logList: IDetailSendLog[] = filter.isSuccess && filter.isInvalid
    ? sendEmail.filter((item) => !item.isSuccess || item.isInvalid)
    : filter.isInvalid
      ? sendEmail.filter((item) => item.isInvalid)
      : filter.isSuccess
        ? sendEmail.filter((item) => !item.isSuccess)
        : sendEmail

  const headers = ['전송ID', '사용자 식별값', '사용자ID', '전송 상태', '이메일 유효성검사', '전송 일자'];
  const headerWidth = [0.5, 1, 1, 1, 1, 1];
  const tableListProps: ITableListProps = {
    viewTitle: true,
    headers,
    headerWidth,
    color: 'violet',
    count: logList.length,
    buttonPosition: 'header',
    // refresh: () => {}
  }

  return (
    <BasePopup 
      id={0}
      open={open}
      onClose={onClose}
      size={'large'}
      type={PopupTypes.BASIC}
      buttons={[{ name: '닫기'}]}
      title={'전송 상세 내역'}
    >
      <Styled.Filters>
        <Styled.Text fontSize='16px' margin='0 20px 0 0'>{'필터:'}</Styled.Text>
        <Form.Checkbox 
          label='전송 실패'
          checked={filter.isSuccess}
          onChange={() => setFilter({ ...filter, isSuccess: !filter.isSuccess })}
        />
        <Form.Checkbox 
          label='유효하지 않은 이메일'
          checked={filter.isInvalid}
          onChange={() => setFilter({ ...filter, isInvalid: !filter.isInvalid })}
        />
      </Styled.Filters>
      <Styled.TableWrap>
        <TableList {...tableListProps}>
          {!isLoading && logList.map((item, index) => (
            <TableListItem 
              key={`detail-send-log-item-${index}`}
              id={index}
              firstItemAlign={'center'}
              items={[
                item.sendId, 
                item.uid, 
                item.userId, 
                <Styled.Text color={item.isSuccess ? '#21ba45' : '#db2828'}>{item.isSuccess ? 'O' : 'X'}</Styled.Text>, 
                <Styled.Text color={!item.isInvalid ? '#21ba45' : '#db2828'}>{!item.isInvalid ? 'O' : 'X'}</Styled.Text>, 
                moment(item.regDt).format('YYYY-MM-DD'),
              ]}
            />
          ))}
        </TableList>
      </Styled.TableWrap>
    </BasePopup>
  )
}

export default DetailSendLogs