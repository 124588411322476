import styled from 'styled-components';
import { Card, TextField, Typography, CardActions } from '@material-ui/core';

const JoinDiv = styled(Card)`
  &&& {
    display: flex;
    width: 580px;
    min-height: 600px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 15px;
  }
`;

const JoinTitleText = styled(Typography)`
  &&& {
    color: rgba(0, 0, 0, 0.3);
    font-weight: 800;
    font-size: 1rem;
    padding-bottom: 20px;
  }
`;

const InputTextDiv = styled(TextField)`
  &&& {
    margin-bottom: 18px;
  }
`;

const ButtonDiv = styled(CardActions)`
  &&& {
    width: 50%;
    margin-top: 15px;
  }
`

export const UserJoinStyles = {
  JoinDiv,
  JoinTitleText,
  InputTextDiv,
  ButtonDiv,
}