import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  margin: 20px 50px 30px 0px 
`;

const Text = styled.p`
  display: flex;
  font-size: 1.2em;
  margin: 0 10px 0 0;
  font-weight: 450;
  color: #333;
  `;

export const NaverAttributeStyles = {
    CardContainer,
    Text
}