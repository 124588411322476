import React, { useEffect, useMemo, useState } from 'react';
// Components
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
import { CheckboxProps, Form, Icon, InputOnChangeData, Button } from 'semantic-ui-react';
import Ckeditor4 from 'components/common/ckeditor4';
import ImageUpload from 'components/common/fileuploader/ImageUploader';
import Flatpickr from 'react-flatpickr';
// Interface
import { getDateFormatter, getStringDateConvert, IEventDetailParams, IEventInsertParams, initIEventInsertParams, initPopupLinkRanges, IPopupLinkRanges, linkAreaColor, locationButtonTextList } from '../interface';
import useEvent from 'hooks/useEvent';
import useBasics from 'hooks/useBasics';
import JSBI from 'jsbi';
// Styled
import { EventWriteStyles as Styled } from '../styles/EventWrite.styles';

require("flatpickr/dist/themes/dark.css");
require("flatpickr/dist/l10n/ko.js");

interface IFiles{
  fileName:string;
  filePath:string,
  fileUrl:string,
}

interface IProps {
  eventNo: number;
  typeList: any[];
  open: boolean;
  onClose(): void;
  showFeedback: Function;
}
const EventWrite: React.FC<IProps> = (props) => {

  const { eventNo, typeList, open, onClose, showFeedback } = props;
  const { requestEventDetail, requestEventInsert, requestEventUpdate } = useEvent();
  const { basics } = useBasics();
  const { user } = basics;
  const [ eventParams, setEventParams ] = useState<IEventInsertParams>(initIEventInsertParams);

  const [ popupLinkRanges, setPopupLinkRanges ] = useState<IPopupLinkRanges[]>([
    {
      popupLinkRangeId: null,
      widthValue: initPopupLinkRanges.widthValue,
      heightValue: initPopupLinkRanges.heightValue,
      topValue: initPopupLinkRanges.topValue,
      leftValue: initPopupLinkRanges.leftValue,
      url: initPopupLinkRanges.url,
    }
  ]);

  useEffect(() => {
    if (eventNo > 0) {
      // 상세 API
      const payload: any = {
        pathVariables: { eventNo },
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            const result: IEventDetailParams = res.response.data;
            let eventDateArray:any[] = [];
            eventDateArray.push(getStringDateConvert(result.startDate));
            eventDateArray.push(getStringDateConvert(result.endDate));
            let popupDateArray: any[] = [];
            if (result.popups && result.popups.length > 0) {
              if (result.popups[0].startDt != null && result.popups[0].endDt != null) {
                popupDateArray.push(getStringDateConvert(result.popups[0].startDt));
                popupDateArray.push(getStringDateConvert(result.popups[0].endDt));
              } 
              else {
                const today: Date = new Date();
                const yyyy = today.getFullYear();
                const mm = today.getMonth()+1 < 10 ? `0${today.getMonth()+1}` : `${today.getMonth()+1}`;
                const dd = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;
                popupDateArray.push(getStringDateConvert(`${yyyy}-${mm}-${dd}`));
                popupDateArray.push(getStringDateConvert(`${yyyy}-${mm}-${dd}`));
              }
            }

            setEventParams({
              ...eventParams,
              completeYn: result.completeYn,
              title: result.title,
              contents: result.contents,
              viewYn: result.viewYn,
              type: result.type,
              topView: result.topView,
              winnerYn: result.winnerYn,
              eventDateList: eventDateArray,
              startDate: result.startDate,
              endDate: result.endDate,
              eventImageName: result.eventImageName,
              eventImagePath: result.eventImagePath,
              popupYn: result.popupYn,
              loginYn: result.loginYn,
              popupSaveDtos: result.popups && result.popups.length > 0 ? [{
                ...result.popups[0],
                popupDateList: popupDateArray,
                detail: result.popups[0].detail != null ? result.popups[0].detail : '',
              }] : initIEventInsertParams.popupSaveDtos
            });
            if (result.popups[0]) {              
              setPopupLinkRanges([
                ...result.popups[0].popupLinkRanges.map((popupLinkRange:any) => ({
                  ...popupLinkRange,
                  popupLinkRangeId: JSBI.BigInt(popupLinkRange.popupLinkRangeId).toString()
                }))
              ]);
            }
          }
        }
      }
      requestEventDetail(payload);    
    }
  }, []);

  const handleChange = (e: React.SyntheticEvent<HTMLElement, Event>, data:any) => {
    let value = null;
    if (data.type === 'checkbox') {
      value = data.checked;
    }
    else {
      value = data.value;
    }

    if (data.name === 'popupYn') {
      setEventParams({
        ...eventParams,
        [data.name]: value,
        popupSaveDtos: [{
          ...eventParams.popupSaveDtos[0],
          viewYn: value
        }]
      });
    } 
    else {
      setEventParams({
        ...eventParams,
        [data.name]: value
      });
    }
  };

  const handlePopupChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: InputOnChangeData | CheckboxProps) => {
    let value = null;        
    let tempPopupYn: boolean = eventParams.popupYn;

    if (data.type === 'checkbox') {   
      const isChecked: boolean = data.checked;         
      if (data.name === 'location') {
        let tempLocation: string = eventParams.popupSaveDtos.length > 0 
          ? eventParams.popupSaveDtos[0].location
          : 'ALL';
        if (isChecked) {
          tempLocation = String(data.value);
        }
        value = tempLocation;
      } 
      else if (data.name === 'viewYn') {
        value = isChecked;
        tempPopupYn = isChecked;
      }      
    } 
    else {
      value = data.value;
    }    
    setEventParams({
      ...eventParams,
      popupSaveDtos: [{
        ...eventParams.popupSaveDtos[0],
        [data.name]: value,        
      }]
    });
  };

  const handlePopupLinkChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: InputOnChangeData | CheckboxProps) => {
    let value = null;       
    const parser = data.name.split('-');
    const dataName: string = parser[0];
    const index: number = parser.length > 1 ? Number(parser[1]) : 0;

    // 배열형 state에 값을 입력하기 위해 임시 배열 생성
    // let tempPopupLinkRanges: IPopupLinkRanges[] = popupLinkRanges.map(item => {
    //   return {
    //     widthValue: item.widthValue,
    //     heightValue: item.heightValue,
    //     topValue: item.topValue,
    //     leftValue: item.leftValue,
    //     url: item.url,
    //   }
    // });
    let tempPopupLinkRanges: IPopupLinkRanges[] = popupLinkRanges.map(item => {
      return item
    });

    if (data.type === 'number') {
      if (dataName === 'widthValue' || dataName === 'heightValue' || dataName === 'topValue' || dataName === 'leftValue') {
        if (Number(data.value) > 0) {

          const dataValue: number = Number(data.value);
          const widthValue: number = popupLinkRanges[index].widthValue;
          const heightValue: number = popupLinkRanges[index].heightValue;
          const topValue: number = popupLinkRanges[index].topValue;
          const leftValue: number = popupLinkRanges[index].leftValue;

          // 링크영역 사이즈, 좌표의 최대 값 제한 (예: 영역의 폭과 x좌표는 합쳐서 600을 넘지 못함)
          if (dataName === 'widthValue') {
            value = dataValue < 601 - leftValue ? dataValue : 600 - leftValue;          
          } else if (dataName === 'leftValue') {
            value = dataValue < 601 - widthValue ? dataValue : 600 - widthValue;          
          } else if (dataName === 'heightValue') {
            value = dataValue < 601 - topValue ? dataValue : 600 - topValue;          
          } else if (dataName === 'topValue') {
            value = dataValue < 601 - heightValue ? dataValue : 600 - heightValue;          
          }
          // value = Number(data.value) < 601 ? Number(data.value) : 600;        
        } 
        else if (Number(data.value) <= 0) {
          value = 0;
        }
      }
    }
    else {
      value = data.value;
    }

    // 변경된 key의 value 값 입력
    tempPopupLinkRanges[index] = {
      ...tempPopupLinkRanges[index],
      [dataName]: value
    };
    setPopupLinkRanges(tempPopupLinkRanges);
  }

  const handleEditorChange = (data:any) => {
    setEventParams({
      ...eventParams,
      contents: data
    });
  };

  const handelDatePickerChange = (data:any) => {
    let startDate = "";
    let endDate = "";
    if (data.length > 0) {
      data.map((item: any, index:number) => {

        // console.log(" item[index] :: ", item[index]);
        if (data.length === 1) {
          startDate = getDateFormatter(item);
          endDate = getDateFormatter(item);
        }
        else {
          if (index === 0) {
            startDate = getDateFormatter(item);
          }
          else if (index === 1) {
            endDate = getDateFormatter(item);
          }
        }
      });
    }
    setEventParams({
      ...eventParams,
      startDate: startDate,
      endDate: endDate,
      eventDateList: data
    });
  };

  const handelPopupDatePickerChange = (data:any) => {
    let startDate = "";
    let endDate = "";
    if (data.length > 0) {
      data.map((item: any, index:number) => {

        // console.log(" item[index] :: ", item[index]);
        if (data.length === 1) {
          startDate = getDateFormatter(item);
          endDate = getDateFormatter(item);
        }
        else {
          if (index === 0) {
            startDate = getDateFormatter(item);
          }
          else if (index === 1) {
            endDate = getDateFormatter(item);
          }
        }
      });
    }
    setEventParams({
      ...eventParams,
      popupSaveDtos: [{
        ...eventParams.popupSaveDtos[0],
        startDt: startDate,
        endDt: endDate,
        popupDateList: data
      }]
    });
  };
  
  const onClickinitDatePicker = () => {
    setEventParams({
      ...eventParams,
      startDate: '',
      endDate: '',
      eventDateList: []
    });
  };

  
  const onClickinitPopupDatePicker = () => {
    setEventParams({
      ...eventParams,
      popupSaveDtos: [{
        ...eventParams.popupSaveDtos[0],
        popupDateList: [],
        popupImageName: '',
        popupImagePath: '',
      }]
    });
  };

  const onSetThumbnailFile = (file:IFiles) => {
    setEventParams({
      ...eventParams,
      eventImageName: file.fileName,
      eventImagePath: file.filePath
    })
  };

  const onSetPopupImageFile = (file:IFiles) => {
    setEventParams({
      ...eventParams,
      popupSaveDtos: [{
        ...eventParams.popupSaveDtos[0],
        popupImageName: file.fileName,
        popupImagePath: file.filePath,
      }]
    })
  };

  const onWriteEvent = () => {
    if (eventNo > 0) {
      // 수정 모드
      const payload: any = {
        body: { 
          ...eventParams,
          eventNo: eventNo,
          popupSaveDtos: eventParams.popupSaveDtos.length > 0 ? [{
            ...eventParams.popupSaveDtos[0],
            location: eventParams.popupSaveDtos[0].location.length > 0 ? eventParams.popupSaveDtos[0].location : 'ALL',
            popupLinkRanges: popupLinkRanges,
          }] : [],
        },
        callback: (succeeded: boolean, res:any) => {
          if(succeeded){
            showFeedback('이벤트 수정 완료되었습니다.');
          }
          else {
            showFeedback('이벤트 수정 실패하였습니다.');
          }
        }
      }
      requestEventUpdate(payload);
    }
    else {
      // 등록 모드
      const payload: any = {
        body: { 
          ...eventParams,
          popupSaveDtos: eventParams.popupSaveDtos.length > 0 ? [{
            ...eventParams.popupSaveDtos,
            location: (eventParams.popupSaveDtos.length > 0 && eventParams.popupSaveDtos[0].location.length > 0) ? eventParams.popupSaveDtos[0].location : 'ALL',
            popupLinkRanges: popupLinkRanges,
          }] : [],
        },
        callback: (succeeded: boolean, res:any) => {
          if(succeeded){
            showFeedback('이벤트 등록 완료되었습니다.');
          }
          else {
            showFeedback('이벤트 등록 실패하였습니다.');
          }
        }
      }
      requestEventInsert(payload);
    }
    onClose();
  };

  const onRemovePopupImage = () => {
    setEventParams({
      ...eventParams,
      popupSaveDtos: [{
        ...eventParams.popupSaveDtos[0],
        popupImageName: '',
        popupImagePath: '',
      }]
    })
  }

  const eventTitle = eventNo > 0 ? "이벤트 수정" : "이벤트 등록";
  const userId = user != undefined ? user.userId : "";
  const defaultImage = eventParams.eventImagePath === "" ? "" : eventParams.eventImagePath + eventParams.eventImageName;
  const popupImage: string = eventParams.popupYn ? eventParams.popupSaveDtos[0].popupImagePath + eventParams.popupSaveDtos[0].popupImageName : "";
  
  // 링크 영역의 폭, 높이 값이 있고, 팝업 이미지 소스가 있으면 가시화된 링크 영역 생성
  const linkArea: JSX.Element[] = popupImage.length > 0 && popupLinkRanges.length > 0 ? popupLinkRanges.map((item, index) => (
    <span 
      key={index}
      className="link-area"
      style={{
        width: `${item.widthValue}px`,
        height: `${item.heightValue}px`,
        top: `${item.topValue}px`,
        left: `${item.leftValue}px`,
        background: linkAreaColor[index],
        opacity: '0.4',
      }}
    />
  )) : [];

  const editor = useMemo(() => 
    eventNo === 0 ?
      <Ckeditor4 
        url="EVENT"
        setContents={handleEditorChange}
        height={500}
      />
    : eventNo > 0 && eventParams.contents ?
      <Ckeditor4 
        url="EVENT"
        contents={eventParams.contents}
        setContents={handleEditorChange}
        height={500}
      />
    : null
  , [eventParams]);

  const onAddLinkArea = () => {
    // 링크 영역 생성은 5개까지 제한
    if (popupLinkRanges.length < 5) {
      setPopupLinkRanges([
        ...popupLinkRanges,
        {
          widthValue: 0,
          heightValue: 0,
          topValue: 0,
          leftValue: 0,
          url: '',
        }
      ]);
    }
  }

  const onDeleteLinkArea = (index: number) => {
    const tempPopupLinkRanges: IPopupLinkRanges[] = popupLinkRanges.filter((_, filterIndex) => filterIndex !== index).map(item=>item);
    setPopupLinkRanges(tempPopupLinkRanges);
  }

  const linkAreaArray: JSX.Element[] = popupLinkRanges.map((item, index) => {
    const widthValue: number = item.widthValue;
    const heightValue: number = item.heightValue;
    const topValue: number = item.topValue;
    const leftValue: number = item.leftValue;
    const url: string = item.url;

    const deleteLinkArea = index !== 0 ? (
      <span style={{float: 'right'}}>
        <Styled.DeleteLinkButton 
          icon
          onClick={()=>onDeleteLinkArea(index)}
        >
          <Icon name="minus"/>
        </Styled.DeleteLinkButton>
      </span>
    ) : <></>;

    return (
      <>
        <div className="link-sub-label">
          <span>#{index+1}</span>
          <Styled.colorBoxSpan style={{background: linkAreaColor[index]}} />
          {deleteLinkArea}
        </div>
        <Styled.LinkAreaItem>
          <Styled.LinkAreaDiv>        
            <Form.Input
              label='폭'  
              type="number"        
              className="form-input-axis"
              name={`widthValue-${index}`}
              placeholder='폭'
              content={widthValue != undefined ? widthValue : 600}
              value={widthValue != undefined ? widthValue : 600}
              onChange={handlePopupLinkChange}
            />

            <Form.Input
              label='높이'
              type="number"
              className="form-input-axis"
              name={`heightValue-${index}`}
              placeholder='높이'
              content={heightValue != undefined ? heightValue : 600}
              value={heightValue != undefined ? heightValue : 600}
              onChange={handlePopupLinkChange}
            />
            <Form.Input
              label='X좌표'
              type="number"          
              className="form-input-axis"
              name={`leftValue-${index}`}
              placeholder='X좌표'
              content={leftValue != undefined ? leftValue : 0}
              value={leftValue != undefined ? leftValue : 0}
              onChange={handlePopupLinkChange}
            />

            <Form.Input
              label='Y좌표'
              type="number"
              className="form-input-axis"
              name={`topValue-${index}`}
              placeholder='Y좌표'          
              content={topValue != undefined ? topValue : 0}
              value={topValue != undefined ? topValue : 0}
              onChange={handlePopupLinkChange}
            />
          </Styled.LinkAreaDiv>
          <Form.Input
            label='외부 링크'
            className="form-input"
            name={`url-${index}`}
            placeholder='여기에 외부 URL을 입력하면, 팝업 내 링크영역 클릭 시 해당 주소로 이동합니다.'
            content={url}
            value={url}
            onChange={handlePopupLinkChange}
          />
        </Styled.LinkAreaItem>
      </>
    )
  })

  const addButton: JSX.Element = (
    <Styled.AddLinkButton 
      onClick={onAddLinkArea}
      icon
    >
      <Icon name="plus" />
    </Styled.AddLinkButton>
  );

  const locationCheckboxList: JSX.Element[] = locationButtonTextList.map((item, index) => {
    const isChecked: boolean = eventParams.popupSaveDtos.length > 0 && eventParams.popupSaveDtos[0].location === item.value;

    return (
      <Form.Checkbox 
        key={index}
        label={item.text} 
        value={item.value}
        checked={isChecked}
        name='location'
        onChange={handlePopupChange}
      />
    );
  })

  const popupDiv: JSX.Element = eventParams.popupYn ? (
    <Styled.PopupDiv>

      <Form.Group widths='equal'>
        <Form.Checkbox 
          label='링크 클릭 시 회원가입 페이지로 이동' 
          value={String(eventParams.popupSaveDtos[0].loginYn)}
          checked={eventParams.popupSaveDtos[0].loginYn}
          name='loginYn'
          onChange={handlePopupChange}
        />
      </Form.Group>

      {/* 팝업 노출 위치 선택 */}
      팝업 노출 위치
      <Styled.CommentSpan></Styled.CommentSpan>
      <Form.Group style={{padding: '0 20px'}}>
        {locationCheckboxList}
      </Form.Group>

      {/* Date Picker */}      
      팝업 노출 시작/종료일
      <Form.Group widths='equal'>
        <Flatpickr
          localName="ko"
          className="event-pickr"
          value={eventParams.popupSaveDtos[0].popupDateList}
          onChange={handelPopupDatePickerChange}
          onClose={handelPopupDatePickerChange}
          options={{
            mode: "range",
            locale: "ko",
            enableTime: false,
            conjunction: "~",
            dateFormat: 'Y-m-d',
            closeOnSelect: false
          }}
        />
        <div onClick={onClickinitPopupDatePicker} >
          <Icon 
            link  
            name="delete" 
            size="large" 
            color="red" 
            style={{ paddingTop: '9px' }} 
          />
        </div>
      </Form.Group>

      {/* Drop Zone */}
      <Styled.PopupImageDiv>
        팝업 이미지
        <Styled.PopupImageDropzonDiv>
          <ImageUpload
            fileType="POPUP"
            setFile={onSetPopupImageFile}
            defaultImage={popupImage}
            defaultImageUrlFull={false}  
            onRemoveImage={onRemovePopupImage}               
          />
          {linkArea}
        </Styled.PopupImageDropzonDiv>
      </Styled.PopupImageDiv>
      <div style={{marginTop: '1em'}}>
        <span>링크 영역</span>
        <span style={{float: 'right'}}>{addButton}</span>
      </div>
      <Styled.LinkAreaContainer>
        {linkAreaArray}
      </Styled.LinkAreaContainer>

      
    </Styled.PopupDiv>
  ) : <></>;

  return (
    <>
      <BasePopup
        open={open}
        id={0}
        size='small'
        title={eventTitle}
        type={PopupTypes.DIALOG}
        buttons={[{},{ onClick: onWriteEvent }]}
        onClose={onClose}
      >
        <Styled.FormDiv>
          <Form.Group widths='equal'>
            <Form.Checkbox 
              label='NEW아이콘' 
              value={String(eventParams.topView)}
              checked={eventParams.topView}
              name='topView'
              onChange={handleChange}
            />
            <Form.Checkbox 
              label='당첨유무' 
              value={String(eventParams.winnerYn)}
              checked={eventParams.winnerYn}
              name='winnerYn'
              onChange={handleChange}
            />
            <Form.Checkbox 
              label='종료유무' 
              value={String(eventParams.completeYn)}
              checked={eventParams.completeYn}
              name='completeYn'
              onChange={handleChange}
            />
            <Form.Checkbox 
              label='게시유무' 
              value={String(eventParams.viewYn)}
              checked={eventParams.viewYn}
              name='viewYn'
              onChange={handleChange}
            />
            <Form.Checkbox 
              label='회원전용' 
              value={String(eventParams.loginYn)}
              checked={eventParams.loginYn}
              name='loginYn'
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Select
              label="카테고리"
              name="type"
              placeholder="선택"
              value={eventParams.type}
              options={typeList}
              onChange={handleChange}
            />
          </Form.Group>
          {/* Date Picker */}
          이벤트 시작/종료일
          <Form.Group widths='equal'>
            <Flatpickr
              localName="ko"
              className="event-pickr"
              value={eventParams.eventDateList}
              onChange={handelDatePickerChange}
              onClose={handelDatePickerChange}
              options={{
                mode: "range",
                locale: "ko",
                enableTime: false,
                conjunction: "~",
                dateFormat: 'Y-m-d',
                closeOnSelect: false
              }}
            />
            <div onClick={onClickinitDatePicker} >
              <Icon 
                link  
                name="delete" 
                size="large" 
                color="red" 
                style={{ paddingTop: '9px' }} 
              />
            </div>
          </Form.Group>

          {/* Drop Zone */}
          썸네일 이미지
          <ImageUpload
            fileType="EVENT"
            setFile={onSetThumbnailFile}
            defaultImage={defaultImage}
            defaultImageUrlFull={false}
          />

          <Form.Input
            label='제목'
            className="form-input"
            name='title'
            placeholder='이벤트 제목을 입력해주세요.'
            content={eventParams.title}
            value={eventParams.title}
            onChange={handleChange}
          />
          {/* 에디터 */}
          {editor}

          <Form.Checkbox 
            label='팝업 노출' 
            className="form-checkbox"
            value={String(eventParams.popupYn)}
            checked={eventParams.popupYn}
            name='popupYn'
            onChange={handleChange}
          />
          {popupDiv}
        </Styled.FormDiv>
      </BasePopup>
    </>
  )
}
export default EventWrite;