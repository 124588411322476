import moment from 'moment';
import React, { useEffect, useState } from 'react'
import BannerList from './BannerList';
import { BannerItem, IBannerData, initBannerData, initBannerRequestParams, IRequestBannerParams } from './interface';
import SearchBox from './SearchBox';
import { BannerStyles as Styled } from './styles/Banner.styles'
import useBanner from 'hooks/useBanner';
import BannerWrite from './popup/BannerWrite';
import BannerDetail from './popup/BannerDetail';
import { Slide, Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

const viewItemCount = 10;

const Banner: React.FC = () => {
  const [ requestParams, setRequestParams ] = useState<IRequestBannerParams>({
    ...initBannerRequestParams,
    startDt: moment().startOf('month').format('YYYY-MM-DD'),
    endDt: moment().endOf('month').format('YYYY-MM-DD'),
    size: viewItemCount,
  });
  const [ bannerData, setBannerData ] = useState<IBannerData>(initBannerData);
  const [ bannerList, setBannerList ] = useState<BannerItem[]>(bannerData.list);
  const [ popupMode, setPopupMode ] = useState<'detail' | 'write' | ''>('');
  const [ selectBannerData, setSelectBannerData ] = useState<BannerItem | undefined>();
  const [ page, setPage ] = useState<number>(1);
  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ resultMsg, setResultMsg] = useState('');
  //const [ deleteBannerIds, setDeleteBannerIds ] = useState<number[]>([]);
  const [ isOnlyDefaultBanner, setIsOnlyDefaultBanner ] = useState<boolean>(false);
  const [ totalElements, setTotalElements ] = useState<number>(0);

  const { requestBannerList, requestDeleteBanner } = useBanner();

  const pages = Array.from({ 
    length: bannerData.page.totalPages === 0 ? 1 : bannerData.page.totalPages
  }, (value, index) => index + 1);

  useEffect(() => {
    setBannerList(bannerData.list);

    if (bannerData.list[0] && !bannerData.list[0].defaultYn) {
      setTotalElements(bannerData.page.totalElements);
    }
  }, [bannerData])

  useEffect(() => {
    if (popupMode === '') {
      requestBanner();
    }
  }, [popupMode])

  const requestBanner = (page?: number) => {
    const params = requestParams.activeYn === '' ?
      {
        serviceType: requestParams.serviceType,
        startDt: requestParams.startDt,
        endDt: requestParams.endDt,
        name: requestParams.name,
        page: page ? page : requestParams.page,
        size: requestParams.size,
        defaultYn: isOnlyDefaultBanner,
      } : {
        ...requestParams,
        defaultYn: isOnlyDefaultBanner,
        activeYn: typeof requestParams.activeYn ==='string' &&
          requestParams.activeYn === 'USE' ? true : false,
      }
    
    const payload: any = {
      queryParameters: params,
      callback: (succeeded: boolean, res: any) => {
        if(succeeded && res.response.code === 'OK'){
          setBannerData(res.response.data);
        }
      },
      async: true,
    }
    
    requestBannerList(payload);
  }


  const onChangeActive = (bannerId: number) => {
    const findData = bannerList.find((banner) => banner.bannerId === bannerId)

    const index = findData && bannerList.indexOf(findData);
    let ChangeData = [ ...bannerList ];

    if (findData && index !== undefined && index >= 0) {
      ChangeData[index] = {
        ...findData,
        activeYn: !findData.activeYn,
      };
  
      setBannerData({
        ...bannerData,
        list: ChangeData,
      })
    }
  }

  const onChangeRequestParams = (key: keyof IRequestBannerParams, value: IRequestBannerParams[keyof IRequestBannerParams]) => {
    setRequestParams({
      ...requestParams,
      [key]: value,
    })
  }

  const onSearch = () => {
    requestBanner();
  }

  const onChangePage = (number: number) => {
    onChangeRequestParams('page', number)
    setPage(number);
    requestBanner(number);
  }

  const onOpenPopup = (mode: 'detail' | 'write') => {
    setPopupMode(mode);
  }

  const onClosePopup = () => {
    setSelectBannerData(undefined);
    setPopupMode('');
  }

  const onSetSelectBannerData = (banner: BannerItem) => {
    setSelectBannerData(banner);
  }

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  const onChangeOnlyDefault = () => {
    setIsOnlyDefaultBanner(!isOnlyDefaultBanner);
  }

  const onOpenSnackBar = (msg: string) => {
    setResultMsg(msg);
    setSnackbarOpen(true);
  }

  const popup = popupMode === 'detail' && selectBannerData ?
  (
    <BannerDetail 
      banner={selectBannerData} 
      open={popupMode === 'detail'}
      onClose={onClosePopup}
      onOpenPopup={onOpenPopup}
    />
  ) : (
    <BannerWrite 
      banner={selectBannerData} 
      open={popupMode === 'write'} 
      onClose={onClosePopup} 
      showFeedback={onOpenSnackBar}
    />
  )

  return (
    <>
      <Styled.Wrap>
        <SearchBox 
          requestParams={requestParams} 
          onChangeParams={onChangeRequestParams} 
          isOnlyDefaultBanner={isOnlyDefaultBanner}
          onChangeOnlyDefault={onChangeOnlyDefault}
          onSearch={onSearch}
        />
        <Styled.Div>
          <Styled.Button onClick={onSearch}>검 색</Styled.Button>
        </Styled.Div>
        <Styled.Div align='right'>
          <Styled.Button onClick={() => onOpenPopup('write')}>등 록</Styled.Button>
        </Styled.Div>
        <BannerList
          requestParams={requestParams}
          bannerList={bannerList}
          onOpenPopup={onOpenPopup}
          onSetSelectBannerData={onSetSelectBannerData}
          onChangeActive={onChangeActive}
          onOpenSnackBar={onOpenSnackBar}
          totalElements={totalElements}
          requestBanner={requestBanner}
        />
        {/* {blankItem} */}
        <Styled.Div margin='0 0 60px 0'>
          <Styled.ArrowButton onClick={() => page > 1 && onChangePage(page - 1)}>{'<'}</Styled.ArrowButton>
            {pages.map((number) => (
              <Styled.PageButton key={number} select={page === number} onClick={() => onChangePage(number)}>{number}</Styled.PageButton>
            ))}
          <Styled.ArrowButton onClick={() => page !== pages[pages.length - 1] && onChangePage(page + 1)}>{'>'}</Styled.ArrowButton>
        </Styled.Div>
      </Styled.Wrap>
      {popup}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}
const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default Banner