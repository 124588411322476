import styled, { css } from 'styled-components'

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
`

const SelectedList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`

const SelectedItem = styled.li`
  min-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 30px;
  border: 1px solid #000;
  border-radius: 20px;
  cursor: pointer;
`

const SelectedTitle = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
`

const SelectedValue = styled.span`
  font-size: 0.7rem;
  font-weight: 500;
`

const FilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  & + & {
    margin-top: 40px;
  }
`

const FilterTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`

const FilterItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

export const StatusFilterPopupStyles = {
  Wrap,
  SelectedList,
  SelectedItem,
  SelectedTitle,
  SelectedValue,
  FilterWrap,
  FilterTitle,
  FilterItemList,
}