import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { SalesState } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';


// ACTION PART
const action = {
  // get sales info
  getSalesInfo: createAction(actionTypes.GET_SALES_INFO),
  fulfilledSalesInfo: createAction(actionTypes.FULFILLED_SALES_INFO),
  failedSalesInfo: createAction(actionTypes.FAILED_SALES_INFO),

}

const initialState: SalesState = {
  salesInfo: [],
};

const reducer = handleActions<SalesState>(
  {
    [actionTypes.GET_SALES_INFO]: (state, action): SalesState => {
      return state
    },
    [actionTypes.FULFILLED_SALES_INFO]: (state, action): SalesState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_SALES_INFO]: (state, action): SalesState => {
      return {
        ...state,
      }
    },
  },
  initialState
);

// EPIC PART
const requestSalesInfoEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_SALES_INFO,
      success: (res: AjaxResponse) => action.fulfilledSalesInfo({ salesInfo: res.response.data }),
      fail: actionTypes.FAILED_SALES_INFO
    },
    url: API_URL.SALES_INFO,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  requestSalesInfoEpic,
);

export { action, epic };
export default reducer;