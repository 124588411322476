import useReviewConfirm from 'hooks/useReviewConfirm'
import React, { useCallback, useEffect, useState } from 'react'
import { IReviewConfirmProduct } from 'store/modules/types';
import { StatusCheckStyles as Styled } from './styles/StatusCheck.style'
import List from './List';
import { Checkbox } from 'semantic-ui-react';
import StatusFilterPopup from './StatusFilterPopup';
import { IStatusCheckFilter } from './interface';

const StatusCheck: React.FC = () => {
  const { 
    reviewConfirm, 
    requestGetReviewConfirmStatusCheck, 
    recollectReviewConfirmProduct,
    recollectReviewConfirmCompare,
  } = useReviewConfirm();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedProductIdList, setSelectedProductIdList] = useState<string[]>([])
  const [selectedCompareProductIdList, setSelectedCompareProductIdList] = useState<string[]>([])
  const [productList, setProductList] = useState<IReviewConfirmProduct[]>(reviewConfirm.statusCheck.customerProducts)

  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [selectFilterData, setSelectFilterData] = useState<IStatusCheckFilter>({
    tableName: [],
    status: [],
    market: [],
  })

  useEffect(() => {
    setIsLoading(true)

    requestGetReviewConfirmStatusCheck({
      callback: (succeeded: boolean, res: any) => {
        setProductList(res.response.data.customerProducts)
        setIsLoading(false);
      }
    })
  }, [])

  const ids = reviewConfirm.statusCheck.customerProducts.reduce((acc, cur) => {
    if (cur.tableName === 'COMPARE') {
      acc.compareIds.push(cur.productId);
    }

    if (cur.tableName === 'PRODUCT') {
      acc.productIds.push(cur.productId);
    }

    return acc;
  }, {
    productIds: [],
    compareIds: [],
  } as {
    productIds: string[];
    compareIds: string[];
  })

  const handleRecollect = () => {
    if (selectedProductIdList.length > 0) handleRecollectProduct();
    if (selectedCompareProductIdList.length > 0) handleRecollectCompare();
  }

  const handleRecollectProduct = () => {
    recollectReviewConfirmProduct({
      queryParameters: {
        ids: selectedProductIdList,
      },
      callback: (succeeded: boolean, res: any) => {
        if (succeeded) {
          setIsLoading(true)

          requestGetReviewConfirmStatusCheck({
            callback: (succeeded: boolean, res: any) => {
              setProductList(res.response.data.customerProducts)  
              setIsLoading(false);
            }
          })
        }
      }
    })
  }

  const handleRecollectCompare = () => {
    recollectReviewConfirmCompare({
      queryParameters: {
        ids: selectedCompareProductIdList,
      },
      callback: (succeeded: boolean, res: any) => {
        if (succeeded) {
          setIsLoading(true)

          requestGetReviewConfirmStatusCheck({
            callback: (succeeded: boolean, res: any) => {
              setIsLoading(false);
            }
          })
        }
      }
    })
  }

  const handleAllProductSelect = () => {
    if (selectedProductIdList.concat(selectedCompareProductIdList).length === ids.productIds.concat(ids.compareIds).length) {
      setSelectedProductIdList([])
      setSelectedCompareProductIdList([])
    } else {
      setSelectedProductIdList(ids.productIds)
      setSelectedCompareProductIdList(ids.compareIds)
    }
  }

  const handleProduct = (product: IReviewConfirmProduct) => {
    if (product.tableName === 'PRODUCT') {
      if (selectedProductIdList.find((selectedProductId) => selectedProductId === product.productId)) {
        setSelectedProductIdList(selectedProductIdList.filter((selectedProductId) => 
          selectedProductId !== product.productId
        ))
      } else {
        setSelectedProductIdList([
          ...selectedProductIdList,
          product.productId,
        ])
      }
    } else {
      if (selectedCompareProductIdList.find((selectedProductId) => selectedProductId === product.productId)) {
        setSelectedCompareProductIdList(selectedCompareProductIdList.filter((selectedProductId) => 
          selectedProductId !== product.productId
        ))
      } else {
        setSelectedCompareProductIdList([
          ...selectedCompareProductIdList,
          product.productId,
        ])
      }
    }
  }

  const handleSortByInterval = (sort: 'desc' | 'asc') => {
    if (sort === 'asc') {
      setProductList([...reviewConfirm.statusCheck.customerProducts].sort((a, b) => 
        a.interval && b.interval && (a.interval > 0 || b.interval > 0) ? a.interval - b.interval : 1
      ))
    } 

    if (sort === 'desc') {
      setProductList([...reviewConfirm.statusCheck.customerProducts].sort((a, b) => 
        a.interval && b.interval && (a.interval > 0 || b.interval > 0) ? b.interval - a.interval : -1
      ))
    }
  }

  const handleOpenPopup = useCallback(() => {
    setOpenPopup(true)
  }, [])

  const handleClosePopup = useCallback(() => {
    setOpenPopup(false)
  }, [])

  const handleFilter = (filter: IStatusCheckFilter) => {
    if (filter.market.length === 0 
      && filter.status.length === 0 
      && filter.tableName.length === 0
    ) {
      setProductList(reviewConfirm.statusCheck.customerProducts)
    } else {
      setProductList([...reviewConfirm.statusCheck.customerProducts].filter((product) => {
        let isTableName = true
        let isStatus = true
        let isMarket = true

        if (filter.tableName.length > 0) {
          isTableName = !!filter.tableName.find((tableName) => product.tableName === tableName)
        }

        if (filter.status.length > 0) {
          isStatus = !!filter.status.find((status) => product.status === status)
        }

        if (filter.market.length > 0) {
          isMarket = !!filter.market.find((market) => product.marketName === market)
        }
        
        return isTableName && isStatus && isMarket
      }))
    }

    setSelectFilterData(filter)
  }

  return (
    <Styled.Wrap>
      <Styled.DataWrapList>
        <Styled.DataWrap>
          <Styled.DataWrapTitle>상품 등록 Status</Styled.DataWrapTitle>
          <Styled.CardList>
            <Styled.Card>
              <Styled.CardTitle>COLLECTING 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.statusCheck.productCollectingCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardTitle>COLLECTED 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.statusCheck.productCollectedCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardTitle>ANALYZED 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.statusCheck.productAnalyzedCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
          </Styled.CardList>
        </Styled.DataWrap>
        <Styled.DataWrap>
          <Styled.DataWrapTitle>리뷰 정보</Styled.DataWrapTitle>
          <Styled.CardList>
            <Styled.Card>
              <Styled.CardTitle>COLLECTING 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.statusCheck.compareCollectingCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardTitle>COLLECTED 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.statusCheck.compareCollectedCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardTitle>ANALYZED 상품</Styled.CardTitle>
              <Styled.CardValue>
                {reviewConfirm.statusCheck.compareAnalyzedCount.toLocaleString()}
              </Styled.CardValue>
            </Styled.Card>
          </Styled.CardList>
        </Styled.DataWrap>
      </Styled.DataWrapList>
      <Styled.ListTitleWrap>
        <Styled.ListTitle>
          {`이슈 상품 리스트 (${productList.length.toLocaleString()})`}
        </Styled.ListTitle>
        <Styled.Button onClick={handleRecollect}>재수집</Styled.Button>
        <Styled.FilterButton onClick={handleOpenPopup}>필터</Styled.FilterButton>
      </Styled.ListTitleWrap>
      <List 
        id={'daily-collect-product-list'}
        listWidthList='minmax(0, 5%) minmax(0, 6%) minmax(0, 6%) minmax(0, 6%) minmax(0, 6%) minmax(0, 6%) minmax(0, 48%) minmax(0, 6%) minmax(0, 9%) minmax(0, 7%)'
        titleList={[
          <Checkbox 
            name={'all'}
            value={'all'}
            label={''}
            checked={
              ids.productIds.concat(ids.compareIds).length !== 0 
              && selectedProductIdList.concat(selectedCompareProductIdList).length === ids.productIds.concat(ids.compareIds).length
            }
            onClick={handleAllProductSelect}
          />,
          '구분',
          'Status',
          'product_id',
          'market_id',
          '마켓',
          '상품명',
          '수집 실패 횟수',
          'Last 등록시간',
          <Styled.SortTitleWrap>
            시간 차감도
            <Styled.ArrowIconWrap>
              <Styled.ArrowIcon onClick={() => handleSortByInterval('asc')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                  <path d="M6 0L11.5 10H0.5L6 0Z" fill="#dddddd"/>
                </svg>
              </Styled.ArrowIcon>
              <Styled.ArrowIcon onClick={() => handleSortByInterval('desc')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 11 10" fill="none">
                  <path d="M5.5 10L11 -3.42727e-07H0L5.5 10Z" fill="#dddddd"/>
                </svg>
              </Styled.ArrowIcon>
            </Styled.ArrowIconWrap>
          </Styled.SortTitleWrap>
        ]}
        list={[...productList].map((product) => (
          <React.Fragment key={`uncollectedProducts-${product.productId}`}>
            <Styled.ListItemText>
              <Checkbox 
                name={`${product.productId}`}
                value={`${product.productId}`}
                label={''}
                checked={
                  product.tableName === 'PRODUCT' ?
                  !!selectedProductIdList.find((selecteProductId) => selecteProductId === product.productId)
                  : !!selectedCompareProductIdList.find((selecteProductId) => selecteProductId === product.productId)
                }
                onClick={() => handleProduct(product)}
              />
            </Styled.ListItemText>
            <Styled.ListItemText>{product.tableName === 'PRODUCT' ? '상품 등록' : '경쟁상품 등록'}</Styled.ListItemText>
            <Styled.ListItemText>{product.status}</Styled.ListItemText>
            <Styled.ListItemText>{product.productId}</Styled.ListItemText>
            <Styled.ListItemText>{product.marketId}</Styled.ListItemText>
            <Styled.ListItemText>{product.marketName}</Styled.ListItemText>
            <Styled.ListItemText 
              isClick 
              onClick={() => window.open(product.productUrl)}
            >
              {product.productName}
            </Styled.ListItemText>
            <Styled.ListItemText>{product.failCount ? product.failCount.toLocaleString() : 0}</Styled.ListItemText>
            <Styled.ListItemText>{product.lastRegDt}</Styled.ListItemText>
            <Styled.ListItemText>{product.interval ? product.interval.toLocaleString() : 0}</Styled.ListItemText>
            </React.Fragment>
        ))}
        isHoverEvent={false}
        isLoading={isLoading}
      />
      {openPopup && (
        <StatusFilterPopup 
          open={openPopup} 
          selectedData={selectFilterData}
          handleClose={handleClosePopup} 
          handleFilter={handleFilter}  
        />
      )}
    </Styled.Wrap>
  )
}

export default StatusCheck