import React from 'react';

import useUserLogs from 'hooks/useUserLogs';
import { useSearchParamsContext } from './interfaces';
import TableList, { IPagination, ITableHeader, ITableListProps } from 'components/common/nextTable/TableList';
import LogListItem from './LogListItem';

// Styled
import { logListStyles as Styled } from './styles/LogList.styles'

interface IProps {

}

const LogList: React.FC<IProps> = (props) => {
  const {} = props;

  const { searchParams, setSearchParams, onSearch } = useSearchParamsContext();

  const { userLoginLogs } = useUserLogs().userLogs;
  const { 
    totalPages = 1, 
    totalElements = 0,
    content: userLogs = [],
  } = userLoginLogs;
 

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page: number) => {
    onSearch({
      ...searchParams,
      pageNumber: page,
    });
  }

  const pagination : IPagination = {
    name: 'pageNumber',
    activePage: searchParams.pageNumber,
    totalPage: totalPages,
    totalCount: totalElements,
    onChange: onChangePage, 
  }


  const headers: ITableHeader[] = [
    { title: 'No', width: '5%' },
    { title: '구분', width: '10%' },
    { title: '상호/법인명', width: '15%' },
    { title: '아이디', width: '15%' },
    { title: '접속환경', width: '10%' },
    { title: '로그 시간', width: '15%' },
    { title: '로그 정보', width: '7%' },
    { title: '접속 IP', width: '10%' },
  ];

  const tableListProps: ITableListProps = {
    headers,
    pagination,
    count: totalElements,
    showHeaderTitle: false,
    refresh: () => {}
  }

  const userLogItems = userLogs.map((userLog, index) => 
    <LogListItem 
      key={'userLog'+index}
      index={totalElements - index - ((searchParams.pageNumber - 1) * 10)}
      userLog={userLog}
    />
  )

  return (
    <>
    <Styled.TableSection>
      <Styled.TableTitleSpan>로그 목록</Styled.TableTitleSpan>
      <TableList {...tableListProps}>
        {userLogItems}
      </TableList>
    </Styled.TableSection>
    </>
  )
}

export default LogList;
