import styled from 'styled-components'

interface IStyleProps {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  color?: string;
}

const Wrap = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;  
`

const PopupHeader = styled.h2`
  width: 100%;
  border-bottom: 1px solid rgba(34,36,38,.15);
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;
  padding: 20px 0px;
`

const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

`

const Div = styled.div<IStyleProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'column'};
  align-items: ${({ alignItems }) => alignItems ? alignItems : 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'center'};
  margin-bottom: 15px;

  & div {
    width: 100%;
  }
`

const Title = styled.h3`  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;

  & > div {
    margin-right: 20px;
  }

  & > div:nth-child(1) {
    margin-left: 60px;
  }
`

const HtmlView = styled.div<IStyleProps>`
  width: 100%;
  min-height: 200px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 5px;
  margin-top: 5px;
  color: ${({ color}) => color && color};
  padding: 10px 0;
`
const ValidMsg = styled.span`
  display: flex;
  align-items: center;
  justify-content: left;
  color: red;
`

export const SendEmailStyles = {
  Wrap,
  PopupHeader,
  Contents,
  Div,
  Title,
  HtmlView,
  ValidMsg,
}