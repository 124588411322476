import styled, { css } from 'styled-components'

interface IStyleProps {
  scrollY?: number;
  isSideView?: boolean;
  isActive?: boolean;
}

const DashBoard = styled.div`
  margin: auto;
  margin-bottom: 200px;
  max-width: 1430px;
  position: relative;
`

const Article = styled.article`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 35px;
`

const Title = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  margin: 50px 0 20px 0;
`

const PopupCalendarWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  & > div:not(:last-of-type) {
    margin-right: 10px;
  }
`

const PopupSubTitle = styled.h3`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 5px;
`

const PopupListWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const PopupListPageNation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const PopupListPageButton = styled.button<IStyleProps>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 400;
  border: 1px solid ${({ isActive }) => isActive ? '#000' : 'rgba(0,0,0,0.12)'};
  background-color: ${({ isActive }) => isActive ? 'rgba(0,0,0,0.16)' : '#fff'};
`

export const IndexStyles = {
  DashBoard,
  Title,
  Article,
  PopupCalendarWrap,
  PopupSubTitle,
  PopupListWrap,
  PopupListPageNation,
  PopupListPageButton,
}