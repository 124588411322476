import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { EventState, initPage } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

// ACTION PART
const action = {

  // list
  getEventList: createAction(actionTypes.GET_EVENT_LIST),
  fulfilledEventList: createAction(actionTypes.FULFILLED_EVENT_LIST),
  failedEventList: createAction(actionTypes.FAILED_EVENT_LIST),

  // detail
  getEventDetail: createAction(actionTypes.GET_EVENT_DETAIL),
  fulfilledEventDetail: createAction(actionTypes.FULFILLED_EVENT_DETAIL),
  failedEventDetail: createAction(actionTypes.FAILED_EVENT_DETAIL),

  // delete
  deleteEvent: createAction(actionTypes.DELETE_EVENT),
  succeededDeleteEvent: createAction(actionTypes.SUCCEEDED_DELETE_EVENT),
  failedDeleteEvent: createAction(actionTypes.FAILED_DELETE_EVENT),

  // insert
  writenevent: createAction(actionTypes.GET_WRITE_EVENT),
  writeneventSucceeded: createAction(actionTypes.FULFILLED_WRITE_EVENT),
  writeneventFailed: createAction(actionTypes.FAILED_WRITE_EVENT),

  // update
  modifyevent: createAction(actionTypes.GET_MODIFY_EVENT),
  modifyeventSucceeded: createAction(actionTypes.FULFILLED_MODIFY_EVENT),
  modifyeventFailed: createAction(actionTypes.FAILED_MODIFY_EVENT),
}

const initialState: EventState = {
  eventList: {
    list: [],
    page: initPage,
  },
};

const reducer = handleActions<EventState>(
  {
    [actionTypes.GET_EVENT_LIST]: (state, action): EventState => {
      return state
    },
    [actionTypes.FULFILLED_EVENT_LIST]: (state, action): EventState => {
      return {
        ...state,
        // userList: action.payload.userList        
        eventList: action.payload.eventList
      }
    },
    [actionTypes.FAILED_EVENT_LIST]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.DELETE_EVENT]: (state, action): EventState => {
      return state
    },
    [actionTypes.SUCCEEDED_DELETE_EVENT]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_DELETE_EVENT]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    // [actionTypes.GET_EVENT_LIST]: (state, action): EventState => {
    //   return {
    //     ...state,
    //   }
    // },
    [actionTypes.FAILED_WRITE_EVENT]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.FULFILLED_WRITE_EVENT]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.GET_WRITE_EVENT]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.GET_EVENT_DETAIL]: (state, action): EventState => {
      return {
        ...state,
        // userList: action.payload.userList     
      }
    },
    [actionTypes.FULFILLED_EVENT_DETAIL]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_EVENT_DETAIL]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.GET_MODIFY_EVENT]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.FULFILLED_MODIFY_EVENT]: (state, action): EventState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_MODIFY_EVENT]: (state, action): EventState => {
      return {
        ...state,
      }
    },
  },
  initialState
);

// EPIC PART 
// List
const requestEventListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_EVENT_LIST,
      success: (res: AjaxResponse) => action.fulfilledEventList({ eventList: res.response.data }),
      fail: actionTypes.FAILED_EVENT_LIST
    },
    url: API_URL.EVENT,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

//  Detail
const requestEventDetailEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_EVENT_DETAIL,
      success: (res: AjaxResponse) => action.fulfilledEventDetail(),
      fail: actionTypes.FAILED_EVENT_DETAIL
    },
    url: API_URL.EVENT,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

// Insert
const requestEventWriteEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_WRITE_EVENT,
      success: (res: AjaxResponse) => action.getEventList({ eventList: res.response.data }),
      fail: actionTypes.FAILED_WRITE_EVENT
    },
    url: API_URL.EVENT,
    method: AjaxMethods.POST,
  };
  return epicService.request(actions$, reqParams);
}

// Update
const requestEventMdifyEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_MODIFY_EVENT,
      success: (res: AjaxResponse) => action.getEventList({ eventList: res.response.data }),
      fail: actionTypes.FULFILLED_MODIFY_EVENT
    },
    url: API_URL.EVENT,
    method: AjaxMethods.PATCH,
  };
  return epicService.request(actions$, reqParams);
}

// Delete
const deleteEventEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.DELETE_EVENT,
      success: (res: AjaxResponse) => action.getEventList({ eventList: res.response.data }),
      fail: actionTypes.FAILED_DELETE_EVENT
    },
    url: API_URL.EVENT,
    method: AjaxMethods.DELETE
  };
  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  requestEventListEpic,
  deleteEventEpic,
  requestEventWriteEpic,
  requestEventDetailEpic,
  requestEventMdifyEpic,
);

export { action, epic };
export default reducer;