import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/banner';

const useBanner = () => {

  const dispatch = useDispatch();

  const requestBannerList = (data: any) => {
    dispatch(action.requestBannerList(data));
  }

  const requestCreateBanner = (data: any) => {
    dispatch(action.requestCreateBanner(data));
  }

  const requestUpdateBanner = (data: any) => {
    dispatch(action.requestUpdateBanner(data));
  }

  const requestDeleteBanner = (data: any) => {
    dispatch(action.requestDeleteBanner(data));
  }

  const requestBannerActiveToggle = (data: any) => {
    dispatch(action.requestBannerActiveToggle(data));
  }
  
  return {
    requestBannerList,
    requestCreateBanner,
    requestUpdateBanner,
    requestDeleteBanner,
    requestBannerActiveToggle,
  }
}
 
export default useBanner;