import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import { NaverAttributeState } from './types';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
    requestNaverAttribute: createAction(actionTypes.GET_DATA_CONFIRM_NAVER_ATTRIBUTE),
    requestNaverAttributeSuccess: createAction(actionTypes.SUCCEEDED_GET_DATA_CONFIRM_NAVER_ATTRIBUTE),
    requestNaverAttributeFailed: createAction(actionTypes.FAILED_GET_DATA_CONFIRM_NAVER_ATTRIBUTE)
}

const initialState: NaverAttributeState = {
    naverAttributeData: {
        date: '',
        totalCategories: 0,
        naverAttributeCategories: 0,
    }
}

const reducer = handleActions<NaverAttributeState>(
    {
      [actionTypes.SUCCEEDED_GET_DATA_CONFIRM_NAVER_ATTRIBUTE]: (state, action): NaverAttributeState => {
        return {
          ...state,
          naverAttributeData: action.payload.naverAttributeData
        }
      },
    },
    initialState
);

const requestNaverAttributeEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_DATA_CONFIRM_NAVER_ATTRIBUTE,
        success: (res: AjaxResponse) => action.requestNaverAttributeSuccess({ naverAttributeData: res.response.data }),
        fail: actionTypes.FAILED_GET_DATA_CONFIRM_NAVER_ATTRIBUTE
      },
      url: API_URL.NAVER_ATTRIBUTE,
      method: AjaxMethods.GET
    }
    return epicService.request(actions$, reqParams);
    ;
}

const epic = combineEpics(
    requestNaverAttributeEpic
)

export{action, epic};
export default reducer;