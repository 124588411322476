import moment from 'moment';
import React from 'react'
import { Line } from 'react-chartjs-2';
import { IDashboardUserStatus } from '../interface';

interface IProps {
  searchDate: string;
  userStatus: IDashboardUserStatus;
  getElementAtEvent: (element: any) => void;
}

const VisitorChart: React.FC<IProps> = (props) => {
  const { searchDate, userStatus, getElementAtEvent } = props;

  const getVisitorChartData = () => {
    const clientData = Array.from({ length: moment(searchDate).endOf('month').get('date') }, (v) => {
      return {
        WEB: 0,
        MOBILE: 0,
      }
    });
    const dailyData = Array.from({ length: moment(searchDate).endOf('month').get('date') }, (v) => 0);

    userStatus.userLogin.mobileChart.forEach((data) => {
      const count = dailyData[moment(data.date).get('date') - 1];
      dailyData[moment(data.date).get('date') - 1] = count && count > 0? data.count + count : data.count

      clientData[moment(data.date).get('date') - 1].MOBILE += data.count
    });

    userStatus.userLogin.webChart.forEach((data) => {
      const count = dailyData[moment(data.date).get('date') - 1];
      dailyData[moment(data.date).get('date') - 1] = count && count > 0? data.count + count : data.count
      clientData[moment(data.date).get('date') - 1].WEB += data.count
    });

    // if (userStatus.visitorStatus.daily.length > 0) {
    //   userStatus.visitorStatus.daily.forEach((data) => {
    //     const webData = data.details.find((detailData) => 
    //       detailData.clientType === 'WEB'
    //     )
    //     const mobileData = data.details.find((detailData) => 
    //       detailData.clientType === 'MOBILE'
    //     )

    //     if (webData) {
    //       clientData[moment(data.date).get('date') - 1].WEB += webData.count
    //       dailyData[moment(data.date).get('date') - 1] += webData.count
    //     }
    //     if (mobileData) {
    //       clientData[moment(data.date).get('date') - 1].MOBILE += mobileData.count
    //       dailyData[moment(data.date).get('date') - 1] += mobileData.count
    //     }
    //   })      
    // }

    return {
      clientData,
      dailyData,
    }
  }

  const dailyData = getVisitorChartData();
  const dayArray = dailyData.dailyData.length > 0 ? dailyData.dailyData.map((a, index) => (index + 1) + '일') : []
  const maxValue = dailyData.dailyData.length > 0 ? dailyData.dailyData.reduce((a, b) => Math.max(a, b)): 0

  const chartData = {
    labels: dayArray,
    datasets: [
      {
        label: "PC",
        data: dailyData.clientData.map((data) => data.WEB),
        fill: false,
        borderColor: "#80b6f4"
      },
      {
        label: "MOBILE",
        data: dailyData.clientData.map((data) => data.MOBILE),
        fill: false,
        borderColor: "#f48080"
      },
    ]
  };

  const legend = {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#323130",
    }
  };

  const options = {
    grouped: true,
    interaction: {
      mode: 'index',
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: maxValue + 3,
            stepSize: 1,
            maxTicksLimit: 10,
          }
        }
      ]
    },
    onHover: (event: any, chartElement: any) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }
  };

  return (
    <Line 
      data={chartData} 
      legend={legend}
      options={options} 
      height={50}
      getElementAtEvent={getElementAtEvent}
    />
  )
}

export default VisitorChart