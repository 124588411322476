import styled from 'styled-components';

const TableSection = styled.section`
  &&& {
    width: 80%;
    max-width: 1280px;
    margin-top: 30px;
    .MuiContainer-root {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
`;

const TableTitleSpan = styled.span`
  &&& {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const logListStyles = {
  TableSection,
  TableTitleSpan,  
}