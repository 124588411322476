export interface IRequestNoticeParams {
  page: number;
  size: number;
  title: string;
  serviceTypes:string;
}

export const initIRequestNoticeParams : IRequestNoticeParams = {
  page: 1,
  size: 10,
  title: '',
  serviceTypes:'SELLERSPOT,IRANK,REVIEWRAY'
}

export interface INoticeInsertParams {
  contents: string;
  importantYn: boolean;
  title: string;
  type: string;
  viewYn: boolean;
  topYn: boolean;
  loginYn: boolean;
  popups: IPopupListItem[];  
  popupYn?: boolean,
  serviceType: 'SELLERSPOT' | 'IRANK' | 'REVIEWRAY'
}

export interface INoticeDetailData {
  noticeNo: number;
  title: string;
  regDt: string;
  contents: string;
  importantYn: boolean;
  preTitle: string;
  preNoticeNo: number;
  nextTitle: string;
  nextNoticeNo: number;
  updateDt: string;
  updateId: string;
  regId: string;
  type: string;
  viewCount: number;
  viewYn: boolean;
  topYn: boolean;
  loginYn: boolean;
  popupYn: boolean;
  popups: IPopupListItem[];
}

export interface IPopupListItem {
  detail: string;
  endDt: string;
  heightValue: number;
  leftValue: number;
  location: string;
  loginYn: boolean;
  popupImageName: string;
  popupImagePath: string;
  popupLinkRanges: IPopupLinkRanges[];
  popupNo?: number | null;
  startDt: string;
  topValue: number;
  type: string;
  typeNo: number;
  viewYn: boolean;
  widthValue: number;
  popupDateList: any[];
}

export const initPopupListItem: IPopupListItem = {
  detail: '',
  endDt: '',
  popupDateList: [],
  popupImageName: '',
  popupImagePath: '',
  popupNo: null,
  startDt: '',
  type: '',
  typeNo: 0,
  viewYn: true,
  widthValue: 600,
  heightValue: 600,
  topValue: 0,
  leftValue: 0,
  loginYn: false,
  location: 'ALL',
  popupLinkRanges: [],
}

export interface IPopupLinkRanges {
  heightValue: number;
  leftValue: number;
  popupLinkRangeId: number | null;
  topValue: number;
  url: string;
  widthValue: number;
}

export const initPopupLinkRanges: IPopupLinkRanges = {
  widthValue: 600,
  heightValue: 600,
  topValue: 0,
  leftValue: 0,
  url: '',
  popupLinkRangeId: null,
}

export const initINoticeInsertParams : INoticeInsertParams = {
  serviceType:'SELLERSPOT',
  contents: "",
  importantYn: false,
  title: "",
  type: "",
  viewYn: true,
  topYn: false,
  loginYn: false,
  popups: [{
    ...initPopupListItem
  }],
}

export const initINoticeDetailData : INoticeDetailData = {
  noticeNo: 0,
  title: '',
  regDt: '',
  contents: '',
  importantYn: false,
  preTitle: '',
  preNoticeNo: 0,
  nextTitle: '',
  nextNoticeNo: 0,
  updateDt: '',
  updateId: '',
  regId: '',
  type: '',
  viewCount: 0,
  viewYn: false,
  topYn: false,
  loginYn: false,
  popupYn: false,
  popups: [{
    ...initPopupListItem
  }],
}

export interface IPopupParams {
  noticeNo: number;
  open: boolean;
  mode: string;
}

export const initIPopupParams : IPopupParams = {
  noticeNo: 0,
  open: false,
  mode: ''
}



export const linkAreaColor: string[] = [
  '#ff0000', // 빨간색
  '#ff7f00', // 주황색
  '#ffff00', // 노랑색
  '#008000', // 초록색
  '#0000ff', // 파란색
];

export const locationButtonTextList: {value: string, text: string}[] = [
  { value: 'IRANK', text: 'IRANK' },
  { value: 'REVIEWRAY', text: 'REVIEWRAY' },
  { value: 'ALL', text: 'ALL' },
];

// Date to String( YYYY-MM-DD )
export const getDateFormatter = (date: Date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth());
  const monthStr = month >= 10 ? month : '0' + month;
  let day = date.getDate();
  const dayStr = day >= 10 ? day : '0' + day;
  return year + '-' + monthStr + '-' + dayStr;
};

// String( YYYY-MM-DD ) to Date
export const getStringDateConvert = (dateStr: string) => {
  let result = null;
  if (dateStr.length > 0 ) {
    const yyyyMMdd = String(dateStr);
    const sYear = yyyyMMdd.substring(0,4);
    const sMonth = yyyyMMdd.substring(5,7);
    const sDate = yyyyMMdd.substring(8,10);
    result = new Date(Number(sYear), Number(sMonth)-1, Number(sDate));
  }
  return result;
}