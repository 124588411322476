import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/faq';

const useFaq = () => {

  const dispatch = useDispatch();

  const faq = useSelector((state: StoreState) => state.faq);

  const requestFaqList = (data: any) => {
    dispatch(action.requestFaqList(data));
  }

  const requestGetFaq = (data: any) => {
    dispatch(action.requestGetFaq(data));
  }

  const requestFaqCreate = (data: any) => {
    dispatch(action.requestFaqCreate(data));
  }
  
  const requestFaqModify = (data: any) => {
    dispatch(action.requestFaqModify(data));
  }
  
  const requestFaqDelete = (data: any) => {
    dispatch(action.requestFaqDelete(data));
  }
  
  return {
    faq,
    requestFaqList,
    requestGetFaq,
    requestFaqCreate,
    requestFaqModify,
    requestFaqDelete,
  }
}
 
export default useFaq;