import React, { SyntheticEvent } from 'react';

import { Icon, IconProps, Popup } from 'semantic-ui-react';

// material-ui
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { TableRow, TableCell, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  hover: {
    '&$hover:hover': {
      backgroundColor: 'rgba(0, 0, 0, .04)'
    },
  }
});

export interface ITableListItemIconButton {
  name?: string;
  color?: IconProps['color'];
  iconName: IconProps['name'];
  tooltip?: string;
  showTooltip?: boolean | undefined;
  onItemClicked(e: SyntheticEvent, item: any): void;
}

export interface ITableListItemProps {
  id: number;
  titleNumber?: number;
  items: ITableListItemText[];
  iconButtons?: ITableListItemIconButton[];
  rowKey?: string;
  onRowSelectedEvent: Function;
}

export interface ITableListItemText {
  value: string;
  textAlign?: string;
  clickable?: boolean;
}

const TableListItem: React.FC<ITableListItemProps> = (props) => {

  const classes = useStyles();
  const { id, items, iconButtons, rowKey, onRowSelectedEvent } = props;

  const itemList = items.map((item: any, index) => {

    const cellStyle = { fontSize: '0.9rem', textAlign: item.textAlign || 'center' }
    const typoStyle = { width: item.textAlign === 'left' ? 'fit-content' : 'auto', cursor: item.clickable ? 'pointer' : 'default' };
    const onItemClick = (e: any) => { 
      if (item.clickable && onRowSelectedEvent) {
        onRowSelectedEvent()
      }
    }

    return (
      <TableCell 
        key={index} scope="row"
        style={cellStyle}
      >
        <Typography style={typoStyle} onClick={onItemClick}>
          {item.value}
        </Typography>
      </TableCell>
    )
  });

  const iconButtonList = iconButtons ?
    iconButtons.map((iconButton: ITableListItemIconButton, index) => (
      <Popup
        key={index}
        trigger={
          <Icon
            style={{ padding: '0 15px', margin: 0 }}
            link
            color={iconButton.color}
            name={iconButton.iconName}
            onClick={iconButton.onItemClicked}
            data={id}
          />
        }        
        open={iconButton.showTooltip}
        content={iconButton.tooltip}
        inverted
        style={{ fontSize: '0.75rem', padding: '4px 8px' }}
      />
    )) : null;

  return (
    <TableRow hover className={`${classes.hover}`}>
      {itemList}
    </TableRow>
  )
}

export default TableListItem;