import React from "react"

import styled from "styled-components"

const Wrap = styled.div`
  width: 95%;
  padding: 20px 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 5px;
  border: 1px solid #D8D8D8;
  //box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  border-radius: 5px;
`

const ItemWrap = styled.div`
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ItemText = styled.span`
  font-size: 12px;
  font-weight: 500;
`

const ItemValue = styled.span`
  font-size: 13px;
  font-weight: 600;

  small {
    font-size: 12px;
    font-weight: 400;
  }
`

interface IProps {
  items: {
    text: string
    value: string
  }[]
}


const ReviewrayMembershipInfoCard: React.FC<IProps> = (props) => {
  const { items } = props
  return <Wrap>
    {items.map(item => <ItemWrap>
      <ItemText>{item.text}</ItemText>
      <ItemValue>
        {item.value}
      </ItemValue>
    </ItemWrap>)}
  </Wrap>
} 

export default ReviewrayMembershipInfoCard