import TableList, { ITableListButton, ITableListProps } from 'components/common/table/TableList';
import TableListItem from 'components/common/table/TableListItem';
import useEmail from 'hooks/useEmail';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import DetailSendLogs from './popup/DetailSendLogs';
import SendEmail from './popup/SendEmail';

interface IProps {
  showFeedback: (msg: string) => void;
}

const EmailSendingHistoryList: React.FC<IProps> = (props) => {
  const { showFeedback } = props;
  const [ openSendEmailPopup, setOpenSendEmailPopup ] = useState<boolean>(false);
  const [ openDetailLogPopup, setOpenDetailLogPopup ] = useState<boolean>(false);
  const [ selectSendId, setSelectSendId ] = useState<number>();
  const { email, requestGetUserSendLogs } = useEmail();

  useEffect(() => {
    requestGetUserSendLogs();
  }, [])

  const openEmailSendPopup = () => {
    setOpenSendEmailPopup(true);
  }

  const closeEmailSendPopup = () => {
    setOpenSendEmailPopup(false);
  }

  const closeSendLogPopup = () => {
    setOpenDetailLogPopup(false);
  }

  const selectSendLogItem = (sendId: number) => {
    setSelectSendId(sendId);
    setOpenDetailLogPopup(true);
  }

  const headers = ['전송ID', '전송 매체', '템플릿', '제목', '전송 일자'];
  const headerWidth = [0.2, 1, 1, 2, 1];
  const buttons: ITableListButton[] = [
    {
      name: '이메일 전송',
      icon: 'add',
      onButtonClicked: openEmailSendPopup,
    },
  ]
  const tableListProps: ITableListProps = {
    title: 'E-mail 전송 내역',
    viewTitle: true,
    headers,
    headerWidth,
    color: 'violet',
    count: email.logList.length,
    buttons,
    buttonPosition: 'header',
    // refresh: () => {}
  }

  return (
    <>
      <TableList {...tableListProps}>
        {email.logList.map((item, index) => (
          <TableListItem 
            key={`email-history-item-${index}`}
            id={index}
            rowKey={`send-log-${index}`}
            firstItemAlign={'center'}
            items={[item.sendId, item.sendType, item.templateId, item.title, moment(item.regDt).format('YYYY-MM-DD')]}
            onRowSelectedEvent={() => selectSendLogItem(item.sendId)}
          />
        ))}
      </TableList>
      <DetailSendLogs open={openDetailLogPopup} closePopup={closeSendLogPopup} sendId={selectSendId} />
      <SendEmail open={openSendEmailPopup} closePopup={closeEmailSendPopup} showFeedback={showFeedback} />
    </>
  )
}

export default EmailSendingHistoryList