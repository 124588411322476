export interface IUserSalesParams {
  startDate: string;
  endDate: string;
  marketList: string[];
}

export const initUserSalesParams = {
  startDate: '',
  endDate: '',
  marketList: [],
}

export interface IUserSalesItem {
  userId: string;
  customerName: string;
  customerId: string;
  yearMonth: string;
  totalSales: number;
  marketDetail: {
    marketId: string;
    amount: number;
  }[]
}