import React, { useCallback, useEffect, useState } from 'react';

// Hooks
import useBasics from 'hooks/useBasics';

// Components
import TableList, { ITableListProps, ITableListButton, IFindTextBox, IPagination } from 'components/common/table/TableList';
import UserListItem from './UserListItem';
import UserInfoSummary from './UserInfoSummary';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import UserSearch from './UserSearch';
import UserPasswordReset from './popup/UserPasswordReset';

// Styled
import { UserListStyled as Styled } from './styles/UserList.styles';

// Interface
import { TransitionProps } from '@material-ui/core/transitions';
import {
  IPopupMode,
  ISearchUserParams,
  initIPopupMode,
  initISearchUserParams,
  useUserContext,
} from './interface';
import UserDelete from './popup/UserDelete';
import { IPage } from 'store/modules/types';
import UserMembership from './UserMembership';
import ReivewrayProducts from './popup/ReviewrayProducts';
import { SORT_TYPE } from 'components/common/button/Sort';

interface IProps {
  data: {
    list: any[]
    page: IPage
  };
  refresh: (params?: ISearchUserParams, pageNumber?: number, reset?: boolean) => void;
  setParams: React.Dispatch<React.SetStateAction<ISearchUserParams>>;
  isLoading: boolean;
}

const UserList: React.FC<IProps> = (props) => {
  const { data, refresh, setParams, isLoading } = props;
  const { basics } = useBasics();
  const { user } = basics;
  const { authorities } = user;

  const { selectedUser, searchParams } = useUserContext();

  const [ popupMode, setPopupMode ] = useState<IPopupMode>(initIPopupMode);
  const [ snackbarOpen, setSnackbarOpen ] = useState(false);
  const [ resultMsg, setResultMsg ] = useState('');
  const [ openReviewrayProducts, setOpenReviewrayProducts ] = useState<boolean>(false)
  const [ regDtSort, setRegDtSort ] = useState<SORT_TYPE | ''>('')
  const [ loginDtSort, setLoginDtSort ] = useState<SORT_TYPE | ''>('')

  const userAuth = authorities[0] != undefined ? authorities[0].authority : '';

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  const onClose = () => {
    setPopupMode({
      ...popupMode,
      open: false
    })
  };

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, getData: any) => {
    refresh(undefined, getData.activePage);
  };

  const onAddButtonClicked = (type: string) => {
    if ((type === 'lock_open' || type === 'edit_user') && !selectedUser) {
      setSnackbarOpen(true);
      setResultMsg('사용자를 선택해주세요.');
      return false;
    }
    setPopupMode({
      ...popupMode,
      open: true,
      mode: type,
    })
  };

  const pagination : IPagination = {
    name: 'page',
    activePage: searchParams.page,
    totalPage: data.page.totalPages,
    onChange: onChangePage, 
  };

  const sortHandler = (
    type: SORT_TYPE, 
    sortKey:'REG_DT' | 'LOGIN_REG_DT', 
    sortType: SORT_TYPE | '',
    setSortType: React.Dispatch<React.SetStateAction<'' | SORT_TYPE>>
  ) => {

    const userSort = (sortType === '' || type !== sortType)
      ? `${sortKey}_${type}` 
      : ''
    
    if (userSort) {
      setSortType(type)
    } else {
      setSortType('')
    }

    const params = {
      ...searchParams,
      userSort: userSort
    } as ISearchUserParams

    setParams({
      ...params
    })

    refresh(params)
  }

  const headers = ['사용자ID', '고객번호', '핸드폰번호', '가입일', '그룹', '마지막 로그인 일시', '제휴']
  const headerSort = [
    { 
      header:'가입일', 
      key:'REG_DT', 
      state: regDtSort, 
      handler: (type: SORT_TYPE) => {
        sortHandler(type, 'REG_DT', regDtSort, setRegDtSort)
        setLoginDtSort('') // 다른 필터 값 초기화
      }
    }, 
    {
      header:'마지막 로그인 일시', 
      key:'LOGIN_REG_DT',
      state: loginDtSort, 
      handler: (type: SORT_TYPE) => {
        sortHandler(type, 'LOGIN_REG_DT', loginDtSort, setLoginDtSort)
        setRegDtSort('') // 다른 필터 값 초기화
      }
    }
  ]

  const buttons: ITableListButton[] = [
    {
      name: '사용자 추가',
      icon: 'add',
      onButtonClicked: () => onAddButtonClicked('add_user')
    }
  ]
  
  // finder,
  const tableListProps: ITableListProps = {
    width: '100%',
    title: ' ',
    size: 'small',
    color: 'violet',
    //buttons: userAuth === 'ROLE_ADMIN' ? buttons : undefined,
    buttons: undefined,
    headers,
    headerSort,
    pagination,
    count: data.page.totalElements,
    refresh,
    refreshNotFlag: true,
    loader: true,
    isLoading: isLoading,
    tBodyPosition: 'relative',
  };

  const UserItems = useCallback(() => {
    return <>{data.list.map((contentUser: any, idx:number) => {    
      return <UserListItem
        key={contentUser.uid + idx}
        user={contentUser}
        showFeedback={(msg) => {
          setResultMsg(msg);
          setSnackbarOpen(true);
        }}
        userAuth={userAuth}
      />
    })}</>
  }, [data.list])

  const userRefresh = () => {
    refresh(searchParams, searchParams.page)
  }

  return (
    <>
      <Styled.Container>
        
        <Styled.TableContainer>
          {/* 검색  */}
          <UserSearch refresh={refresh} />
          <Styled.TableListDiv>
            <TableList {...tableListProps}>
              <><UserItems /></>
            </TableList>
          </Styled.TableListDiv>
        </Styled.TableContainer>

        <Styled.UserInfoMarketDiv>
          <UserInfoSummary 
            userAuth={userAuth}
            onResetPassword={() => onAddButtonClicked('lock_open')}
            onUpdateUser={() => onAddButtonClicked('edit_user')}
            onDeleteUser={() => onAddButtonClicked('delete_user')}
            refresh={userRefresh}
          />

          <UserMembership onOpen={() => {setOpenReviewrayProducts(true)}}/>
        </Styled.UserInfoMarketDiv>        

        {/* <Styled.UserSalesSummaryDiv>
          <UserSaleSummary />
        </Styled.UserSalesSummaryDiv> */}

      </Styled.Container>

      {/* {
        popupMode.open && (popupMode.mode === 'add_user' || (selectedUser && popupMode.mode === 'edit_user')) ? (
          <UserJoin
            user={selectedUser}
            mode={popupMode.mode}
            open={true}
            onClose={onClose}
            refresh={refresh}
            showFeedback= {(msg:string) => {
              setResultMsg(msg);
              setSnackbarOpen(true);
            }}
          />
        ) : null
      } */}
      { openReviewrayProducts && <ReivewrayProducts open={openReviewrayProducts} onClose={() => {setOpenReviewrayProducts(false)}} />}
      {
        selectedUser && popupMode.open && (popupMode.mode === 'delete_user') ? (
          <UserDelete
            user={selectedUser}
            open={true}
            onClose={onClose}
            refresh={refresh}
            showFeedback= {(msg:string) => {
              setResultMsg(msg);
              setSnackbarOpen(true);
            }}
          />
        ) : null
      }
      {
        selectedUser && popupMode.open && popupMode.mode === 'lock_open' ? (
          <UserPasswordReset
            user={selectedUser}
            open={true}
            onClose={onClose}
            showFeedback= {(msg:string) => {
              setResultMsg(msg);
              setSnackbarOpen(true);
            }}
          />
        ) : null
      }

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}

const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default UserList;