import styled from 'styled-components'

const RadioList = styled.ul`
  display: flex;
  align-items: center;
`

const Radio = styled.li`
  display: flex;
  align-items: center;

  & + & {
    margin-left: 3px;
  }
`

const RadioButton = styled.input.attrs({ type: 'radio' })`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 0 5px;
`

export const RadioGroupStyles = {
  RadioList,
  Radio,
  RadioButton,
  Label,
}