module.exports = {
	// 실행 모드
	mode : "development",
	
	// Port
	port : 3000,
	
	// 셀러공간 API 서버 HOST
	API_URL : "https://dev.admin.sellerspot.co.kr",
	
	// 서비스 페이지 HOST
	WEB_URL : "http://dev.sellerspot.co.kr",

	// 개발서버 HOST
	DEV_URL : "https://dev.admin.sellerspot.co.kr"
}