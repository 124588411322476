const config = require('../../config');
export interface IMenuParams {
  key?: string;
  name: string;
  color: string;
  icon?: any;
  comp: any;
  sub?: IMenuParams[];
}

export const MENUS: IMenuParams[] = [
  { name: 'dashboard', color: 'red', icon: 'dashboard', comp: '', },
  { name: 'users', color: 'red', icon: 'users', comp: '' },
  { name: 'user sales', color: 'red', icon: 'analytics', comp: '' },
  { name: 'user logs', color: 'red', icon: 'analytics', comp: '' },
  { name: 'notice', color: 'red', icon: 'dashboard', comp: '', },
  // { name: 'event', color: 'red', icon: 'dashboard', comp: '', },
  { name: 'faq', color: 'red', icon: 'question', comp: '' },
  { name: 'qna', color: 'red', icon: 'question', comp: '' },
  { name: 'terms of service', color: 'red', icon: 'handshake', comp: '' },
  { name: 'payment', color: 'red', icon: 'payment', comp: '' },
  { name: 'guide', color: 'red', icon: 'question', comp: '' },
  { name: 'event', color: 'red', icon: 'dashboard', comp: '', }, 
  { name: 'salon on', color: 'red', icon: 'newspaper outline', comp: '', }, 
  { name: 'market', color: 'red', icon: 'shopping basket', comp: '', }, 
  { name: 'email', color: 'red', icon: 'mail', comp: '', },
  { name: 'email template', color: 'red', icon: 'mail', comp: '', },
  { name: 'banner', color: 'red', icon: 'comment', comp: '', },
];

export const NEXT_MENUS: IMenuParams[] = config.mode === "production" ?
[
  { key: 'dashboard', name: '대시보드', color: 'red', icon: 'dashboard', comp: '', },
  { key: 'users', name: '사용자 관리', color: 'red', icon: 'groups', comp: '' },
  // { key: 'user sales', name: '회원 매출', color: 'red', icon: 'analytics', comp: '' },
  // { key: 'user logs', name: '회원 로그', color: 'red', icon: 'text_snippet', comp: '' },
  // { key: 'terms of service', name: '약관 관리', color: 'red', icon: 'rule', comp: '' },
  { key: 'demo', name: '리뷰레이 데모요청 관리', color: 'red', icon: 'rule', comp: '' },
  { key: 'market', name: '마켓 관리', color: 'red', icon: 'storefront', comp: '', }, 
  { key: 'notice', name: '공지사항 관리', color: 'red', icon: 'campaign', comp: '', },
  { key: 'faq', name: 'FAQ 관리', color: 'red', icon: 'help_center', comp: '' },
  { key: 'qna', name: '1:1문의 관리', color: 'red', icon: 'question_answer', comp: '' },
  { key: 'guide', name: '이용자 가이드 관리', color: 'red', icon: 'alt_route', comp: '' },
  { key: 'event', name: '이벤트 관리', color: 'red', icon: 'event_note', comp: '', }, 
  { key: 'salon on', name: '공간온 관리', color: 'red', icon: 'apps', comp: '', }, 
  // { key: 'payment', name: '결제 관리', color: 'red', icon: 'payment', comp: '' },
  { key: 'email', name: 'E-mail', color: 'red', icon: 'mail', comp: '', },
  { key: 'email template', name: 'E-mail 템플릿 관리', color: 'red', icon: 'mail', comp: '', },
  { key: 'banner', name: '배너 관리', color: 'red', icon: 'comment', comp: '', },
  { key: 'validation-irank', name: '데이터 검증(아이랭크)', color: 'red', icon: 'analytics', comp: '', },
  { key: 'validation-review', name: '데이터 검증(리뷰레이)', color: 'red', icon: 'analytics', comp: '', },
]
: 
[
  { key: 'dashboard', name: '대시보드', color: 'red', icon: 'dashboard', comp: '', },
  { key: 'users', name: '사용자 관리', color: 'red', icon: 'groups', comp: '' },
  // { key: 'user sales', name: '회원 매출', color: 'red', icon: 'analytics', comp: '' },
  // { key: 'user logs', name: '회원 로그', color: 'red', icon: 'text_snippet', comp: '' },
  // { key: 'terms of service', name: '약관 관리', color: 'red', icon: 'rule', comp: '' },
  { key: 'demo', name: '리뷰레이 데모요청 관리', color: 'red', icon: 'rule', comp: '' },
  { key: 'market', name: '마켓 관리', color: 'red', icon: 'storefront', comp: '', }, 
  { key: 'notice', name: '공지사항 관리', color: 'red', icon: 'campaign', comp: '', },
  { key: 'faq', name: 'FAQ 관리', color: 'red', icon: 'help_center', comp: '' },
  { key: 'qna', name: '1:1문의 관리', color: 'red', icon: 'question_answer', comp: '' },
  { key: 'guide', name: '이용자 가이드 관리', color: 'red', icon: 'alt_route', comp: '' },
  { key: 'event', name: '이벤트 관리', color: 'red', icon: 'event_note', comp: '', }, 
  { key: 'salon on', name: '공간온 관리', color: 'red', icon: 'apps', comp: '', }, 
  // { key: 'payment', name: '결제 관리', color: 'red', icon: 'payment', comp: '' },
  { key: 'email', name: 'E-mail', color: 'red', icon: 'mail', comp: '', },
  { key: 'email template', name: 'E-mail 템플릿 관리', color: 'red', icon: 'mail', comp: '', },
  { key: 'banner', name: '배너 관리', color: 'red', icon: 'comment', comp: '', },
  { key: 'validation-irank', name: '데이터 검증(아이랭크)', color: 'red', icon: 'analytics', comp: '', },
  { key: 'validation-review', name: '데이터 검증(리뷰레이)', color: 'red', icon: 'analytics', comp: '', },
  //{ key: 'notice2', name: '신규 공지사항 관리', color: 'red', icon: 'campaign', comp: '', },
  //{ key: 'faq2', name: '신규 FAQ 관리', color: 'red', icon: 'help_center', comp: '', },
]
