import React, { useState } from 'react';

import { Button, Modal } from 'semantic-ui-react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

// Components
import TableList, { ITableListProps, ITableListButton, IFindTextBox, IPagination, ISelectBox } from 'components/common/table/TableList';
import QnaListItem from './QnaListItem';
import QnaWrite from './popup/QnaWrite'
import QnaDetail from './popup/QnaDetail'
import useBasics from 'hooks/useBasics';
import useQna from 'hooks/useQna';
import { IPage } from 'store/modules/types';
import { IRequestQnaListParams, initRequestParams } from './interfaces';
import { convertCommonCode } from 'util/common';


interface IProps {
  category: any[];
  page: IPage;
  list: any[];
  onRequest: Function;
}

const QnaList: React.FC<IProps> = (props) => {
  const { category, page, list, onRequest } = props;

  const { basics } = useBasics();
  const { onCreateQna, onStatsUpdate } = useQna();
  const { user } = basics; 
  const [ requestParams, setRequestParams] = useState<IRequestQnaListParams>({
    ...initRequestParams
  });
  const [ openWrite, setOpenWrite ] = useState(false);
  const [ detailId, setDetailId ] = useState(-1);
  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ resultMsg, setResultMsg] = useState('');
  const [ isDeleted, setIsDeleted ] = useState(false);
  const { commonCodeList : { qna_type } } = useBasics().basics

  const [ qnaCategoryType, setQnaCategoryType] = useState([{ value: 'ALL', text: '전체' }].concat(convertCommonCode(qna_type)));


  const onClose = () => {
    setOpenWrite(false);
  }
  
  const onCloseDetail = () => {
    setDetailId(-1);
  }

  const onCloseDeletedPopup = () => {
    setIsDeleted(false);
  }

  const onShowDetail = (detailNo : number, status : number, delYn: boolean) => { 
    // if(status === 1){
    //   onStatsUpdate({
    //     pathVariables: {'qnaId' : detailNo},
    //     callback : (succeeded: boolean, res: any) => {
    //       if(succeeded){
    //         onRequest(requestParams);
    //       }            
    //     }
    //   });
    // }
      
    setDetailId(detailNo);
  }

  ///////////////////////////////////
  const onSearch = (event : any) => {  
    if(event.key === 'Enter' || event === 'Clicked'){
      setRequestParams({      
        ...requestParams,
        page: 1,
      });

      onRequest({
        ...requestParams,
        page: 1,
      });
    }
  }
    
  const onChange = (event : React.ChangeEvent<HTMLInputElement>, getData: any) => {
    // console.log('data :: ', getData);
    setRequestParams({
      ...requestParams,
      [event.target.name] : event.target.value
    });
  }

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, getData: any) => {
    // setPageNo(getData.activePage);
    setRequestParams({
      ...requestParams, 
      [getData.name]: getData.activePage
    })
    onRequest({
      ...requestParams, 
      [getData.name]: getData.activePage
    });
  }
  ///////////////////////////////////<
  
  const onAddQnaButtonClicked = () => {
    setOpenWrite(true);
  };

  const onCloseSnackbar = () => {
    // console.log("onCloseSnackbar");
    setSnackbarOpen(false);
    setResultMsg('');
  }
  

  const refetch = () => {
    onRequest(requestParams)
  }

  const buttons: ITableListButton[] = [
    {
      name: '1:1 문의하기',
      icon: 'add',
      onButtonClicked: onAddQnaButtonClicked
    }
  ];

  // 테이블 헤더 요소 수정
  const headers = ['', '분류', <div style={{font:'inherit', textAlign:'left'}}>제목</div>, '작성자', '등록일시', ''];
  
  const headerWidth = [1, 2, 8, 1.5, 3, 1];

  const finder : IFindTextBox = {
    name: 'title',
    onChange: onChange,
    onSearch: onSearch    
  }

  const onChangeQnaCategoryType = (event: React.SyntheticEvent<HTMLElement, Event>, getData: any) => {    
    const value = getData.value === 'ALL' ? '' : getData.value;
    onRequest({
      ...requestParams,
      page: 1,
      qnaCategoryType: value
    });
  };

  const select : ISelectBox = {
    name: 'qnaCategoryType',
    options: qnaCategoryType,
    value: requestParams.qnaCategoryType,
    onChange: onChangeQnaCategoryType,
  };

  const pagination : IPagination = {
    name: 'page',
    activePage: requestParams.page,
    totalPage: page.totalPages,
    onChange: onChangePage, 
  }
  
  // QnA 페이지 제목 수정, button 삭제
  const tableListProps: ITableListProps = {
    title: 'QnA 관리',
    size: 'small',
    color: 'violet',
    finder,
    select,
    headers,
    headerWidth,
    buttons,
    pagination,
    count: page.totalElements,
    refresh: ()=>{
      setRequestParams({
        ...initRequestParams,
      });
      onRequest({
        ...initRequestParams
      })
    }
  }
  // console.log('list ::::', list);
  const qnaItems = list.map((item:any) => (
    <QnaListItem
      key={item.qnaId}  
      category={category}
      user={user}
      requestParams={requestParams}
      qna={item}
      onRequest={onRequest}
      onShowDetail= {onShowDetail}
      setIsDeleted= {setIsDeleted}
      showFeedback={(msg) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  ));

  // console.log('category : ', category);

  return (
    <>
      <TableList {...tableListProps}>        
        { qnaItems }
        
        <QnaWrite
          category= {category}
          user= {user}
          open= {openWrite}
          onRequest={onRequest}
          onCreateQna= {onCreateQna}
          onClose= {onClose}
          showFeedback= {(msg:string) => {
            setResultMsg(msg);
            setSnackbarOpen(true);
          }}
          setRequestParams={setRequestParams}
        />
        
        <QnaDetail
          user= {user}
          answerId= {detailId}
          onCreateQna= {onCreateQna}
          onClose= {onCloseDetail}
          onRequest={refetch}
          showFeedback= {(msg:string) => {
            setResultMsg(msg);
            setSnackbarOpen(true);          
          }}
        />
      </TableList>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}

const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default QnaList;