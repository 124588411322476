import React, { useState, useEffect, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Form, Table, Button, TextArea } from 'semantic-ui-react';
import FileSaver from 'file-saver';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

import useQna from 'hooks/useQna'
import { IAnswerQnaParams, initAnswerQnaParams, initQnaDetail, initQnaDetailParams, initRequestParams, IQnaDetailParams, IQnaDetails } from '../interfaces';
import { SERVER_URL } from 'store/modules/types/epics';
import QnaAddAnswer, { HeaderWrap } from './QnaAddAnswer';
import useBasics from 'hooks/useBasics';
import { convertCommonCode } from 'util/common';

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`


interface IProps {
  user: any;
  answerId: number;
  onCreateQna: Function;
  onClose(): void;
  showFeedback: Function;
  onRequest: Function;
}

const QnaDetail: React.SFC<IProps> = (props) => {
  const { user, answerId, onCreateQna, onClose, showFeedback, onRequest } = props;
  const { onGetQna, onModifyQna } = useQna();
  const { basics } = useBasics();
  const [ detail, setDetail ] = useState<IQnaDetailParams>(initQnaDetailParams);
  const { commonCodeList } = basics;

  const [ questionDetail, setQuestionDetail ] = useState<IQnaDetails[]>([initQnaDetail]);
  const [ answerDetail, setAnswerDetail ] = useState<IQnaDetails[]>([initQnaDetail]);
  const [ modifyMode, setModifyMode ] = useState(false);

  const [ addAnswer, setAddAnswer ] = useState<boolean>(false)
  

  const [ answerQnaParams, setAnswerQnaParams ] = useState<IAnswerQnaParams>({   
    ...initAnswerQnaParams
  });

  
  const onRequestQna = () => {
    const payload: any = {
      pathVariables : {
        "params": answerId
      },
      callback: (succeeded: boolean, res: any) =>{
        if(succeeded) {
          setDetail(res.response.data);
          const userQna = res.response.data.userQa          
          const adminQna = res.response.data.adminQa;
          setQuestionDetail(userQna)

          onRequest(initRequestParams)
          
          if(adminQna && adminQna.length > 0){
            setAnswerDetail(adminQna)
          } else {
            setAnswerDetail([initQnaDetail]);
          }

          setAddAnswer(false)
        }
        setAnswerQnaParams({
          ...answerQnaParams,
          qnaId: answerId,
        })
      }
    };
    onGetQna(payload);
  }
  // console.log('detail : ', detail);
  // console.log('answerQnaParams : ', answerQnaParams);

  useEffect(() => {
    // requestFaqCode('faq_type')
    onInitDetails();
    if(answerId !== -1)
      onRequestQna();    
  }, [answerId]);

  // console.log('detail ::: ', detail);
  // console.log('answerDetail ::: ', answerDetail);

  // const download = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, fileName: string, url: string) => {    
  //   let file = new Blob([url], { type: "image/*" });   
  //   URL.createObjectURL(file);
  //   FileSaver.saveAs(url, fileName);
  //   event.preventDefault();
  // }  

  const download = (fileName: string, url: string) => {       
    FileSaver.saveAs(url, fileName);    
  }  


  const onAnswerQna = (e: SyntheticEvent, item: any) => {
    const id = (item && item.data) || undefined;
    if (id && !modifyMode || addAnswer) {
      onCreateQna({
        body : answerQnaParams
      });
      onRequestQna();
      showFeedback('Answer is registered');
    } else if (id && modifyMode) {
      onModifyQna({qnaId: answerId, qnaContents : answerQnaParams.qnaContents, file: [], deleteFileNo: []})
      setModifyMode(false);
      showFeedback('Answer is modified');
    }
    onClose();
  }

  const onChangeParams = (event: React.SyntheticEvent<HTMLElement, Event>, data: any) =>{
    //console.log('data : ', data);
    setAnswerQnaParams({
      ...answerQnaParams,
      [data.name]: data.value
    });
    // console.log('insertQnaParams :: ', insertQnaParams);
  }

  // const onClickButton = () => {
  //   setAnswerQnaParams({
  //     ...answerQnaParams,
  //     qnaContents: answerDetail[answerDetail.length-1].qnaContents,
  //   })
  //   setModifyMode(!modifyMode);
  // }

  const onAddAnswer = () => {
    setAddAnswer(true)
  }

  const onInitDetails = () =>{
    setQuestionDetail([initQnaDetail]);
    setAnswerDetail([initQnaDetail]);
  }

  const fileBoxes = questionDetail[questionDetail.length-1].qnaFiles.length > 0 ? questionDetail[questionDetail.length-1].qnaFiles.map((item, index) => 
    <span key={index} className="file-box">
      <span className="file-clip" />
      <span className="file-name" onClick={()=>download(item.originFileName, SERVER_URL+item.imagePath+item.imageName)}>
          {item.originFileName}
      </span>
    </span>
  ) : [];

  const fileArea = questionDetail[questionDetail.length-1].qnaFiles.length > 0 ? (
    <div className="file-area">
      {fileBoxes}
    </div>
  ) : <></>

  const answerPart = ( (answerDetail[answerDetail.length-1].qnaNo !== -1 && !modifyMode) ?
  (<TextArea style={{ minHeight: 200, backgroundColor:'#f0f0f0' }} value={answerDetail[answerDetail.length-1].qnaContents} disabled />)
  : (
      <TextArea 
        name='qnaContents' 
        defaultValue={answerDetail[answerDetail.length-1].qnaContents}        
        placeholder='답변 내용을 작성해주세요.' 
        style={{ minHeight: 200 }} 
        onChange={onChangeParams} 
      />
    )
  )
  
  const questionRegDate = new Date(questionDetail[questionDetail.length-1].regDt).toLocaleString();
  const answerRegDate = new Date(answerDetail[answerDetail.length-1].regDt).toLocaleString();

  const buttonOptions = (answerDetail[answerDetail.length-1].qnaNo !== -1 && !modifyMode && !addAnswer) 
    ? [{name:'OK', onClick: ()=>{ setModifyMode(false); onClose(); }}] 
    : [{}, { onClick: onAnswerQna }]
  
  return (
    <BasePopup
      open={answerId !== -1}
      id={user.uid}
      size='small'
      type={PopupTypes.DIALOG}
      title='QnA 상세'
      // buttons={}
      buttons={buttonOptions}
      onClose={() => {
          setModifyMode(false);
          onClose();
        }
      }
    >
      <Form className="qna-confirm" size='small'>
        <span style={{float:'right', marginBottom:'5px'}}> 등록일시 : {questionRegDate}</span>
        <Form.Input 
          label="카테고리"
          value={convertCommonCode(commonCodeList.faq_type).find(c => c.value === detail.qnaCategoryType)?.text}
          style={{ 
            height: '50px', 
            backgroundColor: 'rgb(240, 240, 240)', 
            overflowY: 'scroll' 
          }}
          readOnly
        />
        <Form.Input 
          label="전화번호"
          value={detail.phone}
          style={{ 
            height: '50px', 
            backgroundColor: 'rgb(240, 240, 240)', 
            overflowY: 'scroll' 
          }}
          readOnly
        />
        <Form.Input 
          label="아이디"
          value={detail.userId}
          style={{ 
            height: '50px', 
            backgroundColor: 'rgb(240, 240, 240)', 
            overflowY: 'scroll' 
          }}
          readOnly
        />
        <Form.Input 
          label="이메일"
          value={detail.requestEmail}
          style={{ 
            height: '50px', 
            backgroundColor: 'rgb(240, 240, 240)', 
            overflowY: 'scroll' 
          }}
          readOnly
        />
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{detail.qnaTitle}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <TextArea 
                  style={{ height: '200px', backgroundColor:'#f0f0f0' }} 
                  value={questionDetail[questionDetail.length-1].qnaContents} 
                  disabled 
                />
                {fileArea}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br></br>

        

        <div style={{display:'block', lineHeight:'31.94px'}}>
          {(answerDetail[answerDetail.length-1].qnaNo !== -1 && !modifyMode && !addAnswer) 
            ? <ButtonWrap>
                {/* <Button size='mini' inverted color='purple' onClick={onClickButton}> 수정 </Button> */}
                <Button size='mini' inverted color='orange' onClick={onAddAnswer}> 추가답변작성 </Button>
              </ButtonWrap>
            : null
          }
          <span style={{float:'right'}}> {answerDetail[0].qnaNo !== -1 ? '등록일시 : ' +answerRegDate : null}</span>
        </div>
        {/* <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <HeaderWrap>
                  <span>답변 | {answerDetail[0].qnaNo !== -1 ? '관리자('+answerDetail[0].regUserId+')' : '작성'}</span>
                </HeaderWrap>
                  
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {answerPart}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table> */}
        {answerDetail          
          .map(detail => <QnaAddAnswer 
            answerQnaParams={answerQnaParams} 
            onChangeParams={onChangeParams} 
            onRequestQna={onRequestQna}
            showFeedback={showFeedback}
            answerId={answerId}
            answerDetail={detail} 
            onClose={onClose}
          />)
        }
        {(addAnswer || answerDetail.length < 1) && <QnaAddAnswer 
            answerQnaParams={answerQnaParams} 
            onRequestQna={onRequestQna}
            showFeedback={showFeedback}
            answerId={answerId}
            onChangeParams={onChangeParams} 
            onClose={onClose}
          />
        }
      </Form>
    </BasePopup>
  );
}

export default QnaDetail;