export interface IRequestFaqParams {
  page: number;
  size: number;
  serviceTypes:string;
  title: string;
  faqType: string;
}

export const initIRequestFaqParams : IRequestFaqParams = {
  page: 1,
  size: 10,
  serviceTypes: 'SELLERSPOT,IRANK,REVIEWRAY',
  title: '',
  faqType: ''
}

export interface IFaqInsertParams {
  serviceType: string;
  faqContents: string;
  faqTitle: string;
  faqType: string;
  userId: string;
  viewYn: boolean;
  id?:number;
}

export const initIFaqInsertParams : IFaqInsertParams = {
  serviceType:'SELLERSPOT',
  faqContents: '',
  faqTitle: '',
  faqType: '',
  userId: '',
  viewYn: true
}

export interface IPopupParams {
  faqId: number;
  faqType: string;
  open: boolean;
  mode: string;
}

export const initIPopupParams : IPopupParams = {
  faqId: 0,
  faqType: '',
  open: false,
  mode: ''
}