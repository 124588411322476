import React, { useEffect, useState } from 'react';
// Hooks
import useFaq from 'hooks/useFaq';
import useBasics from 'hooks/useBasics';
// Components
import FaqList from './FaqList';
// Interface
import { IRequestFaqParams, initIRequestFaqParams } from './interface';
import { convertCommonCode } from 'util/common';

const Faq: React.FC = () => {

  const { basics } = useBasics();
  const { commonCodeList } = basics;
  const { faq, requestFaqList } = useFaq();
  const { faqData } = faq;
  const [ requestParams, setRequestParams] = useState<IRequestFaqParams>(initIRequestFaqParams);

  useEffect(() => {
    onRequestFaqList();
  }, []);

  const onRequestFaqList = (params?: any) => {
    const reqParams = params === undefined ? initIRequestFaqParams : params;
    setRequestParams(reqParams);
    requestFaqList({
      queryParameters: reqParams
    });
  };

  return (
    <FaqList 
      faqCode={convertCommonCode(commonCodeList.faq_type)} 
      faqList={faqData.list}
      faqPage={faqData.page}
      params={requestParams}
      setParams={setRequestParams}
      refresh={onRequestFaqList} 
    />
  )
}
export default Faq;