import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState, ActionPayload } from 'store/modules/types';
import { action } from 'store/modules/users';

const useUsers = () => {

  const dispatch = useDispatch();

  const users = useSelector((state: StoreState) => state.users);

  const requestUserList = (payload: ActionPayload) => {
    dispatch(action.getUserList(payload));
  }

  const requestDashboardUserList = (payload: ActionPayload) => {
    dispatch(action.getDashboardUserList(payload));
  }

  const requestUser = (payload: ActionPayload) => {
    dispatch(action.getUser(payload));
  }

  const requestUserPasswordUpdate = (payload: ActionPayload) => {
    dispatch(action.userPasswordUpdate(payload));
  }

  const requestUserPasswordReset = (payload: ActionPayload) => {
    dispatch(action.userPasswordReset(payload));
  }

  const requestUserMarketList = (payload: ActionPayload) => {
    dispatch(action.getUserMarketList(payload));
  }

  const requestUserIdCheck = (payload: ActionPayload) => {
    dispatch(action.userIdCheck(payload));
  }

  const requestUserEmailCheck = (payload: ActionPayload) => {
    dispatch(action.userEmailCheck(payload));
  }

  const requestUserInsert = (payload: ActionPayload) => {
    dispatch(action.userInsert(payload));
  }

  const requestTosList = (payload: ActionPayload) => {
    // dispatch(action.getTosList({
    //   pathVariables: { 'useOnly': false }
    // }));
    dispatch(action.getTosList(payload));
  }

  const updateUser = (data: any) => {
    dispatch(action.updateUser(data));
  }

  const deleteUser = (data: any) => {
    dispatch(action.deleteUser(data));
  }
  
  const requestPaymentList = () => {
    dispatch(action.getPaymentList());
  }

  const requestPayment = (data: any) => {
    dispatch(action.getPayment(data))
    // dispatch(action.getPayment({
    //   pathVariables: { 'payment_no': data.paymentNo }
    // }));
  }

  const deletePayment = (data: any) => {
    dispatch(action.deletePayment(data))
  }

  const insertPayment = (data: any) => {
    dispatch(action.insertPayment({
      body: data
    }))
  }

  const modifyPayment = (data: any) => {
    dispatch(action.modifyPayment({
      body: data
    }))
  }

  const requestCodeList = () => {
    dispatch(action.codeListAll());
  }

  const secretLogin = (payload: ActionPayload) => {
    dispatch(action.secretLogin(payload));
  }

  // /api/v1/admin/userSales 판매정보 - 사용자 선택시
  const requestUserSalesInfoSummary = (payload: ActionPayload) => {
    dispatch(action.getUserSalesInfoSummary(payload))
  }

  // /api/v1/admin/userSalesDetail 판매정보 - 사용자 선택 후 날짜 선택시
  const requestUserSalesInfoDetail = (payload: ActionPayload) => {
    dispatch(action.getUserSalesInfoDetail(payload))
  }

  const requestUserAnalyzeStatus = (payload: ActionPayload) => {
    dispatch(action.getUserAnalyzeStatus(payload))
  }

  const requestUserAnalyzeStatusProduct = (payload: ActionPayload) => {
    dispatch(action.getUserAnalyzeStatusProduct(payload))
  }

  const requestUserAnalyzeStatusKeyword = (payload: ActionPayload) => {
    dispatch(action.getUserAnalyzeStatusKeyword(payload))
  }

  const requestUserAnalyzeStatusComprehensive = (payload: ActionPayload) => {
    dispatch(action.getUserAnalyzeStatusComprehensive(payload))
  }

  const requestUserAnalyzeStatusCompareMonitoring = (payload: ActionPayload) => {
    dispatch(action.getUserAnalyzeStatusCompareMonitoring(payload))
  }

  const requestUserAnalyzeStatusTraceMonitoring = (payload: ActionPayload) => {
    dispatch(action.getUserAnalyzeStatusTraceMonitoring(payload))
  }

  const requestGetUserByUid = (payload: ActionPayload) => {
    dispatch(action.requestGetUserByUid(payload))
  }

  const requestPatchUserRoles = (payload: ActionPayload) => {
    dispatch(action.requestPatchUserRoles(payload))
  }

  const requestGetReviewrayProducts = (payload: ActionPayload) => {
    dispatch(action.requestGetReviewrayProducts(payload))
  }

  return {
    users,
    requestUserList,
    requestDashboardUserList,
    requestUser,
    requestUserPasswordUpdate,
    requestUserPasswordReset,
    requestUserMarketList,
    requestUserIdCheck,
    requestUserEmailCheck,
    requestUserInsert,
    requestTosList,
    updateUser,
    deleteUser,
    requestPaymentList,
    requestPayment,
    deletePayment,
    insertPayment,
    modifyPayment,
    requestCodeList,
    secretLogin,
    requestUserSalesInfoSummary,
    requestUserSalesInfoDetail,
    requestUserAnalyzeStatus,
    requestUserAnalyzeStatusProduct,
    requestUserAnalyzeStatusKeyword,
    requestUserAnalyzeStatusComprehensive,
    requestUserAnalyzeStatusCompareMonitoring,
    requestUserAnalyzeStatusTraceMonitoring,
    
    requestGetUserByUid,
    requestPatchUserRoles,
    requestGetReviewrayProducts,
  }
}
 
export default useUsers;