import React, { useEffect, useState } from "react";
import useHotKeyword500 from "hooks/useHotKeyword500";
import {
  Table,
  Title,
  RecollectButtonContainer,
} from "../styles/CommonStyle/Common.style";
import { CustomizedButton } from "../styles/CommonStyle/Common.style";
import { HotKeywordStyles as Styled } from "../styles/HotKeywordStyle/HotKeyword.style";
import HotKeywordCard from "../Card";
import { HotKeyword500State, HotKeywords } from "store/modules/types";
import DayOfWeek from "../DayOfWeek";

const HotKeyword500: React.FC = () => {
  const { hotKeyword500, requestHotKeyword500, requestHotkeyword500Recollect } =
    useHotKeyword500();
  const { hotKeywordData } = hotKeyword500;
  const { hotKeywords } = hotKeywordData;
  const [checkedProducts, setCheckedProducts] = useState<HotKeywords[]>([]);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [selectAll, setSelectAll] = useState(true);

  const getInitialCheckedItems = (num: number) => {
    const initialCheckedItems: { [key: number]: boolean } = {};
    for (let i = 0; i < num; i++) {
      initialCheckedItems[i] = true;
    }
    return initialCheckedItems;
  };

  useEffect(() => {
    requestHotKeyword500();
  }, []);

  useEffect(() => {
    setCheckedProducts(hotKeywords);
    setCheckedItems(getInitialCheckedItems(hotKeywords.length));
  }, hotKeywords);

  useEffect(() => {
    const updatedCheckedProducts = hotKeywords.filter(
      (_, index) => checkedItems[index]
    );
    setCheckedProducts(updatedCheckedProducts);
  }, [checkedItems]);

  const handleCheckboxChange = (idx: number) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [idx]: !prevCheckedItems[idx],
      };
      return newCheckedItems;
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    const newCheckedItems = { ...checkedItems };
    hotKeywordData.hotKeywords.forEach((_, idx) => {
      newCheckedItems[idx] = !selectAll; // 현재 selectAll 상태 반전
    });

    setCheckedItems(newCheckedItems);
  };

  const onRecollect = (hotKeywords: HotKeywords[]) => {
    console.log(hotKeywords);
    if (!hotKeywords.length) {
      alert("미수집 리스트가 존재 하지 않습니다");
      return;
    }

    const payload: any = {
      body: {
        startDate: hotKeywordData.startDt,
        categoryId: hotKeywords.map((hotKeyword) => hotKeyword.categoryId),
      },
      callback: (succeeded: boolean, res: any) => {
        if (succeeded) {
          alert("재수집 요청 성공");
        } else {
          if (res.status === 409) {
            alert("재수집 중");
          } else {
            alert("재수집 실패");
          }
        }
      },
    };
    requestHotkeyword500Recollect(payload);
  };

  return (
    <>
      <Styled.Wrapper>
        <RecollectButtonContainer>
          <Title>핫 키워드 500</Title>

          <CustomizedButton onClick={() => onRecollect(checkedProducts)}>
            재수집{" "}
          </CustomizedButton>
        </RecollectButtonContainer>
        <Styled.LinkButton
          as="a"
          href={`https://datalab.naver.com/shoppingInsight/sCategory.naver
                    `}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <Styled.LinkText>데이터 LAB 바로가기</Styled.LinkText>{" "}
        </Styled.LinkButton>
      </Styled.Wrapper>
      <Styled.TextContainer>
        <Styled.Text>
          데이터 조회 기간: {hotKeywordData.startDt}
          <DayOfWeek isFullDate={false} dateString={hotKeywordData.startDt} />
          &nbsp; ~ {hotKeywordData.endDt}
          <DayOfWeek isFullDate={false} dateString={hotKeywordData.endDt} />
        </Styled.Text>
        <Styled.Text>
          수집 시작 날짜: {hotKeywordData.startCollectedDate}
          &nbsp;
          <DayOfWeek
            isFullDate={false}
            dateString={hotKeywordData.startCollectedDate}
          />
        </Styled.Text>
      </Styled.TextContainer>
      <Styled.Text>
        최근 수집 시간 : {hotKeywordData.latestDateTime}
      </Styled.Text>

      <Styled.CardContainer>
        <HotKeywordCard
          text="전체 카테고리"
          value={hotKeywordData.totalCategories}
        ></HotKeywordCard>
        <HotKeywordCard
          text="전체 카테고리 수집 수"
          value={
            hotKeywordData.totalCategories -
            hotKeywordData.miscollectedCategories
          }
        ></HotKeywordCard>
        <HotKeywordCard
          text="전체 카테고리 핫 키워드 500 수집 수"
          value={hotKeywordData.collectedKeywords}
        ></HotKeywordCard>
      </Styled.CardContainer>
      <Styled.TextContainer>
        <Styled.Text>미 수집 카테고리 ({hotKeywords.length})</Styled.Text>
        <Styled.CheckListContainer>
          <Styled.Text>
            {selectAll ? "전체 선택 해제" : "전체 선택"}
          </Styled.Text>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          ></input>
        </Styled.CheckListContainer>
      </Styled.TextContainer>

      {hotKeywords.length > 0 && (
        <Table isChecklist={true}>
          <thead>
            <tr>
              <th> No </th>
              <th> Category Id </th>
              <th> 카테고리 명 </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {hotKeywordData.hotKeywords.map((item, idx) => (
              <tr key={idx}>
                <td>{idx}</td>
                <td>{item.categoryId}</td>
                <td>{item.categoryName}</td>
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(idx)}
                  checked={!!checkedItems[idx]}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default HotKeyword500;
