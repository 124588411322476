import React from 'react'
import { ListStyles as Styled } from './styles/List.style'

export interface IProps {
  id: string | number
  listWidthList: string
  titleList: (string | JSX.Element)[]
  list: (string | JSX.Element)[]
  isHoverEvent?: boolean
  isLoading: boolean
}

const List: React.FC<IProps> = (props) => {
  const { 
    id, 
    listWidthList, 
    titleList, 
    list, 
    isLoading,
    isHoverEvent = true
  } = props;

  return (
    <Styled.Wrap>
      <Styled.HeaderWrap listWidthList={listWidthList}>
        {titleList.map((title, index) => (
          <Styled.Header key={`${id}-title-${index}`}>{title}</Styled.Header>
        ))}
      </Styled.HeaderWrap>
      <Styled.List>
        {list.map((item, index) => (
          <Styled.Item 
            key={`${id}-item-${index}`} 
            listWidthList={listWidthList} 
            isHoverEvent={isHoverEvent}
          >
            {item}
          </Styled.Item>
        ))}
        {!isLoading && list.length === 0 && (
          <Styled.NoData>데이터가 없습니다.</Styled.NoData>
        )}
      </Styled.List>
    </Styled.Wrap>
  )
}

export default List