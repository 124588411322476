import React, { useEffect, useState } from 'react'
// components
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
import BannerListItem from './BannerListItem';
// hook
import useBanner from 'hooks/useBanner';
// interface
import { BannerItem, IBannerData, initBannerData, IRequestBannerParams } from './interface';
// styles
import { BannerStyles as Styled } from './styles/BannerList.styles'

interface IProps {
  requestParams: IRequestBannerParams;
  bannerList: BannerItem[];
  onOpenPopup: (mode: 'detail' | 'write') => void;
  onSetSelectBannerData: (banner: BannerItem) => void;
  onChangeActive: (bannerId: number) => void;  
  onOpenSnackBar: (msg: string) => void;
  totalElements: number;
  requestBanner:Function;
}

const BannerList: React.FC<IProps> = (props) => {
  const { 
    requestParams,
    bannerList, 
    onOpenPopup, 
    onSetSelectBannerData, 
    onChangeActive,
    onOpenSnackBar,
    totalElements,
    requestBanner,
  } = props;

  const { requestBannerList, requestBannerActiveToggle } = useBanner();

  const [ confirmOpen, setConfirmOpen ] = useState<boolean>(false);
  const [ allBannerList, setAllBannerList ] = useState<IBannerData>(initBannerData);
  const [ allDefaultBannerList, setAllDefaultBannerList ] = useState<IBannerData>(initBannerData);
  const [ isToggleActiveStart, setIsToggleActiveStart ] = useState<boolean>(false);

  const isBannerListNothingActive = bannerList.length > 0 && 
    bannerList.filter((banner) => banner.activeYn).length === 0;

  const isAllBannerNothingActive = allBannerList && allBannerList.list.length > 0 && 
    allBannerList.list.filter((banner) => banner.activeYn).length === 0;

  const isDefaultBannerNothingActive = allDefaultBannerList && allDefaultBannerList.list.length > 0 &&
    allDefaultBannerList.list.filter((banner) => banner.activeYn).length === 0;

  const flexList = [1.5, 1.5, 4, 2, 3, 5, 0.5];

  useEffect(() => {
    if (isBannerListNothingActive || isDefaultBannerNothingActive) {
      requestAllBannerList();
    }
  }, [bannerList, isDefaultBannerNothingActive])

  useEffect(() => {
    if (!isToggleActiveStart) {
      if (isAllBannerNothingActive && isDefaultBannerNothingActive) {
        setIsToggleActiveStart(true);
        allDefaultBannerList.list.forEach((banner, index) => {
          onToggleActive(banner);
        })
        setIsToggleActiveStart(false);
      }
    }
  }, [isAllBannerNothingActive])

  const requestAllBannerList = () => {
    const allBannerPayload: any = {
      queryParameters: {
        ...requestParams,
        pageSize: totalElements,
      },
      callback: (succeeded: boolean, res: any) => {
        if(succeeded && res.response.code === 'OK'){
          setAllBannerList(res.response.contents);
        }
      },
      async: true,
    }

    const allDefaultBannerPayload: any = {
      queryParameters: {
        ...requestParams,
        pageSize: totalElements,
        defaultYn: true,
      },
      callback: (succeeded: boolean, res: any) => {
        if(succeeded && res.response.code === 'OK'){
          setAllDefaultBannerList(res.response.contents);
        }
      },
      async: true,
    }
    
    requestBannerList(allBannerPayload);
    requestBannerList(allDefaultBannerPayload);
    
  }

  const onToggleActive = (banner: BannerItem, last?: boolean) => {
    if (new Date(banner.startDt) > new Date()) {
      onOpenSnackBar('아직 게시 시작일이 아닙니다. 게시 시작일을 먼저 변경해주세요.');
      return
    }

    const payload = {
      pathVariables: {
        path1: banner.bannerId
      },
      callback: (succeeded: boolean, res: any) => {
        if(succeeded && res.response.code === 'OK'){
          onChangeActive(banner.bannerId);
        }
      },
      async: true,
    }

    requestBannerActiveToggle(payload);
  }

  const bannerItem = bannerList.map((banner) => (
    <BannerListItem 
      key={banner.name} 
      banner={banner}
      flexList={flexList}
      onOpenPopup={onOpenPopup}
      onSetSelectBannerData={onSetSelectBannerData}
      onToggleActive={onToggleActive}
      requestBanner={requestBanner}
    />
  ))


  //const isCheck = deleteBannerIds.length === bannerList.length && bannerList.length > 0;

  const notFound = bannerList.length === 0 && (
    <Styled.BlankItem>검색 결과가 없습니다</Styled.BlankItem>
  )

  const openPopup = () => {
    setConfirmOpen(true);
  }

  const closePopup = () => {
    setConfirmOpen(false);
  }

  // const onDelete = () => {
  //   deleteBanner();
  //   setConfirmOpen(false);
  // }

  // const deleteConfirmPopup = (
  //   <BasePopup
  //     open={confirmOpen}
  //     id={0}
  //     size='mini'
  //     type={PopupTypes.DIALOG}
  //     buttons={[
  //       {
  //         name: '취소',
  //         onClick: closePopup,
  //       }, 
  //       {
  //         name: '삭제',
  //         onClick: onDelete,
  //       }
  //     ]}
  //     onClose={closePopup}
  //   >
  //     선택한 배너를 삭제 하시겠습니까?
  //   </BasePopup>
  // )

  return (
    <Styled.Wrap>
      <Styled.Header>
        {/* <Styled.Content flex={flexList[0]}>
          <Styled.CheckBox checked={isCheck} onChange={onChecked} />
        </Styled.Content> */}
        <Styled.Content flex={flexList[0]}>배너 위치</Styled.Content>
        <Styled.Content flex={flexList[1]}>분류</Styled.Content>
        <Styled.Content flex={flexList[2]}>배너명</Styled.Content>
        <Styled.Content flex={flexList[3]}>사용상태</Styled.Content>
        <Styled.Content flex={flexList[4]} merge>
          <Styled.Content divide>게시기간</Styled.Content>
          <Styled.Content>
            <Styled.Content>시작일</Styled.Content>
            <Styled.Content>종료일</Styled.Content>
          </Styled.Content>
        </Styled.Content>
        <Styled.Content flex={flexList[5]}>미리보기</Styled.Content>
      </Styled.Header>
      <Styled.List>{bannerItem}{notFound}</Styled.List>
      {/* <Styled.Div>
        <Styled.Button 
          onClick={openPopup} 
          disabled={deleteBannerIds.length === 0}
        >
          선택 삭제
        </Styled.Button>
      </Styled.Div> */}
      {/* {deleteConfirmPopup} */}
      <Styled.Content flex={flexList[6]}></Styled.Content>
    </Styled.Wrap>
  )
}

export default BannerList