import React from "react";

import TableListItem, { ITableListItemProps } from "components/common/table/TableListItem";
import { convertBoolean, convertDate, getRage } from "util/common";
import { IPopupParams, REVIEWRAY_DEMOS_STATUS } from "./interface";

interface IProps {
  item: any;
  setPopupMode: React.Dispatch<React.SetStateAction<IPopupParams>>
}


const DemoItem: React.FC<IProps> = (props) => {

  const { item, setPopupMode } = props
  const { 
    id, 
    company, 
    department,
    email, 
    phone, 
    name, 
    marketingAgreementYn, 
    regDt,
    membershipStartDate,
    membershipEndDate,
    status
  } = item;

  const tableListItemsProps: ITableListItemProps = {
    id: id,
    items: [ 
      `${company}(${department})`, 
      email, 
      phone, 
      name, 
      convertBoolean(marketingAgreementYn), 
      convertDate(regDt), 
      `${getRage(membershipStartDate, membershipEndDate)}`, 
      REVIEWRAY_DEMOS_STATUS[status]
    ],
    onRowSelectedEvent: () => {
      setPopupMode({
        id: id,
        open: true,
        mode:'detail',
      })
    }
  };

  
  return (<TableListItem {...tableListItemsProps} />)
}

export default DemoItem