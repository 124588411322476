import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';
// modules
import { GuideState, MarketState, NoticeState, initPage } from './types';
// actions
import * as actionTypes from './types/actions';
import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';
import { initNoticeReqParams } from 'components/notice2/NoticeList';

// ACTION PART
const action = {
    // getMarketList
    getnoticelist: createAction(actionTypes.GET_NOTICE_LIST),
    fulfillednoticelist: createAction(actionTypes.FULFILLED_NOTICE_LIST),
    fatilednoticelist: createAction(actionTypes.FAILED_NOTICE_LIST),
    
    writenotice: createAction(actionTypes.GET_WRITE_NOTICE),
    deleteNotice: createAction(actionTypes.DELETE_NOTICE),
    updateNotice: createAction(actionTypes.UPDATE_NOTICE),

    fulfilledWriteNotice: createAction(actionTypes.FULFILLED_WRITE_NOTICE),
    succeededDeleteNotice: createAction(actionTypes.SUCCEEDED_DELETE_NOTICE),
    succeededUpdateNotice: createAction(actionTypes.SUCCEEDED_UPDATE_NOTICE),

    //DetailNotice 
    getdetailNotice : createAction(actionTypes.GET_DETAIL_NOTICE),
    fulfilleddetailNotice: createAction(actionTypes.SUCCEEDED_DETAIL_NOTICE),
    fatileddetailNotice: createAction(actionTypes.FAILED_DETAIL_NOTICE),
  }
  
  const initialState: NoticeState = {
    noticeList: {
      list: [],
      page: initPage
    }
  };
  
  const reducer = handleActions<NoticeState>(
    {
      // notice 
      [actionTypes.GET_NOTICE_LIST]: (state, action): NoticeState => {
        return state;
      },
      [actionTypes.FULFILLED_NOTICE_LIST]: (state, action) => {
        return {
          ...state,
          noticeList: action.payload.noticeList
        }
      },
      [actionTypes.FAILED_NOTICE_LIST]: (state) => {
        return state;
      },
      [actionTypes.GET_WRITE_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.DELETE_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.UPDATE_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.FULFILLED_WRITE_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.SUCCEEDED_DELETE_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.FAILED_UPDATE_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.SUCCEEDED_UPDATE_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.GET_DETAIL_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.SUCCEEDED_DETAIL_NOTICE]: (state, action) : NoticeState => {
        return state;
      },
      [actionTypes.FAILED_DETAIL_NOTICE]: (state, action) : NoticeState => {
        return state;
      }
    },
    initialState
  );
  
  // EPIC PART
  //  Notice list
  const requestNoticeListEpic = (actions$: any) => { 
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_NOTICE_LIST,      
        success: (res: AjaxResponse) => action.fulfillednoticelist({ noticeList: res.response.data }),
      },
      url: API_URL.NOTICE,
      method: AjaxMethods.GET,
    };
    return epicService.request(actions$, reqParams);
  }
  // Notice Detial
  const requestNoticeDetailEpic = (actions$: any) => { 
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_DETAIL_NOTICE,      
      },
      url: API_URL.NOTICE,
      method: AjaxMethods.GET,
    };
    return epicService.request(actions$, reqParams);
  }
   
  // Notice Write
  const requestNoticeWriteEpic = (actions$: any) => { 
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_WRITE_NOTICE,
        success: (res: AjaxResponse) => action.fulfilledWriteNotice(),
        fail : actionTypes.FAILED_WRITE_NOTICE
      },
      url: API_URL.NOTICE,
      method: AjaxMethods.POST, 
    };
    return epicService.request(actions$, reqParams);
  }
  
  // Notice Update
  const updateNoticeEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.UPDATE_NOTICE, 
        success: (res: AjaxResponse) => action.succeededUpdateNotice(),
        fail: actionTypes.FAILED_UPDATE_NOTICE
      },
      url: API_URL.NOTICE,
      method: AjaxMethods.PATCH
    };
    return epicService.request(actions$, reqParams);
  }
  
  // Notice Delete
  const deleteNoticeEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.DELETE_NOTICE,
        success: (res: AjaxResponse) => action.succeededDeleteNotice(),
        fail: actionTypes.FAILED_DELETE_NOTICE
      },
      url: API_URL.NOTICE,
      method: AjaxMethods.DELETE
    };
    return epicService.request(actions$, reqParams);
  }

  const epic = combineEpics(
    requestNoticeListEpic,
    requestNoticeWriteEpic,
    deleteNoticeEpic,
    updateNoticeEpic,
    requestNoticeDetailEpic,
  );
  
  export { action, epic };
  export default reducer;
