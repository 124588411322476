import React, { SyntheticEvent, useState, useContext } from 'react';

import useUsers from 'hooks/useUsers';

import { Label, LabelProps } from 'semantic-ui-react';

// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
import UserModify from './popup/UserModify';
import UserDelete from './popup/UserDelete';

// Lib
import JSBI from 'jsbi';
import { useUserContext, constants, IMySmartStore } from './interface';
import { PARTNERSHIP } from 'util/constants';

interface IProps {
  user: any;
  showFeedback: { (msg: string): void };
  userAuth: string;
}

const config = require('../../config');

const UserListItem: React.FC<IProps> = (props) => {
  const { user, showFeedback, userAuth } = props;
  const { requestGetUserByUid } = useUsers();
  const { uid, userId, role, quitYn, regDt, customerId, phone, lastLoginDate = '', cooperationType } = user;  

  const [open, setOpen] = useState(false);
  const [popupName, setPopupName] = useState(null);

  const { selectedUser, setSelectedUser } = useUserContext();

  const options: Intl.DateTimeFormatOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
  const regDate = new Date(regDt);
  const formattedRegDate = new Intl.DateTimeFormat("ja-JP", options).format(regDate);

  let formattedPhone = phone;

  if (phone.length === 10 || phone.length === 11) {
    formattedPhone = formattedPhone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
  }

  const onClose = () => {
    setOpen(false);
  }

  // const onButtonClicked = (e: SyntheticEvent, item: any) => {
  //   const id = (item && item.data) || undefined;
  //   let accessToken = "";
  //   let refreshToken = '';
  //   let uid = "";
  //   if (id) {
  //     if (item.name === 'sign-in') {
  //       if (typeof window !== "undefined") {
  //         const payload: any = {
  //           body: { userId: userId },
  //           async: true,
  //           callback: (succeeded: boolean, res:any) => {
  //             const resData = res.response.data;
  //             if(succeeded){
  //               accessToken = resData.accessToken;
  //               refreshToken = resData.refreshToken;
  //               uid = JSBI.BigInt(resData.uid).toString()
  //             }
  //           }
  //         }
  //         secretLogin(payload);
  //         window.open(`${config.WEB_URL}/secret-login?access=${accessToken}&refresh=${refreshToken}&id=${uid}`, "_blank");
  //       }
  //     }
  //   }
  // };

  const onRowSelectedEvent = (e: SyntheticEvent) => {
    console.log('onRowSelectedEvent', user)
    //setSelectedUserStore(mySmartstore);
    const payload: any = {
      pathVariables: { uid: JSBI.BigInt(user.uid).toString() },
      queryParameters: {
        cooperationType: user.cooperationType,
      },
      callback: (succeeded: boolean, res:any) => {        
        if (succeeded) {
          setSelectedUser(
            {
              ...{cooperationType: user.cooperationType},
              ...res.response.data,
            }
          );
        }
      }
    }

    requestGetUserByUid(payload)

    // 4. user selected
    const targetTag = document.getElementsByClassName('active center aligned');
    for(let i=0; i<targetTag.length; i++){
      targetTag[i].className = 'center aligned';
    }
    const addTargetTag = document.getElementById(userId);
    if(addTargetTag){
      addTargetTag.className = 'active center aligned';
    }
  };
  
  const roleName = role && role.length > 0 ? constants.ROLE_NAMES[`ROLE_${role[0]}`] : '';
  // const quitType = quitYn === true ? "탈퇴회원" : "회원";
  // const regDate = new Date(regDt).toLocaleString();
  const userIdEllipsis = userId.substr(0, 10) + (userId.length > 10 ? '...' : '');


  const lastLoginDtOptions: Intl.DateTimeFormatOptions = { 
    month: "2-digit", 
    day: "2-digit", 
    year: "numeric", 
    hour: "2-digit", 
    minute: "2-digit", 
    second: "2-digit",
  };

  const formattedLastLoginDt = lastLoginDate ? new Intl.DateTimeFormat("ja-JP", lastLoginDtOptions).format(new Date(lastLoginDate)) : '';

  const tableListItemsProps: ITableListItemProps = {
    id: uid,
    // items: [userLabelByRole(userId, role), customerId, customerName],
    items: [
      userIdEllipsis, 
      customerId, 
      formattedPhone, 
      formattedRegDate, 
      roleName, 
      formattedLastLoginDt,
      PARTNERSHIP[cooperationType],
    ],
    //iconButtons: getButtons(userAuth, quitYn, onButtonClicked),
    rowKey: userId,
    onRowSelectedEvent: onRowSelectedEvent,
  }

  return (
    <>
      <TableListItem {...tableListItemsProps} />
    </>
  );
}

// const getButtons = (role: string, quitYn: boolean, onClicked: any) => {
//   const iconButtons: ITableListItemIconButton[] = [];
  
//   const userLoginButton: ITableListItemIconButton = {
//     color: 'black',
//     iconName: 'sign-in',
//     tooltip: '살펴보기',
//     onItemClicked: onClicked
//   };

//   // iconButtons.push(editButton);
//   if ((role === 'ROLE_ADMIN' || role === 'ROLE_OPERATOR') && !quitYn) {
//     iconButtons.push(userLoginButton);
//   }

//   return iconButtons;
// }

export default UserListItem;