import * as React from 'react';
import { ConfigProvider } from 'react-avatar';
import { hot } from 'react-hot-loader/root';

// components
import RootContainer from 'containers/RootContainer';

const App = () => {

  return (
    <ConfigProvider colors={['red', 'pink', 'blue']}>
      <RootContainer />
    </ConfigProvider>

  );
};

export default hot(App);