import React, { SyntheticEvent, useState } from 'react';
// Components
import { Label, Icon } from 'semantic-ui-react';
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'

interface IProps {
  category : any[];
  data: any;
  setPopupMode: Function;
}

const FaqListItem: React.FC<IProps> = (props) => {

  const { category, data, setPopupMode } = props;
  const { faqId, faqType, faqTitle, userId, regDt, viewYn } = data;
  
  const categoryValueToText = (value: string) => {
    const filtered = category.filter(item => item.value === value)
    return filtered.length ? filtered[0].text : '';
  };

  const onButtonClicked = (e: SyntheticEvent, item: any) => {
    const id = (item && item.data) || undefined;
    const name = item.name;
    if (id) {
      setPopupMode({
        open: true,
        mode: name,
        faqId: faqId
      });
    }
  };

  const onDetial = (no:any) => {
    setPopupMode({
      open: true,
      mode: 'detail',
      faqId: no
    });
  };

  const tilte = <>
    <div style={{font:'inherit', textAlign:'left'}}>
      <a href='#' onClick={() => onDetial(faqId)}>
        {faqTitle}     
      </a>
    </div>
  </>;
  const viewState = viewYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const regDate = regDt !== undefined ? new Date(regDt).toLocaleDateString() : '';
  const tableListItemsProps: ITableListItemProps = {
    id: faqId,
    items: [ setLabel(categoryValueToText(faqType)), tilte, viewState, userId, regDate ],
    iconButtons: getButtons(onButtonClicked)
  };
  
  return (
    <>
      <TableListItem {...tableListItemsProps} />
    </>
  )
}

const setLabel = (labelName: string) => {
  const color = 'red'
  return (
    <>
      <Label color={color} ribbon style={{ fontSize: '0.75rem', width: '100px' }}>{labelName}</Label>
    </>
  )
};

const getButtons = (onClicked: any) => {
  const iconButtons: ITableListItemIconButton[] = [];
  const editButton: ITableListItemIconButton = {
    color: 'green',
    iconName: 'edit',
    tooltip: 'Edit Faq',
    onItemClicked: onClicked
  };
  const deleteButton: ITableListItemIconButton = {
    color: 'red',
    iconName: 'delete',
    tooltip: 'Delete Faq' ,
    onItemClicked: onClicked
  }  
  iconButtons.push(editButton);  
  iconButtons.push(deleteButton);

  return iconButtons;
};

export default FaqListItem;