import React, { useState, SyntheticEvent } from 'react';

import { Select, Form, Message } from 'semantic-ui-react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

// Interfaces
import { IInsertQnaParams, initInsertQnaParams, initRequestParams } from '../interfaces';


interface IProps {
  category: any[];
  user: any;
  open: boolean;
  setRequestParams: any;
  onCreateQna: Function;
  onRequest: Function;
  onClose(): void;
  showFeedback: Function;
}

const UserModify: React.SFC<IProps> = (props) => {

  const { category, user, open, setRequestParams, onCreateQna, onRequest, onClose, showFeedback } = props;


  // const { userId, qnaCategoryType, qnaTitle, requestEmail, qnaContents } = data;
  let insertQnaParams : IInsertQnaParams = {  
    ...initInsertQnaParams
  }

  const onWriteQna = (e: SyntheticEvent, item: any) => {    
    const id = (item && item.data) || undefined;
    if (id) {
      onCreateQna({
        body : {
          ...insertQnaParams
        }, 
        callback : (succeeded: boolean, res: any) => {
          if(succeeded){
            onRequest(initRequestParams);
            setRequestParams(initRequestParams);
          }            
        }
      });
      showFeedback('Question is registered');
    }
    onClose();
  }

  const onChangeParams = (event: React.SyntheticEvent<HTMLElement, Event>, data: any) =>{
    //console.log('data : ', data);
    insertQnaParams = {
      ...insertQnaParams,
      [data.name]: data.value
    };
    // console.log('insertQnaParams :: ', insertQnaParams);
  }
  return (
    <BasePopup
      open={open}
      id={user.uid}
      size='tiny'
      type={PopupTypes.DIALOG}
      title='QnA 등록'
      buttons={[
        {}, { onClick: onWriteQna }
      ]}
      onClose={onClose}
    >
      <Form size='tiny'>
        <Form.Select
          fluid
          label='분류'
          name='qnaCategoryType'
          options={category}
          placeholder='선택'
          onChange={onChangeParams}
        />
        <Form.Input
          label='제목'
          name='qnaTitle'
          placeholder='제목 내용을 적어주세요'
          onChange={onChangeParams}
        />
        <Form.Input
          label='Email'
          name='requestEmail'
          placeholder='mail@mail.com'
          onChange={onChangeParams}
        />
        <Form.TextArea
          label='내용'
          name='qnaContents'
          placeholder='내용을 적어주세요'
          onChange={onChangeParams}
        />
      </Form>
    </BasePopup>
  );
}

export default UserModify;