import React, { useEffect, useState } from 'react';

// Hooks
import useBasics from 'hooks/useBasics';
import useSalonon from 'hooks/useSalonon';


// Components
import SalonOnList from './SalonOnList';
import {IRequestDsonListParams, initRequestDsonListParams} from './interfaces'
import { convertCommonCode } from 'util/common';


const SalonOn: React.FC = () => {

  const { basics } = useBasics();
  const { commonCodeList } = basics;
  const { salonOn, requestDsonList } = useSalonon();
  const { salonOnData } = salonOn;
  const [ requestParams, setRequestParams ] = useState<IRequestDsonListParams>(initRequestDsonListParams);
  
  useEffect(() => {
    onRequest();
  }, []);

  const onRequest = (params? : any) => {
    let reqParams = null;

    if(params){
      reqParams = params;
      
    }else{     
      reqParams = initRequestDsonListParams;
    }

    setRequestParams(reqParams)
    requestDsonList({
      queryParameters: reqParams
    });
  }

  // console.log('salonOnData : ', salonOnData);
  
  return (
    <>
      <SalonOnList 
        category={convertCommonCode(commonCodeList.salon_on_type)} 
        page={salonOnData.page} 
        list={salonOnData.list} 
        requestParams={requestParams} 
        setRequestParams={setRequestParams} 
        onRequest={onRequest} 
      />
    </>
  )
}

export default SalonOn;