import React, { useEffect, useMemo, useState } from 'react';
// Hooks
import useFaq from 'hooks/useFaq';
// Components
import { Form, Header, Input, Button, Select, Grid } from 'semantic-ui-react';
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
import Ckeditor4 from 'components/common/ckeditor4';
// Interface
import { IFaqInsertParams, initIFaqInsertParams } from '../interface';
import useBasics from 'hooks/useBasics';
import { convertCommonCode } from 'util/common';

interface IProps {
  faqId: number;
  faqCode: any[];
  open: boolean;
  onClose(): void;
  showFeedback: Function;
}
const FaqWrite: React.FC<IProps> = (props) => {

  const { faqId, faqCode, open, onClose, showFeedback } = props;
  const { requestGetFaq, requestFaqCreate, requestFaqModify } = useFaq();
  const { basics } = useBasics();
  const { user } = basics;
  const userId = user != undefined ? user.userId : '';
  const [ faqParams, setFaqParams ] = useState<IFaqInsertParams>(initIFaqInsertParams);
  const { commonCodeList  : { service_type } } = useBasics().basics

  useEffect(() => {
    if (faqId > 0) {
      const payload: any = {
        pathVariables: { faqId },
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            const result = res.response.data;
            setFaqParams({
              ...faqParams,
              serviceType: result.serviceType,
              faqContents: result.faqContents,
              faqTitle: result.faqTitle,
              faqType: result.faqType,
              viewYn: result.viewYn,
              id: result.faqId,
            })
          }
        }
      }
      requestGetFaq(payload);
    }
  }, []);

  const handleChange = (e:React.SyntheticEvent<HTMLElement, Event>, data:any) => {
    let value = null;
    if (data.type === 'checkbox') {
      value = data.value === 'true' ? false : true;
    }
    else {
      value = data.value;
    }
    setFaqParams({
      ...faqParams,
      [data.name]: value
    });
  };

  const handleEtidorChange = (data:any) => {
    setFaqParams({
      ...faqParams,
      faqContents: data
    });
  };

  // 저장 및 수정
  const onConfirm = () => {
    if (faqId > 0) {
      // 수정 모드
      const payload: any = {
        //pathVariables: { faqId },
        body: {
          serviceType: faqParams.serviceType,
          faqContents: faqParams.faqContents,
          faqTitle: faqParams.faqTitle,
          faqType: faqParams.faqType,
          viewYn: faqParams.viewYn,
          id: faqParams.id,
        },
        callback: (succeeded: boolean, res:any) => {
          if(succeeded){
            showFeedback('FAQ 수정 완료되었습니다.');
          }
          else {
            showFeedback('FAQ 수정 실패하였습니다.');
          }
        }
      }
      requestFaqModify(payload);
    }
    else {
      // 저장 모드
      const payload: any = {
        body: { 
          serviceType: faqParams.serviceType,
          faqContents: faqParams.faqContents,
          faqTitle: faqParams.faqTitle,
          faqType: faqParams.faqType,
          viewYn: faqParams.viewYn,
        },
        callback: (succeeded: boolean, res:any) => {
          if(succeeded){
            showFeedback('FAQ 등록 완료되었습니다.');
          }
          else {
            showFeedback('FAQ 등록 실패하였습니다.');
          }
        }
      }
      requestFaqCreate(payload);
    }
    onClose();
  };

  const popupHeaderName = faqId === 0 ? "FAQ 등록" : "FAQ 수정";
  const editor = useMemo(() => 
    faqId === 0 ?
      <Ckeditor4 
        url="FAQ"
        setContents={handleEtidorChange}
        height={500}
      />
    : faqId > 0 && faqParams.faqContents ?
      <Ckeditor4 
        url="FAQ"
        contents={faqParams.faqContents}
        setContents={handleEtidorChange}
        height={500}
      />
    : null
  , [faqParams]);

  return (
    <>
      <BasePopup
        id={1}
        open={open}
        size="small"
        type={PopupTypes.DIALOG}
        title={popupHeaderName}
        buttons={[
          {}, { onClick: onConfirm }
        ]}
        onClose={onClose}
      >
        <Form>
          <Form.Group widths='equal'>
            <Form.Select
              label="카테고리"
              name="faqType"
              placeholder="선택"
              value={faqParams.faqType}
              options={faqCode}
              onChange={handleChange}
            />
            <Form.Select
              label="서비스"
              name="serviceType"
              placeholder="선택"
              value={faqParams.serviceType}
              options={[
                //{text:'전체', value:"COMMON"},
                ...convertCommonCode(service_type),
              ]}
              onChange={handleChange}
            />
            <Form.Checkbox 
              label='게시글 노출 여부' 
              value={String(faqParams.viewYn)}
              checked={faqParams.viewYn}
              name='viewYn'
              onChange={handleChange}
              style={{ float: 'right' }}
            />
          </Form.Group>
          <Form.Input
            label='제목'
            name='faqTitle'
            placeholder='공지사항 제목을 입력해주세요.'
            content={faqParams.faqTitle}
            value={faqParams.faqTitle}
            onChange={handleChange}
          />
          내용
          {editor}
        </Form>
      </BasePopup>
    </>
  )
}
export default FaqWrite;