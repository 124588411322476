import React, { useEffect, useState } from 'react'
// lib
import { makeStyles } from '@material-ui/core';
import Flatpickr from "react-flatpickr";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import 'flatpickr/dist/themes/material_blue.css';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 180,
  },
  monthButton: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  }
}))

export const formattedDate = (date: ISearchDate, dataFormat: 'basic' | 'time'): string => {
  const dateFormat = dataFormat === 'time' && date.hour && date.minute ?
    `${date.year}-${fillZero(2, date.month)}-${fillZero(2, date.day)}T${fillZero(2, date.hour)}:${fillZero(2, date.minute)}`
    : `${date.year}-${fillZero(2, date.month)}-${fillZero(2, date.day)}`;

  return dateFormat
}

const fillZero = (width: number, value: number) => {
  const str = value.toString();
  return str.length >= width ? str:new Array(width-str.length+1).join('0') + str;
}

export const toDateObject = (date: Date, addDay?:number): ISearchDate => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate() + (addDay ? addDay : 0),
    hour: date.getHours(),
    minute: date.getMinutes(),
  }
}

interface ISearchDate {
  year: number;
  month: number;
  day: number;
  hour?: number;
  minute?: number;
}

const getDate = (date: Date, formatDate?: string) => {
  if (formatDate) {
    const temp = formatDate?.split("-");
    return {
      year: Number(temp[0]),
      month: Number(temp[1]),
      day: Number(temp[2])
    }
  }
  
  return {
    year: date ? date.getFullYear() : 0,
    month: date ? date.getMonth() + 1 : 0,
    day: date ? date.getDate() : 0
  }
}

interface IProps {
  type: 'startDate' | 'endDate';
  dataFormat: 'basic' | 'time';
  date: string | null;
  onChangeDate: (key: 'startDate' | 'endDate', value: string) => void;
  disabled?: boolean;
}

const DatePick: React.FC<IProps> = (props) => {

  const { type, dataFormat, date, onChangeDate, disabled } = props;
  
  const classes = useStyles();
  const dateFormat = toDateObject(date? new Date(date) : new Date());

  const [ searchDate, setSearchDate ] = useState<ISearchDate>(dateFormat)

  const onSearchDateChanged = (event: any) => {
    const changeDate = toDateObject(event[0]);

    setSearchDate(changeDate);
    onChangeDate(type, formattedDate(changeDate, dataFormat));
  }

  return (
    <Flatpickr
      localName="ko"
      value={formattedDate(searchDate, dataFormat)}
      onChange={onSearchDateChanged}
      onClose={onSearchDateChanged}
      options={{
        mode: "single",
        enableTime: dataFormat !== 'basic',
        locale: "ko",
        dateFormat: dataFormat === 'basic' ? 'Y-m-d' : 'Y-m-d H:i',
      }}
      render={
        ({defaultValue, value, ...props}, ref) => {
          return (
            <TextField
              id="date"
              disabled={disabled}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={defaultValue}
              inputRef={ref}
              InputProps={{
                endAdornment: 
                  <InputAdornment position="end" style={{ zIndex: 4, position: 'relative'}} >
                    <TodayIcon />
                  </InputAdornment>
              }}
            />
          )
        }
      }
    />
  )
}

export default DatePick
