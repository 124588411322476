import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/hotKeyword500";

const useHotKeyword = () => {
  const dispatch = useDispatch();
  const hotKeyword500 = useSelector((state: StoreState) => state.hotKeyword500);
  const requestHotKeyword500 = () => {
    dispatch(action.requestHotKeyword());
  };
  const requestHotkeyword500Recollect = (data: any) => {
    dispatch(action.requestHotKeywordRecollect(data));
  };

  return {
    hotKeyword500,
    requestHotKeyword500,
    requestHotkeyword500Recollect,
  };
};

export default useHotKeyword;
