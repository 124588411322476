import styled from "styled-components";

const UserMembershipsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 5px;

  width: 100%;
`

const UserMembershipWrap = styled.div`
  width: 49%;
  display: flex;

  flex-direction: column;
  padding: 20px;

  //border: 1px solid rgba(34,36,38,.1);
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;

  border-radius: 10px;

  & + & {
    margin-left: 10px;
  }
`

const UserMembershiInfo = styled.div`
  width: 390px;
  min-height: 165px;
  border: 1px solid #D8D8D8;
  //box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  border-radius: 10px;
`

const MembershipTitleWrap = styled.div`
  min-height: 75px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
`

const MembershipTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`

interface ITextProps {
  size: string
  weight: number
  color: string
}

const Text = styled.span<ITextProps>`
  font-size: ${({ size }) => size};
  font-weight: ${({ size }) => size};
  color: ${({ color }) => color};
`

const MembershipInfo = styled.span`
  padding: 5px;
  color: #FFF;
  background: #00D085;

  width: fit-content;
`

const UseDate = styled.span`
  color: #55585C;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.7px;
`

const ShowReviewrayProducts = styled.span`
  margin-top: 25px;
  font-size: 13px;
  font-weight: 300;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

export const UserMembershipStyles = {
  UserMembershipsWrap,
  UserMembershipWrap,
  UserMembershiInfo,
  MembershipTitleWrap,
  MembershipTitle,
  Text,
  MembershipInfo,
  UseDate,
  ShowReviewrayProducts,
}