import styled from 'styled-components';

const Container = styled.div `
  margin: 15px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
`;
const Text = styled.p`
  font-size: 1.2em;
  font-weight: 600;  
  color: #333;
  margin-top: 10px;
  margin-bottom: 0px;
`;
const Article = styled.article`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 10px;
`
const Button = styled.div`
  background-color: white;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 30px;
  margin: 30px 5px 0 5px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  width: 150px; 
  height: 35px; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap; /* 텍스트를 한 줄로 표시 */

  &:hover {
    background-color: #BAD3E2;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const SelectedButton = styled.div`
background-color: #BAD3E2;
color: black;
border: 1px solid rgba(0, 0, 0, 0.12);
padding: 10px 30px;
margin: 30px 5px 0 5px;
border-radius: 8px;
cursor: pointer;
font-size: 15px;
width: 150px; 
height: 35px; 
display: flex;
align-items: center;
justify-content: center;
text-align: center;
white-space: nowrap; /* 텍스트를 한 줄로 표시 */
`;

export const IndexStyles = {
    Container,
    ButtonContainer,
    Text,
    Article,
    Button,
    SelectedButton,
}