import React, { useEffect, useState } from 'react';

// Hooks
import useGuide from 'hooks/useGuide';
import useBasics from 'hooks/useBasics';

// Components
import GuideList from './guideList';

const Guide: React.FC = () => {

	const { basics } = useBasics();
	const { commonCodeList } = basics;
	const { guide, requestGuideList } = useGuide();
	const { guideList } = guide;

	// api call
	useEffect(() => requestGuideList(), []);

	return (
		<GuideList 
			list={guideList} 
			// typeList={commonCodeList.use_guide}
			refresh={requestGuideList} 
		/>
	)

}

export default Guide;