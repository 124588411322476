import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/reviewConfirm";

const useReviewConfirm = () => {
  const dispatch = useDispatch();

  const reviewConfirm = useSelector(
    (state: StoreState) => state.reviewConfirm
  );

  const requestGetReviewConfirmStatusCheck = (payload: any) => {
    dispatch(action.getReviewDataConfirmStatusCheck(payload));
  };

  const requestGetReviewConfirmFirstCollect = (payload: any) => {
    dispatch(action.getReviewDataConfirmFirstCollect(payload));
  };

  const requestGetReviewConfirmDailyCollect = (payload: any) => {
    dispatch(action.getReviewDataConfirmDailyCollect(payload));
  };

  const recollectReviewConfirmProduct = (payload: any) => {
    dispatch(action.recollectReviewConfirmProduct(payload));
  };

  const recollectReviewConfirmCompare = (payload: any) => {
    dispatch(action.recollectReviewConfirmCompare(payload));
  };

  return {
    reviewConfirm,
    requestGetReviewConfirmStatusCheck,
    requestGetReviewConfirmFirstCollect,
    requestGetReviewConfirmDailyCollect,
    recollectReviewConfirmProduct,
    recollectReviewConfirmCompare,
  };
};

export default useReviewConfirm;
