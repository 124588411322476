import React from 'react';

// Components
import TableItemDetail, { ITableItemProps } from 'components/common/nextTable/TableItemDetail';
import { Chip, Typography } from '@material-ui/core';

interface IProps {
  faqType: string;
  data: any;
  onToList: Function;
}

const FaqDetail: React.FC<IProps> = (props) => {

  const { faqType, data, onToList } = props;

  const titleDiv = (
    <>
      <Chip
        label={faqType}
        color="primary"
        variant="outlined"
        size="small"
      />
      <Typography variant="h5" style={{ textAlign: 'center', marginLeft: 10 }}>{data.faqTitle}</Typography>
    </>
  )

  const detailaData: ITableItemProps = {
    titleDiv: titleDiv,
    content: data.faqContents,
    regDt: data.regDt,
    onToList
  }

  return (
    <TableItemDetail data={detailaData} />
  )
}

export default FaqDetail;