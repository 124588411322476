import React from 'react';

import { Container, Table, Header, Button, Icon, TableProps, IconProps, Pagination, Input, Select, Loader, Checkbox } from 'semantic-ui-react'

// import { Select, InputLabel, MenuItem } from '@material-ui/core';

import { TableListStyles as Styled } from './styles/TableListStyle.styles';
import { TDemoItemType } from 'components/demo/interface';
import Sort from '../button/Sort';

export interface ITableListButton {
  name: string;
  icon: IconProps['name'];
  onButtonClicked(): void;
}

export interface ISelectBox {  
  name: string;
  value?: any;
  defaultValue?: any;
  options: {
    value: any;
    text: any;
  }[];
  onChange: Function;
}

export interface ICheckBox {
  name: string;
  value?: any;
  options: {
    value: any;
    text: any;
  }[];
  onChange: Function;
  defaultValues?: string[];
}

export interface IFindTextBox {  
  name: string;
  value?: string;
  onChange: Function;
  onSearch: Function;
}

export interface IPagination {  
  name: string;
  activePage: number;
  totalPage: number;  
  onChange: Function;  
}

export interface ITableListProps {
  width?: string;
  title?: string;
  viewTitle?: boolean;
  size?: TableProps['size'];
  color?: TableProps['color'];
  buttons?: ITableListButton[];
  buttonPosition?: 'header' | 'footer'
  headers: any[];
  headerSort?: any[];
  headerWidth? : number[];
  finder?: IFindTextBox;
  select?: ISelectBox;
  select2?: ISelectBox;
  checkbox?: ICheckBox;
  pagination?: IPagination;
  count: number;
  refresh?: () => void;
  refreshNotFlag?: boolean;
  loader?: boolean;
  isLoading?: boolean;
  tBodyPosition?: string;
}

const TableList: React.FC<ITableListProps> = (props) => {

  const { width, title, viewTitle, size, color, buttons, buttonPosition = 'footer', headers, headerSort, headerWidth, finder, select, select2, checkbox, pagination, count, refresh, refreshNotFlag, children, loader, isLoading, tBodyPosition } = props;
  
  const finderHeader = finder ? (    
    <Input 
      name={finder.name} 
      size='mini' 
      style={{marginRight:'8px'}} 
      icon={{name :'search', circular: true, link: true, onClick: ()=>finder.onSearch('Clicked') }}
      placeholder='Search...'
      value={finder.value} 
      onKeyPress={finder.onSearch} 
      onChange={(event: React.ChangeEvent<HTMLInputElement>, data: any) => finder.onChange(event, data)} 
    />      
  ) : null

  

  const selectHeader = select ? (
    <Select 
      id="header-select-box" 
      name={select.name}
      placeholder="select..." 
      value={select.value} 
      // defaultValue={select.defaultValue} 
      options={select.options} 
      onChange={(event : React.SyntheticEvent<HTMLElement, Event>, data : any)=>select.onChange(event, data)} 
    />
  ) : null;

  const select2Header = select2 ? (
    <Select 
      id="header-select-box2" 
      name={select2.name}
      placeholder="select..." 
      value={select2.value} 
      // defaultValue={select.defaultValue} 
      options={select2.options} 
      onChange={(event : React.SyntheticEvent<HTMLElement, Event>, data : any)=>select2.onChange(event, data)} 
    />
  ) : null;

  const checkboxHeader = checkbox? (
    <Styled.ChekcboxWrap>
      {checkbox.options.map(option => <Checkbox 
        id={`header-check-box-${option.value}`} 
        name={checkbox.name}
        value={option.value} 
        label={option.text}
        checked={checkbox.defaultValues?.includes(option.value)}
        onClick={(event : React.SyntheticEvent<HTMLElement, Event>, data : any)=>checkbox.onChange(data)} 
      />)}
    </Styled.ChekcboxWrap>
  ) : null;
  
  
  const tableHeader = headers ? headers.map((header, index) => {
    if (headerSort && headerSort.length > 0) {
      const sort = headerSort.find(hs => hs.header === header)

      if (sort) {
        return (
          <Table.HeaderCell key={index} style={{width: null}}>
            <Styled.HeaderTitleWrap>
              <span>{header}</span>
              <Styled.SortWrap>
                <Sort type='ASC' selected={sort.state} handler={sort.handler}/>
                <Sort type='DESC' selected={sort.state} handler={sort.handler}/>
              </Styled.SortWrap>
            </Styled.HeaderTitleWrap>
          </Table.HeaderCell>
        )
      }
    }
    return <Table.HeaderCell key={index} style={{width: null}}>{header}</Table.HeaderCell>
  }) : null; 

  // 테이블 헤더 부분의 폭 넓이 조정
  if(headerWidth && tableHeader){
    const widthSum = headerWidth.reduce((sum, value) => sum + value);
    // console.log('widthSum : ', widthSum);
    headerWidth.map((width, index) => (tableHeader[index].props.style.width = (width/widthSum * 100)+'%'))
  }
  
  const headerButtons = buttons && buttonPosition === 'header' ? buttons.map((button: ITableListButton, index) => (
    <Button
      key={index}
      icon
      labelPosition='left'
      compact
      size='tiny'
      onClick={button.onButtonClicked}
    >
      <Icon name={button.icon} />{button.name}
    </Button>
  )) : null;
    
  const titleHeader = title && viewTitle ? (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Header as='h5' style={{ marginBottom: 0 }}>{title}</Header>
      {headerButtons}
    </div>
  ) : null;

  const footerButtons = buttons && buttonPosition === 'footer' ? buttons.map((button: ITableListButton, index) => (
    <Button
      key={index}
      icon
      labelPosition='left'
      compact
      size='tiny'
      onClick={button.onButtonClicked}
    >
      <Icon name={button.icon} />{button.name}
    </Button>
  )) : null;

  const tableFooter = (
    <Table.Row verticalAlign='middle'>
      <Table.HeaderCell colSpan={headers.length}>
        <Styled.FooterDiv>
          <span>total: {count} item(s)</span>
          <span style={{float:'right'}}>
          {footerButtons}
          </span>
        </Styled.FooterDiv>
      </Table.HeaderCell>
    </Table.Row>
  );

  const footerPagination = pagination ? (    
    <div style={{marginTop:'18px', textAlign:'center'}}>
      <Pagination 
        name={pagination.name}
        size='mini'
        activePage={pagination.activePage}
        totalPages={pagination.totalPage} 
        ellipsisItem={'...'} 
        onPageChange={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: any) => pagination.onChange(event, data)}
        pointing 
      />
    </div>
  ) : null;


  const refreshIcon = refresh && refreshNotFlag === undefined ? (
    <Icon link name='refresh' onClick={refresh} />
  ) : null;


  const loaderComp = loader && isLoading && (
    <Styled.Loader>
      <Loader active />
    </Styled.Loader>
  )

  return (
    <Container style={{ width: width || '90%' }}>
      {titleHeader}
      <div style={{display:'flex', justifyContent:'space-between', margin:'0.5em 0 0 0'}}>
        <span style={{verticalAlign:'center' , display:'flex', columnGap:'5px'}} >
          {selectHeader}
          {select2Header}
          {checkboxHeader}
        </span>
        <span style={{verticalAlign:'center' , float:'right'}} >
          {finderHeader}
          {/* <Icon link name='refresh' onClick={refresh} />       */}
          {refreshIcon}
        </span>
      </div>    
      <Table size={size} color={color} selectable>
        <Table.Header >
          <Table.Row textAlign='center'>
            {tableHeader}
          </Table.Row>
        </Table.Header>

        <Styled.TableBody setPosition={tBodyPosition}>
          {loaderComp}
          {count !== 0 ? children : (<Table.Cell colSpan={headers.length} textAlign='center'>검색결과가 없습니다.</Table.Cell>)}
        </Styled.TableBody>

        <Table.Footer>
          {tableFooter}
        </Table.Footer>
      </Table>
      {footerPagination}
    </Container>
  )
}

export default TableList;