import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/event';
import { ActionPayload } from 'store/modules/types';

const useEvent = () => {

  const dispatch = useDispatch();

  const events = useSelector((state: StoreState) => state.event);

  // List
  const requestEventList = (data: any) => {
    dispatch(action.getEventList(data));
  }

  // Detail
  const requestEventDetail = (payload: ActionPayload) => {
    dispatch(action.getEventDetail(payload));
  }

  // Delete
  const requestEventDelete = (payload: ActionPayload) => {
    dispatch(action.deleteEvent(payload));
  }

  // Insert
  const requestEventInsert = (payload: ActionPayload) => {
    dispatch(action.writenevent(payload));
  }

  // Update
  const requestEventUpdate = (payload: ActionPayload) => {
    dispatch(action.modifyevent(payload));
  }
  
  return {
    events,
    requestEventList,
    requestEventDetail,
    requestEventDelete,
    requestEventInsert,
    requestEventUpdate
  }
}

export default useEvent;