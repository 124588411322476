import styled, { css } from 'styled-components'

interface IStyleProps {
  fontWeight?: number;
  flex?: number;
  align?: string;
  defaultItem?: boolean;
  gap?: string;
  alignLeftItemCount?: number;
  fontSize?: string;
  itemDivide?: boolean;
  titleText?: boolean;
  fillBg?: boolean;
  width?: string;
  select?: boolean;
}

const Wrap = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const List = styled.ul<IStyleProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

  ${({ titleText }) => titleText ? css`
    & li:nth-child(2) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  ` : css`
    & li:nth-child(1) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  `}
`

const ListItem = styled.li<IStyleProps>`
  width: 100%;
  display: flex;
  padding: ${({ gap }) => gap ? `${gap} 20px` : '5px 20px'};
  border-radius: 5px 5px 0 0;
  justify-content: center;
  background-color: ${({ fillBg }) => fillBg && 'rgba(0, 0, 0, 0.08)'};
  ${({ fontWeight }) => fontWeight && css`
    & span {
      font-weight: ${fontWeight};
    }
  `};

  & span {
    text-align: center;
    justify-content: center;
  }

  ${({ defaultItem, alignLeftItemCount }) => !defaultItem && alignLeftItemCount && css`
    & span:nth-child(-n+${alignLeftItemCount}) {
      text-align: left;
    } 
  `}

  ${({ itemDivide, gap }) => itemDivide && css`
    & span:nth-child(n+2) {
      height: 100%;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }  
  `}
`

const Text = styled.span<IStyleProps>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  width: ${({ width }) => width && width};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  flex: ${({ flex }) => flex && flex};
  padding-top: ${({ gap }) => gap && gap};
  padding-bottom: ${({ gap }) => gap && gap};

  ${({ fontWeight }) => fontWeight && css`
    font-weight: ${fontWeight};
  `};

  ${({ titleText }) => titleText && css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  `};
`

export const ListStyles = {
  Wrap,
  List,
  ListItem,
  Text,
}
