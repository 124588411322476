import React from 'react';

// Components
import TableList, { ITableListProps, ITableListButton } from 'components/common/table/TableList';
import TermListItem from './TermListItem';

interface IProps {
  list: any[];
  refresh(): void;
}

const TermList: React.FC<IProps> = (props) => {

  const { list, refresh } = props;

  const onAddButtonClicked = () => {
    console.log('onAddUserButtonClicked');
  };

  const buttons: ITableListButton[] = [
    {
      name: 'Add Terms',
      icon: 'add',
      onButtonClicked: onAddButtonClicked
    }
  ];

  const headers = ['제목', '우선순위', '필수여부', '사용여부', '적용일자', '등록일자', ''];

  const tableListProps: ITableListProps = {
    title: '약관 관리',
    size: 'small',
    color: 'violet',
    buttons,
    headers,
    count: list.length,
    refresh
  }

  const termItems = list.map((term) => (
    <TermListItem
      key={term.uid}
      term={term}
    />
  ));

  return (
    <TableList {...tableListProps}>
      {termItems}
    </TableList>
  )
}

export default TermList;