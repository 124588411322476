import { useDispatch, useSelector } from 'react-redux';
// Modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/userLogs';

const useUserLogs = () => {
  const dispatch = useDispatch();
  const userLogs = useSelector((state: StoreState) => state.userLogs);

  // Get List
  const requestUserLogs = (data: any) => {
    dispatch(action.requestUserLogs(data));
  }

  return {
    userLogs,
    requestUserLogs,
  }
}

export default useUserLogs;
