import { action } from "store/modules/trendNews";

import { useDispatch } from "react-redux";
import { StoreState } from "store/modules/types";
import { useSelector } from "react-redux";
// modules

const useTrendNews = () => {
  const dispatch = useDispatch();
  const trendNews = useSelector((state: StoreState) => state.trendNews);
  const requestTrendNews = (data: any) => {
    dispatch(action.requestTrendNews(data));
  };
  const requestRecollectTrendNews = (data: any) => {
    dispatch(action.requestRecollectTrendNews(data));
  };
  return {
    trendNews,
    requestTrendNews,
    requestRecollectTrendNews,
  };
};
export default useTrendNews;
