import React, { SyntheticEvent } from 'react';

import { Table, Icon, IconProps, Popup } from 'semantic-ui-react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      pointerEvents: 'none',
    },
  }),
);

export interface ITableListItemIconButton {
  name?: string;
  color?: IconProps['color'];
  iconName: IconProps['name'];
  tooltip?: string;
  showTooltip?: boolean | undefined;
  onItemClicked(e: SyntheticEvent, item: any): void;
}

export interface ITableListItemProps {
  id?: number;
  titleNumber?: number;
  items: any[];
  flex?: number[];
  firstItemAlign?: 'center' | 'left' | 'right';
  iconButtons?: ITableListItemIconButton[];
  rowKey?: string;
  onRowSelectedEvent?: Function;
}

const TableListItem: React.FC<ITableListItemProps> = (props) => {

  const { id, items, flex, firstItemAlign, iconButtons, rowKey, onRowSelectedEvent } = props;

  const itemList = items.map((item: any, index) => (
    <Table.Cell style={flex && { flex: flex[index] }} key={index} verticalAlign='middle' textAlign={firstItemAlign ? firstItemAlign : index === 0 ? 'left' : 'center'}>{item}</Table.Cell>
  ));

  const iconButtonList = iconButtons ?
    iconButtons.map((iconButton: ITableListItemIconButton, index) => (
      <Popup
        key={index}        
        trigger={
          <Icon
            style={{ padding: '0 15px', margin: 0 }}
            link
            color={iconButton.color}
            name={iconButton.iconName}
            onClick={iconButton.onItemClicked}
            data={id}
          />
        }
        open={iconButton.showTooltip}
        content={iconButton.tooltip}
        inverted
        style={{ fontSize: '0.75rem', padding: '4px 8px' }}
      />
    )) : null;

  return (
    <Table.Row textAlign='center' id={rowKey} onClick={onRowSelectedEvent} style={rowKey !== undefined ? { cursor: 'pointer' } : null}  >
      {itemList}
      {
        iconButtonList ? (
          <Table.Cell verticalAlign='middle'>
            {iconButtonList}
          </Table.Cell>
        ) : null
      }

    </Table.Row>
  )
}

export default TableListItem;