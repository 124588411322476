import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import { TrendKeywordState } from './types';
import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
    requestTrendKeyword: createAction(actionTypes.GET_DATA_CONFIRM_TREND_KEYWORDS),
    requestTrendKeywordSuccess: createAction(actionTypes.SUCCEEDED_GET_DATA_CONFIRM_TREND_KEYWORDS),
    requestTrendKeywordFailed: createAction(actionTypes.FAILED_GET_DATA_CONFIRM_TREND_KEYWORDS),
    requestRecollectTrendKeyword: createAction(actionTypes.RECOLLECT_TREND_KEYWORD),
    requestRecollectTrendKeywordSuccess: createAction(actionTypes.SUCCEEDED_RECOLLECT_TREND_KEYWORD),
    requestRecollectTrendKeywordFailed: createAction(actionTypes.FAILED_RECOLLECT_TREND_KEYWORD)
}

const initialState: TrendKeywordState = {
    keywordData: {
        size: 0,
        list: []
      }  
};

const reducer = handleActions<TrendKeywordState>(
    {
        [actionTypes.SUCCEEDED_GET_DATA_CONFIRM_TREND_KEYWORDS]: (state, action): TrendKeywordState => {
        return {
            ...state,
            keywordData: action.payload.keywordData
        }
            },
    },
    initialState
);

const requestTrendKeywordEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_DATA_CONFIRM_TREND_KEYWORDS,
        success: (res: AjaxResponse) => action.requestTrendKeywordSuccess({ keywordData: res.response.data }),
        fail: actionTypes.FAILED_GET_DATA_CONFIRM_TREND_KEYWORDS
      },
      url: API_URL.TREND_KEYWORD,
      method: AjaxMethods.GET
    }
    return epicService.request(actions$, reqParams);
    ;
  }
  

  const requestRecollectTrendKeywordEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.RECOLLECT_TREND_KEYWORD,
        success: (res: AjaxResponse) => action.requestRecollectTrendKeywordSuccess({}),
        fail: actionTypes.FAILED_RECOLLECT_TREND_KEYWORD
      },
      url: API_URL.TREND_KEYWORD,
      method: AjaxMethods.POST
    }
    return epicService.request(actions$, reqParams);
    ;
  }

  const epic = combineEpics(
    requestTrendKeywordEpic,
    requestRecollectTrendKeywordEpic
  );

  export{action, epic};
  export default reducer;


