import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { Form, Button } from 'semantic-ui-react';



const PopupDiv = styled(Container)`
  &&& {
    margin-top: 10px;
  }
`;


const PopupImageDiv = styled(Container)`
  &&& {
    padding: 0;
  }
`;

const PopupImageDropzonDiv = styled(Container)`
  &&& {
    padding: 0;
    position: relative;
    
  }
  .dzu-previewContainer {    
    display: block !important;
    padding: 0;
    .dzu-previewImage {
      max-height: 100% !important;
      max-width: 100% !important;
      overflow: initial !important;
      width: 600px;
      height: 600px;
    }
    .dzu-previewStatusContainer {
      justify-content: center;
      margin-top: 15px;
    }
  }
  .link-area {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
`;

const LinkAreaContainer = styled(Container)`
  &&& {    
    border: 1px solid #efefef;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;


const LinkAreaItem = styled.div`
  &&& {
    border: 0;
    border-top: 1px solid #efefef;
    padding-top: 5px;
    margin-bottom: 20px;
  }
`

const LinkAreaDiv = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    padding: 0;
    .form-input-axis {
      margin: 0;
      .ui.input {
        input {
          width: inherit;
        }
      }
    }
    .form-input-axis:nth-child(n+2) {
      margin-left: 10px
    }
  }
`;


const AddLinkButton = styled(Button)`
  &&&& {
    padding: 0;
    width: 20px;
    height: 20px;
    background: lightblue;
  }
`

const DeleteLinkButton = styled(Button)`
  &&&& {
    padding: 0;
    width: 20px;
    height: 20px;
    background: #db2828;
  }
`

const colorBoxSpan = styled.span`
  &&& {
    display: inline-block;
    width: 40px;
    height: 13px;
    margin-left: 10px;
    opacity: 0.3;
  }
`

const CommentSpan = styled.span`
  font-size: 14px;
  color: #8b8b8b;
`

export const NoticeWriteStyles = {
  PopupDiv,
  PopupImageDiv,
  PopupImageDropzonDiv,
  LinkAreaContainer,
  LinkAreaItem,
  LinkAreaDiv,
  AddLinkButton,
  DeleteLinkButton,
  colorBoxSpan,
  CommentSpan,
}