export interface IRequestEventParams {
  page: number;
  size: number;
  title: string;
  startDate: string;
  endDate: string;
}

export const initIRequestEventParams : IRequestEventParams = {
  page: 1,
  size: 10,
  title: '',
  startDate: '',
  endDate: ''
}

export interface IPopupParams {
  eventNo: number;
  open: boolean;
  mode: string;
}

export const initIPopupParams : IPopupParams = {
  eventNo: 0,
  open: false,
  mode: ''
}

export interface IPopupObject {
  popupNo: number | null,
  typeNo: number | null,
  startDt: string;
  endDt: string;
  popupDateList: any[];
  popupImageName: string;
  popupImagePath: string;
  //popupNo?: number;
  type: string;
  //typeNo?: number;
  viewYn: boolean;
  detail: string;
  widthValue: number;
  heightValue: number;
  topValue: number;
  leftValue: number;
  loginYn: boolean;
  location: string;
  popupLinkRanges: IPopupLinkRanges[];
}

export interface IEventDetailParams {
  completeYn: boolean;
  contents: string;
  endDate: string;
  eventImageName: string;
  eventImagePath: string;
  startDate: string;
  title: string;
  topView: boolean;
  type: string;
  viewYn: boolean;
  winnerYn: boolean;
  eventDateList: any[];
  popupYn: boolean;
  loginYn: boolean;
  popups: IPopupObject[];  
}

export interface IEventInsertParams {
  completeYn: boolean;
  contents: string;
  endDate: string;
  eventImageName: string;
  eventImagePath: string;
  startDate: string;
  title: string;
  topView: boolean;
  type: string;
  viewYn: boolean;
  winnerYn: boolean;
  eventDateList: any[];
  popupYn: boolean;
  loginYn: boolean;
  popupSaveDtos: IPopupObject[];  
}

export interface IPopupLinkRanges {
  popupLinkRangeId?: number | null;
  heightValue: number;
  leftValue: number;
  topValue: number;
  url: string;
  widthValue: number;
}

export const initPopupLinkRanges: IPopupLinkRanges = {
  heightValue: 600,
  leftValue: 0,
  topValue: 0,
  url: '',
  widthValue: 600,
}

export const initIEventInsertParams : IEventInsertParams = {
  completeYn: false,
  contents: '',
  endDate: '',
  eventImageName: '',
  eventImagePath: '',
  startDate: '',
  title: '',
  topView: false,
  type: '',
  viewYn: true,
  winnerYn: false,
  eventDateList: [],
  popupYn: false,
  loginYn: false,  
  //popupSaveDtos: [],
  popupSaveDtos: [{
    popupNo: null,
    typeNo: null,
    detail: '',
    endDt: '',
    popupDateList: [],
    popupImageName: '',
    popupImagePath: '',
    startDt: '',
    type: '',
    viewYn: false,
    widthValue: 600,
    heightValue: 600,
    topValue: 0,
    leftValue: 0,
    loginYn: false,
    location: 'ALL',
    popupLinkRanges: [
      {
        popupLinkRangeId: null,
        widthValue: 600,
        heightValue: 600,
        leftValue: 0,
        topValue: 0,
        url: '',    
      }
    ],
  }],
}

export interface IPopupListItem {
  popupNo?: number;
  type: string;
  detail: string;
  typeNo?: number;
  popupImagePath: string;
  popupImageName: string;
  startDt: string;
  endDt: string;
  widthValue: number;
  heightValue: number;
  topValue: number;
  leftValue: number;
  loginYn: boolean;
  location: string[];
  popupLinkRanges: IPopupLinkRanges[];
}


export interface IEventDetailData {
  eventNo: number;
  title: string;
  contents: string;
  updateId: string | null;
  regId: string;
  type: string;
  startDate: string;
  endDate: string;
  regDt: string;
  updateDt: string;
  viewCount: number;
  viewYn: boolean;
  completeYn: boolean;
  topView: boolean;
  winnerYn: boolean;
  preTitle: string;
  preEventNo: number;
  nextTitle: string | null;
  nextEventNo: string | null;
  eventImageName: string;
  eventImagePath: string;
  popupYn: boolean;
  loginYn: boolean;
  popups: IPopupListItem[];
}

export const initEventDetailData: IEventDetailData = {
  eventNo: 0,
  title: '',
  contents: '',
  updateId: null,
  regId: '',
  type: '',
  startDate: '',
  endDate: '',
  regDt: '',
  updateDt: '',
  viewCount: 0,
  viewYn: false,
  completeYn: false,
  topView: false,
  winnerYn: false,
  preTitle: '',
  preEventNo: 0,
  nextTitle: null,
  nextEventNo: null,
  eventImageName: '',
  eventImagePath: '',
  popupYn: false,
  loginYn: false,
  popups: [
    {
      type: '',
      detail: '',
      typeNo: 0,
      popupImagePath: '',
      popupImageName: '',
      startDt: '',
      endDt: '',
      widthValue: 0,
      heightValue: 0,
      topValue: 0,
      leftValue: 0,
      loginYn: false,
      location: [],
      popupLinkRanges: [
        {
          widthValue: 600,
          heightValue: 600,
          leftValue: 0,
          topValue: 0,
          url: '',   
          popupLinkRangeId: 0,       
        }
      ],
    }
  ]
}

export const linkAreaColor: string[] = [
  '#ff0000', // 빨간색
  '#ff7f00', // 주황색
  '#ffff00', // 노랑색
  '#008000', // 초록색
  '#0000ff', // 파란색
];

export const locationButtonTextList: {value: string, text: string}[] = [
  { value: 'IRANK', text: 'IRANK' },
  { value: 'REVIEWRAY', text: 'REVIEWRAY' },
  { value: 'ALL', text: 'ALL' },
]

// Date to String( YYYY-MM-DD )
export const getDateFormatter = (date: Date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth());
  const monthStr = month >= 10 ? month : '0' + month;
  let day = date.getDate();
  const dayStr = day >= 10 ? day : '0' + day;
  return year + '-' + monthStr + '-' + dayStr;
};

// String( YYYY-MM-DD ) to Date
export const getStringDateConvert = (dateStr: string) => {
  let result = null;
  if (dateStr.length > 0 ) {
    const yyyyMMdd = String(dateStr);
    const sYear = yyyyMMdd.substring(0,4);
    const sMonth = yyyyMMdd.substring(5,7);
    const sDate = yyyyMMdd.substring(8,10);
    result = new Date(Number(sYear), Number(sMonth)-1, Number(sDate));
  }
  return result;
}