import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/todayBestPerMarket";

const useTodayBestPerMarket = () => {
  const dispatch = useDispatch();
  const bestProductPerMarket = useSelector(
    (state: StoreState) => state.bestMarketData
  );
  const requestBestProductPerMarket = (data: any) => {
    dispatch(action.requestTodayBestPerMarket(data));
  };

  return {
    bestProductPerMarket,
    requestBestProductPerMarket,
  };
};

export default useTodayBestPerMarket;
