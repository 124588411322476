import styled from 'styled-components';
import { ListItemText } from '@material-ui/core';

const MenuItemText = styled(ListItemText)`
  &&& {
    color: #fff,
    font-size: 0.85rem
  }
`;

export const LNBMenuStyle = {
  MenuItemText
}