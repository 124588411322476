import styled from 'styled-components';

const RootDiv = styled.span`
  display: flex;
  flex: 1;
  font-size: 'unset';
  z-index: 10;
  margin-left: 24px;
`

const NavDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  color: #000;
`

const OptionDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  color: #000;
  padding-right: 24px
`

export const AppHeaderMenuStyles = {
  RootDiv,
  NavDiv,
  OptionDiv
}