import styled from 'styled-components'

const Wrap = styled.div`
  padding: 0 24px;
`

const Title = styled.h3`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
`

const PopupHeader = styled.h2`
  border-bottom: 1px solid rgba(34,36,38,.15);
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;
  padding: 20px 0px;
`

const Editor = styled.section`
  margin-bottom: 24px;
`

export const EmailTemplateWriteStyles = {
  Wrap,
  Title,
  PopupHeader,
  Editor,
}