import React, { useState, useEffect } from 'react';

import { Form, Image, TextArea, Table, Icon } from 'semantic-ui-react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

// Hooks
import useSalonon from 'hooks/useSalonon';

import '../interfaces'
import { IInsertDsonParams, initInsertParams, IFiles } from '../interfaces';
// Config
import { SERVER_URL } from 'store/modules/types/epics';

interface IProps {
  category: any[];
  user: any;
  open: boolean;
  postId : number;
  onCreateQna: Function;
  onRequest: Function;
  onClose(): void;
  showFeedback: Function;
}

const SalonOnDetail: React.SFC<IProps> = (props) => {

  const { category, user, open, postId, onCreateQna, onRequest, onClose, showFeedback } = props;

  const { requestDsonDetail } = useSalonon();


  const [ insertDsonParams, setInsertDsonParams] = useState<IInsertDsonParams>({
    ...initInsertParams,
    userId: user.userId
  });

  const [ insertedTag, setInsertedTag ] = useState([]);
  const [ insertedPost, setInsertedPost ] = useState([]);

  const [ thumbnailImagePath, setThumbnailImagePath ] = useState('');
  const [ topImagePath, setTopImagePath ] = useState('');
  
  useEffect(() => {    
    if(postId !== -1){
      requestDsonDetail({
        pathVariables: {
          postsId: postId
        }, callback: (succeeded: boolean, res: any) => {
          if(succeeded){
            const data = res.response.data

            // const tagIdArray = data.map((item:any) => item.hashtagId)
            // let tagIdStr = tagIdArray.join(',');
            // console.log('data :::: ', data);            
            setInsertDsonParams({    
              ...insertDsonParams,      
              mainCategoryCode: data.categoryCode,
              mainTitle: data.mainTitle,
              subTitle: data.subTitle,
              contents: data.contents,
              thumbnailFileName: data.thumbnailFileName,
              thumbnailFilePath: data.thumbnailFilePath,
              topImageName: data.topImageName,
              topImagePath: data.topImagePath,
              iconType: data.iconType,
              postsTargetIds: data.salonRelationPosts && data.salonRelationPosts.length > 0 ? data.salonRelationPosts.map((post:any) => post.postsId) : [],
              hashTagId: changeArrayToString('hashtagId', []),
              userId: user.userId,
              salonPostsId: postId,
            });
            setInsertedPost(data.salonRelationPosts);
            setInsertedTag([]);       
            setThumbnailImagePath(SERVER_URL+data.thumbnailFilePath+data.thumbnailFileName);
            setTopImagePath(SERVER_URL+data.topImagePath+data.topImageName);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    
  }, [insertDsonParams])

  console.log('InsertDsonParams ::', insertDsonParams);

  // const changeObjectFormat
  
  const changeArrayToString = (key:any, data:any) => {
    const idArray = data.map((item:any) => item[key])
    let idStr = idArray.join(',');

    return idStr;
  }
  
  const insertedTagList = insertedTag && insertedTag.length > 0 ? insertedTag.map((item:any) => 
    <span className="tag-post-span">{item.hashtagName}</span>
    // <Button content={item.hashtagName} icon='delete' labelPosition='right' onClick={()=>onDeleteInsertedTag(item.hashtagId)} />
  ) : null

  
  // const insertedPostList = insertedPost.length > 0 ? insertedPost.map((item:any) => 
  //   <span className="tag-post-span">{setEllipsisText(item.mainTitle, 10)}</span>
  //   // <Button content={setEllipsisText(item.mainTitle, 10)} icon='delete' labelPosition='right' onClick={()=>onDeleteInsertedPost(item.postsId)} />
  // ) : null
  // console.log('insertDsonParams.postsTargetId : ', insertDsonParams.postsTargetId);
  // console.log('category : ', category);

  
  const relationPostItemlist = insertedPost && insertedPost.length > 0 ? insertedPost.map((item:any, index)=>
    <Table.Row className="row" key={index}>
      <Table.Cell className="id" style={{textAlign:'center'}}>{item.postsId}</Table.Cell>
      <Table.Cell className="title" >{setEllipsisText(item.mainTitle, 80)}</Table.Cell>
      <Table.Cell className="remove" ></Table.Cell>
    </Table.Row>
  ) : <Table.Cell style={{textAlign:'center'}}>연관 포스트 없음</Table.Cell>

  // console.log('API_URL+insertDsonParams.topImagePath+insertDsonParams.topImageName', API_URL+insertDsonParams.topImagePath+insertDsonParams.topImageName);
  
  return (
    <BasePopup
      open={open}
      id={user.uid}
      size='large'
      type={PopupTypes.CONFIRM}
      title='SalonOn 상세'
      buttons={[
        {onClick: onClose}
      ]}
      onClose={onClose}
    >
      <Form size='small'>
        <div>
          <div className="select-box-div">
            <label className="label">카테고리</label>
            <div className="select-box-text">
              {valueToText(insertDsonParams.mainCategoryCode, category)}
            </div>
          </div>

          <div className="select-box-div">
            <label className="label">썸네일 라벨</label>
            <div className="select-box-text">
              {valueToText(insertDsonParams.iconType, thumbnailLabel)}
            </div>
          </div>
        </div>
        
        <div>
          <label className="label">제목</label>      
          <TextArea 
            style={{ height: '45px', backgroundColor:'#f0f0f0', borderRadius:'0 0 0 0', margin:'0 0 1em' }} 
            value={insertDsonParams.mainTitle} 
            disabled 
          />    
        </div>
        
        <div>
          <label className="label">소개글</label>
          <TextArea 
            style={{ minHeight: '80px', backgroundColor:'#f0f0f0', borderRadius:'0 0 0 0', margin:'0 0 1em' }} 
            value={insertDsonParams.subTitle} 
            disabled 
          />            
        </div>
        
        <label className="label">썸네일 이미지</label>
        <div className="image-uploader">
          <Image src={thumbnailImagePath} size="large" centered />
        </div>

        <label className="label">상단 이미지</label>
        <div className="image-uploader">
          <Image src={topImagePath} size="large" centered />
        </div>
        
        <label className="label">내용</label>
        <div className="text-box" dangerouslySetInnerHTML={ {__html: insertDsonParams.contents}  }>   
        </div>
        
        <label className="label">해시태그</label>
        <div className="tag-post-box">
          {insertedTagList}
        </div>
              
        <label className="label">연관포스트</label>
        <div style={{overflowY:'scroll', height:'10em'}}>
          
          <Table className="relation-post-table" >
            <Table.Header>
              <Table.Row className="row">
                <Table.HeaderCell className="id">Post ID</Table.HeaderCell>
                <Table.HeaderCell className="title">제목</Table.HeaderCell>
                <Table.HeaderCell className="remove"></Table.HeaderCell>
              </Table.Row>
            </Table.Header>



            <Table.Body>
              {relationPostItemlist}
            </Table.Body>

          </Table>
        </div>
      </Form>  
    </BasePopup>
  );
}

const setEllipsisText = (text:string, length: number) => {
  let ellipsisText = '';
  if (text.length > length)
    ellipsisText = text.slice(0,length) + '...';
  else
    ellipsisText = text;

  return ellipsisText;
}

const thumbnailLabel = [
  { value: 0, text: '표시 안함'},
  { value: 1, text: 'NEW'}
]

const valueToText = (value : any, category: any[]) => {  
  const matchedItem = category.filter(item => item.value === value)
    
  return matchedItem.length > 0 ? matchedItem[0].text : 'none';  
}

export default SalonOnDetail;