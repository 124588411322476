import React, { SyntheticEvent, useState } from 'react';
// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
import { Label, Icon, Image } from 'semantic-ui-react';
import ImagePreview from '../common/imagepreview/ImagePreview'

interface IProps {
  typeList: any[];
  event: any;
  setPopupMode: Function;
}
const EventListItem: React.FC<IProps> = (props) => {
  const { typeList, event, setPopupMode } = props;
  const { eventNo, type, eventImagePath, eventImageName, title, topView, winnerYn, completeYn, viewYn, popupYn, loginYn, startDate, endDate } = event;
  const config = require('../../config/index');
  const serverUrl = config.WEB_URL.includes('localhost') ? config.DEV_URL : config.WEB_URL;

  const onButtonClicked = (e: SyntheticEvent, item: any) => {
    const id = (item && item.data) || undefined;
    const name = item.name;
    if (id) {
      setPopupMode({
        open: true,
        mode: name,
        eventNo: eventNo
      });
    }
  };

  const onDetial = (no:any) => {
    setPopupMode({
      open: true,
      mode: 'detail',
      eventNo: no
    });
  };

  const categoryValueToText = (value : string) => {
    if(typeList){
      const matchedItem = typeList.filter(item => item.value === value);
      return matchedItem.length > 0 ? matchedItem[0].text : 'none';
    }
    return 'none';
  };

  const imageBox = (path: string, name: string) => {
    const imagePath = eventImagePath + eventImageName;  
    const image = imagePath.length > 0 ? serverUrl + imagePath : '';
    return (
      <>
        <div style={{textAlign:'center'}}>
          <ImagePreview imgSrc={image}>
            <Image style={{display:''}} src={image} size='tiny' />
          </ImagePreview>
        </div>
      </>
    )
  };
  
  const titleItem = <>
    <div style={{font:'inherit', textAlign:'left'}}>
      <a href='#' onClick={() => onDetial(eventNo)}>
        {title}     
      </a>
    </div>
  </>;
  const topViewState = topView ? <Icon color='green' name='checkmark' size='large' /> : '';
  const winnerYnState = winnerYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const completeYnState = completeYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const viewState = viewYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const popupViewState = popupYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const loginYnState = loginYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const eventStartDate = startDate !== undefined ? new Date(startDate).toLocaleDateString() : '';
  const eventEndDate = endDate !== undefined ? new Date(endDate).toLocaleDateString() : '';
  const tableListItemsProps: ITableListItemProps = {
    id: eventNo,
    items: [
      setLabel(categoryValueToText(type)), 
      imageBox(eventImagePath, eventImageName),
      titleItem, 
      topViewState,
      winnerYnState,
      completeYnState,
      viewState,
      popupViewState,
      loginYnState,
      eventStartDate,
      eventEndDate
    ],
    iconButtons: getButtons(onButtonClicked)
  };
  return (
    <>
      <TableListItem {...tableListItemsProps} />
    </>
  );
}

const setLabel = (type: string) => {
  const color = 'red'
  return (
    <>
      <Label 
        color={color} 
        ribbon 
        style={{ fontSize: '0.75rem', width: '100px' }}
      >
        {type}
      </Label>
    </>
  )
}

const getButtons = (onClicked: any) => {
  const iconButtons: ITableListItemIconButton[] = [];
  const editButton: ITableListItemIconButton = {
    color: 'green',
    iconName: 'edit',
    tooltip: 'edit event',
    onItemClicked: onClicked
  };
  const deleteButton: ITableListItemIconButton = {
    color: 'red',
    iconName: 'delete',
    tooltip: 'delete event',
    onItemClicked: onClicked
  };
  iconButtons.push(editButton);
  iconButtons.push(deleteButton);
  return iconButtons;
}

export default EventListItem;