import React, { SyntheticEvent, useState } from 'react';

import { Label, LabelProps, Image, Table } from 'semantic-ui-react';
import { Link } from '@material-ui/core';

// Coponents
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
import PaymentDelete from './popup/paymentDelete';
import PaymentModify from './popup/paymentModify';

interface IProps {
    paymentList: any;
    onShowDetail(paymentNo: number): void;
    showFeedback: { (msg: string): void };
}
 
const PaymentListItem: React.SFC<IProps> = (props)  => {

    const [open, setOpen] = useState(false);
    const [popupName, setPopupName] = useState('');

    const { paymentList, onShowDetail,  showFeedback } = props;
    const { paymentNo, paymentType, price, analysisTerm, analysisMarket, productInsertCount, viewYn, useYn } = paymentList;

    const onClose = () => {
        setOpen(false);
    }

    const numberCommasReg = /\B(?=(\d{3})+(?!\d))/g;
    const numberCommaFormat = (x: number) => {
        return x.toString().replace(numberCommasReg, ',');
    };

    const onButtonClicked = (e: SyntheticEvent, item: any) => {
        console.log('onButtonClicked', e, item);
        const id = (item && item.data) || undefined;
        if (id) {
            console.log('SyntheticEvent : ', item.name );
          setPopupName(item.name);
          setOpen(true);
        }
    };

    const tableListItemProps: ITableListItemProps = {
        id: paymentNo,
        items: [ guideLabelByType(useYn, (<a href='#' onClick={()=>onShowDetail(paymentNo)}>{paymentType}</a>)), numberCommaFormat(price), analysisTerm+' 개월', analysisMarket+ ' 개', productInsertCount+ ' 개' ],
        iconButtons: getButtons(onButtonClicked)
    }

    const modify = popupName === 'edit' ? 
      <PaymentModify paymentNo={paymentNo} open={open} onClose={onClose} showFeedback={showFeedback} /> 
    : null;

    return(
        <>
            <TableListItem {...tableListItemProps} />
            {modify}
            <PaymentDelete 
                paymentNo={paymentNo}
                paymentType={paymentType}
                open={open && popupName === 'delete'}
                onClose={onClose}
                showFeedback={showFeedback}
            />
        </>
    )

}

const guideLabelByType = (data: boolean, paymentTypeData:any) => {
    let color: LabelProps['color'] = 'blue';
    let name = '';
    switch (data) {
      case true:
        color = 'red';
        name = '사용';
        break;
      case false:
        color = 'teal';
        name = '미사용';
        break;
    }
    return (
      <>
        <Label color={color} ribbon style={{ fontSize: '0.75rem' }}>{name}</Label>
        {paymentTypeData}
      </>
    )
}

const getButtons = (onClicked: any) => {
    const iconButtons: ITableListItemIconButton[] = [];
    const editButton: ITableListItemIconButton = {
      color: 'green',
      iconName: 'edit',
      tooltip: 'edit payment',
      onItemClicked: onClicked
    };
    const deleteButton: ITableListItemIconButton = {
      color: 'red',
      iconName: 'delete',
      tooltip: 'delete payment',
      onItemClicked: onClicked
    }
  
    iconButtons.push(editButton);
    iconButtons.push(deleteButton);
  
    return iconButtons;
  }


export default PaymentListItem;