import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import { KeywordMonitoringState } from './types';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
    requestKeywordMonitoring: createAction(actionTypes.GET_DATA_CONFIRM_KEYWORD_MONITORING),
    requestKeywordMonitoringSuccess: createAction(actionTypes.SUCCEEDED_GET_DATA_CONFIRM_KEYWORD_MONITORING),
    requestKeywordMonitoringFailed: createAction(actionTypes.FAILED_GET_DATA_CONFIRM_KEYWORD_MONITORING),
    requestRecollectKeywordMonitoring: createAction(actionTypes.RECOLLECT_KEYWORD_MONITORING),
    requestRecollectKeywordMonitoringSuccess: createAction(actionTypes.SUCCEEDED_RECOLLECT_KEYWORD_MONITORING),
    requestRecollectKeywordMonitoringFailed: createAction(actionTypes.FAILED_RECOLLECT_KEYWORD_MONITORING)
}

const initialState: KeywordMonitoringState = {
    keywordMonitoringData: {
        total: 0,
        failedCount: 0,
        monitoringKeywords: [],
      }  
    };

const reducer = handleActions<KeywordMonitoringState>(
  {
    [actionTypes.SUCCEEDED_GET_DATA_CONFIRM_KEYWORD_MONITORING]: (state, action): KeywordMonitoringState => {
      return {
        ...state,
        keywordMonitoringData: action.payload.keywordMonitoringData
      }
    },
  },
  initialState
  );

const requestProductMonitoringEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_DATA_CONFIRM_KEYWORD_MONITORING,
        success: (res: AjaxResponse) => action.requestKeywordMonitoringSuccess({ keywordMonitoringData: res.response.data }),
        fail: actionTypes.FAILED_GET_DATA_CONFIRM_KEYWORD_MONITORING
      },
      url: API_URL.MONITORING_KEYWORD,
      method: AjaxMethods.GET
    }
    return epicService.request(actions$, reqParams);
    ;
}

const requestRecollectMonitoringEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.RECOLLECT_KEYWORD_MONITORING,
      success: (res: AjaxResponse) => action.requestRecollectKeywordMonitoringSuccess(),
      fail: actionTypes.FAILED_RECOLLECT_KEYWORD_MONITORING
    },
    url: API_URL.MONITORING_RECOLLECT,
    method: AjaxMethods.POST
  }
  return epicService.request(actions$, reqParams);
  ;
}

const epic = combineEpics(
    requestProductMonitoringEpic,
    requestRecollectMonitoringEpic
);
export{action, epic};
export default reducer;