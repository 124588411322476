import React, { SyntheticEvent } from 'react';


import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
import { Modal, Button } from 'semantic-ui-react';

// Hooks
import guides from 'hooks/useGuide';

interface IProps {
	guide: any;
	open: boolean;
	onClose(): void;
	showFeedback: Function;
}

const GuideDelete: React.FC<IProps> = (props) => {

	const { guide, open, onClose, showFeedback  } = props;

	const { onDeleteGuide }  = guides();

	const onDeleted = () => {
		showFeedback("삭제 완료되었습니다.");
	}

	const onDeletePayment = (e: SyntheticEvent, item: any) => {
		e.isPropagationStopped();
		// const paymentNo = (item && item.data) || undefined;
		
		if (guide != undefined){
			if (guide.detailNo === 0) {
				onDeleteGuide({
					pathVariables: { 
						useGuideNo: BigInt(guide.guideNo)
					},
					callback: onDeleted()
				});
			}
			else {
				onDeleteGuide({
					pathVariables: { 
						useGuideNo: BigInt(guide.guideNo),
						useGuideDetailNo: BigInt(guide.detailNo) 
					},
					callback: onDeleted()
				});
			}
			showFeedback("삭제가 완료 되었습니다.");
			onClose();
		}
		// onCloseDelete();
	}

	return (
		<BasePopup
			open={open}
			id={guide.useGuideNo}
			size='tiny'
			type={PopupTypes.DIALOG}
			title='이용자 가이드 삭제'
			buttons={[
					{}, { onClick: onDeletePayment }
			]}
			// onClose={onCloseDelete}
			onClose={onClose}
		>
			<span>삭제하시겠습니까?</span>
		</BasePopup>
	)
}

export default GuideDelete;