import React, { useState } from 'react'
import { IDashboardPopupListProps } from './interface';
// styles
import { ListStyles as Styled } from './styles/List.styles'

const List: React.FC<IDashboardPopupListProps> = (props) => {
  const { 
    title, 
    headers, 
    contents, 
    defaultMsg, 
    flex, 
    gap, 
    alignLeftItemCount,
    boldHeader,
    headerFontSize, 
    itemFontSize,
    itemDivide,
    fillHeadLine,
  } = props;

  return (
    <Styled.Wrap>
      <Styled.List titleText={title ? true : false}>
        {title && (
          <Styled.Text fontWeight={600} gap='5px' titleText={title ? true : false}>{title}</Styled.Text>
        )}
        <Styled.ListItem fontWeight={boldHeader ? 600 : 500} gap={gap && gap} alignLeftItemCount={alignLeftItemCount} itemDivide={itemDivide} fillBg={fillHeadLine}>
          {headers.map((data, index) => (
            <Styled.Text key={`header-${data}`} flex={flex[index]} fontSize={headerFontSize && headerFontSize}>{data}</Styled.Text>
          ))}
        </Styled.ListItem>
        {contents.length > 0 ? contents.map((content, contentIndex) => (
          <Styled.ListItem key={`ListItem-${contentIndex}`} gap={gap && gap} alignLeftItemCount={alignLeftItemCount} itemDivide={itemDivide}>
            {Object.values(content).map((data, index) => {
              return (
                <Styled.Text key={`${data}${index}`} flex={flex[index]} fontSize={itemFontSize && itemFontSize}>{data}</Styled.Text>
              )
            })}
          </Styled.ListItem>
        )) : (
          <Styled.ListItem gap={gap && gap} defaultItem>
            <Styled.Text fontSize={itemFontSize && itemFontSize}>{defaultMsg}</Styled.Text>
          </Styled.ListItem>
        )}
      </Styled.List>
    </Styled.Wrap>
  )
}

export default List