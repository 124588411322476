import styled from 'styled-components';


const Container = styled.div`
  margin-bottom: 20px;  // 전체 컨테이너의 하단 여백
`;

const Text = styled.p`
  font-size: 1.1em;
  margin: 0 10px 0 0;
  color: #333;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const MonitoringInfoStyles = {
    Container,
    Text,
    CardContainer,
}