import styled from 'styled-components'

const Wrap = styled.div`
  padding: 0 24px;
`

const Title = styled.h3`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
`

const TemplateName = styled.textarea`
  border: 1px solid rgba(34,36,38,.15);
  background-color: #F0F0F0;
  width: 100%;
  padding: 10px;
  margin-bottom: 24px;
`

const PopupHeader = styled.h2`
  border-bottom: 1px solid rgba(34,36,38,.15);
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;
  padding: 20px 0px;
`

export const EmailTemplateDetailStyles = {
  Wrap,
  Title,
  TemplateName,
  PopupHeader,
}