import useCrawlLog from 'hooks/useCrawlLog'
import React, { useEffect, useState } from 'react'
import { ICrawlLogData } from 'store/modules/types'
import { RealTimeStyles as Styled } from '../styles/realTimeStyle/RealTime.style'
import { ICheckBox } from 'components/common/table/TableList'
import { Container, Table, Header, Button, Icon, TableProps, IconProps, Pagination, Input, Select, Loader, Checkbox } from 'semantic-ui-react'

const RealTime = ({ searchDate }: {searchDate: string}) => {
  const { requestCrawlLog, requestRecollectCrawlLog } = useCrawlLog()

  const allTimes = Array.from({ length: 24 }, (v, i) => i)
  const [ times, setTimes ] = useState<number[]>(allTimes)
  const [ list, setList ] = useState<ICrawlLogData[]>([])
  const [ openDetailDataIndexList, setOpenDetailDataIndexList ] = useState<number[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  useEffect(() => {
    getCrawlLog()
  }, [])

  const getCrawlLog = () => {
    setIsLoading(true)

    requestCrawlLog({
      queryParameters: {
        date: searchDate,
        times: times.length === allTimes.length ? [] : times,
      },
      callback: (succeeded: boolean, res: any) => {
        if (succeeded) {
          setList(res.response.data.list)
        }
        
        setIsLoading(false)
      }
    })
  }

  const recollectCrawlLog = (path: string) => {
    requestRecollectCrawlLog({
      queryParameters: {
        path,
      },
      callback: (succeeded: boolean, res: any) => {
        if (succeeded) {
          getCrawlLog()
        }
      }
    })
  }

  const handleOpen = (index: number) => {
    if (openDetailDataIndexList.includes(index)) {
      setOpenDetailDataIndexList(openDetailDataIndexList.filter((openIndex) => openIndex !== index))
    } else {
      setOpenDetailDataIndexList([
        ...openDetailDataIndexList,
        index
      ])
    }
  }

  const handleTime = (time: number) => {
    if (times.includes(time)) {
      setTimes(times.filter((selectedTime) => selectedTime !== time))
    } else {
      setTimes([
        ...times,
        time,
      ])
    }
  }

  const handleAllTimes = () => {
    if (times.length === allTimes.length) {
      setTimes([])
    } else {
      setTimes(allTimes)
    }
  }

  const sortedList = list.sort((a, b) => {
    if (a.status === 'FAIL' && b.status !== 'FAIL') {
      return -1;
    } else if (a.status !== 'FAIL' && b.status === 'FAIL') {
      return 1;
    }
    return 0;
  })

  return (
    <Styled.Wrap>
      <Styled.SelectTimeWrap>
        <Styled.SelectTimeTitle>
          검색 시간
          <Styled.RecollectButton onClick={getCrawlLog}>
            검색
          </Styled.RecollectButton>
        </Styled.SelectTimeTitle>
        <Styled.SelectTimeList>
          <Styled.SelectTimeItem>
            <Checkbox 
              name='전체'
              value='전체'
              label='전체'
              checked={times.length === allTimes.length}
              onClick={handleAllTimes}
            />
          </Styled.SelectTimeItem>
          {allTimes.map((time) => (
            <Styled.SelectTimeItem id={`time-${time}`}>
              <Checkbox 
                name={`${time}`}
                value={`${time}`}
                label={`${time}`}
                checked={times.includes(time)}
                onClick={() => handleTime(time)}
              />
            </Styled.SelectTimeItem>
          ))}
        </Styled.SelectTimeList>
      </Styled.SelectTimeWrap>
      <Styled.HeaderWrap>
        <Styled.Header>상태</Styled.Header>
        <Styled.Header>시간</Styled.Header>
        <Styled.Header>소요시간(밀리초)</Styled.Header>
        <Styled.Header>크롤링 url</Styled.Header>
        <Styled.Header></Styled.Header>
      </Styled.HeaderWrap>
      <Styled.List>
        {sortedList.map((data, index) => (
          <Styled.Item key={`real-time-crawl-data-${index}`}>
            <Styled.BasicDataWrap onClick={() => handleOpen(index)}>
              <Styled.Data>
                <Styled.StatusColor isSucceded={data.status === 'SUCCESS'} />
              </Styled.Data>
              <Styled.Data>{data.regDt}</Styled.Data>
              <Styled.Data>{data.timeMillisecond}</Styled.Data>
              <Styled.Data>{data.path}</Styled.Data>
              <Styled.Data>
                <Styled.RecollectButton onClick={() => recollectCrawlLog(data.path)}>
                  재수집
                </Styled.RecollectButton>
              </Styled.Data>
            </Styled.BasicDataWrap>
            {openDetailDataIndexList.includes(index) && (
              <Styled.DetailDataWrap>
                <Styled.DetailData>
                  <Styled.DetailDataTitle>request type</Styled.DetailDataTitle>
                  <Styled.DetailDataValue>{data.requestType}</Styled.DetailDataValue>
                </Styled.DetailData>
                <Styled.DetailData>
                  <Styled.DetailDataTitle>request body</Styled.DetailDataTitle>
                  <Styled.DetailDataValue>{data.requestBody}</Styled.DetailDataValue>
                </Styled.DetailData>
              </Styled.DetailDataWrap>
            )}
          </Styled.Item>
        ))}
        {!isLoading && list.length === 0 && (
          <Styled.NoData>데이터가 없습니다.</Styled.NoData>
        )}
      </Styled.List>
    </Styled.Wrap>
  )
}

export default RealTime