import React, { useState } from 'react';

import { Button, Modal } from 'semantic-ui-react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

// Hooks
import useBasics from 'hooks/useBasics';
import useSalonon from 'hooks/useSalonon';

// Components
import TableList, { ITableListProps, ITableListButton, IFindTextBox, IPagination, ISelectBox } from 'components/common/table/TableList';
import SalonOnListItem from './SalonOnListItem';
import SalonOnDetail from './popup/SalonOnDetail';
import SalonOnWrite from './popup/SalonOnWrite';
import TagList, { TagPopupTypes } from './popup/TagList'

// interface
import { IRequestDsonListParams } from './interfaces'
import { IPage } from 'store/modules/types';

interface IProps {
  category: {
    text: any;
    value: any;
  }[];
  page: IPage;
  list: any[];
  requestParams: IRequestDsonListParams; 
  setRequestParams: Function;
  onRequest: Function;
}

const SalonOnList: React.FC<IProps> = (props) => {
  
  const { category, page, list, requestParams, setRequestParams, onRequest } = props;

  const { basics } = useBasics();
  const { user } = basics; 
  const { requestDeleteDson } = useSalonon();

  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ resultMsg, setResultMsg] = useState('');

  const [ isDeleted, setIsDeleted ] = useState(false);

  const [ openWrite, setOpenWrite] = useState(false);
  const [ openDetail, setOpenDetail] = useState(false);
  const [ openPostId, setOpenPostId] = useState(-1);
  const [ openTagManage, setOpenTagManage ] = useState(false);

  
  const onCloseSnackbar = () => {
    // console.log("onCloseSnackbar");
    setSnackbarOpen(false);
    setResultMsg('');
  }

  const onCloseWrite = () => {
    setOpenWrite(false);
    setOpenPostId(-1);
  }

  const onCloseDetail = () => {
    setOpenDetail(false);
    setOpenPostId(-1);
  }
  
  const onDelete = (id:number) => {
    requestDeleteDson({
      pathVariables:{id:id},
      callback : (succeeded:any, res:any) => {
        if(succeeded){
          setResultMsg('Item is deleted');
          setSnackbarOpen(true);
          onRequest(requestParams);
        }
      }
    })
  }
  
  const onAddButtonClicked = () => {
    setOpenWrite(true);
  };

  const onOpenTagManage = () => {
    setOpenTagManage(true);
  }

  const onCloseTagManage = () => {
    setOpenTagManage(false);
  }

  
  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, getData: any) => {    
    onRequest({
      ...requestParams, 
      [getData.name]: getData.activePage
    });
  }
    
  const onChange = (event : React.ChangeEvent<HTMLInputElement>, getData: any) => {
    // console.log('data :: ', getData);
    setRequestParams({
      ...requestParams,
      [event.target.name] : event.target.value
    });
  }
  
  const onChangeSelect = (event : React.SyntheticEvent<HTMLElement, Event>, getData : any) => {        
    let selected = '';
    if(getData !== undefined && getData !== null ){
      selected = getData.value === 'ALL' ? '' : getData.value
    }
    // console.log('getData : ', getData);
    // console.log('selected : ', selected);

    onRequest({
      ...requestParams, 
      [getData.name]: selected
    });      
  }

  const onSearch = (event : any) => {  
    if(event.key === 'Enter' || event === 'Clicked'){
      onRequest({
        ...requestParams,
        page: 1,
      });
    }
  }

  
  const buttons: ITableListButton[] = [
    {
      name: '해시태그관리',
      icon: 'add',
      onButtonClicked: onOpenTagManage
    },
    {
      name: '등록하기',
      icon: 'add',
      onButtonClicked: onAddButtonClicked
    }
  ];


  const headers = ['', '썸네일', <div className="title">제목</div>, '작성자', '수정일', '등록일', '조회수', ''];
  const headerWidth = [1, 1.3, 5, 1, 1, 1, 1, 1];

  const select : ISelectBox = {
    name: 'categoryCode',
    value: requestParams.categoryCode === '' ? 'ALL' : requestParams.categoryCode,
    // defaultValue: 'ALL',
    options: [{value:'ALL', text:'전체'}].concat(category),
    onChange: onChangeSelect
  }

  const finder : IFindTextBox = {
    name: 'mainTitle',
    value: requestParams.mainTitle,
    onChange: onChange,
    onSearch: onSearch    
  }
    
  const pagination : IPagination = {
    name: 'page',
    activePage: requestParams.page,
    totalPage: page.totalPages,
    onChange: onChangePage, 
  }
  
  const tableListProps: ITableListProps = {
    title: 'Salon On 관리',
    size: 'small',
    color: 'violet',
    headers,
    headerWidth,
    select,
    finder,
    buttons,
    pagination,
    count: page.totalElements,
    refresh: ()=>onRequest()
  }
  
  const onShowDetail = (id: number) => { 
    setOpenPostId(id);
    setOpenDetail(true);
  }

  const onEditDetail = (id : number) => {
    setOpenPostId(id);
    setOpenWrite(true);
  }
  // console.log('openPostId ', openPostId);
  
  const salonItems = list.map((item:any) => (
    <SalonOnListItem
      key={item.qnaId}  
      category={category}
      loginUser={user}
      requestParams={requestParams}
      data={item}
      onRequest={onRequest}
      onEditDetail={onEditDetail}
      onShowDetail= {onShowDetail}
      setIsDeleted= {setIsDeleted}
      onDelete={onDelete}
      showFeedback={(msg) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  ));

  const salonWrite = openWrite ?
    <SalonOnWrite
      category= {category}
      user= {user}
      open= {openWrite}
      postId = {openPostId}
      onRequest={onRequest}
      onCreateQna= {()=>{alert('create')}}
      onClose= {onCloseWrite}
      showFeedback= {(msg:string) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    /> : null

    

  const salonDetail = openDetail ?
    <SalonOnDetail
      category= {category}
      user= {user}
      open= {openDetail}
      postId = {openPostId}
      onRequest={onRequest}
      onCreateQna= {()=>{alert('create')}}
      onClose= {onCloseDetail}
      showFeedback= {(msg:string) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    /> : null

  const tagManagePopup = openTagManage ?  
    <TagList 
      user= {user}
      open= {openTagManage}
      onCreateQna= {()=>{alert('create')}}
      onClose= {onCloseTagManage}
      type={TagPopupTypes.MANAGE}
    /> : null

  return (
    <>
      <TableList {...tableListProps}>        
        {salonItems}        
      </TableList>
      
      {salonWrite}
      {salonDetail}              
      {tagManagePopup}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}

const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default SalonOnList;