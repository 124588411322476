export interface IRequestMarketParams {
  page: number;
  size: number;
  searchName: string;
  searchType: string;
}

export const initRequestMarketListParams : IRequestMarketParams = {
  page: 1,
  size: 10,
  searchName: '',
  searchType: ''
}

export interface IPopupParams {
  marketId: string;
  open: boolean;
  mode: string;
}

export const initIPopupParams: IPopupParams = {
  marketId: '',
  open: false,
  mode: ''
}

export interface IInsertMarketParams{
  color: string;
  logo: string;
  logoLiteral: string;
  marketId: string;
  name: string;
  subName: string;
  priority: number;
  type: string;
  viewYn: boolean;
  marketLoginComment: string;
  marketLoginType: string;
  sellerLoginUrl: string;
  batchYn: boolean;
  activeYn: boolean;
  summaryType: string;
}

export const initInsertParams : IInsertMarketParams = {  
  color: '#FF0000',
  logo: '',
  logoLiteral: '',
  marketId: '',
  name: '',
  subName: '',
  priority: 0,
  type: '',
  viewYn: true,
  marketLoginComment: '',
  marketLoginType: '',
  sellerLoginUrl: '',
  batchYn: false,
  activeYn: false,
  summaryType: ''
}



