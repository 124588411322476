import React, { useEffect, useState } from "react"
import BasePopup, { PopupType, PopupTypes } from "components/common/popup/BasePopup";
import { DemoPopupStyles as Styled } from './DemoPopup.syles'
import useDemo from "hooks/useDemo";
import { IDemoUsersParam, IDetailParams, REVIEWRAY_DEMOS_STATUS, ininDemoUserParam } from "../interface";
import { convertBoolean, convertDate } from "util/common";
import DatePick, { formattedDate, toDateObject } from "../DatePick";
import { Select } from "semantic-ui-react";
// import { Select, Form, Message } from 'semantic-ui-react';

interface IProps {
  id: number;
  open: boolean;
  onClose: () => void;
  refresh: Function
}

const validation: {[key in string] : string} = {  
  userId: '데모계정',
  customerId: '데모계정',
  startDate : '시작일',
  endDate : '종료일', 
  contractProductCount : '등록가능 상품',
  contractReviewCount: '등록가능 리뷰',
}

const validationItems: {[key in string] : string} = {
  REVIEW_PRODUCT_ANALYSIS: '상품 리뷰 분석',
  REVIEW_COMPARISON: '등록가능 경쟁상품',
  REVIEW_COMPARISON_CHANGE: '경쟁상품 변경횟수',
}



const DemoDetail: React.FC<IProps> = (props) => {
  const { id, open, onClose, refresh } = props;
  const { 
    requestGetReviewDemoById,
    requestGetReviewDemoUsers,
    requestPatchReviewDemoComplete,
    requestPatchReviewDemoCancel,
  } = useDemo();
  const [ demoDetail, setDemoDetail ] = useState<IDetailParams | null>(null)
  const [ popupType, setPopupType] = useState<PopupType>(PopupTypes.DIALOG)
  const [ popupMode, setPopupMode ] = useState<'DETAIL' | 'WRITE'>('DETAIL')
  const [ demoUsers, setDemoUsers] = useState<{customerId:string, userId:string}[]>([])
  const [ params, setParams ] = useState<IDemoUsersParam>(ininDemoUserParam)

  useEffect(() => {
    if (id > 0) {
      requestGetReviewDemoById({
        pathVariables: { id },
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            setDemoDetail(res.response.data)

            if (res.response.data.status === 'WAIT') {
              setPopupMode('WRITE')
            }

            if (res.response.data.customerMembershipReview) {
              const customerMembershipReview = res.response.data.customerMembershipReview;
              const customerId = customerMembershipReview.customerId;
              const userId = demoUsers.find(user => user.customerId === customerId)?.userId;
              const membershipStartDate = customerMembershipReview.membershipStartDate;
              const membershipEndDate = customerMembershipReview.membershipEndDate;
              const contractProductCount = customerMembershipReview.contractProductCount;
              const contractReviewCount = customerMembershipReview.contractReviewCount
              const productChangeLimit = customerMembershipReview.productChangeLimit;
              const items = customerMembershipReview.items
              const newItems = params.items.map(item => {
                const fintItem = items.find((curItem:any) => curItem.item === item.itemType)
                if (fintItem) {
                  if (
                    item.itemType === 'REVIEW_INSIGHT' || 
                    item.itemType === 'REVIEW_REPORT' ||
                    item.itemType === 'REVIEW_SEARCH' 
                  ) {
                    return {
                      itemType : fintItem.item,
                      limitCount: fintItem.limitCount,
                      itemValue: "O",
                    }  
                  }
                  return {
                    itemType : fintItem.item,
                    limitCount: fintItem.limitCount,
                    itemValue: `${fintItem.limitCount}`,
                  }
                }

                return item
              })

              setParams({
                userId: userId ? userId: '',
                customerId: customerId,
                startDate: membershipStartDate,
                endDate: membershipEndDate,
                contractProductCount: contractProductCount? contractProductCount : 0,
                contractReviewCount: contractReviewCount? contractReviewCount : 0,
                productChangeCount: productChangeLimit? productChangeLimit : 0,
                items: newItems
              })
            } else {
              setParams({
                ...params,
                startDate: `${formattedDate(toDateObject(new Date()), 'basic')}`,
                endDate: `${formattedDate(toDateObject(new Date(), 14), 'basic')}`,
              })
            }
          }
        }
      });

      requestGetReviewDemoUsers({
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            setDemoUsers(res.response.data.list)
          }
        }
      })

    } else {
      console.log(" 시스템 오류입니다. ");
    }
  }, []);

  const onChangeDate = (key: "startDate" | "endDate", data: string) => {
    onChangeData(key, data)
  }

  const onChangeItem = (key:string, data:any) => {
    const currentItems = params.items
    const newItems = [
      ...currentItems.filter(item => item.itemType !== key),
      {
        itemType: key,
        limitCount: data,
        itemValue: `${data}`
      }
    ]
    onChangeData('items', newItems)
  }

  const onChangeData = (key:string, data:any) => {
    setParams({
      ...params,
      [key]: data
    })
  }


  const onComplete = () => {
    const validationTextArr:string[] = []
    Object.entries(params).forEach(param => {
      const key = param[0]
      const val = param[1]
      if (key !== 'items' && validation[key] && !val) {
        validationTextArr.push(validation[key])
      } else if (key === 'items') {
        param[1].forEach((item:any) => {
          const key = item.itemType as string
          const val = Number(item.limitCount)
          if (validationItems[key] && val < 1) {
            validationTextArr.push(validationItems[key])
          }
        })
      }
    })

    const payload: any = {
      pathVariables: {
        path1: id,
      },
      body:params,
      callback: (succeeded: boolean, res: any) => {
        if(succeeded && res.response.code === 'OK'){
          alert('데모 계정 발급이 완료되었습니다.')
          refresh()
          onClose()
        } else {
          alert('오류가 발생하였습니다.')
        }
      },

    }

    if (!validationTextArr.length) {
      requestPatchReviewDemoComplete(payload)
    } else {
      alert(`${validationTextArr.join(',')} 값을 입력해주세요.`)
    }
  }

  const onCancel = () => {
    if (window.confirm('선택된 데모신청건을 취소처리 하시겠습니까')) {
      const payload: any = {
        pathVariables: {
          path1: id,
        },
        body:params,
        callback: (succeeded: boolean, res: any) => {
          if(succeeded && res.response.code === 'OK'){
            alert('취소처리 완료 하였습니다.')
            refresh()
            onClose()
          } else {
            alert('오류가 발생하였습니다.')
          }
        },
  
      }
      requestPatchReviewDemoCancel(payload)
    }
  }

  const onModify = () => {
    setPopupMode('WRITE')
  }

  const detailView = !!demoDetail ?
    <>
      <Styled.Wrap>
        <Styled.Table>
          <colgroup>
            <col width={'13%'} />
            <col width={'20.33%'}/>
            <col width={'13%'} />
            <col width={'20.33%'}/>
            <col width={'13%'} />
            <col width={'20.33%'}/>
          </colgroup>
          <tr>
            <td>데모신청일</td>
            <td>{demoDetail.regDt}</td>
            <td>마케팅수신동의</td>
            <td>{convertBoolean(demoDetail.marketingAgreementYn)}</td>
            <td>계정발급상태</td>
            <td>{REVIEWRAY_DEMOS_STATUS[demoDetail.status]}</td>
          </tr>
          <tr>
            <td>상호/법인명</td>
            <td>{demoDetail.company}({demoDetail.department})</td>
            <td>이메일</td>
            <td>{demoDetail.email}</td>
            <td>연락처</td>
            <td>{demoDetail.phone}</td>
          </tr>
          <tr>
            <td>담당자명</td>
            <td>{demoDetail.name}</td>
            <td>부서</td>
            <td>{demoDetail.department}</td>
            <td>직책</td>
            <td>{demoDetail.position}</td>
          </tr>
          <tr>
            <td>스토어정보</td>
            <td colSpan={5}><a href={demoDetail.storeUrl}>{demoDetail.storeUrl}</a></td>
          </tr>
          <tr>
            <td>문의사항</td>
            <td colSpan={5}>{demoDetail.comment}</td>
          </tr>
        </Styled.Table>
      </Styled.Wrap>
    </> 
  : null;

  const demoView = !!demoDetail ?
    <>
      <Styled.Wrap>
        <Styled.Table>
          <colgroup>
            <col width={'13%'} />
            <col width={'20.33%'}/>
            <col width={'13%'} />
            <col width={'20.33%'}/>
            <col width={'13%'} />
            <col width={'20.33%'}/>
          </colgroup>
          <tr>
            <td></td>
            <td></td>
            <td>이용기간</td>
            <td colSpan={3}>시작일 : {demoDetail.membershipStartDate ? convertDate(demoDetail.membershipStartDate) : ''} ~ 종료일 : {demoDetail.membershipEndDate ? convertDate(demoDetail.membershipEndDate) : ''}</td>
          </tr>
          <tr>
            <td>등록가능 상품</td>
            <td>{params.contractProductCount}</td>
            <td>등록가능 리뷰수</td>
            <td>{params.contractReviewCount}</td>
            <td>상품 변경횟수</td>
            <td>{params.productChangeCount}</td>
          </tr>
          <tr>
            <td>상품 리뷰 분석</td>
            <td>
              {params.items.find(item => item.itemType === 'REVIEW_PRODUCT_ANALYSIS')?.itemValue}
            </td>
            <td>등록가능 경쟁상품</td>
            <td>
              {params.items.find(item => item.itemType === 'REVIEW_COMPARISON')?.itemValue}
            </td>
            <td>경쟁상품 변경횟수</td>
            <td>
              {params.items.find(item => item.itemType === 'REVIEW_COMPARISON_CHANGE')?.itemValue}
            </td>
          </tr>
        </Styled.Table>
      </Styled.Wrap>
    </> 
  : null;

  const demoModify = !!demoDetail ?
    <>
      <Styled.Wrap>
        <Styled.Table>
          <colgroup>
            <col width={'13%'} />
            <col width={'20.33%'}/>
            <col width={'13%'} />
            <col width={'20.33%'}/>
            <col width={'13%'} />
            <col width={'20.33%'}/>
          </colgroup>
          <tr>
            <td>데모계정</td>
            <td>
              <Select 
                placeholder='Select userId' 
                options={demoUsers.map(user => ({text:user.userId, value:user.customerId, key:user.userId}))} 
                value={params.customerId} 
                onChange={(e: any, data:any) => {
                  const userId = data.options && data.options.length ? data.options.find((option:any) => option.value === data.value).text : ''
                  setParams({
                    ...params,
                    userId: userId,
                    customerId: data.value
                  })
                }}
              />
            </td>
            <td>시작일</td>
            <td>
              <DatePick 
                type='startDate' 
                dataFormat='basic' 
                date={params.startDate} 
                onChangeDate={onChangeDate} 
              />
            </td>
            <td>종료일</td>
            <td>
              <DatePick 
                type='endDate' 
                dataFormat='basic' 
                date={params.endDate} 
                onChangeDate={onChangeDate} 
              />
            </td>
          </tr>
          <tr>
            <td>등록가능 상품</td>
            <td>
              <Styled.Input 
                type="number" 
                value={params.contractProductCount}
                onChange={(e) => onChangeData('contractProductCount', e.currentTarget.value)} 
              />
            </td>
            <td>등록가능 리뷰수</td>
            <td>
              <Styled.Input 
                type="number" 
                value={params.contractReviewCount}
                onChange={(e) => onChangeData('contractReviewCount', e.currentTarget.value)} 
              />
            </td>
            <td>상품 변경횟수</td>
            <td>
              <Styled.Input 
                type="number" 
                value={params.productChangeCount}
                onChange={(e) => onChangeData('productChangeCount', e.currentTarget.value)} 
              />
            </td>
          </tr>
          <tr>
            <td>상품 리뷰 분석</td>
            <td>
              <Styled.Input 
                type="number" 
                value={
                  params.items.find(item => item.itemType === 'REVIEW_PRODUCT_ANALYSIS')?.itemValue
                }
                onChange={(e) => onChangeItem('REVIEW_PRODUCT_ANALYSIS', e.currentTarget.value)} 
              />
            </td>
            <td>등록가능 경쟁상품</td>
            <td>
              <Styled.Input 
                type="number" 
                value={
                  params.items.find(item => item.itemType === 'REVIEW_COMPARISON')?.itemValue
                }
                onChange={(e) => onChangeItem('REVIEW_COMPARISON', e.currentTarget.value)} 
              />
            </td>
            <td>경쟁상품 변경횟수</td>
            <td>
              <Styled.Input 
                type="number" 
                value={
                  params.items.find(item => item.itemType === 'REVIEW_COMPARISON_CHANGE')?.itemValue
                }
                onChange={(e) => onChangeItem('REVIEW_COMPARISON_CHANGE', e.currentTarget.value)} 
              />
            </td>
          </tr>
        </Styled.Table>
      </Styled.Wrap>
    </> 
  : null;

  return (
    <>
      <BasePopup
        open={open}
        id={id}
        size='large'
        type={PopupTypes.DIALOG}
        title='데모신청 상세'
        buttons={popupMode === 'DETAIL' ? [
          {name:'닫기', onClick: onClose},          
          {name:'수정', onClick: onModify, disabled: demoDetail?.status !== 'WAIT'}
        ] : [
          {name:'닫기', onClick: onClose},
          {name:'취소처리', onClick: onCancel, disabled: demoDetail?.status !== 'WAIT'},
          {name:'수정', onClick: onComplete, disabled: demoDetail?.status === 'CANCEL' || demoDetail?.status === 'COMPLETE'}
        ]}
        onClose={onClose}
      >
        {detailView}
        {popupMode === 'DETAIL'
          ? demoView 
          : demoModify
        }
      </BasePopup>
    </>
  )
}

export default DemoDetail