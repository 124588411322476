import React from "react"
import { MemebershipUseInfoStyles as Styled } from './styles/MembershipUseInfo.styles'

interface IProps {
  title: string
  children: JSX.Element
}

const MembershipUseInfo: React.FC<IProps> = (props) => {
  const { title , children } = props
  return <Styled.Wrap>
    <Styled.Title>{title}</Styled.Title>
    <Styled.ChildrenWrap>
      {children}
    </Styled.ChildrenWrap>
  </Styled.Wrap>
}

export default MembershipUseInfo