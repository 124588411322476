import React, { useEffect, useState, FormEvent } from 'react';
import {  Header, Form, Button } from 'semantic-ui-react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

import useUsers from 'hooks/useUsers';

interface IProps {
    paymentNo: number;
    open: boolean;
    onClose(): void;
}

const PaymentDetail: React.FC<IProps> = (props) => {

    const { paymentNo, open, onClose} = props;
    const { users, requestPayment, requestCodeList } = useUsers();
    const { codeList } = users;
    const [ paymentDetail, setPaymentDetail] = useState(Object);

    /* Api Call */
    useEffect(() => { 
        requestPayment({
            pathVariables: { 'payment_no' : BigInt(paymentNo)},
            callback: onPaymentDetail
        });
        requestCodeList();
    } ,[]);
    /* Api requestPayment Call back  */
    const onPaymentDetail = (success:boolean, data:any) => {
        setPaymentDetail(data.response.data);
    }

    const functionProvidedInit = new String(paymentDetail.functionProvided);
    let functionProvidedList = functionProvidedInit.split(',');
    const functionProvidedArray = functionProvidedInit.split(',');

    const extraServiceInit = new String(paymentDetail.extraService);
    let extraServiceList:Array<string> = extraServiceInit.split(',');
    const extraServiceArray:Array<string> = extraServiceInit.split(',');
    /* Params Change Event! */
    
    const providedCheck = (data:string) => {
        let flag = false;
        for (let index = 0; index < functionProvidedList.length; index++) {
            if(functionProvidedList[index] === data){
                functionProvidedList.splice(functionProvidedList.indexOf(data), 1);
                flag = true;
                break;
            }
        }
        return flag;
    }
 
    function extraServiceCheck(data:string){
        let flag = false;
        for (let index = 0; index < extraServiceList.length; index++) {
            if(extraServiceList[index] === data){
                extraServiceList.splice(extraServiceList.indexOf(data), 1);
                flag = true;
                break;
            }
        }
        return flag;
    }

    /* Service Code */
    const functionProvidedCodeArray : [{}] = codeList.function_provided;
    const functionProvidedCode = functionProvidedCodeArray ? 
        functionProvidedCodeArray.map((data:any) => 
        <Form.Checkbox type='checkbox' label={data.text} value={data.value} checked={providedCheck(data.value)} readOnly /> 
    ) : null;

    // // Extra Service Code
    const extraServiceCodeArray : [{}] = codeList.extra_service;
    const extraServiceCode = extraServiceCodeArray ? 
        extraServiceCodeArray.map((data:any) => 
        <Form.Checkbox  label={data.text} value={data.value} checked={extraServiceCheck(data.value)} readOnly /> 
    ) : null;

    const pageTitle = '요금제 상세보기' 
    const useYnTag = <Form.Checkbox label='사용 유무' checked={paymentDetail.useYn} readOnly/> 
    const viewYnTag = <Form.Checkbox  label='게시글 노출 여부' checked={paymentDetail.viewYn} readOnly />
    const unlimitedYnTag = <Form.Checkbox label='서비스 무제한' checked={paymentDetail.unlimitedYn} readOnly />
    const paymentTypeTag = <Form.Input fluid label='요금제명' value={paymentDetail.paymentType} readOnly />
    const priceTag = <Form.Input fluid label='요금제 가격(원)' type="number" value={paymentDetail.price} readOnly />
    const analysisMarketTag = <Form.Input fluid label='분석 마켓 수(개)' type="number" value={paymentDetail.analysisMarket} readOnly />
    const analysisTermTag = <Form.Input  fluid label='데이터 분석 기간(개월)' type="number" value={paymentDetail.analysisTerm} readOnly/>
    const productInsertCountTag = <Form.Input fluid label='상품 등록 수(개)' type="number" value={paymentDetail.productInsertCount} readOnly/>
    
    return (
        <BasePopup
            open={open}
            id={0}
            size='tiny'
            type={PopupTypes.DIALOG}
            title='요금제 등록'
            buttons={[
                {}, { }
            ]}
            onClose={onClose}
        >
            <Form size='tini' >
                <Form.Group>
                    {useYnTag} 
                    {viewYnTag} 
                    {unlimitedYnTag}
                </Form.Group>
                <Form.Group widths='equal'>
                    {paymentTypeTag} 
                    {priceTag}
                </Form.Group>
                <Form.Group widths='equal'>
                    {analysisMarketTag}
                    {analysisTermTag}
                    {productInsertCountTag}
                </Form.Group>
                <Form.Group>
                    <Form.Field width={10}>* 서비스 항목</Form.Field>
                    <Form.Field width={10}>* 부가 서비스 항목</Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={10}>{functionProvidedCode}</Form.Field>
                    <Form.Field width={10}>{extraServiceCode}</Form.Field>
                </Form.Group>
            </Form>
        </BasePopup>
    )
}
export default PaymentDetail;