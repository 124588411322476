import React, { useEffect, useState } from 'react';
// Hooks
import useUsers from 'hooks/useUsers';
// Components
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
// Styles
import { UserJoinStyles as Styled } from '../styles/UserJoin.styles';
// Material-Ui
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// Lib
import JSBI from 'jsbi';
// Interface
import { 
  IPasswordParams, 
  initIPasswordParams 
} from '../interface';

interface IProps {
  user: any;
  open: boolean;
  onClose(): void;
  showFeedback: Function;
}
const UserPasswordReset: React.FC<IProps> = (props) => {

  const { user, open, onClose, showFeedback } = props;
  const { userId } = user;
  const { requestUserPasswordReset } = useUsers();
  const [ params, setParams ] = useState<IPasswordParams>(initIPasswordParams);

  const title = `비밀번호 수정(${userId})`;

  useEffect(() => {
    setParams(initIPasswordParams);
  }, [user.userId]) 

  const onBlurCheckPassword = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tagName = String([e.target.name]);

    if ('password' === tagName) {
      const newPassword = e.target.value;
      const passwordLengthReg = /^.{8,32}$/;
      let patternCnt = 0;
      const num = newPassword.search(/[0-9]/g)+1;
      const engSmall = newPassword.search(/[a-z]/g)+1;
      const engBig = newPassword.search(/[A-Z]/g)+1;
      const spe = newPassword.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi)+1;
      patternCnt = num + engSmall + engBig + spe;
      if (patternCnt <= 1 || !passwordLengthReg.test(newPassword)) {
        errorParmasChange(
          'password',
          '8-32자 영문, 대/소문자, 숫자, 특수문자(두가지 이상 혼합) 사용 가능합니다.',
          true
        );
      }
      else {
        errorParmasChange(
          'password',
          '',
          false
        );
      }

    }
    else {
      if (params.password !== params.checkPassword) {
        setParams({
          ...params,
          error: true,
          errorMessage: {
            ...params.errorMessage,
            checkPassword: '비밀번호가 일치하지 않습니다.'
          }
        });
      }
      else {
        if (params.errorMessage.checkPassword) {
          setParams({
            ...params,
            error: false,
            errorMessage: {
              ...params.errorMessage,
              checkPassword: ''
            }
          });
        }
      }
    }
  };

  const errorParmasChange = (name:string, message:string, errorFlag:boolean) => {
    setParams({
      ...params,
      error: errorFlag,
      errorMessage: {
        ...params.errorMessage,
        [name]: message
      }
    });
  };

  const onChangePassword = () => {
    if ( !params.error ) {
      if (params.password.length === 0) {
        errorParmasChange(
          'password',
          '신규 비밀번호를 입력해주세요',
          true
        );
      }
      else if (params.checkPassword.length === 0) {
        errorParmasChange(
          'checkPassword',
          '신규 비밀번호를 입력해주세요',
          true
        );
      }
      else {
        const payload: any = {
          pathVariables: { uid: JSBI.BigInt(user.uid).toString() },
          body: { 
            nweUserPw: params.password,
            checkUserPw: params.checkPassword
          },
          callback: (succeeded: boolean, res:any ) => {
            if(succeeded) {
              showFeedback("비밀번호 변경이 완료되었습니다.");
            }
            else {
              showFeedback("비밀번호 변경이 실패하였습니다.");
            }
            onClose();
          }
        }
        requestUserPasswordReset(payload);
      }
    }
  };

  

  return (
    <>
      <BasePopup
        open={open}
        id={0}
        size='tiny'
        type={PopupTypes.DIALOG}
        title={title}
        buttons={[
          {}, { onClick: onChangePassword }
        ]}
        onClose={onClose}
      >
        <form noValidate autoComplete="off" style={{ width: '100%' }}>
          <Container>
            <Styled.InputTextDiv
              required 
              fullWidth
              id="password"
              name="password"
              label="신규 비밀번호"
              type={params.showPassowrd ? 'text' : 'password'}
              placeholder="8-32자 영문, 대/소문자, 숫자, 특수문자 중에서 두가지 이상 혼합"
              value={params.password}
              error={params.errorMessage.password ? true : false}
              helperText={params.errorMessage.password || null}
              onChange={
                (e) => setParams({
                  ...params, 
                  [e.target.name]: e.target.value,
                  errorMessage: {
                    ...params.errorMessage,
                    [e.target.name]: ''
                  }
                })
              }
              onBlur={onBlurCheckPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => setParams({...params, showPassowrd: !params.showPassowrd})}
                      edge="end"
                    >
                      {params.showPassowrd ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Styled.InputTextDiv
              required 
              fullWidth
              id="checkPassword"
              name="checkPassword"
              label="신규 비밀번호 확인"
              type={params.showPassowrd ? 'text' : 'password'}
              placeholder="비밀번호를 한번 더 확인해 주세요"
              value={params.checkPassword}
              error={params.errorMessage.checkPassword ? true : false}
              helperText={params.errorMessage.checkPassword || null}
              onChange={
                (e) => setParams({
                  ...params, 
                  [e.target.name]: e.target.value,
                  errorMessage: {
                    ...params.errorMessage,
                    [e.target.name]: ''
                  }
                })
              }
              onBlur={onBlurCheckPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => setParams({...params, showPassowrd: !params.showPassowrd})}
                      edge="end"
                    >
                      {params.showPassowrd ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Container>
        </form>
      </BasePopup>
    </>
  )
}

export default UserPasswordReset;