import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "light",
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    action: {
      selected: '#3c5adb',
      hover: '#3c5adb',
      focus: 'rgba(0, 0, 0, .12)'
    },
    
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica"',
    fontSize: 12
  }
});

export default theme;