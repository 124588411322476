import { combineEpics } from 'redux-observable';
import { createAction } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

// ACTION PART
const action = {
  getDashboardUserStatus: createAction(actionTypes.GET_DASHBOARD_USER_STATUS),
  succeededGetDashboardUserStatus: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_USER_STATUS),
  failedGetDashboardUserStatus: createAction(actionTypes.FAILED_GET_DASHBOARD_USER_STATUS),

  getDashboardJoinUsers: createAction(actionTypes.GET_DASHBOARD_JOIN_USERS),
  succeededGetDashboardJoinUsers: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_JOIN_USERS),
  failedGetDashboardJoinUsers: createAction(actionTypes.FAILED_GET_DASHBOARD_JOIN_USERS),

  getDashboardVisitorUsers: createAction(actionTypes.GET_DASHBOARD_VISITORS_USERS),
  succeededGetDashboardVisitorUsers: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_VISITORS_USERS),
  failedGetDashboardVisitorUsers: createAction(actionTypes.FAILED_GET_DASHBOARD_VISITORS_USERS),

  getDashboardUserStores: createAction(actionTypes.GET_DASHBOARD_USER_STORES),
  succeededGetDashboardUserStores: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_USER_STORES),
  failedGetDashboardUserStores: createAction(actionTypes.FAILED_GET_DASHBOARD_USER_STORES),

  getDashboardAnalyzeStatus: createAction(actionTypes.GET_DASHBOARD_ANALYZE_STATUS),
  succeededGetDashboardAnalyzeStatus: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_ANALYZE_STATUS),
  failedGetDashboardAnalyzeStatus: createAction(actionTypes.FAILED_GET_DASHBOARD_ANALYZE_STATUS),

  getDashboardAnalyzeStatusResult: createAction(actionTypes.GET_DASHBOARD_ANALYZE_STATUS_RESULTS),
  succeededGetDashboardAnalyzeStatusResult: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_ANALYZE_STATUS_RESULTS),
  failedGetDashboardAnalyzeStatusResult: createAction(actionTypes.FAILED_GET_DASHBOARD_ANALYZE_STATUS_RESULTS),

  getDashboardAnalyzeMonitoringStatus: createAction(actionTypes.GET_DASHBOARD_MONITORING_STATUS),
  succeededGetDashboardMonitoringStatus: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_MONITORING_STATUS),
  failedGetDashboardMonitoringStatus: createAction(actionTypes.FAILED_GET_DASHBOARD_MONITORING_STATUS),

  getDashboardAnalyzeMonitoringStatusResults: createAction(actionTypes.GET_DASHBOARD_MONITORING_STATUS_RESULTS),
  succeededGetDashboardMonitoringStatusResults: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_MONITORING_STATUS_RESULTS),
  failedGetDashboardMonitoringStatusResults: createAction(actionTypes.FAILED_GET_DASHBOARD_MONITORING_STATUS_RESULTS),

  getDashboardQnaCount: createAction(actionTypes.GET_DASHBOARD_QNA_COUNT),
  succeededDashboardQnaCount: createAction(actionTypes.SUCCEEDED_DASHBOARD_QNA_COUNT),
  failedDashboardQnaCount: createAction(actionTypes.FAILED_DASHBOARD_QNA_COUNT), 

  requestGetDashboardUser: createAction(actionTypes.GET_DASHBOARD_USER),
  succeededGetDashboardUser: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_USER),
  failedGetDashboardUser: createAction(actionTypes.FAILED_GET_DASHBOARD_USER), 

  requestGetDashboardQna: createAction(actionTypes.GET_DASHBOARD_QNA),
  succeededGetDashboardQna: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_QNA),
  failedGetDashboardQna: createAction(actionTypes.FAILED_GET_DASHBOARD_QNA), 

  requestGetDashboardUserMore: createAction(actionTypes.GET_DASHBOARD_USER_MORE),
  succeededGetDashboardUserMore: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_USER_MORE),
  failedGetDashboardUserMore: createAction(actionTypes.FAILED_GET_DASHBOARD_USER_MORE), 

  requestGetDashboardUserLoginLogMore: createAction(actionTypes.GET_DASHBOARD_USER_LOGIN_LOG_MORE),
  succeededGetDashboardUserLoginLogMore: createAction(actionTypes.SUCCEEDED_GET_DASHBOARD_USER_LOGIN_LOG_MORE),
  failedGetDashboardUserLoginLogMore: createAction(actionTypes.FAILED_GET_DASHBOARD_USER_LOGIN_LOG_MORE),  
}

// EPIC PART
const requestDashboardUserStatusEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_USER_STATUS,
      success: (res: AjaxResponse) => action.succeededGetDashboardUserStatus(),
      fail: actionTypes.FAILED_GET_DASHBOARD_USER_STATUS
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardJoinUsersEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_JOIN_USERS,
      success: (res: AjaxResponse) => action.succeededGetDashboardJoinUsers(),
      fail: actionTypes.FAILED_GET_DASHBOARD_JOIN_USERS
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardVisitorUsersEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_VISITORS_USERS,
      success: (res: AjaxResponse) => action.succeededGetDashboardVisitorUsers(),
      fail: actionTypes.FAILED_GET_DASHBOARD_VISITORS_USERS
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardUserStoresEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_USER_STORES,
      success: (res: AjaxResponse) => action.succeededGetDashboardUserStores(),
      fail: actionTypes.FAILED_GET_DASHBOARD_USER_STORES
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardAnalyzeStatusEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_ANALYZE_STATUS,
      success: (res: AjaxResponse) => action.succeededGetDashboardAnalyzeStatus(),
      fail: actionTypes.FAILED_GET_DASHBOARD_ANALYZE_STATUS
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardAnalyzeStatusResultsEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_ANALYZE_STATUS_RESULTS,
      success: (res: AjaxResponse) => action.succeededGetDashboardAnalyzeStatusResult(),
      fail: actionTypes.FAILED_GET_DASHBOARD_ANALYZE_STATUS_RESULTS,
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardMonitoringStatusEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_MONITORING_STATUS,
      success: (res: AjaxResponse) => action.succeededGetDashboardMonitoringStatus(),
      fail: actionTypes.FAILED_GET_DASHBOARD_MONITORING_STATUS,
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardMonitoringStatusResultsEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_MONITORING_STATUS_RESULTS,
      success: (res: AjaxResponse) => action.succeededGetDashboardMonitoringStatusResults(),
      fail: actionTypes.FAILED_GET_DASHBOARD_MONITORING_STATUS_RESULTS,
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestDashboardQnaCountEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_QNA_COUNT,
      success: (res: AjaxResponse) => action.succeededDashboardQnaCount(),
      fail: actionTypes.FAILED_DASHBOARD_QNA_COUNT
    },
    url: API_URL.DASHBOARD_V2,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestGetDashboardUser = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_USER,
      success: (res: AjaxResponse) => action.succeededGetDashboardUser(),
      fail: actionTypes.FAILED_GET_DASHBOARD_USER
    },
    url: `${API_URL.DASHBOARD}/user`,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestGetDashboardQna = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_QNA,
      success: (res: AjaxResponse) => action.succeededGetDashboardQna(),
      fail: actionTypes.FAILED_GET_DASHBOARD_QNA
    },
    url: `${API_URL.DASHBOARD}/qna`,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestGetDashboardUserMore = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_USER_MORE,
      success: (res: AjaxResponse) => action.succeededGetDashboardUserMore(),
      fail: actionTypes.FAILED_GET_DASHBOARD_USER_MORE
    },
    url: `${API_URL.DASHBOARD}/user/more`,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestGetDashboardUserLoginLogMore = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DASHBOARD_USER_LOGIN_LOG_MORE,
      success: (res: AjaxResponse) => action.succeededGetDashboardUserLoginLogMore(),
      fail: actionTypes.FAILED_GET_DASHBOARD_USER_LOGIN_LOG_MORE
    },
    url: `${API_URL.DASHBOARD}/login-log/more`,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  requestDashboardUserStatusEpic,
  requestDashboardJoinUsersEpic,
  requestDashboardVisitorUsersEpic,
  requestDashboardUserStoresEpic,
  requestDashboardAnalyzeStatusEpic,
  requestDashboardAnalyzeStatusResultsEpic,
  requestDashboardMonitoringStatusEpic,
  requestDashboardMonitoringStatusResultsEpic,
  requestDashboardQnaCountEpic,

  requestGetDashboardUser,
  requestGetDashboardQna,
  requestGetDashboardUserMore,
  requestGetDashboardUserLoginLogMore,
);

export { action, epic };