import styled from 'styled-components'

const RadioButton = styled.input.attrs({ type: 'radio' })`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  cursor: pointer;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-right: 15px;
  cursor: pointer;
`

export const ToggleStyles = {
  RadioButton,
  Label,
}
