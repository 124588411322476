import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import { HotKeyword500State} from './types';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
    requestHotKeyword: createAction(actionTypes.GET_DATA_CONFIRM_HOT_KEYWORD),
    requestHotKeywordSuccess: createAction(actionTypes.SUCCEEDED_GET_DATA_CONFIRM_HOT_KEYWORD),
    requestHotKeywordFailed: createAction(actionTypes.FAILED_GET_DATA_CONFIRM_HOT_KEYWORD),
    requestHotKeywordRecollect: createAction(actionTypes.RECOLLECT_HOT_KEYWORD),
    requestHotKeywordReocollectSuccess: createAction(actionTypes.SUCCEEDED_RECOLLECT_HOT_KEYWORD),
    requestHotKeywordRecollectFailed: createAction(actionTypes.FAILED_RECOLLECT_HOT_KEYWORD),
}

const initialState: HotKeyword500State = {
    hotKeywordData: {
        startDt: '',
        endDt: '',
        startCollectedDate: '',
        latestDateTime: '',
        totalCategories: 0,
        miscollectedCategories: 0,
        collectedKeywords: 0,
        hotKeywords: [],
    }
}

const reducer = handleActions<HotKeyword500State>(
    {
      [actionTypes.SUCCEEDED_GET_DATA_CONFIRM_HOT_KEYWORD]: (state, action): HotKeyword500State => {
        return {
          ...state,
          hotKeywordData: action.payload.hotKeywordData
        }
      },
      [actionTypes.FAILED_GET_DATA_CONFIRM_HOT_KEYWORD]: (state, action): HotKeyword500State => {
        return {
          ...state
        }
      },
    },
    initialState
    );

const requestHoteKeyword500Epic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_DATA_CONFIRM_HOT_KEYWORD,
        success: (res: AjaxResponse) => action.requestHotKeywordSuccess({ hotKeywordData: res.response.data }),
        fail: actionTypes.FAILED_GET_DATA_CONFIRM_HOT_KEYWORD
      },
      url: API_URL.HOT_KEYWORD500,
      method: AjaxMethods.GET
    }
    return epicService.request(actions$, reqParams);
    ;
}

const requestHotKeywordRecollectEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.RECOLLECT_HOT_KEYWORD,
        success: (res: AjaxResponse) => action.requestHotKeywordReocollectSuccess(),
        fail: actionTypes.FAILED_RECOLLECT_HOT_KEYWORD
      },
      url: API_URL.HOT_KEYWORD500,
      method: AjaxMethods.POST
    }
    return epicService.request(actions$, reqParams);
    ;
}

const epic = combineEpics(
    requestHoteKeyword500Epic,
    requestHotKeywordRecollectEpic
)

export{action,epic};
export default reducer;