import React, { useEffect, useState } from 'react';
// Hooks
import useNotice from 'hooks/useNotice';
// Components
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
import { Form, Image } from 'semantic-ui-react';
// Styles
import { NoticeDetailStyles as Styled } from '../styles/NoticeDetail.styles';
// Config
import { SERVER_URL } from 'store/modules/types/epics';
import { initINoticeDetailData, INoticeDetailData, IPopupLinkRanges, linkAreaColor, locationButtonTextList } from '../interface';

interface IProps {
  noticeNo: number;
  noticeTypes: any[];
  open: boolean;
  onClose(): void;
}
const NoticeDetail: React.FC<IProps> = (props) => {
  
  const { noticeNo, noticeTypes, open, onClose } = props;
  const { requestGetNoticeDetail } = useNotice();
  const [ detailData, setDetailData ] = useState<INoticeDetailData>(initINoticeDetailData);
  const [ popupLinkRanges, setPopupLinkRanges ] = useState<IPopupLinkRanges[]>([]);

  useEffect(() => {
    if (noticeNo > 0) {
      const payload: any = {
        pathVariables: { noticeNo },
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            setDetailData(res.response.data);
            if (res.response.data.popups[0]) {
              setPopupLinkRanges(res.response.data.popups[0].popupLinkRanges);
            }            
          }
        }
      };
      requestGetNoticeDetail(payload);
    }
    else {
      console.log(" 시스템 오류입니다. ");
    }
  }, []);

  const categoryValueToText = (value : string) => {
    if (noticeTypes) {
      const matchedItem = noticeTypes.filter(item => item.value === value);
      return matchedItem.length > 0 ? matchedItem[0].text : 'none';
    }
    return 'none';
  };
  const noticeTypeName = detailData != undefined ? categoryValueToText(detailData.type) : "";
  const popupStartAndEndDate = detailData.popups.length > 0 ? detailData.popups[0].startDt + "~" + detailData.popups[0].endDt : "";
  const popupImage = detailData.popups.length > 0 ? SERVER_URL + detailData.popups[0].popupImagePath + detailData.popups[0].popupImageName : "";

  // 링크 영역의 폭, 높이 값이 있고, 팝업 이미지 소스가 있으면 가시화된 링크 영역 생성
  const linkArea: JSX.Element[] = popupImage.length > 0 && popupLinkRanges.length > 0 ? popupLinkRanges.map((item, index) => (
    <span 
      style={{
        position: 'absolute',
        display: 'inline-block',
        zIndex: 10,
        width: `${item.widthValue}px`,
        height: `${item.heightValue}px`,
        top: `${item.topValue}px`,
        left: `${item.leftValue}px`,
        background: linkAreaColor[index],
        opacity: '0.4',
      }} 
    />
  )) : [];

  const linkAreaArray: JSX.Element[] = popupLinkRanges.map((item, index) => {
    const widthValue: number = item.widthValue;
    const heightValue: number = item.heightValue;
    const topValue: number = item.topValue;
    const leftValue: number = item.leftValue;
    const url: string = item.url;

    return (
      <>
        <Styled.LinkLabelDiv className="link-sub-label">
          <span>링크영역 #{index+1}</span>
          <Styled.ColorBoxSpan style={{background: linkAreaColor[index]}} />
        </Styled.LinkLabelDiv>
        <Styled.LinkAreaItem>
          <Styled.LinkAreaDiv>   
            <Form.Group style={{marginTop: '10px'}}>
              <Form.TextArea 
                label="폭"
                value={widthValue}
                style={{ 
                  height: '50px', 
                  backgroundColor: 'rgb(240, 240, 240)',              
                }}
                readOnly
              />
              <Form.TextArea 
                label="높이"
                value={heightValue}
                style={{ 
                  height: '50px', 
                  backgroundColor: 'rgb(240, 240, 240)', 
                }}
                readOnly
              />
              <Form.TextArea 
                label="X좌표"
                value={leftValue}
                style={{ 
                  height: '50px', 
                  backgroundColor: 'rgb(240, 240, 240)', 
                }}
                readOnly
              />
              <Form.TextArea 
                label="Y좌표"
                value={topValue}
                style={{ 
                  height: '50px', 
                  backgroundColor: 'rgb(240, 240, 240)', 
                }}
                readOnly
              />
            </Form.Group>
          </Styled.LinkAreaDiv>
          <Form.TextArea 
            label="외부 링크"
            value={url}
            style={{ 
              height: '50px',
              backgroundColor: 'rgb(240, 240, 240)', 
            }}
            readOnly
          /> 
        </Styled.LinkAreaItem>
      </>
    );
  })

  const locationCheckboxList: JSX.Element[] = detailData.popups.length > 0 ? locationButtonTextList.map((item, index) => {
    const isChecked: boolean = detailData.popups[0].location.indexOf(item.value) !== -1;

    return (
      <Form.Checkbox 
        key={index}
        label={item.text}
        checked={isChecked}            
        readOnly
      />      
    )
  }) : [];
  
  const popupDiv: JSX.Element = detailData.popupYn ? (
    <Styled.PopupDiv>    
      <Form.Group widths='equal'>
        <Form.Checkbox 
          label='링크 클릭 시 회원가입 페이지로 이동' 
          checked={detailData.popups[0].loginYn}            
          readOnly
        />      
      </Form.Group>

      {/* 팝어 노출 위치 */}
      팝업 노출 위치 
      <Form.Group style={{padding: '0 20px'}}>
        {locationCheckboxList}
      </Form.Group>

      <Form.TextArea 
        label="팝업 시작/종료일"
        value={popupStartAndEndDate}
        style={{ 
          height: '50px', 
          backgroundColor: 'rgb(240, 240, 240)', 
          overflowY: 'scroll' 
        }}
        readOnly
      />

      팝업 이미지
      <Styled.PopupImageDiv>
        {linkArea}
        <Image src={popupImage} />
      </Styled.PopupImageDiv>
      {linkAreaArray}
    </Styled.PopupDiv>
  ) : <></>;

  const detailView = detailData != undefined ?
    <>
      <Styled.InputForm>
        <Form.Group widths='equal'>
          <Form.Checkbox 
            label='중요글 유무'
            checked={detailData.importantYn}
            readOnly
          />
          <Form.Checkbox 
            label='게시유무' 
            checked={detailData.viewYn}
            readOnly
          />
          <Form.Checkbox 
            label='회원전용' 
            checked={detailData.loginYn}
            readOnly
          />
        </Form.Group>
        <Form.TextArea 
          label="카테고리"
          value={noticeTypeName}
          style={{ 
            height: '50px', 
            backgroundColor: 'rgb(240, 240, 240)', 
            overflowY: 'scroll' 
          }}
          readOnly
        />
        <Form.Group widths='equal'>
          <Form.TextArea
            label='제목'
            value={detailData.title}
            style={{ 
              height: '50px',
              backgroundColor: 'rgb(240, 240, 240)',
              overflowY: 'scroll' 
            }}
            readOnly
          />
        </Form.Group>
        내용
        <Styled.HtmlContents
          dangerouslySetInnerHTML={{ __html: detailData.contents }}
        />
        <Form.Checkbox 
          label='팝업 노출' 
          className="form-checkbox"
          checked={detailData.popupYn}
          readOnly
        />
        {popupDiv}
      </Styled.InputForm>
    </> 
  : null;

  return (
    <>
      <BasePopup
        open={open}
        id={noticeNo}
        size='large'
        type={PopupTypes.CONFIRM}
        title='공지사항 상세'
        buttons={[
          {onClick: onClose}
        ]}
        onClose={onClose}
      >
        {detailView}
      </BasePopup>
    </>
  )
}
export default NoticeDetail;