import React, { useEffect } from 'react';

// Components
import FaqList, { IFaqReqParams, initFaqReqParams } from './FaqList';

import useBasics from 'hooks/useBasics';
import useFaq from 'hooks/useFaq';

const Faq2: React.SFC = () => {

  const { basics } = useBasics();
  const { commonCodeList } = basics;
  const { faq, requestFaqList } = useFaq();
  const { faqData } = faq;

  useEffect(() => {
    onRequest();
  }, []);

  const onRequest = (params: IFaqReqParams = initFaqReqParams) => {

    console.log('params ::', params);
    requestFaqList({
      queryParameters: params
    });
  }
  
  console.log('temp', commonCodeList.faq_type);
  console.log('temp', faqData);
  return (
    // <FaqList faqCode={faqCode} faqList={faqList} refresh={requestFaqList} />
    <>
      <FaqList faqCodes={commonCodeList.faq_type} data={faqData} onRequest={onRequest} />
    </>
  )
}

export default Faq2;