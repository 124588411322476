import styled, { css } from 'styled-components'

import { 
  Form,
  Button,
} from 'semantic-ui-react';

interface IStyleProps {
  fontWeight?: number;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.15;

  & + & {
    flex: 0.8;
  }
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
`

const ControlLabel = styled.div`
  display: flex;
  align-items: center;

  & > label > span:nth-child(2) {
    font-size: 12px;
    color: #000;
    font-weight: 700;
  }
`

const RowDiv = styled.div`
  display: flex;
  align-items: center;

`

const Text = styled.span<IStyleProps>`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: ${({ fontWeight }) => fontWeight || 500};
`

const Icon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 3px;
`

const StoreImage = styled.img`
  width: 100px;
  height: 50px;
`

const EditButtonWrap = styled.div`
  position: relative;
  padding: 0 5px;
  
`

const EditButton = styled.span`
  position: absolute;
  top: -15px;
  left: 30px;
  padding: 0 8px;
  color: #3f51b5;
  border: 1px solid #3f51b5;

  &:hover {
    cursor: pointer;
  }
`

const CancelButton = styled.span`
  position: absolute;
  top: -15px;
  left: 100px;
  padding: 0 8px;
  color: #ED0D93;
  border: 1px solid #ED0D93;

  &:hover {
    cursor: pointer;
  }
`

const ModifyButton = styled.span`
  position: absolute;
  top: -15px;
  left: 30px;
  padding: 0 8px;
  color: #3f51b5;
  border: 1px solid #3f51b5;

  &:hover {
    cursor: pointer;
  }
`

const ToggleButtonWrap = styled.div`
  width: 400px;
  position: absolute;
  top: -50px;
`

const ToggleButton = styled(Button as any)`
  &&& {
    padding: 5px 10px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    background: #fff;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
    margin: 3px 2px;
    &:hover {
      background: rgba(95, 117, 209, 0.25);
    ${(props: {active: boolean}) => 
      props.active && 
      css`
        background: rgba(95, 117, 209, 0.5);
        color: white;
        font-weight: 500;
      `      
    }
      
    }
    ${(props: {active: boolean}) => 
      props.active && 
      css`
        background: rgba(95, 117, 209, 0.5);
        color: white;
        font-weight: 500;
      `
      
    }
  }
`

export const UserInfoSummaryStyles = {
  Content,
  Title,
  ControlLabel,
  RowDiv,
  Icon,
  StoreImage,
  Text,
  EditButtonWrap,
  EditButton,
  CancelButton,
  ToggleButton,
  ToggleButtonWrap,
  ModifyButton,
}