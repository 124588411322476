import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/crawlLog";

const useCrawlLog = () => {
  const dispatch = useDispatch();

  const crawlLog = useSelector(
    (state: StoreState) => state.crawlLog
  );

  const requestCrawlLog = (payload: any) => {
    dispatch(action.requestGetCrawlLog(payload));
  };

  const requestRecollectCrawlLog = (payload: any) => {
    dispatch(action.requestRecollectCrawlLog(payload));
  };

  return {
    crawlLog,
    requestCrawlLog,
    requestRecollectCrawlLog,
  };
};

export default useCrawlLog;
