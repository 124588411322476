import useReviewConfirm from 'hooks/useReviewConfirm';
import React, { useEffect, useState } from 'react'
import { FirstCollectStyles as Styled } from './styles/FirstCollect.style'
import List from './List';
import { Checkbox } from 'semantic-ui-react';
import { IReviewConfirmProduct } from 'store/modules/types';

const FirstCollect = () => {
  const { reviewConfirm, requestGetReviewConfirmFirstCollect, recollectReviewConfirmProduct } = useReviewConfirm();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedProductIdList, setSelectedProductIdList] = useState<string[]>([])
  const ids = reviewConfirm.firstCollect.customerProducts.map((product) => product.productId)

  useEffect(() => {
    setIsLoading(true);

    requestGetReviewConfirmFirstCollect({
      callback: (succeeded: boolean, res: any) => {
        setIsLoading(false);
      }
    })
  }, [])

  const handleRecollect = () => {
    recollectReviewConfirmProduct({
      queryParameters: {
        ids: selectedProductIdList,
      },
    })
  }

  const handleAllProductSelect = () => {
    if (selectedProductIdList.length === ids.length) {
      setSelectedProductIdList([])
    } else {
      setSelectedProductIdList(ids)
    }
  }

  const handleProduct = (product: IReviewConfirmProduct) => {
    if (selectedProductIdList.find((selectedProductId) => selectedProductId === product.productId)) {
      setSelectedProductIdList(selectedProductIdList.filter((selectedProductId) => 
        selectedProductId !== product.productId
      ))
    } else {
      setSelectedProductIdList([
        ...selectedProductIdList,
        product.productId,
      ])
    }
  }

  return (
    <Styled.Wrap>
      <Styled.CardList>
        <Styled.Card>
          <Styled.CardTitle>전체 상품</Styled.CardTitle>
          <Styled.CardValue>{reviewConfirm.firstCollect.allProductCount.toLocaleString()}</Styled.CardValue>
        </Styled.Card>
        <Styled.Card>
          <Styled.CardTitle>리뷰 수집 요청</Styled.CardTitle>
          <Styled.CardValue>{reviewConfirm.firstCollect.allPurchaseReviewCount.toLocaleString()}</Styled.CardValue>
        </Styled.Card>
        <Styled.Card>
          <Styled.CardTitle>수집 완료</Styled.CardTitle>
          <Styled.CardValue>{reviewConfirm.firstCollect.allReviewCount.toLocaleString()}</Styled.CardValue>
        </Styled.Card>
        <Styled.Card>
          <Styled.CardTitle>수집 실패 상품</Styled.CardTitle>
          <Styled.CardValue>{reviewConfirm.firstCollect.allNotCollectedProductCount.toLocaleString()}</Styled.CardValue>
        </Styled.Card>
        <Styled.Card>
          <Styled.CardTitle>수집 실패 리뷰</Styled.CardTitle>
          <Styled.CardValue>{reviewConfirm.firstCollect.allNotCollectedReviewCount.toLocaleString()}</Styled.CardValue>
        </Styled.Card>
      </Styled.CardList>
      <Styled.ListTitleWrap>
        <Styled.ListTitle>{`미수집 리스트 (${reviewConfirm.firstCollect.customerProducts.length.toLocaleString()})`}</Styled.ListTitle>
        <Styled.RecollectButton onClick={handleRecollect}>재수집</Styled.RecollectButton>
      </Styled.ListTitleWrap>
      <List 
        id='first-collect-list' 
        listWidthList={
          'minmax(0, 5%) minmax(0, 8%) minmax(0, 8%) minmax(0, 8%) minmax(0, 8%)  minmax(0, 39%) minmax(0, 8%) minmax(0, 8%) minmax(0, 8%)'
        }
        titleList={[
          <Checkbox 
            name={'all'}
            value={'all'}
            label={''}
            checked={selectedProductIdList.length !== 0 && selectedProductIdList.length === ids.length}
            onClick={handleAllProductSelect}
          />,
          'customer_id',
          'product_id',
          'market_id',
          '마켓',
          '상품명',
          '수집 요청',
          '수집 완료',
          '미수집'
        ]} 
        list={reviewConfirm.firstCollect.customerProducts.map((product) => (
          <React.Fragment key={`first-collect-list-${product.productId}`}>
            <Styled.ListItemText>
              <Checkbox 
                name={`${product.productId}`}
                value={`${product.productId}`}
                label={''}
                checked={
                  !!selectedProductIdList.find((selecteProductId) => selecteProductId === product.productId)
                }
                onClick={() => handleProduct(product)}
              />
            </Styled.ListItemText>
            <Styled.ListItemText>{product.customerId}</Styled.ListItemText>
            <Styled.ListItemText>{product.productId}</Styled.ListItemText>
            <Styled.ListItemText>{product.marketId}</Styled.ListItemText>
            <Styled.ListItemText>{product.marketName}</Styled.ListItemText>
            <Styled.ListItemText align='left' isClick={true} onClick={() => window.open(product.productUrl)}>
              {product.productName}
            </Styled.ListItemText>
            <Styled.ListItemText>{product.purchaseReviewCount.toLocaleString()}</Styled.ListItemText>
            <Styled.ListItemText>{product.reviewCount.toLocaleString()}</Styled.ListItemText>
            <Styled.ListItemText>{product.notCollectedReviewCount.toLocaleString()}</Styled.ListItemText>
            </React.Fragment>
        ))} 
        isHoverEvent={false}
        isLoading={isLoading} 
      />
    </Styled.Wrap>
  )
}

export default FirstCollect