'use strict';
const os = require('os');
const hostname = os.hostname();

const NODE_ENV = process.env.REACT_APP_NODE_ENV;
if (!NODE_ENV) console.error("NODE_ENV Empty!!");

let credentials;

/**
 * Use Config by NODE_ENV
 */
console.log(NODE_ENV)
if (NODE_ENV === 'production') {
	credentials = require('./credentials.js');
	credentials.zone = "Live";
} else if (NODE_ENV === 'development') {
	credentials = require('./development_credentials.js');
	credentials.zone = "Test"
} else {
	credentials = require('./default_credentials.js');
	credentials.zone = "Local";
}

if (window && window.location)
	credentials.port = window.location.port;

console.log(
	`hostname: ${hostname} [${credentials.zone}] with pid [${process.pid}] on port [${[
		credentials.port
	].toString()}]`
);


module.exports = credentials;
