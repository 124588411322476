import React, { useState, SyntheticEvent } from 'react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

// Hooks
import useUsers from 'hooks/useUsers';

interface IProps {
  user: any;
  open: boolean;
  onClose(): void;
  showFeedback: Function;
  refresh: any;
}

const UserDelete: React.SFC<IProps> = (props) => {

  const { deleteUser } = useUsers();

  const { user, open, onClose, showFeedback, refresh } = props;

  const onDeleted = (succeeded: boolean, res: any) => {
    console.log("callback");
    let message = '실패하였습니다.';
    if (succeeded) {
      refresh();
      message = '완료되었습니다.';
    }
    showFeedback(`사용자 삭제 ${message}`);
  }

  const onDeleteUser = (e: SyntheticEvent, item: any) => {
    console.log("onDeleteUser", item);

    const id = (item && item.data) || undefined;
    console.log("onDeleteUser", id);
    if (id) {
      deleteUser({
        pathVariables: { id: BigInt(id) },
        callback: onDeleted
      });
    }

    onClose();
  }

  return (
    <BasePopup
      open={open}
      id={user.uid}
      size='tiny'
      type={PopupTypes.DIALOG}
      title={`Delete User(${user.userName})`}
      buttons={[
        {}, { onClick: onDeleteUser }
      ]}
      onClose={onClose}
    >
      <span>Are you sure want to delete user?</span>
    </BasePopup>
  );
}

export default UserDelete;