import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { GuideState, MarketState, initPage } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';


// ACTION PART
const action = {
    // getMarketList
    getMarketList: createAction(actionTypes.GET_MARKET_LIST),
    fulfilledMarketList: createAction(actionTypes.FULFILLED_MARKET_LIST),
    failedMarketList: createAction(actionTypes.FAILED_MARKET_LIST),

    getMarket: createAction(actionTypes.GET_MARKET),
    succeededGetMarket: createAction(actionTypes.SUCCEEDED_GET_MARKET),
    failedGetMarket: createAction(actionTypes.FAILED_GET_MARKET),

    createMarket: createAction(actionTypes.CREATE_MARKET),
    succeededCreateMarket: createAction(actionTypes.SUCCEEDED_CREATE_MARKET),
    failedCreateMarket: createAction(actionTypes.FAILED_CREATE_MARKET),

    deleteMarket: createAction(actionTypes.DELETE_MARKET),
    succeededDeleteMarket: createAction(actionTypes.SUCCEEDED_DELETE_MARKET),
    failedDeleteMarket: createAction(actionTypes.FAILED_DELETE_MARKET),

    modifyMarket: createAction(actionTypes.MODIFY_MARKET),
    succeededModifyMarket: createAction(actionTypes.SUCCEEDED_MODIFY_MARKET),
    failedModifyMarket: createAction(actionTypes.FAILED_MODIFY_MARKET),
  }
  
  const initialState: MarketState = {
    marketData: {
      list: [],
      page: initPage,
    },
  };
  
  const reducer = handleActions<MarketState>(
    {
      [actionTypes.GET_MARKET_LIST]: (state, action): MarketState => {
        return state
      },
      [actionTypes.FULFILLED_MARKET_LIST]: (state, action): MarketState => {
        return {
          ...state,
          marketData: action.payload.marketData
        }
      },
      [actionTypes.FAILED_MARKET_LIST]: (state, action): MarketState => {
        return {
          ...state,
        }
      },

      [actionTypes.GET_MARKET]: (state, action): MarketState => {
        return state
      },
      [actionTypes.SUCCEEDED_GET_MARKET]: (state, action): MarketState => {
        return {
          ...state,
        }
      },
      [actionTypes.FAILED_GET_MARKET]: (state, action): MarketState => {
        return {
          ...state,
        }
      },
      
      [actionTypes.CREATE_MARKET]: (state, action): MarketState => {
        return state
      },
      [actionTypes.SUCCEEDED_CREATE_MARKET]: (state, action): MarketState => {
        return {
          ...state,
        }
      },
      [actionTypes.FAILED_CREATE_MARKET]: (state, action): MarketState => {
        return {
          ...state,
        }
      },

      [actionTypes.DELETE_MARKET]: (state, action): MarketState => {
        return state
      },
      [actionTypes.SUCCEEDED_DELETE_MARKET]: (state, action): MarketState => {
        return {
          ...state,
        }
      },
      [actionTypes.FAILED_DELETE_MARKET]: (state, action): MarketState => {
        return {
          ...state,
        }
      },

      [actionTypes.MODIFY_MARKET]: (state, action): MarketState => {
        return state
      },
      [actionTypes.SUCCEEDED_MODIFY_MARKET]: (state, action): MarketState => {
        return {
          ...state,
        }
      },
      [actionTypes.FAILED_MODIFY_MARKET]: (state, action): MarketState => {
        return {
          ...state,
        }
      },

    },
    initialState
  );
  
  // EPIC PART
  // const requestMarketListEpic = (actions$: any) => {
  //   const reqParams: IEpicReqParams = {
  //     actions: {
  //       request: actionTypes.GET_MARKET_LIST,
  //       success: (res: AjaxResponse) => action.fulfilledMarketList({ marketData: res.response.contents }),
  //       fail: actionTypes.FAILED_MARKET_LIST
  //     },
  //     url: API_URL.MARKET,
  //     method: AjaxMethods.GET
  //   };
  //   return epicService.request(actions$, reqParams);
  // }

  const requestMarketListEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_MARKET_LIST,
        success: (res: AjaxResponse) => action.fulfilledMarketList({ marketData: res.response.data }),
        fail: actionTypes.FAILED_MARKET_LIST
      },
      url: API_URL.CODE_MARKET,
      method: AjaxMethods.GET
    };
    return epicService.request(actions$, reqParams);
  }

  const requestGetMarketEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_MARKET,
        success: (res: AjaxResponse) => action.succeededGetMarket(),
        fail: actionTypes.FAILED_GET_MARKET
      },
      url: API_URL.MARKET,
      method: AjaxMethods.GET
    };
    return epicService.request(actions$, reqParams);
  }

  const requestCreateMarketEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.CREATE_MARKET,
        success: (res: AjaxResponse) => action.getMarketList({ marketData: res.response.contents }),
        fail: actionTypes.FAILED_CREATE_MARKET
      },
      url: API_URL.MARKET,
      method: AjaxMethods.POST
    };
    return epicService.request(actions$, reqParams);
  }

  const requestDeleteMarketEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.DELETE_MARKET,
        success: (res: AjaxResponse) => action.getMarketList({ marketData: res.response.contents }),
        fail: actionTypes.FAILED_DELETE_MARKET
      },
      url: API_URL.MARKET,
      method: AjaxMethods.DELETE
    };
    return epicService.request(actions$, reqParams);
  }

  const requestModifyMarketEpic = (actions$: any) => {
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.MODIFY_MARKET,
        success: (res: AjaxResponse) => action.getMarketList({ marketData: res.response.contents }),
        fail: actionTypes.FAILED_MODIFY_MARKET
      },
      url: API_URL.MARKET,
      method: AjaxMethods.PUT
    };
    return epicService.request(actions$, reqParams);
  }

  const epic = combineEpics(
    requestMarketListEpic,
    requestGetMarketEpic,
    requestCreateMarketEpic,
    requestDeleteMarketEpic,
    requestModifyMarketEpic,
  );
  
  export { action, epic };
  export default reducer;