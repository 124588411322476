import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/keywordMonitoring";

const useMonitoringKeyword = () => {
  const dispatch = useDispatch();
  const keywordMonitoring = useSelector(
    (state: StoreState) => state.keywordMonitoring
  );
  const requestKeywordMonitoring = (data: any) => {
    dispatch(action.requestKeywordMonitoring(data));
  };

  const requestRecollectKeywordMonitoring = (data: any) => {
    dispatch(action.requestRecollectKeywordMonitoring(data));
  };

  return {
    keywordMonitoring,
    requestKeywordMonitoring,
    requestRecollectKeywordMonitoring,
  };
};

export default useMonitoringKeyword;
