import React, { useEffect, useState } from 'react'
// lib
import { makeStyles } from '@material-ui/core';
import Flatpickr from "react-flatpickr";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import 'flatpickr/dist/themes/material_blue.css';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 180,
  },
  monthButton: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  }
}))

const formattedDate = (date: ISearchDate, dataFormat: 'basic' | 'time'): string => {
  const dateFormat = dataFormat === 'time' && date.hour && date.minute ?
    `${date.year}-${fillZero(2, date.month)}-${fillZero(2, date.day)}T${fillZero(2, date.hour)}:${fillZero(2, date.minute)}`
    : `${date.year}-${fillZero(2, date.month)}-${fillZero(2, date.day)}`;

  return dateFormat
}

const fillZero = (width: number, value: number) => {
  const str = value.toString();
  return str.length >= width ? str:new Array(width-str.length+1).join('0') + str;
}

const toDateObject = (date: Date): ISearchDate => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
  }
}

interface ISearchDate {
  year: number;
  month: number;
  day: number;
  hour?: number;
  minute?: number;
}

interface IProps {
  type: 'startDt' | 'endDt';
  dataFormat: 'basic' | 'time';
  date: string;
  onChangeDate: (key: 'endDt' | 'startDt', value: string) => void;
  disabled?: boolean;
}

const DatePick: React.FC<IProps> = (props) => {

  const { type, dataFormat, date, onChangeDate, disabled } = props;
  
  const classes = useStyles();
  const dateFormat = toDateObject(new Date(date));

  const [ searchDate, setSearchDate ] = useState<ISearchDate>(dateFormat)

  const onSearchDateChanged = (event: any) => {
    const changeDate = toDateObject(event[0]);

    setSearchDate(changeDate);
    onChangeDate(type, formattedDate(changeDate, dataFormat));
  }

  return (
    <Flatpickr
      localName="ko"
      value={formattedDate(searchDate, dataFormat)}
      onChange={onSearchDateChanged}
      onClose={onSearchDateChanged}
      options={{
        mode: "single",
        enableTime: dataFormat !== 'basic',
        locale: "ko",
        dateFormat: dataFormat === 'basic' ? 'Y-m-d' : 'Y-m-d H:i',
      }}
      render={
        ({defaultValue, value, ...props}, ref) => {
          return (
            <TextField
              id="date"
              disabled={disabled}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={defaultValue}
              inputRef={ref}
              InputProps={{
                endAdornment: 
                  <InputAdornment position="end" style={{ zIndex: 4, position: 'relative'}} >
                    <TodayIcon />
                  </InputAdornment>
              }}
            />
          )
        }
      }
    />
  )
}

export default DatePick
