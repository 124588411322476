import styled from 'styled-components';

const HtmlContents = styled.div`
  height: 600px;
  border: 1px solid rgba(34,36,38,.15);
  background-color: rgb(240, 240, 240);  
  padding: .78571429em 1em; 
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  overflow: scroll;
`;

export const FaqDetailStyles = {
  HtmlContents
}