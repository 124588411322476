import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/productMonitoring";

const useMonitoringProduct = () => {
  const dispatch = useDispatch();
  const productMonitoring = useSelector(
    (state: StoreState) => state.productMonitoring
  );
  const requestProductMonitoring = (data: any) => {
    dispatch(action.requestProductMonitoring(data));
  };

  const requestRecollectProductMonitoring = (data: any) => {
    dispatch(action.requestRecollectProductMonitoring(data));
  };
  return {
    productMonitoring,
    requestProductMonitoring,
    requestRecollectProductMonitoring,
  };
};

export default useMonitoringProduct;
