import React, { useState } from 'react'
import { Form, Table, Button, TextArea } from 'semantic-ui-react';
import { IAnswerQnaParams, IQnaDetails } from '../interfaces';

import styled from 'styled-components';
import useQna from 'hooks/useQna';

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.span`
  
`

const ButtonWrap = styled.div`
  
`

interface IProps {
  answerQnaParams: IAnswerQnaParams
  answerDetail?: IQnaDetails
  onRequestQna: () => void
  showFeedback: Function
  answerId: number
  onChangeParams : (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => void
  onClose: () => void
}


const QnaAddAnswer: React.FC<IProps> = ({
  answerQnaParams,
  answerDetail,
  onRequestQna,
  showFeedback,
  answerId,
  onChangeParams,
  onClose,
}) => {
  const [isModify, setIsModify] = useState<boolean>(false)
  const { onCreateQna, onModifyQna } = useQna()
  
  const onModifyButton = () => {
    setIsModify(true)
  }

  const onSaveButton = () => {
    if (answerDetail && answerDetail.qnaNo !== -1 ) {
      onModifyQna({
        qnaId: answerId, 
        qnaContents : answerQnaParams.qnaContents, 
        file: [], 
        deleteFileNo: [],
        qnaDetailId: answerDetail.qnaNo
        
      })
      setIsModify(false);
      showFeedback('Answer is modified');
    } else {
      onCreateQna({
        body : answerQnaParams
      });
      onRequestQna();
      showFeedback('Answer is registered');
    }

    onClose();
  }

  const answerPart = ( (answerDetail && answerDetail.qnaNo !== -1 && !isModify) ?
  (
    <TextArea 
      style={{ minHeight: 200, backgroundColor:'#f0f0f0' }} 
      value={answerDetail?.qnaContents} 
      disabled 
    />
  ) : (
      <TextArea 
        name='qnaContents' 
        defaultValue={answerDetail?.qnaContents}        
        placeholder='답변 내용을 작성해주세요.' 
        style={{ minHeight: 200 }} 
        onChange={onChangeParams} 
      />
    )
  )

  return <Table celled padded>
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>
        <HeaderWrap>
          <Title>{answerDetail && answerDetail.qnaNo !== -1 ? '관리자('+answerDetail?.regUserId+')' : '작성'}</Title>
          {(answerDetail && answerDetail.qnaNo !== -1 && !isModify) 
            ? <Button size='mini' inverted color='purple' onClick={onModifyButton}> 수정 </Button>
            : <Button size='mini' inverted color='purple' onClick={onSaveButton}> 저장 </Button>
          }
        </HeaderWrap>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
  <Table.Body>
    <Table.Row>
      <Table.Cell>
        {answerPart}
      </Table.Cell>
    </Table.Row>
  </Table.Body>
</Table>
}

export default QnaAddAnswer