import React, { useEffect, useState } from 'react'
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
import { Form } from 'semantic-ui-react';
import { SendEmailStyles as Styled } from './styles/SendEmail.styles'
import { EmailTemplateItem } from 'store/modules/types';
import useEmailTemplate from 'hooks/useEmailTemplate';
import useEmail from 'hooks/useEmail';

interface IProps {
  open: boolean;
  showFeedback: (msg: string) => void;
  closePopup: () => void;
}

const initValid = { view: false, msg: '' };

const SendEmail: React.FC<IProps> = (props) => {
  const { open, showFeedback, closePopup } = props;
  const [ openConfirmPopup, setOpenConfirmPopup ] = useState<boolean>(false);
  const [ selectTemplate, setSelectTemplate ] = useState<EmailTemplateItem>();
  const [ viewTemplate, setViewTemplate ] = useState<boolean>(false);
  const [ sendRole, setSendRole ] = useState<TSendRole>('직접입력');
  const [ usersEmail, setUsersEmail ] = useState<string>('');
  const [ isSendLoading, setIsSendLoading ] = useState<boolean>(false);
  const [ valid, setValid ] = useState<{ view: boolean, msg: string }>(initValid);
  const { emailTemplate, requestGetEmailTemplateList } = useEmailTemplate();
  const { sendEmailBulkByEmails, sendEmailBulkByRole } = useEmail();

  const roles = ['직접입력', 'USER', 'MANAGER', 'OPERATOR', 'DEVELOPER', 'TESTER', 'ADMIN'];
  type TSendRole = typeof roles[number];

  useEffect(() => {
    requestGetEmailTemplateList({
      queryParameters: {
        isFilter: true,
      }
    } as any)
  }, [])

  const validMsg = {
    TEMPLATE: '템플릿을 선택해 주세요.',
    EMAIL: '이메일을 입력해 주세요.'
  }

  const openConfirm = () => {
    if (!selectTemplate) {
      setValid({
        view: true,
        msg: validMsg.TEMPLATE,
      });

      return;
    }

    if (sendRole === '직접입력' && !usersEmail) {
      setValid({
        view: true,
        msg: validMsg.EMAIL,
      });

      return;
    }

    setOpenConfirmPopup(true);
  }

  const closeConfirm = () => {
    setOpenConfirmPopup(false);
  }

  const sendEmail = () => {
    setIsSendLoading(true);

    const callback = (succeeded: boolean, res: any) => {
      if (succeeded) {
        setIsSendLoading(false);
        closeConfirm();
        closePopup();
        showFeedback('E-mail 전송 되었습니다.');
        setUsersEmail('');
      } else {
        showFeedback('E-mail 전송 실패하였습니다.');
      }
    }

    if (sendRole === '직접입력') {
      sendEmailBulkByEmails({
        // pathVariables: {
        //   path: 'bulk-by-emails',
        // },
        body: {
          emails: usersEmail,
          templateId: selectTemplate?.templateId,
        },
        callback,
      } as any)
    } else {
      sendEmailBulkByRole({
        // pathVariables: {
        //   path: 'bulk-by-role',
        // },
        body: {
          role: sendRole,
          templateId: selectTemplate?.templateId,
        },
        callback,
      } as any)
    }
  }

  const toggleViewTemplate = () => {
    setViewTemplate(!viewTemplate);
  }

  const handleRole = (role: TSendRole) => {
    setSendRole(role);
  }

  const handleTemplateChange = (e: React.SyntheticEvent<HTMLElement, Event>, data:any) => {
    if (valid.view) {
      setValid(initValid);
    }

    const template = emailTemplate.list.find((template) => template.templateId === data.value);
    setSelectTemplate(template);
  }

  const handleUsersEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (valid.view) {
      setValid(initValid);
    }

    setUsersEmail(e.currentTarget.value);
  }
  
  const templateNameList = emailTemplate.list.map((template) => {
    return {
      value: template.templateId,
      text: template.templateId,
    }
  });

  return (
    <BasePopup 
      id={0}
      open={open}
      onClose={closePopup}
      size={'large'}
      type={PopupTypes.DIALOG}
      buttons={[{ name: '취소 '}, { name: '전송', onClick: openConfirm }]}
    >
      <Styled.Wrap>
        <Styled.PopupHeader>{'이메일 전송'}</Styled.PopupHeader>
        <Styled.Contents>
          <Styled.Title>
            {'템플릿 선택'}
            <Form.Checkbox 
              label='미리보기'
              checked={viewTemplate}
              onChange={toggleViewTemplate}
            />
          </Styled.Title>
          <Styled.Div>
            <Form.Select
              name='type'
              placeholder='선택'
              options={templateNameList}
              onChange={handleTemplateChange}
            />
            {valid.view && valid.msg === validMsg.TEMPLATE && <Styled.ValidMsg>{valid.msg}</Styled.ValidMsg>}
          </Styled.Div>
          <Styled.Title>
            {'받는사람'}
            {roles.map((role) => (
              <Form.Radio 
                name='send-role'
                label={role}
                value={role}
                checked={sendRole === role}
                onClick={() => handleRole(role)}
              />
            ))}
          </Styled.Title>
          <Styled.Div>
            <Form.Input
              name='users email'
              placeholder='이메일을 작성해주세요. (콤마로 구분하여 공백없이 ‘abc@kies.co.kr,def@kies.co.kr’ 형태로 입력)'
              content={usersEmail}
              value={usersEmail}
              onChange={handleUsersEmail}
              disabled={sendRole !== '직접입력'}
            />
          </Styled.Div>
          {valid.view && valid.msg === validMsg.EMAIL && <Styled.ValidMsg>{valid.msg}</Styled.ValidMsg>}
          {viewTemplate && (
              <Styled.HtmlView 
                color={selectTemplate ? undefined : 'rgba(34,36,38,.15)'}
                dangerouslySetInnerHTML={{ __html: selectTemplate ? selectTemplate.template : '템플릿 미리보기' }} 
              />
            )}
        </Styled.Contents>
      </Styled.Wrap>
      <BasePopup 
        id={1}
        open={openConfirmPopup}
        onClose={closeConfirm}
        size={'tiny'}
        type={PopupTypes.DIALOG}
        buttons={[{ name: '취소 '}, { name: '확인', onClick: sendEmail, disabled: isSendLoading }]}
      >
        {'메일을 전송 하시겠습니까?'}
      </BasePopup>
    </BasePopup>
  )
}

export default SendEmail