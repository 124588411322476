import styled, { css } from 'styled-components'

interface IStyleProps {
  flex?: number;
  merge?: boolean;
  divide?: boolean;
  disabled?: boolean;
}

const Wrap = styled.section`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Header = styled.div`
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid #dddddd;
  font-size: 12px;
  display: flex;
`

const Content = styled.div<IStyleProps>`
  flex: ${({ flex }) => flex && flex};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ merge }) => merge && 'column'};
  border-bottom: ${({ divide }) => divide && '1px solid black'};

  ${({ merge }) => merge && css`
    & div {
      width: 100%;
      padding: 6px 0;
    }
  `};
`

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
`

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 15px;
  height: 15px;
  margin: 0 5px 0 10px;
`

const Button = styled.button<IStyleProps>`
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 8px 40px;
  font-size: 13px;
  font-weight: 700;
  opacity: ${({ disabled }) => disabled && 0.5};
  cursor: ${({ disabled }) => disabled && 'default'};
`

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
`

const BlankItem = styled.li`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dddddd;
`

export const BannerStyles = {
  Wrap,
  Header,
  Content,
  List,
  CheckBox,
  Button,
  Div,
  BlankItem,
}
