import React from 'react';
// Components
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
import useEvent from 'hooks/useEvent';

interface IProps {
  eventNo: number;
  open: boolean;
  onClose(): void;
  showFeedback: Function;
}
const EventDelete: React.FC<IProps> = (props) => {
  const { eventNo, open, onClose, showFeedback } = props;
  const { requestEventDelete } = useEvent();

  const onConfirm = () => {
    if (eventNo) {
      console.log(" eventNo :: ", eventNo);
      const payload: any = {
        pathVariables: { eventNo: eventNo },
        callback: (succeeded: boolean, res: any) => {
          if(succeeded){
            showFeedback('이벤트 삭제 완료되었습니다.');
          }else{
            showFeedback('이벤트 실패 하였습니다.');
          }
        }
      }
      requestEventDelete(payload);
    }
    else {
      console.log("시스템 일시적인 장애입니다. 다시 시도하여주세요.");
    }
    onClose();
  };

  return (
    <BasePopup
      id={eventNo}
      open={open}
      size="small"
      type={PopupTypes.DIALOG}
      title='이벤트 삭제'
      buttons={[
        {}, { onClick: onConfirm }
      ]}
      onClose={onClose}
    >
      <span>삭제하시겠습니까?</span>
    </BasePopup>
  )
}

export default EventDelete;