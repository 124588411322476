import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/todayBest";

const useTodayBest = () => {
  const dispatch = useDispatch();
  const bestProduct = useSelector((state: StoreState) => state.bestProduct);
  const requestBestProduct = (data: any) => {
    dispatch(action.requestTodayBest(data));
  };
  const requestRecollectTodayBest = (data: any) => {
    dispatch(action.requestRecollectTodayBest(data));
  };
  return {
    bestProduct,
    requestBestProduct,
    requestRecollectTodayBest,
  };
};

export default useTodayBest;
