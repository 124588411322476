import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';
// modules
import { ReviewConfirmState } from './types';
// actions
import * as actionTypes from './types/actions';
import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

// ACTION PART
const action = {
  // getMarketList
  getReviewDataConfirmStatusCheck: createAction(actionTypes.GET_REVIEW_DATA_CONFIRM_STATUS_CHECK),
  succeededGetReviewDataConfirmStatusCheck: createAction(actionTypes.SUCCEEDED_GET_REVIEW_DATA_CONFIRM_STATUS_CHECK),
  fatiledGetReviewDataConfirmStatusCheck: createAction(actionTypes.FAILED_GET_REVIEW_DATA_CONFIRM_STATUS_CHECK),

  getReviewDataConfirmFirstCollect: createAction(actionTypes.GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT),
  succeededGetReviewDataConfirmFirstCollect: createAction(actionTypes.SUCCEEDED_GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT),
  fatiledGetReviewDataConfirmFirstCollect: createAction(actionTypes.FAILED_GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT),

  getReviewDataConfirmDailyCollect: createAction(actionTypes.GET_REVIEW_DATA_CONFIRM_DAILY_COLLECT),
  succeededGetReviewDataConfirmDailyCollect: createAction(actionTypes.SUCCEEDED_REVIEW_DATA_CONFIRM_DAILY_COLLECT),
  fatiledGetReviewDataConfirmDailyCollect: createAction(actionTypes.FAILED_REVIEW_DATA_CONFIRM_DAILY_COLLECT),

  recollectReviewConfirmProduct: createAction(actionTypes.REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT),
  succeededRecollectReviewConfirmProduct: createAction(actionTypes.SUCCEEDED_REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT),
  fatiledRecollectReviewConfirmProduct: createAction(actionTypes.FAILED_REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT),

  recollectReviewConfirmCompare: createAction(actionTypes.REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE),
  succeededRecollectReviewConfirmCompare: createAction(actionTypes.SUCCEEDED_REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE),
  fatiledRecollectReviewConfirmCompare: createAction(actionTypes.FAILED_REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE),
}
  
const initialState: ReviewConfirmState = {
  statusCheck: {
    productAnalyzedCount: 0,
    productCollectingCount: 0,
    productCollectedCount: 0,
    compareAnalyzedCount: 0,
    compareCollectingCount: 0,
    compareCollectedCount: 0,
    customerProducts: []
  },
  firstCollect: {
    allProductCount: 0,
    allPurchaseReviewCount: 0,
    allReviewCount: 0,
    allNotCollectedProductCount: 0,
    allNotCollectedReviewCount: 0,
    customerProducts: []
  },
  dailyCollect: {
    allProductCount: 0,
    collectedProductCount: 0,
    failedProductCount: 0,
    increaseReviewCount: 0,
    uncollectedReviewCount: 0,
    uncollectedProducts: [],
    uncollectedReviews: []
  }
};

const reducer = handleActions<ReviewConfirmState>(
  {
    [actionTypes.SUCCEEDED_GET_REVIEW_DATA_CONFIRM_STATUS_CHECK]: (state, action): ReviewConfirmState => {
      return {
        ...state,
        statusCheck: action.payload.statusCheck
      };
    },
    [actionTypes.SUCCEEDED_GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT]: (state, action): ReviewConfirmState => {
      return {
        ...state,
        firstCollect: action.payload.firstCollect
      };
    },
    [actionTypes.SUCCEEDED_REVIEW_DATA_CONFIRM_DAILY_COLLECT]: (state, action): ReviewConfirmState => {
      return {
        ...state,
        dailyCollect: action.payload.dailyCollect
      };
    },
    [actionTypes.SUCCEEDED_REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT]: (state, action): ReviewConfirmState => {
      return state;
    },
  },
  initialState
);

// EPIC PART
// status-check
const requestReviewConfirmStatusCheckEpic = (actions$: any) => { 
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_REVIEW_DATA_CONFIRM_STATUS_CHECK,      
      success: (res: AjaxResponse) => action.succeededGetReviewDataConfirmStatusCheck({ statusCheck: res.response.data }),
      fail : actionTypes.FAILED_GET_REVIEW_DATA_CONFIRM_STATUS_CHECK
    },
    url: API_URL.REVIEW_CONFIRM_STATUS_CHECK,
    method: AjaxMethods.GET,
  };
  return epicService.request(actions$, reqParams);
}

// first-collect
const requestReviewConfirmFirstCollectEpic = (actions$: any) => { 
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT,      
      success: (res: AjaxResponse) => action.succeededGetReviewDataConfirmFirstCollect({ firstCollect: res.response.data }),
      fail : actionTypes.FAILED_GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT
    },
    url: API_URL.REVIEW_CONFIRM_FIRST_COLLECT,
    method: AjaxMethods.GET,
  };
  return epicService.request(actions$, reqParams);
}

// daily-collect
const requestReviewConfirmDailyCollectEpic = (actions$: any) => { 
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_REVIEW_DATA_CONFIRM_DAILY_COLLECT,      
      success: (res: AjaxResponse) => action.succeededGetReviewDataConfirmDailyCollect({ dailyCollect: res.response.data }),
      fail : actionTypes.FAILED_REVIEW_DATA_CONFIRM_DAILY_COLLECT
    },
    url: API_URL.REVIEW_CONFIRM_DAILY_COLLECT,
    method: AjaxMethods.GET,
  };
  return epicService.request(actions$, reqParams);
}

// recollect product
const recollectReviewConfirmProductEpic = (actions$: any) => { 
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT,      
      success: (res: AjaxResponse) => action.succeededRecollectReviewConfirmProduct(),
      fail : actionTypes.FAILED_REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT
    },
    url: API_URL.REVIEW_CONFIRM_RECOLLECT_PRODUCT,
    method: AjaxMethods.POST,
  };
  return epicService.request(actions$, reqParams);
}

// recollect compare
const recollectReviewConfirmCompareEpic = (actions$: any) => { 
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE,      
      success: (res: AjaxResponse) => action.succeededRecollectReviewConfirmCompare(),
      fail : actionTypes.FAILED_REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE
    },
    url: API_URL.REVIEW_CONFIRM_RECOLLECT_COMPARE,
    method: AjaxMethods.POST,
  };
  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  requestReviewConfirmStatusCheckEpic,
  requestReviewConfirmFirstCollectEpic,
  requestReviewConfirmDailyCollectEpic,
  recollectReviewConfirmProductEpic,
  recollectReviewConfirmCompareEpic,
);

export { action, epic };
export default reducer;
