import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { switchMap, map, catchError, takeUntil } from 'rxjs/operators';

import { ActionPayload } from './';
import * as actionTypes from './actions';
import { action } from '../basics';
//import useBasics from 'hooks/useBasics';

const config = require('../../../config');
console.log(config)
export const SERVER_URL = config.API_URL;
//const API_V1_URL = SERVER_URL+'/api/v1';
//const API_V2_URL = SERVER_URL+'/api/v2';
const API_V3_URL = SERVER_URL+'/apis/v3';
// const API_CRW_URL = "http://222.233.76.211:5000"; //192.168.116.211 


export const API_URL = {
  LOGIN: `${API_V3_URL}/login`,
  LOGOUT: `${API_V3_URL}/logout`,
  TOS: `${API_V3_URL}/user-tos`,


  LOGIN_ADMIN: `${API_V3_URL}/login-admin`,
  TOKEN_REFRESH: `${API_V3_URL}/token/refresh`,
  USERS: `${API_V3_URL}/users`,
  USER_SALES: `${API_V3_URL}/admin/userMarketSales`,
  ADMIN_USERS: `${API_V3_URL}/admin/users`,
  ADMIN_USERS_V2: `${API_V3_URL}/admin/users`,
  ADMIN_USER: `${API_V3_URL}/admin/user`,
  ADMIN_USER_V2: `${API_V3_URL}/users`,

  USER: `${API_V3_URL}/user`,
  USER_PW_CHANGE: `${API_V3_URL}/userpw`,
  EMAIL_CHECK: `${API_V3_URL}/checkemail`,
  ID_CHECK: `${API_V3_URL}/checkid`,
  USER_MARKET: `${API_V3_URL}/customer-market-group`,
  JOIN: `${API_V3_URL}/register`,
  CODE_LIST_ALL: `${API_V3_URL}/codeListAll`,
  CODE_LIST_GROUP: `${API_V3_URL}/codeListGroup`,
  CODE: `${API_V3_URL}/code`,
  CODE_MARKET: `${API_V3_URL}/code/markets`,

  DASHBOARD: `${API_V3_URL}/dashboards`,
  DASHBOARD_V2: `${API_V3_URL}/dashboard`,

  NOTICE: `${API_V3_URL}/notices`,

  FAQ: `${API_V3_URL}/faqs`,

  PAYMENT: `${API_V3_URL}/paymentRead`,
  PAYMENT_DETAIL: `${API_V3_URL}/paymentDetail`,
  PAYMENT_DELETE: `${API_V3_URL}/paymentDelete`,
  PAYMENT_INSERT: `${API_V3_URL}/paymentCreate`,
  PAYMENT_MODIFY: `${API_V3_URL}/paymentUpdate`,
  
  QNA: `${API_V3_URL}/qna`,

  USER_GUIDE: `${API_V3_URL}/useGuide`,

  EVENT: `${API_V3_URL}/events`,
  CODELISTALL: `${API_V3_URL}/code`,

  FILE: SERVER_URL+`/file/fileUpload`, 
  FILEADD: SERVER_URL+`/file/fileUpload`, 

  DSON_LIST: `${API_V3_URL}/salonPosts`,
  DSON_DETAIL: `${API_V3_URL}/salonPosts`,
  DSON: `${API_V3_URL}/salonPosts`,

  BEST_PRODUCT: `${API_V3_URL}/data-confirm/today-best`,
  BEST_PRODUCT_PER_MARKET: `${API_V3_URL}/data-confirm/today-best-market`,
  TREND_NEWS: `${API_V3_URL}/data-confirm/trend-news`,
  TREND_KEYWORD: `${API_V3_URL}/data-confirm/trend-keyword`,
  MONITORING_PRODUCT: `${API_V3_URL}/data-confirm/monitoring-product`,
  MONITORING_KEYWORD: `${API_V3_URL}/data-confirm/monitoring-keyword`,
  MONITORING_RECOLLECT: `${API_V3_URL}/data-confirm/monitoring`,
  HOT_KEYWORD500: `${API_V3_URL}/data-confirm/hot-keyword-500`,
  NAVER_ATTRIBUTE: `${API_V3_URL}/data-confirm/naver-attribute`,
  CRAWL_LOG: `${API_V3_URL}/crawl-log`,

  REVIEW_CONFIRM_STATUS_CHECK: `${API_V3_URL}/review/data-confirm/status-check`,
  REVIEW_CONFIRM_FIRST_COLLECT: `${API_V3_URL}/review/data-confirm/first-collect`,
  REVIEW_CONFIRM_DAILY_COLLECT: `${API_V3_URL}/review/data-confirm/daily-collect`,
  REVIEW_CONFIRM_RECOLLECT_PRODUCT: `${API_V3_URL}/review/data-confirm/recollect-product`,
  REVIEW_CONFIRM_RECOLLECT_COMPARE: `${API_V3_URL}/review/data-confirm/recollect-compare`,


  // Hash Tag
  TAG_LIST: `${API_V3_URL}/hashtags`,
  TAG: `${API_V3_URL}/hashtag`,

  MARKET: `${API_V3_URL}/market`,

  SALES_INFO: `${API_V3_URL}/sales/info`,

  EMAIL_SEND: `${API_V3_URL}/email-log`,
  EMAIL_LOGS: `${API_V3_URL}/email-log`,

  EMAIL_TEMPLATE: `${API_V3_URL}/email-templates`,

  BANNER: `${API_V3_URL}/banners`,

  ADMIN_USER_SALES_INFO_SUMMARY: `${API_V3_URL}/admin/userSales`,
  ADMIN_USER_SALES_INFO_DETAIL: `${API_V3_URL}/admin/userSalesDetail`,

  // /api/v1/admin/userLoginLog 회원로그
  ADMIN_USER_LOGIN_LOG: `${API_V3_URL}/admin/userLoginLog`,

  REVIVEWRAY_DEMOS: `${API_V3_URL}/review-demos`,
  REVIVEWRAY_PRODUCTS: `${API_V3_URL}/review-products`,
} 

// export const API_MONITORING_URL = {
//   SUMMARY: `${API_CRW_URL}/summary_msg`
// }

export const AjaxMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
} as const
type AjaxMethod = typeof AjaxMethods[keyof typeof AjaxMethods]

export interface IEpicActions {
  request: string;
  success?: { (res: AjaxResponse): void };
  fail?: string;
  cancel?: string;
}

interface IEpicHeader {
  contentType: string;
}

export interface IEpicReqParams {
  actions: IEpicActions;
  url: string;
  method: AjaxMethod;
  headers?: IEpicHeader;
  async?: boolean;
}

const epicService = (() => {

  const request = (action$: any, epicReqParams: IEpicReqParams) =>
    action$.pipe(
      ofType(epicReqParams.actions.request),
      switchMap((req: ActionPayload) => {
        console.log("epicService", "req payload ==>", req);
        const pathVariables = (req && req.payload && req.payload.pathVariables) || undefined;
        const pathVariabless = (req && req.payload && req.payload.pathVariabless) || undefined;
        const queryParameters = (req && req.payload && req.payload.queryParameters) || undefined;
        const body = (req && req.payload && req.payload.body) || {};
        const asyncFlag = req && req.payload && req.payload.async != undefined ? false : true;
        let callback = (req && req.payload && req.payload.callback) ? req.payload.callback : undefined;
        const accessToken = localStorage.getItem('access-token');

        //console.log("epicService", "callback ==>", callback);

        const ajaxRequest = {
          url: getUrl(epicReqParams.url, pathVariables, pathVariabless, queryParameters),
          method: epicReqParams.method,
          async: asyncFlag,
          headers: {
            'Content-Type': 'application/json',
          },
          body,
          withCredentials: true,
          crossDomain: true,
        };
        console.log("This is request URL", ajaxRequest.url);
        console.log("epicService", "epic request params ==>", epicReqParams);

        return ajax(ajaxRequest).pipe(
          map(res => {
            console.log("epicService", "response ==>", res);

            // 일반적으로 epic 상황에서 success 할 경우
            // successCallback에 의해 처리되지만
            // 별도로 다른 곳에서도 callback을 받아서 처리해야 하는 경우에 
            // 이곳에서 처리되도록 함
            if (callback) {
              callback(true, res);
            }

            if (epicReqParams.actions.success) {
              return epicReqParams.actions.success(res);
            }
            else {
              return action.epicSucceeded();
            }

            /*return {
              type: reqParams.actions.success,
              payload: getPayload(reqParams.resPayloadKeys, res)
            }*/
          }),
          takeUntil(
            action$.ofType(epicReqParams.actions.cancel || actionTypes.EPIC_CANCEL)
          ),
          catchError(e => {
            console.log("request", "error", e);

            if (e.status === 401) {
              if (e.response.code === 'ACCESS_TOKEN_EXPIRED') {
                const originReq = {
                  type: req.type,
                  payload: {
                    ...req.payload,
                    async: false,
                  },
                }

                fetch(`${API_URL.TOKEN_REFRESH}`, {
                  method: 'POST',
                  headers: {
                    "Content-Type": "application/json",
                  },
                  credentials: 'include',
                }).then(res => res.json()
                ).then((res:any) => {
                  // const user = res.data;
                  // if (user.userId === 'admin') {
                  //   if (user.authorities[0].authority === 'ROLE_ADMIN' || user.authorities[0].authority === 'ROLE_OPERATOR' ) {
                  //     return onLoginSucceeded({
                  //       user: user
                  //     })
                  //   }
                  //   return onLoginFailed({
                  //     msg: 'Access Denied, no authority'
                  //   });
                  // }

                  console.log('refreshToken::::::::::::::::::::::', res)
                  
                  action$.pipe(
                    ofType(epicReqParams.actions.request),
                    switchMap((req: ActionPayload) => {
                      //console.log(succeeded, res, originReq)
                      return of(originReq)
                    })
                  )
                }).catch(e => {
                  console.log(e)
                })

                // return of({
                //   type: actionTypes.TOKEN_REFRESH,
                //   payload: {
                //     async: false,
                //     callback: (succeeded: boolean, res: any) => {
                //       if (succeeded) {
                //         //localStorage.setItem('access-token', res.response.data.accessToken);
                //         action$.pipe(
                //           ofType(epicReqParams.actions.request),
                //           switchMap((req: ActionPayload) => {
                //             console.log(succeeded, res, originReq)
                //             return of(originReq)
                //           })
                //         )
                //       }
                //     }
                //   },
                // }, originReq)
              } else {
                localStorage.removeItem('account');
                localStorage.removeItem('access-token');
                localStorage.removeItem('refresh-token');
                
                return of({
                  type: actionTypes.LOGOUT,
                })
              }
            }

            // error 중 403일 경우 forbidden 또는 accessdenied 인 경우임
            // 그런데 로그인 상태에서 이런 error 가 발생하면 token 만료이므로
            // 개별적으로 처리하지 말고 이곳에서 logout 처리를 하도록 한다.
            if (e.status === 403) {
              return of({
                type: actionTypes.LOGOUT
              })
            }

            if (callback) {
              callback(false, e);
            }

            return of({
              type: epicReqParams.actions.fail || actionTypes.EPIC_FAIL,
              payload: (e && e.xhr && e.xhr.response) || ''
            })
          })
        )
      })
    );
  
  const getUrl = (url: string, pathVariables?: any, pathVariabless?: any, queryParameters?: any) => {
    if (pathVariables) {
      const keys = Object.keys(pathVariables)
      keys.forEach((key: any) => {
        //console.log("getUrl", key, pathVariables[key]);
        url = url + "/" + pathVariables[key];
      })
    }if (pathVariabless) {
      let res = "";
      Object.entries(pathVariabless).map(([key, value]) => {
         res += key + "=" + value+ "&"; 
      })  
       url = url + "?"  + res; 
    }

    if (queryParameters) {
      url = url + "?"  + combineParams(queryParameters);
    }
    // console.log('url :' ,url)
    return url;
  }

  const combineParams = (data : any) =>{
    let params = '';
    const keys = Object.keys(data);
    const paramsCount = keys.length;
    
    keys.forEach((key: any, index : number) => {
      // data[key] 자료형이 배열인 경우, 각 배열 요소별로 queryParameter에 추가
      if (typeof data[key] === 'object' && data[key].length > 0) {
        const keyDatas: any[] = [...data[key]];
        keyDatas.forEach((keyData: any, dataIndex: number) => {
          params += key+'='+keyData;
          if (dataIndex < data[key].length - 1) {
            params += '&';
          }
        })
      }
      else {
        params += key+'='+data[key];
      }
      if (paramsCount-1 > index ) {
        params += '&';
      }
    })
    return params;
  }

  /*const getPayload = (keys?: any, res?: AjaxResponse) => {
    let payload: any = {};
    if (keys && res) {
      const dataKey = keys.data;
      const listKey = keys.list;
      if (dataKey) {
        payload[dataKey] = res.response.data || '';
      }
      if (listKey) {
        payload[listKey] = res.response.list || '';
      }
    }

    return payload;
  }*/

  return {
    request
  }
})();

export default epicService;