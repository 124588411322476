import React, { SyntheticEvent, useEffect, useState } from 'react';

import { Form, Image } from 'semantic-ui-react';
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

// Hooks
import useGuide from 'hooks/useGuide';

// Componetnts
import ImageUpload from 'components/common/fileuploader/ImageUploader';
// Config
import { SERVER_URL } from 'store/modules/types/epics';
interface IFiles{
	fileName:string;
	filePath:string,
	fileUrl:string,
}
interface IProps {
	guideItem: any;
	typeList: any[];
	fileType: string;
	open: boolean;
	onClose(): void;
	showFeeback: Function;
}

const GuideModify: React.FC<IProps> = (props) => {
	const { guideItem, typeList, open, fileType, onClose, showFeeback } = props;
	const { guide, onModifyGuide } = useGuide();
	const [ file, setFile ] = useState<IFiles>();
	const [ type, setType ] = useState(guideItem.guideType);
	const [ sort, setSort ] = useState(guideItem.priority);
	const [ viewYn, setViewYn ] = useState(guideItem.viewYn);

	useEffect(() => {
		setType(guideItem.guideType);
		setViewYn(guideItem.viewYn);
		setSort(guideItem.priority);
	}, [guideItem])

	const handleChange = (e: any, { name, value }: any) => ({ [name]: value });

	const guideTypeCickEvent = (value: any) => {
		setType(value);
	}

	const sortChange = (e : React.ChangeEvent<HTMLInputElement>)  => {
		setSort(parseInt(e.target.value));
	}

	const viewYnChange = (e : React.FormEvent<HTMLInputElement>) => {
		// e.currentTarget.checked == false ? setViewYn(true) : setViewYn(false);
		setViewYn(!viewYn);
	}

	const modifyGuide = (e: SyntheticEvent, item: any) => {
		// console.log("수정");
		const fileName = file === undefined ? guideItem.imageName : file.fileName;
		const filePath = file === undefined ? guideItem.imagePath : file.filePath;
		const guideNoItem = guide.guideList.find((data:any) => {
			if (type === data.guideType) {
				return data;		
			}
		});

		if (guideNoItem != undefined) {
			onModifyGuide({
				"detail": [{
					"detailNo": guideItem.detailNo,
					"imageName": fileName,
					"imagePath": filePath,	
					"priority": sort,
				}],
				"no": guideNoItem.guideNo,
				"guideType": type,
				"viewYn": viewYn,
			});
		}
		else {
			onModifyGuide({
				"detail": [{
					"detailNo": guideItem.detailNo,
					"imageName": fileName,
					"imagePath": filePath,	
					"priority": sort,
				}],
				"guideType": type,
				"viewYn": viewYn,
			});
		}
		showFeeback("수정이 완료되었습니다.");
		setFile(undefined);
		onClose();
	}

	const imageUrl = SERVER_URL+guideItem.imagePath+guideItem.imageName;
	const isImage = file === undefined ? <Form.Group> <Image src={imageUrl} size='medium' /></Form.Group> : '';

	const viewType = typeList.map((item:any) => {
		return (
			<Form.Radio
				label={item.text}
				value={item.value}
				checked={type === item.value}
				onChange={handleChange}
				onClick={() => {guideTypeCickEvent(item.value) }}
			/>
		)
	})

	return (
		<BasePopup
			open={open}
			id={0}
			size='large'
			title='이용 가이드 수정'
			type={PopupTypes.DIALOG}
			buttons={[{},{ onClick: modifyGuide }]}
			onClose={onClose}
		>
			<Form>
				<Form.Group inline>
					<Form.Checkbox 
						label='게시글 노출 여부' 
						defaultChecked={viewYn} 
						onChange={viewYnChange} 
					/>
			</Form.Group>
			<Form.Group inline>
				<label>이용 가이드 타입 : </label>
				{viewType}
			</Form.Group>
			<Form.Group inline>
				<Form.Input
					label='순서 : '
					type='number'
					value={sort}
					onChange={sortChange}
				/>
			</Form.Group>
			{isImage}
			</Form>
			<ImageUpload fileType={fileType} setFile={setFile} />
		</BasePopup>
	)
}
export default GuideModify;