import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/trendKeyword";

const useTrendKeyword = () => {
  const dispatch = useDispatch();

  const trendKeyword = useSelector((state: StoreState) => state.trendKeyword);

  const requestTrendKeyword = (data: any) => {
    dispatch(action.requestTrendKeyword(data));
  };

  const requestRecollectTrendKeyword = (data: any) => {
    dispatch(action.requestRecollectTrendKeyword(data));
  };
  return {
    trendKeyword,
    requestTrendKeyword,
    requestRecollectTrendKeyword,
  };
};

export default useTrendKeyword;
