import React, { useEffect, useState } from 'react'
// hooks
import useBasics from 'hooks/useBasics';
// interface
import { ICommonCode } from 'store/modules/types';
import { IUserSalesParams } from './interface';
// styles
import { MarketListStyles as Styled } from './styles/MarketList.styles'

interface IProps {
  requestParams: IUserSalesParams;
  setRequestParams: Function;
}

const MarketList: React.FC<IProps> = (props) => {

  const { requestParams, setRequestParams } = props;
  const { basics } = useBasics();
  const { admin_user_market_list: marketData } = basics.commonCodeList;

  const onSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (requestParams.marketList.includes(event.currentTarget.value)) {
      setRequestParams({
        ...requestParams,
        marketList: [
          ...requestParams.marketList.filter((market) => market !== event.currentTarget.value),
        ]
      })
    } else {
      setRequestParams({
        ...requestParams,
        marketList: [
          ...requestParams.marketList,
          event.currentTarget.value,
        ]
      })
    }
  }

  const onSelectAll = () => {
    const allMarket: string[] = [];
    marketData.forEach((market: ICommonCode) => allMarket.push(market.value));
    marketData.length === requestParams.marketList.length ?
      setRequestParams({
        ...requestParams,
        marketList: []
      }) 
      : setRequestParams({
        ...requestParams,
        marketList: [...allMarket]
      }) 
  }

  const marketList = (
    <>
      {marketData && marketData.length && marketData.map((market: ICommonCode, index: number) => {
        if (index === 0) {
          return (
            <Styled.MarketListItem>
              <Styled.Button 
                value={'전체'} 
                onClick={onSelectAll} 
                select={marketData.length === requestParams.marketList.length && true}
              >
                전체
              </Styled.Button>
            </Styled.MarketListItem>
          )
        } else {
          const select = requestParams.marketList.includes(market.value) && true;
      
          return (
            <Styled.MarketListItem key={market.value}>
              <Styled.Button 
                value={market.key} 
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => onSelect(event)} 
                select={select}
              >
                {market.value}
              </Styled.Button>
            </Styled.MarketListItem>
          )
        }
      })}
    </>
  )

  return (
    <Styled.MarketList>
      {marketList}
    </Styled.MarketList>
  )
}

export default MarketList
