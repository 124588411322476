import styled from 'styled-components'

interface IStyleProps {
  select?: boolean;
}

const MarketList = styled.ul`
  flex: 10;
  display: flex;
  justify-content: left;
  flex-flow: row wrap;
  border-left: 1px solid rgba(34,36,38,.1);
`

const MarketListItem = styled.li`
  list-style: none;
  border: none;
  border-radius: 2px;

  @media(min-width: 500px) {
    margin: 5px 5px;
  }
  
  @media(min-width: 1000px) {
    margin: 10px 10px;
  }

  @media(min-width: 1700px) {
    margin: 10px 15px;
  }
`

const Button = styled.button`
  background-color: 
    ${(props: IStyleProps) => props.select ? 
      'rgba(95,117,209,0.5)' : 'rgb(159 159 159 / 50%)'
    };
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 2px;
  color: white;
  width: 100%;
  transition: 0.2s;
  
  &:hover {
    background-color: rgba(95,117,209,0.5);
  }
  padding: 8px 20px;
`

export const MarketListStyles = {
  MarketList,
  MarketListItem,
  Button,
}