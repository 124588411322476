import React from 'react'
import { TReviewProductType } from 'store/modules/types'
import styled from 'styled-components'
import ReviewrayProductInfo from './ReviewrayProductInfo'

const Wrap = styled.li`
  display: grid;
  grid-template-columns: 5% 75% 10% 10%;

  & > div:not(:last-child) {
    border-right: 1px solid #D8D8D8;
  }
`

interface ITextProps {
  color: string
  size: string
  weight: number
}

const Text = styled.div<ITextProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${({size}) => size};
  font-weight: ${({weight}) => weight};
  color: ${({color}) => color};
`

interface IProps {
  no: number
  product: TReviewProductType
}

const ReviewrayProductItem: React.FC<IProps> = (props) => {
  const { no, product } = props

  return <Wrap>
    <Text color={'#353535'} size={'12px'} weight={300}>{no}</Text>
    <ReviewrayProductInfo product={product} />
    <Text color={'#353535'} size={'12px'} weight={300}>{product.analyzeReviewCount.toLocaleString()}</Text>
    <Text color={'#353535'} size={'12px'} weight={300}>{product.analyzeReviewCount.toLocaleString()}</Text>
  </Wrap>
}

export default ReviewrayProductItem