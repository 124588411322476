import React, { useEffect, useMemo, useState } from 'react';
// Lib
import Flatpickr from 'react-flatpickr';
import ImageUpload from 'components/common/fileuploader/ImageUploader';
// Hooks
import useNotice from 'hooks/useNotice';
// Components
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';
import { CheckboxProps, Form, InputOnChangeData, Icon } from 'semantic-ui-react';
import CkEditor4 from 'components/common/ckeditor4';
// Interface
import { INoticeInsertParams, initINoticeInsertParams, getDateFormatter, linkAreaColor, IPopupLinkRanges, initPopupLinkRanges, IPopupListItem, initPopupListItem, getStringDateConvert, locationButtonTextList, initIRequestNoticeParams } from '../interface';

// Styled
import { NoticeWriteStyles as Styled } from '../styles/NoticeWrite.styles';
import useBasics from 'hooks/useBasics';
import { convertCommonCode } from 'util/common';
import JSBI from 'jsbi';

interface IFiles {
  fileName: string;
  filePath: string;
  fileUrl: string;
}

interface IProps {
  noticeNo: number;
  noticeTypes: any[];
  open: boolean;
  onClose(): void;
  showFeedback: Function;
}
const NoticeWrite: React.FC<IProps> = (props) => {
  const { noticeNo, noticeTypes, open, onClose, showFeedback } = props;
  const { requestGetNoticeDetail, requestInsertNotice, requestUpdateNotice  } = useNotice();
  const { commonCodeList  : { service_type } } = useBasics().basics
  const [ noticeParams, setNoticeParams ] = useState<INoticeInsertParams>(initINoticeInsertParams);

  const [ popupLinkRanges, setPopupLinkRanges ] = useState<IPopupLinkRanges[]>([
    {
      widthValue: initPopupLinkRanges.widthValue,
      heightValue: initPopupLinkRanges.heightValue,
      topValue: initPopupLinkRanges.topValue,
      leftValue: initPopupLinkRanges.leftValue,
      url: initPopupLinkRanges.url,
      popupLinkRangeId: null,
    }
  ]);
  
  useEffect(() => {
    if (noticeNo > 0) {
      const payload: any = {
        pathVariables: { noticeNo },
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            const result = res.response.data;


            let popupDateArray: any[] = [];
            if (result.popups.length > 0) {
            if (result.popups[0].startDate && result.popups[0].endDate) {
                popupDateArray.push(getStringDateConvert(result.popups[0].startDate));
                popupDateArray.push(getStringDateConvert(result.popups[0].endDate));
              } 
              else {
                const today: Date = new Date();
                const yyyy = today.getFullYear();
                const mm = today.getMonth()+1 < 10 ? `0${today.getMonth()+1}` : `${today.getMonth()+1}`;
                const dd = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;
                popupDateArray.push(getStringDateConvert(`${yyyy}-${mm}-${dd}`));
                popupDateArray.push(getStringDateConvert(`${yyyy}-${mm}-${dd}`));
              }
            }

            setNoticeParams({
              ...noticeParams,
              contents: result.contents,
              importantYn: result.importantYn,
              serviceType: result.serviceType,
              title: result.title,
              type: result.type,
              viewYn: result.viewYn,
              topYn: result.topYn,
              loginYn: result.loginYn,   
              popupYn: result.popupYn && result.popups[0].viewYn,    
              popups: result.popups.length > 0? [{
                ...result.popups[0],
                popupDateList: popupDateArray,
                detail: result.popups[0].detail != null ? result.popups[0].detail : '',
              }] : initINoticeInsertParams.popups
            });

            if (result.popups[0]) {
              setPopupLinkRanges([
                ...result.popups[0].popupLinkRanges.map((popupLinkRange:any) => ({
                  ...popupLinkRange,
                  popupLinkRangeId: JSBI.BigInt(popupLinkRange.popupLinkRangeId).toString()
                }))
              ]);
            }
          }
        }
      };
      requestGetNoticeDetail(payload);
    }
  }, []);

  // Function
  const handleChange = (e:React.SyntheticEvent<HTMLElement, Event>, data:any) => {
    let value = null;
    if (data.type === 'checkbox') {
      value = data.value === 'true' ? false : true;
    }
    else {
      value = data.value;
    }
    setNoticeParams({
      ...noticeParams,
      [data.name]: value
    });
  };

  const handleEtidorChange = (data:any) => {
    setNoticeParams({
      ...noticeParams,
      contents: data
    });
  };

  const handlePopupChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: InputOnChangeData | CheckboxProps) => {
    let value = null;        
    let tempPopupYn: boolean = !!noticeParams.popupYn;

    if (data.type === 'checkbox') {      
      const isChecked: boolean = data.checked;              
      if (data.name === 'location') {
        let tempLocation: string = noticeParams.popups[0].location;
        if (isChecked) {
          tempLocation = String(data.value);
        }
        value = tempLocation;
      } 
      else if (data.name === 'viewYn') {
        tempPopupYn = isChecked;
      }      
    } 
    else {
      value = data.value;
    }
    setNoticeParams({
      ...noticeParams,
      popupYn: tempPopupYn,
      popups: [{
        ...noticeParams.popups[0],
        [data.name]: value,        
      }]
    });
  };

  const onSetPopupImageFile = (file:IFiles) => {
    setNoticeParams({
      ...noticeParams,
      popups: [{
        ...noticeParams.popups[0],
        popupImageName: file.fileName,
        popupImagePath: file.filePath,
      }]
    })
  };
  
  const onClickinitPopupDatePicker = () => {
    setNoticeParams({
      ...noticeParams,
      popups: [{
        ...noticeParams.popups[0],
        popupDateList: [],
        popupImageName: '',
        popupImagePath: '',
      }]
    });
  };

  const handelPopupDatePickerChange = (data:any) => {
    let startDate = "";
    let endDate = "";
    if (data.length > 0) {
      data.map((item: any, index:number) => {

        // console.log(" item[index] :: ", item[index]);
        if (data.length === 1) {
          startDate = getDateFormatter(item);
          endDate = getDateFormatter(item);
        }
        else {
          if (index === 0) {
            startDate = getDateFormatter(item);
          }
          else if (index === 1) {
            endDate = getDateFormatter(item);
          }
        }
      });
    }
    setNoticeParams({
      ...noticeParams,
      popups: [{
        ...noticeParams.popups[0],
        startDt: startDate,
        endDt: endDate,
        popupDateList: data
      }]
    });
  };

  const onRemovePopupImage = () => {
    setNoticeParams({
      ...noticeParams,
      popups: [{
        ...noticeParams.popups[0],
        popupImageName: '',
        popupImagePath: '',
      }]
    })
  };

  const onAddLinkArea = () => {
    // 링크 영역 생성은 5개까지 제한
    if (popupLinkRanges.length < 5) {
      setPopupLinkRanges([
        ...popupLinkRanges,
        {
          widthValue: 0,
          heightValue: 0,
          topValue: 0,
          leftValue: 0,
          url: '',
          popupLinkRangeId: null,
        }
      ]);
    }
  }

  const onDeleteLinkArea = (index: number) => {
    const tempPopupLinkRanges: IPopupLinkRanges[] = popupLinkRanges.filter((_, filterIndex) => filterIndex !== index).map(item=>item);
    setPopupLinkRanges(tempPopupLinkRanges);
  }

  // 저장
  const onWriteNotice = () => {
    if (noticeNo > 0) {
      // 수정모드
      const payload: any = {
        body: { 
          title: noticeParams.title,
          contents: noticeParams.contents,
          importantYn: noticeParams.importantYn,
          viewYn: noticeParams.viewYn,
          topYn: noticeParams.topYn,
          loginYn: noticeParams.loginYn, 
          type: noticeParams.type,
          serviceType: noticeParams.serviceType,
          noticeNo: noticeNo,
          popupYn: noticeParams.popupYn,
          popupSaveDtoList: noticeParams.popups.length > 0 ? [{
            ...noticeParams.popups[0],
            viewYn: noticeParams.popupYn,
            location: noticeParams.popups[0].location.length > 0 ? noticeParams.popups[0].location : 'ALL',
            popupLinkRanges: popupLinkRanges
          }] : [],
          deletePopupIds: [],
        },
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            showFeedback('공지사항 수정 완료되었습니다.');
          }
          else {
            showFeedback('공지사항 수정 실패하였습니다.');
          }
        }
      }
      requestUpdateNotice(payload);
    }
    else {
      // 등록모드
      const payload: any = {
        body: { 
          title: noticeParams.title,
          contents: noticeParams.contents,
          importantYn: noticeParams.importantYn,
          viewYn: noticeParams.viewYn,
          topYn: noticeParams.topYn,
          type: noticeParams.type,
          loginYn: noticeParams.loginYn, 
          serviceType: noticeParams.serviceType,
          //popupYn: noticeParams.popupYn,
          popupSaveDtoList: noticeParams.popupYn && noticeParams.popups && noticeParams.popups.length > 0 ? [
            {
              ...noticeParams.popups[0],
              viewYn: noticeParams.popupYn,
              location: noticeParams.popups[0].location.length > 0 ? noticeParams.popups[0].location : 'ALL',
              popupLinkRanges: popupLinkRanges
            },
          ] : [],
        },
        callback: (succeeded: boolean, res:any) => {          
          if (succeeded) {
            showFeedback('공지사항 등록 완료되었습니다.');
          }
          else {
            showFeedback('공지사항 등록 실패하였습니다.');
          }
        }
      }
      requestInsertNotice(payload);
    }
    
    onClose();
  };


  const handlePopupLinkChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: InputOnChangeData | CheckboxProps) => {
    let value = null;       
    const parser = data.name.split('-');
    const dataName: string = parser[0];
    const index: number = parser.length > 1 ? Number(parser[1]) : 0;

    let tempPopupLinkRanges: IPopupLinkRanges[] = popupLinkRanges.map(item => {
      return item
    });

    if (data.type === 'number') {
      if (dataName === 'widthValue' || dataName === 'heightValue' || dataName === 'topValue' || dataName === 'leftValue') {
        if (Number(data.value) > 0) {

          const dataValue: number = Number(data.value);
          const widthValue: number = popupLinkRanges[index].widthValue;
          const heightValue: number = popupLinkRanges[index].heightValue;
          const topValue: number = popupLinkRanges[index].topValue;
          const leftValue: number = popupLinkRanges[index].leftValue;

          // 링크영역 사이즈, 좌표의 최대 값 제한 (예: 영역의 폭과 x좌표는 합쳐서 600을 넘지 못함)
          if (dataName === 'widthValue') {
            value = dataValue < 601 - leftValue ? dataValue : 600 - leftValue;          
          } else if (dataName === 'leftValue') {
            value = dataValue < 601 - widthValue ? dataValue : 600 - widthValue;          
          } else if (dataName === 'heightValue') {
            value = dataValue < 601 - topValue ? dataValue : 600 - topValue;          
          } else if (dataName === 'topValue') {
            value = dataValue < 601 - heightValue ? dataValue : 600 - heightValue;          
          }
          // value = Number(data.value) < 601 ? Number(data.value) : 600;        
        } 
        else if (Number(data.value) <= 0) {
          value = 0;
        }
      }
    }
    else {
      value = data.value;
    }

    // 변경된 key의 value 값 입력
    tempPopupLinkRanges[index] = {
      ...tempPopupLinkRanges[index],
      [dataName]: value
    };
    setPopupLinkRanges(tempPopupLinkRanges);
  }


  const linkAreaArray: JSX.Element[] = popupLinkRanges.map((item, index) => {
    const widthValue: number = item.widthValue;
    const heightValue: number = item.heightValue;
    const topValue: number = item.topValue;
    const leftValue: number = item.leftValue;
    const url: string = item.url;

    const deleteLinkArea = index !== 0 ? (
      <span style={{float: 'right'}}>
        <Styled.DeleteLinkButton 
          icon
          onClick={()=>onDeleteLinkArea(index)}
        >
          <Icon name="minus"/>
        </Styled.DeleteLinkButton>
      </span>
    ) : <></>;

    return (
      <>
        <div className="link-sub-label">
          <span>#{index+1}</span>
          <Styled.colorBoxSpan style={{background: linkAreaColor[index]}} />
          {deleteLinkArea}
        </div>
        <Styled.LinkAreaItem>
          <Styled.LinkAreaDiv>        
            <Form.Input
              label='폭'  
              type="number"        
              className="form-input-axis"
              name={`widthValue-${index}`}
              placeholder='폭'
              content={widthValue != undefined ? widthValue : 600}
              value={widthValue != undefined ? widthValue : 600}
              onChange={handlePopupLinkChange}
            />

            <Form.Input
              label='높이'
              type="number"
              className="form-input-axis"
              name={`heightValue-${index}`}
              placeholder='높이'
              content={heightValue != undefined ? heightValue : 600}
              value={heightValue != undefined ? heightValue : 600}
              onChange={handlePopupLinkChange}
            />
            <Form.Input
              label='X좌표'
              type="number"          
              className="form-input-axis"
              name={`leftValue-${index}`}
              placeholder='X좌표'
              content={leftValue != undefined ? leftValue : 0}
              value={leftValue != undefined ? leftValue : 0}
              onChange={handlePopupLinkChange}
            />

            <Form.Input
              label='Y좌표'
              type="number"
              className="form-input-axis"
              name={`topValue-${index}`}
              placeholder='Y좌표'          
              content={topValue != undefined ? topValue : 0}
              value={topValue != undefined ? topValue : 0}
              onChange={handlePopupLinkChange}
            />
          </Styled.LinkAreaDiv>
          <Form.Input
            label='외부 링크'
            className="form-input"
            name={`url-${index}`}
            placeholder='여기에 외부 URL을 입력하면, 팝업 내 링크영역 클릭 시 해당 주소로 이동합니다.'
            content={url}
            value={url}
            onChange={handlePopupLinkChange}
          />
        </Styled.LinkAreaItem>
      </>
    )
  })

  const popupHeaderName = noticeNo === 0 ? "공지사항 등록" : "공지사항 수정";
  const editor = useMemo(() => 
    noticeNo === 0 ?
      <CkEditor4 
        url="NOTICE"
        setContents={handleEtidorChange}
        height={500}
      />
    : noticeNo > 0 && noticeParams.contents ?
      <CkEditor4 
        url="NOTICE"
        contents={noticeParams.contents}
        setContents={handleEtidorChange}
        height={500}
      />
    : null
  , [noticeParams]);

  const popupImage: string = noticeParams.popups.length > 0 ? noticeParams.popups[0].popupImagePath + noticeParams.popups[0].popupImageName : "";
    
  // 링크 영역의 폭, 높이 값이 있고, 팝업 이미지 소스가 있으면 가시화된 링크 영역 생성
  const linkArea: JSX.Element[] = popupImage.length > 0 && popupLinkRanges.length > 0 ? popupLinkRanges.map((item, index) => (
    <span 
      key={index}
      className="link-area"
      style={{
        width: `${item.widthValue}px`,
        height: `${item.heightValue}px`,
        top: `${item.topValue}px`,
        left: `${item.leftValue}px`,
        background: linkAreaColor[index],
        opacity: '0.4',
      }}
    />
  )) : [];

  const addButton: JSX.Element = (
    <Styled.AddLinkButton 
      onClick={onAddLinkArea}
      icon
    >
      <Icon name="plus" />
    </Styled.AddLinkButton>
  );

  // 팝어 노출 체크박스
  const locationCheckboxList: JSX.Element[] = locationButtonTextList.map((item, index) => {
    const isChecked: boolean = noticeParams.popups.length > 0 && noticeParams.popups[0].location === item.value;

    return (
      <Form.Checkbox 
        key={index}
        label={item.text} 
        value={item.value}
        checked={isChecked}
        name='location'
        onChange={handlePopupChange}
      />
    );
  }) 

  const popupDiv: JSX.Element = noticeParams.popupYn ? (
    <Styled.PopupDiv>
      <Form.Group widths='equal'>
        <Form.Checkbox 
          label='링크 클릭 시 회원가입 페이지로 이동' 
          value={String(noticeParams.popups[0].loginYn)}
          checked={noticeParams.popups[0].loginYn}
          name='loginYn'
          onChange={handlePopupChange}
        />
      </Form.Group>

      {/* 팝업 노출 위치 */}
      팝업 노출 위치
      <Styled.CommentSpan></Styled.CommentSpan>
      <Form.Group style={{padding: '0 20px'}}>
        {locationCheckboxList}
      </Form.Group>

      {/* Date Picker */}
      팝업 노출 시작/종료일
      <Form.Group widths='equal'>
        <Flatpickr
          localName="ko"
          className="event-pickr"
          value={noticeParams.popups[0].popupDateList}
          onChange={handelPopupDatePickerChange}
          onClose={handelPopupDatePickerChange}
          options={{
            mode: "range",
            locale: "ko",
            enableTime: false,
            conjunction: "~",
            dateFormat: 'Y-m-d',
            closeOnSelect: false
          }}
        />
        <div onClick={onClickinitPopupDatePicker} >
          <Icon 
            link  
            name="delete" 
            size="large" 
            color="red" 
            style={{ paddingTop: '9px' }} 
          />
        </div>
      </Form.Group>

      {/* Drop Zone */}
      <Styled.PopupImageDiv>
        팝업 이미지
        <Styled.PopupImageDropzonDiv>
          <ImageUpload
            fileType="POPUP"
            setFile={onSetPopupImageFile}
            defaultImage={popupImage}
            defaultImageUrlFull={false}  
            onRemoveImage={onRemovePopupImage}               
          />
          {linkArea}
        </Styled.PopupImageDropzonDiv>
      </Styled.PopupImageDiv>
      <div style={{marginTop: '1em'}}>
        <span>링크 영역</span>
        <span style={{float: 'right'}}>{addButton}</span>
      </div>
      <Styled.LinkAreaContainer>
        {linkAreaArray}
      </Styled.LinkAreaContainer>

      
    </Styled.PopupDiv>
  ) : <></>;

  return (
    <>
      <BasePopup
        open={open}
        id={0}
        size="small"
        title={popupHeaderName}
        type={PopupTypes.DIALOG}
        buttons={[{}, { onClick: onWriteNotice }]}
        onClose={onClose}
      >
        <Form>
          <Form.Group widths='equal'>
            <Form.Checkbox 
              label='중요글 유무'
              value={String(noticeParams.importantYn)}
              checked={noticeParams.importantYn}
              name='importantYn'
              onChange={handleChange}
            />
            <Form.Checkbox 
              label='게시유무' 
              value={String(noticeParams.viewYn)}
              checked={noticeParams.viewYn}
              name='viewYn'
              onChange={handleChange}
            />
            <Form.Checkbox 
              label='항상 위에 표시' 
              value={String(noticeParams.topYn)}
              checked={noticeParams.topYn}
              name='topYn'
              onChange={handleChange}
            />
            <Form.Checkbox 
              label='회원전용' 
              value={String(noticeParams.loginYn)}
              checked={noticeParams.loginYn}
              name='loginYn'
              onChange={handleChange}
            />
          </Form.Group>
            <Form.Select
              label="카테고리"
              name="type"
              placeholder="선택"
              value={noticeParams.type}
              options={convertCommonCode(noticeTypes)}
              onChange={handleChange}
            />
            <Form.Select
              label="서비스타입"
              name="serviceType"
              placeholder="선택"
              value={noticeParams.serviceType}
              options={[
                //{text:'전체', value:"COMMON"},
                ...convertCommonCode(service_type),
              ]}
              onChange={handleChange}
            />
          <Form.Group widths='equal'>
            <Form.Input
              label='제목'
              name='title'
              placeholder='공지사항 제목을 입력해주세요.'
              content={noticeParams.title}
              value={noticeParams.title}
              onChange={handleChange}
            />
          </Form.Group>
          내용
          {editor}

          <Form.Checkbox 
            label='팝업 노출' 
            className="form-checkbox"
            value={String(noticeParams.popupYn)}
            checked={noticeParams.popupYn}
            name='popupYn'
            onChange={handleChange}
          />
          {popupDiv}
        </Form>
      </BasePopup>
    </>
  )
}
export default NoticeWrite;