import React from 'react';
import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import 'moment/locale/ko';

// modules
import configureStore, { epicRun } from "store/configureStore";
import * as serviceWorker from './registerServiceWorker';

// components
import App from './App';

// styles
import './index.css';
import './style.css';

const store = configureStore();

// call after creaeting store
epicRun();

const container = document.querySelector('#root');

if (container) {
  const root = createRoot(container);
  
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>,
  );
  //registerServiceWorker();
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();  
}
