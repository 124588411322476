import { useDispatch } from 'react-redux';

// modules
import { action } from 'store/modules/dashboard';

const useDashBoard = () => {

  const dispatch = useDispatch();

  const requestgetDashboardUserStatus = (payload: any) => {    
    dispatch(action.getDashboardUserStatus(payload));
  }    

  const requestDashboardJoinUsers = (payload: any) => {    
    dispatch(action.getDashboardJoinUsers(payload));
  }    

  const requestDashboardVisitorUsers = (payload: any) => {    
    dispatch(action.getDashboardVisitorUsers(payload));
  }    

  const requestDashboardUserStores = (payload: any) => {    
    dispatch(action.getDashboardUserStores(payload));
  }    

  const requestDashboardAnalyzeStatus = (payload: any) => {    
    dispatch(action.getDashboardAnalyzeStatus(payload));
  }    

  const requestDashboardAnalyzeStatusResults = (payload: any) => {    
    dispatch(action.getDashboardAnalyzeStatusResult(payload));
  }    

  const requestDashboardMonitoringStatus = (payload: any) => {    
    dispatch(action.getDashboardAnalyzeMonitoringStatus(payload));
  }    

  const requestDashboardMonitoringStatusResults = (payload: any) => {    
    dispatch(action.getDashboardAnalyzeMonitoringStatusResults(payload));
  }    

  const requestDashboardQnaCount = (payload: any) => {    
    dispatch(action.getDashboardQnaCount(payload));
  }
  
  const requestGetDashboardUser = (payload: any) => {    
    dispatch(action.requestGetDashboardUser(payload));
  }

  const requestGetDashboardUserMore = (payload: any) => {    
    dispatch(action.requestGetDashboardUserMore(payload));
  }
  
  const requestGetDashboardQna = (payload: any) => {    
    dispatch(action.requestGetDashboardQna(payload));
  }

  const requestGetDashboardUserLoginLogMore = (payload: any) => {    
    dispatch(action.requestGetDashboardUserLoginLogMore(payload));
  }


  return {
    requestgetDashboardUserStatus,
    requestDashboardJoinUsers,
    requestDashboardVisitorUsers,
    requestDashboardUserStores, 
    requestDashboardAnalyzeStatus,
    requestDashboardAnalyzeStatusResults,
    requestDashboardMonitoringStatus,
    requestDashboardMonitoringStatusResults,
    requestDashboardQnaCount,

    requestGetDashboardUser,
    requestGetDashboardQna,
    requestGetDashboardUserMore,
    requestGetDashboardUserLoginLogMore,
  }
}

export default useDashBoard;