import { IPage, initPage } from "store/modules/types";

export interface IFiles{
  fileName:string;
  filePath:string,
  fileUrl:string,
}

export interface IRequestDsonListParams{
  categoryCode: string;
  mainTitle: string;
  page: number;
  size: number;
}

export interface IDsonListItem {
  categoryCode: string;
  contents: string;
  hashtagList: IDsonHashtagListItem[];
  iconType: number;
  mainTitle: string;
  postsId: number;
  regDt: string;
  salonRelationPosts: IDsonSalonRelationPostListItem[];
  subTitle: string;
  thumbnailFileName: string;
  thumbnailFilePath: string;
  topImageName: string;
  topImagePath: string;
  updateDt: string;
  updateUserId: string;
  user: any;
  viewCount: number;
  viewYn: boolean;
}

export interface IDsonSalonRelationPostListItem {
  postsId: number;
  rank: number;
}

export interface IDsonHashtagListItem {
  hashtagId: number;
  hashtagName: string;
  regDt: string;
  regUserId: string;
  updateDt: string;
  useYn: boolean;
}

export interface IInsertDsonParams{
  mainCategoryCode: string; //"SALON_REPOT",
  mainTitle: string; //"살롱온 제목 테스트",
  subTitle: string; //"살롱온 부제목 테스트",
  contents: string; //"내용 확인",
  postsTargetIds: number[]; //"13,15",
  thumbnailFileName: string; //"1596163969650.jpg",
  thumbnailFilePath: string; //"/file/image/",
  topImageName: string; //"1596163969650.jpg",
  topImagePath: string; //"/file/image/",
  hashTagId: string; //"1,2,3",
  iconType: number | undefined; //0, 
  viewYn: boolean;
  salonPostsId:number;
  userId?: string; //"admin"
}

export const initInsertParams : IInsertDsonParams = {  
  mainCategoryCode: '', //"SALON_REPOT",
  mainTitle: '', //"살롱온 제목 테스트",
  subTitle: '', //"살롱온 부제목 테스트",
  contents: '', //"내용 확인",
  postsTargetIds: [], //"13,15",
  thumbnailFileName: '', //"1596163969650.jpg",
  thumbnailFilePath: '', //"/file/image/",
  topImageName: '', //"1596163969650.jpg",
  topImagePath: '', //"/file/image/",
  hashTagId: '', //"1,2,3",
  viewYn: false,
  iconType: undefined, //0, 
  salonPostsId:0
}


export interface IGetDson{
  list: any[],
  page: IPage,
}

//////////////////////////////////////////////////////////////

export const initRequestDsonListParams : IRequestDsonListParams = {
  categoryCode: '',
  mainTitle: '',
  page: 1,
  size: 10,
}


export const initGetData : IGetDson = {  
  list: [],
  page:initPage
}
