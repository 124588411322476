import React, { useEffect, useState } from 'react'
// components
import UserInfo from './UserInfo'
import QnaInfo from './QnaInfo';
// lib
import moment from 'moment'
// styles
import { IndexStyles as Styled } from './styles/index.styles'
import Calendar from '../common/calendar/Calendar';
import { IDashboardPopupListProps, initDashboardPopupListProps, IPopupSearchData, TDashboardPopupType } from './interface';
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
import List from './List';

const DashBoard: React.FC = () => {
  const [ searchDate, setSearchDate ] = useState<string>(moment().format('YYYY-MM-DD'))

  const initPopupData: IPopupSearchData = {
    popupType: '',
    searchPageSize: 20,
    startDate: moment(searchDate).subtract(1, 'months').format('YYYY-MM-DD'),
    endDate: searchDate,
    totalPageNumber: 1,
    pageNumber: 1,
    totalContentsCount: 0,
  }

  const [ isPopupOpen, setIsPopupOpen ] = useState<boolean>(false);
  const [ popupData, setPopupData ] = useState<IPopupSearchData>(initPopupData);
  const [ popupListData, setPopupListData ] = useState<IDashboardPopupListProps>(initDashboardPopupListProps);

  useEffect(() => {
    setPopupData(initPopupData);
  }, [searchDate])

  const popupDefaultData = {
    'user-join': {
      title: '가입자 리스트',
      subTitle: `${popupData.startDate} ~ ${popupData.endDate} 가입자 리스트 (총: ${popupData.totalContentsCount})`,
    },
    'user-visitors': {
      title: '방문자 리스트',
      subTitle: `${popupData.startDate} ~ ${popupData.endDate} 방문자 리스트 (총: ${popupData.totalContentsCount})`
    },
    // 'user-store': {
    //   title: '스토어 등록 리스트',
    //   subTitle: `${popupData.startDate} ~ ${popupData.endDate} 스토어 등록 리스트 (총: ${popupData.totalContentsCount})`
    // },
    // 'analyze': {
    //   title: '분석 조회 리스트',
    //   subTitle: `${popupData.startDate} ~ ${popupData.endDate} 분석 리스트 (총: ${popupData.totalContentsCount})`
    // },
    // 'monitoring': {
    //   title: '모니터링 리스트',
    //   subTitle: `${popupData.startDate} ~ ${popupData.endDate} 분석 리스트 (총: ${popupData.totalContentsCount})`
    // },
  }

  const popupOpen = () => {
    setIsPopupOpen(true);
  }

  const popupClose = () => {
    setIsPopupOpen(false);
    setPopupData(initPopupData);
  }

  const setPopupType = (popupType: TDashboardPopupType) => {
    setPopupData({
      ...popupData,
      popupType,
    })
  }

  const setPopupDatas = (totalPageNumber: number, totalContentsCount: number, listData: IDashboardPopupListProps) => {
    setPopupData({
      ...popupData,
      totalPageNumber,
      totalContentsCount,
    })

    setPopupListData({
      ...listData,
    });
  }

  const onChangeEventDate = (e: any) => {
    const date = moment(e[0]).format('YYYY-MM-DD');
    setSearchDate(date);
  }

  const onChangeStringDate = (date: string) => {
    setSearchDate(date);
  }

  const onChangePopupStartDate = (e: any) => {
    setPopupData({
      ...popupData,
      startDate: moment(e[0]).format('YYYY-MM-DD'),
    })
  }

  const onChangePopupEndDate = (e: any) => {
    setPopupData({
      ...popupData,
      endDate: moment(e[0]).format('YYYY-MM-DD'),
    })
  }

  const onChangePageNumber = (pageNumber: number) => {
    setPopupData({
      ...popupData,
      pageNumber,
    })
  }

  const onChangePrevPageNumber = () => {
    if (popupData.pageNumber > 1) {
      setPopupData({
        ...popupData,
        pageNumber: popupData.pageNumber - 1,
      })
    }
  }

  const onChangeNextPageNumber = () => {
    if (popupData.pageNumber < popupData.totalPageNumber) {
      setPopupData({
        ...popupData,
        pageNumber: popupData.pageNumber + 1,
      })
    }
  }

  const onChangeFirstPageNumber = () => {
    if (popupData.pageNumber > 1) {
      setPopupData({
        ...popupData,
        pageNumber: 1,
      })
    }
  } 

  const onChangeLastPageNumber = () => {
    if (popupData.pageNumber < popupData.totalPageNumber) {
      setPopupData({
        ...popupData,
        pageNumber: popupData.totalPageNumber,
      })
    }
  } 

  return (
    <>
      <Styled.DashBoard>
        <Calendar type='main' searchDate={searchDate} label='day' onChangeEventDate={onChangeEventDate} />
        <Styled.Title>회원 정보</Styled.Title>
        <Styled.Article>
          <UserInfo 
            searchDate={searchDate} 
            onChangeStringDate={onChangeStringDate} 
            popupData={popupData}
            popupOpen={popupOpen}  
            setPopupType={setPopupType}
            setPopupDatas={setPopupDatas}
          />
        </Styled.Article>
        {/* <Styled.Title>누적 등록현황</Styled.Title>
        <Styled.Article>
          <RegMarketInfo 
            searchDate={searchDate} 
            onChangeStringDate={onChangeStringDate} 
            popupData={popupData}
            popupOpen={popupOpen}  
            setPopupType={setPopupType}
            setPopupDatas={setPopupDatas}
          />
        </Styled.Article>
        <Styled.Title>{`모니터링 정보 (${moment(searchDate).format('YY.MM.DD')})`}</Styled.Title>
        <Styled.Article>
          <MornitoringInfo 
            searchDate={searchDate} 
            onChangeStringDate={onChangeStringDate} 
            popupData={popupData}
            popupOpen={popupOpen}  
            setPopupType={setPopupType}
            setPopupDatas={setPopupDatas}
          />
        </Styled.Article> */}
        <Styled.Title>{`1:1 문의현황 (${moment(searchDate).format('YY.MM.DD')})`}</Styled.Title>
        <Styled.Article>
          <QnaInfo searchDate={searchDate} />
        </Styled.Article>
      </Styled.DashBoard>
      <BasePopup
        open={isPopupOpen}
        id={0}
        title={popupData.popupType? popupDefaultData[popupData.popupType].title : ''}
        size='large'
        type={PopupTypes.BASIC}
        onClose={popupClose}
        style={{
          width: '75%',
        }}
      >
        <Styled.PopupCalendarWrap>
          <Calendar type='static' searchDate={popupData.startDate} label='startDate' onChangeEventDate={onChangePopupStartDate} />
          <Calendar type='static' searchDate={popupData.endDate} label='endDate' onChangeEventDate={onChangePopupEndDate} />
        </Styled.PopupCalendarWrap>
        <Styled.PopupSubTitle>{popupData.popupType ? popupDefaultData[popupData.popupType].subTitle : ''}</Styled.PopupSubTitle>
        <Styled.PopupListWrap>
          <List {...popupListData} />
        </Styled.PopupListWrap>
        <Styled.PopupListPageNation>
          <Styled.PopupListPageButton onClick={onChangeFirstPageNumber}>{'<<'}</Styled.PopupListPageButton>
          <Styled.PopupListPageButton onClick={onChangePrevPageNumber}>{'<'}</Styled.PopupListPageButton>
          {Array.from({ length: popupData.totalPageNumber }, (v, i) => i + 1).map((num) => (
            <Styled.PopupListPageButton 
              isActive={num === popupData.pageNumber} 
              onClick={() => onChangePageNumber(num)}
            >
              {num}
            </Styled.PopupListPageButton>
          ))}
          <Styled.PopupListPageButton onClick={onChangeNextPageNumber}>{'>'}</Styled.PopupListPageButton>
          <Styled.PopupListPageButton onClick={onChangeLastPageNumber}>{'>>'}</Styled.PopupListPageButton>
        </Styled.PopupListPageNation>
      </BasePopup>
    </>
  )
}

export default DashBoard