import React from "react"

import styled from "styled-components"

const Wrap = styled.div`
  padding: 10px;
  width: 47%;
  border: 1px solid #D8D8D8;
  //box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  border-radius: 5px;

  min-height: 200px;
  
`

const Title = styled.span`
  font-size: 12px;
  font-weight: 700;
`

const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 20px;
`

const ItemText = styled.span`
  font-size: 12px;
  font-weight: 400;
`

const ItemValue = styled.span`
  font-size: 13px;
  font-weight: 600;

  small {
    font-size: 12px;
    font-weight: 400;
  }
`

interface IProps {
  title: string
  items: {
    text: string
    value: string
    unit: string
  }[]
}


const IrankMembershipInfoCard: React.FC<IProps> = (props) => {
  const { title, items } = props
  return <Wrap>
    <Title>{title}</Title>
    {items.map(item => <ItemWrap>
      <ItemText>{item.text}</ItemText>
      <ItemValue>
        {item.value}
        <small>{item.unit}</small>
      </ItemValue>
    </ItemWrap>)}
  </Wrap>
} 

export default IrankMembershipInfoCard