import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

const SearchSection = styled.section`
  width: 80%;
  max-width: 1280px;
  border: 1px solid rgba(34,36,38,.1);
  border-radius: 4px;
  box-shadow: 
    0px 3px 3px -2px rgb(0 0 0 / 20%), 
    0px 3px 4px 0px rgb(0 0 0 / 14%), 
    0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding: 15px 25px;
  > div {
    display: flex;
    margin: 20px 0;
    >:nth-child(n+2) {
      display: flex;
      height: 45px;
      align-items: center;
    }
  }
`;

const FormSelect = styled(Form.Select)`
  #header-select-box {
    width: 100%;
    height: 33px;
    font-size: 12px;
    .menu {
      max-height: 9.5em;
      .item {
        font-size: 1em;
        height: 2.7em;
        padding: .78571429em 2.1em .78571429em 1em !important;
        .text {
          font-size: 1em;
        }
      }
    }
  }
`

const TableSection = styled.section`
`;

const SubjectSpan = styled.span`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  flex: 0.8;
`;

const RowDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  .field:nth-child(n+2) {
    width: 300px;
    margin-left: 10px;
    .input {
      width: 100%;
    }
  }
`;

const SearchParamSpan = styled.span`
  flex: 10;
  padding-left: 10px;
  border-left: 1px solid rgba(34,36,38,.1);
  div {
    margin-left: 0;
  }
  &.user-role {
    display: flex;
    align-items: center;
    input {
      &:nth-child(n+2) {
        margin-left: 15px;
      }
      margin-right: 3px;
    }
  }
`;

const SearchButton = styled.button`
  &&& {
    width: 120px;
    height: 40px;
    margin-top: 30px;
    background: rgba(95,117,209,0.7);
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    &:hover {
      background: rgba(95,117,209, .8);
    }
    &:active {
      background: rgba(95,117,209, 1);
    }
  }
`;

export const UserLogsStyles = {
  Wrapper,
  SearchSection,
  RowDiv,
  FormSelect,
  TableSection,
  SubjectSpan,
  SearchParamSpan,
  SearchButton,
}