import styled from 'styled-components'

const UserInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

const Title = styled.h3`
  font-size: 0.9rem;
  font-weight: 500;
`

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 30px;
  
  & > div {
    margin: 0 5px;
  }
`

const UserMarket = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const MoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
`

const UrlLink = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  text-overflow: ellipsis;

  &:hover {
    color: #80b6f4;
  }
`

export const UserInfoStyles = {
  UserInfo,
  TitleWrap,
  Title,
  Section,
  UserMarket,
  MoreButton,
  UrlLink,
}