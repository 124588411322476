import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { FaqState, initPage } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse, ajax } from 'rxjs/ajax';

// ACTION PART
const action = {

  requestFaqList: createAction(actionTypes.FAQ_LIST),
  fulfilledFaqList: createAction(actionTypes.FULFILLED_FAQ_LIST),
  
  requestGetFaq: createAction(actionTypes.GET_FAQ),
  fulfilledGetFaq: createAction(actionTypes.FULFILLED_GET_FAQ),

  requestFaqCreate: createAction(actionTypes.FAQ_CREATE),
  succeededFaqCreate: createAction(actionTypes.SUCCEEDED_FAQ_CREATE),
  failedFaqCreate: createAction(actionTypes.FAILED_FAQ_CREATE),
  
  requestFaqModify: createAction(actionTypes.FAQ_MODIFY),  
  succeededFaqModify: createAction(actionTypes.SUCCEEDED_FAQ_MODIFY),
  failedFaqModify: createAction(actionTypes.FAILED_FAQ_MODIFY),

  requestFaqDelete: createAction(actionTypes.FAQ_DELETE),
  succeededFaqDelete: createAction(actionTypes.SUCCEEDED_FAQ_DELETE),
  failedFaqDelete: createAction(actionTypes.FAILED_FAQ_DELETE),
  
}

const initialState: FaqState = {
  faqData: {
    list: [],
    size: 0,
    page:initPage,
  }
};

const reducer = handleActions<FaqState>(
  {
    [actionTypes.FULFILLED_FAQ_LIST]: (state, action): FaqState => {
      return {
        ...state,
        faqData: action.payload.faqData
      }
    },
  },
  initialState
);

// EPIC PART
const requestFaqListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.FAQ_LIST,
      success: (res: AjaxResponse) => action.fulfilledFaqList({ faqData: res.response.data }),
      fail: actionTypes.FAILED_FAQ_LIST
    },
    url: API_URL.FAQ,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestGetFaqEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_FAQ,
      success: (res: AjaxResponse) => action.fulfilledGetFaq(),
      fail: actionTypes.FAILED_GET_FAQ
    },
    url: API_URL.FAQ,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestFaqCreateEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.FAQ_CREATE,
      success: (res: AjaxResponse) => action.requestFaqList({ faqData: res.response.data }),
      fail: actionTypes.FAILED_FAQ_CREATE
    },
    url: API_URL.FAQ,
    method: AjaxMethods.POST
  };  
  return epicService.request(actions$, reqParams);
}

const requestFaqModifyEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.FAQ_MODIFY,
      success: (res: AjaxResponse) => action.requestFaqList({ faqData: res.response.data }),
      fail: actionTypes.FAILED_FAQ_MODIFY
    },
    url: API_URL.FAQ,
    method: AjaxMethods.PATCH
  };

  return epicService.request(actions$, reqParams);
}

const requestFaqDeleteEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.FAQ_DELETE,
      success: (res: AjaxResponse) => action.requestFaqList({ faqData: res.response.datap }),
      fail: actionTypes.FAILED_FAQ_DELETE
    },
    url: API_URL.FAQ,
    method: AjaxMethods.DELETE
  };

  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  requestFaqListEpic,
  requestGetFaqEpic,
  requestFaqCreateEpic,
  requestFaqModifyEpic,
  requestFaqDeleteEpic,
);

export { action, epic };
export default reducer;