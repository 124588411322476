import useNaverAttribute from "hooks/useNaverAttribute";
import React, { useEffect } from "react";
import { Title } from "../styles/CommonStyle/Common.style";
import { NaverAttributeStyles as Styled } from "../styles/NaverAttributeStyle/NaverAttribute.style";
import NaverAttributeCard from "../Card";
import DayOfWeek from "../DayOfWeek";
const NaverAttribute: React.FC = () => {
  const { naverAttribute, requestNaverAttribute } = useNaverAttribute();
  const { naverAttributeData } = naverAttribute;
  useEffect(() => {
    requestNaverAttribute();
  }, []);

  return (
    <div>
      <Title>네이버 상품 속성</Title>
      <Styled.Text>
        수집 날짜 {naverAttributeData.date}
        &nbsp;
        <DayOfWeek
          isFullDate={false}
          dateString={naverAttributeData.date}
        ></DayOfWeek>
      </Styled.Text>
      <Styled.CardContainer>
        <NaverAttributeCard
          text="전체 카테고리"
          value={naverAttributeData.totalCategories}
        ></NaverAttributeCard>
        <NaverAttributeCard
          text="네이버 상품 속성 수집 수"
          value={naverAttributeData.naverAttributeCategories}
        ></NaverAttributeCard>
      </Styled.CardContainer>
    </div>
  );
};

export default NaverAttribute;
