import React, { useState, useEffect } from 'react';
import Dropzone, { ILayoutProps } from 'react-dropzone-uploader';
import "react-dropzone-uploader/dist/styles.css";
// Config
import { SERVER_URL } from 'store/modules/types/epics';

interface IProps {
  fileType: string;
  setFile: Function;
  defaultImage?: string;
  defaultImageUrlFull?: boolean;
  onRemoveImage?: () => void;
}

const ImageUpload: React.FC<IProps> = (props) => {

  const { fileType, setFile, defaultImage, defaultImageUrlFull, onRemoveImage } = props;

  const [initialImage, setInitialImage] = useState<File>();
  const [isDefaultImage, setIsDefaultImage] = useState(false);
  const [dropzoneDispaly, setDropzoneDispaly] = useState('');
  const [prevImageDispaly, setPrevImageDispaly] = useState('flex');

  useEffect(() => {
    if (defaultImage) {
      setDropzoneDispaly('none');
      setIsDefaultImage(true);
      onSetImage(defaultImage);
    }
  }, [defaultImage]);

  const onSetImage = (imagePathName: string) => {
    console.log(" SERVER_URL :: ", SERVER_URL);
    console.log(" SERVER_URL :: ", defaultImageUrlFull);
    let imageURL = null;
    if (defaultImageUrlFull) {
      imageURL = imagePathName;
    }
    else {
      imageURL = SERVER_URL + imagePathName;
    }
    
    // fetch(imageURL).then(res => {
    //   res.arrayBuffer().then(buf => {
    //     const file = new File([buf], '', { type: 'image/jpeg' })
    //     setInitialImage(file);
    //   })
    // })
  }

  const getUploadParams = (files: any) => {
    console.log('isDefaultImage:::',isDefaultImage)
    if (isDefaultImage) {
      setIsDefaultImage(false);
      return { url: '' }
    }
    else {
      const body = new FormData();
      body.append('upload', files.file);
      return { url: SERVER_URL + '/apis/v3/file/' + fileType, body }
    }

  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles }
  }: ILayoutProps) => {
    return (
      <div>
        {previews}
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
        {files.length > 0 && submitButton}
      </div>
    );
  };

  const handleChangeStatus = (files: any, xhr: string, status: any) => {
    // console.log('files : ', files)
    // console.log('status : ', status);
    // console.log('xhr : ', xhr)
    if (xhr === 'done') {
      setDropzoneDispaly('none');
      setPrevImageDispaly('flex');
      const res = JSON.parse(status[0].xhr.response);
      onSetImage(res.filePath + res.fileName);
      setFile({ "fileName": res.fileName, "filePath": res.filePath, "fileUrl": res.url });
    }
    else if (xhr === 'removed') {
      setInitialImage(undefined);
      setIsDefaultImage(false);
      setPrevImageDispaly('none');
      setDropzoneDispaly('flex');
      if (onRemoveImage) {
        onRemoveImage();
      }
    }
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      LayoutComponent={Layout}
      maxFiles={1}
      onChangeStatus={handleChangeStatus}
      minSizeBytes={1024}
      maxSizeBytes={31457280}
      canRestart={false}
      initialFiles={initialImage ? [initialImage] : undefined}
      accept="image/*"
      inputContent="Drag & Drop Or Click"
      styles={{
        preview: { display: prevImageDispaly },
        previewImage: { maxHeight: '25%', maxWidth: '50%', overflow: 'auto' },
        dropzone: { display: dropzoneDispaly }
      }}
    />
  )
}

export default ImageUpload;