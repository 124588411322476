import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup';
import React from 'react'
import { BannerItem } from '../interface';
import { BannerDetailStyles as Styled } from './styles/BannerDetail.styles'
import { SERVER_URL } from 'store/modules/types/epics';

interface IProps {
  banner: BannerItem;
  open: boolean;
  onClose: () => void;
  onOpenPopup: (mode: 'detail' | 'write') => void;
}

const BannerDetail: React.FC<IProps> = (props) => {
  const { banner, open, onClose, onOpenPopup } = props;
  const config = require('../../../config/index')
  const serverUrl = SERVER_URL;
  const imgPath = `${serverUrl}${banner.imagePath}${banner.imageName}`;

  const onChangePopup = () => {
    onOpenPopup('write');
  }

  const onOpenLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(banner.link);
  }

  return (
    <BasePopup
      open={open}
      id={0}
      size='small'
      type={PopupTypes.DIALOG}
      title='배너 상세'
      buttons={[
        {
          name: '수정',
          onClick: onChangePopup
        }, 
        {
          name: '닫기',
          onClick: onClose
        }
      ]}
      onClose={onClose}
    >
      <Styled.List>
        <Styled.Item>
          <Styled.Title>배너명</Styled.Title>
          <Styled.Input value={banner.name} />
        </Styled.Item>
        <Styled.Item>
          <Styled.Title>배너 위치</Styled.Title>
          <Styled.Input value={banner.serviceType} />
        </Styled.Item>
        <Styled.Item>
          <Styled.Title>분류</Styled.Title>
          <Styled.Input value={banner.deviceType} />
        </Styled.Item>
        <Styled.Item>
          <Styled.Title>게시 기간</Styled.Title>
          <Styled.Div>
            <Styled.Input value={banner.startDt ? banner.startDt.replace('T', ' ') : '무제한'} flex={1} />
            <Styled.Text>~</Styled.Text>
            <Styled.Input value={banner.endDt ? banner.endDt.replace('T', ' ') : '무제한'} flex={1} />
          </Styled.Div>
        </Styled.Item>
        <Styled.Item>
          <Styled.Title>사용 여부</Styled.Title>
          <Styled.Div>
            <Styled.Label>
              <Styled.Radio checked={banner.activeYn} disabled={!banner.activeYn} onChange={() => {}} />
              사용
            </Styled.Label>
            <Styled.Label>
              <Styled.Radio checked={!banner.activeYn} disabled={banner.activeYn} onChange={() => {}} />
              미사용
            </Styled.Label>
          </Styled.Div>
        </Styled.Item>
        <Styled.Item>
          <Styled.Div>
            <Styled.Title>
              {`배경색상`}
            </Styled.Title>            
            <Styled.ColorWrap 
              isGradation={banner.rgb.indexOf('linear') > -1}
              backgroundColor={banner.rgb}              
            ></Styled.ColorWrap>
          </Styled.Div>
            <Styled.Div>
            <Styled.Input value={banner.rgb} flex={1} />
          </Styled.Div>
        </Styled.Item>
        {/* <Styled.Item>
          <Styled.Title>기본배너 여부</Styled.Title>
          <Styled.Div>
            <Styled.Label>
              <Styled.Radio checked={banner.defaultYn} disabled={!banner.activeYn} onChange={() => {}} />
              사용
            </Styled.Label>
            <Styled.Label>
              <Styled.Radio checked={!banner.defaultYn} disabled={banner.activeYn} onChange={() => {}} />
              미사용
            </Styled.Label>
          </Styled.Div>
        </Styled.Item> */}
        <Styled.Item>
          <Styled.Title>링크</Styled.Title>
          <Styled.Link onClick={onOpenLink}>{banner.link}</Styled.Link>
        </Styled.Item>
        <Styled.Item>
          <Styled.Title>배너 이미지</Styled.Title>
          <Styled.Image src={imgPath} alt={banner.imageName} />
        </Styled.Item>
      </Styled.List>
    </BasePopup>
  )
}

export default BannerDetail