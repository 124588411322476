import React, { useEffect, useState } from 'react';
// Components
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import TableList, { ITableListProps, ITableListButton, IFindTextBox, ISelectBox, IPagination } from 'components/common/table/TableList';
import FaqListItem from './FaqListItem';
import FaqDelete from './popup/FaqDelete';
import FaqWrite from './popup/FaqWrite';
import FaqDetial from './popup/FaqDetial';
// Interface
import { IPopupParams, initIPopupParams } from './interface';
import useBasics from 'hooks/useBasics';
import { convertCommonCode } from 'util/common';

interface IProps {
  faqCode: any[];
  faqList: any;
  faqPage: any;
  params: any;
  setParams: Function;
  refresh: Function;
}
const FaqList: React.FC<IProps> = (props) => {
  
  const { faqList, faqPage, refresh, params, setParams, faqCode } = props;
  const [ resultMsg, setResultMsg ] = useState('');
  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ categoryOptions, setCategoryOptions] = useState([{ value: 'ALL', text: '전체' }]);
  const [ popupMode, setPopupMode ] = useState<IPopupParams>(initIPopupParams);
  const { commonCodeList : { service_type } } = useBasics().basics
  const [ serviceType, setServiceType] = useState([
    { value: 'SELLERSPOT,IRANK,REVIEWRAY', text: '전체' },
    ...convertCommonCode(service_type)
  ]);

  useEffect(() => {
    if (faqCode != undefined) {
      if (categoryOptions.length > 0 && faqCode[0].value !== 'none') {
        setCategoryOptions(categoryOptions.concat(faqCode));
      }     
    }
  }
  ,[faqCode]);

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  const onClose = () => {
    setPopupMode({
      ...popupMode,
      open: false,
      mode: '',
      faqId: 0
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({
      ...params,
      [event.target.name]: event.target.value
    });
  };

  const onSearch = (event: any) => {
    if(event.key === 'Enter' || event === 'Clicked'){
      refresh({
        ...params,
        page: 1,
      });
    }
  };

  const onCategorySearch = (event: React.SyntheticEvent<HTMLElement, Event>, getData: any) => {    
    const value = getData.value === 'ALL' ? '' : getData.value;
    refresh({
      ...params,
      page: 1,
      faqType: value
    });
  };

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page:any) => {
    console.log(page)
    refresh({
      ...params,
      [page.name]: page.activePage
    })
  };
  
  const onAddButtonClicked = () => {
    setPopupMode({
      ...popupMode,
      open: true,
      mode: 'write'
    });
  };

  // Table
  const finder : IFindTextBox = {
    name: 'title',
    onChange: onChange,
    onSearch: onSearch
  };
  const select : ISelectBox = {
    name: 'faqType',
    options: categoryOptions,
    value: params.faqType === '' ? 'ALL' : params.faqType,
    onChange: onCategorySearch,
  };

  const onChangeServiceType = (event: React.SyntheticEvent<HTMLElement, Event>, getData: any) => {    
    const value = getData.value === 'COMMON' ? 'SELLERSPOT,IRANK,REVIEWRAY' : getData.value;
    refresh({
      ...params,
      page: 1,
      serviceTypes: value
    });
  };

  const select2 : ISelectBox = {
    name: 'serviceTypes',
    options: serviceType,
    value: params.serviceTypes,
    onChange: onChangeServiceType,
  };

  const title = <><div style={{font:'inherit', textAlign:'left'}}>제목</div></>;
  const headers = ['', title, '노출여부', '작성자', '등록일자', ''];
  const headerWidth = [2, 7, 1, 1, 2, 1];
  const pagination : IPagination = {
    name: 'page',
    activePage: params.page,
    totalPage: faqPage.totalPages,
    onChange: onChangePage, 
  };
  const buttons: ITableListButton[] = [
    {
      name: '등록하기',
      icon: 'add',
      onButtonClicked: onAddButtonClicked
    }
  ];
  const tableListProps: ITableListProps = {
    title: '',
    size: 'small',
    color: 'violet',
    select,
    select2,
    finder,
    headers,
    headerWidth,
    buttons,
    pagination,
    count: faqPage.totalElements,
    refresh: () => refresh()
  }


  const itemList = faqList.length > 0 ? faqList.map((data:any, index : number) => (        
    <FaqListItem 
      category={faqCode}
      key={index}
      data={data}
      setPopupMode={setPopupMode}
    />
  )) : null;

  // Popup
  const deletePopup = popupMode.open && popupMode.mode === 'delete' ?
    <FaqDelete
      selectedDeleteId={popupMode.faqId}
      open={popupMode.open}
      onClose={onClose}
      showFeedback= {(msg:string) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  : null;

  const writePopup = popupMode.open && ( popupMode.mode === 'write' || popupMode.mode === 'edit' ) ?
    <FaqWrite
      faqCode={faqCode}
      faqId={popupMode.faqId}
      open={popupMode.open}
      onClose={onClose}
      showFeedback= {(msg:string) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  : null;

  const detailPopup = popupMode.open && popupMode.mode === 'detail' ?
    <FaqDetial
      faqId={popupMode.faqId}
      faqCode={faqCode}
      open={popupMode.open}
      onClose={onClose}
    />
  : null;

  return (
    <>
      <TableList {...tableListProps}>        
        { itemList }
      </TableList>

      {deletePopup}
      {writePopup}
      {detailPopup}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}

const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default FaqList;