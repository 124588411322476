import styled from 'styled-components';

interface IStyleButtonProps{
    selected?: boolean;
    missingKeyword?: boolean;
}

const Section = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 0;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%; /* 부모 컨테이너의 전체 너비 사용 */

`;

const Text = styled.p`
    font-size: 1.0em;
    margin: 0 10px 0 0;
    color: #333;
`;
const Article = styled.article`
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 10px;
`
const Button = styled.div <IStyleButtonProps>`
    --margin-right: 40px; /* margin-right 값 정의 */
    background-color: ${props => (props.selected ? '#BAD3E2' : 'white')};

    color: ${props => (props.missingKeyword ? 'red' : 'black')};
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px 30px;
    margin-top: 10px;
    margin-right: var(--margin-right); /* 변수 사용 */
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    //부모 컨테이너의 20% 너비 차지 -> 한줄에 5개의 버튼. width 는 margin-right을 고려해서 계산 해야된다.
    width: calc(20% - var(--margin-right)); /* 변수 사용하여 너비 계산 */
    height: 35px; 
    box-sizing: border-box; /* padding과 border를 width에 포함 */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap; 

    &:hover {
    background-color: #BAD3E2;
    }

    &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
    }

    &:nth-child(5n) {
        margin-right: 0; /* 마지막 버튼은 여백을 제거 -> margin-right 이 커질 경우 정렬 유지 안 될수 있음*/
    }
`;

export const TrendKeywordStyles = {
    Section,
    ButtonContainer,
    Text,
    Article,
    Button,
}