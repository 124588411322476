import styled, { css } from 'styled-components'

interface IStyleProps {
  scrollY: number;
  isSideView: boolean;
}

const MainCalendar = styled.div<IStyleProps>`
  ${({ isSideView, scrollY }) => isSideView ? css`
    display: flex;
    position: absolute;
    top: 50px;
    left: 0;
    transition: 0.1s;
    transform: ${`translate(-110%, ${scrollY}px)`};
  ` : css`
    display: flex;
    position: relative;
    transform: none;
  `}
  

  @media only screen and (max-width: 2050px) {
    display: flex;
    position: relative;
    transform: none;
  }
`

const CalendarWrap = styled.div`
  display: flex;
  position: relative;
`

export const CalendarStyles = {
  MainCalendar,
  CalendarWrap,
  
}
