import { IPage, initPage } from "store/modules/types";

export interface IRequestBannerParams {
  activeYn: boolean | string;
  serviceType: string;
  endDt: string;
  name: string;
  page: number;
  size: number;
  startDt: string;
}

export const initBannerRequestParams: IRequestBannerParams = {
  activeYn: '',
  serviceType: '',
  endDt: '',
  name: '',
  page: 1,
  size: 5,
  startDt: '',
}

export interface BannerItem {
  activeYn: boolean;
  defaultYn: boolean;
  bannerId: number;
  serviceType: string;
  deviceType: 'PC' | 'MOBILE' | undefined;
  endDt: string;
  imageName: string;
  imagePath: string;
  link: string;
  name: string;
  regDt: string;
  startDt: string;
  updateDt: string;
  priority: number;
  rgb: string
  //viewLocation: 'HOME' | undefined;
}
export interface IBannerData {
  list: BannerItem[],
  page: IPage,
}

export const initBannerData: IBannerData = {
  list: [],
  page: initPage,
}