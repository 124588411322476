import { Card, TextField, Container, CardActions, Typography } from '@material-ui/core';

import styled from 'styled-components';

const RootDiv = styled(Container)`
  &&& {
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;

const TitleDiv = styled(Container)`
  &&& {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    background-color: #f8f8f8;
    border-top: 1px solid #000;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }
`;

const TitleText = styled(Typography)`
  &&& {
    font-size: '0.9rem';
  }
`;

const ContentDiv = styled(Container)`
  &&& {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const ButtonDiv = styled(Container)`
  &&& {
    margin-top: 20px;
  }
`;

export const TableItemDetailStyles = {
  RootDiv,
  TitleDiv,
  TitleText,
  ContentDiv,
  ButtonDiv
}