import React from 'react';

// UI Components
import { Label, LabelProps } from 'semantic-ui-react';

interface IProps {
  color: LabelProps['color']
  
}

const ListItemLabel: React.FC<IProps> = (props) => {
  const { color, children } = props;

  return (
    <Label color={color} style={{ fontSize: '0.75rem' }} ribbon>
      {children}
    </Label>
  )
}

export default ListItemLabel;