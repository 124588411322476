import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { DemoState, initPage } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

// ACTION PART
const action = {
  requestGetReviewDemos: createAction(actionTypes.GET_REVIEW_DEMOS),
  succeededGetReviewDemos: createAction(actionTypes.SUCCEEDED_GET_REVIEW_DEMOS),
  failedGetReviewDemos: createAction(actionTypes.FAILED_GET_REVIEW_DEMOS),

  requestGetReviewDemoById: createAction(actionTypes.GET_REVIEW_DEMOS_BY_ID),
  succeededGetReviewDemoById: createAction(actionTypes.SUCCEEDED_GET_REVIEW_DEMOS_BY_ID),
  failedGetReviewDemoById: createAction(actionTypes.FAILED_GET_REVIEW_DEMOS_BY_ID),

  requestGetReviewDemoUsers: createAction(actionTypes.GET_REVIEW_DEMOS_USERS),
  succeededGetReviewDemoUsers: createAction(actionTypes.SUCCEEDED_GET_REVIEW_DEMOS_USERS),
  failedGetReviewDemoUsers: createAction(actionTypes.FAILED_GET_REVIEW_DEMOS_USERS),

  requestPatchReviewDemoComplete: createAction(actionTypes.PATCH_REVIEW_DEMOS_COMPLETE),
  succeededPatchReviewDemoComplete: createAction(actionTypes.SUCCEEDED_PATCH_REVIEW_DEMOS_COMPLETE),
  failedPatchReviewDemoComplete: createAction(actionTypes.FAILED_PATCH_REVIEW_DEMOS_COMPLETE),

  requestPatchReviewDemoCancel: createAction(actionTypes.PATCH_REVIEW_DEMOS_CANCEL),
  succeededPatchReviewDemoCancel: createAction(actionTypes.SUCCEEDED_PATCH_REVIEW_DEMOS_CANCEL),
  failedPatchReviewDemoCancel: createAction(actionTypes.FAILED_PATCH_REVIEW_DEMOS_CANCEL),
}
//
const initialState: DemoState = {
  demoList: {
    list: [],
    page: initPage
  }
};

const reducer = handleActions<DemoState>(
  {
    [actionTypes.GET_REVIEW_DEMOS]: (state, action): DemoState => {
      return state
    },
    [actionTypes.SUCCEEDED_GET_REVIEW_DEMOS]: (state, action): DemoState => {
      return {
        ...state,
        demoList: action.payload.demoList
      }
    },
    [actionTypes.GET_REVIEW_DEMOS_BY_ID]: (state, action): DemoState => {
      return state
    },
  },
  initialState
);

 // EPIC PART
  //  Notice list
  const requestGetReviewDemos = (actions$: any) => { 
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_REVIEW_DEMOS,      
        success: (res: AjaxResponse) => action.succeededGetReviewDemos({ demoList: res.response.data }),
      },
      url: API_URL.REVIVEWRAY_DEMOS,
      method: AjaxMethods.GET,
    };
    return epicService.request(actions$, reqParams);
  }

  const requestGetReviewDemoById = (actions$: any) => { 
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_REVIEW_DEMOS_BY_ID,      
        success: (res: AjaxResponse) => action.succeededGetReviewDemoById(),
      },
      url: API_URL.REVIVEWRAY_DEMOS,
      method: AjaxMethods.GET,
    };
    return epicService.request(actions$, reqParams);
  }

  const requestGetReviewDemoUsers = (actions$: any) => { 
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.GET_REVIEW_DEMOS_USERS,      
        success: (res: AjaxResponse) => action.succeededGetReviewDemoUsers(),
      },
      url: `${API_URL.REVIVEWRAY_DEMOS}/users`,
      method: AjaxMethods.GET,
    };
    return epicService.request(actions$, reqParams);
  }

  const requestPatchReviewDemoComplete = (actions$: any) => { 
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.PATCH_REVIEW_DEMOS_COMPLETE,      
        success: (res: AjaxResponse) => action.succeededPatchReviewDemoComplete(),
      },
      url: `${API_URL.REVIVEWRAY_DEMOS}/complete`,
      method: AjaxMethods.PATCH,
    };
    return epicService.request(actions$, reqParams);
  }

  const requestPatchReviewDemoCancel = (actions$: any) => { 
    const reqParams: IEpicReqParams = {
      actions: {
        request: actionTypes.PATCH_REVIEW_DEMOS_CANCEL,      
        success: (res: AjaxResponse) => action.succeededPatchReviewDemoCancel(),
      },
      url: `${API_URL.REVIVEWRAY_DEMOS}/cancel`,
      method: AjaxMethods.PATCH,
    };
    return epicService.request(actions$, reqParams);
  }

  const epic = combineEpics(
    requestGetReviewDemos,
    requestGetReviewDemoById,
    requestGetReviewDemoUsers,
    requestPatchReviewDemoComplete,
    requestPatchReviewDemoCancel,

  );
  
  export { action, epic };
  export default reducer;