import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useUserContext } from "../interface"
import useUsers from "hooks/useUsers"
import BasePopup, { PopupTypes } from "components/common/popup/BasePopup"
import { IReviewrayProducts, initReviewrayProducts } from "store/modules/types"
import ReviewrayProductItem from "./ReviewrayProductItem"


interface IStyleProps {
  scrollY?: number;
  isSideView?: boolean;
  isActive?: boolean;
}

const HeaderWrap = styled.div`
  display: grid;
  grid-template-columns: 5% 75% 10% 10%;

  border: 1px solid #D8D8D8;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    color: #353535;
    font-weight: 400;
  }

  & > span:not(:last-child) {
    border-right: 1px solid #D8D8D8;
  }
`

const Wrap = styled.div`
  
`

const ListWrap = styled.ul`
  border-left: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
`

const PopupListPageNation = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PopupListPageButton = styled.button<IStyleProps>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 400;
  border: 1px solid ${({ isActive }) => isActive ? '#000' : 'rgba(0,0,0,0.12)'};
  background-color: ${({ isActive }) => isActive ? 'rgba(0,0,0,0.16)' : '#fff'};
`

interface IProps {
  open: boolean;
  onClose: () => void;
}

const ReivewrayProducts: React.FC<IProps> = (props) => {
  const { open, onClose } = props

  const { selectedUser } = useUserContext();
  const { requestGetReviewrayProducts } = useUsers()
  const [ currentPage, setCurrentPage ] = useState<number>(1)
  const [ reviewrayProducts, setReviewrayProducts ] = useState<IReviewrayProducts>(initReviewrayProducts)  

  useEffect(() => {
    if (selectedUser) {
      const payload: any = {
        queryParameters : {
          customerId: selectedUser.memberDetails.customer.customerId,
          page: currentPage,
          size: 5
        },
        callback: (succeeded: boolean, res:any) => {
          if (succeeded) {
            setReviewrayProducts(res.response.data)
          }
        }
      }
      requestGetReviewrayProducts(payload)
    }
  }, [selectedUser])

  if (!selectedUser) return <></>

  const onChangePrevPageNumber = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage- 1)
    }
  }

  const onChangeNextPageNumber = () => {
    if (currentPage < reviewrayProducts.page.totalElements) {
      setCurrentPage(currentPage+ 1)      
    }
  }

  const onChangeFirstPageNumber = () => {
    if (currentPage > 1) {
      setCurrentPage(1)
    }
  } 

  const onChangeLastPageNumber = () => {
    if (currentPage < reviewrayProducts.page.totalElements) {
      setCurrentPage(reviewrayProducts.page.totalElements)
    }
  } 

  return <BasePopup
    open={open}
    id={Math.random()}
    size='large'
    type={PopupTypes.BASIC}
    title='리뷰레이 구독상품'
    onClose={onClose}
  >
    <Wrap>
      <HeaderWrap>
        <span>No</span>
        <span>상품</span>
        <span>초기 수집수</span>
        <span>분석 리뷰수</span>
      </HeaderWrap>
      <ListWrap>
        {reviewrayProducts.list.map((product, idx) => <ReviewrayProductItem product={product} no={idx + 1 + ((currentPage - 1) * reviewrayProducts.page.size)} />)}
      </ListWrap>
      <PopupListPageNation>
        <PopupListPageButton onClick={onChangeFirstPageNumber}>{'<<'}</PopupListPageButton>
        <PopupListPageButton onClick={onChangePrevPageNumber}>{'<'}</PopupListPageButton>
        {Array.from({ length: reviewrayProducts.page.totalPages }, (v, i) => i + 1).map((num) => (
          <PopupListPageButton 
            isActive={num === reviewrayProducts.page.page} 
            onClick={() => setCurrentPage(num)}
          >
            {num}
          </PopupListPageButton>
        ))}
        <PopupListPageButton onClick={onChangeNextPageNumber}>{'>'}</PopupListPageButton>
        <PopupListPageButton onClick={onChangeLastPageNumber}>{'>>'}</PopupListPageButton>
      </PopupListPageNation>
    </Wrap>
  </BasePopup>
}

export default ReivewrayProducts