import React, { SyntheticEvent, useState } from 'react';

// UI
import { Label, LabelProps, Image } from 'semantic-ui-react';
import { Link } from '@material-ui/core';

// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
import SalonOnDelete from './popup/SalonOnDelete'
import ImagePreview from '../common/imagepreview/ImagePreview'

// Hooks

// CSS
import './SalonOnList';

// Config
// import { SERVER_URL } from 'store/modules/types/epics';
import { IDsonListItem } from './interfaces';


interface IProps {
  category: any[];
  loginUser: any;
  data: IDsonListItem;
  requestParams: any;
  onShowDetail: Function;
  onDelete: Function;
  setIsDeleted: Function;
  onEditDetail: Function;
  onRequest: Function;
  showFeedback: { (msg: string): void };
}

const SalonOnListItem: React.SFC<IProps> = (props) => {
  const { category, loginUser, requestParams, data, onDelete, onShowDetail, setIsDeleted, onEditDetail, onRequest, showFeedback } = props;
  // QnA 데이터 속성과 매핑
  const { postsId, categoryCode, thumbnailFileName, thumbnailFilePath, mainTitle, user, updateDt, regDt, viewCount } = data;

  const [ openDelete, setOpenDelete] = useState(false);

  const config = require('../../config/index');
  const serverUrl = config.WEB_URL.includes('localhost') ? config.DEV_URL : config.WEB_URL;

  const onCloseDelete = () => {
    setOpenDelete(false);
  }

  const onDeleteButtonClicked = (e: SyntheticEvent, item: any) => {
    const id = (item && item.data) || undefined;
    if (id) {
      if (item.name === 'edit') {
        onEditDetail(postsId);
      }
      else if (item.name === 'delete'){
        setOpenDelete(true);
      }
    }
  };

  const onDeleteItem = () => {
    onDelete(postsId);
  }
  
  
  // console.log('category : ', category);
  const categoryValueToText = (value : string) => {
    if(category){
      const matchedItem = category.filter(item => item.value === value)
      return matchedItem.length > 0 ? matchedItem[0].text : 'none';
    }
    return 'none';
  }
  
  const imageIcon = (path: string, name: string) => {
    if(!path || !name)
      return ( <> 이미지 없음 </>)
    let img = serverUrl+path+name;
    return(
        <div style={{textAlign:'center'}}>
          <ImagePreview imgSrc={img}>
            <Image style={{display:''}} src={img} size='tiny' />
          </ImagePreview>
        </div>
    )
  }
  
  const dateToLocale = (regDt: any) => {    
    const regDate = new Date(regDt)
    const year = regDate.getFullYear();
    const month = regDate.getMonth() < 10 ? '0' + (regDate.getMonth()+1) : (regDate.getMonth()+1);
    const date = regDate.getDate() < 10 ? '0' + regDate.getDate() : regDate.getDate();

    // const regDate = regDt != undefined ? regDt.split('T')[0] : "0000-00-00";
    return year+'-'+month+'-'+date;
  }

  // const role = (authorities && authorities[0] && authorities[0].authority) || '';
  
  const tableListItemsProps: ITableListItemProps = {
    id: postsId,
    items: [
      setLabel(categoryValueToText(categoryCode)), 
      imageIcon(thumbnailFilePath, thumbnailFileName),
      // mainTitle,
      (<div style={{font:'inherit', textAlign:'left'}}><a href='#' onClick={()=>onShowDetail(postsId)}>{mainTitle}</a></div>), 
      user?.userId, 
      dateToLocale(updateDt),
      dateToLocale(regDt),
      viewCount
    ],
    iconButtons: getButtons(onDeleteButtonClicked)
  }

  return (
    <>
      <TableListItem {...tableListItemsProps} />
      
      <SalonOnDelete
        id={postsId}
        open={openDelete}
        onDelete={onDeleteItem}
        onClose={onCloseDelete}
      />
    </>
  );
}

const setLabel = (labelName: string) => {
  const color = 'red'
  return (
    <>
      <Label color={color} ribbon style={{ fontSize: '0.75rem', width: '100px' }}>{labelName}</Label>
    </>
  )
}

const getButtons = (onClicked: any, isDeleted?: boolean) => {
  const iconButtons: ITableListItemIconButton[] = [];
  const editButton: ITableListItemIconButton = {
    color: 'green',
    iconName: 'edit',
    tooltip: 'Edit',
    onItemClicked: onClicked
  };
  const deleteButton: ITableListItemIconButton = {
    color: 'red',
    iconName: 'delete',
    tooltip: 'Delete' ,
    onItemClicked: onClicked
  }  
  iconButtons.push(editButton);  
  iconButtons.push(deleteButton);

  return iconButtons;
}

export default SalonOnListItem;