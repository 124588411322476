import styled from 'styled-components'

interface IStyleProps {
  align?: 'left' | 'right';
  select?: boolean;
  margin?: string;
}

const Wrap = styled.article`
  width: 100%;
  padding: 0 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Button = styled.button`
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 8px 50px;
  font-size: 13px;
  font-weight: 700;
`

const Div = styled.div<IStyleProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ align }) => align ? align : 'center'};
  align-items: center;
  margin: ${({ margin }) => margin && margin};
`

const ArrowButton = styled.button`
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`

const PageButton = styled.button<IStyleProps>`
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: ${({ select }) => select ? 'black' : '#cccccc'};
`

export const BannerStyles = {
  Wrap,
  Button,
  Div,
  ArrowButton,
  PageButton,
}
