import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules

// actions
import * as actionTypes from './types/actions';

import epicService, {API_URL, IEpicReqParams, AjaxMethods } from './types/epics'; 

// ACTION PART
const action = {
  // getCrawlingSummary
  getCrawlingSummary: createAction(actionTypes.GET_CRAWLING_SUMMARY),
  
}

const initialState = {
};

const reducer = handleActions(
  {
    [actionTypes.GET_CRAWLING_SUMMARY]: (state) => {
      return state
    },
  },
  initialState
);

// EPIC PART
// const requestCrawlingSummaryEpic = (actions$: any) => {
//   const reqParams: IEpicReqParams = {
//     actions: {
//       request: actionTypes.GET_CRAWLING_SUMMARY
//     },
//     url: API_MONITORING_URL.SUMMARY,
//     method: AjaxMethods.POST,
//     headers: {
//       contentType: 'application/x-www-form-urlencoded'
//     }
//   };
//   return epicService.request(actions$, reqParams);
// }

const epic = combineEpics(
  // requestCrawlingSummaryEpic,
);

export { action, epic };
export default reducer;