import styled from 'styled-components';
import { Form, Image } from 'semantic-ui-react';
import { Container } from '@material-ui/core';

const HtmlContents = styled.div`
  height: 600px;
  border: 1px solid rgba(34,36,38,.15);
  background-color: rgb(240, 240, 240);  
  padding: .78571429em 1em; 
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  overflow: scroll;
`;

const Wrap = styled.div`
  display: flex;

  & + & {
    margin-top: 30px;
  }
`

const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;

  td {
    height: 50px !important;
    vertical-align: middle;
    border: 1px solid black;
    border-collapse: collapse;

    padding-left: 10px;

    font-size: 12px;
    font-weight: 400;
    color:  #353535;
  }

  td:nth-child(even) {

    font-size: 12px;
    font-weight: 400;
    color:  #353535;
  }

  td:nth-child(odd) {

    font-size: 12px;
    font-weight: 600;
    color:  #353535;
  }

  td > div.selection {
    width: 150px !important;
    min-width: 150px !important;
  }
`


const Input = styled.input`
  width: 100px;
  font-size: 12px;
  padding: 10px;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 5px;

  &:focus {
    outline: 1px solid black;
  }

  &:hover {
    outline: 1px solid black;
  }
`


export const DemoPopupStyles = {
  Wrap,
  Table,
  HtmlContents,
  Input,
}