import React, { useEffect, useState } from "react";
import { MonitoringProducts } from "store/modules/types";
import { MonitoringInfoStyles as Styled } from "../styles/MonitoringStyle/MonitoringInfo.style";
import MonitoringProductCard from "../Card";
import {
  Table,
  Title,
  RecollectButtonContainer,
} from "../styles/CommonStyle/Common.style";
import { CustomizedButton } from "../styles/CommonStyle/Common.style";
import { OpenInNew } from "@material-ui/icons";
import useMonitoringProduct from "hooks/useMonitoringProduct";
interface IProps {
  searchDate: string;
  total: number;
  failedCount: number;
  monitoringProducts: MonitoringProducts[];
}
const MonitoringProduct: React.FC<IProps> = (props) => {
  const { searchDate, total, failedCount, monitoringProducts } = props;
  const today = new Date().toISOString().slice(0, 10);
  const { requestRecollectProductMonitoring } = useMonitoringProduct();
  const [checkedProducts, setCheckedProducts] =
    useState<MonitoringProducts[]>(monitoringProducts);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );

  const getInitialCheckedItems = (num: number) => {
    const initialCheckedItems: { [key: number]: boolean } = {};
    for (let i = 0; i < num; i++) {
      initialCheckedItems[i] = true;
    }
    return initialCheckedItems;
  };

  useEffect(() => {
    setCheckedProducts(monitoringProducts);
    setCheckedItems(getInitialCheckedItems(monitoringProducts.length));
  }, [monitoringProducts]);

  const handleCheckboxChange = (idx: number) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [idx]: !prevCheckedItems[idx],
      };

      const updatedCheckedProducts = monitoringProducts.filter(
        (_, index) => newCheckedItems[index]
      );

      setCheckedProducts(updatedCheckedProducts);
      return newCheckedItems;
    });
  };

  const onRecollect = (monitoringKeywords: MonitoringProducts[]) => {
    if (!monitoringKeywords.length) {
      alert("미수집 리스트가 존재 하지 않습니다");
      return;
    }
    const productIds: number[] = Array.from(
      new Set(monitoringKeywords.map((obj) => obj.id))
    );
    const payload: any = {
      body: {
        crawlerTarget: "TARGETADMINDAILY",
        productIds: productIds,
        productItemIds: [],
      },
      callback: (succeeded: boolean, res: any) => {
        if (succeeded) {
          alert("재수집 요청 성공");
        } else {
          if (res.status === 409) {
            alert("재수집 중");
          } else {
            alert("재수집 실패");
          }
        }
      },
    };
    requestRecollectProductMonitoring(payload);
  };

  return (
    <Styled.Container>
      <RecollectButtonContainer>
        <Title>상품 정보</Title>
        {searchDate === today && (
          <CustomizedButton onClick={() => onRecollect(checkedProducts)}>
            재수집
          </CustomizedButton>
        )}
      </RecollectButtonContainer>
      <Styled.CardContainer>
        <MonitoringProductCard
          text="전체"
          value={total}
        ></MonitoringProductCard>
        <MonitoringProductCard
          text="수집완료"
          value={total - failedCount}
        ></MonitoringProductCard>
        <MonitoringProductCard
          text="실패"
          value={failedCount}
        ></MonitoringProductCard>
      </Styled.CardContainer>
      <Styled.Text>미수집 리스트 ({monitoringProducts.length})</Styled.Text>
      {monitoringProducts.length > 0 && (
        <Table isChecklist={true}>
          <thead>
            <tr>
              <th> product_id </th>
              <th> id </th>
              <th> 상품명 </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {monitoringProducts.map((item, idx) => (
              <tr key={idx}>
                <td>{item.productId}</td>
                <td>
                  {item.id}{" "}
                  <a href={item.productUrl} target="_blank">
                    <OpenInNew></OpenInNew>
                  </a>
                </td>
                <td>{item.productName}</td>
                <td>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(idx)}
                    checked={!!checkedItems[idx]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Styled.Container>
  );
};
export default MonitoringProduct;
