import React, { useState, SyntheticEvent } from 'react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

// Hooks
import useQna from 'hooks/useQna';

interface IProps {
  id: number;
  open: boolean;
  requestParams: any;
  onClose(): void;
  onRequest: Function;
  showFeedback: Function;
}

const QnaDelete: React.SFC<IProps> = (props) => {

  const { onDeleteQna } = useQna();

  const { id, open, requestParams, onClose, onRequest, showFeedback } = props;

  const onDelete = (e: SyntheticEvent, item: any) => {
    // console.log("onDeleteQna", item);

    const id = (item && item.data) || undefined;
    if (id) {
      onDeleteQna({
        pathVariables : {qnaId : id},
        callback : (succeeded: boolean, res: any) => {
          if(succeeded){
            onRequest(requestParams);
          }            
        }
      });
    }
    showFeedback('QnA deleted');
    onClose();
  }

  return (
    <BasePopup
      open={open}
      id={id}
      size='tiny'
      type={PopupTypes.DIALOG}
      title='Delete QnA'
      buttons={[
        {}, { onClick: onDelete }
      ]}
      onClose={onClose}
    >
      <span>Are you sure want to delete QnA?</span>
    </BasePopup>
  );
}

export default QnaDelete;