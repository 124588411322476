import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/naverAttribute";

const useNaverAttribute = () => {
  const dispatch = useDispatch();
  const naverAttribute = useSelector(
    (state: StoreState) => state.naverAttribute
  );
  const requestNaverAttribute = () => {
    dispatch(action.requestNaverAttribute());
  };

  return {
    naverAttribute,
    requestNaverAttribute,
  };
};

export default useNaverAttribute;
