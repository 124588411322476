import styled from 'styled-components'

interface IStyleProps {
  flex?: number
}

const Wrap = styled.div`
  display: flex;
  width: 100%;
`

const Section = styled.section<IStyleProps>`
  width: 100%;
  flex: 1;
  display: grid;
  padding-right: 70px;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
`

const GridSection = styled.section`
  flex: 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
`

export const QnaInfoStyles = {
  Section,
  Wrap,
  GridSection,
}