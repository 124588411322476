import React, { useState, SyntheticEvent } from 'react';
// Hooks
import useUsers from 'hooks/useUsers';
import useFaq from 'hooks/useFaq';
// Components
import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

interface IProps {
  selectedDeleteId: number;
  open: boolean;
  onClose(): void;
  showFeedback: Function;
}
const FaqDelete: React.FC<IProps> = (props) => {

  const { selectedDeleteId, open, onClose, showFeedback,  } = props;
  const { requestFaqDelete } = useFaq();

  const onConfirm = () => {
    if (selectedDeleteId) {
      const payload: any = {
        pathVariables: { faqId: selectedDeleteId },
        callback: (succeeded: boolean, res: any) => {
          console.log( "faq delete ::", res );
          if(succeeded){
            showFeedback('공지사항 삭제 완료되었습니다.');
          }else{
            showFeedback('공지사항 실패 하였습니다.');
          }
        }
      }
      requestFaqDelete(payload);
    }
    else {
      console.log("시스템 일시적인 장애입니다. 다시 시도하여주세요.");
    }
    onClose();
  };

  return (
    <BasePopup
      id={selectedDeleteId}
      open={open}
      size="small"
      type={PopupTypes.DIALOG}
      title='FAQ 삭제'
      buttons={[
        {}, { onClick: onConfirm }
      ]}
      onClose={onClose}
    >
      <span>삭제하시겠습니까?</span>
    </BasePopup>
  )
}

export default FaqDelete;