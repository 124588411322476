import React, { MouseEvent, useState } from 'react'
// Common
import TableListItem, { ITableListItemIconButton, ITableListItemProps } from 'components/common/table/TableListItem';
// Interface
import { EmailTemplateItem } from 'store/modules/types';
// Components
import { popuptype } from './EmailTemplateList';


interface IProps {
  item: EmailTemplateItem,
  itemNumber: number,
  onTemplatePopupOpen: (itemNumber: number, item: EmailTemplateItem, mode: popuptype) => void,
  setOpenTemplatePopup: Function,
  setTemplatePopupMode: Function,
}

const EmailTemplateListItem: React.FC<IProps> = (props) => {

  const { 
    item, 
    itemNumber, 
    onTemplatePopupOpen,
    setOpenTemplatePopup,
    setTemplatePopupMode
  } = props;

  const titleItem = (
    <a 
      href='#' 
      onClick={() => onTemplatePopupOpen(itemNumber, item, 'detail')}
    >
      {item.templateName}
    </a>
  )

  const onButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.className.indexOf('edit') > -1) {
      setOpenTemplatePopup(true);
      setTemplatePopupMode({
        itemNumber: itemNumber,
        item: item,
        mode: 'write'
      })
    } else {
      setOpenTemplatePopup(true);
      setTemplatePopupMode({
        itemNumber: itemNumber,
        item: item,
        mode: 'delete'
      })
    }
  }

  const getButtons = (onClicked: any) => {
    const iconButtons: ITableListItemIconButton[] = [];
    const editButton: ITableListItemIconButton = {
      color: 'green',
      iconName: 'edit',
      tooltip: 'edit event',
      onItemClicked: onClicked
    };
    const deleteButton: ITableListItemIconButton = {
      color: 'red',
      iconName: 'delete',
      tooltip: 'delete event',
      onItemClicked: onClicked
    };
    iconButtons.push(editButton);
    iconButtons.push(deleteButton);
    return iconButtons;
  }

  const options: Intl.DateTimeFormatOptions = { 
    month: "2-digit", 
    day: "2-digit", 
    year: "numeric",
  };

  const regDt = item.regDt ? new Intl.DateTimeFormat('en-CA', options).format(new Date(item.regDt)) : '';
  const updateDt = item.updateDt ? new Intl.DateTimeFormat('en-CA', options).format(new Date(item.updateDt)) : '';

  const tableListitemProps: ITableListItemProps = {
    id: itemNumber,
    items: [
      titleItem,
      regDt,
      updateDt,
    ],
    iconButtons: getButtons(onButtonClicked)
  }

  return (
    <>
      <TableListItem {...tableListitemProps} />
    </>
  )
}

export default EmailTemplateListItem
