import styled, { css } from 'styled-components'

interface IStyleProps {
  isSucceded?: boolean
  listWidthList?: string
  isHoverEvent?: boolean
}

const Wrap = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const HeaderWrap = styled.div<IStyleProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ listWidthList }) => listWidthList};
  border: 1px solid #0000001f;
  border-bottom: none;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #f0f8ff;
`

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Item = styled.li<IStyleProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ listWidthList }) => listWidthList};
  border: 1px solid #0000001f;

  & + & {
    border-top: none;
  }

  ${({ isHoverEvent }) => isHoverEvent && css`
    &:hover {
      background-color: #f9f9f9;
    }
  `}
`

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #0000001f;
`

export const ListStyles = {
  Wrap,
  HeaderWrap,
  Header,
  List,
  Item,
  NoData,
}
