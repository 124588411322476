import React from 'react';


// Components
import Header from 'components/layout/Header';
import Menu from 'components/layout/Menu';
import Main from 'components/layout/Main';
import NextLogin from 'components/login/NextLogin';

// Hooks
import useBasics from 'hooks/useBasics';


const RootContainer: React.SFC = () => {

  const { basics, isAuthenticated } = useBasics();
  const { menu } = basics;

  const authenticated = isAuthenticated();
  // console.log('authenticated', authenticated)

  let mainComp = authenticated ? <Main selectedMenu={menu} /> : <NextLogin />;

  return (
    <>
      { authenticated ? <Header authenticated={authenticated} /> : null }
      <main id='main-container' style={{ marginTop: authenticated ? '64px' : 0 }}>
        {mainComp}
      </main>
    </>
  )
}

export default RootContainer;