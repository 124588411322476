import React, { SyntheticEvent } from 'react';
// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
import { Icon } from 'semantic-ui-react';
// Styles
import { NoticeListItemStyles as Styled } from './styles/NoticeListItem.styles';

interface IProps {
  item: any;
  key: number;
  setPopupMode: Function;
}

const NoticeListItem: React.FC<IProps> = (props) => {

  const { item, setPopupMode } = props;
  const { noticeNo, title, importantYn, viewYn, topYn, loginYn, regDt } = item;

  // Function
  const onButtonClicked = (e: SyntheticEvent, item: any) => {
    const id = (item && item.data) || undefined;
    const name = item.name;
    if(id){
      setPopupMode({
        open: true,
        mode: name,
        noticeNo: noticeNo
      });
    }
  };

  const onDetial = (no:any) => {
    setPopupMode({
      open: true,
      mode: 'detail',
      noticeNo: no
    });
  };

  const titleTag =  <>
    <Styled.ItemHover onClick={() => onDetial(noticeNo)}>
      <a>
        {title}
      </a>
    </Styled.ItemHover>
  </>;
  const importantState = importantYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const viewState = viewYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const topState = topYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const loginState = loginYn ? <Icon color='green' name='checkmark' size='large' /> : '';
  const regDate = regDt !== undefined ? new Date(regDt).toLocaleDateString() : '';
  const tableListItemsProps: ITableListItemProps = {
    id: noticeNo,
    items: [ titleTag, importantState, viewState, topState, loginState, regDate ],
    iconButtons: getButtons(onButtonClicked)
  };

  return (
    <>
      <TableListItem {...tableListItemsProps} />
    </>
  )
}
const getButtons = (onClicked: any) => {
  const iconButtons: ITableListItemIconButton[] = [];
  const editButton: ITableListItemIconButton = {
    color: 'green',
    iconName: 'edit',
    tooltip: 'edit notice',
    onItemClicked: onClicked
  };
  const deleteButton: ITableListItemIconButton = {
    color: 'red',
    iconName: 'delete',
    tooltip: 'delete notice',
    onItemClicked: onClicked
  }

  iconButtons.push(editButton);
  iconButtons.push(deleteButton);
  return iconButtons;
}
export default NoticeListItem;