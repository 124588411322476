import React, { useState } from 'react';

// Components
import TableList, { ITableHeader, ITableListProps, ITableListButton, IPagination, ISearchTextBox } from 'components/common/nextTable/TableList';
import NoticeListItem from './NoticeListItem';
import NoticeDetail from './NoticeDetail';

export interface INoticeReqParams {
  pageNumber: number;
  pageSize: number;
  searchDetail: string;
}

export const initNoticeReqParams: INoticeReqParams = {
  pageNumber: 1,
  pageSize: 0,
  searchDetail: ''
}

interface IProps {
  data: any;
  onRequest: Function;
}

const NoticeList: React.FC<IProps> = (props) => {

  const { data, onRequest } = props;
  const [reqParams, setReqParams] = useState<INoticeReqParams>({...initNoticeReqParams});
  const [noticeIndex, setNoticeIndex] = useState(-1);

  const onAddButtonClicked = () => {
    console.log('onAddUserButtonClicked');
  };

  const onSearch = (text : string) => {
    const params: INoticeReqParams = {
      ...reqParams,
      searchDetail: text,
      pageNumber: 1
    }

    setReqParams(params);

    onRequest(params);
  }

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page: number) => {
    console.log("onChangePage", page, reqParams);

    const params: INoticeReqParams = {
      ...reqParams,
      pageNumber: page
    }

    setReqParams(params);
    
    console.log("onChangePage", params);

    onRequest(params);
  }

  const searchable : ISearchTextBox = {
    name: 'searchKey',
    onSearch: onSearch
  }

  const pagination : IPagination = {
    name: 'pageNumber',
    activePage: reqParams.pageNumber,
    totalPage: data.totalPages,
    totalCount: data.totalElements,
    onChange: onChangePage, 
  }

  const buttons: ITableListButton[] = [
    {
      name: 'Add Terms',
      icon: 'add',
      onButtonClicked: onAddButtonClicked
    }
  ];

  const headers: ITableHeader[] = [
    { title: '제목', width: '65%' },
    { title: '중요', width: '5%' },
    { title: '노출', width: '5%' },
    { title: '조회수', width: '10%' },
    { title: '등록일자', width: '15%' }
  ];

  const tableListProps: ITableListProps = {
    size: 'small',
    color: 'violet',
    buttons,
    headers,
    searchable,
    pagination,
    count: data && data.content ? data.content.length : 0,
    refresh: () => {}
  }

  const noticeItems = data.content && data.content.map((notice: any, index: number) => (
    <NoticeListItem
      key={index}
      notice={notice}
      onSelect={() => setNoticeIndex(index)}
    />
  ));

  return (
    <>
      {
        noticeIndex < 0 ? (
          <TableList {...tableListProps}>
            {noticeItems}
          </TableList>
        ) : (
          <NoticeDetail data={data.content[noticeIndex]} onToList={() => setNoticeIndex(-1)} />
        )
      }
    </>
  )
}

export default NoticeList;