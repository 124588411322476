import { useDispatch, useSelector } from 'react-redux';

// modules
import { StoreState } from 'store/modules/types';
import { action } from 'store/modules/notice';
import { ActionPayload } from 'store/modules/types';

const useNotice = () => {

  const dispatch = useDispatch();

  const notice = useSelector((state: StoreState) => state.notice);

  // Get List
  const requestGetNoticeList = (data: any) => {
    dispatch(action.getnoticelist(data));
  }

  // Get Detial
  const requestGetNoticeDetail = (payload: ActionPayload) => {
    dispatch(action.getdetailNotice(payload));
  }

  // Insert
  const requestInsertNotice = (payload: ActionPayload) => {
    dispatch(action.writenotice(payload));
  }

  // Update
  const requestUpdateNotice = (payload: ActionPayload) => {
    dispatch(action.updateNotice(payload));
  }

  // Delete
  const requestDeleteNotice = (payload: ActionPayload) => {
    dispatch(action.deleteNotice(payload));
  }

  return {
    notice,
    requestGetNoticeList,
    requestGetNoticeDetail,
    requestInsertNotice,
    requestUpdateNotice,
    requestDeleteNotice,
  }
}
 
export default useNotice;