import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { UserSalesState } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse, ajax } from 'rxjs/ajax';

// ACTION PART
const action = {
  requestGetUserSalesList: createAction(actionTypes.GET_USER_SALES),
  succeededGetUserSalesList: createAction(actionTypes.SUCCEDED_GET_USER_SALES),
  failedGetUserSalesList: createAction(actionTypes.FAILED_GET_USER_SALES),
}
//
const initialState = new UserSalesState();

const reducer = handleActions<UserSalesState>(
  {
    [actionTypes.GET_USER_SALES]: (state, action): UserSalesState => {
      return state
    },
    [actionTypes.SUCCEDED_GET_USER_SALES]: (state, action): UserSalesState => {
      return state
        .set('userSalesData', action.payload.userSalesData);
    },
    [actionTypes.FAILED_GET_USER_SALES]: (state, action): UserSalesState => {
      return state;
    },
  },
  initialState
);

// EPIC PART
const requestUserSalesEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_SALES,
      success: (res: AjaxResponse) => action.succeededGetUserSalesList({ userSalesData: res.response.data }),
      fail: actionTypes.FAILED_GET_USER_SALES
    },
    url: API_URL.USER_SALES,
    method: AjaxMethods.GET
  };
  
  return epicService.request(actions$, reqParams);
}


const epic = combineEpics(
  requestUserSalesEpic,
);

export { action, epic };
export default reducer;