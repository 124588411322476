import React, { useState, useRef } from 'react';

import { Header, Button, Icon, TableProps, IconProps, Input } from 'semantic-ui-react'

// material-ui
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import { Container, Table, TableHead, TableRow, TableCell, TableBody, Theme, Typography, Box, Paper, IconButton, InputBase, TextField, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import useBasics from 'hooks/useBasics';

export const useSearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 400,
      /*'&:focus-within': {
        boxShadow: `${fade(theme.palette.primary.main, 0.7)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      }*/
      '& .MuiInput-underline:after': {
        borderBottomColor: 'rgba(60, 90, 219, .7)',
      },
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    outlined: {
      padding: '10px'
    }
  }),
);

const usePaginationStyles = makeStyles((theme: Theme) => ({
  page: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .1)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.action.selected,
      '&:hover, &$focusVisible': {
        backgroundColor: theme.palette.action.selected,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.palette.action.selected,
        },
      },
    }
  },
  selected: {
    color: '#fff'
  }
}));

export interface ITableHeader {
  width?: string;
  title: string;
  textAlign?: string;
}

export interface ITableListButton {
  name: string;
  icon: IconProps['name'];
  onButtonClicked(): void;
}

export interface ISelectBox {  
  name: string;
  value?: any;
  defaultValue?: any;
  options: {
    value: any;
    text: any;
  }[];
  onChange: Function;
}

export interface ISearchTextBox {  
  name: string;
  value?: string;
  onSearch: Function;
}

export interface IPagination {  
  name: string;
  activePage: number;
  totalPage: number;  
  totalCount: number;
  onChange: Function;  
}

export interface ITableListProps {
  size?: TableProps['size'];
  color?: TableProps['color'];
  buttons?: ITableListButton[];
  headers: ITableHeader[];
  headerWidth? : number[];
  searchable?: ISearchTextBox;
  searchCategory?: any;
  select?: ISelectBox;
  pagination?: IPagination;
  showHeaderTitle?: boolean;
  count: number;
  refresh(): void;
}

const NextTableList: React.FC<ITableListProps> = (props) => {

  const searchClasses = useSearchStyles();
  const paginationClasses = usePaginationStyles();
  
  const { basics } = useBasics();
  const { menu } = basics;

  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = React.useState('all');

  const { 
    headers, 
    searchable, 
    searchCategory, 
    select, 
    pagination, 
    count, 
    showHeaderTitle = true,
    children, 
  } = props;

  const headerTitleDiv = showHeaderTitle && (
    <Container style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
      <Typography variant="h4">{menu.name}</Typography>
    </Container>
  )

  const searchDiv = searchable ? (
    <Container style={{ display: 'flex', justifyContent: 'center' }}>
      
      {
        searchCategory ? (
          <FormControl variant="outlined" className={searchClasses.formControl}>
            <InputLabel id="search-type-label">타입</InputLabel>
            <Select
              classes={{
                outlined: searchClasses.outlined
              }}
              labelId="earch-type-label"
              id="searchType"
              value={searchType}
              onChange={(e: any) => setSearchType(e.target.value)}
              label="타입"
            >
              <MenuItem value='all'>전체</MenuItem>
              {
                searchCategory.map((it: any) => (
                  <MenuItem key={it.value} value={it.value}>{it.text}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        ) : null
      }

      <div className={searchClasses.root}>
        <TextField
          className={searchClasses.input}
          placeholder="검색어를 입력해 주세요"
          inputProps={{ 'aria-label': 'search' }}
          value={searchText}
          onChange={(e: any) => setSearchText(e.target.value)}
          onKeyPress={(e: any) => { if (e.key === 'Enter') { searchable.onSearch(searchText, searchType) } }}
        />
        {
          searchText.length > 0 ? (
            <IconButton className={searchClasses.iconButton} aria-label="search" 
              onClick={(e: any) => setSearchText('')}
            >
              <CloseIcon />
            </IconButton>
          ) : null
        }
        <IconButton className={searchClasses.iconButton} aria-label="search" onClick={(e: object) => searchable.onSearch(searchText, searchType)}>
          <SearchIcon />
        </IconButton>
      </div>
    </Container>
  ) : null;
  
  const upperDiv = (
    <Container style={{ display: 'flex', padding: '20px 16px', alignItems: 'center' }}>
      <Box css={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
        <Typography>{`총 ${pagination && pagination.totalCount || 0}건`}</Typography>
      </Box>
      <Box css={{ display: 'flex', flex: 1, justifyContent: 'center' }}>

      </Box>
      <Box css={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
        
      </Box>
    </Container>
  );

  const tableHeader = headers ? headers.map((header: ITableHeader, index) => (
    <TableCell 
      key={index} 
      style={{ width: header.width || '', fontSize: '0.9rem', fontWeight: 700, textAlign: header.textAlign ? undefined : 'center' }}
    >
      {header.title}
    </TableCell>
  )) : null;

  const footerPagination = pagination && count > 0 ? (    
    <div style={{ display: 'flex', justifyContent: 'center', marginTop:'35px' }}>
      <Pagination
        defaultPage={pagination.activePage}
        count={pagination.totalPage}
        renderItem={
          (item) => <PaginationItem {...item} classes={{ page: paginationClasses.page, selected: paginationClasses.selected }} />
        }
        onChange={(event: object, page: number) => pagination.onChange(event, page)}
      />
    </div>
  ) : null;

  return (
    <Container style={{ marginTop: '30px', marginBottom: '50px' }}>

      {headerTitleDiv}
      {searchDiv}
      {upperDiv}

      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ borderTop: '1px solid #222' }}>
            {tableHeader}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            count !== 0 ? children : (
              <TableRow>
                <TableCell 
                  style={{ fontSize: '0.9rem' }}
                  colSpan={headers.length}
                  align='center'
                >
                  검색결과가 없습니다.
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>

      {footerPagination}

    </Container>
  )
}

export default NextTableList;