import React, { useEffect, useState } from 'react';
import { UserLogsStyles as Styled } from './styles/UserLogs.styles';

import LogSearch from './LogSearch';
import useUserLogs from 'hooks/useUserLogs';
import { initSearchParams, ISearchParams } from './interfaces';
import LogList from './LogList';

import {
  SearchParamsContext
} from './interfaces';

const UserLogs: React.FC = () => {
  const { requestUserLogs } = useUserLogs();
  const [ searchParams, setSearchParams ] = useState<ISearchParams>(initSearchParams);

  useEffect(() => {
    getUserLogs();
  }, []);

  const getUserLogs = (params?: ISearchParams) => {
    const payload: any = {
      queryParameters: {
        ...initSearchParams,
        ...searchParams,
        ...params,
      }
    };
    if (params) setSearchParams(params);
    requestUserLogs(payload);
  }

  const onHandleClick = () => {
    getUserLogs({
      ...searchParams,
      pageNumber: 1,
    });
  }

  return (
    <SearchParamsContext.Provider 
      value={{ searchParams, setSearchParams, onSearch: getUserLogs }}
    >
      <Styled.Wrapper>
        <LogSearch />
        <Styled.SearchButton onClick={onHandleClick}>검색</Styled.SearchButton>
        <LogList></LogList>
      </Styled.Wrapper>
    </SearchParamsContext.Provider>
  )
}

export default UserLogs;
