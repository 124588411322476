import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { UserLogsState } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse, ajax } from 'rxjs/ajax';

// ACTION PART
const action = {
  requestUserLogs: createAction(actionTypes.GET_USER_LOGS),
  succeededUserLogs: createAction(actionTypes.FULFILLED_USER_LOGS),
  failedUserLogs: createAction(actionTypes.FAILED_USER_LOGS),
}
//
const initialState = new UserLogsState();

const reducer = handleActions<UserLogsState>(
  {
    [actionTypes.GET_USER_LOGS]: (state, action): UserLogsState => {
      return state
    },
    [actionTypes.FULFILLED_USER_LOGS]: (state, action): UserLogsState => {
      const payload: any = action.payload
      const contents = payload.contents ? payload.contents : {
        content: [],
        size: 0,
        totalPages: 1,
        totalElements: 0,
      }
      return state
        .set('userLoginLogs', contents);
    },
    [actionTypes.FAILED_USER_LOGS]: (state, action): UserLogsState => {
      return state;
    },
  },
  initialState
);

// EPIC PART
const requestUserLogsEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_USER_LOGS,
      success: (res: AjaxResponse) => action.succeededUserLogs({ contents: res.response.contents }),
      fail: actionTypes.FAILED_USER_LOGS
    },
    url: API_URL.ADMIN_USER_LOGIN_LOG,
    method: AjaxMethods.GET
  };
  
  return epicService.request(actions$, reqParams);
}


const epic = combineEpics(
  requestUserLogsEpic,
);

export { action, epic };
export default reducer;