import { useDispatch, useSelector } from 'react-redux'
// Modules
import { action } from 'store/modules/emailTemplate';
import { ActionPayload, StoreState } from 'store/modules/types';

const useEmailTemplate = () => {

  const dispatch = useDispatch();

  const emailTemplate = useSelector((state: StoreState) => state.emailTemplate);

  // Get List
  const requestGetEmailTemplateList = (payload?: ActionPayload) => {
    dispatch(action.requestGetEmailTemplateList(payload && payload));
  }

  // Insert
  const requestInsertEmailTemplate = (payload: ActionPayload) => {
    dispatch(action.requestCreateEmailTemplateList(payload));
  }

  // Update
  const requestUpdateEmailTemplate = (payload: ActionPayload) => {
    dispatch(action.requestUpdateEmailTemplateList(payload));
  }

  // Delete
  const requestDeleteEmailTemplate = (payload: ActionPayload) => {
    dispatch(action.requestDeleteEmailTemplateList(payload));
  }

  return {
    emailTemplate,
    requestGetEmailTemplateList,
    requestInsertEmailTemplate,
    requestUpdateEmailTemplate,
    requestDeleteEmailTemplate,
  }
}

export default useEmailTemplate
