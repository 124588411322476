import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  margin-top: 20px;
`

const ChildrenWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  column-gap: 10px;
  row-gap: 10px;
`

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
`


export const MemebershipUseInfoStyles = {
  Wrap,
  ChildrenWrap,
  Title
}