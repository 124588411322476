import React, { useState, useEffect, SyntheticEvent } from 'react';

import { Select, Form, Message, Checkbox } from 'semantic-ui-react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

// Hooks
import useSalonon from 'hooks/useSalonon';

// Components
import TableList, { ITableListProps, ITableListButton, IFindTextBox, IPagination } from 'components/common/table/TableList';
import TagListItem from './TagListItem';

import '../interfaces';

const initRequestParams = {
  page : 1,
  size : 0,
  searchKey : '',
}


export const TagPopupTypes = {
  MANAGE: 'manage',
  SELECT: 'select',
} as const
type TagPopupType = typeof TagPopupTypes[keyof typeof TagPopupTypes]

interface IProps {
  user: any;
  open: boolean;
  onCreateQna: Function;
  onClose(): void;
  type: TagPopupType;
  insertedTag?: any;
  setInsertedTag?: Function;
}

const TagList: React.SFC<IProps> = (props) => {

  const { user, open, onClose, type, insertedTag, setInsertedTag  } = props;

  const { salonOn, requestTagList, createTag, modifyTag, deleteTag } = useSalonon();
  const { tagData } = salonOn;

  const [ requestParams, setRequestParams ] = useState(initRequestParams);

  const [ addTagName, setAddTagName] = useState('');

  const [ selectedTag, setSelectedTag ] = useState(insertedTag);

  
  useEffect(() => {
    onRequest();
  }, []);

  const onRequest = (params? : any) => {
    let reqParams = null;

    if(params){
      reqParams = params;
    }else{     
      reqParams = {
        page: 0,
        size:0,
      }
      setRequestParams(initRequestParams)
    }
     
    requestTagList({
      queryParameters: reqParams
    });
  }

  const onWriteTag = (event: any) => {   
    // console.log('event : ', event);
    if(addTagName && event) {
      if(event.key === 'Enter' || event === 'Clicked'){
        createTag({
          queryParameters: {
            hashTagName: addTagName
          },
          callback : (succeeded: boolean, res: any) => {
            if(succeeded){
              onRequest();
              setAddTagName('');
            }
          }
        });
      }           
    } 
  }

  const onModifyTag = (params : any) => {
    modifyTag({
      queryParameters: params,    
      callback : (succeeded: boolean, res: any) => {
        if(succeeded)
          onRequest(requestParams);
      }
    })
  }

  const onDeleteTag = (params : any) => {
    deleteTag({
      queryParameters: params,    
      callback : (succeeded: boolean, res: any) => {
        if(succeeded)
          onRequest(requestParams);
      }
    })
  }

  const onChangeParams = (event: React.ChangeEvent<HTMLInputElement>) =>{
    // console.log('event.target.value : ', event.target.value)
    setAddTagName(event.target.value);    
  }

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, getData: any) => {
    // setPageNo(getData.activePage);
    setRequestParams({
      ...requestParams, 
      [getData.name]: getData.activePage
    })
    onRequest({
      ...requestParams, 
      [getData.name]: getData.activePage
    });
  }

  const onConfirm = () => {
    if(setInsertedTag)
      setInsertedTag(selectedTag)
    onCloseClicked();
  }

  const onCloseClicked = () => {    
    setRequestParams(initRequestParams);
    onClose();
  }
  
    
  const onChange = (event : React.ChangeEvent<HTMLInputElement>, getData: any) => {
    // console.log('data :: ', getData);
    setRequestParams({
      ...requestParams,
      [event.target.name] : event.target.value
    });
  }

  const onSearch = (event : any) => {  
    if(event.key === 'Enter' || event === 'Clicked'){
      setRequestParams({      
        ...requestParams,
        page: 1,
      });

      onRequest({
        ...requestParams,
        page: 1,
      });
    }
  }

  
  const headers = [    
    type === TagPopupTypes.SELECT ? '선택' : '',
    '태그명', 
    ''
  ];

  const headerWidth = [0.5, 2.8, 1];
  
  const finder : IFindTextBox = {
    name: 'searchKey',
    value: requestParams.searchKey,
    onChange: onChange,
    onSearch: onSearch    
  }
  
  const pagination : IPagination = {
    name: 'page',
    activePage: requestParams.page,
    totalPage: tagData.page.totalPages,
    onChange: onChangePage, 
  }
  
  const tableListProps: ITableListProps = {
    title: ' ',
    size: 'small',
    color: 'violet',
    finder,
    headers,
    headerWidth,
    // buttons,
    count: tagData.page.totalElements,
    pagination,
    refresh: ()=> onRequest()
  }

  const tinyItems = tagData.list.map((item:any) => (
    <TagListItem
      key={item.hashtagId}  
      requestParams={'1'}
      data={item}
      onRequest={onRequest}
      onWriteTag={onWriteTag}
      onModifyTag={onModifyTag}
      onDeleteTag={onDeleteTag}
      // setIsDeleted= {setIsDeleted}
      type={type}
      selectedTag={selectedTag}
      setSelectedTag={setSelectedTag}
    />
  ));


  tinyItems.unshift(
    <TagListItem
      key={0}  
      requestParams={'1'}
      data={{
        hashtagId : -1, 
        hashtagName: <input type="text" size={5} value={addTagName} onChange={onChangeParams} onKeyPress={onWriteTag} />
      }}
      onRequest={onRequest}
      onWriteTag={onWriteTag}
      onModifyTag={onModifyTag}
      onDeleteTag={onDeleteTag}
      type={type}
    />
  )
  const buttons = type === TagPopupTypes.MANAGE ? [
    { onClick: onCloseClicked }
  ] : [
    { onClick: onCloseClicked },
    { onClick: onConfirm }
  ]  

  return (
    <BasePopup
      open={open} 
      id={user.uid}
      size='tiny'
      type={type === 'manage' ? PopupTypes.BASIC : PopupTypes.DIALOG}
      title={type === 'manage' ? '해시태그 관리' : '해시태그 선택'}
      buttons={buttons}
      onClose={onCloseClicked}
    >
      <TableList {...tableListProps}>        
        {tinyItems}
      </TableList>
    </BasePopup>
  );
}


export default TagList;