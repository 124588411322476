import React from 'react'
// styles
import { CardStyles as Styled } from './styles/Card.styles'

type TData = {
  text: string | number;
  value?: string | number;
}

interface IProps {
  title?: TData;
  contents?: TData[];
  contents2?: TData[];
  height?: string;
  margin?: number;
  titleTextFontSize?: string;
  titleTextFontWeight?: number;
  titleValueFontSize?: string;
  titleValueFontWeight?: number;
  contentsTextFontSize?: string;
  contentsValueFontSize?: string;
  contents2TextFontSize?: string;
  contents2ValueFontSize?: string;
  contentsTextFontWeight?: number;
  contentsValueFontWeight?: number;
  contents2TextFontWeight?: number;
  contents2ValueFontWeight?: number;
  notDivide?: boolean;
}

const Card: React.FC<IProps> = (props) => {
  const { 
    title,
    contents,
    contents2,
    height,
    margin,
    titleTextFontSize,
    titleTextFontWeight,
    titleValueFontSize,
    titleValueFontWeight,
    contentsTextFontSize,
    contentsValueFontSize,
    contents2TextFontSize,
    contents2ValueFontSize,
    contentsTextFontWeight,
    contentsValueFontWeight,
    contents2TextFontWeight,
    contents2ValueFontWeight,
    notDivide,
  } = props;

  return (
    <Styled.Card notDivide={notDivide} height={height} margin={margin}>
      {title && (
        <Styled.Box>
          <Styled.Text fontSize={titleTextFontSize || '1rem'} fontWeight={titleTextFontWeight && titleTextFontWeight}>{title.text}</Styled.Text>
          {title.value !== undefined && (
            <Styled.Text fontSize={titleValueFontSize || '2rem'} fontWeight={titleValueFontWeight || 600}>
              {typeof title.value === 'number' ? title.value.toLocaleString() : title.value}
            </Styled.Text>
          )}
        </Styled.Box>
      )}
      {contents && (
        <Styled.Box>
          {contents.map((content, index) => (
            <Styled.Box key={`${content.text}-${index}`}>
              <Styled.Text fontSize={contentsTextFontSize || '0.8rem'} fontWeight={contentsTextFontWeight && contentsTextFontWeight}>{content.text}</Styled.Text>
              {content.value !== undefined && (
                <Styled.Text fontSize={contentsValueFontSize || '1.2rem'} fontWeight={contentsValueFontWeight || 600}>
                  {typeof content.value === 'number' ? content.value.toLocaleString() : content.value}
                </Styled.Text>
              )}
            </Styled.Box>
          ))}
        </Styled.Box>
      )}
      {contents2 && (
        <Styled.Box>
          {contents2.map((content, index) => (
            <Styled.Box key={`${content.text}-${index}`}>
              <Styled.Text fontSize={contents2TextFontSize || '0.8rem'} fontWeight={contents2TextFontWeight && contents2TextFontWeight}>{content.text}</Styled.Text>
              {content.value !== undefined && (
                <Styled.Text fontSize={contents2ValueFontSize || '1.2rem'} fontWeight={contents2ValueFontWeight || 600}>
                  {typeof content.value === 'number' ? content.value.toLocaleString() : content.value}
                </Styled.Text>
              )}
            </Styled.Box>
          ))}
        </Styled.Box>
      )}
    </Styled.Card>
  )
}

export default Card