import React, { useEffect, useState } from 'react';
import { IParams, initIRequestDemoListParams } from './interface';
import useDemo from 'hooks/useDemo';
import DemoList from './DemoList';

interface IProps {
  
}



const Demo: React.FC = () => {
  const { 
    demo : { demoList },
    requestGetReviewDemos
  } = useDemo()
  const [ requestParams, setRequestParams ] = useState<IParams>(initIRequestDemoListParams);


  useEffect(() => {
    onRequestDemoList()
  }, [])

  const onRequestDemoList = (params?: IParams) => {
    const reqParmas = params ? params : initIRequestDemoListParams;
    setRequestParams(reqParmas);
    if (reqParmas && reqParmas.page > 0) {
      requestGetReviewDemos({
        queryParameters: reqParmas
      })
    }
  } 
  
  return <>
    <DemoList 
      params={requestParams} 
      setParams={setRequestParams} 
      itemList={demoList} 
      refresh={onRequestDemoList} 
    />
  </>
}

export default Demo