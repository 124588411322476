import styled, { css } from 'styled-components'

interface IStyleProps {
  flex?: number;
  align?: 'left' | 'right';
  cursor?: string;
  flexDirection?: string;
  active?: boolean;
  underLine?: boolean;
}

const Li = styled.li`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dddddd;
`

const Content = styled.div<IStyleProps>`
  flex: ${({ flex }) => flex && flex};
  display: flex;
  justify-content: ${({ align }) => align ? align : 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection && flexDirection};
  align-items: center;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  text-align: center;
  cursor: ${({ cursor }) => cursor && cursor};

  &:hover {
    text-decoration: ${({ underLine }) => underLine && 'underline'};
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
`

const ToggleButton = styled.button<IStyleProps>`
  width: 50px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  background-color: ${({ active }) => active ? '#3c5adb' : '#dddddd'};
`

const Switch = styled.div<IStyleProps>`
  width: 29px;
  height: 29px;
  background-color: #ffffff;
  border-radius: 100px;
  border: 1px solid #dddddd;
  transition: 0.45s;
  ${({ active }) => active ? css`
    transform: translateX(82%);
  ` : css`
    transform: translateX(-8%);
  `}
`

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 15px;
  height: 15px;
  margin: 0 5px 0 10px;
`

export const BannerListItemStyles = {
  Li,
  Content,
  Img,
  ToggleButton,
  Switch,
  CheckBox,
}
