import React, { useState } from 'react';
 
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import { Container, Header, Segment, Dimmer, Loader, Image, Input, Button, Table, Select, Grid, Modal } from 'semantic-ui-react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@material-ui/core/';
// import Pagination from '@material-ui/lab/Pagination';


// Components
import TableList, { ITableListProps, ITableListButton } from 'components/common/table/TableList';

import PaymentWrite from './popup/paymentWrite';
import PaymentDetail from './popup/paymentDetail';
import PaymentListItem from './paymentListItem';

// hooks
import useUsers from 'hooks/useUsers';

interface IProps {
    list: any[];
    refresh(): void;
}

const PaymentList: React.FC<IProps> = (props) => {

    const { list, refresh } = props;
    
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [resultMsg, setResultMsg] = useState('');
    const [ paymentNo, setPaymentNo ] = useState(-1);

    /* modal close */
    const onClose = () => {
        setOpen(false);
    }

    const onCloseDetail = () => {
        setOpenDetail(false);
    }

    /* Snackbar */
    const onCloseSnackbar = () => {
        setSnackbarOpen(false);
        setResultMsg('');
    }

    const [open, setOpen] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    
    const headers = ['요금제명', '가격', '데이터 분석 개월수', '마켓연동수', '상품등록수', ''];
    
    const [popupName, setPopupName] = useState('');

    const onAddPaymentButtonClicked = () => {
        setOpen(true);
    };

    const onShowDetail = (paymentNo : number) => { 
        setPaymentNo(paymentNo);
        setOpenDetail(true);
    }

    const buttons: ITableListButton[] = [
        {
          name: '요금제 등록',
          icon: 'add',
          onButtonClicked: onAddPaymentButtonClicked
        }
    ];

    const tableListProps: ITableListProps = {
        title: '요금제 관리',
        size: 'small',
        color: 'violet',
        buttons,
        headers,
        count: list.length,
        refresh
    }

    const paymentItems = list.reverse().map((paymentList) => (
        <PaymentListItem
            key={paymentList.paymentNo}
            paymentList={paymentList}
            onShowDetail={onShowDetail}
            showFeedback={(msg) => {
                setResultMsg(msg);
                setSnackbarOpen(true);
            }}
        />
    ));

    const detail = openDetail === true ? 
        <PaymentDetail paymentNo={paymentNo} open={openDetail} onClose={onCloseDetail} /> 
    : null
    
    return(
        <>
            <TableList {...tableListProps}>
                {paymentItems}
            </TableList>
            <PaymentWrite 
                open={open} 
                onClose={onClose}
                showFeedback={(msg) => {
                    setResultMsg(msg);
                    setSnackbarOpen(true);
                }}
            />
            {detail}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={onCloseSnackbar}
                TransitionComponent={slideTransition}
                message={resultMsg}
                key={slideTransition.name}
            />
        </>
    )
}

const slideTransition = (props: TransitionProps) => {
    return <Slide {...props} direction="up" />;
}

export default PaymentList;