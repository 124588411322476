import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { QnaState, initPage } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

// ACTION PART
const action = {
  getQnaList: createAction(actionTypes.GET_QNA_LIST),
  fulfilledQnaList: createAction(actionTypes.FULFILLED_QNA_LIST),  
  getQna: createAction(actionTypes.ADMIN_GET_QNA),
  createQna: createAction(actionTypes.CREATE_QNA),
  succeededCreateQna: createAction(actionTypes.SUCCEEDED_CREATE_QNA),
  failedCreateQna: createAction(actionTypes.FAILED_CREATE_QNA),
  modifyQna: createAction(actionTypes.MODIFY_QNA),
  succeededModifyQna: createAction(actionTypes.SUCCEEDED_MODIFY_QNA),
  failedModifyQna: createAction(actionTypes.FAILED_MODIFY_QNA),
  deleteQna: createAction(actionTypes.DELETE_QNA),
  succeededDeleteQna: createAction(actionTypes.SUCCEEDED_DELETE_QNA),
  failedDeleteQna: createAction(actionTypes.FAILED_DELETE_QNA),  
  statusUpdate: createAction(actionTypes.STATUS_UPDATE),
  succeededStatusUpdate: createAction(actionTypes.SUCCEEDED_STATUS_UPDATE),
  failedStatusUpdate: createAction(actionTypes.FAILED_STATUS_UPDATE),  
}

const initialState: QnaState = {
  qnaData: {    
    list: [],
    page: initPage,
  },
};

const reducer = handleActions<QnaState>(
  {
    [actionTypes.GET_QNA_CODE]: (state, action): QnaState => {
      return state
    },
    [actionTypes.SUCCEEDED_QNA_CODE]: (state, action): QnaState => {
      return {
        ...state,        
        // qnaCode: action.payload.qnaCode
      }
    },
    [actionTypes.GET_QNA_LIST]: (state, action): QnaState => {
      return state
    },
    [actionTypes.FULFILLED_QNA_LIST]: (state, action): QnaState => {
      return {
        ...state,
        qnaData: action.payload.qnaData,
      }
    },
    [actionTypes.FAILED_QNA_LIST]: (state, action): QnaState => {
      return {
        ...state,
      }
    },
    [actionTypes.CREATE_QNA]: (state, action): QnaState => {
      return state
    },
    [actionTypes.SUCCEEDED_CREATE_QNA]: (state, action): QnaState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_CREATE_QNA]: (state, action): QnaState => {
      return {
        ...state,
      }
    },    
    [actionTypes.MODIFY_QNA]: (state, action): QnaState => {
      return state
    },
    [actionTypes.SUCCEEDED_MODIFY_QNA]: (state, action): QnaState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_MODIFY_QNA]: (state, action): QnaState => {
      return {
        ...state,
      }
    },    
    [actionTypes.DELETE_QNA]: (state, action): QnaState => {
      return state
    },
    [actionTypes.SUCCEEDED_DELETE_QNA]: (state, action): QnaState => {
      return {
        ...state,
      }
    },
    [actionTypes.FAILED_DELETE_QNA]: (state, action): QnaState => {
      return {
        ...state,
      }
    },
    [actionTypes.STATUS_UPDATE]: (state, action): QnaState => {
      return {
        ...state,
      }
    },
  },
  initialState
);

// EPIC PART


const requestQnaListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_QNA_LIST,
      success: (res: AjaxResponse) => action.fulfilledQnaList({ qnaData: res.response.data }),
      fail: actionTypes.FAILED_QNA_LIST
    },
    url: API_URL.QNA,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const getQnaEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.ADMIN_GET_QNA,      
      fail: actionTypes.FAILED_ADMIN_GET_QNA
    },
    url: API_URL.QNA,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}


const createQnaEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.CREATE_QNA,
      success: (res: AjaxResponse) => action.succeededCreateQna(),
      fail: actionTypes.FAILED_CREATE_QNA
    },
    url: API_URL.QNA,
    method: AjaxMethods.POST
  };

  return epicService.request(actions$, reqParams);
}

const modifyQnaEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.MODIFY_QNA,
      success: (res: AjaxResponse) => action.succeededModifyQna(),
      fail: actionTypes.FAILED_MODIFY_QNA
    },
    url: API_URL.QNA,
    method: AjaxMethods.PATCH
  };

  return epicService.request(actions$, reqParams);
}

const deleteQnaEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.DELETE_QNA,
      success: (res: AjaxResponse) => action.succeededDeleteQna(),
      fail: actionTypes.FAILED_DELETE_QNA
    },
    url: API_URL.QNA,
    method: AjaxMethods.DELETE
  };

  return epicService.request(actions$, reqParams);
}


const statusUpdateEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.STATUS_UPDATE,
      success: (res: AjaxResponse) => action.succeededStatusUpdate(),
      fail: actionTypes.FAILED_STATUS_UPDATE
    },
    url: API_URL.QNA,
    method: AjaxMethods.PATCH
  };

  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  // requestQnaCodeEpic,
  requestQnaListEpic,
  getQnaEpic,
  createQnaEpic,
  modifyQnaEpic,
  deleteQnaEpic,
  statusUpdateEpic,
);

export { action, epic };
export default reducer;