import { Slide, Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import ImageUpload from 'components/common/fileuploader/ImageUploader';
import BasePopup, { PopupTypes } from 'components/common/popup/BasePopup'
import { IFiles } from 'components/salonOn/interfaces';
import useBanner from 'hooks/useBanner';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import DatePick from '../DatePick';
import { BannerItem, IRequestBannerParams } from '../interface';
import { BannerWriteStyles as Styled } from './styles/BannerWrite.styles';

interface IProps {
  banner?: BannerItem;
  open: boolean;
  onClose: () => void;
  showFeedback: (msg: string) => void,
}

type TWriteBanner = Omit<BannerItem, 'updateDt' | 'bannerId' | 'regDt'> & {id? : number}

const initWriteData: TWriteBanner = {
  activeYn: true,
  defaultYn: false,
  serviceType:'SELLERSPOT',
  deviceType: 'PC',
  endDt: moment().endOf('month').format('YYYY-MM-DDTHH:mm'),
  imageName: '',
  imagePath: '',
  link: '',
  name: '',
  startDt: moment().format('YYYY-MM-DDTHH:mm'),
  priority:0,
  rgb:'#FFF'
  //viewLocation: 'HOME',
}

const BannerWrite: React.FC<IProps> = (props) => {
  const { banner, open, onClose, showFeedback } = props;  
  //const [ isInfinityTime, setIsInfinityTime ] = useState<boolean>(false);
  const [ writeData, setWriteData ] = useState<TWriteBanner | BannerItem>(
    banner ? 
      !banner.startDt && !banner.endDt? {
        ...banner,
        startDt: moment().format('YYYY-MM-DDTHH:mm'),
        endDt: moment().endOf('month').format('YYYY-MM-DDTHH:mm'),
        id: banner.bannerId
      } : { ...banner }
      : { ...initWriteData }
  );
  const [ snackbarOpen, setSnackbarOpen] = useState(false);
  const [ resultMsg, setResultMsg] = useState('');

  const { requestCreateBanner, requestUpdateBanner } = useBanner();

  const compareActiveData = new Date(writeData.startDt) > new Date();

  // useEffect(() => {
  //   if ((banner?.startDt === null && banner?.endDt === null) || banner?.defaultYn)
  //     setIsInfinityTime(true);
  // }, [])

  useEffect(() => {
    compareActiveData && setWriteData({
      ...writeData,
      activeYn: false,
    })
  }, [writeData.startDt])

  const onWrite = () => {
    const body = { 
      ...writeData,
      startDt: writeData.startDt,
      endDt: writeData.endDt,
      priority:0,
    }

    const bannerId = (banner && banner.bannerId) ? {
      id: banner.bannerId
    } : {}

    const payload = (mode: 'update' | 'create') => {
      return {
        body: {...body, ...bannerId},
        callback: (succeeded: boolean, res: any) => {
          if(succeeded && res.response.code === 'OK'){
            setWriteData(initWriteData);
            onClose();
            showFeedback(mode === 'update' ? '배너 수정 완료되었습니다.' : '배너 등록 완료되었습니다.');
          } else {
            if (writeData.imageName === '') {
              setSnackbarOpen(true);
              setResultMsg('배너 이미지를 등록해주세요.');
            } else {
              setSnackbarOpen(true);
              setResultMsg(mode === 'update' ? '배너 수정 실패하였습니다.' : '배너 등록 실패하였습니다.');
            }
          }
        },
        async: true,
      }
    }
    
    if (banner) {
      requestUpdateBanner(payload('update'))
    } else {
      requestCreateBanner(payload('create'));
    }
  }

  const onChangeData = (key: keyof typeof writeData, value: string | boolean) => {    
    if (key === 'activeYn' && typeof value === 'boolean') {
      setWriteData({
        ...writeData,
        activeYn: value,
      })
    // } else if (key === 'defaultYn' && typeof value === 'boolean') {
    //   if (value) {
    //     setIsInfinityTime(true);
    //   } else {
    //     setIsInfinityTime(false);
    //   }
    //   setWriteData({
    //     ...writeData,
    //     [key]: value,
    //   })
    } else {
      setWriteData({
        ...writeData,
        [key]: value,
      })
    }
  }

  const onClosePopup = () => {
    setWriteData(initWriteData);
    onClose();
  }

  const onSetBannerFile = (file: IFiles) => {
    setWriteData({
      ...writeData,
      imagePath: file.filePath,
      imageName: file.fileName,
    })
  };

  const onRemoveBannerFile = () => {
    setWriteData({
      ...writeData,
      imagePath: '',
      imageName: '',
    })
  };

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  };

  return (
    <BasePopup
      open={open}
      id={0}
      size='small'
      type={PopupTypes.DIALOG}
      title='배너 등록 / 수정'
      buttons={[
        {
          name: '취소',
          onClick: onClosePopup,
        }, 
        {
          name: '등록',
          onClick: onWrite,
        }
      ]}
      onClose={onClosePopup}
    >
      <Styled.Form onSubmit={(e) => e.preventDefault()}>
        <Styled.List>
          <Styled.Item>
            <Styled.Title>
              배너명
              <Styled.RequireMark>*</Styled.RequireMark>
            </Styled.Title>
            <Styled.Input 
              value={writeData.name} 
              onChange={(e) => onChangeData('name', e.currentTarget.value)} 
            />
          </Styled.Item>
          <Styled.Item>
            <Styled.Div>
              <Styled.Title flex={1}>배너 위치</Styled.Title>
              <Styled.Title flex={1}>분류</Styled.Title>
            </Styled.Div>
            <Styled.Div>
              <Styled.Select flex={1} value={writeData.serviceType} onChange={(e) => onChangeData('serviceType', e.currentTarget.value)}>
                <Styled.Option value='SELLERSPOT'>셀러공간</Styled.Option>
                <Styled.Option value='IRANK'>아이랭크</Styled.Option>
                <Styled.Option value='REVIEWRAY'>리뷰레이</Styled.Option>
              </Styled.Select>
              <Styled.Select flex={1} value={writeData.deviceType} onChange={(e) => onChangeData('deviceType', e.currentTarget.value)}>
                <Styled.Option value='PC'>PC</Styled.Option>
                <Styled.Option value='MOBILE'>MOBILE</Styled.Option>
              </Styled.Select>
            </Styled.Div>
          </Styled.Item>
          <Styled.Item>
            <Styled.Title>게시 기간</Styled.Title>
            <Styled.Div>
              <DatePick 
                type='startDt' 
                dataFormat='time' 
                date={writeData.startDt} 
                onChangeDate={onChangeData} 
              />
              <Styled.Text>~</Styled.Text>
              <DatePick 
                type='endDt' 
                dataFormat='time' 
                date={writeData.endDt} 
                onChangeDate={onChangeData} 
              />
              {/* <Styled.Label>
                <Styled.CheckBox 
                  checked={isInfinityTime} 
                  onChange={() => {
                    if (banner?.defaultYn) {
                      onChangeData('defaultYn', false);
                    } else {
                      onChangeData('defaultYn', true);
                    }
                    setIsInfinityTime(!isInfinityTime);
                  }} 
                />
                무제한
              </Styled.Label> */}
            </Styled.Div>
          </Styled.Item>
          <Styled.Item>
            <Styled.Div>
              <Styled.Title flex={1}>사용 여부</Styled.Title>
              <Styled.Title flex={1}>우선 순위</Styled.Title>
            </Styled.Div>
            <Styled.Div>
              <Styled.Div>
                <Styled.Label>
                  <Styled.Radio 
                    name='activeYn' 
                    checked={writeData.activeYn} 
                    onChange={() => onChangeData('activeYn', true)} 
                    disabled={compareActiveData} 
                  />
                  사용
                </Styled.Label>
                <Styled.Label>
                  <Styled.Radio 
                    name='activeYn' 
                    checked={!writeData.activeYn} 
                    onChange={() => onChangeData('activeYn', false)} 
                  />
                  미사용
                </Styled.Label>
              </Styled.Div>
              <Styled.Div>
                <Styled.Input 
                  type='number'
                  value={writeData.priority} 
                  onChange={(e) => onChangeData('priority', e.currentTarget.value)} 
                />
              </Styled.Div>
            </Styled.Div>
          </Styled.Item>
          <Styled.Item>
            <Styled.Div>
              <Styled.Title>배경색상</Styled.Title>
              <Styled.ColorWrap 
                isGradation={writeData.rgb.indexOf('linear') > -1}
                backgroundColor={writeData.rgb}              
              ></Styled.ColorWrap>
            </Styled.Div>
            <Styled.Div>
              <Styled.Input 
                  type='text'
                  value={writeData.rgb} 
                  onChange={(e) => onChangeData('rgb', e.currentTarget.value)} 
                />
            </Styled.Div>
            <Styled.Div>
              <Styled.Text style={{fontSize: '12px'}} >{'* 그라데이션 코드(Figma 제공값)를 입력하세요.'}</Styled.Text>
            </Styled.Div>
        </Styled.Item>
          {/* <Styled.Item>
            <Styled.Title>기본배너 여부</Styled.Title>
            <Styled.Div>
              <Styled.Label>
                <Styled.Radio 
                  name='defaultYn' 
                  checked={writeData.defaultYn} 
                  onChange={() => onChangeData('defaultYn', true)} 
                  disabled={compareActiveData} 
                />
                사용
              </Styled.Label>
              <Styled.Label>
                <Styled.Radio 
                  name='defaultYn' 
                  checked={!writeData.defaultYn} 
                  onChange={() => onChangeData('defaultYn', false)} 
                />
                미사용
              </Styled.Label>
            </Styled.Div>
          </Styled.Item> */}
          <Styled.Item>
            <Styled.Title>
              링크
            <Styled.RequireMark>*</Styled.RequireMark>
            </Styled.Title>
            <Styled.Input value={writeData.link} onChange={(e) => onChangeData('link', e.currentTarget.value)} />
          </Styled.Item>
          <Styled.Item>
            <Styled.Title>
              배너 이미지
              <Styled.RequireMark>*</Styled.RequireMark>
            </Styled.Title>
            <div className="image-uploader">
              <ImageUpload 
                fileType={"BANNER"} 
                setFile={onSetBannerFile} 
                defaultImage={writeData.imagePath + writeData.imageName}
                defaultImageUrlFull={true}
                onRemoveImage={onRemoveBannerFile}
              />
            </div>
          </Styled.Item>
        </Styled.List>
      </Styled.Form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </BasePopup>
  )
}
const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default React.memo(BannerWrite)
