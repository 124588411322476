import styled, { css } from 'styled-components'

interface IStyleProps{
  align?: string;
  isClick?: boolean;
}

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #8e8e8e;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #636363;
  }
`

const FilterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  padding: 10px;
  background-color: #8e8e8e;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #636363;
  }
`

const DataWrapList = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`

const DataWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`

const CardList = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
`

const Card = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  transition: box-shadow 0.17s ease-in-out;
`;

const DataWrapTitle = styled.span`
  display: flex;
  margin-left: 5px;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 600;
`

const CardTitle = styled.span`
  font-size: 0.8rem;
  margin: 0 0 0 0;
`;

const CardValue = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
`;

const ListTitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  gap: 30px;
  margin-bottom: 10px;
`

const ListTitle = styled.span`
  display: flex;
  margin-left: 5px;
  font-size: 0.8rem;
  font-weight: 600;
`

const ListItemText = styled.span<IStyleProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align ? align : 'center'};
  padding: 10px 0;
  font-size: 12px;
  font-weight: 500;

  ${({ isClick }) => isClick && css`
    cursor: pointer;

    &:hover {
      font-weight: 700;
    }
  `}
`

const SortTitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const ArrowIconWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const ArrowIcon = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;

  &:hover {
    left: 1px;
    top: -1px;
  }
`

export const StatusCheckStyles = {
  Wrap,
  Button,
  FilterButton,
  DataWrapList,
  DataWrap,
  CardList,
  Card,
  DataWrapTitle,
  CardTitle,
  CardValue,
  ListTitleWrap,
  ListTitle,
  ListItemText,
  SortTitleWrap,
  ArrowIconWrap,
  ArrowIcon,
}