import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { SalonOnState, initPage } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

// ACTION PART
const action = {
  requestDsonList: createAction(actionTypes.GET_DSON_LIST),
  fulfilledDsonList: createAction(actionTypes.FULFILLED_DSON_LIST),
  failedDsonList: createAction(actionTypes.FAILED_GET_DSON_LIST),
  requestDsonSelectList: createAction(actionTypes.GET_DSON_SELECT_LIST),
  failedDsonSelectList: createAction(actionTypes.FAILED_GET_DSON_SELECT_LIST),
  
  createDson: createAction(actionTypes.CREATE_DSON),
  succeededCreateDson: createAction(actionTypes.SUCCEEDED_CREATE_DSON),

  requestModifyDson: createAction(actionTypes.MODIFY_DSON),
  succeededModifyDson: createAction(actionTypes.SUCCEEDED_MODIFY_DSON),
  failedModifyDson: createAction(actionTypes.FAILED_MODIFY_DSON),

  requestDeleteDson: createAction(actionTypes.DELETE_DSON),
  succeededDeleteDson: createAction(actionTypes.SUCCEEDED_DELETE_DSON),
  failedDeleteDson: createAction(actionTypes.FAILED_DELETE_DSON),

  requestDsonDetail: createAction(actionTypes.GET_DSON_DETAIL),
  succeededGetDsonDetail: createAction(actionTypes.SUCCEEDED_GET_DSON_DETAIL),
  failedGetDsonDetail: createAction(actionTypes.FAILED_GET_DSON_DETAIL),

  
  //HASH TAG
  requestTagList: createAction(actionTypes.GET_TAG_LIST),
  fulfilledTagList: createAction(actionTypes.FULFILLED_TAG_LIST),
  failedTagList: createAction(actionTypes.FAILED_GET_TAG_LIST),
  createTag: createAction(actionTypes.CREATE_TAG),
  succeededCreateTag: createAction(actionTypes.SUCCEEDED_CREATE_TAG),
  modifyTag: createAction(actionTypes.MODIFY_TAG),
  succeededModifyTag: createAction(actionTypes.SUCCEEDED_MODIFY_TAG),
  deleteTag: createAction(actionTypes.DELETE_TAG),
  succeededDeleteTag: createAction(actionTypes.SUCCEEDED_DELETE_TAG),
}

const initialState: SalonOnState = {
  salonOnData: {    
    list: [],
    page:initPage
  },
  tagData: {    
    list: [],
    page:initPage
  },
};

const reducer = handleActions<SalonOnState>(
  {
    [actionTypes.FULFILLED_DSON_LIST]: (state, action): SalonOnState => {
      return {
        ...state,
        salonOnData: action.payload.salonOnData,
      }
      // return {
      //   ...state,
      //   salonOnData: action.payload.salonOnData,
      // }
    },


    
    [actionTypes.FULFILLED_TAG_LIST]: (state, action): SalonOnState => {
      return state
      // return {
      //   ...state,
      //   tagData: action.payload.tagData,
      // }
    },
  },
  initialState,
);

// EPIC PART


const requestDsonListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DSON_LIST,
      success: (res: AjaxResponse) => action.fulfilledDsonList({ salonOnData: res.response.data }),
      fail: actionTypes.FAILED_GET_DSON_LIST
    },
    url: API_URL.DSON_LIST,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}


const requestDsonSelectListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DSON_SELECT_LIST,
      fail: actionTypes.FAILED_GET_DSON_SELECT_LIST
    },
    url: API_URL.DSON_LIST,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}

const requestCreateDsonEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.CREATE_DSON,
      success: (res: AjaxResponse) => action.succeededCreateDson(),
      fail: actionTypes.FAILED_CREATE_DSON
    },
    url: API_URL.DSON,
    method: AjaxMethods.POST
  };

  return epicService.request(actions$, reqParams);
}



const requestModifyDsonEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.MODIFY_DSON,
      success: (res: AjaxResponse) => action.succeededModifyDson(),
      fail: actionTypes.FAILED_MODIFY_DSON
    },
    url: API_URL.DSON,
    method: AjaxMethods.PATCH
  };

  return epicService.request(actions$, reqParams);
}

const requestDeleteDsonEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.DELETE_DSON,
      success: (res: AjaxResponse) => action.succeededDeleteDson(),
      fail: actionTypes.FAILED_DELETE_DSON
    },
    url: API_URL.DSON,
    method: AjaxMethods.DELETE
  };

  return epicService.request(actions$, reqParams);
}


const requestDsonDetailEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_DSON_DETAIL,
      success: (res: AjaxResponse) => action.succeededGetDsonDetail(),
      fail: actionTypes.FAILED_GET_DSON_DETAIL
    },
    url: API_URL.DSON_DETAIL,
    method: AjaxMethods.GET
  };

  return epicService.request(actions$, reqParams);
}



const requestTagListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_TAG_LIST,
      success: (res: AjaxResponse) => action.fulfilledTagList({ tagData: res.response.data }),
      fail: actionTypes.FAILED_GET_TAG_LIST
    },
    url: API_URL.TAG_LIST,
    method: AjaxMethods.POST
  };

  return epicService.request(actions$, reqParams);
}

const requestCreateTagEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.CREATE_TAG,
      success: (res: AjaxResponse) => action.succeededCreateTag(),
      fail: actionTypes.FAILED_CREATE_TAG
    },
    url: API_URL.TAG,
    method: AjaxMethods.POST
  };

  return epicService.request(actions$, reqParams);
}


const requestModifyTagEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.MODIFY_TAG,
      success: (res: AjaxResponse) => action.succeededModifyTag(),
      fail: actionTypes.FAILED_MODIFY_TAG
    },
    url: API_URL.TAG,
    method: AjaxMethods.PUT
  };

  return epicService.request(actions$, reqParams);
}


const requestDeleteTagEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.DELETE_TAG,
      success: (res: AjaxResponse) => action.succeededDeleteTag(),
      fail: actionTypes.FAILED_DELETE_TAG
    },
    url: API_URL.TAG,
    method: AjaxMethods.DELETE
  };

  return epicService.request(actions$, reqParams);
}


const epic = combineEpics(
  requestDsonListEpic,
  requestDsonSelectListEpic,
  requestCreateDsonEpic,
  requestModifyDsonEpic,
  requestDeleteDsonEpic,
  requestDsonDetailEpic,

  requestTagListEpic,
  requestCreateTagEpic,
  requestModifyTagEpic,
  requestDeleteTagEpic,
);

export { action, epic };
export default reducer;