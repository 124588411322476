import { combineEpics } from 'redux-observable';
import { createAction, handleActions } from 'redux-actions';

// modules
import { UserState, CodeListsState } from './types';

// actions
import * as actionTypes from './types/actions';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

// ACTION PART
const action = {
  getCodeList: createAction(actionTypes.GET_CODE_LIST),
  succeededgetCodeList: createAction(actionTypes.SUCCEEDED_GET_CODE_LIST),
  failedgetCodeList: createAction(actionTypes.FAILED_GET_CODE_LIST),
}

const initialState: CodeListsState = {
  codeLists: [],
};

const reducer = handleActions<CodeListsState>(
  {
    [actionTypes.FAILED_GET_CODE_LIST]: (state, action): CodeListsState => {
      return state
    },
    [actionTypes.GET_CODE_LIST]: (state, action): CodeListsState => {
      return state
    },
    [actionTypes.SUCCEEDED_GET_CODE_LIST]: (state, action): CodeListsState => {
      return {
        ...state,
        codeLists: action.payload.codeLists
      }
    },

  },
  initialState
);

// EPIC PART 
const requestEventListEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_EVENT_LIST,
      success: (res: AjaxResponse) => action.succeededgetCodeList(

        { codeLists: res.response }),
      fail: actionTypes.FAILED_GET_CODE_LIST
    },
    url: API_URL.CODELISTALL,
    method: AjaxMethods.GET
  };
  return epicService.request(actions$, reqParams);
}

const epic = combineEpics(
  requestEventListEpic,
);

export { action, epic };
export default reducer;