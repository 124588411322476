import React, { SyntheticEvent, useState, useEffect } from 'react';

// UI
import { Checkbox } from 'semantic-ui-react';

// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
import SalonOnDelete from './SalonOnDelete'

export const TagPopupTypes = {
  MANAGE: 'manage',
  SELECT: 'select',
} as const
type TagPopupType = typeof TagPopupTypes[keyof typeof TagPopupTypes]

interface IModifyTagParams {
  hashtagId: number;
  hashtagName: string;
  useYn: boolean;
}

// Hooks
interface IProps {
  data: any;
  requestParams: any;
  setIsDeleted?: Function;
  onRequest: Function;
  onWriteTag: Function;
  onModifyTag: Function;
  onDeleteTag: Function;
  type: TagPopupType;
  selectedTag?: any;
  setSelectedTag?: Function;
}

const TagListItem: React.SFC<IProps> = (props) => {
  const { data, onWriteTag, onModifyTag, onDeleteTag, type, selectedTag, setSelectedTag } = props;
  // QnA 데이터 속성과 매핑
  const { hashtagId, hashtagName, useYn } = data;

  const [modifyParams, setModifyParams ] = useState<IModifyTagParams>({
    hashtagId : hashtagId,
    hashtagName: hashtagName,
    useYn : useYn
  });

  const [ originalHashtagName, setOriginalHashtagName ] = useState('');

  const [isEditMode, setIsEditMode] = useState(false);

  const [ openDelete, setOpenDelete ] = useState(false);

  const onButtonClicked = (e: SyntheticEvent, item: any) => {
    // console.log('onButtonClicked', e, item);

    const id = (item && item.data) || undefined;
    if (id) {
      if (item.name === 'edit') { // 수정을 위해 입력 텍스트로 변환
        // originalHashtagName = modifyParams.hashtagName;
        setOriginalHashtagName(modifyParams.hashtagName);
        setIsEditMode(true);
      }
      if (item.name === 'check') {
        if (hashtagId === -1){ // 해시태그 추가 시
          onWriteTag('Clicked');
        } else { // 해시태그 수정 시
          onModify();
        }
      }
      if (item.name === 'delete') { // 해시태그 삭제 시
        setOpenDelete(true);
      }
      if (item.name === 'cancel') { // 해시태그 수정 취소 시
        setModifyParams({
          ...modifyParams,
          hashtagName: originalHashtagName
        })
        setIsEditMode(false);
      }
    }
  };

  const onModify = () => {
    onModifyTag({
      ...modifyParams,
      hashtagName: modifyParams.hashtagName
    });
    setIsEditMode(false);
  }

  const onKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === 'Enter'){
      onModify();
    }
  }

  const onCheckSelected = (tagId:number) => {
    const filtered = selectedTag ? selectedTag.filter((item:any) => item.hashtagId === tagId) : null
    // console.log('filtered ', filtered)
    
    return filtered.length > 0 ? true : false;
  }

  const onChangeParams = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifyParams({
      ...modifyParams,
      [event.target.name]: event.target.value
    })
    // console.log('modifyParams : ', modifyParams)
  }

  const onChangeCheck = (event: React.FormEvent<HTMLInputElement>, data: any) => {
    if(data.checked && setSelectedTag){
      setSelectedTag(selectedTag.concat({
        hashtagId: hashtagId,
        hashtagName: hashtagName
      }))
    } else if(!data.checked && setSelectedTag) {
      const filtered = selectedTag.filter((item:any) => item.hashtagId !== hashtagId)
      setSelectedTag(filtered);
    }
  }    

  const onDeleteItem = () => {
    onDeleteTag({
      hashtagId: hashtagId
    });
  }
  
  const onCloseDelete = () => {
    setOpenDelete(false);
  }

  // const role = (authorities && authorities[0] && authorities[0].authority) || '';
  // console.log('data : ', data);
  const cellTagName = !isEditMode ? (
    <div style={{textAlign:'center'}} >{hashtagName}</div>
  ) : (
    <input type="text" name="hashtagName" size={5} value={modifyParams.hashtagName} onChange={onChangeParams} onKeyPress={onKeyPressed} />
    //<div style={{textAlign:'center'}} >{hashtagName}</div>
  )

  const tableListItemsProps: ITableListItemProps = {
    id: hashtagId,
    items: [  
      hashtagId !== -1 ? (
        <>
          {type === 'select' ? <div style={{textAlign:'center'}}><Checkbox checked={onCheckSelected(hashtagId)} onChange={onChangeCheck} /></div> : null}
        </>) : (        
          <div style={{textAlign:'center'}}>추가</div>
        ),
      cellTagName,
    ],
    iconButtons: type === 'manage' ? 
      getButtons(hashtagId, isEditMode, onButtonClicked) : 
      (hashtagId === -1 ? getButtons(hashtagId, false, onButtonClicked) : 
      [])
  }

  return (
    <>
      <TableListItem {...tableListItemsProps} />
      
      <SalonOnDelete
        id={hashtagId}
        open={openDelete}
        onDelete={onDeleteItem}
        onClose={onCloseDelete}
      />
    </>
  );
}

const getButtons = (hashtagId:number, isEditMode: boolean, onClicked: any) => {
  const iconButtons: ITableListItemIconButton[] = [];
  if (hashtagId !== -1 && !isEditMode){
    const editButton: ITableListItemIconButton = {
      color: 'green',
      iconName: 'edit',
      // tooltip: 'Edit Tag',
      showTooltip:false,
      onItemClicked: onClicked
    };
    const deleteButton: ITableListItemIconButton = {
      color: 'red',
      iconName: 'delete',
      // tooltip: 'Delete Tag' ,
      showTooltip:false,
      onItemClicked: onClicked
    }  
    iconButtons.push(editButton);  
    iconButtons.push(deleteButton);
  } else if (hashtagId !== -1 && isEditMode) {
    const confirmButton: ITableListItemIconButton = {
      color: 'green',
      iconName: 'check',
      // tooltip: 'Confirm',
      showTooltip:false,
      onItemClicked: onClicked
    };
    const cancelButton: ITableListItemIconButton = {
      color: 'red',
      iconName: 'cancel',
      // tooltip: 'Cancel' ,
      showTooltip:false,
      onItemClicked: onClicked
    }  
    iconButtons.push(confirmButton);  
    iconButtons.push(cancelButton);
  } else {    
    const confirmButton: ITableListItemIconButton = {
      color: 'green',
      iconName: 'check',
      // tooltip: 'Confirm',
      showTooltip:false,
      onItemClicked: onClicked
    };
    iconButtons.push(confirmButton);  
  }

  return iconButtons;
}


const dateToLocale = (regDt: any) => {    
  const regDate = new Date(regDt)
  const year = regDate.getFullYear();
  const month = regDate.getMonth() < 10 ? '0' + (regDate.getMonth()+1) : (regDate.getMonth()+1);
  const date = regDate.getDate() < 10 ? '0' + regDate.getDate() : regDate.getDate();

  // const regDate = regDt != undefined ? regDt.split('T')[0] : "0000-00-00";
  return year+'-'+month+'-'+date;
}

  
// console.log('category : ', category);
const categoryValueToText = (value : string, category: [{value:string, text:string}]) => {
  const matchedItem = category ? category.filter(item => item.value === value) : []
  
  return matchedItem.length > 0 ? matchedItem[0].text : 'none';
}


export default TagListItem;