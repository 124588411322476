import React, { useEffect } from 'react';

// Components
import NoticeList, { INoticeReqParams, initNoticeReqParams } from './NoticeList';

import useNotice from 'hooks/useNotice';

const Notice2: React.SFC = () => {

  const { notice, requestGetNoticeList } = useNotice();
  const { noticeList } = notice;

  useEffect(() => {
    onRequest();
  }, []);

  const onRequest = (params: INoticeReqParams = initNoticeReqParams) => {

    console.log('params ::', params);
    requestGetNoticeList({
      queryParameters: params
    });
  }
  
  return (
    // <FaqList faqCode={faqCode} faqList={faqList} refresh={requestFaqList} />
    <>
      <NoticeList data={noticeList} onRequest={onRequest} />
    </>
  )
}

export default Notice2;