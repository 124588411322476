import React, { useEffect, useState } from 'react'
// hook
import useDashBoard from 'hooks/useDashBoard'
// component
import Card from './Card'
// interface
import { IDashboardQnaCount, initDashboardQnaCount } from './interface'
// styles
import { QnaInfoStyles as Styled } from './styles/QnaInfo.styles'

interface IProps {
  searchDate: string;
}

const QnaInfo: React.FC<IProps> = ({ searchDate }) => {
  const { requestGetDashboardQna } = useDashBoard()
  const [ dashboardQnaCount, setDashboardQnaCount ] = useState<IDashboardQnaCount>(initDashboardQnaCount);

  useEffect(() => {
    const payload = {
      queryParameters: {
        searchDt : searchDate,
      },
      callback : (succeeded:any, res:any) => {
        if(succeeded){
          setDashboardQnaCount(res.response.data)
        }
      },
      async: true,
    }

    requestGetDashboardQna(payload);
  }, [searchDate])

  const qnaCategory: { [key: string]: string } = {
    /* SALES: '판매정보',
    CALCULATE: '정산정보',
    MONITORING: '상품 모니터링',
    MARKET_ACCOUNT_MANAGEMENT: '마켓 계정 관리 / 데이터 수집',
    MOBILE: '모바일',
    SUGGESTION: '의견남기기',
    PAYMENT: '결제/취소/환불',
    ETC: '기타', */
    SERVICE_NORMAL: '서비스 일반',
    IRANK: '아이랭크',
    REVIEWRAY: '리뷰레이',
    COOPERATION: '제휴문의',
    QUOTE_INQUIRY: '견적문의',
    ERROR: '오류문의',
    MEMBERSHIP: '멤버십',
    ETC: '기타',
  }

  const totalData = [
    {
      title: { text: '전체 미확인 문의' },
      contents: [
        { text: dashboardQnaCount.allNonCheck },
      ],
    },
    {
      title: { text: '전체 처리중 문의' },
      contents: [
        { text: dashboardQnaCount.allInProgress },
      ],
    },
  ]

  const contents = [
    { text: '미확인' },
    { text: '처리중' },
  ]

  const QnaCountData = dashboardQnaCount.countByTypes.map((data) => {
    return {
      title: { text: data.enumMapperValue.value },
      contents: contents,
      contents2: [
        { text: data.nonCheckCount },
        { text: data.inProgressCount },
      ]
    }
  })

  return (
    <Styled.Wrap>
      <Styled.Section>
        {totalData.map((data) => (
          <Card 
            key={`${data.title.text}-card`} 
            title={data.title} 
            contents={data.contents}
            titleTextFontWeight={600}
          />
        ))}
      </Styled.Section>
      <Styled.GridSection>
        {QnaCountData.map((data) => (
          <Card 
            key={`${data.title.text}-card`} 
            title={data.title} 
            contents={data.contents}
            contents2={data.contents2}
            titleTextFontWeight={500}
          />
        ))}
      </Styled.GridSection>
    </Styled.Wrap>
  )
}

export default QnaInfo