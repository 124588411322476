import styled from 'styled-components'

const HtmlViewWrap = styled.section`
  width: 100%;
`

const HtmlView = styled.div`
  border: 1px solid #F0F0F0;
  padding: 34px 0;
  border-radius: 5px;
`

export const HtmlViewStyles = {
  HtmlViewWrap,
  HtmlView,
}