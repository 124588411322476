import React, { useState } from 'react'
// Common
import Ckeditor4 from 'components/common/ckeditor4';
import BasePopup from 'components/common/popup/BasePopup';
// Styles
import { EmailTemplateDetailStyles as Styled } from './styles/EmailTemplateDetail.styles'
// Components
import HtmlView from '../section/HtmlView';
// Moduels
import { EmailTemplateItem } from 'store/modules/types';

interface IProps {
  itemNumber: number,
  open: boolean,
  onClose: () => void,
  item: EmailTemplateItem,
}

const EmailTemplateDetail: React.FC<IProps> = (props) => {

  const { itemNumber, open, onClose, item } = props;

  return (
    <BasePopup 
      id={itemNumber}
      open={open}
      onClose={onClose}
      size={'large'}
      buttons={[{name: 'OK', onClick: onClose}]}
    >
      <Styled.Wrap>
        <Styled.PopupHeader>템플릿 조회</Styled.PopupHeader>
        <Styled.Title>제목</Styled.Title>
        <Styled.TemplateName rows={1} readOnly>{item.templateName}</Styled.TemplateName>
        <Styled.Title>미리보기</Styled.Title>
        <HtmlView 
          htmlCode={item.template}
        />
      </Styled.Wrap>
    </BasePopup>
  )
}

export default EmailTemplateDetail
