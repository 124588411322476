import React, { useEffect, useState } from 'react'
// lib
import { makeStyles } from '@material-ui/core';
import Flatpickr from "react-flatpickr";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
// interface
import { IUserSalesParams } from './interface';
// styles
import { DatePickStyles as Styled } from './styles/DatePick.styles'
import 'flatpickr/dist/themes/material_blue.css';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 180,
  },
  monthButton: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  }
}))

const formattedDate = (date: ISearchDate): string => {
  return `${date.year}-${fillZero(2, date.month)}-${fillZero(2, date.day)}`
}

const fillZero = (width: number, value: number) => {
  const str = value.toString();
  return str.length >= width ? str:new Array(width-str.length+1).join('0') + str;
}

const toDateObject = (date: Date, formatDate?: string) => {
  if (formatDate) {
    const temp = formatDate?.split("-");
    return {
      year: Number(temp[0]),
      month: Number(temp[1]),
      day: Number(temp[2])
    }
  }
  
  return {
    year: date ? date.getFullYear() : 0,
    month: date ? date.getMonth() + 1 : 0,
    day: date ? date.getDate() : 0
  }
}

interface ISearchDate {
  year: number;
  month: number;
  day: number;
}

interface IProps {
  requestParams: IUserSalesParams;
  setRequestParams: Function;
}

const DatePick: React.FC<IProps> = (props) => {

  const { requestParams, setRequestParams } = props;
  
  const classes = useStyles();
  const defaultDate = toDateObject(new Date());

  const [ searchDate, setSearchDate ] = useState<ISearchDate>(defaultDate)

  useEffect(() => {
    const lastDay = new Date(defaultDate.year, defaultDate.month, 0);
    setRequestParams({
      ...requestParams,
      startDate: `${defaultDate.year}-${fillZero(2, defaultDate.month)}-01`,
      endDate: `${defaultDate.year}-${fillZero(2, defaultDate.month)}-${fillZero(2, lastDay.getDate())}`,
    })
  }, [])

  const onSearchDateChanged = (event: any) => {
    const changeDate = toDateObject(event[0]);
    const lastDay = new Date(changeDate.year, changeDate.month, 0);
    const startDate = `${changeDate.year}-${fillZero(2, changeDate.month)}-${fillZero(2, changeDate.day)}`;
    const endDate = `${changeDate.year}-${fillZero(2, changeDate.month)}-${fillZero(2, lastDay.getDate())}`;

    setSearchDate(changeDate);

    setRequestParams({
      ...requestParams,
      startDate: startDate,
      endDate: endDate,
    })
  }

  const flatPickrMonths: any = {
    shorthand: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    longhand: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  };

  return (
    <Styled.Wrap>
      <Flatpickr
        localName="ko"
        value={formattedDate(searchDate)}
        onChange={onSearchDateChanged}
        onClose={onSearchDateChanged}
        options={{
          mode: "single",
          enableTime: false,
          locale: {
            months: flatPickrMonths,
          },
          plugins: [
            new (monthSelectPlugin as any)({
              shorthand: true, //defaults to false
              dateFormat: "Y-M", //defaults to "F Y"
            })
          ],
        }}
        render={
          ({defaultValue, value, ...props}, ref) => {
            return (
              <TextField
                id="date"
                label="Month"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={defaultValue}
                inputRef={ref}
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="end" style={{ zIndex: -1, position: 'relative'}} >
                      <TodayIcon />
                    </InputAdornment>
                }}
              />
            )
          }
        }
      />
    </Styled.Wrap>
  )
}

export default DatePick
