import useBasics from 'hooks/useBasics';
import React from 'react'
import DatePick from './DatePick';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { useSearchParamsContext } from './interfaces';
import { UserLogsStyles as Styled } from './styles/UserLogs.styles';

interface IProps {
}

const LogSearch: React.FC<IProps> = (props) => {
  const { } = props;
  const { searchParams, setSearchParams, onSearch } = useSearchParamsContext();
  const { admin_user_role_type: roles = [] } = useBasics().basics.commonCodeList;

  const onChangeParams = (event: React.MouseEvent<HTMLInputElement | HTMLButtonElement>) => {
    const { name, value } = event.currentTarget;
    setSearchParams({
      ...searchParams,
      [name]: value
    });
  }

  const onHandleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: InputOnChangeData) => {
    setSearchParams({
      ...searchParams,
      [data.name]: data.value
    });
  }

  const onHandlePressEnter = (event: any) => {  
    if(event.key === 'Enter' ){
      onSearch();
    }
  };

  const userRoles = [{ value: 'ALL', text: '전체' }, ...roles].map((role, index) => {
    return (
      <>
        <input 
          key={role.value} 
          type="radio" 
          id="roleType"
          name="roleType" 
          value={role.value}
          checked={searchParams.roleType === role.value}
          onClick={onChangeParams} 
        />
        <button name="roleType" value={role.value} onClick={onChangeParams}>{role.value}</button>
      </>
    )
  })
  
  return (
    <Styled.SearchSection>
      <div>
        <Styled.SubjectSpan>고객 유형</Styled.SubjectSpan>
        <Styled.SearchParamSpan>
          <Styled.RowDiv>
            <Styled.FormSelect
              id="header-select-box"
              name="searchType"
              value={'userId'} 
              options={[{value: 'userId', text: '아이디'}]} 
            />
            <Form.Input 
              name='keyword'
              width={2}
              value={searchParams.keyword}
              placeholder='검색어를 입력해주세요.'
              onChange={onHandleChange}
              onKeyPress={onHandlePressEnter}
            />
          </Styled.RowDiv>
        </Styled.SearchParamSpan>
        
      </div>
      <div>
        <Styled.SubjectSpan>회원 구분</Styled.SubjectSpan>
        <Styled.SearchParamSpan className="user-role">
          {userRoles}
        </Styled.SearchParamSpan>
        
      </div>
      <div>
        <Styled.SubjectSpan>접속 일자</Styled.SubjectSpan>
        <Styled.SearchParamSpan>
          <DatePick />
        </Styled.SearchParamSpan>
      </div>
    </Styled.SearchSection>
  )
}

export default LogSearch;
