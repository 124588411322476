import React from 'react'
// Styles
import { HtmlViewStyles as Styled } from './styles/HtmlView.styles'

interface IProps {
  htmlCode: string
}

const HtmlView: React.FC<IProps> = (props) => {

  const { htmlCode } = props;

  return (
    <div>
      <Styled.HtmlView dangerouslySetInnerHTML={{ __html: htmlCode }} />
    </div>
  )
}

export default HtmlView
