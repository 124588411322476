import React from 'react'
import { RadioGroupStyles as Styled } from './RadioGroup.styles';

interface IProps {
  objKey: string;
  checkedValue: string;
  datas: {
    value: string;
    label: string;
  }[];
  onClick: (key: string, value: string) => void;
}

const RadioGroup: React.FC<IProps> = (props) => {
  const { objKey, checkedValue, datas, onClick } = props;

  return (
    <Styled.RadioList>
      {datas.map((data) => (
        <Styled.Radio>
          <Styled.RadioButton 
            id={`radio-button-${objKey}-${data.value}`}
            value={data.value}
            onClick={() => onClick(objKey, data.value)}
            checked={data.value === checkedValue}
          />
          <Styled.Label htmlFor={`radio-button-${objKey}-${data.value}`}>{data.label}</Styled.Label>
        </Styled.Radio>
      ))}
    </Styled.RadioList>
  )
}

export default RadioGroup