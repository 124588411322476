import React, { useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

// Components
import TableList, { ITableListProps, ITableListButton } from 'components/common/table/TableList';
import GuideListItem from './guideListItem';
import GuideWrite from './popup/guideWrite';
import useGuide from 'hooks/useGuide';

interface IProps {
  list: any[];
  refresh(): void;
}

const GuideList: React.FC<IProps> = (props) => {

  const { list, refresh } = props;

	const { guide } = useGuide();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [resultMsg, setResultMsg] = useState('');
  const fileType = 'USE_GUIDE';
  const typeList = guide.guideList
    .map((guideItem: any) => {
      return {
        value: guideItem.guideType,
        text: guideItem.guideName,
      }
    })
    .reduce((acc: any[], cur: any) => {
      if (!acc.find((type: any) => type.value === cur.value)) return [...acc, cur];
      return acc
    }, [])

  const onclose = () => {
    setOpen(false);
  }

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
    setResultMsg('');
  }

  // table 
  const headers = ['타입', '이미지', '순서', '게시여부', ''];

  const onAddUserButtonClicked = () => {
    setOpen(true);
  };

  const buttons: ITableListButton[] = [
    {
      name: 'Add Guide',
      icon: 'add',
      onButtonClicked: onAddUserButtonClicked
    }
  ];

  const tableListProps: ITableListProps = {
    title: '이용자 가이드 관리',
    size: 'small',
    color: 'violet',
    buttons,
    headers,
    count: list.length,
    refresh
  }

  const guideItems = list.reduce((acc, cur, i) => {
    const items = cur.useGuideDetails.map(
      (detail:any) => ({
        no: cur.no,
        detailNo: detail.detailNo,
        guideType: cur.guideType,
        imageName : detail.imageName, 
        imagePath: detail.imagePath, 
        priority: detail.priority,
        viewYn: detail.viewYn
      })
    )

    return [...acc, ...items]
  }, []).map((guide:any) => (     
    <GuideListItem
      key={guide.detailNo}
      typeList={typeList}
      guide={guide}
      fileType={fileType}
      showFeedback={(msg) => {
        setResultMsg(msg);
        setSnackbarOpen(true);
      }}
    />
  ));

  return (
    <>
      <TableList {...tableListProps}>
        {guideItems}
      </TableList>

      <GuideWrite
        open={open}
        typeList={typeList}
        onClose={onclose}
        fileType={fileType}
        showFeedback={(msg) => {
          setResultMsg(msg);
          setSnackbarOpen(true);
        }}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={onCloseSnackbar}
        TransitionComponent={slideTransition}
        message={resultMsg}
        key={slideTransition.name}
      />
    </>
  )
}

const slideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

export default GuideList;