import React from 'react';

// Components
import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/nextTable/TableListItem';
import { IUserLogItem } from 'store/modules/types';
import useBasics from 'hooks/useBasics';

interface IProps {
  index: number;
  userLog: IUserLogItem
}

const LogListItem: React.FC<IProps> = (props) => {
  const { index, userLog } = props;
  const { getTextByCode } = useBasics();

  
  const {
    userRole,
    customerName,
    userId,
    agent,
    clientType,
    timestamp,
    clientIp,
  } = userLog;

  const isMobile: boolean = clientType === 'mobile' || agent.includes('okhttp') || agent.includes('mobile');
  const isSocial: boolean = clientType === 'social';
  const accessType: string = (isMobile ? 'APP' : 'WEB') + (isSocial ? ' (SOCIAL)' : '');

  const tableListItemsProps: ITableListItemProps = {
    id: index,
    items: [
      { value: String(index) },
      { value: getTextByCode('admin_user_role_type', userRole) },
      { value: customerName },
      { value: userId },
      { value: accessType},
      { value: timestamp },
      { value: '로그인' },
      { value: clientIp }
    ],
    onRowSelectedEvent: () => {}
  }

  return (
    <TableListItem {...tableListItemsProps} />
  );
}

export default LogListItem;