import styled, { css } from 'styled-components'

interface IStyleProps {
  fontSize?: string;
  fontWeight?: number;
  color?: string;
  notDivide?: boolean;
  height?: string;
  margin?: number;
}

const Card = styled.div<IStyleProps>`
  width: 100%;
  height: ${({ height }) => height || '100px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  & + & {
    margin-left: ${({ margin }) => margin}px;
  }

  ${({ notDivide }) => !notDivide && css`
    & > div:nth-child(n+2) {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  `}
`

const Box = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: wrap;
  flex: 1;

  & > div:nth-child(n+2) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
`

const Text = styled.span<IStyleProps>`
  display: block;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  color: ${({ color }) => color && color};
`

export const CardStyles = {
  Card,
  Box,
  Text,
}