import React, { SyntheticEvent } from 'react';

import { Modal, Button, ModalProps, } from 'semantic-ui-react';

export const PopupTypes = {
  BASIC: 'basic',
  CONFIRM: 'confirm',
  DIALOG: 'dialog',
} as const
export type PopupType = typeof PopupTypes[keyof typeof PopupTypes]

export interface IPopupButtonProps {
  name?: string;
  icon?: string;
  disabled?: boolean;
  onClick?: { (e: SyntheticEvent, item: any): void };
}

export interface IPopupProps {
  id: number,
  type?: PopupType;
  open: boolean;
  title?: string;
  size?: ModalProps['size'];
  buttons?: IPopupButtonProps[];
  onClose(): void;
  style?: React.CSSProperties;
}

const BasePopup: React.FC<IPopupProps> = (props) => {

  const { id, type, open, title, size, buttons, onClose, style, children } = props;

  const header = title ? <Modal.Header>{title}</Modal.Header> : null;

  const close = () => {
    onClose();
  }

  return (
    <Modal
      open={open}
      size={size ? size : 'small'}
      dimmer='blurring'
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={close}
      style={style}
    >
      {header}
      <Modal.Content>
        {children}
      </Modal.Content>

      {/* button 정의 */}
      <Modal.Actions>
        {getActions(type, id, buttons, close)}
      </Modal.Actions>

    </Modal>
  );
}

const getActions = (type?: PopupType, id?: number, buttons?: IPopupButtonProps[], onClose?: any) => {
  const actions = buttons ? buttons.map((button, index) => {

    if (type === PopupTypes.DIALOG && index === 0) {
      return (
        <Button key={index} onClick={button.onClick ? button.onClick : onClose} negative>
          {button.name ? button.name : 'No'}
        </Button>
      )
    }

    const { icon = 'checkmark' } = button;
    const content = button.name ? button.name : ( type === PopupTypes.DIALOG ? 'Yes' : 'OK' );   

    return (
      <Button
        key={index}
        onClick={button.onClick ? button.onClick : onClose}
        positive
        icon={icon}
        labelPosition='right'
        content={content}
        data={id}
        disabled={button.disabled}
      />
    )
  }) : null;

  return actions;
}

export default BasePopup;