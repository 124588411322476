import React from 'react';


import { Typography, Divider, Button, Container } from '@material-ui/core';

// Components

// Styles
import { TableItemDetailStyles as Styled } from './styles/NextTableItemDetail.styles';

// Hooks
import useBasics from 'hooks/useBasics';

export interface ITableItemProps {
  titleDiv?: any;
  title?: string;
  content: string;
  regDt: string;
  onToList: Function;
}

interface IProps {
  data: ITableItemProps;
}

const TableItemDetail: React.FC<IProps> = (props) => {

  const { basics } = useBasics();
  const { menu } = basics;

  const { data } = props;
  const { title, titleDiv, content, regDt, onToList } = data;
  
  const options = { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };
  const regDate = new Date(regDt);
  const formattedRegDate = new Intl.DateTimeFormat("ja-JP", options).format(regDate);

  const headerTitleDiv = (
    <Container style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
      <Typography variant="h4">{menu.name}</Typography>
    </Container>
  )

  return (
    <>
      <Styled.RootDiv>

        {headerTitleDiv}

        <Styled.TitleDiv>
          <div style={{ display: 'flex', flex: 3, justifyContent: 'flex-start' }}>
            { titleDiv }
            { title ? <Typography variant="h5" style={{ textAlign: 'center' }}>{data.title}</Typography> : null}
          </div>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, .3)', lineHeight: '30px' }}>{formattedRegDate}</Typography>
          </div>
        </Styled.TitleDiv>

        <Styled.ContentDiv>
          <Typography dangerouslySetInnerHTML={{ __html: content }} />
        </Styled.ContentDiv>
        <Divider />

        <Styled.ButtonDiv>
          <Button variant="outlined" color="primary" size="large" onClick={() => onToList()}>목록</Button>
        </Styled.ButtonDiv>

      </Styled.RootDiv>
    </>
  )
}

export default TableItemDetail;