import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { TodayBestState } from "store/modules/types";
import {
  RecollectButtonContainer,
  Table,
  CustomizedButton,
  Text,
} from "../styles/CommonStyle/Common.style";
import { OpenInNew } from "@material-ui/icons";
import { useState } from "react";
import { defaultMaxListeners } from "stream";
import useTodayBest from "hooks/useTodayBest";

interface IProps {
  todayBests: TodayBestState[];
  clickedDate: string;
  marketName: string;
}

const TodayBestTable: React.FC<IProps> = (props) => {
  const { todayBests, clickedDate, marketName } = props;
  const { requestRecollectTodayBest } = useTodayBest();
  const category1List = todayBests.map((item) => item.categoryBottom);
  const category2List = todayBests.map((item) => item.categoryTop);
  const urlList = todayBests.map((item) => item.categoryUrl);
  const today = new Date().toISOString().slice(0, 10);
  const [checkedProducts, setCheckedProducts] =
    useState<TodayBestState[]>(todayBests);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );

  const handleCheckboxChange = (idx: number) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [idx]: !prevCheckedItems[idx],
      };

      const updatedCheckedProducts = todayBests.filter(
        (_, index) => newCheckedItems[index]
      );

      setCheckedProducts(updatedCheckedProducts);
      return newCheckedItems;
    });
  };
  
  useEffect(() => {
    setCheckedProducts(todayBests);
    setCheckedItems(getInitialCheckedItems(todayBests.length));
  }, [todayBests]);

  const getInitialCheckedItems = (num: number) => {
    const initialCheckedItems: { [key: number]: boolean } = {};
    for (let i = 0; i < num; i++) {
      initialCheckedItems[i] = true;
    }
    return initialCheckedItems;
  };

  const onRecollect = (misCollectedList: TodayBestState[]) => {
    if (misCollectedList.length == 0) {
      alert(marketName + "의 미수집 리스트가 존재 하지 않습니다");
      return;
    }
    const payload: any = {
      body: misCollectedList,
      callback: (succeeded: boolean, res: any) => {
        if (succeeded) {
          alert(marketName + " 재수집 요청 성공");
        } else {
          if (res.status === 409) {
            alert(marketName + " 재수집 중");
          } else {
            alert(marketName + " 재수집 실패");
          }
        }
      },
    };
    requestRecollectTodayBest(payload);
  };

  return (
    <div>
      <RecollectButtonContainer>
        <p>
          {clickedDate} 의 미수집 리스트 ({todayBests.length})
        </p>
        {clickedDate === today && (
          <CustomizedButton
            height="20px"
            width="60px"
            margin="0px 0px 7px"
            onClick={() => onRecollect(checkedProducts)}
          >
            재수집
          </CustomizedButton>
        )}
      </RecollectButtonContainer>

      {todayBests.length > 0 && (
        <Table isChecklist={true}>
          <thead>
            <tr>
              <th> Category 1</th>
              <th> Cateogry 2</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {category1List.map((item, idx) => (
              <tr key={idx}>
                <td>{category2List[idx]}</td>
                <td>
                  <a
                    href={
                      urlList[idx] !== null
                        ? urlList[idx]
                        : "https://search.shopping.naver.com/best/category/click?categoryCategoryId=ALL&categoryDemo=A00&categoryRootCategoryId=ALL&period=P1D"
                    }
                    target="_blank"
                  >
                    <Text isNull={urlList[idx] === null}>
                      {item}
                      <OpenInNew></OpenInNew>
                    </Text>
                  </a>
                </td>
                <td>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(idx)}
                    checked={!!checkedItems[idx]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default TodayBestTable;
